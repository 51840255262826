import AnimatedButton from "components/animatedButton";
import Logo from "assets/images/verify-email.svg";
import OTPInput from "otp-input-react";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IoArrowBackSharp } from "react-icons/io5";
import LetterEmoji from "assets/images/loveletter.png";
import { toast } from "react-toastify";
import { useEmailVerification, useResendCode } from "services/auth-api";

const EmailVerification = () => {
  const emailVerificationMutation = useEmailVerification();
  const resendCodeMutation = useResendCode();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [OTP, setOTP] = useState("");
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(30);
  const [canResend, setCanResend] = useState(false);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else {
      setCanResend(true);
    }

    return () => clearInterval(interval);
  }, [timer]);

  const handleVerificationSubmit = async () => {
    setLoading(true);
    try {
      const response = await emailVerificationMutation.mutateAsync({
        email: state.email,
        code: OTP,
      });
      localStorage.setItem("isNewUser", true);
      localStorage.setItem("isSignUp", true);
      if (response.user_status) {
        setLoading(false);
        toast.success("Account created successfully! You can log in now.");
        navigate("/");
      }
    } catch (error) {
      if (error.data && error.data.detail) {
        toast.error(error.data.detail);
        if (
          error.data.detail ===
          "User cannot be confirmed. Current status is CONFIRMED"
        ) {
          navigate("/");
        }
      } else {
        toast.error("An unknown error occurred");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleEmailResend = async () => {
    if (canResend) {
      setCanResend(false);
      setTimer(30);
      try {
        const response = await resendCodeMutation.mutateAsync({
          email: state.email,
        });
        console.log(response, "response");
        toast.success("Code Re-sent", {
          icon: () => (
            <img src={LetterEmoji} alt="name" className="!w-[46px] !h-[16px]" />
          ),
        });
      } catch (error) {
        console.log(error, "error");
        toast.error("Code Re-send Failed", {
          icon: () => (
            <img src={LetterEmoji} alt="name" className="!w-[46px] !h-[16px]" />
          ),
        });
      }
    } else {
      toast.info(`Please wait ${timer} seconds before resending.`);
    }
  };

  return (
    <div className="relative">
      <div
        className="!h-[100%] font-inter flex flex-col justify-center items-center max-w-[340px] m-auto max-[767px]:h-auto  max-[767px]:p-[20px]  max-[767px]:pt-[50px]  max-[767px]:max-w-[100%]  signin-card"
        data-aos="fade-in"
        data-aos-duration="2000"
      >
        <img src={Logo} alt="Logo" className="h-[60px] w-[60px]" />
        <h1 className="text-[30px] text-center text-[#F5F5F6] font-[600] mt-[20px] mb-[12px]">
          Check your email
        </h1>
        <div className="text-[16px] font-[400] text-center text-[#94969C] leading-[24px]">
          We sent a verification code to{" "}
          <p
            href="mailto:olivia@facebook.com"
            className="font-[500] text-[#CCCCCC]"
          >
            {state?.email}
          </p>
        </div>
        <form className="mt-[22px] w-full" onSubmit={(e) => e.preventDefault()}>
          <div className="mb-[32px] flex items-center justify-center mr-[-20px]">
            <OTPInput
              value={OTP}
              onChange={setOTP}
              autoFocus
              OTPLength={6}
              otpType="number"
              disabled={false}
              inputClassName="otpInput !h-[56px] !px-[2px] text-[28px] !w-[40px] font-[500] border border-[#CBCBE84F] rounded-[6px] bg-transparent text-[#fff] !outline-none focus:!border-[#8A43FF] focus:!border-[2px]"
            />
          </div>
          <AnimatedButton
            title={
              <div className="flex items-center justify-center">
                {loading ? (
                  <div className="w-[20px] h-[20px] border-[3px] border-t-[3px] border-[#fff] border-solid rounded-full animate-spin border-t-[#8A43FF]" />
                ) : (
                  "Verify email"
                )}
              </div>
            }
            onClick={handleVerificationSubmit}
            disabled={OTP.length !== 6}
            className="!text-[15px] !font-[600] !h-[42px] !rounded-[7px] !mb-[32px] !bg-[#635BFF]"
            fontSize="!text-[15px]"
          />
        </form>
        {canResend ? (
          <p className="text-center font-[400] text-[14px] text-[#94969C]">
            Didn’t receive the email?
            <span
              className="font-[600] text-[13px] text-[#CECFD2] cursor-pointer ml-[4px] cursor-pointer"
              onClick={handleEmailResend}
            >
              Click to resend
            </span>
          </p>
        ) : (
          <p className="text-center font-[400] text-[14px] text-[#94969C]">
            <span
              className="font-[600] text-[13px] text-[#CECFD2] cursor-pointer ml-[4px]"
              onClick={handleEmailResend}
            >
              {`Please Wait ${timer}s to resend verification code`}
            </span>
          </p>
        )}

        <p
          className="text-center font-[600] text-[14px] text-[#eeeeee] cursor-pointer absolute top-full mt-5 left-1/2 -translate-x-1/2"
          onClick={() => navigate("/signUp")}
        >
          <IoArrowBackSharp className="mr-[6px] inline mt-[-2px] text-[18px] w-[20px] h-[20px]" />{" "}
          Back to Sign Up
        </p>
      </div>
    </div>
  );
};

export default EmailVerification;
