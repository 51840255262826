import { useContext, useState } from "react";
import OutlinedInput from "components/outlinedInput";
import CustomRadioButton from "components/radioButton";
import { HiOutlineMail } from "react-icons/hi";
import visa from "assets/images/visa.svg";
import mastercard from "assets/images/mastercard.svg";
import amex from "assets/images/amex.svg";
import discover from "assets/images/discover.svg";
import unionpay from "assets/images/unionpay.svg";
import dinnersClub from "assets/images/dinners-club.svg";
import jcb from "assets/images/jcb.svg";
import CommonCheckbox from "components/commonCheckbox";
import { IoMdAdd } from "react-icons/io";
import PaymentCardModal from "components/paymentCardModal";
import { AppContext } from "context/AppContext";
import { toast } from "react-toastify";
import CC from "assets/images/cc.png";
import { usePaymentBillInfo, usePaymentMethods } from "services/payments-api";
import { useAuthStore } from "stores";
import Loader from "components/loader";

const PaymentMethodForm = ({ localEmail, setLocalEmail }) => {
  const { userDetails } = useAuthStore();
  const { appState, setAppState } = useContext(AppContext);
  const [selectedEmailOption, setSelectedEmailOption] = useState(
    appState?.alternativeEmail ? "alternative" : "account"
  );
  const [isPaymentCardOpen, setIsPaymentCardOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([
    {
      cardNumber: "3216 5497 8654 3069",
      cvv: "123",
      expiration: "06/25",
      name: "John Doe",
      id: new Date(),
    },
  ]);
  const { data: billingDetails } = usePaymentBillInfo({
    user_id: userDetails?.userid,
  });
  const [cardDetails, setCardDetails] = useState(null);
  const [selectedCards, setSelectedCards] = useState([paymentMethods[0]?.id]);
  const [emailError, setEmailError] = useState("");
  const [emailInput, setEmailInput] = useState(
    appState?.alternativeEmail || ""
  );

  const {
    data: paymentMethod,
    isPending,
    refetch,
  } = usePaymentMethods({
    user_id: userDetails?.userid,
  });

  const handleRadioChange = (option) => {
    setSelectedEmailOption(option);
    if (option === "account") {
      setAppState((prev) => ({
        ...prev,
        alternativeEmail: "",
      }));
      setEmailError("");
    }
  };

  const handleCheckboxChange = (id) => {
    setSelectedCards([id]);
    toast.success("Payment Method Updated", {
      icon: () => <img src={CC} alt="name" />,
    });
  };

  const togglePaymentCard = (editing = false, index = null) => {
    setIsEditing(editing);
    setIsPaymentCardOpen(!isPaymentCardOpen);
  };

  const handleAddOrEditCard = (cardData) => {
      // Add new card
      setPaymentMethods((prevMethods) => [
        ...prevMethods,
        { ...cardData, id: new Date() },
      ]);
      toast.success("Payment Method Added", {
        icon: () => <img src={CC} alt="name" />,
      });
    setIsPaymentCardOpen(false);
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setEmailInput(email);

    if (validateEmail(email)) {
      setEmailError("");
      setAppState((prev) => ({
        ...prev,
        alternativeEmail: email,
      }));
      setLocalEmail(email);
    } else {
      setEmailError("Please enter a valid email address");
      setAppState((prev) => ({
        ...prev,
        alternativeEmail: ``,
      }));
    }
  };

  const getCardImage = (brand) => {
    switch (brand.toLowerCase()) {
      case "mastercard":
        return (
          <div className="flex items-center justify-center border rounded shadow-sm">
            <img
              src={mastercard}
              alt="Mastercard"
              className="w-[46px] h-[32px]"
            />
          </div>
        );
      case "discover":
        return (
          <img
            src={discover}
            alt="Discover"
            className="w-[46px] h-[32px] object-cover"
          />
        );
      case "amex":
        return (
          <img
            src={amex}
            alt="Amex"
            className="w-[46px] h-[32px] object-cover"
          />
        );
      case "unionpay":
        return (
          <img
            src={unionpay}
            alt="UnionPay"
            className="w-[46px] h-[32px] object-cover"
          />
        );
      case "jcb":
        return (
          <img src={jcb} alt="jcb" className="w-[46px] h-[32px] object-cover" />
        );
      case "diners":
        return (
          <img
            src={dinnersClub}
            alt="diners"
            className="w-[46px] h-[32px] object-cover"
          />
        );
      default:
        return <img src={visa} alt="Visa" className="w-[46px] h-[32px]" />;
    }
  };
  return (
    <div>
    {billingDetails?.[0]?.subscription_end_date &&  <p className="font-[400] text-[#475467] text-[14px]">
        Your next payment is due on{" "}
        <span className="font-[600]">
          {billingDetails?.[0]?.subscription_end_date}.
        </span>
      </p>}
      <div className="flex items-start pb-[20px] border-b-[1px] border-[#E4E7EC] pt-[24px] w-[90%]">
        <div className="w-[300px] mr-[32px]">
          <h6 className="font-[600] text-[#101828] text-[14px]">
            Contact email
          </h6>
          <p className="font-[400] text-[#475467] text-[14px]">
            Where should invoices be sent?
          </p>
        </div>
        <div className="max-w-full">
          <div className="flex items-start mb-[16px]">
            <CustomRadioButton
              id="account-email"
              className="mt-[3px]"
              checked={selectedEmailOption === "account"}
              onChange={() => handleRadioChange("account")}
            />
            <label htmlFor="account-email" className="cursor-pointer">
              <p className="font-[500] text-[#344054] text-[14px]">
                Send to my account email
              </p>
              <p className="font-[400] text-[#344054] text-[14px]">
                {userDetails?.email}
              </p>
            </label>
          </div>
          <div className="flex items-start">
            <CustomRadioButton
              id="alternative-email"
              className="mt-[3px]"
              checked={selectedEmailOption === "alternative"}
              onChange={() => handleRadioChange("alternative")}
            />
            <label htmlFor="alternative-email" className="cursor-pointer">
              <p className="font-[500] text-[#344054] text-[14px] mb-[12px]">
                Send to an alternative email
              </p>
              <div className="relative w-[488px]">
                <HiOutlineMail className="text-[#667085] w-[20px] h-[20px] text-[14px] absolute top-[12px] left-[14px] z-10" />
                <OutlinedInput
                  placeholder={"john@smith.com"}
                  value={emailInput}
                  onChange={handleEmailChange}
                  className="pl-[40px]"
                  readOnly={selectedEmailOption !== "alternative"}
                />
                {emailError && (
                  <p className="text-[11px] mt-[8px] text-[red]">
                    {emailError}
                  </p>
                )}
              </div>
            </label>
          </div>
        </div>
      </div>
      {/* card detail */}
      <div className="flex items-start py-[20px]">
        <div className="w-[300px] mr-[32px]">
          <h6 className="font-[600] text-[#101828] text-[14px]">
            Card details<span className="text-[#7F56D9] pl-[1px]">*</span>
          </h6>
          <p className="font-[400] text-[#475467] text-[14px]">
            Select default payment method.
          </p>
        </div>
        <div className="max-w-full">
          {paymentMethod?.id ? (
            <div
              className={`p-[14px] bg-transparent cursor-pointer rounded-[12px] border-[2px] ${
                selectedCards?.includes(paymentMethod?.id)
                  ? "border-[#9E77ED]"
                  : "#6B7280"
              }  w-[512px] mb-[16px]`}
            >
              <div className="flex items-start">
                <div className="flex items-start w-full">
                  {getCardImage(paymentMethod?.brand)}
                  <div className="ml-[12px]">
                    <h6 className="font-inter text-[14px] font-[500] text-[#344054]">
                      {paymentMethod?.brand || "Visa"} ending in{" "}
                      {paymentMethod?.last4 || "1234"}
                    </h6>
                    <p className="text-[#475467] text-[14px] font-[400] mb-[8px]">
                      Exp{" "}
                      {(paymentMethod?.exp_month &&
                        `${paymentMethod?.exp_month}/${paymentMethod?.exp_year
                          .toString()
                          .slice(-2)}`) ||
                        "06/24"}
                    </p>
                    <button
                      className="border-0 !outline-none font-inter font-[600] text-[#6941C6] text-[14px] flex items-center"
                      onClick={(e) => {
                        e.stopPropagation();
                        togglePaymentCard(true, 0);
                        setCardDetails(paymentMethod);
                      }}
                    >
                      Edit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex items-center justify-center w-full h-20">
              {isPending ? (
                <Loader />
              ) : (
                <p className="font-[400] text-[#475467] text-[14px] -mt-4">
                  No payment methods added.
                </p>
              )}
            </div>
          )}

          {isPaymentCardOpen && (
            <PaymentCardModal
              isEditing={isEditing}
              setIsPaymentCardOpen={setIsPaymentCardOpen}
              onSave={handleAddOrEditCard}
              cardDetails={cardDetails}
              isRefetch={refetch}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentMethodForm;
