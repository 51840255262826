import { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import Button from "components/button";
import connectIcon from "assets/images/connect.svg";
import OutlinedInput from "components/outlinedInput";
import mailIcon from "assets/images/mail.svg";
import secureIcon from "assets/images/secure.svg";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useProxyLocation } from "services/auth-api";
import Select from "react-select";
import { countrySelectStyleLight } from "constant";
import { debounceCountry } from "utils/";
import { AllLocations } from "constant/locations";

const ConnectLinkedInModal = ({
  isOpen,
  onClose,
  isReconnectedFlow,
  email,
  setEmail,
  password,
  setPassword,
  onConnect,
  setSelectedLocation,
  isPending,
}) => {
  const proxyLocationMutation = useProxyLocation();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [locations, setLocations] = useState(AllLocations);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const getCountry = async (searchQuery) => {
    try {
      setLoading(true);
      const location = await proxyLocationMutation.mutateAsync({
        query: searchQuery,
      });
      location?.results?.length && setLocations(location?.results);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onSearchLocation = debounceCountry((event) => {
    const searchValue = event;
    if (searchValue) {
      getCountry(searchValue);
    } else {
      setLocations(AllLocations);
    }
  }, 300);

  const validateEmail = (email) => {
    // Simple email regex validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    if (!validateEmail(value)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };
  useEffect(() => {
    if (isOpen) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [isOpen]);

  if (!isOpen && !isModalVisible) return null;

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[300ms] ease-in-out ${
        isModalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className="fixed inset-0 bg-black opacity-10"
        onClick={onClose}
      ></div>
      <div
        className={`bg-white p-[24px] rounded-lg modal-shadow z-10 w-full max-w-[400px] relative transition-all ease-in-out duration-[150ms] ${
          isModalVisible ? "scale-100" : "scale-95"
        }`}
      >
        <IoClose
          className="text-[#667085] text-[20px] w-[24px] h-[24px] absolute right-[24px] top-[26px] cursor-pointer"
          onClick={onClose}
        />
        <div className="modal-scroll">
          <div className="flex flex-col items-start justify-start mb-[20px] font-inter">
            <div
              className="flex justify-center items-center border-[#E4E7EC] border w-[48px] h-[48px] rounded-[10px] mb-[16px]"
              style={{
                boxShadow:
                  "0px 4px 8px 0px rgba(16, 24, 40, 0.05), 0px 2px 0px 0px rgba(16, 24, 40, 0.05), 0px 0px 1px 0px rgba(16, 24, 40, 0.18)",
              }}
            >
              <img
                src={connectIcon}
                alt="coins"
                className="h-[24px] w-[24px]"
              />
            </div>
            <h4 className="font-[600] text-[18px] text-[#101828] m-0 font-inter">
              {isReconnectedFlow
                ? "Re-Connect LinkedIn"
                : "Connect LinkedIn Account"}
            </h4>
          </div>
          {/* form */}
          <>
            <div className="w-full">
              <p className="font-inter text-[14px] font-[500] text-[#344054] leading-[20px] mb-[6px]">
                Email address
              </p>
              <div className="relative mb-[12px]">
                <OutlinedInput
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  className="relative pr-[14px] pl-[40px] py-[10px] h-[40px] !text-[15px] placeholder:!text-[#667085]"
                />
                <img
                  src={mailIcon}
                  alt="mail"
                  className="w-[20px] h-[20px] absolute left-[12px] top-[9px]"
                />
                {emailError && (
                  <p className="text-red-500 text-sm mt-1">{emailError}</p>
                )}
              </div>
            </div>
            <div className="w-full">
              <p className="font-inter text-[14px] font-[500] text-[#344054] leading-[20px] mb-[6px]">
                Password
              </p>
              <div className="relative mb-[12px]">
                <OutlinedInput
                  type={passwordVisible ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="relative pr-[14px] pl-[40px] py-[10px] h-[40px] !text-[15px] placeholder:!text-[#667085]"
                />
                <img
                  src={secureIcon}
                  alt="secure"
                  className="w-[20px] h-[20px] absolute left-[12px] top-[9px]"
                />
                <div onClick={() => setPasswordVisible(!passwordVisible)}>
                  {passwordVisible ? (
                    <FiEye
                      className="absolute right-[15px] z-50 top-[14px] cursor-pointer"
                      color="#CBCBE866"
                    />
                  ) : (
                    <FiEyeOff
                      className="absolute right-[15px] z-50 top-[14px] cursor-pointer"
                      color="#CBCBE866"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="w-full">
              <p className="font-inter text-[14px] font-[500] text-[#344054] leading-[20px] mb-[6px]">
                Location
              </p>
              <div className="relative mb-[12px]">
                <Select
                  options={locations}
                  classNamePrefix="leads-react-select"
                  className="!font-inter w-full !border-none !border-black text-white"
                  placeholder="Search your country"
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  maxMenuHeight="120px"
                  styles={countrySelectStyleLight}
                  isClearable
                  isSearchable={true}
                  noOptionsMessage={() => "No Results"}
                  onChange={(selectedOption) => {
                    setSelectedLocation(selectedOption);
                  }}
                  onInputChange={(e) => {
                    onSearchLocation(e);
                  }}
                  isLoading={loading}
                />
              </div>
            </div>
          </>
          <p className="max-w-[250px] mx-auto text-center text-[#808080] text-[9.8px] font-[400]">
            By clicking <b className="text-[#808080]">Connect</b>, you are
            authorizing us to access and take actions through your LinkedIn
            account.
          </p>
          <div className="flex items-center justify-center mt-[15px]">
            <Button
              title="Cancel"
              isWhiteBtn={true}
              onClick={onClose}
              className="!text-[16px] !h-[44px] !font-[600] !text-[#344054] y-axis-shadow !rounded-[8px]"
            />
            <Button
              onClick={onConnect}
              disabled={emailError || password?.length === 0}
              title={
                isPending ? (
                  <div className="flex items-center justify-center">
                    <div className="w-[18px] h-[18px] border-[3px] border-t-[3px] mr-[12px] border-[#fff] border-solid rounded-full animate-spin border-t-[#8A43FF]" />
                    Loading...
                  </div>
                ) : (
                  "Connect"
                )
              }
              secondaryColor={`!border-[#7F56D9] !bg-[#7F56D9] ml-[12px] ${
                isPending
                  ? "hover:!text-white hover:!bg-[#7F56D9]"
                  : "hover:!bg-transparent hover:!text-[#7F56D9]"
              }`}
              className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow !rounded-[8px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectLinkedInModal;
