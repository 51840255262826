const CampaignTableSkeleton = () => {
  return (
    <div className="w-full border border-gray-200 rounded-md overflow-hidden">
      {/* Header Skeleton */}
      <div className="flex items-center py-3 bg-white box-border">
        {Array(4)
          .fill(0)
          .map((_, index) => (
            <div key={index} className={`px-4 ${index === 0 ? "w-[32%]" : "w-[25%]"}`}>
              <div className={`h-[25px] animate-bg-pulse w-[70%] rounded-sm`}></div>
            </div>
          ))}
      </div>
      {/* Skeleton Rows */}
      {Array(11)
        .fill(0)
        .map((_, index) => (
          <div key={index} className="flex items-center py-4 border-t border-gray-200 w-full box-border">
            <div className="flex items-center px-4 w-[32%]">
              <div className="h-[22px] rounded-full w-[40px] animate-bg-pulse mr-4"></div>
              <div className="h-[22px] rounded-sm w-[55%] animate-bg-pulse"></div>
            </div>
            <div className="px-4 w-[25%] flex items-center">
              <div className="h-[22px] w-[40px] rounded-sm animate-bg-pulse mr-2"></div>
              <div className="h-[22px] w-[40px] rounded-sm animate-bg-pulse mr-2"></div>
              <div className="h-[22px] w-[40px] rounded-sm animate-bg-pulse mr-2"></div>
              <div className="h-[22px] w-[40px] rounded-sm animate-bg-pulse mr-2"></div>
              <div className="h-[22px] w-[40px] rounded-sm animate-bg-pulse mr-2"></div>
            </div>
            <div className="px-4 w-[25%]">
              <div className="h-[8px] rounded w-[70%] animate-bg-pulse"></div>
            </div>
            <div className="px-4 w-[25%] flex items-center justify-between">
              <div className="h-[22px] rounded-sm w-[70%] animate-bg-pulse"></div>
              <div className="h-[10px] rounded-sm w-[30px] animate-bg-pulse mr-4"></div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default CampaignTableSkeleton;
