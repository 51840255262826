import Button from "components/button";
import checkBg from "assets/images/check-bg.svg";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "context/AppContext";
import { useNavigate } from "react-router-dom";
import { MdOutlineAdd } from "react-icons/md";
import { useProspectStore } from "stores";
import { useGetProspectsList } from "services/prospecting-api";

const SuccessModalStep = ({ onClose, setStep, prospectId, setLeadsValue }) => {
  const { appState } = useContext(AppContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const getProspectsList = useGetProspectsList();
  const getSaveListProps = JSON.parse(
    localStorage?.getItem("isSaveListModals")
  );
  const { setProspectInfo, prospectList } = useProspectStore();
  const getSelectedBtnType = localStorage?.getItem("selectedButtonType");

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  const getTitle = () => {
    if (getSaveListProps) {
      return "Lead List Saved";
    } else if (
      getSelectedBtnType === "Companies" ||
      getSelectedBtnType === "People"
    ) {
      return "Lead List Created";
    } else if (appState?.importMethodClicked === "import-hubspot") {
      return "Hubspot Leads Imported";
    } else if (appState?.importMethodClicked === "viaLinkedin") {
      return "LinkedIn URL Lead Data Imported";
    } else {
      return "CSV Imported";
    }
  };

  const getDescription = () => {
    if (getSaveListProps) {
      return "You saved your prospected list.";
    } else if (
      getSelectedBtnType === "Companies" ||
      getSelectedBtnType === "People"
    ) {
      return "Your links were imported and enriched to a lead list.";
    } else if (appState?.importMethodClicked === "import-hubspot") {
      return "Your contacts were uploaded to a lead list.";
    } else {
      return "Your data was uploaded to a lead list.";
    }
  };
  const getCommonListType = (name) => {
    switch (name) {
      case "hubspot leads":
        return "import-hubspot";
      case "founders in sf":
        return "founders-in-sf";
      case "Series B - Engineers":
        return "Series-B";
      case "healthcare ctos":
        return "csv-upload";
      default:
        return "test";
    }
  };

  const onView = async () => {
    setLeadsValue("");
    if (!prospectId) {
      onClose();
      if (getSaveListProps) {
        localStorage.removeItem("isSaveListModals");
        setStep(1);
      } else {
        setStep("import-options");
        localStorage.removeItem("selectedButtonType");
      }
    } else {
      const response = await getProspectsList.mutateAsync({
        limit: 200,
        page: 1,
        sort_by: "name",
        sort_order: "desc",
      });
      if (response?.data) {
        const prospect = response?.data?.find(
          (item) => item?.prospect_id === prospectId
        );
        console.log(prospect, "prospect", prospectId);
        onClose();
        if (getSaveListProps) {
          localStorage.removeItem("isSaveListModals");
          setStep(1);
        } else {
          setStep("import-options");
          localStorage.removeItem("selectedButtonType");
        }
        setProspectInfo(prospect);
        navigate(`/prospecting/${prospectId}`);
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <>
          {/* Skeleton view */}
          <div className="flex flex-col items-start">
            <div className="w-[48px] h-[48px] rounded-full bg-[#ededed] animate-bg-pulse mb-[16px]" />
            <div className="w-full h-[28px] rounded-sm bg-[#ededed] animate-bg-pulse mb-2" />
            <div className="w-full h-[25px] rounded-sm bg-[#ededed] animate-bg-pulse" />
            <div className="w-full h-[38px] rounded-[8px] bg-[#ededed] animate-bg-pulse mt-[28px]" />
          </div>
          {/* End of skeleton view */}
        </>
      ) : (
        <>
          <div className="flex flex-col items-start justify-start font-inter">
            <div className="flex items-center justify-center mb-[16px] relative">
              <div className="relative flex items-center justify-center h-[48px] w-[48px] ">
                <img
                  src={checkBg}
                  alt="checkBg"
                  className="h-full w-full rounded-full absolute top-0 z-[1]"
                />
                <div className="h-[20px] w-[20px] rounded-full relative z-[2]">
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 130.2 130.2"
                    className="w-full h-full"
                  >
                    <circle
                      className="custom-checkmark circle"
                      fill="none"
                      stroke="#039855"
                      strokeWidth="10"
                      strokeMiterlimit="10"
                      cx="65.1"
                      cy="65.1"
                      r="60"
                    />
                    <polyline
                      className="custom-checkmark check"
                      fill="none"
                      stroke="#039855"
                      strokeWidth="10"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      points="92.2,50.2 52.5,88.8 32.8,70.5"
                    />
                  </svg>
                </div>
              </div>
              <div className="animate-ping duration-100 bg-[#ECFDF3] absolute top-0 left-[-8px] w-[50px] h-[50px] rounded-full z-[-1]" />
            </div>
            <h4 className="font-[600] text-[18px] text-[#101828] m-0 font-inter">
              {getTitle()}
            </h4>
            <p className="font-normal text-[14px] text-[#475467] font-inter">
              {getDescription()}
            </p>
          </div>
          <div className="mt-[32px] flex justify-between gap-[12px]">
            <Button
              title={
                getProspectsList?.isPending ? (
                  <div className="w-[20px] h-[20px] border-[3px] border-t-[3px] mx-auto border-[#fff] border-solid rounded-full animate-spin border-t-[#8A43FF]" />
                ) : (
                  "View Lead List"
                )
              }
              className="!text-[16px] !h-[44px] !font-[600] !text-[#344054] y-axis-shadow"
              isWhiteBtn={true}
              isRounded="6px"
              onClick={() => onView()}
            />

            <Button
              // title=""
              title={
                <div className="flex items-center justify-center font-inter !text-[14px] !font-[600]">
                  <MdOutlineAdd className="text-[18px] mr-[7px] mt-[1px]" />
                  Create Campaign
                </div>
              }
              secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9]"
              className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow"
              isRounded="6px"
              onClick={() => {
                onClose();
                navigate("/new-campaign");
                setLeadsValue("");
              }}
            />
          </div>
        </>
      )}
    </>
  );
};

export default SuccessModalStep;
