import Layout from "components/layout";
import Button from "components/button";
import Tabs from "components/tabs";
import { useContext, useEffect, useState } from "react";
import BreadcrumbHeader from "components/breadcrumbHeader";
import { MdOutlineAdd } from "react-icons/md";
import { AppContext } from "context/AppContext";
import { campaignTabs } from "constant";
import CampaignTable from "components/campaigns/campaignTable";
import CommonModal from "components/prospecting/modal";
import EditCampaignModal from "components/campaigns/modals/editCampaign";
import ArchiveCampaignModal from "components/campaigns/modals/archiveCampaign";
import DeleteCampaignModal from "components/campaigns/modals/deleteCampaign";
import ShareLinkCampaignModal from "components/campaigns/modals/shareLinkCampaign";
import DeleteDraftCampaignModal from "components/campaigns/modals/deleteDraftCampaign";
import { useLocation, useNavigate } from "react-router-dom";
import EmptyPage from "../../components/emptyPageComponent";
import unArchiveIcon from "assets/images/unarchive.svg";
import CampaignIsStillUnderProcess from "../../components/campaigns/campaignModals/campaignStilBeingProcessed";
import {
  useArchiveCampaign,
  useCampaignActiveStatus,
  useCampaignList,
  useDeleteCampaign,
} from "services/campaigns-api";
import { useAuthStore } from "stores";
import useCampaignStore from "stores/campaignStore";
import { toast } from "react-toastify";
import deleteImg from "assets/images/trash.svg";
import CampaignTableSkeleton from "components/skeletonScreens/campaignTableSkeleton";
import ReconnectBanner from "components/reconnectBanner";
import EmptyCampaigns from "components/emptyCampaignComponent";

let loaded = false;

const Campaigns = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const deleteCampaignList = useDeleteCampaign();
  const useArchiveApi = useArchiveCampaign();
  const campaignActiveStatus = useCampaignActiveStatus("");

  const { companyId } = useAuthStore();
  const { campaignList, setCampaignList, setCampaignId } = useCampaignStore();
  const { setAppState } = useContext(AppContext);

  const [tab, setTab] = useState("All Campaigns");
  const [filteredData, setFilteredData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isDraftModalOpen, setIsDraftModalOpen] = useState(false);
  const [currentEditItem, setCurrentEditItem] = useState(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [emptyState, setEmptyState] = useState(false);
  const [sortBy, setSortBy] = useState("created_ts");
  const [sortOrder, setSortOrder] = useState("desc");
  const [currentId, setCurrentId] = useState(null);
  const [prefillCampaignName, setPrefillCampaignName] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [paginationData, setPaginationData] = useState({
    limit: 20,
    page: 1,
  });
  const [
    isCampaignIsStillUnderProcessModalOpen,
    setIsCampaignIsStillUnderProcessModalOpen,
  ] = useState(false);
  const [loader, setLoader] = useState(true);

  let campaignParams = {
    type:
      tab === "Active"
        ? "ACTIVE"
        : tab === "Archive"
        ? "ARCHIVED"
        : tab === "Drafts"
        ? "DRAFTED"
        : "All",
    limit: paginationData?.limit,
    sort_by: sortBy,
    sort_order: sortOrder,
    page: paginationData?.page,
  };

  const {
    data: campaignData,
    refetch,
    isFetching,
    isRefetching,
  } = useCampaignList(campaignParams);

  useEffect(() => {
    if (paginationData?.page === 1) {
      setCampaignList(campaignData?.campaign_data);
    } else {
      setCampaignList([...campaignList ,...campaignData?.campaign_data ]);
      setLoader(true);
    }
    setTotalItems(campaignData?.pagination?.total_records);
  }, [campaignData?.campaign_data]);

  useEffect(() => {
    if (!loaded) {
      loaded = true;
    }
    refetch();
  }, [sortBy, sortOrder, tab, paginationData]);

  useEffect(() => {
    setPaginationData({
      limit: 20,
      page: 1,
    });
  }, [tab]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const isEmptyState = params.get("emptyState") === "true";
    setEmptyState(isEmptyState);
  }, [location.search]);

  useEffect(() => {
    let filtered;
    switch (tab) {
      case "All Lists":
        filtered = campaignList;
        break;
      case "Active":
        filtered = campaignList;
        break;
      case "Shared":
        filtered = campaignList.filter((item) => item?.is_shared);
        break;
      case "Archive":
        filtered = campaignList;
        break;
      default:
        filtered = campaignList;
        break;
    }
    setFilteredData(filtered);
  }, [tab, campaignList]);

  const onToggleChange = async (item, id) => {
    setLoader(false);
    const payload = {
      campaign_id: id,
      active_status: !item?.active_status,
    };
    const result = await campaignActiveStatus.mutateAsync(payload);
    if (result?.status === 200) {
      setLoader(false);
      refetch();
    }
  };

  const handleEdit = (id) => {
    const itemToEdit = campaignList?.find((item) => item.id === id);
    setCurrentEditItem(itemToEdit);
    setIsEditModalOpen(true);
    setMenuIsOpen(false);
  };

  const handleArchive = async (id, type) => {
    if (type === "unarchived") {
      const payload = {
        campaign_id: id,
        archive_status: false,
      };
      try {
        await useArchiveApi.mutateAsync(payload);
        toast.success("Unarchived", {
          icon: () => <img src={unArchiveIcon} alt="Unarchive" />,
        });
        refetch();
      } catch (error) {}
    } else {
      setIsArchiveModalOpen(true);
      setAppState((prev) => ({ ...prev, itemToArchiveId: id }));
    }
  };

  const handleUnArchive = (id) => {
    const updatedData = campaignList?.map((item) =>
      item.id === id ? { ...item, listType: "Inactive", active: false } : item
    );
    setCampaignList(updatedData);
    setMenuIsOpen(false);
  };

  const handleTableSort = (column) => {
    const isAsc = sortBy === column && sortOrder === "asc";
    setSortBy(column);
    setSortOrder(isAsc ? "desc" : "asc");
    setLoader(false)
  };

  const handleDeleteCampaignList = async () => {
    try {
      const res = await deleteCampaignList.mutateAsync(
        {
          company_id: companyId,
          campaign_id: currentId,
        },
        {
          onSuccess: () => {
            setIsDeleteModalOpen(false);
            toast.success(`Campaign Deleted`, {
              icon: () => <img src={deleteImg} alt="delete" />,
            });
            refetch();
          },
        }
      );
    } catch (error) {
      toast.error("An unexpected error occurred");
    }
  };

  const onRefetch = () => {
    if (totalItems === filteredData?.length) {
      setHasMore(false);
      return;
    }
    setLoader(false);
    setPaginationData({
      ...paginationData,
      page: paginationData?.page + 1,
      limit: 20,
    });
  };

  return (
    <Layout>
      <div className="h-[100vh] flex flex-col justify-start items-start font-inter">
        <BreadcrumbHeader
          breadcrumbItems={[
            { title: "Campaigns", route: "/Campaigns" },
            { title: "All" },
          ]}
        />
        <ReconnectBanner />
        <div
          className="max-w-[100%] w-full px-[31px] py-[18px] h-full !overflow-hidden"
          data-aos="fade-left"
        >
          <div className="flex items-center justify-between w-full mb-1">
            <h1 className="text-[32px] text-left text-[#252C32] font-semibold leading-normal mb-[8px]">
              Campaigns
            </h1>
            {campaignList?.length > 0 && (
              <div className="flex items-center w-full justify-end">
                <Button
                  isFullWidth={false}
                  gradientBg={true}
                  height="32px"
                  className="!rounded-[8px] !pr-[8px]"
                  title={
                    <div className="flex items-center justify-center font-inter !text-[14px] !font-[600] mt-[-2px]">
                      New Campaign
                      <MdOutlineAdd className="text-[18px] ml-[7px] mt-[1px]" />
                    </div>
                  }
                  onClick={() => {
                    setCampaignId(null);
                    navigate("/new-campaign");
                  }}
                />
              </div>
            )}
          </div>
          <Tabs
            tab={tab}
            setTab={(e) => {
              setTab(e);
            }}
            tabOptions={campaignTabs}
          />

          <div className="mt-5">
            {(isFetching || isRefetching) && loader ? (
                  <CampaignTableSkeleton />
            ) : filteredData?.length > 0 ? (
              <CampaignTable
                isEditModalOpen={isEditModalOpen}
                setIsEditModalOpen={setIsEditModalOpen}
                isArchiveModalOpen={isArchiveModalOpen}
                setIsArchiveModalOpen={setIsArchiveModalOpen}
                isDeleteModalOpen={isDeleteModalOpen}
                setIsDeleteModalOpen={setIsDeleteModalOpen}
                isShareModalOpen={isShareModalOpen}
                setIsShareModalOpen={setIsShareModalOpen}
                isDraftModalOpen={isDraftModalOpen}
                setIsDraftModalOpen={setIsDraftModalOpen}
                campaignTableData={filteredData}
                handleEdit={handleEdit}
                handleArchive={handleArchive}
                handleUnArchive={handleUnArchive}
                tab={tab}
                menuIsOpen={menuIsOpen}
                onChange={onToggleChange}
                setIsCampaignIsStillUnderProcessModalOpen={
                  setIsCampaignIsStillUnderProcessModalOpen
                }
                sortBy={sortBy}
                sortOrder={sortOrder}
                handleTableSort={handleTableSort}
                setCurrentId={setCurrentId}
                setPrefillCampaignName={setPrefillCampaignName}
                setPaginationData={setPaginationData}
                paginationData={paginationData}
                totalItems={totalItems}
                onRefetch={onRefetch}
                hasMore={hasMore}
              />
            ) : (
              <div className="flex items-center justify-center h-[calc(100vh_-400px)] w-full">
                {tab === "All Campaigns" ? (
                  <EmptyPage
                    title="No Campaigns found"
                    description="You’ll need to create a list to start your first campaign!"
                    whiteButtonTitle="Find Leads"
                    whiteButtonClass="!w-[128px]"
                    primaryButtonTitle="New Campaign"
                    primaryButtonClass="!w-[180px]"
                    onClick={() => navigate("/findLeads")}
                    onPrimaryClick={() => {
                      setCampaignId(null);
                      navigate("/new-campaign");
                    }}
                    showIcon={false}
                  />
                ) : (
                  <EmptyCampaigns title="No Campaigns Found" />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {/* modal */}
      <CommonModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setAppState((prev) => ({ ...prev, saveListName: "" }));
        }}
      />

      <EditCampaignModal
        isOpen={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false);
          setPrefillCampaignName("");
        }}
        prefillCampaignName={prefillCampaignName}
        currentId={currentId}
        refetch={refetch}
      />
      <ArchiveCampaignModal
        isOpen={isArchiveModalOpen}
        onClose={() => {
          setIsArchiveModalOpen(false);
          setMenuIsOpen(false);
        }}
        refetch={refetch}
        currentId={currentId}
      />
      <DeleteCampaignModal
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
        }}
        confirmDelete={handleDeleteCampaignList}
        loading={deleteCampaignList?.isPending}
      />
      <ShareLinkCampaignModal
        isOpen={isShareModalOpen}
        onClose={() => {
          setIsShareModalOpen(false);
        }}
      />
      <DeleteDraftCampaignModal
        isOpen={isDraftModalOpen}
        onClose={() => {
          setIsDraftModalOpen(false);
        }}
      />
      <CampaignIsStillUnderProcess
        isOpen={isCampaignIsStillUnderProcessModalOpen}
        onClose={() => {
          setIsCampaignIsStillUnderProcessModalOpen(false);
        }}
        setIsCampaignIsStillUnderProcessModalOpen={
          setIsCampaignIsStillUnderProcessModalOpen
        }
      />
    </Layout>
  );
};

export default Campaigns;
