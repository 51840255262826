import domainIcon from "assets/images/domain.svg";
import deleteIcon from "assets/images/domain-trash.svg";
import OutlinedInput from "components/outlinedInput";
import blockIcon from "assets/images/block-emoji.svg";
import { toast } from "react-toastify";
import { useDeleteBlockDomain } from "services/settings-api";

const BlockListDomainListForm = ({blocklistDomainList,getBlockDomains}) => {

  const deleteDomain = useDeleteBlockDomain();
  const removeDomain = async (id) => {
    try {
      const payload = { block_id: id };
      await deleteDomain.mutateAsync(payload);
      getBlockDomains();
      toast.success("Blocklist Updated. Domain was removed.", {
        icon: () => <img src={blockIcon} alt="blockIcon" />,
      });
    } catch (error) {
      console.error("Error deleting domain:", error);
    }
  };

  return (
    <div className="flex items-center flex-wrap max-w-[1250px]">
      {blocklistDomainList.map((domain, index) => (
        <div className="relative flex items-center w-[370px] mb-[12px] mr-[33px]" key={domain.block_id}>
          <div className="!w-[320px] relative mr-[18px]">
            <OutlinedInput
              type="text"
              value={domain.block_domain}
              readOnly 
              className={`relative pr-[40px] pl-[40px] py-[8px] !w-[320px] h-[36px] !text-[#3E3E3E] !text-[14px]`}
            />
          </div>
          <img
            src={domainIcon}
            alt="domain icon"
            className="w-[20px] h-[20px] absolute left-[12px] top-[8px]"
          />
          <img
            src={deleteIcon}
            alt="delete"
            onClick={() => removeDomain(domain.block_id)}
            className="w-[20px] h-[20px] min-w-[20px] mt-[-2px] cursor-pointer"
          />
        </div>
      ))}
    </div>
  );
};

export default BlockListDomainListForm;
