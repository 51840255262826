import "./App.css";
import { RouterProvider } from "react-router-dom";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { AppContextProvider } from "context/AppContext";
import Toaster from "components/toaster";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { router } from "routes";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GOOGLE_KEY, PYLON_APP_ID } from "constant";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 600,
    });
  }, []);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  useEffect(() => {
    window.pylon = {
      chat_settings: {
        app_id: PYLON_APP_ID,
        email: "guestuser@test.com",
        name: "Guest User",
      },
    };
  }, []);

  return (
    <div className="relative h-[100vh] overflow-hidden">
      <QueryClientProvider client={queryClient}>
        <GoogleOAuthProvider clientId={GOOGLE_KEY}>
          <AppContextProvider>
            <RouterProvider router={router} />
            <Toaster />
          </AppContextProvider>
        </GoogleOAuthProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
