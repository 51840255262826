const Tabs = ({
  tab,
  setTab,
  tabOptions,
  isNewCampaignScreen = false,
  settingTab = false,
  isNewCampaignTabs = false,
  completedTabs = null,
}) => {
  const handleCampaignTabNavigation = (targetTab) => {
    if (completedTabs?.includes(targetTab)) {
      setTab(targetTab);
    }
  };

  return (
    <div className="text-sm font-medium text-center text-[#6B7280]">
      <ul className="flex flex-wrap">
        {tabOptions?.map((item, index) => {
          const isActive = item?.label === tab;
          const isCompleted = completedTabs?.includes(item?.label);
          const isDisabled =
             (!isCompleted && isNewCampaignTabs);
          return (
            <li className={`me-1 ${settingTab && "mr-[8px]"}`} key={index}>
              <span
                onClick={() => {
                  if (isDisabled) return;
                  if (isNewCampaignTabs) {
                    handleCampaignTabNavigation(item.label);
                  } else {
                    setTab?.(item.label);
                  }
                }}
                className={`inline-block px-[10px] py-[4px] mr-4 text-[13.5px] rounded-[6px] font-inter ${
                  isActive || (isActive && isDisabled)
                    ? " bg-[#EAE3FD] !text-[#4338CA] hover:!text-[#4338CA]"
                    : ""
                } 
                ${
                  isNewCampaignScreen
                    ? `!font-[400] text-[#252C32] px-[16px]`
                    : `font-[400] text-[#252C32]  ${
                        !isCompleted && isNewCampaignTabs
                          ? ""
                          : "hover:text-[#252C32]"
                      } px-[10px]`
                }
                ${
                  isActive &&
                  isNewCampaignTabs &&
                  "bg-[#EAE3FD] !text-[#6134E9] hover:!text-[#6134E9] font-[600]"
                }
                ${
                  !isCompleted && isNewCampaignTabs
                    ? "text-[#BBBBBB]"
                    : "cursor-pointer"
                }
                ${isDisabled && "!text-[#CBCCCC] cursor-not-allowed"}
               `}
              >
                {item?.label}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Tabs;
