import { IoClose } from "react-icons/io5";
import Button from "components/button";
import deleteIcon from "assets/images/delete-modal.svg";
import deleteImg from "assets/images/sad.svg";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const SubscriptionWasCancelled = (props) => {
  const { isOpen } = props;
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [isOpen]);

  if (!isOpen && !isModalVisible) return null;

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[300ms] ease-in-out ${
        isModalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div className="fixed inset-0 bg-black opacity-10"></div>
      <div
        className={`bg-white p-[24px] rounded-lg modal-shadow z-10 w-full max-w-[600px] relative transition-all ease-in-out duration-[150ms] ${
          isModalVisible ? "scale-100" : "scale-95"
        }`}
      >
        <div className="modal-scroll">
          <div className="modal-scroll">
            <div className="flex flex-col items-start justify-start mb-[20px] font-inter">
              <div className="flex items-center justify-center mb-[16px]">
                <img
                  src={deleteImg}
                  alt="info img"
                  className="h-[48px] w-[48px]"
                />
              </div>
              <h4 className="font-[600] text-[18px] text-[#101828] m-[4px] ml-0 font-inter">
                Your Subscription was cancelled.
              </h4>
            </div>
            <div
              onClick={() => {
                navigate("/settings?tab=Billing");
              }}
              className="flex items-center justify-center mt-[32px]"
            >
              <Button
                title="Re-activate your Account"
                secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9]"
                className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow !rounded-[8px]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionWasCancelled;
