import { IoClose } from "react-icons/io5";
import Button from "components/button";
import checkBg from "assets/images/check-bg.svg";
import { useEffect, useState } from "react";
import connectIcon from "assets/images/captcha.svg";
import Select from "react-select";
import OTPInput from "otp-input-react";
import { toast } from "react-toastify";
import AnimatedButton from "components/animatedButton";
import LetterEmoji from "assets/images/loveletter.png";
import Phone from "assets/images/mobile.svg";

import { useLinkedinCallback } from "services/auth-api";

const ReconnectOtpModal = ({
  isOpen,
  onClose,
  handleResendCode,
  setIsLinkedinAccountConnected,
  handleGetLinkedInAccountDetails,
  accountId,
  startVerificationInterval,
  setLikedInData,
  setLoader,
  setOpenConnectModalSteps,
  OTP,
  setOTP,
  handleVerificationSubmit,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const linkedInCallback = useLinkedinCallback();

  const handleCallback = async (OTP) => {
    console.log("In Handle Callback", OTP)
    setLoader(true);
    try {
      const response = await linkedInCallback.mutateAsync({
        account_id: String(accountId),
        code: OTP,
      });

      if (response?.status === 201) {
        if (response?.message === "AccountCreated") {
          startVerificationInterval(String(accountId));
        }
      }

      if (response?.status === 202) {
        if (response?.checkpoint?.type === "2FA") {
          setLikedInData(response);
          setLoader(false);
          setOpenConnectModalSteps(7);
        }
      } else if (response?.status === 400) {
        setLoader(false);
        toast.error(response?.message);
      }
    } catch (error) {
      setLoader(false);
      setOpenConnectModalSteps(0);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [isOpen]);
  if (!isOpen && !isModalVisible) return null;

  return (
    <>
      <div
        className={`fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[300ms] ease-in-out ${
          isModalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
      >
        <div
          className="fixed inset-0 bg-black opacity-10"
          onClick={onClose}
        ></div>
        <div
          className={`p-[24px] bg-white rounded-lg shadow-lg z-10 w-full max-w-[556px] relative transition-all ease-in-out duration-[150ms] ${
            isModalVisible ? "scale-100" : "scale-95"
          }`}
        >
          <IoClose
            className="text-[#667085] text-[20px] w-[24px] h-[24px] absolute right-[24px] top-[27px] cursor-pointer"
            onClick={onClose}
          />
          <div className="modal-scroll">
            <>
              <div className="w-[48px] h-[48px] rounded-[13.9px] flex items-center justify-center border border-[#E4E7EC]">
                <img
                  src={Phone}
                  alt="Logo"
                  className="h-[24px] w-[24px] block"
                  style={{
                    filter: "brightness(1.2)",
                  }}
                />
              </div>
              <h1 className="text-[16px] text-[#303030] font-[600] mt-[22px] mb-[5px]">
                Enter the Code
              </h1>
              <p className="text-[14px] font-[400] text-[#475467] leading-[24px]">
                Enter the code sent to your email or phone by LinkedIn.{" "}
              </p>
              <form
                className="mt-[22px] w-full"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="mb-[16px] flex items-center justify-center mr-[-20px]">
                  <OTPInput
                    value={OTP}
                    onChange={setOTP}
                    autoFocus
                    OTPLength={6}
                    otpType="number"
                    inputClassName="otpInput custom-shadow !h-[56px] !px-[2px] !w-[40px] text-[28px] border border-[#E4E7EC] rounded-[6px] bg-transparent text-[#000] !outline-none focus:!border-[#8A43FF] focus:!border-[2px]"
                  />
                </div>
                <p className="text-[14px] m-0 font-[400] text-center text-[#475467] leading-[24px]">
                  Didn’t receive the code?
                </p>
                <p
                  className="mt-[11.12px] text-[#6941C6] font-[600] text-[14px] text-center cursor-pointer"
                  onClick={() => handleResendCode("verification_code")}
                >
                  Click to resend
                </p>
                <div className="flex items-center justify-center mt-[20.85px] w-full">
                  {OTP?.length >= 6 ? (
                    <AnimatedButton
                      title="Next"
                      onClick={() => handleCallback(OTP)}
                      disabled={OTP.length !== 6}
                      className="!text-[16px] !font-[600] !h-[44px] !rounded-[7px] !bg-[#7F56D9] w-full"
                    />
                  ) : (
                    <Button
                      isWhiteBtn={true}
                      title="Cancel"
                      secondaryColor=""
                      className="!text-[16px] text-[#344054] !h-[44px] w-full !font-[600] y-axis-shadow !rounded-[8px]"
                      onClick={() => {
                        handleGetLinkedInAccountDetails();
                        setIsLinkedinAccountConnected(true);
                        onClose();
                      }}
                    />
                  )}
                </div>
              </form>
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReconnectOtpModal;
