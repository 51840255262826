import deleteImg from "assets/images/speaker.svg";

const BillingFailureTeamMember = (props) => {
  const { isOpen, onClose } = props;
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-[99] font-inter">
      <div
        className="fixed inset-0 bg-black opacity-10"
      ></div>
      <div className="bg-white p-[24px] rounded-lg modal-shadow z-10 w-full max-w-[400px] relative">
        <div className="modal-scroll">
          <div className="modal-scroll">
            <div className="flex flex-col items-start justify-start mb-[20px] font-inter">
              <div className="flex items-center justify-center mb-[16px]">
                <img
                  src={deleteImg}
                  alt="info img"
                  className="h-[48px] w-[48px]"
                />
              </div>
              <h4 className="font-[600] text-[18px] text-[#101828] m-[4px] ml-0 font-inter">
                Failed to Access
              </h4>
              <p className="font-normal text-[14px] text-[#475467] font-inter mt-[11px]">
                Please contact your Workspace administrator.
              </p>
              <p className="text-[14px] text-[#475467] font-[500] font-inter mt-[23px] italic">
                Your campaigns have been paused and you are unable to access
                your workspace.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingFailureTeamMember;
