const ProspectingTableSkeleton = () => {
  return (
    <div className="w-full border border-gray-200 rounded-md overflow-hidden">
      {/* Header Skeleton */}
      <div className="flex items-center py-3 bg-white box-border">
        {Array(5)
          .fill(0)
          .map((_, index) => (
            <div key={index} className={`px-4 ${index === 0 ? "w-[32%]" : "w-[20%]"}`}>
              <div className={`h-[25px] animate-bg-pulse w-1/2 rounded-sm ${index === 0 && "w-[70%]"}`}></div>
            </div>
          ))}
      </div>
      {/* Skeleton Rows */}
      {Array(10)
        .fill(0)
        .map((_, index) => (
          <div key={index} className="flex items-center py-4 border-t border-gray-200 w-full box-border">
            <div className="flex items-center px-4 w-[32%]">
              <div className="h-[20px] rounded-sm w-[20px] animate-bg-pulse mr-4"></div>
              <div className="h-[20px] rounded-sm w-[60%] animate-bg-pulse"></div>
            </div>
            <div className="px-4 w-[20%]">
              <div className="h-[20px] rounded-sm w-1/2 animate-bg-pulse"></div>
            </div>
            <div className="px-4 w-[20%]">
              <div className="h-[30px] w-[30px] rounded-full animate-bg-pulse"></div>
            </div>
            <div className="px-4 w-[20%]">
              <div className="h-[20px] rounded w-1/2 animate-bg-pulse"></div>
            </div>
            <div className="px-4 w-[20%] flex items-center justify-between">
              <div className="h-[20px] rounded-sm w-[70%] animate-bg-pulse"></div>
              <div className="h-[10px] rounded-sm w-[30px] animate-bg-pulse mr-4"></div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default ProspectingTableSkeleton;
