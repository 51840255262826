import DatePickers from "components/datePicker";
import ReactSelect from "components/select";
import moment from "moment";
import { TiMinus } from "react-icons/ti";

const FundingAmount = (props) => {
  const {
    options,
    name,
    placeholder,
    formData,
    setFormData,
    handleNegativeNumbers,
  } = props;

  const isValidFunding =
    Number(formData?.["minFunding"]) >= Number(formData?.["maxFunding"]) &&
    formData?.["minFunding"] &&
    formData?.["maxFunding"];
  const isInvalidDateRange =
    formData?.["startDate"] &&
    formData?.["endDate"] &&
    new Date(formData?.["startDate"]) > new Date(formData?.["endDate"]);
  const onDatePicker = (event, name) => {
    const date = new Date(event);
    const year = date.getFullYear();

    if (moment(date).isValid() && year > 999) {
      setFormData({
        ...formData,
        [name]: event,
      });
    }
  };
  const onSelectChange = (event, keyName) => {
    if (["dateFounded"]?.includes(name)) {
      setFormData({
        ...formData,
        [keyName]: event,
      });
    } else {
      setFormData({
        ...formData,
        [name]: {
          values: [...event],
        },
      });
    }
  };

  if (isInvalidDateRange) {
    setFormData({
      ...formData,
      ["endDate"]: "",
    });
  }

  const onInputChange = (event) => {
    let { name, value } = event?.target;
    value = value.replace(/^0+(?=\d)/, "");
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  return (
    <>
      <div className="mb-[15px] custom-react-select">
        <ReactSelect
          placeholder={placeholder}
          onChange={onSelectChange}
          options={options}
          value={formData?.[name]?.values || []}
          isMulti={true}
          hideMultiSelectValues
        />
      </div>
      <div className="mb-[15px]">
        <DatePickers
          placeholder={"Start Date of Funding"}
          onChange={(e) => {
            onDatePicker(e, "startDate");
          }}
          id="datepicker-autohide"
          name="startDate"
          value={formData?.["startDate"] || null}
          maxDate={formData?.["endDate"] || null}
        />
      </div>
      <div>
        <DatePickers
          placeholder={"End Date of Funding"}
          onChange={(e) => onDatePicker(e, "endDate")}
          id="datepicker-autohide-1"
          name="endDate"
          value={formData?.["endDate"] || null}
          minDate={formData?.["startDate"] || null}
        />
      </div>
      <div className="mt-[15px]">
        <p className="text-[#344054] font-[500] text-[14px] mb-[11px]">
          Custom Range
        </p>
        <div className="flex items-center">
          <input
            type="number"
            placeholder="min"
            className="text-[14px] font-[400] pb-[4px] text-[#1E1E1ED4] placeholder:text-[#B2B4B8] border-[1px] border-[#D0D5DD] text-center py-[2px] px-[12px] w-[94px] outline-none focus:!border-[#D0D5DD] bg-[#fff] rounded-[8px] h-[34px] y-axis-shadow"
            onChange={onInputChange}
            name="minFunding"
            value={formData?.["minFunding"]}
            onKeyPress={handleNegativeNumbers}
            min={0}
          />
          <TiMinus className="text-[14px] text-[#8A43FF] mx-[6px]" />
          <input
            type="number"
            placeholder="max"
            className={`text-[14px] font-[400]  pb-[4px] text-[#1E1E1ED4] placeholder:text-[#B2B4B8] border-[1px] border-[#D0D5DD] text-center py-[2px] px-[12px] w-[94px] outline-none focus:!border-[#D0D5DD] bg-[#fff] rounded-[8px] h-[34px] y-axis-shadow ${
              isValidFunding && "!border-[red]"
            }`}
            onChange={onInputChange}
            name="maxFunding"
            value={formData?.["maxFunding"]}
            onKeyPress={handleNegativeNumbers}
            min={1}
          />
        </div>
        {isValidFunding && (
          <p className="text-[11px] mt-[8px] text-[red]">
            Max value must be greater than min value.
          </p>
        )}
      </div>
    </>
  );
};

export default FundingAmount;
