import Layout from "components/layout";
import Button from "components/button";
import Tabs from "components/tabs";
import BreadcrumbHeader from "components/breadcrumbHeader";
import { useContext, useEffect, useState } from "react";
import { purchaseCreditInfo, settingPageTabs } from "constant";
import PaymentMethodForm from "components/setting/paymentMethodForm";
import BillingTable from "components/setting/billingTable";
import ProfileForm from "components/setting/profileForm";
import { AppContext, AppContextProvider } from "context/AppContext";
import { toast } from "react-toastify";
import Tick from "assets/images/name-check.svg";
import { useGetWorkspaceSettings } from "services/settings-api";
import CollaboratorsTable from "components/setting/collaborators/collaboraterTable";
import DeleteCollaboratorModal from "components/setting/collaborators/deleteCollaboratersModal";
import NotificationsSettings from "components/setting/notificationSettings";
import { MdOutlineAdd } from "react-icons/md";
import DontAccess from "components/dontAccess";
import coins from "assets/images/coins-stacked.svg";
import NotificationToggleModal from "components/setting/notificationToggleModal";
import Heart from "assets/images/dil-pruple.png";
import EndSubscription from "components/subscriptionModals/endSubscription";
import SubscriptionCancelled from "components/subscriptionModals/subscriptionCancelled";
import AddEnrichCreditOptions from "components/setting/purchaseModals/addEnrichCreditOptions";
import AddedPurchasedCredit from "components/setting/purchaseModals/addedPurchaseCredit";
import PurchasedSuccessModal from "components/setting/purchaseModals/purchaseSuccessfulModal";
import AddTeamSeat from "components/setting/addCollaborateTeamModals/addTeamSeats";
import SentInvitationModal from "components/setting/addCollaborateTeamModals/sentInvitationModal";
import PurchasedSuccessfulTeamModal from "components/setting/addCollaborateTeamModals/purchasedSuccessfulTeamModal";
import hubSpotIcon from "assets/images/Hubspot.svg";
import SwitchToggle from "components/switchToggle";
import BellIcon from "assets/images/bellIcon.png";
import ConnectHubSpot from "components/integrationComponent/Modals/connectHubSpot";
import IntegrationSettings from "components/integrationComponent/integrationSettings";
import importData from "assets/images/import.svg";
import SettingUploadCsvModal from "components/setting/blocklist/settingUploadCsvModal";
import SettingDataMapping from "components/setting/blocklist/settingDataMappingModal";
import SettingUploadFailed from "components/setting/blocklist/settingUploadFailedModal";
import SettingDataNotMapping from "components/setting/blocklist/settingDataNotMappedModal";
import SettingDomainAnimationModal from "components/setting/blocklist/settingDomainAnimationModal";
import BlockListSuccessModal from "components/setting/blocklist/blocklistSuccessModal";
import SettingManualBlocklist from "components/setting/blocklist/settingManualBlocklist";
import BlockListDomainListForm from "components/setting/blocklist/blocklistDomainsListForm";
import RequestIntegrationModal from "components/setting/integration/requestIntegrationModal";
import IntegrationSuccessModal from "components/setting/integration/integrationSuccessModal";
import UpgradeTeamMemberModal from "components/setting/collaborators/upgradeTeamMemberModal";
import UpgradeTeamSuccessModal from "components/setting/collaborators/upgradeTeamSuccessModal";
import { useAccountStore, useAuthStore, useProspectStore } from "stores";
import { useGetCollaborators } from "services/prospecting-api";
import Loader from "components/loader";
import {
  useAddCollaborators,
  useDeleteCollaborator,
  useDomainCsvUpload,
  useGetBlockDomain,
  useSwapAdminRole,
} from "services/settings-api";
import {
  useCancelSubscription,
  useReActivateSubscription,
} from "services/payments-api";
import useSettingStore from "stores/settingStore";
import UpdatePassword from "components/setting/updatePasswordModal";
import EditEmailModal from "components/setting/collaborators/editCollaboraterEmail";
import { useLocation } from "react-router-dom";
import ReactivateSubscriptionModal from "components/subscriptionModals/ReactivateSubscriptionModal";
import ReconnectBanner from "components/reconnectBanner";
import { useAmpersandInstall } from "services/hubspot-api";
import {
  HUBSPOTAPIKEY,
  HUBSPOTINTEGRATIONID,
  HUBSPOTPROJECTID,
} from "constant/hubspot";

const Settings = () => {
  const location = useLocation();
  const { appState } = useContext(AppContext);
  const getCollaborators = useGetCollaborators();
  const addCollaborators = useAddCollaborators();
  const cancelSubscription = useCancelSubscription();
  const swapToAdmin = useSwapAdminRole();
  const { linkedinAccountDetails } = useAccountStore();

  const { setSubscriptionEndDate, subscriptionEndDate, userData, setUserData } =
    useSettingStore();
  const {
    isSubscriptionCancelled,
    setIsSubscriptionCancelled,
    isAdmin,
    companyId,
  } = useAuthStore();

  const [tab, setTab] = useState("Profile");
  const [isRequestIntegrationModal, setIsRequestIntegrationModal] =
    useState(false);
  const [isIntegrationSuccessModal, setIsIntegrationSuccessModal] =
    useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [
    isReactivateSubscriptionModalOpen,
    setIsReactivateSubscriptionModalOpen,
  ] = useState(false);
  const [currentClickedUser, setCurrentClickedUser] = useState(null);
  const [openEndSubscriptionModal, setOpenEndSubscriptionModal] =
    useState(false);
  const [openSubscriptionCancelledModal, setOpenSubscriptionModal] =
    useState(false);
  const [localEmail, setLocalEmail] = useState("");
  const [isNotificationModal, setIsNotificationModal] = useState(false);
  const [isPurchaseCreditModal, setIsPurchaseCreditModal] = useState(false);
  const [storePurchaseCredit, setStorePurchaseCredit] = useState(
    purchaseCreditInfo[0]
  );
  const [isStoredPurchaseModal, setIsStoredPurchaseModal] = useState(false);
  const [purchaseSuccessfulModal, setPurchaseSuccessfulModal] = useState(false);
  const [addCollaborateTeamModal, setAddCollaborateTeamModal] = useState(false);
  const [sentInvitationModal, setSentInvitationModal] = useState(false);
  const [teamPurchaseSuccessfulModal, setTeamPurchaseSuccessfulModal] =
    useState(false);
  const [isUpdatePasswordModal, setIsUpdatePasswordModal] = useState(false);
  const [teamSeatsNumber, setTeamSeatsNumber] = useState(1);
  const [manageSeatsInput, setManageSeatsInput] = useState(
    Array(teamSeatsNumber).fill({ value: "" })
  );
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [showConnectHubSpot, setShowConnectHubSpot] = useState(false);
  const [showIntegrationSettings, setShowIntegrationSettings] = useState(false);
  const [isImportCsvSteps, setIsImportCsvSteps] = useState(1);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [isManualBlocklistSteps, setIsManualBlocklistSteps] = useState(1);
  const [isManualBlocklistStepsOpen, setIsManualBlocklistStepsOpen] =
    useState(false);
  const [isUpgradeTeamModal, setIsUpgradeTeamModal] = useState(false);
  const [isUpgradedTeamSuccessModal, setIsUpgradedTeamSuccessModal] =
    useState(false);
  const [adminData, setAdminData] = useState();
  const [collaboratorsData, setCollaboratorsData] = useState([]);
  const [editEmailModal, setEditEmailModal] = useState(false);
  const [editEmail, setEditEmail] = useState();
  const [selectedPackage, setSelectedPackage] = useState(purchaseCreditInfo[0]);
  const [packageName, setPackageName] = useState("package_1");
  const [creditsAdded, setCreditsAdded] = useState(0);
  const [blockListResponse, setBlockListResponse] = useState("");
  const reActivatedSubscription = useReActivateSubscription();
  const deleteCollaborator = useDeleteCollaborator();
  const [blocklistDomainList, setBlocklistDomainList] = useState([]);
  const getDomains = useGetBlockDomain();
  const csvBlockDomain = useDomainCsvUpload();
  const { fileId } = useProspectStore();
  const [connectionId, setConnectionId] = useState(null);
  const ampersandInstall = useAmpersandInstall();
  const getSettings = useGetWorkspaceSettings();

  useEffect(() => {
    getBlockDomains();
  }, []);

  useEffect(() => {
    setManageSeatsInput(Array(teamSeatsNumber).fill({ value: "" }));
  }, [teamSeatsNumber]);

  useEffect(() => {
    setManageSeatsInput(Array(teamSeatsNumber).fill({ value: "" }));
  }, [teamSeatsNumber]);

  useEffect(() => {
    if (location?.search) {
      const tab = location?.search?.split("?tab=")?.filter(Boolean)?.[0];
      setTab(tab);
    }
  }, [location]);

  useEffect(() => {
    if (
      tab !== "Billing" &&
      appState?.alternativeEmail &&
      localEmail === appState?.alternativeEmail
    ) {
      setLocalEmail("");
      toast.success("Email Updated to Receive Invoices", {
        icon: () => <img src={Tick} alt="Tick" />,
      });
    }
  }, [tab]);

  // open import csv SettingDomainAnimationModal
  useEffect(() => {
    if (isImportCsvSteps === 5) {
      const timer = setTimeout(() => {
        blockDomains();
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isImportCsvSteps]);

  // open SettingDomainAnimationModal in the case of manually add domain
  useEffect(() => {
    if (isManualBlocklistSteps === 2) {
      const timer = setTimeout(() => {
        setIsManualBlocklistSteps(3);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isManualBlocklistSteps]);

  useEffect(() => {
    handleGetCollaborators();
  }, []);

  const getBlockDomains = async () => {
    try {
      const response = await getDomains.mutateAsync();
      setBlocklistDomainList(response);
    } catch (error) {
      toast.success("Something went wrong");
    }
  };

  const handleDeleteCollaborator = async (id) => {
    try {
      const data = {
        collaborator_id: id,
      };
      await deleteCollaborator.mutateAsync({ data });
      handleGetCollaborators();
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddCollaborator = async () => {
    try {
      const payload = {
        team_members: manageSeatsInput?.map((i) => i?.value),
      };
      const response = await addCollaborators?.mutateAsync(payload);
      setSentInvitationModal(false);
      setTeamPurchaseSuccessfulModal(true);
      handleGetCollaborators();
    } catch (error) {
      toast.success(error?.data?.detail);
    }
  };

  const handleEmailToggle = () => {
    // Show toast notification on update
    toast.success("Notification Preference Updated", {
      icon: () => <img src={BellIcon} alt="name" />,
    });
  };

  const handleConnectClick = () => {
    if (!isSwitchOn) {
      setShowConnectHubSpot(true);
    } else {
      setIsSwitchOn(false);
      setShowIntegrationSettings(false);
    }
  };

  const handleCloseConnectHubSpot = () => {
    setShowConnectHubSpot(false);
  };

  const onInstallations = async (connection_id) => {
    const integrationId = HUBSPOTINTEGRATIONID;
    const hubspotProjectId = HUBSPOTPROJECTID;
    // const hubspotProjectId= process.env.REACT_APP_HUBSPOT_PROJECT_ID
    const hubspotAPIKey = HUBSPOTAPIKEY;
    console.log(companyId.toString(), "companyId.toString()");
    const body = {
      groupRef: companyId.toString(),
      connectionId: connection_id,
      config: {
        content: {
          provider: "hubspot",
          read: {
            objects: {
              contacts: {
                objectName: "contacts",
                schedule: "*/10 * * * *", // Fixed the schedule syntax
                destination: "contactWebhook",
                selectedFields: {
                  firstname: true,
                  lastname: true,
                  lifecyclestage: true,
                },
              },
            },
          },
          write: {
            objects: {
              contacts: { objectName: "contacts" },
              leads: { objectName: "leads" },
              deals: { objectName: "deals" },
              companies: { objectName: "companies" },
            },
          },
          proxy: { enabled: true },
        },
      },
    };

    const headers = {
      "Content-Type": "application/json",
      "X-Api-Key": hubspotAPIKey,
    };

    try {
      const response = await fetch(
        `https://api.withampersand.com/v1/projects/${hubspotProjectId}/integrations/${integrationId}/installations`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(body),
        }
      );

      const data = await response.json(); // Or .text(), etc.
      if (data?.id) {
        return data;
      } else if (data?.detail) {
        toast.error(data?.detail || "An Unknown error occured.");
      }
    } catch (error) {
      // onInstallations(connection_id);
      console.error("Error in onConnectSuccess:", error);
    }
  };
  const handleConnectSuccess = async (connection_id) => {
    const hubspot = await onInstallations(connection_id);
    try {
      let payload = {
        company_id: companyId,
        ampersand_install_id:
          hubspot?.config?.installationId || userData?.company?.ampersand_id,
      };
      const result = await ampersandInstall.mutateAsync(payload);
      if (result?.status === 200) {
        setShowConnectHubSpot(false);
        setShowIntegrationSettings(true);
        getUserProfile();
      }
    } catch (error) {
      console.log(error, "ampersandInstall error");
    }
  };

  const getUserProfile = async () => {
    try {
      const response = await getSettings.mutateAsync();
      const responseMain = {
        ...response,
        user: {
          ...response?.user,
          profile_pic: userData?.profile_pic,
        },
        company: {
          ...response?.company,
          // localTimezone: getTimezoneObject(response?.company?.timezone)?.value,
        },
      };
      setUserData({ ...userData, ...responseMain });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  // api for data column
  const blockDomains = async () => {
    try {
      const res = await csvBlockDomain?.mutateAsync({
        file_id: fileId,
      });
      if (res) {
        setTimeout(() => {
          setIsImportCsvSteps(6);
        }, 3000);
      }
    } catch (error) {}
  };

  // steps for import csv modals
  const renderCsvStepComponent = () => {
    switch (isImportCsvSteps) {
      case 1:
        return (
          <SettingUploadCsvModal
            isOpen={true}
            onClose={() => {
              setIsImportCsvSteps(0);
            }}
            setStep={setIsImportCsvSteps}
          />
        );

      case 2:
        return (
          <SettingUploadFailed
            isOpen={true}
            onClose={() => setIsImportCsvSteps(0)}
            setStep={setIsImportCsvSteps}
          />
        );
      case 3:
        return (
          <SettingDataMapping
            isOpen={true}
            onClose={() => setIsImportCsvSteps(0)}
            setStep={setIsImportCsvSteps}
          />
        );
      case 4:
        return (
          <SettingDataNotMapping
            isOpen={true}
            onClose={() => setIsImportCsvSteps(0)}
            setStep={setIsImportCsvSteps}
          />
        );
      case 5:
        return (
          <SettingDomainAnimationModal
            isOpen={true}
            onClose={() => setIsImportCsvSteps(0)}
            setStep={setIsImportCsvSteps}
          />
        );
      case 6:
        return (
          <BlockListSuccessModal
            isOpen={true}
            onClose={() => {
              setIsImportCsvSteps(0);
              getBlockDomains();
            }}
          />
        );
      default:
        return (
          <SettingUploadCsvModal
            isOpen={true}
            onClose={() => {
              setIsImportCsvSteps(0);
            }}
            setStep={setIsImportCsvSteps}
          />
        );
    }
  };

  // steps for manually add domain modals
  const renderManualBlocklistSteps = () => {
    switch (isManualBlocklistSteps) {
      case 1:
        return (
          <SettingManualBlocklist
            isOpen={true}
            setStep={setIsManualBlocklistSteps}
            onClose={() => setIsManualBlocklistSteps(0)}
            setBlockListResponse={setBlockListResponse}
            getBlockDomains={getBlockDomains}
          />
        );
      case 2:
        return (
          <SettingDomainAnimationModal
            isOpen={true}
            onClose={() => setIsManualBlocklistSteps(0)}
            setStep={setIsManualBlocklistSteps}
          />
        );
      case 3:
        return (
          <BlockListSuccessModal
            isOpen={true}
            onClose={() => setIsManualBlocklistSteps(0)}
            isManualSuccessModal={true}
          />
        );
      default:
        <SettingManualBlocklist
          isOpen={true}
          setStep={setIsManualBlocklistSteps}
          onClose={() => setIsManualBlocklistSteps(0)}
          setBlockListResponse={setBlockListResponse}
          getBlockDomains={getBlockDomains}
        />;
    }
  };

  const handleGetCollaborators = async () => {
    try {
      const response = await getCollaborators.mutateAsync();
      setAdminData(response?.admin);
      setCollaboratorsData(response?.team_members);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSwapToAdminRole = async () => {
    try {
      const payload = {
        collaborator_id: currentClickedUser?.collaborator_id,
      };
      await swapToAdmin?.mutateAsync(payload);
      setIsUpgradeTeamModal(false);
      setIsUpgradedTeamSuccessModal(false);
      handleGetCollaborators();
    } catch (error) {
      toast.error(error?.data?.detail);
    }
  };

  const onEndSubscription = async () => {
    try {
      const result = await cancelSubscription?.mutateAsync();
      if (result?.subscription_id) {
        setOpenEndSubscriptionModal(false);
        setOpenSubscriptionModal(true);
        setSubscriptionEndDate(result?.subscription_end);
        toast.success(result?.message);
      }
    } catch (error) {
      setOpenEndSubscriptionModal(false);
      toast.error(
        error?.data?.detail ||
          error?.data?.response?.detail ||
          "Something went wrong!"
      );
    }
  };

  const onReactivateSubscription = async () => {
    try {
      const result = await reActivatedSubscription?.mutateAsync();
      if (result?.subscription_id) {
        toast.success("Pipeline Subscription Resumed!", {
          icon: () => <img src={Heart} alt="name" />,
        });
        setIsSubscriptionCancelled(false);
        setSubscriptionEndDate(null);
        setIsReactivateSubscriptionModalOpen(false);
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to reactivate subscription");
    }
  };

  return (
    <Layout>
      <div className="h-[100vh] flex flex-col justify-start items-start font-inter">
        <BreadcrumbHeader
          breadcrumbItems={[
            { title: "Settings", route: "/Settings" },
            { title: "All" },
          ]}
        />
        <ReconnectBanner />
        <div
          className="max-w-[100%] w-full pt-[18px] pb-[10px] h-full"
          data-aos="fade-left"
        >
          <div className="px-[31px] relative">
            <div className="flex items-center justify-between w-full relative">
              <h1 className="text-[32px] text-left text-[#252C32] font-semibold leading-normal">
                Settings
              </h1>
              {tab === "Billing" && isAdmin && (
                <div className="w-full flex items-center justify-end mr-5">
                  <Button
                    isFullWidth={false}
                    secondaryColor="!border-[#6366F1] !h-[40px] !bg-[#6366F1] hover:!text-[#6366F1] hover:!bg-transparent"
                    className={`!rounded-[8px] ${
                      isSubscriptionCancelled && "!w-[194px] !text-[14px]"
                    } `}
                    title={
                      isSubscriptionCancelled
                        ? "Re-activate Subscription"
                        : "End Subscription"
                    }
                    height="40px"
                    onClick={() => {
                      if (isSubscriptionCancelled) {
                        setIsReactivateSubscriptionModalOpen(true);
                      } else {
                        setOpenEndSubscriptionModal(true);
                      }
                    }}
                  />
                </div>
              )}
              {isAdmin ? (
                <div className="flex items-center justify-end">
                  <Button
                    isFullWidth={false}
                    secondaryColor="!border-[#7F56D9] !bg-[#fff] !text-[#7F56D9] hover:!bg-[#7F56D9] hover:!text-[#fff]"
                    height="40px"
                    className="!rounded-[8px] !pr-[8px] !h-[40px] !w-[211px] mr-[17px] purchase-button"
                    title={
                      <div className="flex items-center justify-center font-inter !text-[14px] !font-[600]">
                        <img
                          src={coins}
                          alt="coins"
                          className="mr-[5px] w-[20px] h-[20px]"
                        />
                        Purchase More Credits
                      </div>
                    }
                    onClick={() => setIsPurchaseCreditModal(true)}
                  />
                  <Button
                    isFullWidth={false}
                    gradientBg={true}
                    height="40px"
                    className="!rounded-[8px] !pr-[8px] !h-[40px] !w-[181px]"
                    title={
                      <div className="flex items-center justify-center font-inter !text-[14px] !font-[600]">
                        <MdOutlineAdd className="text-[20px] mr-[7px] mt-[1px]" />
                        Add team member
                      </div>
                    }
                    onClick={() => setAddCollaborateTeamModal(true)}
                  />
                </div>
              ) : null}
            </div>

            <div className=" mt-[17px] mb-[24px]">
              <Tabs
                tab={tab}
                setTab={setTab}
                tabOptions={settingPageTabs}
                settingTab={true}
              />
            </div>
            {isSubscriptionCancelled && tab === "Billing" && (
              <p className="absolute top-[50px] right-[30px] text-[#D92D20] font-[700] text-[14px]">
                Subscription will cancel {subscriptionEndDate}
              </p>
            )}
          </div>
          <div
            className={`mt-5 overflow-auto innerScrollBar ${
              linkedinAccountDetails?.reconnect_status
                ? "h-[calc(100vh_-260px)]"
                : "h-[calc(100vh_-195px)]"
            } ${tab === "Blocklist" && "!overflow-hidden"}`}
          >
            {/* Profile */}
            {tab === "Profile" && (
              <div className="px-[35px]">
                <div className="pb-[21px]">
                  <h4 className="font-[600] text-[#101828] text-[18px] mb-1">
                    Personal info
                  </h4>
                  <p className="font-[400] text-[#475467] text-[14px]">
                    Update your personal details here.
                  </p>
                </div>
                <ProfileForm
                  isUpdatePasswordModal={isUpdatePasswordModal}
                  setIsUpdatePasswordModal={setIsUpdatePasswordModal}
                />
              </div>
            )}
            {/* Billing */}
            {tab === "Billing" &&
              (isAdmin ? (
                <div className="px-[35px]">
                  <div className="pb-[20px] mb-[24px] border-b-[1px] border-[#E4E7EC] w-[90%]">
                    <h4 className="font-[600] text-[#101828] text-[18px] mb-1">
                      Payment method
                    </h4>
                    <p className="font-[400] text-[#475467] text-[14px]">
                      Update your billing details and address.
                    </p>
                  </div>
                  <div>
                    <PaymentMethodForm
                      localEmail={localEmail}
                      setLocalEmail={setLocalEmail}
                    />
                  </div>
                  <div className="flex items-center justify-between w-full mb-[24px]">
                    <h1 className="text-[18px] text-left text-[#101828] font-semibold leading-normal mb-[8px]">
                      Billing history
                    </h1>
                  </div>
                  <BillingTable />
                </div>
              ) : (
                <DontAccess />
              ))}
            {/* Collaborators */}
            {tab === "Collaborators" &&
              (getCollaborators?.isPending ? (
                <div className="h-[200px] flex items-center justify-center">
                  <Loader />
                </div>
              ) : (
                <div className="px-[35px]">
                  <div className="pb-[21px] border-b-[1px] border-[#E4E7EC]">
                    <h4 className="font-[600] text-[#101828] text-[18px] mb-1">
                      Team Members
                    </h4>
                    <p className="font-[400] text-[#475467] text-[14px]">
                      Manage your team members and their account permissions
                      here.
                    </p>
                  </div>
                  <div className="w-full">
                    <div className="pt-[24px] pb-[20px] flex items-start">
                      <div className="w-[280px] min-w-[280px] mr-[30px]">
                        <h6 className="font-[600] text-[#344054] text-[14px] mb-0 leading-[20px]">
                          Admin users
                        </h6>
                        <p className="text-[#475467] text-[14px] font-[400] leading-[20px]">
                          Admins can add and remove users, have billing access
                          and manage organization-level settings.
                        </p>
                      </div>
                      <div className="w-full">
                        <CollaboratorsTable
                          isDeleteModalOpen={isDeleteModalOpen}
                          setIsDeleteModalOpen={setIsDeleteModalOpen}
                          collaboratorsTableData={adminData}
                          isAdminTable={true}
                          setIsUpgradeTeamModal={setIsUpgradeTeamModal}
                        />
                      </div>
                    </div>
                    <div className="border-t-[1px] border-[#E4E7EC] pt-[24px] pb-[20px] flex items-start">
                      <div className="w-[280px] min-w-[280px] mr-[30px]">
                        <h6 className="font-[600] text-[#344054] text-[14px] mb-0 leading-[20px]">
                          Team Members (Users)
                        </h6>
                      </div>
                      <div className="w-full">
                        {collaboratorsData.length ? (
                          <CollaboratorsTable
                            isDeleteModalOpen={isDeleteModalOpen}
                            setIsDeleteModalOpen={setIsDeleteModalOpen}
                            collaboratorsTableData={collaboratorsData}
                            setCurrentClickedUser={setCurrentClickedUser}
                            isAdminTable={false}
                            setIsUpgradeTeamModal={setIsUpgradeTeamModal}
                            setEditEmailModal={setEditEmailModal}
                            setEditEmail={setEditEmail}
                          />
                        ) : (
                          <div>
                            <Button
                              isFullWidth={false}
                              secondaryColor="!border-[#4D51FD] !bg-[#6366F1] hover:!text-[#6366F1] hover:!bg-transparent"
                              height="40px"
                              className="!rounded-[8px] !pr-[8px] !h-[40px] !w-[181px]"
                              title={
                                <div className="flex items-center justify-center font-inter !text-[14px] !font-[600]">
                                  <MdOutlineAdd className="text-[20px] mr-[7px] mt-[1px]" />
                                  Add team member
                                </div>
                              }
                              onClick={() => setAddCollaborateTeamModal(true)}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            {/* Blocklist */}
            {tab === "Blocklist" && (
              <div className="px-[35px]">
                <div className="pb-[16px] border-b-[1px] border-[#E4E7EC] mb-[24px]">
                  <h4 className="font-[600] text-[#101828] text-[18px] mb-1">
                    Blocklist
                  </h4>
                  <p className="font-[400] text-[#475467] text-[14px] mb-[10px]">
                    By adding a company domain, Pipeline will block any leads
                    that are at these companies from being enrolled into a
                    campaign.
                  </p>
                </div>
                {/* <BlocklistCompanyDomainForm /> */}
                <div className="flex items-center w-full justify-start">
                  <Button
                    isWhiteBtn={true}
                    isFullWidth={false}
                    className="mr-[15px] y-axis-shadow !rounded-[8px] !p-[0px] !w-[121px]"
                    height="32px"
                    title={
                      <div className="flex items-center justify-center font-inter !font-[600] !text-[14px] !text-[#111827] !border-[#D1D5DB] mt-[-2.5px]">
                        <img
                          src={importData}
                          alt="import"
                          className="mr-[6px] w-[16px] h-[16px] mt-1"
                        />
                        <p className="pt-[3px]">Import CSV</p>
                      </div>
                    }
                    onClick={() => {
                      setIsImportCsvSteps(1);
                      setIsImportModalOpen(true);
                    }}
                  />
                  <Button
                    isFullWidth={false}
                    secondaryColor="!border-[#6366F1] !bg-[#6366F1] hover:!text-[#6366F1] hover:!bg-transparent"
                    height="32px"
                    className="!rounded-[8px] !p-[0px] !w-[144px]"
                    title={
                      <div className="flex items-center justify-center font-inter !text-[14px] !font-[600] mt-[-2.5px] pl-[2px]">
                        Manually Enter
                        <MdOutlineAdd className="text-[18px] ml-[4px] mt-[1px]" />
                      </div>
                    }
                    onClick={() => {
                      setIsManualBlocklistSteps(1);
                      setIsManualBlocklistStepsOpen(true);
                    }}
                  />
                </div>
                <div
                  className={`mt-[32px] modal-scroll  overflow-auto ${
                    linkedinAccountDetails?.reconnect_status
                      ? "h-[calc(100vh_-450px)]"
                      : "h-[calc(100vh_-380px)]"
                  }`}
                >
                  <BlockListDomainListForm
                    blockListResponse={blockListResponse}
                    blocklistDomainList={blocklistDomainList}
                    getBlockDomains={getBlockDomains}
                  />
                </div>
              </div>
            )}
            {/* notification */}
            {tab === "Notifications" && (
              <div className="px-[35px]">
                <div className="pb-[21px] border-b-[1px] border-[#E4E7EC] mb-[6px]">
                  <h4 className="font-[600] text-[#101828] text-[18px] mb-1">
                    Notifications
                  </h4>
                  <p className="font-[400] text-[#475467] text-[14px]">
                    Customize your notification preferences for campaign updates
                    and activity. You may receive updates about your account
                    outside of these settings.
                  </p>
                </div>
                <div className="pl-[32px] max-w-[1064px]">
                  <NotificationsSettings
                    setIsNotificationModal={setIsNotificationModal}
                  />
                </div>
              </div>
            )}
            {/* Integrations */}
            {(showIntegrationSettings || userData?.company?.ampersand_id) &&
            tab === "Integrations" &&
            !isSwitchOn ? (
              <AppContextProvider>
                <IntegrationSettings
                  setIsSwitchOn={setIsSwitchOn}
                  setShowIntegrationSettings={setShowIntegrationSettings}
                />
              </AppContextProvider>
            ) : (
              <>
                {tab === "Integrations" && (
                  <div className="px-[35px]">
                    <div className="pb-[21px] border-[#E4E7EC] mb-[6px]">
                      <div className="flex justify-between items-center">
                        <h4 className="font-[600] text-[#101828] text-[18px] mb-1">
                          Connected Apps
                        </h4>
                        <div
                          className="flex flex-row items-center border border-[#D0D5DD] w-[190px] h-[40px] py-2 px-[14px] rounded-md cursor-pointer hover:!bg-[#f5f5f5db]"
                          onClick={() => {
                            setIsRequestIntegrationModal(true);
                          }}
                        >
                          <MdOutlineAdd className="text-[20px]" />
                          <p className="font-[600] text-[#344054] text-[14px] ml-[4px]">
                            Request integration{" "}
                          </p>{" "}
                        </div>
                      </div>
                      <p className="font-[400] text-[#475467] text-[14px]">
                        Integrate your current workflows and connect tools you
                        use every day.
                      </p>
                    </div>

                    <div
                      className="w-[398px] h-[189px] border-[#E4E7EC] border rounded-[12px] opacity-55"
                      style={{
                        boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                      }}
                    >
                      <div className="order-[#E4E7EC] border-b p-[20px]">
                        <div className="flex justify-between mb-[26px]">
                          <div className="flex flex-row items-center">
                            <img
                              src={hubSpotIcon}
                              alt="Hubspot"
                              className="w-[48px] h-[48px] mr-[14px]"
                            />
                            <p className="text-[16px] font-[600] text-[#101828]">
                              Hubspot
                            </p>
                          </div>
                          <div className="campaign-toggle">
                            <SwitchToggle
                              checked={isSwitchOn}
                              // onChange={() => {
                              //   if (!isSwitchOn) {
                              //     setShowConnectHubSpot(true);
                              //   }
                              //   setIsSwitchOn(false);
                              // }}
                            />
                          </div>
                        </div>
                        <div>
                          <p className="font-[400] text-[14px] text-[#475467]">
                            Push contacts to your Hubspot.
                          </p>
                        </div>
                      </div>
                      <div className="flex justify-end items-center px-[24px] py-[16px]">
                        <p
                          // onClick={handleConnectClick}
                          className="font-[600] text-[14px] text-[#6941C6] cursor-pointer"
                        >
                          {!isSwitchOn
                            ? "Connect Hubspot"
                            : "Disconnect Hubspot"}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {/* modals */}
      <DeleteCollaboratorModal
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
        }}
        currentClickedUser={currentClickedUser}
        handleDeleteCollaborator={(id) => handleDeleteCollaborator(id)}
      />
      <NotificationToggleModal
        isOpen={isNotificationModal}
        onClose={() => {
          setIsNotificationModal(false);
          handleEmailToggle();
        }}
      />
      <EndSubscription
        isOpen={openEndSubscriptionModal}
        onClose={() => {
          setOpenEndSubscriptionModal(false);
        }}
        setOpenSubscriptionModal={setOpenSubscriptionModal}
        onSubmit={() => onEndSubscription()}
        isPending={cancelSubscription?.isPending}
      />
      <SubscriptionCancelled
        isOpen={openSubscriptionCancelledModal}
        onClose={() => {
          setOpenSubscriptionModal(false);
        }}
        setOpenSubscriptionModal={setOpenSubscriptionModal}
        date={subscriptionEndDate}
      />
      <ReactivateSubscriptionModal
        isOpen={isReactivateSubscriptionModalOpen}
        onClose={() => setIsReactivateSubscriptionModalOpen(false)}
        onReactivateSubscription={onReactivateSubscription}
        isPending={reActivatedSubscription?.isPending}
      />
      <AddEnrichCreditOptions
        isOpen={isPurchaseCreditModal}
        onClose={() => {
          setIsPurchaseCreditModal(false);
        }}
        setStorePurchaseCredit={setStorePurchaseCredit}
        setIsStoredPurchaseModal={setIsStoredPurchaseModal}
        setSelectedPackage={setSelectedPackage}
        setPackageName={setPackageName}
      />
      <AddedPurchasedCredit
        isOpen={isStoredPurchaseModal}
        onClose={() => setIsStoredPurchaseModal(false)}
        storePurchaseCredit={storePurchaseCredit}
        setIsPurchaseCreditModal={setIsPurchaseCreditModal}
        setPurchaseSuccessfulModal={setPurchaseSuccessfulModal}
        setStorePurchaseCredit={setStorePurchaseCredit}
        packageName={packageName}
        selectedPackage={selectedPackage}
        creditsAdded={creditsAdded}
        setCreditsAdded={setCreditsAdded}
      />
      <PurchasedSuccessModal
        isOpen={purchaseSuccessfulModal}
        onClose={() => setPurchaseSuccessfulModal(false)}
        setIsStoredPurchaseModal={setIsStoredPurchaseModal}
        setTab={setTab}
      />
      <AddTeamSeat
        isOpen={addCollaborateTeamModal}
        onClose={() => {
          setAddCollaborateTeamModal(false);
        }}
        setSentInvitationModal={setSentInvitationModal}
        setTeamSeatsNumber={setTeamSeatsNumber}
        teamSeatsNumber={teamSeatsNumber}
        handleClear={() => {
          setTeamSeatsNumber(1);
          setAddCollaborateTeamModal(false);
        }}
      />
      <SentInvitationModal
        isOpen={sentInvitationModal}
        onClose={() => {
          setSentInvitationModal(false);
        }}
        setAddCollaborateTeamModal={setAddCollaborateTeamModal}
        setSentInvitationModal={setSentInvitationModal}
        setTeamPurchaseSuccessfulModal={setTeamPurchaseSuccessfulModal}
        teamSeatsNumber={teamSeatsNumber}
        manageSeatsInput={manageSeatsInput}
        setManageSeatsInput={setManageSeatsInput}
        handleClear={() => {
          setTeamSeatsNumber(1);
          setManageSeatsInput(Array(1).fill({ value: "" }));
          setSentInvitationModal(false);
        }}
        handleAddCollaborator={handleAddCollaborator}
        addCollaborators={addCollaborators}
      />
      <PurchasedSuccessfulTeamModal
        isOpen={teamPurchaseSuccessfulModal}
        onClose={() => {
          setTeamSeatsNumber(1);
          setManageSeatsInput(Array(1).fill({ value: "" }));
          setTeamPurchaseSuccessfulModal(false);
        }}
        setTab={setTab}
      />
      {showConnectHubSpot && (
        <ConnectHubSpot
          onClose={handleCloseConnectHubSpot}
          isOpen={showConnectHubSpot}
          onConnectSuccess={handleConnectSuccess}
          connectionId={connectionId}
          setConnectionId={setConnectionId}
        />
      )}

      {isImportCsvSteps && isImportModalOpen && (
        <>{renderCsvStepComponent()} </>
      )}
      {isManualBlocklistSteps && isManualBlocklistStepsOpen && (
        <>{renderManualBlocklistSteps()} </>
      )}

      <RequestIntegrationModal
        isOpen={isRequestIntegrationModal}
        onClose={() => setIsRequestIntegrationModal(false)}
        setStep={() => setIsIntegrationSuccessModal(true)}
      />
      <IntegrationSuccessModal
        isOpen={isIntegrationSuccessModal}
        onClose={() => setIsIntegrationSuccessModal(false)}
      />
      <UpgradeTeamMemberModal
        isOpen={isUpgradeTeamModal}
        onClose={() => setIsUpgradeTeamModal(false)}
        setIsUpgradedTeamSuccessModal={setIsUpgradedTeamSuccessModal}
        currentClickedUser={currentClickedUser}
        swapToAdmin={swapToAdmin}
        handleSwapToAdminRole={handleSwapToAdminRole}
      />
      <UpgradeTeamSuccessModal
        isOpen={isUpgradedTeamSuccessModal}
        onClose={() => setIsUpgradedTeamSuccessModal(false)}
      />
      <UpdatePassword
        isOpen={isUpdatePasswordModal}
        onClose={() => {
          setIsUpdatePasswordModal(false);
        }}
      />
      <EditEmailModal
        isOpen={editEmailModal}
        onClose={() => {
          setEditEmailModal(false);
        }}
        editEmail={editEmail}
        refetch={async () => await handleGetCollaborators()}
      />
    </Layout>
  );
};

export default Settings;
