const ProspectingSearchTableSkeleton = () => {
  return (
    <div className="w-full border border-gray-200 rounded-md overflow-hidden">
      {/* Header Skeleton */}
      <div className="flex items-center py-2 bg-white box-border">
        {Array(3)
          .fill(0)
          .map((_, index) => (
            <div
              key={index}
              className="py-[8px] px-[12px] w-[33%]"
            >
              <div
                className={`h-[25px] animate-bg-pulse w-[70%] rounded-sm ${index === 2 && "!w-full"}`}
              ></div>
            </div>
          ))}
      </div>
      {/* Skeleton Rows */}
      {Array(6)
        .fill(0)
        .map((_, index) => (
          <div
            key={index}
            className="flex items-center py-[8px] px-[12px] border-t border-gray-200 w-full box-border"
          >
            <div className="flex items-center px-4 w-[33%]">
              <div class="animate-bg-pulse w-[40px] min-w-[40px] h-[40px] rounded-full mr-2"></div>
              <div class="flex flex-col w-full">
                <div class="animate-bg-pulse w-[50%] h-[12px] rounded-full mb-[8px]"></div>
                <div class="animate-bg-pulse w-[60%] h-[12px] rounded-full"></div>
              </div>
            </div>
            <div className="px-4 w-[33%] flex items-center justify-between pr-14">
              <div className="flex items-center w-full">
                <div className="w-[32px] h-[32px] rounded-sm animate-bg-pulse mr-2"></div>
                <div className="h-[20px] w-[50%] rounded-sm animate-bg-pulse"></div>
              </div>
              <div className="w-[32px] h-[32px] min-w-[32px] rounded-sm animate-bg-pulse mr-2"></div>
            </div>
            <div className="px-4 w-[33%] flex items-center">
            <div className="w-[20px] h-[20px] rounded-sm animate-bg-pulse mr-2"></div>
              <div className="h-[20px] w-full rounded-sm animate-bg-pulse"></div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default ProspectingSearchTableSkeleton;
