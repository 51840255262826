import emptyLogo from "assets/images/empty-logo.svg";

const EmptyCampaigns = (props) => {
  const {
    className,
    title,
  } = props;
  return (
    <div className={`${className} w-[469px] font-inter`}>
      <div className={`empty-page-bg flex flex-col justify-center items-center`}>
        <img
          src={emptyLogo}
          alt="lines"
          className="h-[117px] w-[157px] mx-auto mb-[18.3px]"
        />
        <h4 className="font-[600] !text-center text-[16.49px] text-[#101828] mb-[7px] font-inter">
          {title}
        </h4>
      </div>
    </div>
  );
};

export default EmptyCampaigns;
