import Button from "components/button";
import emptyLogo from "assets/images/empty-logo.svg";
import { MdOutlineAdd } from "react-icons/md";
import importData from "assets/images/import.svg";

const EmptyPage = (props) => {
  const {
    className,
    title,
    description,
    whiteButtonTitle,
    primaryButtonTitle,
    whiteButtonClass,
    primaryButtonClass,
    onClick,
    onPrimaryClick,
    showIcon = true,
    hideWhiteButton= false,
    textWidth,
    showButtons = true
  } = props;
  return (
    <div className={`${className} w-[469px] font-inter`}>
      <div className={`${showButtons ? "empty-page-bg ":"empty-page-bg-2 "}flex flex-col justify-center items-center`}>
        <img
          src={emptyLogo}
          alt="lines"
          className="h-[117px] w-[157px] mx-auto mb-[18.3px]"
        />
        <h4 className="font-[600] !text-center text-[16.4px] text-[#101828] mb-[7px] font-inter">
          {title}
        </h4>
        <p className={`font-[400] text-[12.8px] text-center text-[#475467] font-inter max-w-[195px] ${textWidth}`}>
          {description}
        </p>
      </div>
      {showButtons && <div className="flex items-center justify-center mt-[30px]">
       {hideWhiteButton ? null : <Button
          title={<div className="flex items-center justify-center font-inter font-[600] text-[14px] !text-[#111827] !border-[#D1D5DB] mt-[-2px]">
            {showIcon && <img
              src={importData}
              alt="import"
              className="mr-[6px] w-[16px] h-[16px]"
            />}
            {whiteButtonTitle}
          </div>}
          isWhiteBtn={true}
          onClick={onClick}
          className={`!text-[16px] !h-[44px] !font-[600] !text-[#344054] y-axis-shadow !rounded-[8px] mr-[12px] ${whiteButtonClass}`}
        />}
        <Button
          title={
            <div className="flex items-center justify-center font-inter !text-[16px] !font-[600] mt-[-1px]">
              <MdOutlineAdd className="text-[20px] mr-[7px] mt-[1px]" />
              {primaryButtonTitle}
            </div>
          }
          secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9] !rounded-[8px]"
          className={`!text-[16px] !h-[44px] !font-[600] y-axis-shadow ${primaryButtonClass}`}
          onClick={onPrimaryClick}
        />
      </div>}
    </div>
  );
};

export default EmptyPage;
