import { useEffect, useState } from "react";
import CommonCheckbox from "components/commonCheckbox";
import { billingTable } from "constant";
import check from "assets/images/green-check-outline.svg";
import { IoArrowDown } from "react-icons/io5";
import shareNext from "assets/images/share-next.svg";
import { useAuthStore, useSettingStore } from "stores";
import { usePaymentBillInfo } from "services/payments-api";
import Loader from "components/loader";
import CrossIcon from "assets/images/cross-small.svg";
import moment from "moment";
import BillingTableSkeleton from "components/skeletonScreens/billingTableSkeleton";

const BillingTable = () => {
  const { userDetails } = useAuthStore();
  const { userData } = useSettingStore();
  const [checkedItems, setCheckedItems] = useState(
    new Array(billingTable?.length).fill(false)
  );
  const [isHeaderChecked, setIsHeaderChecked] = useState(false);

  const { data: billingDetails, isPending } = usePaymentBillInfo({
    user_id: userDetails?.userid,
  });

  const handleCheckboxChange = (index) => {
    const updatedCheckedItems = [...checkedItems];
    updatedCheckedItems[index] = !updatedCheckedItems[index];
    setCheckedItems(updatedCheckedItems);

    if (updatedCheckedItems.includes(false)) {
      setIsHeaderChecked(false);
    } else {
      setIsHeaderChecked(true);
    }
  };

  const handleHeaderCheckboxChange = () => {
    const newCheckedState = !isHeaderChecked;
    setIsHeaderChecked(newCheckedState);
    setCheckedItems(new Array(billingDetails.length).fill(newCheckedState));
  };

  const TableHeader = [
    "",
    "Invoice",
    "Date",
    "Type",
    "Amount",
    "Status",
    "Users on plan",
    "",
  ];

  return (
    <div className="overflow-hidden rounded-tl-[12px] rounded-tr-[12px] rounded-bl-[12px] rounded-br-[12px] border border-[#E4E7EC]">
      {!isPending ? (
        <table className="min-w-full font-inter ">
          <thead className="sticky top-0 z-40 bg-white">
            <tr className="w-full relative">
              {TableHeader.map((header, index) => (
                <th
                  key={index}
                  className={`text-[12px] font-[500] py-[12px] text-[#475467] capitalize text-left px-[24px] ${
                    index === 0 && "!w-[20px] pr-[12px]"
                  }
                ${index === 1 && "!pl-0"}`}
                >
                  {/* Render the header checkbox */}
                  {index === 0 ? (
                    <div className="flex items-center justify-center creditCheckbox ">
                      <div className=" w-[16px] h-[16px] mt-[-1px]">
                        <CommonCheckbox
                          checked={isHeaderChecked}
                          onChange={handleHeaderCheckboxChange}
                        />
                      </div>
                    </div>
                  ) : (
                    header
                  )}
                  {index === 1 && (
                    <IoArrowDown className="text-[16px] text-[#475467] ml-[5px] inline" />
                  )}
                </th>
              ))}
            </tr>
            <span className="w-full border-b border-[#E4E7EC] h-[1px] absolute top-full z-10 left-0" />
          </thead>
          <tbody>
            {billingDetails?.length ? (
              billingDetails?.map((item, index) => {
                const isLastRow = index === billingDetails?.length - 1;
                const isChecked = checkedItems[index];
                return (
                  <tr
                    className={`group border-b border-[#E4E7EC] cursor-pointer 
                ${isLastRow ? "border-b-0" : ""}
                ${isChecked ? "font-[600]" : "hover:bg-gray-50"}`}
                    key={index}
                  >
                    {/* Checkbox Column */}
                    <td className="text-[14px] p-[24px] text-[#475467] pr-[12px]">
                      <div className="flex items-center justify-center creditCheckbox ">
                        <div className=" w-[20px] h-[20px] mt-[-1px]">
                          <CommonCheckbox
                            checked={isChecked}
                            onChange={() => handleCheckboxChange(index)}
                          />
                        </div>
                      </div>
                    </td>

                    <td
                      className={`text-[14px] p-[24px] !text-[#101828] !pl-0 ${
                        isChecked ? "font-[600]" : "font-[500]"
                      }  text-left w-[500px] max-[1300px]:w-[unset]`}
                    >
                      {item?.plan_name}
                    </td>
                    <td
                      className={`text-[14px] p-[24px] py-[16px] text-[#475467] ${
                        isChecked ? "font-[600]" : "font-[400]"
                      }  text-left w-[130px] max-[1300px]:w-[unset]`}
                    >
                      {moment(item?.subscription_start_date).format(
                        "MM-DD-YYYY"
                      )}
                    </td>
                    <td
                      className={`text-[14px] p-[24px] py-[16px] text-[#475467] ${
                        isChecked ? "font-[600]" : "font-[400]"
                      }  text-left w-[135px] max-[1300px]:w-[unset]`}
                    >
                      Monthly
                    </td>
                    <td
                      className={`text-[14px] p-[24px] py-[16px] text-[#475467] ${
                        isChecked ? "font-[600]" : "font-[400]"
                      }  text-left w-[135px] max-[1300px]:w-[unset]`}
                    >
                      {item?.total_amount && "$"}
                      {item?.total_amount}
                    </td>
                    <td
                      className={`text-left p-[24px] py-[16px] text-[#475467] ${
                        isChecked ? "font-[600]" : "font-[400]"
                      }  w-[150px] max-[1300px]:w-[unset]`}
                    >
                      {item?.payment_status ? (
                        <div
                          className={`border-[2px] w-fit ${
                            item?.payment_status === "failed"
                              ? "border-[#e60000]"
                              : "border-[#079455]"
                          } rounded-full py-[0px] px-[5px] flex items-center h-full`}
                        >
                          <img
                            src={
                              item?.payment_status === "failed"
                                ? CrossIcon
                                : check
                            }
                            alt="check"
                            className={`w-[14px] mr-[4px] ${
                              item?.payment_status === "failed"
                                ? "crossIcon"
                                : ""
                            }`}
                          />
                          <p
                            className={`${
                              item?.payment_status === "failed"
                                ? "text-[#e60000]"
                                : "text-[#067647]"
                            } font-[500] text-center text-[12px] capitalize`}
                          >
                            {item?.payment_status}
                          </p>
                        </div>
                      ) : null}
                    </td>
                    <td
                      className={`text-left p-[24px] py-[16px] ${
                        isChecked ? "font-[600]" : "font-[400]"
                      }  w-[150px] max-[1300px]:w-[unset] relative`}
                    >
                      {item?.users_on_plan > 0 && item?.users_on_plan}
                      {/* {item?.images?.map((image, index) => (
                    <img
                      key={index}
                      src={image.src}
                      alt={`User ${index}`}
                      className={`max-w-[24px] max-h-[24px] min-w-[24px] absolute top-[32%] cursor-pointer`}
                      style={{
                        left: image.left,
                        zIndex: image.zIndex,
                      }}
                    />
                  ))} */}
                    </td>
                    <td
                      align="right"
                      className={`p-[16px] ${
                        isChecked ? "font-[600]" : "font-[400]"
                      }  !w-[140px] max-[1300px]:w-[unset]`}
                    >
                     {item?.payment_status ==="paid" && <a
                        href={item?.invoice_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center border-[1px] border-[#D0D5DD] rounded-[8px] py-[11px] px-[14px] w-[140px] h-[40px]"
                      >
                        <img
                          src={shareNext}
                          alt="Icon"
                          className="w-[18px] h-[18px] mr-[6px]"
                        />
                        <p className="font-[600] text-[#344054] text-[14px]">
                          View Invoice
                        </p>
                      </a>}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  colSpan={TableHeader.length}
                  rowSpan={7}
                  className="text-[14px] px-[22px] py-[10px] text-center text-[#474563] h-[200px]"
                >
                  <p>No Data Found</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      ) : (
        <BillingTableSkeleton />
      )}
    </div>
  );
};

export default BillingTable;
