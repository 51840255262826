import { useEffect, useRef, useState } from "react";
import Button from "components/button";
import hubspotIcon from "assets/images/Hubspot.svg";
import addIcon from "assets/images/purple-addition.svg";
import ReactSelect from "components/select";
import { hubSpotAssociationSelectoptions, hubSpotSelectOptions } from "constant";
import { MdOutlineAdd } from "react-icons/md";
import deleteIcon from "assets/images/dark-trash.svg";

const HubspotStep = ({ setStep }) => {
  const [addOnData, setAddOnData] = useState({
    properties: [{ id: Date.now(), associations: [{ id: Date.now() }] }],
  });
  const [isNewPropertyAdded,setIsNewPropertyadded] = useState(false)
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const hubspotParentRef = useRef(null);

  const addProperty = () => {
    const newId = Date.now();
    setAddOnData((prevState) => ({
      ...prevState,
      properties: [
        ...prevState.properties,
        { id: newId, associations: [{ id: newId }] },
      ],
    }));
    setIsNewPropertyadded(true)
  };

  // Remove Property
  const removePropertyAndAssociation = (id) => {
    setAddOnData((prevState) => ({
      ...prevState,
      properties: prevState.properties.filter((property) => property.id !== id),
    }));
  };

  // Add Association
  const addAssociation = (propertyId) => {
    const newId = Date.now();
    setAddOnData((prevState) => ({
      ...prevState,
      properties: prevState.properties.map((property) =>
        property.id === propertyId
          ? {
              ...property,
              associations: [...property.associations, { id: newId }],
            }
          : property
      ),
    }));
    setIsNewPropertyadded(true)
  };

  // Remove Association
  const removeAssociation = (propertyId, associationId) => {
    setAddOnData((prevState) => ({
      ...prevState,
      properties: prevState.properties.map((property) =>
        property.id === propertyId
          ? {
              ...property,
              associations: property.associations.filter(
                (association) => association.id !== associationId
              ),
            }
          : property
      ),
    }));
  };

  const onPropertiesChange = (event, property) => {};

  useEffect(() => {
    if (hubspotParentRef && hubspotParentRef?.current && ( isNewPropertyAdded || isSelectOpen)) {
      hubspotParentRef.current.scrollTo({
        top: hubspotParentRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
    setIsNewPropertyadded(false)
  }, [isSelectOpen,isNewPropertyAdded]);

  return (
    <div className="modal-scroll">
      <div className="flex flex-col items-start justify-start font-inter">
        <div className="flex items-center justify-center border-[1px] border-[#E4E7EC] rounded-[10px] p-[8px] mb-[16px] y-axis-shadow">
          <img src={hubspotIcon} alt="info img" className="h-[32px] w-[32px]" />
        </div>
        <h4 className="font-[600] text-[18px] text-[#101828] m-0 font-inter">
          Import from Hubspot
        </h4>
        <h4 className="font-[400] text-[14px] text-[#475467] m-0 font-inter">
          Select criteria to import your Hubspot contacts.
        </h4>
      </div>
      <div className="max-h-[300px] overflow-auto innerScrollBar" ref={hubspotParentRef}>
        <div className="px-[24px] py-[22px] w-[85%]">
          {addOnData.properties.map((property, index) => (
          <>
            <div className="mb-[6px] w-full">
            <p className="font-[500] text-[14px] text-[#344054] font-inter mb-[0px]">
              Contact Property
            </p>
          </div>
          <div
              key={property.id}
              className={`w-full`}
            >
              <div className="mb-[12px] hubspot-select flex items-center w-full">
                <div className="mr-[30px] w-full">
                  <ReactSelect
                    placeholder={"Select Property"}
                    options={hubSpotSelectOptions}
                    isHubSpot={true}
                    onChange={(event) => onPropertiesChange(event, property)}
                  />
                </div>
                {index !== 0 ? (
                  <img
                    src={deleteIcon}
                    alt="delete"
                    className="w-[20px] h-[20px] min-w-[20px] mt-[-2px] cursor-pointer"
                    onClick={() => removePropertyAndAssociation(property.id)}
                  />
                ) : (
                  <div className="w-[20px] h-[20px]" />
                )}
              </div>
              <div className="mb-[12px]">
                <p className="font-[500] text-[14px] text-[#344054] font-inter mb-1.5">
                  Choose Association
                </p>
                {property.associations.map((association, index) => (
                  <div
                    key={association.id}
                    className="mb-[12px] hubspot-select flex items-center"
                  >
                    <div className="mr-[30px] w-full">
                      <ReactSelect
                        placeholder={"Any"}
                        options={hubSpotAssociationSelectoptions}
                        onChange={(event) =>
                          onPropertiesChange(event, association)
                        }
                        // setIsSelectOpen={setIsSelectOpen}
                      />
                    </div>
                    {index !== 0 ? (
                      <div className="w-[20px] h-[20px]">
                        <img
                          src={deleteIcon}
                          alt="delete"
                          className="w-[20px] h-[20px] min-w-[20px] mt-[-2px] cursor-pointer"
                          onClick={() =>
                            removeAssociation(property.id, association.id)
                          }
                        />
                      </div>
                    ) : (
                      <div className="w-[20px] h-[20px]" />
                    )}
                  </div>
                ))}
                <div className="flex justify-end">
                  <button
                    className="border-0 !outline-none font-inter font-[600] text-[#FF5B35] text-[14px] flex items-center mr-[30px]"
                    onClick={() => addAssociation(property.id)}
                  >
                    <MdOutlineAdd className="max-w-[25px] mr-[8px] text-[22px] text-[#FF5B35]" />
                    Add Association
                  </button>
                  <div className="w-[20px]" />
                </div>
              </div>
            </div>
          </>
          ))}
          <button
            className="border-0 !outline-none font-inter font-[600] text-[#6941C6] text-[14px] flex items-center"
            onClick={addProperty}
          >
            <img src={addIcon} alt="add" className="max-w-[14px] mr-[10px]" />
            Add Property
          </button>
        </div>
      </div>
      <div className="flex items-center justify-center mt-[32px]">
        <Button
          title="Cancel"
          isWhiteBtn={true}
          isRounded="6px"
          onClick={() => setStep("import-options")}
          className="!text-[16px] !h-[44px] !font-[600] !text-[#344054] y-axis-shadow"
        />
        <Button
          title="Confirm"
          secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9] ml-[12px]"
          className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow"
          isRounded="6px"
          onClick={() => setStep("add-team")}
        />
      </div>
    </div>
  );
};

export default HubspotStep;
