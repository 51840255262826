import Mail from "assets/images/white-mail-icon.svg";
import RocketIcon from "assets/images/hd4.png";
import Input from "components/textInput";
import AnimatedButton from "components/animatedButton";
import { HiPlusSm } from "react-icons/hi";
import tooltipIcon from "assets/images/invite-users.svg";
import tooltipArrow from "assets/images/Tooltip.svg";
import { useEffect, useRef, useState } from "react";
import deleteIcon from "assets/images/dark-trash.svg";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuthCheck, useCreateCompany } from "services/auth-api";
import { setPaymentVerify } from "utils/token-service";

const CreateWorkSpace = ({
  setLoader,
  setIsLaunchButtonClicked,
  handleGetLinkedInAccountDetails,
}) => {
  const createCompanyMutation = useCreateCompany();
  const authCkeckMutation = useAuthCheck();
  const parentRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const navigate = useNavigate();
  const [emails, setEmails] = useState([""]);
  const [errors, setErrors] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [companyNameError, setCompanyNameError] = useState(false);

  const handleAddEmail = () => {
    if (emails?.every((email) => email?.length > 0 && validateEmail(email))) {
      setEmails([...emails, ""]);
      setErrors([...errors, false]);
      setTimeout(() => {
        scrollToView();
      }, 100);
    } else {
      toast.success(
        emails?.length <= 1 
          ? "Please enter a valid email address before adding another one"
          : "Please enter valid email address in all fields before adding another one"
      );
    }
  };
  console.log(
    emails?.every((email) => email?.length > 0),
    "emails?.every((email)=>email?.length > 0)"
  );
  useEffect(() => {
    if (errors[emails?.length - 1]) {
      setTimeout(() => {
        scrollToView();
      }, 100);
    }
  }, [errors]);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailChange = (index, value) => {
    const newEmails = [...emails];
    newEmails[index] = value;
    setEmails(newEmails);

    // Validate email
    const newErrors = [...errors];
    newErrors[index] = value ? !validateEmail(value) : false;
    setErrors(newErrors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isCompanyNameValid = companyName.trim() !== "";
    setCompanyNameError(!isCompanyNameValid);

    const newErrors = emails.map((email) =>
      email ? !validateEmail(email) : false
    );

    const hasErrors = newErrors.some((error) => error);

    if (!isCompanyNameValid || hasErrors) {
      return;
    }
    setIsLaunchButtonClicked(true);
    setLoader(true);
    try {
      const response = await createCompanyMutation.mutateAsync({
        company_name: companyName,
        team_members: emails?.[0]?.length > 0 ? emails?.filter(Boolean) : [],
      });

      if (response.success) {
        handleGetLinkedInAccountDetails();
        setIsLaunchButtonClicked(false);
        setPaymentVerify(true);
        navigate("/dashboard");
      }
    } catch (error) {
      if (error?.data && error?.data?.detail) {
        toast.error(error.data.detail);
      } else {
        toast.error("An unknown error occurred");
      }
    } finally {
      setLoader(false);
    }
  };

  const scrollToView = () => {
    if (parentRef?.current) {
      parentRef.current.scrollTo({
        top: parentRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const handleDeleteEmail = (index) => {
    setEmails(emails.filter((_, i) => i !== index));
  };

  const handleAuthCheck = async () => {
    try {
      const navigationCheck = await authCkeckMutation.mutateAsync({});
      if (navigationCheck?.company_setup) {
        handleGetLinkedInAccountDetails();
        setIsLaunchButtonClicked(false);
        setPaymentVerify(true);
        setTimeout(() => {
          setLoader(false);
          navigate("/dashboard");
        }, 1000);
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  useEffect(() => {
    handleAuthCheck();
  }, []);

  return (
    <div
      className="font-inter !h-full  flex flex-col justify-center items-center  m-auto max-[767px]:h-auto  max-[767px]:p-[20px]  max-[767px]:pt-[50px] "
      data-aos="fade-in"
      data-aos-duration="2000"
    >
      <div className="!w-[425px] relative signin-card">
        <img
          src={RocketIcon}
          alt="Logo"
          className="h-[60px] w-[60px] mx-auto block"
          style={{
            filter: "brightness(1.2)",
          }}
        />
        <h1 className="text-[30px] text-center text-[#F5F5F6] font-[600] mt-[20px] mb-[1px]">
          Create your Workspace
        </h1>

        <form className="m-auto mt-[30px] w-[340px]" onSubmit={handleSubmit}>
          <div
            className="max-h-[220px] overflow-auto overflow-x-hidden mb-[10px] innerScrollBar "
            ref={parentRef}
          >
            <p className="text-[13px] font-[500] text-[#F6F6F6] mb-[6px] ">
              Company Name *
            </p>
            <Input
              isSigninInput
              type="text"
              placeholder={"Enter your company name"}
              className="w-full h-[40px] rounded-[7.5px] border border-[#D0D5DD] !mb-[4px] !bg-[#0d0515] text-[#9e9ba0]"
              value={companyName}
              onChange={(e) => {
                setCompanyName(e.target.value);
                setCompanyNameError("");
              }}
            />
            <p className="text-red-500 text-xs">
              {companyNameError ? "Company Name is Required." : ""}
            </p>

            <p className="text-[13px] font-[500] text-[#F6F6F6] mb-[6px] mt-[12px]">
              Team Members
            </p>
            <form>
              {emails.map((email, index) => (
                <div className="relative w-full">
                  <div className="relative flex items-center w-full">
                    <img
                      src={Mail}
                      alt="mail"
                      className="absolute left-3 top-[13px] transform h-[16.5px] w-[16.5px] z-10"
                    />
                    <Input
                      isSigninInput
                      type="email"
                      placeholder={"you@example.com"}
                      className="pl-10 w-full h-[40px] rounded-[7.5px] border border-[#D0D5DD] !mb-[10px] flex-1 pr-[30px] !bg-[#0d0515] text-[#9e9ba0]"
                      value={email}
                      onChange={(e) => handleEmailChange(index, e.target.value)}
                      onBlur={() => setShowTooltip(false)}
                      onFocus={() => setShowTooltip(true)}
                    />
                    {![0]?.includes(index) && (
                      <button
                        type="button"
                        className="absolute right-[-10px] top-1/2 transform -translate-y-1/2 text-red-500 hover:text-red-700"
                        onClick={() => handleDeleteEmail(index)}
                      >
                        <img
                          src={deleteIcon}
                          alt="Delete"
                          style={{
                            filter:
                              "brightness(0) saturate(100%) invert(81%) sepia(1%) saturate(102%) hue-rotate(319deg) brightness(105%) contrast(90%)",
                          }}
                          className="w-[15px] h-[15px] ml-[10px] mt-[-10px] mr-[20px]"
                        />
                      </button>
                    )}
                  </div>
                  {errors[index] && (
                    <p className="text-red-500 text-xs mt-[-3px] ml-0 mb-[10px]">
                      Enter a valid email
                    </p>
                  )}
                </div>
              ))}
            </form>
          </div>
          <span
            className="cursor-pointer select-none text-[#CECFD2] text-[13px] font-[600] mb-[20px] mr-auto justify-end text-right hover:underline transition-all hover:text-[#fff] flex items-center"
            onClick={handleAddEmail}
          >
            <HiPlusSm size={20} className="mr-[5px] mt-[1px]" />
            <span>Add another</span>
          </span>
          <AnimatedButton
            title="Launch Workspace"
            className="!text-[15px] !font-[600] !h-[42px] !rounded-[7px] !bg-[#635BFF]"
            fontSize="!text-[15px]"
            type="submit"
          />
        </form>
        {/* tooltip */}
        <div
          className={`absolute top-[270px] right-[-325px] Z-50 transition-opacity duration-1000 w-[313px] ${
            showTooltip ? "opacity-100" : "opacity-0"
          }`}
        >
          <div className="bg-white p-[12px] rounded-[8px] relative z-20 w-[313px]">
            <img
              src={tooltipArrow}
              alt="icon"
              className="w-[24px] h-[24px] absolute left-[-9px] -translate-y-1/2 top-[50%] z-[999]"
            />
            <div className="flex items-center mb-[5px]">
              <img
                src={tooltipIcon}
                alt="icon"
                className="w-[24px] h-[24px] mr-[10px]"
              />
              <p className="text-[#344054] text-[600] text-[12px] font-semibold">
                Invite your team!
              </p>
            </div>
            <p className="font-[500] text-[#475467] text-[12px]">
              Each team member you add to the workspace will add another seat
              and more enrichment credits.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateWorkSpace;
