import cloudIllustration from "assets/images/cloud_illustration.gif";
import Button from "components/button";
import { useEffect, useState } from "react";

const EnrichingLeadsCampaign = ({ isOpen, onClose }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [isOpen]);

  if (!isOpen && !isModalVisible) return null;

  return (
    <>
      <div
        className={`fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[300ms] ease-in-out ${
          isModalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
      >
        <div
          className="fixed inset-0 bg-black opacity-10"
          onClick={onClose}
        ></div>
        <div
          className={`bg-white p-[24px] flex flex-col items-center rounded-lg shadow-lg z-10 w-full max-w-[500px] relative transition-all ease-in-out duration-[150ms] ${
            isModalVisible ? "scale-100" : "scale-95"
          }`}
        >
          <div className="flex items-center justify-center p-[11px]">
            <img
              src={cloudIllustration}
              alt="info img"
              className="h-[117px] w-[158px] ml-[-2px]"
            />
          </div>
          <h4 className="font-[600] text-center text-[16.5px] text-[#101828] mb-1.5">
            Enriching Leads
          </h4>
          <div className="max-w-[248px]">
            <p className="font-normal text-center text-[12.8px] leading-[18px] text-[#475467]">
              We’re working on enriching your leads and adding them to the
              campaign.
            </p>
            <p className="font-normal text-center text-[12.8px] leading-[18px] text-[#475467] mt-2">
              This may take a few minutes.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnrichingLeadsCampaign;
