import deleteImg from "assets/images/thumbs-down.svg";
import Button from "components/button";
import { AppContext } from "context/AppContext";
import { useContext, useEffect } from "react";

const DataMappingFailed = ({ setStep }) => {
  const { appState } = useContext(AppContext);
  // useEffect(() => {

  //   const timer = setTimeout(() => {
  //     if(appState?.importMethodClicked !== "uploadPeopleCsv"){
  //       setStep("succes-modal");
  //     }else{
  //       setStep("could-not-enrich-leads");
  //     }
  //   }, 3000);

  //   return () => clearTimeout(timer);
  // }, [setStep]);

  return (
    <>
      <div className="tems-center justify-center font-inter">
        <div className="modal-scroll">
          <div className="modal-scroll">
            <div className="flex flex-col items-start justify-start font-inter">
              <div className="flex items-center justify-center mb-[16px]">
                <img
                  src={deleteImg}
                  alt="info img"
                  className="h-[48px] w-[48px]"
                />
              </div>
              <h4 className="font-[600] text-[18px] text-[#101828] m-[4px] mb-0 ml-0 font-inter">
                Mapping Failed
              </h4>
              <p className="font-[700] text-[14px] text-[#475467] font-inter mt-[9px]">
                Please try again.
              </p>
            </div>
          </div>
        </div>
        <Button
          title="Back to Data Mapping"
          isWhiteBtn={true}
          isRounded="6px"
          onClick={() => {
            setStep("csv-form-step");
          }}
          className="!text-[16px] !h-[44px] !font-[600] !mb-[0px] !mt-[32px] y-axis-shadow mr-[12px] !text-[#344054]"
        />
      </div>
    </>
  );
};

export default DataMappingFailed;
