import { useContext, useEffect, useState } from "react";
import Button from "components/button";
import downloadIcon from "assets/images/download-icon.svg";
import CommonCheckbox from "components/commonCheckbox";
import { uploadersData } from "constant";
import { AppContext } from "context/AppContext";

const ImportOptionsStep = ({ setStep, onClose }) => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const { appState, setAppState, setSelectedButtonType } =
    useContext(AppContext);

  const handleCheckboxChange = (index) => {
    setSelectedIndex(index);
  };
  const handleStateUpdateImportMethod = (method) => {
    setAppState((prev) => ({
      ...prev,
      importMethodClicked: method,
      addProspectingData: true,
    }));
  };

  const handleSelectClick = () => {
    if (selectedIndex !== null) {
      const selectedItem = uploadersData[selectedIndex];
      switch (selectedItem.type) {
        case "uploadPeopleCsv":
          setStep("uploadPeopleCsv");
          setSelectedButtonType("Peoples");
          handleStateUpdateImportMethod("uploadPeopleCsv");
          break;
        case "uploadCampanyCsv":
          setStep("uploadPeopleCsv");
          handleStateUpdateImportMethod("uploadCampanyCsv");
          setSelectedButtonType("Company");
          break;
        case "uploadCsvUrl":
          setStep("uploadCsvUrl");
          handleStateUpdateImportMethod("uploadCsvUrl");
          break;
        case "uploadCsvEmail":
          setStep("uploadCsvEmail");
          handleStateUpdateImportMethod("uploadCsvEmail");
          break;
        // case "linkedInSearch":
        //   setStep("linkedin-search");
        //   handleStateUpdateImportMethod("linkedin-search");
        //   break;
        // case "salesNavigator":
        //   setStep("sales-navigator");
        //   handleStateUpdateImportMethod("sales-navigator");
        //   break;
        // case "LlnkedInRecruiter":
        //   setStep("linkedin-recruiter");
        //   handleStateUpdateImportMethod("linkedin-recruiter");
        //   break;
        // case "importHubspot":
        //   setStep("import-hubspot");
        //   handleStateUpdateImportMethod("import-hubspot");
        //   break;
        case "manuallyCompanyUrl":
          setStep("manuallyCompanyUrl");
          handleStateUpdateImportMethod("manuallyCompanyUrl");
          break;
        case "manuallyLink":
          setStep("manuallyLink");
          handleStateUpdateImportMethod("manuallyLink");
          break;
        case "viaLinkedin":
          setStep("viaLinkedin");
          handleStateUpdateImportMethod("viaLinkedin");
          break
        default:
          return null;
      }
    }
  };
  useEffect(() => {
    localStorage?.setItem("selectedIndex", selectedIndex);
  }, [selectedIndex]);

  const isAnyChecked = selectedIndex !== null;

  console.log({ uploadersData });

  return (
    <>
      <div className="flex items-start justify-start mb-[24px] font-inter">
        <div className="flex items-start justify-center border-[1px] border-[#E4E7EC] rounded-[10px] p-[11px] y-axis-shadow mr-[10px] w-[48px] h-[48px]">
          <img
            src={downloadIcon}
            alt="csv-icon"
            className="h-[24px] w-[24px]"
          />
        </div>
        <div className="w-full">
          <h4 className="font-[600] text-[18px] text-[#101828] m-0 font-inter">
            Import Data
          </h4>
          <p className="font-[400] text-[14px] text-[#535862] font-inter">
            Select which method you’d like to choose.
          </p>
        </div>
      </div>
      <div className="pr-[10px] modal-scroll grid grid-cols-3 gap-x-[34px] p-[10px]">
        {uploadersData.map((item, index) => (
          <div
            key={index}
            className={`flex flex-col cursor-pointer transition-all duration-100 ease-in mb-[27px] relative ${
              selectedIndex === index && ""
            } ${item?.disabled ? "opacity-45" : ""}`}
            onClick={() => {
              if (!item?.disabled) {
                handleCheckboxChange(index);
              }
            }}
          >
            <img
              src={item.icons}
              alt="info img"
              className={`h-[160px] w-[240px] min-w-[240px] mb-[12px] rounded-[10px]  ${
                selectedIndex === index &&
                "outline outline-[3px] outline-[#7F56D9] -outline-offset-[3px]"
              }`}
            />
            <div className="flex items-start">
              <div
                className={`w-[16px] h-[16px] mr-[8px] mt-[2px] dot-checkobox`}
              >
                <CommonCheckbox
                  isRound={true}
                  disabled={item?.disabled}
                  checked={selectedIndex === index}
                  onChange={() => handleCheckboxChange(index)}
                />
              </div>
              <div className="">
                <h6
                  className={`font-inter font-[600] text-[14px] leading-[20px] text-[#181D27] m-0 ${
                    selectedIndex === index && ""
                  }`}
                >
                  {item.title}
                </h6>
                {item.subTitle && (
                  <p
                    className={`font-inter font-normal text-[12px] leading-[20px] text-[#535862] m-0 ${
                      selectedIndex === index && ""
                    }`}
                  >
                    {item.subTitle}
                  </p>
                )}
              </div>
            </div>
            {item?.newChip && (
              <p className="y-axis-shadow text-[14px] text-[#fff] font-[600] m-0 bg-[#7F56D9] h-[23.6px] pt-[1px] px-[7px] w-[53px] font-inter rounded-[5.65px] absolute top-[-14px] right-[-5px]">
                NEW!
              </p>
            )}
          </div>
        ))}
      </div>
      {isAnyChecked ? (
        <div className="flex items-center justify-center mt-[4px]">
          {/* <Button
          title="Cancel"
          isWhiteBtn={true}
          isRounded="6px"
          onClick={onClose}
          className="!text-[16px] !h-[44px] !font-[600] !text-[#344054] y-axis-shadow"
        /> */}

          <Button
            title="Continue"
            secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9]"
            className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow !w-[410px]"
            isRounded="6px"
            onClick={handleSelectClick}
          />
        </div>
      ) : null}
    </>
  );
};

export default ImportOptionsStep;
