import { MuiChipsInput } from "mui-chips-input";
import downIcon from "assets/images/chevron-down.svg";
import { useEffect, useState, useCallback, useRef } from "react";
import {
  useGetIndustry,
  useGetJobTitles,
  useGetLocation,
  useGetMajors,
  useGetSchools,
  useGetSkills,
} from "services/prospecting-api";
import { debounce } from "utils/index";

function TypeInput({
  setJobShowList,
  showJobList,
  name,
  placeholder,
  formData,
  setFormData,
  options,
  subTitle,
  isExcludeInInput = false,
  showExclude = false,
  active,
  preventEnter = false,
  isImportDataModalInput = false,
}) {
  const getSchools = useGetSchools();
  const getMajors = useGetMajors();
  const getLocation = useGetLocation();
  const getJobTitles = useGetJobTitles();
  const getTheSkills = useGetSkills();
  const getIndustries = useGetIndustry();

  const [searchText, setSearchText] = useState("");
  const [currentJobIndex, setCurrentJobIndex] = useState(0);
  const [resultOptions, setResultOptions] = useState([]);

  const debouncedGetSchoolsAPI = useRef(
    debounce(async (text) => {
      try {
        const response = await getSchools?.mutateAsync(text);
        const data = response?.results?.map((i) => ({ label: i }));
        setResultOptions(data);
      } catch (error) {
        console.error("Error fetching schools:", error);
      }
    }, 300)
  ).current;

  const debouncedGetLocationAPI = useRef(
    debounce(async (text) => {
      try {
        const response = await getLocation?.mutateAsync(text);
        console.log({ response });
        const locationData = response?.results?.map((i) => ({
          label: `${i?.label}/${i?.location_type}`,
        }));
        setResultOptions(locationData);
      } catch (error) {
        console.error("Error fetching schools:", error);
      }
    }, 300)
  ).current;

  const debouncedGetMajorsAPI = useRef(
    debounce(async (text) => {
      try {
        const response = await getMajors?.mutateAsync(text);
        const data = response?.results?.map((i) => ({ label: i }));
        setResultOptions(data);
      } catch (error) {
        console.error("Error fetching majors:", error);
      }
    }, 300)
  ).current;

  const debouncedGetJobTitlesAPI = useRef(
    debounce(async (text) => {
      try {
        const response = await getJobTitles?.mutateAsync(text);
        const data = response?.results?.map((i) => ({ label: i }));
        setResultOptions(data);
      } catch (error) {
        console.error("Error fetching majors:", error);
      }
    }, 300)
  ).current;

  const debouncedFetchSkills = useRef(
    debounce(async (text) => {
      try {
        const response = await getTheSkills?.mutateAsync(text);
        const data = response?.results?.map((item) => ({ label: item }));
        setResultOptions(data);
      } catch (error) {
        console.error("Error fetching skills:", error);
      }
    }, 300)
  ).current;

  const debouncedFetchIndustries = useRef(
    debounce(async (text) => {
      try {
        const response = await getIndustries?.mutateAsync(text);
        const data = response?.results?.map((item) => ({ label: item }));
        console.log({data})
        setResultOptions(data);
      } catch (error) {
        console.error("Error fetching Industries:", error);
      }
    }, 300)
  ).current;

  useEffect(() => {
    if (searchText?.length > 1) {
      if (name === "school") {
        debouncedGetSchoolsAPI(searchText);
      } else if (name === "major") {
        debouncedGetMajorsAPI(searchText);
      } else if (name === "location" || name === "company_location") {
        debouncedGetLocationAPI(searchText);
      } else if (name === "job_title") {
        debouncedGetJobTitlesAPI(searchText);
      } else if (name === "skill") {
        debouncedFetchSkills(searchText);
      } else if (name === "company_industry") {
        debouncedFetchIndustries(searchText);
      }
    }
  }, [
    searchText,
    name,
    debouncedGetSchoolsAPI,
    debouncedGetMajorsAPI,
    debouncedGetLocationAPI,
    debouncedGetJobTitlesAPI,
    debouncedFetchSkills,
    debouncedFetchIndustries,
  ]);

  useEffect(() => {
    if (!active) {
      setSearchText("");
    }
  }, [active]);

  // Helper function to normalize text for comparison (case-insensitive)
  const normalizeText = (text) => {
    if (text?.label) {
      return text?.label?.toLowerCase();
    }
    return text.toLowerCase();
  };

  // Filter options based on search text and existing form data
  const filteredOptions = (resultOptions ?? options)?.filter(
    (item) =>
      !formData[name]
        ?.map((item) => normalizeText(item?.replace(/^(In:|Ex:)\s*/, "")))
        ?.includes(normalizeText(item?.label)) &&
      normalizeText(item.label).includes(normalizeText(searchText))
  );

  const handleEnterKeyPressed = (event) => {
    let { key } = event;
    if (key === "Enter" && preventEnter) {
      setSearchText(searchText);
    }
    if (key === "Enter" && !preventEnter) {
      event.preventDefault();
      const value = normalizeText(searchText.trim().replace(/^0+(?=\d)/, ""));
      if (
        value.length > 0 &&
        !formData[name]?.some((item) => normalizeText(item) === value)
      ) {
        setFormData({
          ...formData,
          [name]: [...(formData[name] || []), searchText.trim()],
        });
        setSearchText(""); // Clear the input field
        setJobShowList(false); // Hide job list if it was shown
      } else if (filteredOptions.length > 0 && showJobList) {
        const selectedOption = filteredOptions[currentJobIndex]?.label;
        if (
          !formData[name]?.some(
            (item) => normalizeText(item) === normalizeText(selectedOption)
          )
        ) {
          setFormData({
            ...formData,
            [name]: [...(formData[name] || []), selectedOption],
          });
        }
        setSearchText(""); // Clear the input field
        setJobShowList(false); // Hide job list if it was shown
        setCurrentJobIndex(0); // Reset current job index
      }
    }
  };

  const addButtonChip = (arr, isExclude = false) => {
    setSearchText("");
    const value = normalizeText(searchText.trim());
    if (
      value.length > 0 &&
      !formData[name]?.some((item) => normalizeText(item) === value)
    ) {
      setFormData({
        ...formData,
        [name]: [
          ...formData?.[name],
          isExclude ? `Ex:${searchText.trim()}` : searchText.trim(),
        ],
      });
    }
  };

  const onDeleteChip = (chip) => {
    setFormData({
      ...formData,
      [name]: (formData[name] || []).filter((item) => item !== chip),
    });
  };

  const preventInvalidInput = (event) => {
    const invalidChars = ["+", "~", "[", "]", ";"];
    if (
      invalidChars.includes(event.key) ||
      /[!@#$%^&*()?"{}|<>]/.test(event.key)
    ) {
      event.preventDefault();
    }
  };

  const onChange = (label, name, action) => {
    let newLabel = label;
    if (action === "Exclude") {
      newLabel = `Ex: ${label}`;
    } else if (action === "Include") {
      newLabel = `In: ${label}`;
    }

    if (
      !formData[name]?.some(
        (item) => normalizeText(item) === normalizeText(newLabel)
      )
    ) {
      setFormData({
        ...formData,
        [name]: formData?.[name] ? [...formData?.[name], newLabel] : [newLabel],
      });
    }
  };

  return (
    <div className="h-36 relative">
      <div
        className={`flex items-center justify-between h-10 !shadow-lg !shadow-[#1018280D] py-1.5 px-2 rounded-[8px] w-full border-[#D0D5DD] bg-[#fff] border text-[#454545] text-[12px]`}
      >
        <div className="w-full flex items-center justify-between pr-1">
          <MuiChipsInput
            inputValue={searchText}
            onInputChange={(e) => setSearchText(e)}
            onKeyDown={handleEnterKeyPressed}
            onKeyPress={preventInvalidInput}
            placeholder={`${placeholder}...`}
            className="!text-[18px] font-inter border-[0px] style-chips-input !pr-[10px] searchInput"
            onDeleteChip={onDeleteChip}
          />
          {(getSchools?.isPending ||
            getLocation?.isPending ||
            getMajors?.isPending ||
            getJobTitles?.isPending ||
            getTheSkills?.isPending ||
            getIndustries?.isPending) && (
            <div class="snippet" data-title="dot-flashing">
              <div class="stage">
                <div class="dot-flashing"></div>
              </div>
            </div>
          )}
          {searchText && options && filteredOptions?.length > 0 && (
            <img src={downIcon} alt="info img" className="h-[20px] w-[20px]" />
          )}
        </div>
        {showExclude && (
          <button
            className="z-10 font-[600] relative text-[13px] text-[#7927FF] leading-normal h-[24px] rounded-[5px] py-1.5 px-4 mr-2 font-inter max-w-[44px] flex items-center justify-center"
            onClick={() => {
              if (searchText && searchText?.trim()?.length > 0) {
                addButtonChip(filteredOptions, true);
                setJobShowList(!showJobList);
                setCurrentJobIndex(0);
              } else {
                setSearchText("");
              }
            }}
          >
            Exclude
          </button>
        )}
      </div>

      {searchText && options && filteredOptions?.length > 0 ? (
        <>
          <div
            className="fixed top-9 left-0 w-full h-full"
            onClick={() => {
              setJobShowList(!showJobList);
              setCurrentJobIndex(0);
              setSearchText("");
            }}
          />
          <ul
            className={`absolute botttom-0 mt-[9px] innerScrollparent innerScrollBar left-0 w-full ${
              isImportDataModalInput ? "max-h-[100px]" : "max-h-[128px]"
            } overflow-auto bg-white border rounded-[8px] shadow-lg ${"z-[99999999]"}`}
          >
            {filteredOptions?.map((item, index) => {
              return (
                <li
                  key={index}
                  className={`py-[7px h-[42px] w-full flex items-center cursor-pointer hover:bg-gray-50 px-[14px] max-[1300px]:my-[10px]`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setJobShowList(!showJobList);
                    setCurrentJobIndex(0);
                    onChange(item?.label, name);
                    setSearchText("");
                  }}
                >
                  <div className="w-full flex items-center justify-between">
                    <p className="text-[16px] font-inter text-[#475467] leading-[25px]">
                      {name === "location" || name === "company_location"
                        ? item?.label?.split("/")?.[0]
                        : item?.label}
                    </p>
                    {isExcludeInInput && (
                      <span
                        className="text-[#8A43FF] text-normal text-[13px] hover:text-[#8A43FF] font-[500]"
                        onClick={(e) => {
                          e.stopPropagation();
                          setCurrentJobIndex(0);
                          setJobShowList(!showJobList);
                          onChange(item?.label, name, "Exclude");
                          setSearchText("");
                        }}
                      >
                        Exclude
                      </span>
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
        </>
      ) : (
        <p className="text-sm text-[#475467] font-inter mt-2">{subTitle}</p>
      )}
    </div>
  );
}

export default TypeInput;
