import { IoClose } from "react-icons/io5";
import Button from "components/button";
import deleteImg from "assets/images/sad.svg";
import { useAuthStore } from "stores";
import { useEffect, useState } from "react";

const SubscriptionCancelled = (props) => {
  const { isOpen, onClose, setOpenSubscriptionModal, date } = props;
  const { setIsSubscriptionCancelled } = useAuthStore();
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [isOpen]);

  if (!isOpen && !isModalVisible) return null;

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[300ms] ease-in-out ${
        isModalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className="fixed inset-0 bg-black opacity-10"
        onClick={() => {
          onClose();
          setIsSubscriptionCancelled(true);
          setOpenSubscriptionModal(false);
        }}
      ></div>
      <div
        className={`bg-white p-[24px] rounded-lg modal-shadow z-10 w-full max-w-[600px] relative transition-all ease-in-out duration-[150ms] ${
          isModalVisible ? "scale-100" : "scale-95"
        }`}
      >
        <IoClose
          className="text-[#667085] text-[20px] w-[24px] h-[24px] absolute right-[24px] top-[26px] cursor-pointer"
          onClick={() => {
            onClose();
            setIsSubscriptionCancelled(true);
            setOpenSubscriptionModal(false);
          }}
        />
        <div className="modal-scroll">
          <div className="modal-scroll">
            <div className="flex flex-col items-start justify-start mb-[20px] font-inter">
              <div className="flex items-center justify-center mb-[16px]">
                <img
                  src={deleteImg}
                  alt="info img"
                  className="h-[48px] w-[48px]"
                />
              </div>
              <h4 className="font-[600] text-[18px] text-[#101828] m-[4px] ml-0 font-inter">
                Your Subscription has been cancelled.
              </h4>
              <p className="font-normal text-[14px] text-[#475467] font-inter mb-[16px]">
                We’re sad to see you go!
              </p>
              <p className="font-normal text-[14px] text-[#475467] font-inter mb-[8px]">
                Your workspace access will end:
              </p>
              <p className="font-[700] text-[14px] text-[#475467] font-inter">
                {date}
              </p>
              <p className="font-normal text-[14px] text-[#475467] font-inter mt-[14px]">
                If you’d like us to delete any data, please contact us.
              </p>
            </div>
            <div
              onClick={() => {
                setIsSubscriptionCancelled(true);
                setOpenSubscriptionModal(false);
              }}
              className="flex items-center justify-center mt-[32px]"
            >
              <Button
                title="Contact Us"
                isWhiteBtn={true}
                onClick={onClose}
                className="!text-[16px] !h-[44px] !font-[600] !text-[#344054] y-axis-shadow !rounded-[8px]"
              />
              <Button
                title="Got it"
                secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9] ml-[12px]"
                className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow !rounded-[8px]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionCancelled;
