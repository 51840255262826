import DashboardIcon from "assets/images/dashboardIcon.svg";
import ProspectingIcon from "assets/images/prospectingIcon.svg";
import CampaignsIcon from "assets/images/campaingsIcon.svg";
import ConnectionsIcon from "assets/images/connectionsIcon.svg";
import AccountIcon from "assets/images/accountIcon.svg";
import SettingIcon from "assets/images/settingIcon.svg";
import csvIcon from "assets/images/import-csv.svg";
import postIcon from "assets/images/import-post.svg";
import candid from "assets/images/candid.svg";
import twitter from "assets/images/twitter-logo.svg";
import demi from "assets/images/demi.svg";
import drew from "assets/images/drew.svg";
import natali from "assets/images/natali.svg";
import hubspotOutlined from "assets/images/outline-hubspot.svg";
import documentIcon from "assets/images/document.svg";
import editIcon from "assets/images/edit.svg";
import archieveIcon from "assets/images/archive.svg";
import shareIcon from "assets/images/share.svg";
import deleteIcon from "assets/images/action-delete.svg";
import exportIcon from "assets/images/export.svg";
import directionImg from "assets/images/next-direction.svg";
import pinkCircleImg from "assets/images/pink-circle.svg";
import userIcon from "assets/images/user.svg";
import PrimaryColorWand from "assets/images/magic-stick.svg";
import billingAvatar1 from "assets/images/billingAvatar1.svg";
import billingAvatar2 from "assets/images/billingAvatar2.svg";
import billingAvatar3 from "assets/images/billingAvatar3.svg";
import billingAvatar4 from "assets/images/billingAvatar4.svg";
import billingAvatar5 from "assets/images/billingAvatar5.svg";
import downloadIcon from "assets/images/download.svg";
import collaborater1 from "assets/images/callobartor1.svg";
import collaborater3 from "assets/images/callobartor3.svg";
import collaborater4 from "assets/images/callobartor4.svg";
import collaborater5 from "assets/images/callobartor5.svg";
import collaborater6 from "assets/images/callobartor6.svg";
import collaborater7 from "assets/images/callobartor7.svg";
import microsoft from "assets/images/microsoft.svg";
import orlando from "assets/images/orlando.svg";
import Avatar from "assets/images/manFace.jpg";
import faceSmile from "assets/images/face-smile.svg";
import faceContent from "assets/images/face-content.svg";
import pin from "assets/images/pin.svg";
import award from "assets/images/award.svg";
import lightBulb from "assets/images/lightbulb.svg";
import star2 from "assets/images/star2.svg";
import zapFast from "assets/images/zap-fast.svg";
import target from "assets/images/target.svg";
import graduationHat from "assets/images/graduation-hat.svg";
import certificate from "assets/images/certificate.svg";
import building from "assets/images/building2.svg";
import markerPin from "assets/images/marker-pin.svg";
import users from "assets/images/users.svg";
import industry from "assets/images/industry2.svg";
import calendarDate from "assets/images/calendar-date.svg";
import microsfts from "assets/images/mircosofts.svg";
import facebookIcon from "assets/images/facebook-avatar.svg";
import githubIcon from "assets/images/github.svg";
import linearIcon from "assets/images/linear.svg";
import youTube from "assets/images/youtube.svg";
import logomark from "assets/images/Logomark.svg";
import billingAvatarImg1 from "assets/images/ProspectingAvatar1.svg";
import billingAvatarImg2 from "assets/images/ProspectingAvatar2.svg";
import billingAvatarImg3 from "assets/images/ProspectingAvatar3.svg";
import billingAvatarImg4 from "assets/images/ProspectingAvatar4.svg";
import billingAvatarImg5 from "assets/images/ProspectingAvatar5.svg";
import stars from "assets/images/stars.svg";
import fileAttachment from "assets/images/file-attachment.svg";
import avatarImg from "assets/images/verified-lead.svg";
import linkedInImg from "assets/images/colored-linkedin.svg";
import twitterImg from "assets/images/twitter.svg";
import markerImg from "assets/images/marker-pin.svg";
import catelog from "assets/images/catelog.svg";
import circoole from "assets/images/circool.svg";
import commandR from "assets/images/commandR.svg";
import hourGlass from "assets/images/hourGlass.svg";
import layers from "assets/images/layers.svg";
import quotient from "assets/images/quotent.svg";
import sisyphus from "assets/images/sisyph.svg";
import checkIcon from "assets/images/outlined-check.svg";
import dashboardVideo from "assets/images/dashboard-video-icon.svg";
import dashboardMagic from "assets/images/dashboard-magic-stick.svg";
import dashboardCampaign from "assets/images/dashboard-campaign-stars.svg";
import actionRightArrow from "assets/images/dashboard-action-arrow.svg";
import nitali from "assets/images/natali.svg";
import randomProfileLogo1 from "assets/images/random-profile-logo.svg";
import randomProfileLogo2 from "assets/images/random-profile-logo1.svg";
import randomProfileLogo3 from "assets/images/random-profile-logo2.svg";
import randomProfileLogo4 from "assets/images/random-profile-logo3.svg";
import randomProfileLogo5 from "assets/images/random-profile-logo4.svg";
import randomProfileLogo6 from "assets/images/random-profile-logo6.svg";
import randomCompanyLogo1 from "assets/images/random-company-logo1.svg";
import randomCompanyLogo2 from "assets/images/random-company-logo2.svg";
import randomCompanyLogo3 from "assets/images/random-company-logo3.svg";
import randomCompanyLogo4 from "assets/images/random-company-logo5.svg";
import randomCompanyLogo5 from "assets/images/random-company-logo6.svg";
import randomCompanyLogo6 from "assets/images/random-company-logo7.svg";
import randomCompanyLogo7 from "assets/images/random-company-logo8.svg";
import randomCompanyLogo8 from "assets/images/random-company-logo9.svg";
import randomCompanyLogo9 from "assets/images/random-company-logo10.svg";
import randomCompanyLogo10 from "assets/images/random-company-logo11.svg";
import randomCompanyLogo11 from "assets/images/random-company-logo12.svg";
import randomCompanyLogo12 from "assets/images/random-company-logo13.svg";
import randomCompanyLogo13 from "assets/images/random-company-logo14.svg";
import randomCompanyLogo14 from "assets/images/random-company-logo15.svg";
import randomCompanyLogo15 from "assets/images/random-company-logo16.svg";
import randomCompanyLogo16 from "assets/images/random-company-logo17.svg";
import randomCompanyLogo17 from "assets/images/random-company-logo18.svg";
import randomCompanyLogo18 from "assets/images/random-company-logo19.svg";
import randomCompanyLogo19 from "assets/images/random-company-logo20.svg";
import randomCompanyLogo20 from "assets/images/random-company-logo21.svg";
import uploadCsv from "assets/images/uploadcsv.svg";
import csvLinkedIn from "assets/images/csv-linkedin.svg";
import csvEmail from "assets/images/csv-email.svg";
import csvCompany from "assets/images/csvCompany.svg";
import linkedInUrl from "assets/images/linkedInUrl.svg";
import companyUrl from "assets/images/companyUrl.svg";
import ViaLinkedin from "assets/images/via-linkedin.svg";
import resource1 from "assets/images/resources1.png";
import resource2 from "assets/images/resources2.svg";
import resource3 from "assets/images/resources3.svg";
import viaProfileUrl from "assets/images/linkedin-profile-url.svg"

const years = Array.from({ length: 2024 - 1900 + 1 }, (v, i) => ({
  label: (1900 + i).toString(),
  value: 1900 + i,
})).reverse();

export const GOOGLE_KEY =
  "640339073523-6ik4k746agkq6algl6704flqsnphctpj.apps.googleusercontent.com";

export const countryCodeOptions = [
  {
    label: "US",
    value: 1,
  },
  {
    label: "CA",
    value: 1,
  },
  {
    label: "IN",
    value: 91,
  },
  {
    label: "PH",
    value: 63,
  },
];

export const countrySelectStyle = {
  placeholder: (provided) => ({
    ...provided,
    fontSize: "16px",
    color: "#fff",
    marginTop: "5px",
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: "16px",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    minWidth: "50px",
  }),
  valueContainer: (provided) => ({
    ...provided,
    fontSize: "16px",
    color: "#fff",
  }),
  option: (provided) => ({
    ...provided,
    fontSize: "16px",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    marginTop: "-3.4px",
    height: "44.5px",
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: "16px",
  }),
};

export const countrySelectStyleBalck = {
  placeholder: (provided) => ({
    ...provided,
    fontSize: "16px",
    color: "#000",
    marginTop: "5px",
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: "16px",
    color: "#000",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    minWidth: "50px",
  }),
  valueContainer: (provided) => ({
    ...provided,
    fontSize: "16px",
    color: "#000",
  }),
  option: (provided) => ({
    ...provided,
    fontSize: "16px",
    color: "#000",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    marginTop: "-3.4px",
    height: "44.5px",
    backgroundColor: "#fff",
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: "16px",
  }),
};

export const countrySelectStyleLight = {
  placeholder: (provided) => ({
    ...provided,
    fontSize: "16px",
    color: "#000",
    marginTop: "5px",
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: "16px",
    color: "#000",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    minWidth: "50px",
  }),
  valueContainer: (provided) => ({
    ...provided,
    fontSize: "16px",
    color: "#000",
  }),
  option: (provided) => ({
    ...provided,
    fontSize: "16px",
    color: "#000",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    marginTop: "-3.4px",
    height: "44.5px",
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: "16px",
  }),
};

export const menuItems = [
  {
    icon: DashboardIcon,
    name: "Dashboard",
    route: "/dashboard",
  },
  {
    icon: ProspectingIcon,
    name: "Prospecting",
    route: "/prospecting",
  },
  {
    icon: CampaignsIcon,
    name: "Campaigns",
    route: "/campaigns",
  },
  {
    icon: ConnectionsIcon,
    name: "Connections",
    route: "/connections",
  },
  {
    icon: AccountIcon,
    name: "Accounts",
    route: "/accounts",
  },
  {
    icon: SettingIcon,
    name: "Settings",
    route: "/settings",
  },
];

export const peopleFilters = [
  {
    src: faceSmile,
    name: "first_name",
    label: "First Name",
    type: "First Name",
    placeholder: "Type a first name",
  },
  {
    src: faceContent,
    name: "last_name",
    label: "Last Name",
    type: "Last Name",
    placeholder: "Type a last name",
  },
  {
    name: "location",
    src: pin,
    label: "Location",
    type: "location",
    placeholder: "Type a location",
  },
  {
    src: award,
    name: "job_title",
    label: "Job Title",
    type: "Job Title",
    placeholder: "Type a job title",
  },
  {
    src: lightBulb,
    name: "job_title_level",
    label: "Job Level",
    type: "Job Level",
    placeholder: "Enter or Select a Job Level",
    options: [
      {
        label: "Founder and CEO",
      },
      {
        label: "Founder and CTO",
      },
      {
        label: "Founder and COO",
      },
      {
        label: "Founder and CMO",
      },
      {
        label: "Founder and CFO",
      },
    ],
  },
  {
    src: star2,
    name: "job_role",
    label: "Job Role",
    type: "Job Role",
    placeholder: "Enter or Select a Job Role",
    options: [
      {
        label: "Founder and CEO",
      },
      {
        label: "Founder and CTO",
      },
      {
        label: "Founder and COO",
      },
      {
        label: "Founder and CMO",
      },
      {
        label: "Founder and CFO",
      },
    ],
  },
  {
    src: zapFast,
    name: "job_sub_role",
    label: "Job Subrole",
    type: "Job Subrole",
    placeholder: "Enter or Select a Job Subrole",
    options: [
      {
        label: "Founder and CEO",
      },
      {
        label: "Founder and CTO",
      },
      {
        label: "Founder and COO",
      },
      {
        label: "Founder and CMO",
      },
      {
        label: "Founder and CFO",
      },
    ],
  },
  {
    src: target,
    name: "skill",
    label: "Skills",
    type: "Skills",
    placeholder: "Type a skill...",
    options: [
      {
        label: "Founder and CEO",
      },
      {
        label: "Founder and CTO",
      },
      {
        label: "Founder and COO",
      },
      {
        label: "Founder and CMO",
      },
      {
        label: "Founder and CFO",
      },
    ],
  },
  {
    src: graduationHat,
    name: "school",
    label: "School",
    type: "School",
    placeholder: "Enter or Select a School",
    options: [
      {
        label: "Founder and CEO",
      },
      {
        label: "Founder and CTO",
      },
      {
        label: "Founder and COO",
      },
      {
        label: "Founder and CMO",
      },
      {
        label: "Founder and CFO",
      },
    ],
  },
  {
    src: certificate,
    name: "major",
    label: "Major",
    type: "Major",
    placeholder: "Enter or Select a Major",
    options: [
      {
        label: "Founder and CEO",
      },
      {
        label: "Founder and CTO",
      },
      {
        label: "Founder and COO",
      },
      {
        label: "Founder and CMO",
      },
      {
        label: "Founder and CFO",
      },
    ],
  },
];
export const leadsRightProfile = [
  {
    src: building,
    label: "Company",
    type: "Company",
    name: "job_company",
    placeholder: "Type a company name",
  },
  {
    src: markerPin,
    label: "HQ Location",
    type: "HQ Location",
    name: "company_location",
    placeholder: "Type a location",
  },
  {
    src: users,
    label: "Headcount",
    type: "Headcount",
    name: "company_size",
    placeholder: "Select headcount from dropdown...",
    options: [
      { value: "1-10", label: "1-10" },
      { value: "11-50", label: "11-50" },
      { value: "51-200", label: "51-200" },
      { value: "201-500", label: "201-500" },
      { value: "501-1000", label: "501-1000" },
      { value: "1001-5000", label: "1001-5000" },
      { value: "5001-10000", label: "5001-10000" },
      { value: "10000+", label: "10000+" },
    ],
  },
  {
    src: industry,
    label: "Industry",
    type: "Industry",
    name: "company_industry",
    placeholder: "Type a industry",
    options: [],
  },
  {
    src: calendarDate,
    label: "Year Founded",
    type: "Year Founded",
    name: "year_founded_start",
    options: years,
  },
];
export const companyDropDownOptions = [
  { value: "First Name", label: "First Name" },
  { value: "Last Name", label: "Last Name" },
  { value: "company", label: "Company Name" },
  { value: "website", label: "Company Domain" },
  { value: "Linkedin URL", label: "Linkedin URL" },
  { value: "do_not_include", label: "Do Not Include" },
];

export const dropdownOptions = [
  { value: "company_name", label: "Company Name" },
  { value: "website_domain", label: "Company Domain" },
  { value: "do_not_include", label: "Do Not Include" },
];

export const uploadersData = [
  {
    icons: uploadCsv,
    title: "Find LinkedIn URLS",
    subTitle: "First and Last Name, Company Name",
    type: "uploadPeopleCsv",
    newChip: false,
  },
  {
    icons: csvLinkedIn,
    title: "Upload a CSV of URLs",
    subTitle: "LinkedIn URLs",
    type: "uploadCsvUrl",
    newChip: false,
    disabled: false,
  },
  {
    icons: csvEmail,
    title: "Upload a CSV of Emails",
    subTitle: "Find LinkedIn URLS from emails",
    type: "uploadCsvEmail",
    newChip: true,
    disabled: false,
  },
  {
    icons: csvCompany,
    title: "Upload a CSV of Companies",
    subTitle: "Company Name and Domain",
    type: "uploadCampanyCsv",
    newChip: false,
  },
  {
    icons: linkedInUrl,
    title: "Manually Enter LinkedIn URLs",
    subTitle: "Enrich your URLs",
    type: "manuallyLink",
    newChip: false,
  },
  {
    icons: companyUrl,
    title: "Manually Enter Company URLs",
    subTitle: "Find leads at the companies",
    type: "manuallyCompanyUrl",
    disabled: false,
    newChip: false,
  },
  {
    icons: ViaLinkedin,
    title: "via Linkedin Search URL",
    subTitle: "Pull leads from a LinkedIn Search",
    type: "viaLinkedin",
    disabled: false,
    newChip: false,
  },
  {
    icons: viaProfileUrl,
    title: "LinkedIn Post URL",
    subTitle: "Get profiles who reacted to a post",
    type: "viaProfileUrl",
    disabled: false,
    newChip: false,
  },
];

export const uploadersEncrichmentData = [
  {
    icons: csvIcon,
    title: "No Enrichment",
    subTitle: "Import your data as is.",
  },
  {
    icons: postIcon,
    title: "LinkedIn and Email",
    subTitle: "Enrich list for their email and LinkedIn.",
  },
];

export const jobSelectionOption = [
  { value: "Founder and CMO", label: "Founder and CMO" },
  { value: "Founder and CTO", label: "Founder and CTO" },
];
export const addTeamMembers = [
  {
    icons: candid,
    name: "Candice Wu",
    tagTitle: "@candice",
    profile: "You (Admin)",
  },
  {
    icons: twitter,
    name: "[Company Name] Workspace",
    tagTitle: "All Team Members",
    profile: "Editor",
  },
  {
    icons: demi,
    name: "Demi Wilkinson",
    tagTitle: "@demi",
    profile: "Editor",
  },
  {
    icons: drew,
    name: "Drew Cano",
    tagTitle: "Import a list of leads from LR",
    profile: "Editor",
  },
  {
    icons: natali,
    name: "Natali Crag",
    tagTitle: "@natali",
    profile: "Editor",
  },
];

export const hubSpotSelectOptions = [
  {
    label: "Lifecycle Stage",
    value: "Lifecycle Stage",
    imageUrl: hubspotOutlined,
  },
  {
    label: "Account Owner",
    value: "Account Owner",
    imageUrl: hubspotOutlined,
  },

  {
    label: "Keywords",
    value: "Keywords",
    imageUrl: hubspotOutlined,
  },

  {
    label: "Industry",
    value: "Industry",
    imageUrl: hubspotOutlined,
  },
];

export const hubSpotAssociationSelectoptions = [
  {
    label: "Sales Qualified Lead",
    value: "Sales Qualified Lead",
  },
  {
    label: "Rob Smith",
    value: "Rob Smith",
  },
  {
    label: "B2B",
    value: "B2B",
  },
  {
    label: "Lifecycle Stage",
    value: "Lifecycle Stage",
  },
];

export const viewPeopleDropdownOpt = [
  { label: "View People", value: "view_people" },
  { label: "View Company", value: "view_company" },
];
export const viewDropdownOpt = [
  { label: "View People", value: "view-people" },
  { label: "View Company", value: "view-company" },
];

export const sortOptions = [
  {
    name: "Default",
    data: [
      { label: "Default", value: "default" },
      { label: "Name", value: "full_name" },
      { label: "Job Title", value: "title" },
      { label: "Location", value: "location" },
    ],
  },
  {
    name: "view",
    data: [{ label: "Companies", value: "company" }],
  },
];
export const tableTypeData = [
  {
    name: "HubSpot Leads",
    commomKeyType: "import-hubspot",
    image: documentIcon,
    chips: [
      {
        value: "Live",
        textColor: "#15803D",
        bgColor: "#DCFCE7",
        active: true,
        activeColor: "#22C55E",
      },
      {
        value: "HubSpot",
        textColor: "#FF5A36",
        bgColor: "#FFE3DD",
        active: false,
        activeColor: "",
      },
      {
        value: "100/438 leads enriched",
        textColor: "#7E22CE",
        bgColor: "#F3E8FF",
        active: false,
        activeColor: "",
      },
      {
        value: "Active Campaign",
        textColor: "#4338CA",
        bgColor: "#E0E7FF",
        active: true,
        activeColor: "#6366F1",
      },
    ],
  },
  {
    name: "Founders in SF",
    image: logomark,
    commomKeyType: "founders-in-sf",
    chips: [
      {
        value: "12,438 Leads",
        textColor: "#3538CD",
        bgColor: "#EEF4FF",
        active: false,
        activeColor: "",
        borderColor: "#C7D7FE",
      },
      {
        icon: stars,
        value: "7 enriched",
        textColor: "#6941C6",
        bgColor: "#F9F5FF",
        active: true,
        activeColor: "#6366F1",
        borderColor: "#E9D7FE",
      },
    ],
    images: [
      { src: billingAvatarImg1, zIndex: 1, left: 16 },
      { src: billingAvatarImg2, zIndex: 2, left: 35 },
      { src: billingAvatarImg3, zIndex: 3, left: 55 },
      { src: billingAvatarImg4, zIndex: 4, left: 75 },
    ],
  },
  {
    name: "Healthcare CTOs",
    image: logomark,
    commomKeyType: "health-care",
    images: [{ src: billingAvatarImg1, zIndex: 1, left: 16 }],
    chips: [
      {
        value: "12,438 Leads",
        textColor: "#3538CD",
        bgColor: "#EEF4FF",
        active: false,
        activeColor: "",
        borderColor: "#C7D7FE",
      },
      {
        icon: stars,
        value: "0 enriched",
        textColor: "#6941C6",
        bgColor: "#F9F5FF",
        active: true,
        activeColor: "#6366F1",
        borderColor: "#E9D7FE",
      },
    ],
  },
  {
    name: "Series B Engineers",
    image: logomark,
    commomKeyType: "Series-B",
    images: [{ src: billingAvatarImg1, zIndex: 1, left: 16 }],
    chips: [
      {
        value: "400 Leads",
        textColor: "#3538CD",
        bgColor: "#EEF4FF",
        active: false,
        activeColor: "",
        borderColor: "#C7D7FE",
      },
      {
        icon: stars,
        value: "400 enriched",
        textColor: "#6941C6",
        bgColor: "#F9F5FF",
        active: true,
        activeColor: "#6366F1",
        borderColor: "#E9D7FE",
      },
      {
        icon: fileAttachment,
        value: "Imported",
        textColor: "#067647",
        bgColor: "#ECFDF3",
        active: true,
        activeColor: "#6366F1",
        borderColor: "#ABEFC6",
      },
    ],
  },

  {
    name: "a16z Partners",
    image: logomark,
    commomKeyType: "a16z",
    images: [{ src: billingAvatarImg1, zIndex: 1, left: 16 }],
    chips: [
      {
        value: "400 Leads",
        textColor: "#3538CD",
        bgColor: "#EEF4FF",
        active: false,
        activeColor: "",
        borderColor: "#C7D7FE",
      },
      {
        icon: stars,
        value: "400 enriched",
        textColor: "#6941C6",
        bgColor: "#F9F5FF",
        active: true,
        activeColor: "#6366F1",
        borderColor: "#E9D7FE",
      },
      {
        icon: fileAttachment,
        value: "Imported",
        textColor: "#067647",
        bgColor: "#ECFDF3",
        active: true,
        activeColor: "#6366F1",
        borderColor: "#ABEFC6",
      },
    ],
  },
  {
    name: "CEO's in Palo Alto",
    image: logomark,
    commomKeyType: "ceo",
    images: [{ src: billingAvatarImg1, zIndex: 1, left: 16 }],
    chips: [
      {
        value: "400 Leads",
        textColor: "#3538CD",
        bgColor: "#EEF4FF",
        active: false,
        activeColor: "",
        borderColor: "#C7D7FE",
      },
      {
        icon: stars,
        value: "400 enriched",
        textColor: "#6941C6",
        bgColor: "#F9F5FF",
        active: true,
        activeColor: "#6366F1",
        borderColor: "#E9D7FE",
      },
      {
        icon: fileAttachment,
        value: "Imported",
        textColor: "#067647",
        bgColor: "#ECFDF3",
        active: true,
        activeColor: "#6366F1",
        borderColor: "#ABEFC6",
      },
    ],
  },
  {
    name: "Lead List 4",
    image: logomark,
    commomKeyType: "lead-list",
    images: [{ src: billingAvatarImg1, zIndex: 1, left: 16 }],
    chips: [
      {
        value: "400 Leads",
        textColor: "#3538CD",
        bgColor: "#EEF4FF",
        active: false,
        activeColor: "",
        borderColor: "#C7D7FE",
      },
      {
        icon: stars,
        value: "400 enriched",
        textColor: "#6941C6",
        bgColor: "#F9F5FF",
        active: true,
        activeColor: "#6366F1",
        borderColor: "#E9D7FE",
      },
      {
        icon: fileAttachment,
        value: "Imported",
        textColor: "#067647",
        bgColor: "#ECFDF3",
        active: true,
        activeColor: "#6366F1",
        borderColor: "#ABEFC6",
      },
    ],
  },
];
export const settingsOptions = [
  { label: "Edit List Name", icon: editIcon },
  { label: "Archive", icon: archieveIcon },
  { label: "Export Enriched Leads", icon: exportIcon },
  { label: "Team Access", icon: shareIcon },
  { label: "Delete List", icon: deleteIcon },
];
export const countryTableData = [
  {
    companyName: "Jupiter Labs Inc",
    companyLogo: pinkCircleImg,
    images: [{ src: directionImg }],
    industryName: "Biotech",
    leadCount: "2",
    location: "Menlo Park, CA",
  },
  {
    companyName: "Jupiter Labs Inc",
    companyLogo: pinkCircleImg,
    images: [{ src: directionImg }],
    industryName: "Fintech",
    leadCount: "1",
    location: "Menlo Park, CA",
  },
  {
    companyName: "Jupiter Labs Inc",
    companyLogo: pinkCircleImg,
    images: [{ src: directionImg }],
    industryName: "Technology & Info",
    leadCount: "5",
    location: "Menlo Park, CA",
  },
  {
    companyName: "Jupiter Labs Inc",
    companyLogo: pinkCircleImg,
    images: [{ src: directionImg }],
    industryName: "Medical",
    leadCount: "1",
    location: "San Francisco, CA",
  },
  {
    companyName: "Jupiter Labs Inc",
    companyLogo: pinkCircleImg,
    images: [{ src: directionImg }],
    industryName: "Education",
    leadCount: "1",
    location: "San Francisco, CA",
  },
  {
    companyName: "Jupiter Labs Inc",
    companyLogo: pinkCircleImg,
    images: [{ src: directionImg }],
    industryName: "Biotech",
    leadCount: "1",
    location: "San Francisco, CA",
  },
  {
    companyName: "Jupiter Labs Inc",
    companyLogo: pinkCircleImg,
    images: [{ src: directionImg }],
    industryName: "Fintech",
    leadCount: "1",
    location: "San Francisco, CA",
  },
  {
    companyName: "Jupiter Labs Inc",
    companyLogo: pinkCircleImg,
    images: [{ src: directionImg }],
    industryName: "Technology & Info",
    leadCount: "1",
    location: "San Francisco, CA",
  },
  {
    companyName: "Jupiter Labs Inc",
    companyLogo: pinkCircleImg,
    images: [{ src: directionImg }],
    industryName: "Medical",
    leadCount: "1",
    location: "San Francisco, CA",
  },
  {
    companyName: "Jupiter Labs Inc",
    companyLogo: pinkCircleImg,
    images: [{ src: directionImg }],
    industryName: "Education",
    leadCount: "1",
    location: "San Francisco, CA",
  },
];

export const prospectingTabs = [
  {
    label: "All Lists",
    value: "all_list",
  },
  {
    label: "My Lists",
    value: "my_list",
  },
  {
    label: "Shared",
    value: "shared",
  },
  {
    label: "Imported",
    value: "imported",
  },
  {
    label: "Archive",
    value: "archive",
  },
];

export const connectionTabs = [
  {
    label: "Accepted",
    value: "accepted",
  },
  {
    label: "Invite Sent",
    value: "invite_sent",
  },
  {
    label: "Withdrawn",
    value: "withdrawn",
  },
];

export const dashboardTab = [
  {
    label: "24 Hours",
    value: "24 hours",
  },
  {
    label: "All Time",
    value: "all_time",
  },
];

export const settingPageTabs = [
  {
    label: "Profile",
    value: "Profile",
  },
  {
    label: "Collaborators",
    value: "Collaborators",
  },
  {
    label: "Blocklist",
    value: "Blocklist",
  },
  {
    label: "Integrations",
    value: "Integrations",
  },

  {
    label: "Notifications",
    value: "Notifications",
  },
  {
    label: "Billing",
    value: "Billing",
  },
];

export const campaignTabs = [
  {
    label: "All Campaigns",
    value: "All Campaigns",
  },
  {
    label: "Active",
    value: "Active",
  },
  {
    label: "Archive",
    value: "Archive",
  },
  {
    label: "Drafts",
    value: "Drafts",
  },
];
export const newCampaignTabs = [
  {
    label: "Leads",
    value: "Leads",
  },
  {
    label: "Campaign Steps",
    value: "Steps",
  },
  {
    label: "Settings",
    value: "Settings",
  },
  {
    label: "Review",
    value: "Review",
  },
];
export const CAMPAIGN_TABLEDATA = [
  {
    id: "c7e3d1b2-5d6f-43f1-9b9e-18a47f918b82",
    lastname: "Sequoia Partners",
    leadcount: "6/85",
    created: "Dec 30, 09:42 PM",
    listType: "Active",
    active: true,
    label: "Sequoia Partners",
    value: "Sequoia Partners",
    imageUrl: userIcon,
    isPublic: false,
    campaignSteps: [
      { title: "Start Campaign", type: "Start Campaign", icon: "" },
      {
        title: "View Profile",
        type: "View Profile",
        icon: "",
        order_number: 1,
        sequence_id: 1,
        value: "",
      },
      {
        title: "Send Connection Request",
        type: "Send Connection Request",
        icon: "",
        order_number: 3,
        sequence_id: 3,
        value: '{"inputValue":"","isSendRequestCheckBoxChecked":true}',
      },
      {
        title: "Follow-up Message",
        type: "Follow-up Message",
        icon: "",
        order_number: 4,
        sequence_id: 4,
        value: "",
      },
      {
        title: "Like a Post",
        type: "Like a Post",
        icon: "",
        order_number: 6,
        sequence_id: 6,
        value: "",
      },
      {
        title: "Like a Comment",
        type: "Like a Comment",
        icon: "",
        order_number: 7,
        sequence_id: 7,
        value: "",
      },
      {
        title: "Follow-up Message",
        type: "Follow-up Message",
        icon: "",
        order_number: 4,
        sequence_id: 4,
        value: "",
      },
    ],
    leadType: "Imported",
  },
  {
    id: "9a5c8e6d-3b1c-487d-9d67-f3b5b8a2c9e4",
    lastname: "Founders in SF",
    leadcount: "50/205",
    created: "Dec 30, 09:42 PM",
    listType: "Active",
    active: true,
    label: "Founders in SF",
    value: "Founders in SF",
    imageUrl: userIcon,
    isPublic: true,
    campaignSteps: [
      { title: "Start Campaign", type: "Start Campaign", icon: "" },
      {
        title: "View Profile",
        type: "View Profile",
        icon: "",
        sequence_id: 1,
        value: "",
      },
      {
        title: "Send Connection Request",
        type: "Send Connection Request",
        icon: "",
        sequence_id: 3,
        value: '{"inputValue":"","isSendRequestCheckBoxChecked":true}',
      },
      {
        title: "Follow-up Message",
        type: "Follow-up Message",
        icon: "",
        sequence_id: 4,
        value: "",
      },
      {
        title: "Like a Post",
        type: "Like a Post",
        icon: "",
        sequence_id: 6,
        value: "",
      },
      {
        title: "Like a Comment",
        type: "Like a Comment",
        icon: "",
        sequence_id: 7,
        value: "",
      },
      {
        title: "Follow-up Message",
        type: "Follow-up Message",
        icon: "",
        sequence_id: 4,
        value: "",
      },
    ],
    leadType: "Prospected",
  },
  {
    id: "7e2f2c0e-6b6b-4e98-9a4d-8e6b1d7f3d14",
    lastname: "Account Mangers",
    leadcount: "50/50",
    created: "Dec 30, 09:42 PM",
    listType: "Inactive",
    active: false,
    chips: [
      {
        value: "Completed",
        textColor: "#15803D",
        bgColor: "#DCFCE7",
        active: true,
        activeColor: "#22C55E",
      },
    ],
    label: "Account Mangers",
    value: "Account Mangers",
    imageUrl: userIcon,
    isPublic: false,
    campaignSteps: [
      { title: "Start Campaign", type: "Start Campaign", icon: "" },
      {
        title: "View Profile",
        type: "View Profile",
        icon: "",
        sequence_id: 1,
        value: "",
      },
      {
        title: "Send Connection Request",
        type: "Send Connection Request",
        icon: "",
        sequence_id: 3,
        value: '{"inputValue":"","isSendRequestCheckBoxChecked":true}',
      },
      {
        title: "Follow-up Message",
        type: "Follow-up Message",
        icon: "",
        sequence_id: 4,
        value: "",
      },
      {
        title: "Like a Post",
        type: "Like a Post",
        icon: "",
        sequence_id: 6,
        value: "",
      },
      {
        title: "Like a Comment",
        type: "Like a Comment",
        icon: "",
        sequence_id: 7,
        value: "",
      },
      {
        title: "Follow-up Message",
        type: "Follow-up Message",
        icon: "",
        sequence_id: 4,
        value: "",
      },
    ],
    leadType: "Prospected",
  },
  {
    id: "b0a9a54e-4f56-4b89-8c6b-df73b9e1a2f5",
    lastname: "July 10th Leads",
    leadcount: "50/200",
    created: "Dec 30, 09:42 PM",
    listType: "Archive",
    active: false,
    label: "July 10th Leads",
    value: "July 10th Leads",
    imageUrl: userIcon,
    isPublic: true,
    campaignSteps: [
      { title: "Start Campaign", type: "Start Campaign", icon: "" },
      {
        title: "View Profile",
        type: "View Profile",
        icon: "",
        sequence_id: 1,
        value: "",
      },
      {
        title: "Send Connection Request",
        type: "Send Connection Request",
        icon: "",
        sequence_id: 3,
        value: '{"inputValue":"","isSendRequestCheckBoxChecked":true}',
      },
      {
        title: "Follow-up Message",
        type: "Follow-up Message",
        icon: "",
        sequence_id: 4,
        value: "",
      },
      {
        title: "Like a Post",
        type: "Like a Post",
        icon: "",
        sequence_id: 6,
        value: "",
      },
      {
        title: "Like a Comment",
        type: "Like a Comment",
        icon: "",
        sequence_id: 7,
        value: "",
      },
      {
        title: "Follow-up Message",
        type: "Follow-up Message",
        icon: "",
        sequence_id: 4,
        value: "",
      },
    ],
    leadType: "Imported",
  },
  {
    id: "b0a9a54e-4f56-4b89-8c6b-df73b9e1567f5",
    lastname: "B2B Saas",
    leadcount: "0/200",
    created: "Dec 30, 09:42 PM",
    listType: "Archive",
    active: false,
    label: "B2B Saas",
    value: "B2B Saas",
    imageUrl: userIcon,
    isPublic: true,
    campaignSteps: [
      { title: "Start Campaign", type: "Start Campaign", icon: "" },
      {
        title: "View Profile",
        type: "View Profile",
        icon: "",
        sequence_id: 1,
        value: "",
      },
      {
        title: "Send Connection Request",
        type: "Send Connection Request",
        icon: "",
        sequence_id: 3,
        value: '{"inputValue":"","isSendRequestCheckBoxChecked":true}',
      },
      {
        title: "Follow-up Message",
        type: "Follow-up Message",
        icon: "",
        sequence_id: 4,
        value: "",
      },
      {
        title: "Like a Post",
        type: "Like a Post",
        icon: "",
        sequence_id: 6,
        value: "",
      },
      {
        title: "Like a Comment",
        type: "Like a Comment",
        icon: "",
        sequence_id: 7,
        value: "",
      },
      {
        title: "Follow-up Message",
        type: "Follow-up Message",
        icon: "",
        sequence_id: 4,
        value: "",
      },
    ],
    leadType: "Shared",
  },
  // {
  //   id: "b9f02814-cc73-4c70-9c8f-fb24a4d9b6fyc",
  //   lastname: "YC Batch ‘23",
  //   leadcount: "20/20",
  //   created: "Dec 30, 09:42 PM",
  //   listType: "Inactive",
  //   active: false,
  //   chips: [
  //     {
  //       value: "Completed",
  //       textColor: "#15803D",
  //       bgColor: "#DCFCE7",
  //       active: true,
  //       activeColor: "#22C55E",
  //     },
  //   ],
  //   label: "YC Batch ‘23",
  //   value: "YC Batch ‘22",
  //   imageUrl: userIcon,
  //   isPublic: false,
  //   campaignSteps: [
  //     { title: "Start Campaign", type: "Start Campaign", icon: "" },
  //     { title: "View Profile", type: "View Profile", icon: "" },
  //     {
  //       title: "Send Connection Request",
  //       type: "Send Connection Request",
  //       icon: "",
  //     },
  //     { title: "Follow-up Message", type: "Follow-up Message", icon: "" },
  //     { title: "Like a Post", type: "Like a Post", icon: "" },
  //     { title: "Like a Comment", type: "Like a Comment", icon: "" },
  //     { title: "Follow-up Message", type: "Follow-up Message", icon: "" },
  //   ],
  // },
  // {
  //   id: "b9f02814-cc73-4c70-9c8f-fb24a4d9b6sds",
  //   lastname: "YC Batch ‘23",
  //   leadcount: "20/20",
  //   created: "Dec 30, 09:42 PM",
  //   listType: "Drafts",
  //   active: false,
  //   label: "YC Batch ‘23",
  //   value: "YC Batch ‘21",
  //   imageUrl: userIcon,
  //   isPublic: true,
  //   campaignSteps: [
  //     { title: "Start Campaign", type: "Start Campaign", icon: "" },
  //     { title: "View Profile", type: "View Profile", icon: "" },
  //     {
  //       title: "Send Connection Request",
  //       type: "Send Connection Request",
  //       icon: "",
  //     },
  //     { title: "Follow-up Message", type: "Follow-up Message", icon: "" },
  //     { title: "Like a Post", type: "Like a Post", icon: "" },
  //     { title: "Like a Comment", type: "Like a Comment", icon: "" },
  //     { title: "Follow-up Message", type: "Follow-up Message", icon: "" },
  //   ],
  // },
  // {
  //   id: "b9f02814-cc73-4c70-9c8f-fb24a4d9b6ggh",
  //   lastname: "YC Batch ‘23",
  //   leadcount: "20/20",
  //   created: "Dec 30, 09:42 PM",
  //   listType: "Drafts",
  //   active: false,
  //   label: "YC Batch ‘23",
  //   value: "YC Batch ‘20",
  //   imageUrl: userIcon,
  //   isPublic: false,
  //   campaignSteps: [
  //     { title: "Start Campaign", type: "Start Campaign", icon: "" },
  //     { title: "View Profile", type: "View Profile", icon: "" },
  //     {
  //       title: "Send Connection Request",
  //       type: "Send Connection Request",
  //       icon: "",
  //     },
  //     { title: "Follow-up Message", type: "Follow-up Message", icon: "" },
  //     { title: "Like a Post", type: "Like a Post", icon: "" },
  //     { title: "Like a Comment", type: "Like a Comment", icon: "" },
  //     { title: "Follow-up Message", type: "Follow-up Message", icon: "" },
  //   ],
  // },
];

export const CAMPAIGN_LEAD_TOGGLELIST = [
  {
    id: 1,
    toggle: true,
    title: "Exclude Duplicates",
    description:
      "Enroll a new batch of leads from this lead list that haven’t been reached out to by another team member.",
  },
  {
    id: 2,
    toggle: true,
    title: "Include Duplicates",
    description:
      "Enroll leads from the list your team members have enrolled into a campaign (you will not be charged a credit for these leads)",
  },
];

export const chooseStepButtonList = [
  {
    title: "View Profile",
    type: "View Profile",
    icon: "",
    sequence_id: 1,
    value: "",
  },
  {
    title: "Waiting Period",
    type: "Waiting Period",
    icon: "",
    sequence_id: 2,
    value: "",
  },
  {
    title: "Send Connection Request",
    type: "Send Connection Request",
    icon: "",
    sequence_id: 3,
    value: '{"inputValue":"","isSendRequestCheckBoxChecked":true}',
  },
  {
    title: "Follow-up Message",
    type: "Follow-up Message",
    icon: "",
    sequence_id: 4,
    value: "",
  },
  {
    title: "Message Existing Connection",
    type: "Message Existing Connection",
    icon: "",
    sequence_id: 5,
    value: "",
  },
  {
    title: "Like a Post",
    type: "Like a Post",
    icon: "",
    sequence_id: 6,
    value: "",
  },
  {
    title: "Like a Comment",
    type: "Like a Comment",
    icon: "",
    sequence_id: 7,
    value: "",
  },
  {
    title: "AI Generate Steps",
    type: "AI Generate Steps",
    icon: PrimaryColorWand,
  },
];

export const CAMPAIGN_NAMES = [
  { label: "Cold Outreach", value: "Cold Outreach Campaign" },
  { label: "Lead Nurturing", value: "Lead Nurturing Sequence" },
  { label: "Follow-Up Drip", value: "Follow-Up Drip Campaign" },
  { label: "Re-Engagement", value: "Re-Engagement Campaign" },
  { label: "Event Invitation", value: "Event Invitation Campaign" },
  { label: "Product Launch", value: "Product Launch Campaign" },
  { label: "Customer Feedback", value: "Customer Feedback Campaign" },
  { label: "Referral Program", value: "Referral Program Campaign" },
  { label: "Seasonal Promotion", value: "Seasonal Promotion Campaign" },
];
export const billingTable = [
  {
    invoice: "Team Plan - Dec 2024",
    date: "Dec 1, 2024",
    type: "Monthly",
    amount: "USD $750",
    status: "",
    images: [
      { src: billingAvatar1, zIndex: 0, left: 16 },
      { src: billingAvatar2, zIndex: 1, left: 32 },
      { src: billingAvatar3, zIndex: 2, left: 49 },
      { src: billingAvatar4, zIndex: 3, left: 65 },
      { src: billingAvatar5, zIndex: 4, left: 82 },
    ],
    icon: downloadIcon,
  },
  {
    invoice: "Team Plan - Nov 2024",
    date: "Nov 1, 2024",
    type: "Monthly",
    amount: "USD $300",
    status: "paid",
    images: [
      { src: billingAvatar1, zIndex: 0, left: 16 },
      { src: billingAvatar2, zIndex: 1, left: 32 },
    ],
    icon: downloadIcon,
  },
  {
    invoice: "Basic Plan – Oct 2024",
    date: "Oct 1, 2024",
    type: "Monthly",
    amount: "USD $150.00",
    status: "paid",
    images: [{ src: billingAvatar1, zIndex: 0, left: 16 }],
    icon: downloadIcon,
  },
  {
    invoice: "Basic Plan – Sep 2024",
    date: "Sep 1, 2024",
    type: "Monthly",
    amount: "USD $150.00",
    status: "paid",
    images: [{ src: billingAvatar1, zIndex: 0, left: 16 }],
    icon: downloadIcon,
  },
  {
    invoice: "Basic Plan – Aug 2024",
    date: "Aug 1, 2024",
    type: "Monthly",
    amount: "USD $150.00",
    status: "paid",
    images: [{ src: billingAvatar1, zIndex: 0, left: 16 }],
    icon: downloadIcon,
  },
  {
    invoice: "Basic Plan – Aug 2024",
    date: "Jun 1, 2024",
    type: "Monthly",
    amount: "USD $150.00",
    status: "paid",
    images: [{ src: billingAvatar1, zIndex: 0, left: 16 }],
    icon: downloadIcon,
  },
];

export const notificationsSettings = [
  {
    title: "LinkedIn Notifications",
    description:
      "Get updates on any activity related to your LinkedIn account.",
    switches: ["Email", "Slack"],
  },
  {
    title: "Campaign Notifications",
    description: "Get updates on any activity related to your campaigns.",
    switches: ["Email", "Slack"],
  },
  {
    title: "Email",
    description:
      "These may include promotional emails, product updates and reminders.",
    switches: ["Email"],
  },
];

export const InitialReviewTableData = [
  {
    id: "d9a0ef8d-5b91-4f72-ae6e-3e8e7c1e1a0b",
    leadImg: Avatar,
    leadName: "Olivia Rhye",
    leadProfile: "Product Development",
    companyLogo: microsoft,
    companyName: "Microsoft",
    companyLink: "microsoft.com",
    action: "Remove",
  },
  {
    id: "d61bba2e-0b23-4e5f-9790-0b7d1ed2b4d2",
    leadImg: Avatar,
    leadName: "Olivia Rhye",
    leadProfile: "Product Development",
    companyLogo: microsoft,
    companyName: "Microsoft",
    companyLink: "microsoft.com",
    action: "Remove",
  },
  {
    id: "f1a4e810-b074-41e2-a5a6-2d2fc14e4e1e",
    leadImg: Avatar,
    leadName: "Olivia Rhye",
    leadProfile: "Product Development",
    companyLogo: microsoft,
    companyName: "Microsoft",
    companyLink: "microsoft.com",
    action: "Remove",
  },
  {
    id: "3e8c90e6-56c0-4c79-8b37-4d2b3f3422cb",
    leadImg: Avatar,
    leadName: "Olivia Rhye",
    leadProfile: "Product Development",
    companyLogo: microsoft,
    companyName: "Microsoft",
    companyLink: "microsoft.com",
    action: "Remove",
  },
  {
    id: "8a5a0d2e-9c43-4a73-bf4f-9d2d4d3d8c6f",
    leadImg: Avatar,
    leadName: "Olivia Rhye",
    leadProfile: "Product Development",
    companyLogo: microsoft,
    companyName: "Microsoft",
    companyLink: "microsoft.com",
    action: "Remove",
  },
  {
    id: "2d3b9c88-6e5a-4f2a-b8b5-20d5b6b57d0a",
    leadImg: Avatar,
    leadName: "Olivia Rhye",
    leadProfile: "Product Development",
    companyLogo: microsoft,
    companyName: "Microsoft",
    companyLink: "microsoft.com",
    action: "Remove",
  },
  {
    id: "f6a4d8b5-6ec6-41f1-b730-631de7e63a48",
    leadImg: Avatar,
    leadName: "Olivia Rhye",
    leadProfile: "Product Development",
    companyLogo: microsoft,
    companyName: "Microsoft",
    companyLink: "microsoft.com",
    action: "Remove",
  },
  {
    id: "2c5db14b-50b4-4dbe-83e7-705d1582724a",
    leadImg: Avatar,
    leadName: "Olivia Rhye",
    leadProfile: "Product Development",
    companyLogo: microsoft,
    companyName: "Microsoft",
    companyLink: "microsoft.com",
    action: "Remove",
  },
  {
    id: "9f6387b4-4f36-4c43-9a1b-d678f8eae5f2",
    leadImg: Avatar,
    leadName: "Olivia Rhye",
    leadProfile: "Product Development",
    companyLogo: microsoft,
    companyName: "Microsoft",
    companyLink: "microsoft.com",
    action: "Remove",
  },
  {
    id: "3b7d2ea0-1a91-4c45-bf5a-bc8d0725e012",
    leadImg: Avatar,
    leadName: "Olivia Rhye",
    leadProfile: "Product Development",
    companyLogo: microsoft,
    companyName: "Microsoft",
    companyLink: "microsoft.com",
    action: "Remove",
  },
  {
    id: "5f8e9e1a-cd39-4d94-b567-dc7491f4578e",
    leadImg: Avatar,
    leadName: "Olivia Rhye",
    leadProfile: "Product Development",
    companyLogo: microsoft,
    companyName: "Microsoft",
    companyLink: "microsoft.com",
    action: "Remove",
  },
  {
    id: "7e5c0d7b-4b5e-4e8b-b1b5-9bdfb2a8b14d",
    leadImg: Avatar,
    leadName: "Olivia Rhye",
    leadProfile: "Product Development",
    companyLogo: microsoft,
    companyName: "Microsoft",
    companyLink: "microsoft.com",
    action: "Remove",
  },
];

export const collaboratersTableData = [
  {
    id: "abc123",
    userImg: collaborater1,
    userName: "Olive Klien",
    userEmail: "olive@jupiter.ai",
    CreditUsage: "",
    isAdmin: true,
    date: "Feb 22, 2024",
    active: "Mar 14, 2024",
  },
  {
    id: "ghi789",
    userImg: nitali,
    userName: "Natali Craig",
    userEmail: "natali@jupiter.ai",
    CreditUsage: "234",
    isAdmin: false,
    date: "Feb 22, 2024",
    active: "Mar 14, 2024",
  },
  {
    id: "jkl012",
    userImg: collaborater4,
    userName: "Drew Cano",
    userEmail: "drew@jupiter.ai",
    CreditUsage: "43",
    isAdmin: false,
    date: "Feb 22, 2024",
    active: "Mar 14, 2024",
  },
  {
    id: "mno345",
    userImg: collaborater5,
    userName: "Orlando Diggs",
    userEmail: "orlando@jupiter.ai",
    CreditUsage: "23",
    isAdmin: false,
    date: "Feb 22, 2024",
    active: "Mar 14, 2024",
  },
  {
    id: "pqr678",
    userImg: collaborater6,
    userName: "Andi Lane",
    userEmail: "andi@jupiter.ai",
    CreditUsage: "233",
    isAdmin: false,
    date: "Feb 22, 2024",
    active: "Mar 14, 2024",
  },
  {
    id: "stu901",
    userImg: collaborater7,
    userName: "Kate Morrison",
    userEmail: "kate@jupiter.ai",
    CreditUsage: "566",
    isAdmin: false,
    date: "Feb 22, 2024",
    active: "Mar 14, 2024",
  },
];

export const JobLevelOptions = [
  { value: "VP", label: "VP" },
  { value: "Director", label: "Director" },
  { value: "Owner", label: "Owner" },
  { value: "Partner", label: "Partner" },
  { value: "Senior", label: "Senior" },
  { value: "Manager", label: "Manager" },
  { value: "CXO", label: "CXO" },
  { value: "Entry", label: "Entry" },
  { value: "Training", label: "Training" },
  { value: "Unpaid", label: "Unpaid" },
];

export const jobRoleOptions = [
  { value: "customer_service", label: "Customer Service" },
  { value: "design", label: "Design" },
  { value: "education", label: "Education" },
  { value: "engineering", label: "Engineering" },
  { value: "finance", label: "Finance" },
  { value: "health", label: "Health" },
  { value: "human_resources", label: "Human Resources" },
  { value: "legal", label: "Legal" },
  { value: "marketing", label: "Marketing" },
  { value: "media", label: "Media" },
  { value: "operations", label: "Operations" },
  { value: "public_relations", label: "Public Relations" },
  { value: "real_estate", label: "Real Estate" },
  { value: "sales", label: "Sales" },
];

export const subJobRoleOptions = [
  { value: "accounting", label: "Accounting" },
  { value: "accounts", label: "Accounts" },
  { value: "brand_marketing", label: "Brand Marketing" },
  { value: "broadcasting", label: "Broadcasting" },
  { value: "business_development", label: "Business Development" },
  { value: "compensation", label: "Compensation" },
  { value: "content_marketing", label: "Content Marketing" },
  { value: "customer_success", label: "Customer Success" },
  { value: "data", label: "Data" },
  { value: "dental", label: "Dental" },
  { value: "devops", label: "Devops" },
  { value: "doctor", label: "Doctor" },
  { value: "editorial", label: "Editorial" },
  { value: "education_administration", label: "Education Administration" },
  { value: "electrical", label: "Electrical" },
  { value: "employee_development", label: "Employee Development" },
  { value: "events", label: "Events" },
  { value: "fitness", label: "Fitness" },
  { value: "graphic_design", label: "Graphic Design" },
  { value: "information_technology", label: "Information Technology" },
  { value: "instructor", label: "Instructor" },
  { value: "investment", label: "Investment" },
  { value: "journalism", label: "Journalism" },
  { value: "judicial", label: "Judicial" },
  { value: "lawyer", label: "Lawyer" },
  { value: "logistics", label: "Logistics" },
  { value: "marketing_communications", label: "Marketing Communications" },
  { value: "mechanical", label: "Mechanical" },
  { value: "media_relations", label: "Media Relations" },
  { value: "network", label: "Network" },
  { value: "nursing", label: "Nursing" },
  { value: "office_management", label: "Office Management" },
  { value: "paralegal", label: "Paralegal" },
  { value: "pipeline", label: "Pipeline" },
  { value: "product", label: "Product" },
  { value: "product_design", label: "Product Design" },
  { value: "product_marketing", label: "Product Marketing" },
  { value: "professor", label: "Professor" },
  { value: "project_engineering", label: "Project Engineering" },
  { value: "project_management", label: "Project Management" },
  { value: "property_management", label: "Property Management" },
  { value: "quality_assurance", label: "Quality Assurance" },
  { value: "realtor", label: "Realtor" },
  { value: "recruiting", label: "Recruiting" },
  { value: "researcher", label: "Researcher" },
  { value: "security", label: "Security" },
  { value: "software", label: "Software" },
  { value: "support", label: "Support" },
  { value: "systems", label: "Systems" },
  { value: "tax", label: "Tax" },
  { value: "teacher", label: "Teacher" },
  { value: "therapy", label: "Therapy" },
  { value: "video", label: "Video" },
  { value: "web", label: "Web" },
  { value: "web_design", label: "Web Design" },
  { value: "wellness", label: "Wellness" },
  { value: "writing", label: "Writing" },
];

export const GlobalSchools = [
  { label: "Harvard University", location: "Cambridge, Massachusetts, USA" },
  { label: "University of Toronto", location: "Toronto, Ontario, Canada" },
  { label: "McGill University", location: "Montreal, Quebec, Canada" },
  {
    label: "University of British Columbia",
    location: "Vancouver, British Columbia, Canada",
  },
  { label: "Stanford University", location: "Stanford, California, USA" },
  {
    label: "Massachusetts Institute of Technology (MIT)",
    location: "Cambridge, Massachusetts, USA",
  },
  {
    label: "University of California, Los Angeles (UCLA)",
    location: "Los Angeles, California, USA",
  },
  { label: "University of Chicago", location: "Chicago, Illinois, USA" },
  {
    label: "California Institute of Technology (Caltech)",
    location: "Pasadena, California, USA",
  },
  { label: "University of Oxford", location: "Oxford, England, UK" },
  { label: "University of Cambridge", location: "Cambridge, England, UK" },
  { label: "ETH Zurich", location: "Zurich, Switzerland" },
  { label: "Imperial College London", location: "London, England, UK" },
  { label: "University College London (UCL)", location: "London, England, UK" },
  { label: "University of Edinburgh", location: "Edinburgh, Scotland, UK" },
  { label: "University of Amsterdam", location: "Amsterdam, Netherlands" },
  { label: "LMU Munich", location: "Munich, Germany" },
  { label: "Sorbonne University", location: "Paris, France" },
  { label: "University of Tokyo", location: "Tokyo, Japan" },
  { label: "National University of Singapore", location: "Singapore" },
  { label: "Tsinghua University", location: "Beijing, China" },
  { label: "Peking University", location: "Beijing, China" },
  { label: "Seoul National University", location: "Seoul, South Korea" },
  {
    label: "Hong Kong University of Science and Technology (HKUST)",
    location: "Hong Kong",
  },
  { label: "University of Hong Kong (HKU)", location: "Hong Kong" },
  { label: "Kyoto University", location: "Kyoto, Japan" },
  { label: "Nanyang Technological University (NTU)", location: "Singapore" },
  {
    label: "University of Sydney",
    location: "Sydney, New South Wales, Australia",
  },
  {
    label: "University of Melbourne",
    location: "Melbourne, Victoria, Australia",
  },
  {
    label: "Australian National University",
    location: "Canberra, Australian Capital Territory, Australia",
  },
  {
    label: "University of Queensland",
    location: "Brisbane, Queensland, Australia",
  },
  {
    label: "University of New South Wales (UNSW)",
    location: "Sydney, New South Wales, Australia",
  },
  { label: "University of São Paulo (USP)", location: "São Paulo, Brazil" },
  {
    label: "University of Buenos Aires (UBA)",
    location: "Buenos Aires, Argentina",
  },
  {
    label: "Pontifical Catholic University of Chile",
    location: "Santiago, Chile",
  },
  { label: "University of the Andes", location: "Bogotá, Colombia" },
  { label: "University of Cape Town", location: "Cape Town, South Africa" },
  {
    label: "University of the Witwatersrand",
    location: "Johannesburg, South Africa",
  },
  { label: "American University in Cairo", location: "Cairo, Egypt" },
  { label: "University of Nairobi", location: "Nairobi, Kenya" },
];

export const MajorSubjects = [
  {
    label: "Computer Science",
    description:
      "Study of computers and computational systems, including programming, algorithms, and software development.",
  },
  {
    label: "Creative Writing",
    description:
      "Study of computers and computational systems, including programming, algorithms, and software development.",
  },
  {
    label: "Criminal Justice",
    description:
      "Study of computers and computational systems, including programming, algorithms, and software development.",
  },
  {
    label: "Mechanical Engineering",
    description:
      "Field focused on the design, analysis, and manufacturing of mechanical systems and machinery.",
  },
  {
    label: "Civil Engineering",
    description:
      "Engineering discipline concerned with the design, construction, and maintenance of infrastructure such as roads, bridges, and buildings.",
  },
  {
    label: "Electrical Engineering",
    description:
      "Branch of engineering dealing with the study and application of electricity, electronics, and electromagnetism.",
  },
  {
    label: "Chemical Engineering",
    description:
      "Engineering field that combines chemistry, physics, mathematics, biology, and economics to efficiently use, produce, design, transport, and transform energy and materials.",
  },
  {
    label: "Biomedical Engineering",
    description:
      "Interdisciplinary field that applies engineering principles to medicine and biology for healthcare purposes.",
  },
  {
    label: "Economics",
    description:
      "Social science that studies the production, distribution, and consumption of goods and services.",
  },
  {
    label: "Psychology",
    description:
      "Study of the mind and behavior, encompassing various aspects including mental processes, development, and social interactions.",
  },
  {
    label: "Physics",
    description:
      "Natural science that involves the study of matter, energy, and the fundamental forces of nature, including concepts like motion, force, and energy.",
  },
  {
    label: "Mathematics",
    description:
      "Abstract science of numbers, quantity, and space, either as abstract concepts or as applied to other disciplines.",
  },
  {
    label: "Biology",
    description:
      "Science of life and living organisms, including their structure, function, growth, evolution, and distribution.",
  },
  {
    label: "Chemistry",
    description:
      "Science of matter and its interactions with energy and itself, including the study of substances, their properties, and reactions.",
  },
  {
    label: "History",
    description:
      "Study of past events, particularly in human affairs, focusing on understanding and interpreting historical developments and their impacts.",
  },
  {
    label: "Philosophy",
    description:
      "Study of fundamental questions regarding existence, knowledge, values, reason, and reality through critical analysis and argumentation.",
  },
  {
    label: "Political Science",
    description:
      "Study of politics, government systems, and political behavior, including the analysis of political activities and institutions.",
  },
  {
    label: "Sociology",
    description:
      "Study of society, social relationships, and social institutions, examining how individuals and groups interact and influence each other.",
  },
  {
    label: "Geography",
    description:
      "Study of the Earth's physical features and the human societies spread across it, including the relationships between them.",
  },
  {
    label: "Environmental Science",
    description:
      "Interdisciplinary field that studies the environment and the solutions to environmental problems, focusing on ecological systems and human impacts.",
  },
  {
    label: "Astronomy",
    description:
      "Study of celestial objects, space, and the universe as a whole, including the observation and analysis of stars, planets, and galaxies.",
  },
  {
    label: "Art",
    description:
      "Creative field encompassing various forms of visual and performing arts, including painting, sculpture, music, theater, and dance.",
  },
  {
    label: "Music",
    description:
      "Art form and cultural activity involving the creation of sound through vocal or instrumental means, including the study of theory, composition, and performance.",
  },
  {
    label: "Theater",
    description:
      "Performing art that involves live performance by actors on a stage, including the study of dramatic literature, stagecraft, and performance techniques.",
  },
  {
    label: "Architecture",
    description:
      "Field focused on the design and planning of buildings and structures, combining aesthetics with functionality and structural integrity.",
  },
  {
    label: "Business Administration",
    description:
      "Study of business operations, including management, finance, marketing, and strategic planning, aimed at preparing individuals for leadership roles in organizations.",
  },
  {
    label: "Law",
    description:
      "Study of legal systems, principles, and practices, including the interpretation and application of laws and legal processes.",
  },
];

export const SkillsArray = [
  // Technical Skills
  {
    label: "Figma",
    value: "Figma",
    description:
      "The ability to write code in various programming languages such as Python, Java, C++, JavaScript, and more.",
  },
  {
    label: "C++",
    value: "C++",
    description:
      "Proficiency in building websites and web applications using HTML, CSS, JavaScript, and frameworks like React.js, Angular, and Next.js.",
  },
  {
    label: "Accounting",
    value: "Accounting",
    description:
      "The ability to interpret and analyze data using tools like Excel, Python, R, or SQL to extract actionable insights.",
  },
  {
    label: "Python",
    value: "Python",
    description:
      "The skill of applying algorithms and statistical models to enable computers to improve tasks through experience.",
  },
  {
    label: "Recruiting",
    value: "Recruiting",
    description:
      "Knowledge of cloud platforms such as AWS, Google Cloud, and Microsoft Azure for deploying and managing applications.",
  },
  {
    label: "Finance",
    value: "Finance",
    description:
      "The ability to design, maintain, and query databases using SQL, NoSQL, and related technologies.",
  },
];

export const Locations = [
  { label: "Cambridge", value: "Cambridge" },
  { label: "Toronto", value: "Toronto" },
  { label: "Montreal", value: "Montreal" },
  { label: "Vancouver", value: "Vancouver" },
  { label: "Stanford", value: "Stanford" },
  { label: "Los Angeles", value: "Los Angeles" },
  { label: "Chicago", value: "Chicago" },
  { label: "Pasadena", value: "Pasadena" },
  { label: "Oxford", value: "Oxford" },
  { label: "Cambridge UK", value: "Cambridge UK" },
  { label: "Zurich", value: "Zurich" },
  { label: "London", value: "London" },
  { label: "Edinburgh", value: "Edinburgh" },
  { label: "Amsterdam", value: "Amsterdam" },
  { label: "Munich", value: "Munich" },
  { label: "Paris", value: "Paris" },
  { label: "Tokyo", value: "Tokyo" },
  { label: "Singapore", value: "Singapore" },
  { label: "Beijing", value: "Beijing" },
  { label: "Seoul", value: "Seoul" },
  { label: "HongKong", value: "HongKong" },
  { label: "Kyoto", value: "Kyoto" },
  { label: "Sydney", value: "Sydney" },
  { label: "Melbourne", value: "Melbourne" },
  { label: "Canberra", value: "Canberra" },
  { label: "Brisbane", value: "Brisbane" },
  { label: "SãoPaulo", value: "SãoPaulo" },
  { label: "Buenos Aires", value: "Buenos Aires" },
  { label: "Santiago", value: "Santiago" },
  { label: "Bogotá", value: "Bogotá" },
  { label: "Cape Town", value: "Cape Town" },
  { label: "Johannesburg", value: "Johannesburg" },
  { label: "Cairo", value: "Cairo" },
  { label: "Nairobi", value: "Nairobi" },
];

export const Companies = [
  {
    label: "Google",
    value: "Google",
  },
  {
    label: "Apple",
    value: "Apple",
  },
  {
    label: "Microsoft",
    value: "Microsoft",
  },
  {
    label: "Amazon",
    value: "Amazon",
  },
  {
    label: "Facebook",
    value: "Facebook",
  },
  {
    label: "Tesla",
    value: "Tesla",
  },
  {
    label: "Netflix",
    value: "Netflix",
  },
];

export const Industry = [
  { value: "Accounting", label: "Accounting" },
  { value: "Airlines/Aviation", label: "Airlines/Aviation" },
  {
    value: "Alternative Dispute Resolution",
    label: "Alternative Dispute Resolution",
  },
  { value: "Alternative Medicine", label: "Alternative Medicine" },
  { value: "Animation", label: "Animation" },
  { value: "Apparel & Fashion", label: "Apparel & Fashion" },
  { value: "Architecture & Planning", label: "Architecture & Planning" },
  { value: "Arts & Crafts", label: "Arts & Crafts" },
  { value: "Automotive", label: "Automotive" },
  { value: "Aviation & Aerospace", label: "Aviation & Aerospace" },
  { value: "Banking", label: "Banking" },
  { value: "Biotechnology", label: "Biotechnology" },
  { value: "Broadcast Media", label: "Broadcast Media" },
  { value: "Building Materials", label: "Building Materials" },
  {
    value: "Business Supplies & Equipment",
    label: "Business Supplies & Equipment",
  },
  { value: "Capital Markets", label: "Capital Markets" },
  { value: "Chemicals", label: "Chemicals" },
  { value: "Civil Engineering", label: "Civil Engineering" },
  {
    value: "Civic & Social Organization",
    label: "Civic & Social Organization",
  },
  { value: "Commercial Real Estate", label: "Commercial Real Estate" },
  {
    value: "Computer & Network Security",
    label: "Computer & Network Security",
  },
  { value: "Computer Games", label: "Computer Games" },
  { value: "Computer Hardware", label: "Computer Hardware" },
  { value: "Computer Networking", label: "Computer Networking" },
  { value: "Computer Software", label: "Computer Software" },
  { value: "Construction", label: "Construction" },
  { value: "Consumer Electronics", label: "Consumer Electronics" },
  { value: "Consumer Goods", label: "Consumer Goods" },
  { value: "Consumer Services", label: "Consumer Services" },
  { value: "Cosmetics", label: "Cosmetics" },
  { value: "Dairy", label: "Dairy" },
  { value: "Defense & Space", label: "Defense & Space" },
  { value: "Design", label: "Design" },
  { value: "E-Learning", label: "E-Learning" },
  { value: "Education Management", label: "Education Management" },
  {
    value: "Electrical & Electronic Manufacturing",
    label: "Electrical & Electronic Manufacturing",
  },
  { value: "Entertainment", label: "Entertainment" },
  { value: "Environmental Services", label: "Environmental Services" },
  { value: "Events Services", label: "Events Services" },
  { value: "Executive Office", label: "Executive Office" },
  { value: "Facilities Services", label: "Facilities Services" },
  { value: "Farming", label: "Farming" },
  { value: "Financial Services", label: "Financial Services" },
  { value: "Fine Art", label: "Fine Art" },
  { value: "Fishery", label: "Fishery" },
  { value: "Food & Beverages", label: "Food & Beverages" },
  { value: "Food Production", label: "Food Production" },
  { value: "Fundraising", label: "Fundraising" },
  { value: "Furniture", label: "Furniture" },
  { value: "Gambling & Casinos", label: "Gambling & Casinos" },
  { value: "Glass, Ceramics & Concrete", label: "Glass, Ceramics & Concrete" },
  { value: "Government Administration", label: "Government Administration" },
  { value: "Government Relations", label: "Government Relations" },
  { value: "Graphic Design", label: "Graphic Design" },
  { value: "Health, Wellness & Fitness", label: "Health, Wellness & Fitness" },
  { value: "Higher Education", label: "Higher Education" },
  { value: "Hospitality", label: "Hospitality" },
  { value: "Hospital & Health Care", label: "Hospital & Health Care" },
  { value: "Human Resources", label: "Human Resources" },
  { value: "Import & Export", label: "Import & Export" },
  {
    value: "Individual & Family Services",
    label: "Individual & Family Services",
  },
  { value: "Industrial Automation", label: "Industrial Automation" },
  { value: "Information Services", label: "Information Services" },
  {
    value: "Information Technology & Services",
    label: "Information Technology & Services",
  },
  { value: "Insurance", label: "Insurance" },
  { value: "International Affairs", label: "International Affairs" },
  {
    value: "International Trade & Development",
    label: "International Trade & Development",
  },
  { value: "Internet", label: "Internet" },
  { value: "Investment Banking", label: "Investment Banking" },
  { value: "Investment Management", label: "Investment Management" },
  { value: "Judiciary", label: "Judiciary" },
  { value: "Law Enforcement", label: "Law Enforcement" },
  { value: "Law Practice", label: "Law Practice" },
  { value: "Legal Services", label: "Legal Services" },
  { value: "Legislative Office", label: "Legislative Office" },
  { value: "Leisure, Travel & Tourism", label: "Leisure, Travel & Tourism" },
  { value: "Libraries", label: "Libraries" },
  { value: "Logistics & Supply Chain", label: "Logistics & Supply Chain" },
  { value: "Luxury Goods & Jewelry", label: "Luxury Goods & Jewelry" },
  { value: "Machinery", label: "Machinery" },
  { value: "Management Consulting", label: "Management Consulting" },
  { value: "Maritime", label: "Maritime" },
  { value: "Market Research", label: "Market Research" },
  { value: "Marketing & Advertising", label: "Marketing & Advertising" },
  {
    value: "Mechanical or Industrial Engineering",
    label: "Mechanical or Industrial Engineering",
  },
  { value: "Media Production", label: "Media Production" },
  { value: "Medical Device", label: "Medical Device" },
  { value: "Medical Practice", label: "Medical Practice" },
  { value: "Mental Health Care", label: "Mental Health Care" },
  { value: "Military", label: "Military" },
  { value: "Mining & Metals", label: "Mining & Metals" },
  { value: "Mobile Games", label: "Mobile Games" },
  { value: "Motion Pictures & Film", label: "Motion Pictures & Film" },
  { value: "Museums & Institutions", label: "Museums & Institutions" },
  { value: "Music", label: "Music" },
  { value: "Nanotechnology", label: "Nanotechnology" },
  { value: "Newspapers", label: "Newspapers" },
  {
    value: "Non-Profit Organization Management",
    label: "Non-Profit Organization Management",
  },
  { value: "Oil & Energy", label: "Oil & Energy" },
  { value: "Online Media", label: "Online Media" },
  { value: "Outsourcing/Offshoring", label: "Outsourcing/Offshoring" },
  { value: "Package/Freight Delivery", label: "Package/Freight Delivery" },
  { value: "Packaging & Containers", label: "Packaging & Containers" },
  { value: "Paper & Forest Products", label: "Paper & Forest Products" },
  { value: "Performing Arts", label: "Performing Arts" },
  { value: "Pharmaceuticals", label: "Pharmaceuticals" },
  { value: "Philanthropy", label: "Philanthropy" },
  { value: "Photography", label: "Photography" },
  { value: "Plastics", label: "Plastics" },
  { value: "Political Organization", label: "Political Organization" },
  {
    value: "Primary/Secondary Education",
    label: "Primary/Secondary Education",
  },
  { value: "Printing", label: "Printing" },
  {
    value: "Professional Training & Coaching",
    label: "Professional Training & Coaching",
  },
  { value: "Program Development", label: "Program Development" },
  { value: "Public Policy", label: "Public Policy" },
  {
    value: "Public Relations & Communications",
    label: "Public Relations & Communications",
  },
  { value: "Public Safety", label: "Public Safety" },
  { value: "Publishing", label: "Publishing" },
  { value: "Railroad Manufacture", label: "Railroad Manufacture" },
  { value: "Ranching", label: "Ranching" },
  { value: "Real Estate", label: "Real Estate" },
  {
    value: "Recreational Facilities & Services",
    label: "Recreational Facilities & Services",
  },
  { value: "Religious Institutions", label: "Religious Institutions" },
  { value: "Renewables & Environment", label: "Renewables & Environment" },
  { value: "Research", label: "Research" },
  { value: "Restaurants", label: "Restaurants" },
  { value: "Retail", label: "Retail" },
  { value: "Security & Investigations", label: "Security & Investigations" },
  { value: "Semiconductors", label: "Semiconductors" },
  { value: "Shipbuilding", label: "Shipbuilding" },
  { value: "Sporting Goods", label: "Sporting Goods" },
  { value: "Sports", label: "Sports" },
  { value: "Staffing & Recruiting", label: "Staffing & Recruiting" },
  { value: "Supermarkets", label: "Supermarkets" },
  { value: "Telecommunications", label: "Telecommunications" },
  { value: "Textiles", label: "Textiles" },
  { value: "Think Tanks", label: "Think Tanks" },
  { value: "Tobacco", label: "Tobacco" },
  { value: "Translation & Localization", label: "Translation & Localization" },
  {
    value: "Transportation/Trucking/Railroad",
    label: "Transportation/Trucking/Railroad",
  },
  { value: "Utilities", label: "Utilities" },
  {
    value: "Venture Capital & Private Equity",
    label: "Venture Capital & Private Equity",
  },
  { value: "Veterinary", label: "Veterinary" },
  { value: "Warehousing", label: "Warehousing" },
  { value: "Wholesale", label: "Wholesale" },
  { value: "Wireless", label: "Wireless" },
  { value: "Writing & Editing", label: "Writing & Editing" },
];
export const resultTable = [
  {
    id: "abc123",
    userImg: collaborater1,
    userName: "Natali Craig",
    userEmail: "Head of Product",
    CompanyLogo: microsfts,
    companyName: "Microsoft",
    companyLink: "microsoft.com",
    verified: true,
  },
  {
    id: "abc1234",
    userImg: drew,
    userName: "Drew Cano",
    userEmail: "Product Manager",
    CompanyLogo: facebookIcon,
    companyName: "Facebook",
    companyLink: "facebook.com",
    verified: true,
  },
  {
    id: "abc12345",
    userImg: orlando,
    userName: "Orlando Diggs",
    userEmail: "Lead PM",
    CompanyLogo: githubIcon,
    companyName: "Github",
    companyLink: "github.com",
    verified: true,
  },
  {
    id: "abc123456",
    userImg: collaborater3,
    userName: "Andi Lane",
    userEmail: "Product Specialist",
    CompanyLogo: linearIcon,
    companyName: "Linear",
    companyLink: "linear.io",
    verified: true,
  },
  {
    id: "abc1234567",
    userImg: demi,
    userName: "Kate Morrison",
    userEmail: "Head of Product Development",
    CompanyLogo: youTube,
    companyName: "Youtube",
    companyLink: "youtube.com",
    verified: true,
  },
];
// prospecting search result table
export const prospecting_search_result_table = [
  {
    avatar: avatarImg,
    userName: "Luna Thompson",
    userProfile: "Founder or CEO",
    companyLogo: catelog,
    companyName: "Catalog",
    companyLink: "catalogapp.io",
    images: [{ src: linkedInImg }, { src: twitterImg }],
    locationIcon: markerImg,
    location: "San Francisco, CA, USA",
  },
  {
    avatar: avatarImg,
    userName: "Orlando Diggs",
    userProfile: "CEO",
    companyLogo: circoole,
    companyName: "Circloes",
    companyLink: "getcircloes.com",
    images: [{ src: linkedInImg }, { src: twitterImg }],
    locationIcon: markerImg,
    location: "San Francisco, CA, USA",
  },
  {
    avatar: avatarImg,
    userName: "Drew Cano",
    userProfile: "Founder, CEO",
    companyLogo: hourGlass,
    companyName: "Hourglass",
    companyLink: "hourglass.app",
    images: [{ src: linkedInImg }, { src: twitterImg }],
    locationIcon: markerImg,
    location: "San Francisco, CA, USA",
  },
  {
    avatar: avatarImg,
    userName: "Andi Lane",
    userProfile: "CEO",
    companyLogo: circoole,
    companyName: "Circloes",
    companyLink: "getcircloes.com",
    images: [{ src: linkedInImg }, { src: twitterImg }],
    locationIcon: markerImg,
    location: "London, England, UK",
  },
  {
    avatar: avatarImg,
    userName: "Natal Craig",
    userProfile: "Founder",
    companyLogo: commandR,
    companyName: "Command+R",
    companyLink: "cmd+r.com",
    images: [{ src: linkedInImg }, { src: twitterImg }],
    locationIcon: markerImg,
    location: "San Francisco, CA, USA",
  },
  {
    avatar: avatarImg,
    userName: "Lana Steiner",
    userProfile: "CEO",
    companyLogo: layers,
    companyName: "Layers",
    companyLink: "layers.app",
    images: [{ src: linkedInImg }, { src: twitterImg }],
    locationIcon: markerImg,
    location: "San Francisco, CA, USA",
  },
  {
    avatar: avatarImg,
    userName: "Orlando Diggs",
    userProfile: "CEO",
    companyLogo: quotient,
    companyName: "Quotient",
    companyLink: "quotient.com",
    images: [{ src: linkedInImg }, { src: twitterImg }],
    locationIcon: markerImg,
    location: "San Francisco, CA, USA",
  },
  {
    avatar: avatarImg,
    userName: "Drew Cano",
    userProfile: "Founder, CEO",
    companyLogo: catelog,
    companyName: "Catalog",
    companyLink: "catalogapp.io",
    images: [{ src: linkedInImg }, { src: twitterImg }],
    locationIcon: markerImg,
    location: "San Francisco, CA, USA",
  },
  {
    avatar: avatarImg,
    userName: "Natal Craig",
    userProfile: "Founder",
    companyLogo: sisyphus,
    companyName: "Sisyphus",
    companyLink: "sisyphus.com",
    images: [{ src: linkedInImg }, { src: twitterImg }],
    locationIcon: markerImg,
    location: "San Francisco, CA, USA",
  },
  {
    avatar: avatarImg,
    userName: "Andi Lane",
    userProfile: "CEO",
    companyLogo: hourGlass,
    companyName: "Hourglass",
    companyLink: "hourglass.app",
    images: [{ src: linkedInImg }, { src: twitterImg }],
    locationIcon: markerImg,
    location: "San Francisco, CA, USA",
  },
  {
    avatar: avatarImg,
    userName: "Lana Steiner",
    userProfile: "Co-Founder",
    companyLogo: layers,
    companyName: "Layers",
    companyLink: "layers.app",
    images: [{ src: linkedInImg }, { src: twitterImg }],
    locationIcon: markerImg,
    location: "San Francisco, CA, USA",
  },
  {
    avatar: avatarImg,
    userName: "Orlando Diggs",
    userProfile: "CEO",
    companyLogo: circoole,
    companyName: "Circloes",
    companyLink: "getcircloes.com",
    images: [{ src: linkedInImg }, { src: twitterImg }],
    locationIcon: markerImg,
    location: "San Francisco, CA, USA",
  },
  {
    avatar: avatarImg,
    userName: "Drew Cano",
    userProfile: "Founder, CEO",
    companyLogo: commandR,
    companyName: "Command+R",
    companyLink: "cmd+r.com",
    images: [{ src: linkedInImg }, { src: twitterImg }],
    locationIcon: markerImg,
    location: "San Francisco, CA, USA",
  },
  {
    avatar: avatarImg,
    userName: "Luna Thompson",
    userProfile: "Founder or CEO",
    companyLogo: hourGlass,
    companyName: "Hourglass",
    companyLink: "hourglass.app",
    images: [{ src: linkedInImg }, { src: twitterImg }],
    locationIcon: markerImg,
    location: "San Francisco, CA, USA",
  },
  {
    avatar: avatarImg,
    userName: "Phoenix Baker",
    userProfile: "Founder",
    companyLogo: layers,
    companyName: "Layers",
    companyLink: "gaietyos.io",
    images: [{ src: linkedInImg }, { src: twitterImg }],
    locationIcon: markerImg,
    location: "San Francisco, CA, USA",
  },
  {
    avatar: avatarImg,
    userName: "Natal Craig",
    userProfile: "Founder",
    companyLogo: quotient,
    companyName: "Quotient",
    companyLink: "quotient.com",
    images: [{ src: linkedInImg }, { src: twitterImg }],
    locationIcon: markerImg,
    location: "San Francisco, CA, USA",
  },
  {
    avatar: avatarImg,
    userName: "Andi Lane",
    userProfile: "CEO",
    companyLogo: hourGlass,
    companyName: "Hourglass",
    companyLink: "hourglass.app",
    images: [{ src: linkedInImg }, { src: twitterImg }],
    locationIcon: markerImg,
    location: "San Francisco, CA, USA",
  },
  {
    avatar: avatarImg,
    userName: "Lana Steiner",
    userProfile: "Co-Founder",
    companyLogo: layers,
    companyName: "Layers",
    companyLink: "layers.app",
    images: [{ src: linkedInImg }, { src: twitterImg }],
    locationIcon: markerImg,
    location: "San Francisco, CA, USA",
  },
  {
    avatar: avatarImg,
    userName: "Orlando Diggs",
    userProfile: "CEO",
    companyLogo: commandR,
    companyName: "Command+R",
    companyLink: "cmd+r.com",
    images: [{ src: linkedInImg }, { src: twitterImg }],
    locationIcon: markerImg,
    location: "San Francisco, CA, USA",
  },
  {
    avatar: avatarImg,
    userName: "Luna Thompson",
    userProfile: "Founder or CEO",
    companyLogo: hourGlass,
    companyName: "Hourglass",
    companyLink: "hourglass.app",
    images: [{ src: linkedInImg }, { src: twitterImg }],
    locationIcon: markerImg,
    location: "San Francisco, CA, USA",
  },
  {
    avatar: avatarImg,
    userName: "Phoenix Baker",
    userProfile: "Founder",
    companyLogo: layers,
    companyName: "Layers",
    companyLink: "gaietyos.io",
    images: [{ src: linkedInImg }, { src: twitterImg }],
    locationIcon: markerImg,
    location: "San Francisco, CA, USA",
  },
];
export const country_table = [
  {
    avatar: avatarImg,
    userName: "Luna Thompson",
    userProfile: "Founder or CEO",
    companyLogo: catelog,
    companyName: "Catalog",
    companyLink: "catalogapp.io",
    images: [{ src: linkedInImg }, { src: twitterImg }],
    locationIcon: markerImg,
    location: "San Francisco, CA, USA",
  },
  {
    avatar: avatarImg,
    userName: "Orlando Diggs",
    userProfile: "CEO",
    companyLogo: catelog,
    companyName: "Catalog",
    companyLink: "catalogapp.io",
    images: [{ src: linkedInImg }, { src: twitterImg }],
    locationIcon: markerImg,
    location: "San Francisco, CA, USA",
  },
  {
    avatar: avatarImg,
    userName: "Drew Cano",
    userProfile: "Founder, CEO",
    companyLogo: catelog,
    companyName: "Catalog",
    companyLink: "catalogapp.io",
    images: [{ src: linkedInImg }, { src: twitterImg }],
    locationIcon: markerImg,
    location: "San Francisco, CA, USA",
  },
  {
    avatar: avatarImg,
    userName: "Andi Lane",
    userProfile: "CEO",
    companyLogo: circoole,
    companyName: "Circloes",
    companyLink: "getcircloes.com",
    images: [{ src: linkedInImg }, { src: twitterImg }],
    locationIcon: markerImg,
    location: "San Francisco, CA, USA",
  },
  {
    avatar: avatarImg,
    userName: "Natal Craig",
    userProfile: "Founder",
    companyLogo: circoole,
    companyName: "Circloes",
    companyLink: "getcircloes.com",
    images: [{ src: linkedInImg }, { src: twitterImg }],
    locationIcon: markerImg,
    location: "San Francisco, CA, USA",
  },
  {
    avatar: avatarImg,
    userName: "Lana Steiner",
    userProfile: "CEO",
    companyLogo: commandR,
    companyName: "Command+R",
    companyLink: "cmd+r.com",
    images: [{ src: linkedInImg }, { src: twitterImg }],
    locationIcon: markerImg,
    location: "San Francisco, CA, USA",
  },

  {
    avatar: avatarImg,
    userName: "Phoenix Baker",
    userProfile: "Founder",
    companyLogo: commandR,
    companyName: "Command+R",
    companyLink: "cmd+r.com",
    images: [{ src: linkedInImg }, { src: twitterImg }],
    locationIcon: markerImg,
    location: "San Francisco, CA, USA",
  },
];

export const DEFAULT_ACTIVITY_CARDS = [
  { name: "Invites Sent", value: 205 },
  { name: "New Connections", value: 90 },
  { name: "Follow-ups", value: 59 },
  { name: "Profiles Viewed", value: 205 },
  { name: "Liked Posts", value: 100 },
  { name: "Liked Comments", value: 100 },
];
export const DEFAULT_DASHBOARD_CARDS = [
  { name: "Invites Sent", value: 0 },
  { name: "New Connections", value: 0 },
  { name: "Follow-ups", value: 0 },
  { name: "Profiles Viewed", value: 0 },
  { name: "Liked Posts", value: 0 },
  { name: "Liked Comments", value: 0 },
];
export const purchaseCreditInfo = [
  {
    price: "$10",
    title: "100 Enrichment Credits",
    purchaseCount: "One Time Purchase",
    purchaseStatus: checkIcon,
    description: "These credits roll-over until used",
  },
  {
    price: "$25",
    title: "400 Enrichment Credits",
    purchaseCount: "One Time Purchase",
    purchaseStatus: checkIcon,
    description: "These credits roll-over until used",
  },
  {
    price: "$50",
    title: "1000 Enrichment Credits",
    purchaseCount: "One Time Purchase",
    purchaseStatus: checkIcon,
    description: "These credits roll-over until used",
  },
];

export const timeSlotsOptions = [
  { value: "00:00", label: "12:00 AM" },
  { value: "01:00", label: "01:00 AM" },
  { value: "02:00", label: "02:00 AM" },
  { value: "03:00", label: "03:00 AM" },
  { value: "04:00", label: "04:00 AM" },
  { value: "05:00", label: "05:00 AM" },
  { value: "06:00", label: "06:00 AM" },
  { value: "07:00", label: "07:00 AM" },
  { value: "08:00", label: "08:00 AM" },
  { value: "09:00", label: "09:00 AM" },
  { value: "10:00", label: "10:00 AM" },
  { value: "11:00", label: "11:00 AM" },
  { value: "12:00", label: "12:00 PM" },
  { value: "13:00", label: "01:00 PM" },
  { value: "14:00", label: "02:00 PM" },
  { value: "15:00", label: "03:00 PM" },
  { value: "16:00", label: "04:00 PM" },
  { value: "17:00", label: "05:00 PM" },
  { value: "18:00", label: "06:00 PM" },
  { value: "19:00", label: "07:00 PM" },
  { value: "20:00", label: "08:00 PM" },
  { value: "21:00", label: "09:00 PM" },
  { value: "22:00", label: "10:00 PM" },
  { value: "23:00", label: "11:00 PM" },
];

export const INITIAL_FIND_LEADS_FORM = {
  job_title: [],
  job_company: [],
  location: [],
  company_location: [],
  company_size: { values: [] },
  year_founded_start: "",
  year_founded_end: "",
  job_title_level: { values: [] },
  job_role: { values: [] },
  job_sub_role: { values: [] },
  company_industry: [],
  skill: [],
  first_name: [],
  last_name: [],
  major: [],
  school: [],
};

export const TABLE_DATA = [
  {
    id: "1a2b3c4d-1234-5678-abcd-1234567890ac",
    lastname: "Founders in SF",
    leadcount: "7/12000",
    commonKey: "founders-in-sf",
    images: [
      { src: billingAvatarImg1, zIndex: 1, left: 16 },
      { src: billingAvatarImg2, zIndex: 2, left: 35 },
      { src: billingAvatarImg3, zIndex: 3, left: 55 },
      { src: billingAvatarImg4, zIndex: 4, left: 75 },
    ],
    created: "12/01/2024 at 09:42 PM",
    socialIcon: linkedInImg,
    campaign: "Campaign Active",
    listType: "My Lists",
  },
  {
    id: "1a2b3c4d-1234-5678-abcd-1234567890ad",
    lastname: "Series B - Engineers",
    leadcount: "400/400",
    commonKey: "Series-B",
    images: [{ src: billingAvatarImg1, zIndex: 1, left: 16 }],
    created: "01/01/2024 at 09:42 PM",
    socialIcon: linkedInImg,
    campaign: "Campaign Active",
    listType: "My Lists",
  },
  {
    id: "1a2b3c4d-1234-5678-abcd-1234567890ae",
    lastname: "Healthcare CTOs",
    leadcount: "0/832",
    commonKey: "health-care",
    images: [{ src: billingAvatarImg1, zIndex: 1, left: 16 }],
    created: "12/01/2024 at 09:47 PM",
    chips: [
      {
        value: "Live",
        textColor: "#15803D",
        bgColor: "#DCFCE7",
        active: true,
        activeColor: "#22C55E",
      },
    ],
    message: "+22",
    socialIcon: linkedInImg,
    // campaign: "Campaign Active",
    listType: "My Lists",
  },
  {
    id: "1a2b3c4d-1234-5678-abcd-1234567890af",
    lastname: "a16z Partners",
    leadcount: "20/5000",
    commonKey: "a16z",
    listType: "Imported",
    images: [
      { src: billingAvatarImg1, zIndex: 1, left: 16 },
      { src: billingAvatarImg4, zIndex: 2, left: 35 },
    ],
    created: "12/01/2024 at 09:23 PM",
    socialIcon: linkedInImg,
    // campaign: "Campaign Active",
    chips: [
      {
        value: "HubSpot",
        textColor: "#FF5A36",
        bgColor: "#FFE3DD",
        active: false,
        activeColor: "",
      },
      {
        value: "Live",
        textColor: "#15803D",
        bgColor: "#DCFCE7",
        active: true,
        activeColor: "#22C55E",
      },
    ],
  },
  {
    id: "1a2b3c4d-1234-5678-abcd-1234567890b0",
    lastname: "Lead List 4",
    leadcount: "100/232000",
    commonKey: "lead-list",
    images: [
      { src: billingAvatarImg2, zIndex: 1, left: 16 },
      { src: billingAvatarImg3, zIndex: 2, left: 35 },
      { src: billingAvatarImg4, zIndex: 3, left: 55 },
      { src: billingAvatarImg1, zIndex: 4, left: 75 },
      { src: billingAvatarImg5, zIndex: 5, left: 95 },
    ],
    created: "12/01/2024 at 07:45 PM",
    socialIcon: linkedInImg,
    // campaign: "Campaign Active",
    listType: "Imported",
  },
  {
    id: "1a2b3c4d-1234-5678-abcd-1234567890b1",
    lastname: "CEO’s in Palo Alto",
    leadcount: "100/600",
    commonKey: "ceo",
    listType: "Archive",
    images: [
      { src: billingAvatarImg3, zIndex: 1, left: 16 },
      { src: billingAvatarImg1, zIndex: 2, left: 35 },
      { src: billingAvatarImg2, zIndex: 3, left: 55 },
    ],
    created: "11/01/2024 at 09:42 PM",
    socialIcon: linkedInImg,
    campaign: "Campaign Active",
  },
];

export const dashboardGetStartedLink = [
  {
    title: "Video Walkthrough",
    icon: dashboardVideo,
    actionIcon: actionRightArrow,
  },
  {
    title: "How to Prospect Leads",
    icon: dashboardMagic,
    actionIcon: actionRightArrow,
  },
  {
    title: "Launch a Campaign",
    icon: dashboardCampaign,
    actionIcon: actionRightArrow,
    link: " https://pipeline-knowledge-base.help.usepylon.com/articles/2660296029-how-to-create-a-campaign",
  },
];

export const INITIAL_NEW_CAMPAIGN_STEPS = [
  {
    title: "Start Campaign",
    type: "Start Campaign",
    icon: "",
    sequence_id: "",
    value: "",
  },
  {
    title: "View Profile",
    type: "View Profile",
    icon: "",
    sequence_id: 1,
    value: "",
  },
];

export const randowGradientsBg = [
  "linear-gradient(180deg, #A5C0EE 0%, #FBC5EC 100%)",
  "linear-gradient(180deg, #FBC2EB 0%, #A18CD1 105.25%)",
  "linear-gradient(225deg, #FAD0C4 0%, #FF9A9E 100%)",
  "linear-gradient(270deg, #FCB69F 0%, #FFECD2 100%)",
  "linear-gradient(180deg, #FECFEF 0%, #FF989C 100%)",
  "linear-gradient(45deg, #FF9DE4 0%, #FFEAF6 100%)",
  "linear-gradient(0deg, #D1FDFF 0%, #FDDB92 100%)",
  "linear-gradient(45deg, #7CDADA 0%, #F797AA 100%)",
  "linear-gradient(45deg, #AD00FE 0%, #00E0EE 100%)",
  "linear-gradient(180deg, #FFD1FF 0%, #FAD0C4 100%)",
  "linear-gradient(45deg, #4300B1 0%, #A531DC 100%)",
  "linear-gradient(135deg, #B01EFF 0%, #E1467C 100%)",
  "linear-gradient(45deg, #4A879A 0%, #C5EDF5 100%)",
  "linear-gradient(180deg, #F9D423 0%, #E14FAD 100%)",
  "linear-gradient(0deg, #7028E4 0%, #E5B2CA 100%)",
  "linear-gradient(135deg, #FFD3A5 0%, #FD6585 100%)",
  "linear-gradient(45deg, #39A0FF 0%, #8FFF85 100%)",
  "linear-gradient(315deg, #764BA2 0%, #667EEA 100%)",
];
export const randomProfileLogo = [
  {
    src: randomProfileLogo1,
  },
  {
    src: randomProfileLogo2,
  },
  {
    src: randomProfileLogo3,
  },
  {
    src: randomProfileLogo4,
  },
  {
    src: randomProfileLogo5,
  },
  {
    src: randomProfileLogo6,
  },
];
export const randomCompanyLogo = [
  {
    src: randomCompanyLogo1,
  },
  {
    src: randomCompanyLogo2,
  },
  {
    src: randomCompanyLogo3,
  },
  {
    src: randomCompanyLogo4,
  },
  {
    src: randomCompanyLogo5,
  },
  {
    src: randomCompanyLogo6,
  },
  {
    src: randomCompanyLogo7,
  },
  {
    src: randomCompanyLogo8,
  },
  {
    src: randomCompanyLogo9,
  },
  {
    src: randomCompanyLogo10,
  },
  {
    src: randomCompanyLogo11,
  },
  {
    src: randomCompanyLogo12,
  },
  {
    src: randomCompanyLogo13,
  },
  {
    src: randomCompanyLogo14,
  },
  {
    src: randomCompanyLogo15,
  },
  {
    src: randomCompanyLogo16,
  },
  {
    src: randomCompanyLogo17,
  },
  {
    src: randomCompanyLogo18,
  },
  {
    src: randomCompanyLogo19,
  },
  {
    src: randomCompanyLogo20,
  },
];

export const AiGenerateStepsNotSendConnectionRequest = [
  {
    title: "Send Connection Request",
    type: "Send Connection Request",
    icon: "",
    sequence_id: 3,
    delay: 1000,
    value: '{"inputValue":"","isSendRequestCheckBoxChecked":true}',
  },
  {
    title: "Follow-up Message",
    type: "Follow-up Message",
    icon: "",
    delay: 1500,
    sequence_id: 4,
    value: "",
  },
  {
    title: "Like a Post",
    type: "Like a Post",
    icon: "",
    delay: 2000,
    sequence_id: 6,
    value: "",
  },
  {
    title: "Waiting Period",
    type: "Waiting Period",
    icon: "",
    delay: 2500,
    sequence_id: 2,
    value: "",
  },
  {
    title: "Like a Comment",
    type: "Like a Comment",
    icon: "",
    delay: 3000,
    sequence_id: 7,
    value: "",
  },
];

export const AiGenerateStepsIsSendConnectionRequest = [
  {
    title: "Follow-up Message",
    type: "Follow-up Message",
    icon: "",
    delay: 1000,
    order_number: 4,
    value: "",
  },
  {
    title: "Like a Post",
    type: "Like a Post",
    icon: "",
    delay: 1500,
    sequence_id: 6,
    value: "",
  },
  {
    title: "Waiting Period",
    type: "Waiting Period",
    icon: "",
    delay: 2000,
    sequence_id: 2,
    order_number: 2,
    value: "",
  },
  {
    title: "Like a Comment",
    type: "Like a Comment",
    icon: "",
    delay: 2500,
    sequence_id: 7,
    value: "",
  },
];

export const AiGenerateStepsIsSendConnectionRequestIsFollowUp = [
  {
    title: "Waiting Period",
    type: "Waiting Period",
    icon: "",
    delay: 1000,
    sequence_id: 2,
    value: "",
  },
  {
    title: "Like a Post",
    type: "Like a Post",
    icon: "",
    delay: 1500,
    sequence_id: 6,
    value: "",
  },
  {
    title: "Waiting Period",
    type: "Waiting Period",
    icon: "",
    delay: 2000,
    sequence_id: 2,
    value: "",
  },
  {
    title: "Like a Comment",
    type: "Like a Comment",
    icon: "",
    delay: 2500,
    sequence_id: 7,
    value: "",
  },
];

export const AiGenerateIsLikeAPost = [
  {
    title: "Waiting Period",
    type: "Waiting Period",
    icon: "",
    delay: 1000,
    sequence_id: 2,
    value: "",
  },
  {
    title: "Like a Comment",
    type: "Like a Comment",
    icon: "",
    delay: 1500,
    sequence_id: 7,
    value: "",
  },
];

export const AiGenerateIsLikeAComment = [
  {
    title: "Waiting Period",
    type: "Waiting Period",
    icon: "",
    delay: 1000,
    sequence_id: 2,
    value: "",
  },
  {
    title: "Like a Post",
    type: "Like a Post",
    icon: "",
    delay: 1500,
    sequence_id: 6,
    value: "",
  },
];

export const AiGenerateDefaultSteps = [
  {
    title: "Waiting Period",
    type: "Waiting Period",
    icon: "",
    delay: 1500,
    sequence_id: 2,
    value: "",
  },
  {
    title: "Follow-up Message",
    type: "Follow-up Message",
    icon: "",
    delay: 2000,
    sequence_id: 4,
    value: "",
  },
];

export const timeZones = [
  { label: "US/Hawaii", value: "US/Hawaii" },
  { label: "US/Alaska", value: "US/Alaska" },
  { label: "US/Pacific", value: "US/Pacific" },
  { label: "US/Mountain", value: "US/Mountain" },
  { label: "US/Central", value: "US/Central" },
  { label: "US/Eastern", value: "US/Eastern" },
  { label: "UTC", value: "UTC" },
  { label: "Europe/London", value: "Europe/London" },
  { label: "Europe/Berlin", value: "Europe/Berlin" },
  { label: "Asia/Kolkata", value: "Asia/Kolkata" },
];

export const newTimeZones = [
  { label: "US/Hawaii", value: "US/Hawaii" },
  { label: "US/Alaska", value: "US/Alaska" },
  { label: "US/Pacific", value: "US/Pacific" },
  { label: "US/Mountain", value: "US/Mountain" },
  { label: "US/Central", value: "US/Central" },
  { label: "US/Eastern", value: "US/Eastern" },
  { label: "UTC", value: "UTC" },
  { label: "Europe/London", value: "Europe/London" },
  { label: "Europe/Berlin", value: "Europe/Berlin" },
  { label: "Asia/Kolkata", value: "Asia/Kolkata" },
];

export const TimeFrameOptions = [
  {
    label: "Last 24 Hours",
    value: "24 hours",
  },
  {
    label: "All Time",
    value: "all_time",
  },
];

export const CONTACT_OPTIONS = [
  "Send Connection Request",
  "Connection Request Accepted",
];

export const PYLON_APP_ID = "f11415e6-8e25-4194-9567-d9513a890a72";

export const pipelineResources = [
  {
    imgSrc: resource1,
    title: "Guided Video Tutorial",
    description:
      "Watch our CEO, Suds, guide you step-by-step through getting started and mastering Pipeline!",
    chips: [
      {
        name: "Tutorial",
        color: "#9E77ED",
      },
      {
        name: "Onboarding",
        color: "#EE46BC",
      },
    ],
  },
  {
    imgSrc: resource2,
    title: "How to Write a Message That Converts",
    description:
      "Master the art of cold messaging on LinkedIn. Learn how to make your first impression <strong>count</strong>.",
    chips: [
      {
        name: "Blog",
        color: "#9E77ED",
      },
      {
        name: "LinkedIn",
        color: "#4E63FF",
      },
    ],
  },
  {
    imgSrc: resource3,
    title: "Finding Leads that Convert",
    description:
      "Discover potential leads instantly using our comprehensive people database.",
    chips: [
      {
        name: "Blog",
        color: "#9E77ED",
      },
      {
        name: "Pipeline",
        color: "#B156E9",
      },
    ],
  },
];

export const SampleGuidedTourProspects = [
  {
    prospect_id: 1261,
    prospect_name: "Facebook Leads",
    prospected_type: "Imported",
    total_available_leads: 2,
    total_enriched_leads: 0,
    shared_users: [
      {
        name: "Member_999",
        email: "pipeline_member01@yopmail.com",
        profile_pic: "",
      },
    ],
    access_users: [
      {
        name: "test123",
        email: "testpipeline999@yopmail.com",
        profile_pic: "",
        is_owner: true,
        member_active: true,
      },
      {
        name: "Member_999",
        email: "pipeline_member01@yopmail.com",
        profile_pic: "",
        is_owner: false,
        member_active: true,
      },
    ],
    campaign_active: true,
    created_at: "2024-11-25T05:21:37.017926",
    is_shared: false,
    my_list: true,
    is_archived: false,
    tag: "Upload a CSV of Emails",
    non_enriched_leads: 2,
    actual_total_available_leads: 2,
    failed_enriched_leads_count: 2,
    display_failed_leads: true,
    get_prospect_and_campaign_leads_count: {
      total_leads_in_list: 2,
      enriched_leads_in_list: 0,
      unique_leads_enrolled_in_all_campaign: 0,
    },
  },
  {
    prospect_id: 1260,
    prospect_name: "Imported CSV Emails",
    prospected_type: "Imported",
    total_available_leads: 2,
    total_enriched_leads: 0,
    shared_users: [
      {
        name: "Member_999(1)",
        email: "pipeline_member02@yopmail.com",
        profile_pic: "",
      },
    ],
    access_users: [
      {
        name: "test123",
        email: "testpipeline999@yopmail.com",
        profile_pic: "",
        is_owner: true,
        member_active: true,
      },
      {
        name: "Member_999(1)",
        email: "pipeline_member02@yopmail.com",
        profile_pic: "",
        is_owner: false,
        member_active: true,
      },
    ],
    campaign_active: false,
    created_at: "2024-11-25T05:19:52.546610",
    is_shared: false,
    my_list: true,
    is_archived: false,
    tag: "Upload a CSV of Emails",
    non_enriched_leads: 2,
    actual_total_available_leads: 2,
    failed_enriched_leads_count: 2,
    display_failed_leads: true,
    get_prospect_and_campaign_leads_count: {
      total_leads_in_list: 2,
      enriched_leads_in_list: 0,
      unique_leads_enrolled_in_all_campaign: 0,
    },
  },
  {
    prospect_id: 1259,
    prospect_name: "React Js Devs 😅",
    prospected_type: "Imported",
    total_available_leads: 19,
    total_enriched_leads: 19,
    shared_users: [],
    access_users: [
      {
        name: "test123",
        email: "testpipeline999@yopmail.com",
        profile_pic: "",
        is_owner: true,
        member_active: true,
      },
    ],
    campaign_active: false,
    created_at: "2024-11-25T05:12:55.133709",
    is_shared: false,
    my_list: true,
    is_archived: false,
    tag: "Upload a CSV of URLs",
    non_enriched_leads: 0,
    actual_total_available_leads: 19,
    failed_enriched_leads_count: 0,
    display_failed_leads: true,
    get_prospect_and_campaign_leads_count: {
      total_leads_in_list: 19,
      enriched_leads_in_list: 19,
      unique_leads_enrolled_in_all_campaign: 0,
    },
  },
];

export const SampleGuidedTourCampaigns = [
  {
    campaign_id: 1419,
    campaign_name: "Angel Investors",
    invites_sent: 0,
    invites_accepted: 0,
    followup_sent: 0,
    profile_viewed: 0,
    like_posts: 0,
    like_comments: 0,
    created_ts: "2024-11-27T12:53:51.560873",
    active_status: true,
    progress: 90,
    campaign_setup: "setup_completed",
    list_id: 1272,
  },
  {
    campaign_id: 1419,
    campaign_name: "React Devs Campaign",
    invites_sent: 0,
    invites_accepted: 0,
    followup_sent: 0,
    profile_viewed: 0,
    like_posts: 0,
    like_comments: 0,
    created_ts: "2024-11-27T12:53:51.560873",
    active_status: true,
    progress: 45,
    campaign_setup: "setup_completed",
    list_id: 1273,
  },
];

export const SampleGuidedTourConnections = [
  {
    lead_id: 5653,
    prospect_lead_id: 8795,
    linkedin_url: "https://linkedin.com/in/agusvivasdl",
    lead_name: "Agustin Vivas De Lorenzi",
    profile_pic:
      "https://media.licdn.com/dms/image/v2/D4D03AQH8o0e84HSI7g/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1727784193778?e=1737590400&v=beta&t=lDh3g-IyN_Ym29iZm30NyNjPVsb3EHj55-5p5FK4VJE",
    job_title: "Founding Member GTM",
    company_name: "DevRev",
    company_logo:
      "https://media.licdn.com/dms/image/v2/D560BAQH1ANTVz-JL_Q/company-logo_400_400/company-logo_400_400/0/1688417141093/devrev_logo?e=1740009600&v=beta&t=4eAfiiIwECEBlJO49QOsNe7FN2mCuvEGYKhCnJjxrr0",
    status_name: "ACCEPTED",
    last_action_ts: "2024-11-25T17:00:25.248744",
    campaign: [
      {
        campaign_name: "Mon, Nov 18: Send Connection Request",
        campaign_status: "ACTIVE",
        is_deleted: false,
      },
    ],
  },
  {
    lead_id: 5224,
    prospect_lead_id: 8004,
    linkedin_url: "https://linkedin.com/in/brian-owusu",
    lead_name: "Brian Owusu",
    profile_pic: "",
    job_title: "Growth and Venture Capital Investor, Expansion Capital",
    company_name: "Morgan Stanley",
    company_logo: "",
    status_name: "ACCEPTED",
    last_action_ts: "2024-11-15T17:00:42.719897",
    campaign: [
      {
        campaign_name: "Wed, Nov 13: 20 Leads, Send Invite",
        campaign_status: "COMPLETED",
        is_deleted: false,
      },
    ],
  },
  {
    lead_id: 5236,
    prospect_lead_id: 6783,
    linkedin_url: "https://linkedin.com/in/monicaxie",
    lead_name: "Monica Xie",
    profile_pic:
      "https://media.licdn.com/dms/image/v2/D5603AQHjlhrxnALNaQ/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1713470155776?e=1736985600&v=beta&t=Hma19aFq036RxMeipNjga6rSu8PUL5yRllgGYfHQFQQ",
    job_title: "Investor",
    company_name: "ZhenFund 真格基金",
    company_logo: "",
    status_name: "ACCEPTED",
    last_action_ts: "2024-11-14T05:00:51.857393",
    campaign: [
      {
        campaign_name: "Wed, Nov 13: 20 Leads, Send Invite",
        campaign_status: "COMPLETED",
        is_deleted: false,
      },
    ],
  },
];

export const SampleGuidedTourActivity = [
  {
    feed_id: 7042,
    lead_name: "Yom Hirpa",
    sequence_id: 1,
    sequence_name: "View Profile",
    linkedin_profile: "https://linkedin.com/in/yomiyu",
    created_ts: "2024-12-02T22:41:15.152747",
    profile_pic:
      "https://media.licdn.com/dms/image/v2/D5603AQFvL5H269v4Qw/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1667585784551?e=1738195200&v=beta&t=IRMJj3rBDaRGfaktUowfcGWHbtmeanRuSKhtaT89AGc",
  },
  {
    feed_id: 7041,
    lead_name: "Vatsalya A.",
    sequence_id: 1,
    sequence_name: "View Profile",
    linkedin_profile: "https://linkedin.com/in/vatsalya-a-74321aa8",
    created_ts: "2024-12-02T22:02:35.107932",
    profile_pic: "",
  },
  {
    feed_id: 7040,
    lead_name: "Willy Sanjaya",
    sequence_id: 1,
    sequence_name: "View Profile",
    linkedin_profile: "https://linkedin.com/in/willy-sanjaya-987a90b7",
    created_ts: "2024-12-02T21:37:44.834656",
    profile_pic:
      "https://media.licdn.com/dms/image/v2/C4D03AQFA6fO7kIRREw/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1656834435205?e=1738195200&v=beta&t=xNn5bdK9OlKTkfE1LEJMDcXBqZbw8maSRwvXyLLylCk",
  },
  {
    feed_id: 7039,
    lead_name: "Tarun S",
    sequence_id: 1,
    sequence_name: "View Profile",
    linkedin_profile: "https://linkedin.com/in/tarunshri",
    created_ts: "2024-12-02T20:57:30.316467",
    profile_pic:
      "https://media.licdn.com/dms/image/v2/C4E03AQFzCzR01nVOoQ/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1578937097345?e=1738195200&v=beta&t=ExY-ywMwRkJGuJXAigBHJzrNX4uAaKmOdAW2KoLzK7s",
  },
  {
    feed_id: 7038,
    lead_name: "Sneha Dakalia",
    sequence_id: 1,
    sequence_name: "View Profile",
    linkedin_profile: "https://linkedin.com/in/sneha-dakalia-28905a77",
    created_ts: "2024-12-02T20:40:28.771525",
    profile_pic: "",
  },
  {
    feed_id: 7037,
    lead_name: "Steven Miller",
    sequence_id: 1,
    sequence_name: "View Profile",
    linkedin_profile: "https://linkedin.com/in/steven-miller-55b171b8",
    created_ts: "2024-12-02T20:35:42.448543",
    profile_pic:
      "https://media.licdn.com/dms/image/v2/D4E03AQHE0EDcBE7-VQ/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1674742624296?e=1738195200&v=beta&t=UzHtYWdTXQL0b2P_zSpU2Nj09OsFiC4fB8N1b6qrSlQ",
  },
  {
    feed_id: 7036,
    lead_name: "Tara Desai",
    sequence_id: 1,
    sequence_name: "View Profile",
    linkedin_profile: "https://linkedin.com/in/taraleininger",
    created_ts: "2024-12-02T19:50:50.619974",
    profile_pic:
      "https://media.licdn.com/dms/image/v2/C5603AQGLSYtik-5TAA/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1653072215043?e=1738195200&v=beta&t=Hd7dLjVbVAx9w9MJAXvhCuD1o8hoG4ARtGQ83uGtomI",
  },
  {
    feed_id: 7035,
    lead_name: "Simon Verville",
    sequence_id: 1,
    sequence_name: "View Profile",
    linkedin_profile: "https://linkedin.com/in/simon-verville",
    created_ts: "2024-12-02T19:35:58.393292",
    profile_pic:
      "https://media.licdn.com/dms/image/v2/C5603AQHdw08wfJW1uw/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1604334043182?e=1738195200&v=beta&t=SVojFtVlEgF3nTTi56u2MpPBfzH2fAphr-5AJJhe0ks",
  },
  {
    feed_id: 7034,
    lead_name: "Shreya Prakash",
    sequence_id: 1,
    sequence_name: "View Profile",
    linkedin_profile: "https://linkedin.com/in/shreya-prakash-6562b24",
    created_ts: "2024-12-02T19:33:02.343672",
    profile_pic: "",
  },
];
