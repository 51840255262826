import AnimatedButton from "components/animatedButton";
import TwoFAIcon from "assets/images/2fa-lock.png";
import OTPInput from "otp-input-react";
import { useLinkedinCallback } from "services/auth-api";
import { toast } from "react-toastify";

const Step2FA = ({
  OTP,
  setOTP,
  startVerificationInterval,
  accountId,
  setLikedInData,
  setStep,
  setLoader,
  handleResendCode,
}) => {
  const linkedInCallback = useLinkedinCallback();

  const handleCallback = async (OTP) => {
    setLoader(true);
    try {
      const response = await linkedInCallback.mutateAsync({
        account_id: String(accountId),
        code: OTP,
      });

      if (response?.status === 201) {
        if (response?.message === "AccountCreated") {
          startVerificationInterval(String(accountId));
        }
      }

      if (response?.status === 202) {
        if (response?.checkpoint?.type === "2FA") {
          setLikedInData(response);
          setStep(7);
          setLoader(false);
        }
      }

      if (response?.status === 400) {
        setLoader(false);
        toast.error(response?.message);
      }
    } catch (error) {
      setLoader(false);
      setStep(0);
      if (error?.message) {
        toast.error(error?.message);
      }
    }
  };

  return (
    <div className="relative">
      <div
        className="!h-full  font-inter flex flex-col justify-center items-center !w-[450px] m-auto max-[767px]:h-auto  max-[767px]:p-[20px]  max-[767px]:pt-[50px]  max-[767px]:max-w-[100%] signin-card"
        data-aos="fade-in"
        data-aos-duration="2000"
      >
        <div className="w-[60px] h-[60px] flex items-center justify-center rounded-xl border border-[#333741] bg-[#0C111D]">
          <img
            src={TwoFAIcon}
            alt="Logo"
            className="h-[26px] w-[20px] block"
            style={{
              filter: "brightness(1.2)",
            }}
          />
        </div>
        <h1 className="text-[30px] text-center text-[#F5F5F6] font-[600] mt-[20px] mb-[12px]">
          2FA Code
        </h1>
        <p className="text-[16px] font-[400] text-center text-[#94969C] leading-[24px]">
          You have 2FA enabled, please enter the code.
        </p>
        <form className="mt-[22px] w-full" onSubmit={(e) => e.preventDefault()}>
          <div className="mb-[32px] flex items-center justify-center mr-[-20px]">
            <OTPInput
              value={OTP}
              onChange={setOTP}
              autoFocus
              OTPLength={6}
              otpType="number"
              disabled={false}
              inputClassName="otpInput !h-[56px] !px-[2px] !w-[40px] text-[28px] border border-[#CBCBE84F] rounded-[6px] bg-transparent text-[#fff] !outline-none focus:!border-[#8A43FF] focus:!border-[2px]"
            />
          </div>
          <div className="flex items-center justify-center">
            <button
              className="text-center w-fit font-[600] text-[14px] text-[#E0D0FB] mb-[20px] cursor-pointer"
              onClick={() => handleResendCode()}
            >
              Click to resend the code
            </button>
          </div>
          <AnimatedButton
            title="Submit"
            onClick={() => handleCallback(OTP)}
            disabled={OTP.length !== 6}
            className="!text-[15px] !font-[600] !h-[42px] !rounded-[7px] !bg-[#635BFF]"
            fontSize="!text-[15px]"
          />
        </form>
      </div>
    </div>
  );
};

export default Step2FA;
