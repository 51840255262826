const SwitchToggle = (props) => {
  const { checked, onChange, disabled, onKeyDown = ()=>null} = props;
  return (
    <>
      <label class="custom-switch">
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          onKeyDown={onKeyDown}
        />
        <span class="slider round"></span>
      </label>
    </>
  );
};

export default SwitchToggle;
