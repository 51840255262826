import { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import Button from "components/button";
import coinsIcon from "assets/images/purple-stacked-coins.svg";
import CommonCheckbox from "components/commonCheckbox";
import { usePurchaseCredits } from "services/payments-api";
import { useWorkSpaceCreditLimit } from "services/accounts-api";
import { useAccountStore } from "stores";
import { purchaseCreditInfo } from "constant";
import { toast } from "react-toastify";

const AddedPurchasedCredit = (props) => {
  const getCredits = usePurchaseCredits();
  const { data, refetch } = useWorkSpaceCreditLimit({ isEnabled: true });
  const { setWorkSpaceCreditLimit } = useAccountStore();
  const {
    isOpen,
    onClose,
    storePurchaseCredit,
    setIsPurchaseCreditModal,
    setCreditsAdded,
    setPurchaseSuccessfulModal,
    setStorePurchaseCredit,
    packageName,
    selectedPackage,
    creditsAdded,
  } = props;

  const [isChecked, setIsChecked] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const handlePurchaseCredits = async () => {
    if (!selectedPackage) {
      toast.error("Please select a package before proceeding.");
      return;
    }
    try {
      const payload = {
        package_name: packageName,
      };
      const response = await getCredits.mutateAsync(payload);
      if (response.status === "success") {
        refetch(data);
        const credits = response?.credits_added || 0;
        setCreditsAdded(credits);
        toast.success(response.message);
      }
    } catch (error) {
      console.error("Error purchasing credits:", error);
      toast.error("An error occurred while processing your request.");
    }
  };

  useEffect(() => {
    setStorePurchaseCredit(purchaseCreditInfo[0]);
  }, [setStorePurchaseCredit]);

  useEffect(() => {
    if (data?.credit_limit) {
      setWorkSpaceCreditLimit(data?.credit_limit + creditsAdded);
    }
  }, [creditsAdded]);

  useEffect(() => {
    if (isOpen) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [isOpen]);

  if (!isOpen && !isModalVisible) return null;
  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[300ms] ease-in-out ${
        isModalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className="fixed inset-0 bg-black opacity-10"
        onClick={onClose}
      ></div>
      <div
        className={`bg-white rounded-lg shadow-lg z-10 w-full max-w-[504px] relative transition-all ease-in-out duration-[300ms] ${
          isModalVisible ? "scale-100" : "scale-95"
        }`}
      >
        <IoClose
          className="text-[#667085] text-[20px] w-[24px] h-[24px] absolute right-[24px] top-[22px] cursor-pointer"
          onClick={onClose}
        />
        <div className="modal-scroll">
          <div className="p-[24px] flex items-center">
            <div
              className="flex justify-center items-center border-[#E4E7EC] border w-[48px] h-[48px] rounded-[10px] mr-[16px]"
              style={{
                boxShadow:
                  "0px 4px 8px 0px rgba(16, 24, 40, 0.05), 0px 2px 0px 0px rgba(16, 24, 40, 0.05), 0px 0px 1px 0px rgba(16, 24, 40, 0.18)",
              }}
            >
              <img src={coinsIcon} alt="coins" className="h-[22px] w-[22px]" />
            </div>
            <div>
              <h6 className="text-[#101828] font-[600] text-[18px] leading-[28px]">
                Add Enrichment Credits
              </h6>
              <p className="font-[400] text-[14px] text-[#475467]">
                Confirm your one-time purchase.
              </p>
            </div>
          </div>
          <div className="px-[24px] pt-[20px] border-t-[1px] border-[#E4E7EC]">
            {/* card */}
            <div className="flex w-full items-start flex-wrap">
              <div
                className={`w-[345px] mx-auto flex items-start border-[2px] transition duration-75 ease-in-out border-[#7F56D9] p-[20px] rounded-[12px] m-[8px]`} // Change border color based on checked state
              >
                <div className="w-full">
                  <h4 className="text-[24px] font-[600] text-[#101828] leading-[32px] m-0">
                    {storePurchaseCredit?.price}
                  </h4>
                  <div className="mb-[20px] mt-[8px]">
                    <p className="text-[16px] font-[600] text-[#101828] leading-[24px] mb-[2px]">
                      {storePurchaseCredit?.title}
                    </p>
                    <p className="text-[14px] font-[400] text-[#475467] leading-[20px] mb-[2px]">
                      {storePurchaseCredit?.purchaseCount}
                    </p>
                  </div>
                  <div className="flex">
                    <img
                      src={storePurchaseCredit?.purchaseStatus}
                      alt="round-check"
                      className="w-[24px] h-[24px] mr-[12px]"
                    />
                    <p className="text-[16px] font-[400] text-[#475467] leading-[24px]">
                      {storePurchaseCredit?.description}
                    </p>
                  </div>
                </div>
                <div
                  className="mr-[16px] creditCheckbox "
                  onClick={(e) => e.stopPropagation()}
                >
                  <CommonCheckbox checked={true} />
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between mt-[13px]">
              <p className="font-[600] text-[#101828] text-[16px] m-0">
                Total Due Today
              </p>
              <p className="text-[16px] font-[400] text-[#475467] leading-[20px]">
                {storePurchaseCredit?.price}
              </p>
            </div>

            <div className="flex w-full mt-[17px]">
              <div
                onClick={(e) => e.stopPropagation()}
                className="w-[10px] h-[10px] mr-[20px] creditCheckbox"
              >
                <CommonCheckbox
                  checked={isChecked}
                  onChange={() => setIsChecked(!isChecked)}
                />
              </div>
              <p className="text-[#101828] font-[500] text-[12px]">
                By clicking Purchase, you agree to be bound by our{" "}
                <a
                  href="https://withpipeline.com/tos"
                  target="_blank"
                  rel="noreferrer"
                  className="underline cursor-pointer"
                >
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  href="https://withpipeline.com/privacy"
                  target="_blank"
                  rel="noreferrer"
                  className="underline cursor-pointer"
                >
                  Privacy Policy
                </a>
                .
              </p>
            </div>
          </div>
          <div className="flex items-center justify-center pt-[33px] pb-[27px] px-[34px]">
            <Button
              title="Back"
              isWhiteBtn={true}
              onClick={() => {
                onClose();
                setIsPurchaseCreditModal(true);
              }}
              className="!text-[16px] !h-[44px] !font-[600] !text-[#344054] y-axis-shadow !rounded-[8px]"
            />
            <Button
              title="Purchase"
              secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9] ml-[12px]"
              className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow !rounded-[8px]"
              onClick={() => {
                handlePurchaseCredits();
                onClose();
                setPurchaseSuccessfulModal(true);
              }}
              disabled={!isChecked}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddedPurchasedCredit;
