import Button from "components/button";
import leftIcon from "assets/images/dark-left.svg";
import { HiPlusSm } from "react-icons/hi";
import Dropdown from "components/dropdown";
import { sortOptions, tableTypeData, viewPeopleDropdownOpt } from "constant";
import colorIcon from "assets/images/color-wand-outline.svg";
import { useEffect, useState } from "react";
import FilterDropdown from "components/filterDropdown";
import { IoMdSettings } from "react-icons/io";
import editIcon from "assets/images/edit.svg";
import archieveIcon from "assets/images/archive.svg";
import exportIcon from "assets/images/export.svg";
import shareIcon from "assets/images/share.svg";
import deleteIcon from "assets/images/action-delete.svg";
import logomark from "assets/images/Logomark.svg";
import duplicateIcon from "assets/images/duplicate.svg";
import verified from "assets/images/verified.svg";
import { useAuthStore, useCampaignStore, useProspectStore } from "stores";
import { useNavigate } from "react-router-dom";
import {
  useExportFailedEnrichedLeads,
  useProspectFiltersInfo,
} from "services/prospecting-api";
import Stars from "assets/images/stars.svg";
import ExcludedIcon from "assets/images/leads_excluded.svg";
import ArrowIcon from "assets/images/arrow-right.svg";
import PaperIcon from "assets/images/paper.png";
import fileIcon from "assets/images/file-attachment.svg";
import { Tooltip } from "react-tooltip";
import rb2b from "assets/images/jira1.png";
import { toast } from "react-toastify";
import { downloadCSV } from "utils/index";

const SearchResultHeader = (props) => {
  const {
    isEditModalOpen,
    setIsEditModalOpen,
    isArchiveModalOpen,
    setIsArchiveModalOpen,
    isShareModalOpen,
    setIsShareModalOpen,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    setIsExportModalOpen,
    isExportModalOpen,
    setIsModalOpen,
    selectedViewDropdown,
    handleDropdownSelect,
    totalItems,
    settingsMenuOpen,
    setSettingsMenuOpen,
    isEnriching,
    tag,
    notEnrichedCount,
    displayFailedLeads,
  } = props;
  const navigate = useNavigate();
  const { prospect } = useProspectStore();
  const { companyId } = useAuthStore();
  const { clearTrackerId } = useCampaignStore();
  const useGetProspectFiltersInfo = useProspectFiltersInfo();
  const [currentTableTypeData, setCurrentTableTypeData] = useState({});
  const [showExportFailedLeads, setShowExportFailedLeads] = useState(false);

  const exportFailedEnrichedLeads = useExportFailedEnrichedLeads();

  useEffect(() => {
    if (prospect) {
      const current = tableTypeData?.find((i) => i?.commomKeyType === prospect);
      setCurrentTableTypeData(current);
    }
  }, [prospect]);

  const prospectData = prospect;

  const handleDuplicateFilters = async () => {
    try {
      const response = await useGetProspectFiltersInfo.mutateAsync({
        company_id: companyId,
        prospect_id: prospect?.prospect_id || prospect?.prospects_id,
      });
      navigate("/findLeads", {
        state: { prospectData: { ...response, isShowExcludeFilters: false } },
      });
    } catch (error) {}
  };

  const handleExportFailedEnrichedeads = async () => {
    try {
      const response = await exportFailedEnrichedLeads?.mutateAsync({
        prospect_id: prospect?.prospects_id,
        is_people: ["view_people", "default"]?.includes(
          selectedViewDropdown?.value
        ),
      });
      toast.success("List Exported", {
        icon: () => <img src={PaperIcon} alt="PaperIcon" />,
      });
      downloadCSV(
        response,
        `exported_leads.csv`
      );
    } catch (error) {
      toast.success(error?.data?.detail);
    }
  };

  return (
    <>
      {!prospect ? (
        <div className="flex items-center justify-between py-[20px] pr-[31px] pl-[29px] header-gradient">
          <Button
            isWhiteBtn={true}
            isRounded="8px"
            isFullWidth={false}
            className="mr-[9px] y-axis-shadow !px-[10px]"
            title={
              <div className="flex items-center justify-center font-inter font-[500] text-[14px] !text-[#111827] !border-[#D1D5DB]">
                <img
                  src={leftIcon}
                  alt="import"
                  className="mr-[5px] max-w-[17px]"
                />
                Edit Filters
              </div>
            }
            onClick={() => {
              clearTrackerId();
              navigate(-1);
            }}
          />
          <div className="flex items-center justify-center">
            <img
              src={colorIcon}
              alt="rocket"
              className="w-[24px] h-[24px] mr-[7px] mt-[-4px]"
            />
            {selectedViewDropdown?.value === "view_people" ? (
              <h1 className="text-[24px] font-inter text-left text-[#4B4B4B] font-[500] leading-normal mb-0 w-full">
                You found{" "}
                <span className="text-[#000000] font-[800]">1,567</span> new
                leads.
              </h1>
            ) : (
              <h1 className="text-[24px] font-inter text-left text-[#4B4B4B] font-[500] leading-normal mb-0 w-full">
                You found{" "}
                <span className="text-[#000000] font-[800]">1,289</span> new
                companies.
              </h1>
            )}
          </div>
          <div className="flex items-center">
            <Dropdown
              options={viewPeopleDropdownOpt}
              initialName="View People"
              onSelect={handleDropdownSelect}
            />
            <Button
              isRounded="6px"
              isFullWidth={false}
              height="33px"
              className="ml-[6px]"
              secondaryColor="border-[#635BFF] bg-[#635BFF] hover:!text-[#635BFF]"
              title={
                <div className="flex items-center justify-center font-inter text-[14px] mt-[-2px]">
                  <HiPlusSm size={20} className="mr-[5px] mt-[1px]" />
                  Save List
                </div>
              }
              onClick={() => setIsModalOpen(true)}
            />
          </div>
        </div>
      ) : (
        <div className="flex items-end justify-between py-[18px] pr-[31px] pl-[29px]">
          <div className="flex items-center">
            <Button
              style={{
                boxShadow:
                  " 0px 1px 2px rgba(0, 0, 0, 0.05), inset 0px 0px 1px #D1D5DB,inset 0px 0px 0px #FFFFFF",
              }}
              isWhiteBtn={true}
              isFullWidth={false}
              className="!px-[10px] !w-[81px] !rounded-[6px]"
              title={
                <div className="flex items-center justify-center font-inter font-[500] text-[14px] !text-[#111827] !border-[#D1D5DB] mt-[-2px]">
                  <img
                    src={leftIcon}
                    alt="import"
                    className="mr-[5px] w-[17px] h-[17px]"
                  />
                  Back
                </div>
              }
              onClick={() => {
                clearTrackerId();
                navigate("/prospecting");
              }}
            />
            <div className="flex items-center justify-center mx-[15px]">
              <div className="flex mt-[2px]">
                {prospectData?.prospect_name && (
                  <div className="flex items-center justify-center">
                    {prospectData?.integration === "RB2B" ? (
                      <img
                        src={rb2b || logomark}
                        alt="documentIcon"
                        className="w-[25.2px] h-[25.2px] mr-[7px]"
                      />
                    ) : (
                      <img
                        src={currentTableTypeData?.image || logomark}
                        alt="documentIcon"
                        className="w-[25.2px] h-[25.2px] mr-[7px]"
                      />
                    )}
                    <h1 className="text-[24.3px] font-inter text-left text-[#000000] font-[500] leading-[29px] mb-0 mt-[-1px] w-full text-ellipsis overflow-hidden whitespace-nowrap max-w-[450px]">
                      {prospectData?.prospect_name}
                    </h1>
                  </div>
                )}

                {prospectData?.integration !== "RB2B" && (
                  <div className="flex items-center ml-[2px] justify-start ">
                    <p
                      className="mx-[4px] flex items-center min-h-[22px] font-[500] py-[2px] px-[7px] rounded-[16px] text-[12px] leading-[16px]"
                      style={{
                        backgroundColor: "#EEF4FF",
                        color: "#3538CD",
                        border: "0.8px solid",
                        borderColor: "#C7D7FE",
                      }}
                    >
                      {prospectData?.prospect_type === "Imported" && tag
                        ? tag === "via LinkedIn Search URL"
                          ? prospect?.total_leads
                          : (totalItems || prospect?.total_enriched_leads || 0)
                              ?.toString()
                              ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : (
                            prospect?.total_leads ||
                            prospect?.total_available_leads
                          )
                            ?.toString()
                            ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}{" "}
                      Leads
                    </p>

                    {!isEnriching ? (
                      <div
                        className="mx-[4px] flex items-center min-h-[22px] font-[500] py-[2px] px-[7px] rounded-[16px] text-[12px] leading-[16px]"
                        style={{
                          backgroundColor: "#F9F5FF",
                          color: "#6941C6",
                          border: "0.8px solid",
                          borderColor: "#E9D7FE",
                        }}
                      >
                        <img
                          src={Stars}
                          alt=""
                          className="h-[10px] w-[10px] mr-[2px]"
                        />
                        {(totalItems || prospect?.total_enriched_leads || 0)
                          ?.toString()
                          ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                        Enriched
                      </div>
                    ) : (
                      <div className="mx-[4px] bg-[#7F56D9] text-white flex items-center min-h-[22px] font-[500] py-[2px] px-[7px] rounded-[16px] text-[12px] leading-[16px]">
                        <div className="w-[12px] h-[12px] border-[2px] border-t-[3px] border-[#fff] border-solid rounded-full animate-spin border-t-[#8A43FF] mr-2" />
                        Enriching...
                      </div>
                    )}
                    {currentTableTypeData?.chips?.map((chip, index) => (
                      <>
                        <p
                          key={index}
                          className="mx-[4px] flex items-center min-h-[22px] font-[500] py-[2px] px-[7px] rounded-[16px] text-[12px] leading-[16px]"
                          style={{
                            backgroundColor: chip?.bgColor,
                            color: chip?.textColor,
                            border: "0.8px solid",
                            borderColor: chip?.borderColor,
                          }}
                        >
                          {chip?.active && (
                            <span className="w-[11px] h-[11px] mr-[2px]">
                              {" "}
                              <img
                                src={chip?.icon}
                                alt="stars"
                                className=" "
                              />{" "}
                            </span>
                          )}
                          {chip?.value}
                        </p>
                      </>
                    ))}
                    {prospectData?.prospect_type === "Imported" && (
                      <div
                        className="mx-[4px] flex items-center min-h-[22px] font-[500] py-[2px] px-[7px] rounded-[16px] text-[12px] leading-[16px]"
                        style={{
                          backgroundColor: "#ECFDF3",
                          color: "#067647",
                          border: "0.8px solid",
                          borderColor: "#ABEFC6",
                        }}
                      >
                        <img
                          src={fileIcon}
                          alt=""
                          className="h-[11px] w-[11px] mr-[2px]"
                        />
                        Imported
                      </div>
                    )}
                    {notEnrichedCount !== 0 &&
                      tag &&
                      !isEnriching &&
                      displayFailedLeads && (
                        <>
                          {" "}
                          <div
                            onClick={() => {
                              if (exportFailedEnrichedLeads?.isPending) {
                                return;
                              }
                              handleExportFailedEnrichedeads();
                            }}
                            className="mx-[4px] transition-all duration-[1500ms] ease-in-out flex items-center min-h-[22px] font-[500] py-[2px] px-[7px] rounded-[8px] text-[12px] leading-[16px] cursor-pointer"
                            style={{
                              color: "#067647",
                              border: "0.8px solid",
                              borderColor: "#D5D7DA",
                            }}
                          >
                            <div
                              onMouseEnter={() => {
                                setShowExportFailedLeads(true);
                              }}
                              className="w-[12px] h-[12px] bg-[#F04438] border-[2px] border-[#FEE4E2] rounded-full"
                            ></div>
                            <div
                              className={`transition-all duration-[1500ms] ease-in-out overflow-hidden ${
                                showExportFailedLeads
                                  ? "max-w-[160px] opacity-100 text-ellipsis max-h-[12px] mt-[-2px]"
                                  : "max-w-0 opacity-0"
                              }`}
                            >
                              <div
                                className={`text-[#414651] font-[500] ml-[4px]  ${
                                  showExportFailedLeads
                                    ? "opacity-100 max-w-[160px] text-ellipsis max-h-[12px] mt-[-2px]"
                                    : "opacity-0 max-w-[0px] max-h-0"
                                }`}
                              >
                                {notEnrichedCount} could not be enriched
                              </div>
                            </div>
                            <div
                              onMouseEnter={() => {
                                setShowExportFailedLeads(true);
                              }}
                              className="border border-[#D5D7DA] rounded-sm px-2 py-[1px] ml-[8px] flex transition-all duration-[1500ms] ease-in-out"
                            >
                              {" "}
                              <div
                                className={`text-[#414651] font-[500] mr-[4px] ${
                                  showExportFailedLeads
                                    ? "opacity-100 min-w-[114px] text-ellipsis"
                                    : "opacity-0 max-w-[0px] max-h-0"
                                }`}
                              >
                                {exportFailedEnrichedLeads?.isPending
                                  ? "Exporting...."
                                  : "Export Failed Leads"}
                              </div>
                              <img src={ArrowIcon} alt="ArrowIcon" />
                            </div>
                          </div>
                        </>
                      )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex items-center relative">
            {currentTableTypeData?.images && (
              <img
                src={verified}
                alt="verified"
                className="absolute z-40 w-[10px] h-[10px] left-[18px] bottom-[4px] "
              />
            )}
            <div
              className="flex items-center relative"
              style={{
                minWidth: "50px",
                width:
                  currentTableTypeData?.images?.length === 1 ? "50px" : "110px",
              }}
            >
              {currentTableTypeData?.images?.map((image, index) => (
                <img
                  key={index}
                  src={image.src}
                  alt="user images"
                  className="w-[31px] h-[31px] mr-[6px] absolute"
                  style={{ left: image?.left, zIndex: image?.zIndex }}
                />
              ))}
            </div>

            <div className="mr-[5px]">
              <FilterDropdown
                options={sortOptions}
                initialName="Sort"
                onSelect={handleDropdownSelect}
              />
            </div>
            <div className="relative">
              <button
                className={`rounded-[6px] border-[1px] border-[#D1D5DB] h-[34px] w-[34px] flex items-center justify-center ml-[4px] relative ${
                  settingsMenuOpen && "purple-inner-shadow bg-[#8C45FF]"
                }`}
                onClick={() => setSettingsMenuOpen(!settingsMenuOpen)}
              >
                <IoMdSettings
                  className={`w-[20px] h-[20px] text-[#8C45FF] ${
                    settingsMenuOpen && "text-[#fff]"
                  }`}
                />
              </button>
              {settingsMenuOpen && (
                <>
                  <div
                    className="fixed top-0 left-0 w-full h-full"
                    onClick={() => setSettingsMenuOpen(false)}
                  />
                  <div
                    className={`bg-[#fff] border-[1px] border-[#E6E7E9] rounded-[6px] absolute actionButton-shadow pointer-events-auto z-[999999] right-[0px] mt-[8px] w-[192px] py-[2px]`}
                  >
                    <ul className="pt-[3px] pb-[2px]">
                      <li
                        className="flex items-center px-[12px] py-[6px] cursor-pointer text-[13px] font-[400] text-[#374151] hover:bg-[#cccccc1c]"
                        onClick={() => {
                          setIsEditModalOpen(!isEditModalOpen);
                        }}
                      >
                        <img
                          src={editIcon}
                          alt="editIcon"
                          className="w-[15px] h-[15px] mr-[9px]"
                        />
                        Edit List Name
                      </li>
                      <li
                        className="flex items-center px-[12px] py-[6px] cursor-pointer text-[13px] font-[400] text-[#374151] hover:bg-[#cccccc1c]"
                        onClick={() =>
                          setIsArchiveModalOpen(!isArchiveModalOpen)
                        }
                      >
                        <img
                          src={archieveIcon}
                          alt="editIcon"
                          className="w-[15px] h-[15px] mr-[9px]"
                        />
                        {prospect?.is_archived ? "Unarchive" : "Archive"}
                      </li>
                      {prospectData?.prospect_type !== "Imported" && (
                        <li
                          className="flex items-center px-[12px] py-[6px] cursor-pointer text-[13px] font-[400] text-[#374151] hover:bg-[#cccccc1c]"
                          onClick={handleDuplicateFilters}
                        >
                          <img
                            src={duplicateIcon}
                            alt="editIcon"
                            className="w-[15px] h-[15px] mr-[9px]"
                          />
                          Duplicate Filters
                        </li>
                      )}
                      <li
                        className="flex items-center px-[12px] py-[6px] border-b-[1px] border-b-[#F3F4F6] cursor-pointer text-[13px] font-[400] text-[#374151] hover:bg-[#cccccc1c]"
                        onClick={() => setIsExportModalOpen(!isExportModalOpen)}
                      >
                        <img
                          src={exportIcon}
                          alt="editIcon"
                          className="w-[15px] h-[15px] mr-[9px]"
                        />
                        Export Enriched Leads
                      </li>
                      {!prospect?.is_archived && (
                        <li
                          className="flex items-center px-[12px] py-[6px] border-b-[1px] border-b-[#F3F4F6] cursor-pointer text-[13px] font-[400] text-[#374151] hover:bg-[#cccccc1c]"
                          onClick={() => setIsShareModalOpen(!isShareModalOpen)}
                        >
                          <img
                            src={shareIcon}
                            alt="editIcon"
                            className="w-[15px] h-[15px] mr-[9px]"
                          />
                          Share
                        </li>
                      )}
                      <li
                        className="flex items-center px-[12px] py-[6px] cursor-pointer text-[13px] font-[400] text-[#374151] hover:bg-[#cccccc1c]"
                        onClick={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
                      >
                        <img
                          src={deleteIcon}
                          alt="editIcon"
                          className="w-[15px] h-[15px] mr-[9px]"
                        />
                        Delete List
                      </li>
                    </ul>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SearchResultHeader;
