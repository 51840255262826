import deleteImg from "assets/images/thumbs-down.svg";
import Button from "components/button";
import { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";

const SettingUploadFailed = ({ isOpen, setStep, onClose }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [isOpen]);

  if (!isOpen && !isModalVisible) return null;

  return (
    <>
      <div
        className={`fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[300ms] ease-in-out ${
          isModalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
      >
        <div
          className="fixed inset-0 bg-black opacity-10"
          onClick={onClose}
        ></div>
        <div
          className={`bg-white p-[24px] rounded-lg modal-shadow z-10 w-full max-w-[600px] relative transition-all ease-in-out duration-[150ms] ${
            isModalVisible ? "scale-100" : "scale-95"
          }`}
        >
          <IoClose
            className="text-[#667085] text-[20px] w-[24px] h-[24px] absolute right-[24px] top-[26px] cursor-pointer"
            onClick={onClose}
          />
          <div className="tems-center justify-center font-inter">
            <div className="modal-scroll">
              <div className="modal-scroll">
                <div className="flex flex-col items-start justify-start font-inter">
                  <div className="flex items-center justify-center mb-[16px]">
                    <img
                      src={deleteImg}
                      alt="info img"
                      className="h-[48px] w-[48px]"
                    />
                  </div>
                  <h4 className="font-[600] text-[18px] text-[#101828] m-[4px] mb-0 ml-0 font-inter">
                    Upload Failed
                  </h4>
                  <p className="font-[700] text-[14px] text-[#475467] font-inter mt-[9px]">
                    Please try again.
                  </p>
                </div>
              </div>
            </div>
            <Button
              title="Back to CSV Upload"
              isWhiteBtn={true}
              isRounded="6px"
              onClick={() => {
                onClose();
                setStep(1);
              }}
              className="!text-[16px] !h-[44px] !font-[600] !mb-[0px] !mt-[32px] y-axis-shadow mr-[12px] !text-[#344054]"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingUploadFailed;
