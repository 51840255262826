import Button from "components/button";
import emptyImg from "assets/images/empty-lead.svg";
import { MdOutlineAdd } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const SuccessResultStep = () => {
  const navigate = useNavigate();

  return (
    <div className="modal-scroll w-[480px]">
      <div className="max-w-[338px] mx-auto pt-[20px]">
        <div className="flex flex-col items-center justify-center mb-[20px] font-inter">
          <img
            src={emptyImg}
            alt="info img"
            className="h-[117px] w-[157px] mx-auto"
          />
          <h4 className="font-[600] !text-center text-[16.4px] text-[#101828] mt-[18px] mb-[7px] font-inter">
            Lead List Created!
          </h4>
          <p className="font-normal text-center text-[12.8px] text-[#8D9196] font-inter">
            When you create a campaign, you will pull from this list of saved
            leads to enrich them.
          </p>
        </div>
        <div className="flex items-center justify-center mt-[20px]">
          <Button
            title="New Search"
            isWhiteBtn={true}
            onClick={() => navigate("/findLeads")}
            className="!text-[16px] !h-[44px] !font-[600] !w-[129px] !text-[#344054] y-axis-shadow !rounded-[8px]"
          />
          <Button
            title={
              <div className="flex items-center justify-center font-inter !text-[16px] !font-[600] mt-[-2.5px]">
                <MdOutlineAdd className="text-[20px] mr-[7px] mt-[-0.5px]" />
                Create Campaign
              </div>
            }
            secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9] ml-[12px] !rounded-[8px]"
            className="!text-[16px] !h-[44px] !w-[197px] !font-[600] y-axis-shadow"
            onClick={() => navigate("/new-campaign")}
          />
        </div>
      </div>
    </div>
  );
};

export default SuccessResultStep;
