import trashIcon from "assets/images/dark-trash.svg";
import arrowIcon from "assets/images/up-arrow.svg";
import sentIcon1 from "assets/images/sent.svg";
import inviteSent from "assets/images/loveletter.png";
import collaborator from "assets/images/callobartor1.svg";
import EditIcon from "assets/images/edit-icon.svg";
import moment from "moment-timezone";
import { randomProfileLogo } from "constant";
import useRandomImagesStore from "stores/randomImagesStore";
import { useAuthStore, useSettingStore } from "stores";
import { useState } from "react";
import { getNameInititals } from "utils/index";
import { toast } from "react-toastify";
import TooltipItem from "components/campaigns/tooltip";
import { useResendInvite } from "services/settings-api";
import inviteLogo from "assets/images/inviteLogo.svg";

const CollaboratersTable = ({
  isDeleteModalOpen,
  setIsDeleteModalOpen,
  collaboratorsTableData,
  isAdminTable = false,
  setCurrentClickedUser,
  setEditEmailModal,
  setEditEmail,
  setIsUpgradeTeamModal,
  setCurrentId,
}) => {
  const { userData } = useSettingStore();
  const { profileImage } = useRandomImagesStore();
  const resendInvite = useResendInvite();
  const { isAdmin } = useAuthStore();

  const [tooltipVisibility, setTooltipVisibility] = useState({});

  const handleMouseEnter = (rowIndex, key) => {
    setTooltipVisibility((prev) => ({
      ...prev,
      [`${rowIndex}-${key}`]: true,
    }));
  };

  const handleMouseLeave = (rowIndex, key) => {
    setTooltipVisibility((prev) => ({
      ...prev,
      [`${rowIndex}-${key}`]: false,
    }));
  };

  const teamProfilePics = randomProfileLogo?.filter(
    (i) => i.src !== userData?.user?.profile_pic
  );

  const getRandomProfileLogo = (random) =>
    teamProfilePics[Math.floor(random * teamProfilePics.length)];

  const getRandomProfileLogoWithoutRandom = () =>
    teamProfilePics[Math.floor(Math.random() * teamProfilePics.length)];

  const TableHeader =
    isAdminTable || !isAdmin
      ? ["Name", "", "Date added", "Last active"]
      : ["Name", "", "Date added", "Last active", ""];

  const handleResend = async (id) => {
    const payload = {
      collaborator_id: id,
    };

    try {
      const response = await resendInvite.mutateAsync(payload);
      console.log(response, "response");
      if (response?.status === 200) {
        toast.success("Invite sent!", {
          icon: () => (
            <img
              src={inviteSent}
              alt="Invite Icon"
              className="!w-[46px] !h-[16px]"
            />
          ),
        });
      }
    } catch (error) {
      toast.error(error?.data?.detail);
    }
  };
  return (
    <div className="box-border rounded-tl-[12px] rounded-tr-[12px] rounded-bl-[12px] rounded-br-[12px] border border-[#E4E7EC]">
      <table className="min-w-full font-inter">
        <thead>
          <tr className="w-full bg-[#F9FAFB] border-b border-[#E4E7EC]">
            {TableHeader.map((header, index) => (
              <th
                key={index}
                className="text-[12px] font-[500] py-[12px] text-[#475467] text-left px-[24px] font-inter first:rounded-tl-[12px] last:rounded-tr-[12px]"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {collaboratorsTableData?.map((item, index) => {
            const isLastRow = index === collaboratorsTableData.length - 1;
            const isLoggedInUser = item?.email === userData?.user?.email;
            if (!isAdmin && item?.invite_status === "Pending") return;
            return (
              <tr
                className={`group hover:bg-gray-50 ${
                  isLastRow ? "" : "border-b border-[#E4E7EC]"
                }`}
                key={index}
              >
                <td
                  className={`text-[14px] px-[24px] py-[16px] text-[#101828] text-left  max-[1300px]:w-[unset]
                    ${isLastRow && "first:rounded-bl-[12px]"} `}
                >
                  <div className="flex items-center">
                    {isAdminTable || item?.invite_status === "Pending" ? (
                      <img
                        src={
                          item?.invite_status === "Pending"
                            ? inviteLogo
                            : item?.profile_pic
                            ? item.profile_pic
                            : isAdminTable
                            ? index === 0 && isAdmin
                              ? userData?.user?.profile_pic
                                ? userData?.user?.profile_pic
                                : getRandomProfileLogo(profileImage)?.src
                              : getRandomProfileLogoWithoutRandom()?.src
                            : collaborator
                        }
                        alt="lead"
                        className="w-[40px] min-w-[40px] h-[40px] mr-[12px] rounded-full"
                      />
                    ) : isLoggedInUser && item?.profile_pic ? (
                      <img
                        src={
                          item?.invite_status === "Pending"
                            ? inviteLogo
                            : item?.profile_pic || userData?.user?.profile_pic
                        }
                        alt="lead"
                        className="w-[40px] min-w-[40px] h-[40px] mr-[12px] rounded-full"
                      />
                    ) : (
                      <div className="w-[40px] min-w-[40px] h-[40px] rounded-full flex items-center justify-center text-[14px] font-[700] text-[#4409B9] bg-[#EEEDFD] mr-[12px]">
                        {getNameInititals(item?.name)}
                      </div>
                    )}
                    <div>
                      <p className="font-[500] [text-[#101828] text-[14px] m-0 leading-[17px] ">
                        {item?.name || item?.userName}{" "}
                        {!isAdminTable && !item?.name ? "Invite Sent" : ""}
                      </p>
                      <p className="font-[400] text-[#475467] text-[14px] m-0 leading-[17px]  pr-[10px]">
                        {item?.email || item?.userEmail}
                      </p>
                    </div>
                  </div>
                </td>
                {!isAdminTable ? (
                  <td className="text-[14px] px-[24px] py-[16px] font-[400] text-left  text-[#475467] !w-[145px] min-w-[150px] max-[1300px]:w-[unset]">
                    {item?.CreditUsage}
                  </td>
                ) : (
                  <td className="px-[24px] py-[16px] !w-[145px] min-w-[150px] max-[1300px]:w-[unset]"></td>
                )}
                <td className="text-[14px] px-[24px] py-[16px] font-[400] text-left  text-[#475467] !w-[145px] min-w-[150px] max-[1300px]:w-[unset]">
                  {moment(item?.created_ts).format("MMM DD, YYYY")}
                </td>
                <td
                  className={`${
                    isLastRow && "last:rounded-br-[12px]"
                  }  text-[14px] px-[24px] py-[16px] font-[400] text-left  text-[#475467] !w-[145px]  min-w-[150px] max-[1300px]:w-[unset]`}
                >
                  {item?.last_login_ts
                    ?  moment(item?.last_login_ts).format("MMM DD, YYYY")
                    : ""}
                </td>
                {!isAdminTable && isAdmin ? (
                  <>
                    <td
                      align="center"
                      className={`w-[200px] px-[22px] ${
                        isLastRow && "last:rounded-br-[12px]"
                      } `}
                    >
                      <div className="flex">
                        <div className="w-[36px] flex items-center justify-center">
                          <img
                            src={trashIcon}
                            alt="trashIcon"
                            className="w-[20px] h-[23px] cursor-pointer"
                            onClick={() => {
                              setCurrentClickedUser?.(item);
                              setIsDeleteModalOpen(!isDeleteModalOpen);
                            }}
                          />
                        </div>
                        {!item?.last_login_ts && (
                          <>
                            <div className="w-[30px] mr-2 flex items-center justify-center">
                              <img
                                src={EditIcon}
                                alt="edit"
                                className="w-[23px] h-[23px] cursor-pointer"
                                onClick={() => {
                                  setEditEmail(item);
                                  setEditEmailModal(true);
                                }}
                              />
                            </div>
                            {/* <div
                                className="cursor-pointer flex justify-center items-center border-[#E4E7EC] bg-[#fff] border w-[30px] min-w-[36px] h-[36px] rounded-[8px] y-axis-shadow"
                                onMouseEnter={() => setIsTooltipVisible(true)}
                                onMouseLeave={() => setIsTooltipVisible(false)}
                                onClick={() => {
                                  toast.success("Invite sent!", {
                                    icon: () => (
                                      <img
                                        src={inviteSent}
                                        alt="name"
                                        className="!w-[46px] !h-[16px]"
                                      />
                                    ),
                                  });
                                }}
                                // onClick={() => {
                                //   setCurrentClickedUser?.(item);
                                //   setIsUpgradeTeamModal(true);
                                // }}
                              >
                                <img
                                  src={sentIcon1}
                                  alt="coins"
                                  className="h-[18px] w-[18px]"
                                />
                              </div> */}
                            <div
                              className="cursor-pointer flex justify-center items-center border-[#E4E7EC] bg-[#fff] border w-[30px] min-w-[36px] h-[36px] rounded-[8px] y-axis-shadow"
                              onClick={() =>
                                handleResend(item?.collaborator_id)
                              }
                            >
                              <TooltipItem
                                key="sendInvite"
                                icon={sentIcon1}
                                value=""
                                text="Resend Invite"
                                hideWrapperMargin={true}
                                fontSize="!text-[14px] !w-auto !h-auto !mb-[12px]"
                                tooltipImgStyling="!w-[18px] !h-[18px] !m-0"
                                isVisible={
                                  tooltipVisibility[`${index}-sendInvite`] ||
                                  false
                                }
                                onMouseEnter={() =>
                                  handleMouseEnter(index, "sendInvite")
                                }
                                onMouseLeave={() =>
                                  handleMouseLeave(index, "sendInvite")
                                }
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </td>
                  </>
                ) : null}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
    // {isTooltipVisible && (
    //   <p className="absolute top-[25px] z-50 right-[-20px] mb-2 rounded-[8px] border-[1px] border-[#E9D7FE] text-[#6941C6] bg-[#F9F5FF] font-[500] text-[14px] py-[4px] px-[10px] transition-opacity duration-300 leading-[20px]">
    //     Resend Invite
    //   </p>
    // )}
  );
};

export default CollaboratersTable;
