import Select from "react-select";
import AnimatedButton from "components/animatedButton";
import { countryCodeOptions, countrySelectStyle } from "constant";
import OTPInput from "otp-input-react";
import { toast } from "react-toastify";
import InAppIcon from "assets/images/in-app.png";

const StepOTP = ({
  isPhoneCard,
  inputClasses,
  isFocused,
  setIsFocused,
  setSelectedCountryCode,
  selectedCountryCode,
  phoneNumber,
  handlePhoneNumberChange,
  handlePhoneVerify,
  OTP,
  setOTP,
  handleVerificationSubmit,
  handleResendCode,
}) => {
  return (
    <div className="relative">
      <div
        className="!h-full  font-inter flex flex-col justify-center items-center !w-[450px] m-auto max-[767px]:h-auto  max-[767px]:p-[20px]  max-[767px]:pt-[50px]  max-[767px]:max-w-[100%] signin-card"
        data-aos="fade-in"
        data-aos-duration="2000"
      >
        <div className="w-[60px] h-[60px] flex items-center justify-center rounded-xl border border-[#333741] bg-[#0C111D]">
          <img
            src={InAppIcon}
            alt="Logo"
            className="h-[26px] w-[20px] block"
            style={{
              filter: "brightness(1.2)",
            }}
          />
        </div>
        {isPhoneCard ? (
          <>
            <h1 className="text-[30px] text-center text-[#F5F5F6] font-[600] mt-[20px] mb-[12px]">
              Enter Phone Number
            </h1>
            <p className="text-[16px] font-[400] text-center text-[#94969C] leading-[24px]">
              Enter your phone number to receive <p>the verification code.</p>
            </p>
            <form
              className="mt-[22px] w-full"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="mb-[24px] flex flex-col justify-center mr-[-20px]">
                <p className="text-[14px] font-[500] text-[#CECFD2] mb-[6px]">
                  Phone Number <span className="text-[#7F56D9]">*</span>
                </p>
                <div
                  className={`flex-grow ${inputClasses} !w-[95%] !bg-[#0d0515] text-[#9e9ba0] relative flex ${
                    isFocused && "!border-[#9e9ba0]"
                  }`}
                >
                  <div className="w-[70px] absolute left-[5px] z-50 top-0.5 cursor-pointer  no-border-select">
                    <Select
                      defaultValue={countryCodeOptions[0]}
                      options={countryCodeOptions}
                      classNamePrefix="leads-react-select"
                      className="!font-inter w-full !border-none !border-black"
                      placeholder="US"
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.label}
                      maxMenuHeight="128px"
                      styles={countrySelectStyle}
                      isClearable
                      isSearchable={false}
                      noOptionsMessage={() => "No Results"}
                      onChange={(selectedOption) => {
                        setSelectedCountryCode(selectedOption);
                      }}
                    />
                  </div>
                  <p className="text-white pl-[60px] z-20">
                    +{selectedCountryCode?.value}
                  </p>
                  <input
                    type="tel"
                    placeholder=""
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    className={`flex-grow !bg-[#0d0515] w-[90%] !text-[#fff] !border-none mt-0.5 -ml-2`}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                  />
                </div>
                <p className="text-[12px] text-[#94969C] mt-2">
                  Please include the country code in brackets.
                  <p>e.g. for U.S. phone numbers (+1)6503334444</p>
                </p>
              </div>
              <AnimatedButton
                title="Next"
                disabled={phoneNumber.length < 10}
                onClick={() => handlePhoneVerify()}
                className="!text-[15px] !font-[600] !h-[42px] !rounded-[7px] !bg-[#635BFF]"
                fontSize="!text-[15px]"
              />
            </form>
          </>
        ) : (
          <>
            <h1 className="text-[30px] text-center text-[#F5F5F6] font-[600] mt-[20px] mb-[12px]">
              Enter Code
            </h1>
            <p className="text-[16px] font-[400] text-center text-[#94969C] leading-[24px]">
              Enter the verification code sent to your email or phone by
              LinkedIn.
            </p>
            <form
              className="mt-[22px] w-full"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="flex items-center justify-center mr-[-20px]">
                <OTPInput
                  value={OTP}
                  onChange={setOTP}
                  autoFocus
                  OTPLength={6}
                  otpType="number"
                  disabled={false}
                  inputClassName="otpInput !h-[56px] !px-[2px] !w-[40px] text-[28px] border border-[#CBCBE84F] rounded-[6px] bg-transparent text-[#fff] !outline-none focus:!border-[#8A43FF] focus:!border-[2px]"
                />
              </div>
              <div className="flex items-center justify-center">
                <button
                  className="text-center w-fit font-[600] text-[14px] text-[#E0D0FB] my-[20px] cursor-pointer"
                  onClick={() => handleResendCode()}
                >
                  Click to resend the code
                </button>
              </div>
              <AnimatedButton
                title="Next"
                onClick={() => handleVerificationSubmit()}
                disabled={OTP.length !== 6}
                className="!text-[15px] !font-[600] !h-[42px] !rounded-[7px] !bg-[#635BFF]"
                fontSize="!text-[15px]"
              />
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default StepOTP;
