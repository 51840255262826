import { useMutation, useQuery } from "@tanstack/react-query";
import axiosInt from "services";

const useGetLinkedinAccount = () =>
  useMutation({
    mutationFn: async () => {
      const response = await axiosInt.get(`/linkedin/get_linkedin_account`);
      return response;
    },
  });

const useUpdateAccountLimits = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.put(
        `/linkedin/update_account_limits`,
        data
      );
      return response;
    },
  });

const useRemoveLinkedinAccount = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.delete(`/linkedin/remove_account`, data);
      return response;
    },
  });

const useWorkSpaceCreditLimit = ({ isEnabled }) =>
  useQuery({
    queryKey: ["getCreditLimit"],
    queryFn: async () => {
      const response = await axiosInt.get(`/workspace/get_credit_limit`);
      return response;
    },
    enabled: isEnabled,
  });

export {
  useGetLinkedinAccount,
  useUpdateAccountLimits,
  useRemoveLinkedinAccount,
  useWorkSpaceCreditLimit,
};
