import Loader from "components/loader";
import Logo from "assets/images/logo.svg";

const LoginLoader = ({ loaderDescription }) => {
  return (
    <div
      className="!h-full  font-inter flex flex-col justify-center items-center max-[767px]:h-auto  max-[767px]:p-[20px] max-[767px]:pt-[50px] max-[767px]:max-w-[100%]"
      data-aos="fade-in"
      data-aos-duration="2000"
    >
      <div
        className="mx-auto flex flex-col items-center justify-center"
        data-aos="fade-in"
        data-aos-duration="2000"
      >
        <img src={Logo} alt="Logo" className="h-[60px] w-[60px] mb-[30px]" />
        <div className="ml-[-9px]">
          <Loader />
        </div>
        <p className="mt-[67px] text-[#CBCBE8BF] text-[14px] font-[400] text-center">
          {loaderDescription}
        </p>
      </div>
    </div>
  );
};

export default LoginLoader;
