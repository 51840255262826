import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  useDismissNotification,
  useGetNotification,
  useReadNotification,
  useMarkAllAsReadNotifications,
  useGetNotificationCount,
} from "services/settings-api";
import { createSocketConnection, disconnectSocket } from "socket/socket";
import Tick from "assets/images/name-check.svg";
import starIcon from "assets/images/light-stars.svg";
import loadingIcon from "assets/images/Loading-circle.png";
import leadIcon from "assets/images/electric-lead.svg";
import disconnectIcon from "assets/images/disconnect.svg";
import { useSettingStore } from "stores";

const NotificationModal = ({
  isNotificationModalVisible,
  setIsNotificationModalVisible,
}) => {
  const [notifications, setNotifications] = useState([]);
  const getNotification = useGetNotification(isNotificationModalVisible);
  const isReadNotification = useReadNotification();
  const isDismissNotification = useDismissNotification();
  const isMarkAllAsReadNotifications = useMarkAllAsReadNotifications();
  const { setNotificationCount } = useSettingStore();
  const getNotificationCount = useGetNotificationCount();
  const [isNotification, setIsNotification] = useState(true);

  const getNotifications = async () => {
    try {
      const result = await getNotification?.mutateAsync();
      const notificationCount = await getNotificationCount?.mutateAsync();
      setNotificationCount(notificationCount?.unread_count);
      setNotifications(result?.notification);
    } catch {
      console.log("Something went wrong");
    }
  };

  useEffect(() => {
    getNotifications();
  }, [isNotificationModalVisible]);

  const onView = async (id) => {
    const payload = {
      notification_id: id,
      is_read: true,
    };
    const result = await isReadNotification?.mutateAsync(payload);
    if (result?.notification?.id) {
      getNotifications();
    }
  };

  const onDismiss = async (id) => {
    try {
      const payload = {
        notification_id: id,
      };
      const result = await isDismissNotification?.mutateAsync(payload);
      if (result?.message) {
        // toast.success(result?.message);
        getNotifications();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleNotification = (data) => {
    console.log("Notification from server: index js", data, isNotification);
    if (isNotification) {
      setIsNotification(false);
      toast.success(data?.title, {
        icon: false,
        closeButton: true,
        position: "top-right",
        autoClose: 2000,
        onClick: () => {
          setIsNotificationModalVisible(true);
        },
      });
      setTimeout(() => {
        setIsNotification(true);
      }, 3000);
    }
  };

  useEffect(() => {
    console.log("NotificationListener mounted");
    createSocketConnection(handleNotification);

    return () => {
      console.log("NotificationListener unmounted");
      disconnectSocket();
    };
  }, [isNotification]);

  const onMarkAllRead = async () => {
    try {
      const res = await isMarkAllAsReadNotifications.mutateAsync();
      toast.success(res?.message, {
        icon: () => <img src={Tick} alt="name" />,
      });
      getNotifications();
    } catch (error) {
      toast.error(error?.detail?.error || "An Unknown error occured.");
    }
  };

  const getIconType = (icon) => {
    let renderIcon;
    let textColor;

    switch (icon) {
      case 1:
        return {
          renderIcon: (
            <img
              src={starIcon}
              alt="Enriched"
              className="h-[30px] w-[30px] min-w-[30px]"
            />
          ),
          textColor: "#8582FF",
        };
      case 2:
        return {
          renderIcon: (
            <img
              src={loadingIcon}
              alt="Loading"
              className="h-[30px] w-[30px] min-w-[30px]"
            />
          ),
          textColor: "#A060FA",
        };
      case 3:
        return {
          renderIcon: (
            <img
              src={leadIcon}
              alt="Lead Added"
              className="h-[30px] w-[30px] min-w-[30px]"
            />
          ),
          textColor: "#8582FF",
        };
      case 4:
        return {
          renderIcon: (
            <img
              src={disconnectIcon}
              alt="Disconnected"
              className="h-[30px] w-[30px] min-w-[30px]"
            />
          ),
          textColor: "#8582FF",
        };
      default:
        return null;
    }
  };

  return (
    <>
      {isNotificationModalVisible && (
        <div
          className="fixed left-0 top-0 w-full h-full z-10"
          onClick={() => setIsNotificationModalVisible(false)}
        />
      )}
      <div
        className={`modal-scroll w-[400px] top-[48px] fixed right-4 px-[12px] pt-2 bg-white overflow-auto rounded-[8px] !z-50 transition-all duration-500 ease-in-out ${
          isNotificationModalVisible
            ? "opacity-100 max-h-[450px]"
            : "opacity-0  max-h-0"
        } border border-[#E4E7EC] font-inter pb-[0px]`}
      >
        <div className="relative">
          {/* <div className="bg-white sticky top-0 mb-[11px] z-10">
            <div className="mr-[6px] py-[20px] ml-[8px] flex justify-between border-b border-[#E5E7EB]">
              <div className="flex justify-between w-full">
                <div className="flex items-center space-x-1">
                  <img src={Baja} width={20} />
                  <p className="text-lg font-semibold text-[#101828] leading-[25px]">
                    Notifications
                  </p>
                </div>
              </div>
              <div
                className="mt-[3px]"
                onClick={() => setIsNotificationModalVisible(false)}
              >
                <img
                  src={arrowIcon}
                  alt="ArrowIcon"
                  className="cursor-pointer w-[24px] h-[24px] mt-[-2px]"
                />
              </div>
            </div>
          </div> */}
          <div className={`${notifications?.length > 0 && "mb-5"}`}>
            {notifications?.length > 0 ? (
              notifications?.map((item) => {
                const { renderIcon, textColor } =
                  getIconType(item?.icon_type) || {};
                return (
                  <div
                    className="cursor-pointer flex items-center mb-[9px]"
                    onClick={() => onView(item?.id)}
                  >
                    <div
                      className={`min-w-[7px] w-[7px] h-[7px] bg-[#8582FF] rounded-[8px] mr-[10px] ${
                        !item?.is_read ? "opacity-100" : "hidden"
                      }`}
                    />
                    <div className="relative w-full border border-[#F5F5F5] rounded-[7px] pt-[13px] pb-[16px] px-[10px]">
                      <div className="flex items-center">
                        <div className="mr-[9.5px] rounded-full">
                          {renderIcon}
                        </div>
                        <div className="">
                          <h4 className="text-[12.5px] font-[500] text-[#374151] mb-[2px]">
                            {item?.title}
                            <span className="text-[#9CA3AF] text-[9.5px] font-[400] pl-[5px]">
                              {moment(item?.created_ts).fromNow()}
                            </span>
                          </h4>
                          <p
                            className="text-[10.6px] font-[500] text-[#334155] mb-[8px]"
                            style={{ color: textColor }}
                          >
                            {item?.message}
                          </p>
                        </div>
                        <div className="absolute right-[10px] bottom-[5px]">
                          {/* {!item?.is_read && (
                        <button
                          onClick={() => onView(item?.id)}
                          className="text-[#9CA3AF] text-[9.5px] font-[400] mr-[10px]"
                        >
                          View
                        </button>
                      )} */}
                          <button
                            onClick={(e) => {
                              e?.stopPropagation();
                              onDismiss(item?.id);
                            }}
                            className="text-[#9CA3AF] text-[9.5px] font-[400]"
                          >
                            Dismiss
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="flex items-center flex-col mt-[40px]">
                <h4 className="font-[700] mb-[5px] text-[15px]">
                  No Notifications
                </h4>
                <p className="font-[400] mb-[20px] text-[15px]">
                  You currently have no new notifications.
                </p>
              </div>
            )}
          </div>
        </div>
        {notifications?.length > 0 && (
          <div className="sticky bottom-0 z-50 bg-white py-[10px]">
            <p
              className="flex items-center justify-center text-[11px] font-semibold text-[#6700F7] leading-[25px] cursor-pointer"
              onClick={
                notifications?.length > 0 &&
                notifications?.some((item) => !item?.is_read)
                  ? onMarkAllRead
                  : undefined
              }
            >
              Mark all as read
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default NotificationModal;
