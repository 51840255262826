import Button from "components/button";
import listIcon from "assets/images/purple-color-wand-outline.svg";
import SearchResultTable from "./searchResultTable";
import { useNavigate } from "react-router-dom";

const SearchResultDefaultStep = ({ setResultSteps ,leadCount,prospectData,profiles}) => {
  const navigate = useNavigate();
  return (
    <div className="modal-scroll w-[890px]">
      <div className="flex flex-col items-center justify-center mb-[29px] font-inter">
        <div className="flex items-center justify-center border-[1px] border-[#E4E7EC] rounded-[10px] p-[12px] mb-[11px] y-axis-shadow">
          <img src={listIcon} alt="info img" className="h-[24px] w-[24px]" />
        </div>
        <h1 className="text-[24px] font-inter text-center text-[#101828] font-[600] leading-normal mb-[11px] w-full">
          <span className="text-[#6366F1] font-[700]">{leadCount?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span> new leads
          fit your criteria!
        </h1>
        <p className="font-normal text-[14px] text-[#475467] font-inter">
          Here’s a preview of the leads to see what we found.
        </p>
      </div>
      <div className="max-w-[702px] mx-auto">
        <SearchResultTable profiles={profiles}/>
      </div>
      <div className="flex items-center justify-center mt-[32px] mb-[15px]">
        <Button
          title="Back"
          isWhiteBtn={true}
          isRounded="6px"
          onClick={() => navigate("/findLeads",{state:{prospectData}})}
          className="!text-[16px] !h-[44px] !font-[600] !text-[#344054] y-axis-shadow !w-[210px]"
        />
        <Button
          title="Looks good!"
          secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9] ml-[12px] !w-[210px]"
          className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow"
          isRounded="6px"
          onClick={() => setResultSteps(2)}
        />
      </div>
      <p className="text-[#8D9196] text-[12.8px] leading-[18px] m-0 font-inter text-center">
        When you create a campaign, you will pull from this list of saved leads
        to enrich them.
      </p>
    </div>
  );
};

export default SearchResultDefaultStep;
