import { IoClose } from "react-icons/io5";
import Button from "components/button";
import checkBg from "assets/images/check-bg.svg";
import { useEffect, useState } from "react";
import connectIcon from "assets/images/captcha.svg";
import { ArkoseCaptchaIntegration } from "utils/arkose";
import { useLinkedinCallback } from "services/auth-api";
import { toast } from "react-toastify";

const ReconnectCaptchaModal = ({
  isOpen,
  onClose,
  setOpenConnectModalSteps,
  setIsLinkedinAccountConnected,
  handleGetLinkedInAccountDetails,
  data,
  accountId,
  startVerificationInterval,
  setLikedInData,
  setLoader,
  setIsPhoneCard,
}) => {
  const publicKey = data?.public_key;
  const linkedInCallback = useLinkedinCallback();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleCallback = async (token) => {
    try {
      const response = await linkedInCallback.mutateAsync({
        account_id: String(accountId),
        code: token,
      });

      console.log({ response });

      if (response?.status === 201) {
        if (response?.message === "AccountCreated") {
          startVerificationInterval(String(accountId));
        }
      }

      if (response?.status === 202) {
        if (response?.checkpoint?.type === "OTP") {
          setLikedInData(response);
          setLoader(false);
          setOpenConnectModalSteps(6);
          setIsPhoneCard(false);
        } else if (response?.checkpoint?.type === "2FA") {
          setLikedInData(response);
          setLoader(false);
          setOpenConnectModalSteps(8);
        } else if (response?.checkpoint?.type === "IN_APP_VALIDATION") {
          setLikedInData(response);
          setLoader(false);
          setOpenConnectModalSteps(9);
        } else if (response?.checkpoint?.type === "PHONE_REGISTER") {
          setLoader(false);
          setIsPhoneCard(true);
          setOpenConnectModalSteps(2);
          return;
        }
      } else if (response?.status === 400) {
        setLoader(false);
        toast.error(response?.message);
        onClose();
      } else {
        setLoader(false);
        onClose();
      }

      console.log(response, "response");
    } catch (error) {
      setLoader(false);
      toast.error(error?.data?.detail);
      console.log(error, "err");
    }
  };

  useEffect(() => {
    if (!publicKey) return;

    const captcha = new ArkoseCaptchaIntegration(publicKey, data?.data);

    // Setup the callbacks
    captcha.onLoaded = () => {
      console.log("Captcha has been successfully loaded into the DOM!");
    };

    captcha.onSuccess = (token) => {
      handleCallback(token);
      console.log("Captcha has been resolved!", { token });
      captcha.unload();
      onClose();
      setLoader(true);
    };

    captcha.onError = () => {
      console.log("Captcha resolution failed!");
    };

    try {
      captcha.load("captcha-frame");
    } catch (error) {
      console.error(error.message);
    }

    // Cleanup function to unload captcha on component unmount
    return () => {
      captcha.unload();
    };
  }, [publicKey, data]);

  useEffect(() => {
    if (isOpen) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [isOpen]);
  if (!isOpen && !isModalVisible) return null;

  return (
    <>
      <div
        className={`fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[300ms] ease-in-out ${
          isModalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
      >
        <div
          className="fixed inset-0 bg-black opacity-10"
          onClick={onClose}
        ></div>
        <div
          className={`p-[24px] bg-white rounded-lg shadow-lg z-10 w-full max-w-[556px] relative transition-all ease-in-out duration-[150ms] ${
            isModalVisible ? "scale-100" : "scale-95"
          }`}
        >
          <IoClose
            className="text-[#667085] text-[20px] w-[24px] h-[24px] absolute right-[24px] top-[27px] cursor-pointer"
            onClick={onClose}
          />
          <div className="modal-scroll">
            <div className="flex flex-col items-start justify-start mb-[20px] font-inter">
              <div
                className="flex justify-center items-center border-[#E4E7EC] border w-[48px] h-[48px] rounded-[10px] mb-[22px]"
                style={{
                  boxShadow:
                    "0px 4px 8px 0px rgba(16, 24, 40, 0.05), 0px 2px 0px 0px rgba(16, 24, 40, 0.05), 0px 0px 1px 0px rgba(16, 24, 40, 0.18)",
                }}
              >
                <img
                  src={connectIcon}
                  alt="coins"
                  className="h-[24px] w-[24px]"
                />
              </div>
              <h4 className="font-[600] text-[18px] text-[#101828] mb-[5px] font-inter">
                Solve the Captcha
              </h4>
              <p className="font-normal text-[#475467] text-[14px]">
                You’ll need to solve the captcha below.
              </p>
            </div>
            <div className="flex w-full items-center justify-center">
              <div className="h-[302px] rounded-lg overflow-hidden">
                <div id="captcha-frame"></div>
              </div>
            </div>
            <div className="flex items-center justify-center mt-[32px]">
              <Button
                isWhiteBtn={true}
                title="Cancel"
                secondaryColor=""
                className="!text-[16px] text-[#344054] !h-[44px] !font-[600] y-axis-shadow !rounded-[8px]"
                onClick={() => {
                  handleGetLinkedInAccountDetails();
                  setIsLinkedinAccountConnected(true);
                  onClose();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReconnectCaptchaModal;
