import { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import Button from "components/button";
import coinsIcon from "assets/images/purple-stacked-coins.svg";
import CommonCheckbox from "components/commonCheckbox";
import { purchaseCreditInfo } from "constant";
import { toast } from "react-toastify";

const AddEnrichCreditOptions = (props) => {
  const {
    isOpen,
    onClose,
    setStorePurchaseCredit,
    setIsStoredPurchaseModal,
    setSelectedPackage,
    setPackageName,
  } = props;
  const [checkedItems, setCheckedItems] = useState(0);
  const [isChecked, setIsChecked] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const getPackageName = (index) => {
    return `package_${index + 1}`;
  };

  const handleCheckboxChange = (index, data) => {
    const packageName = getPackageName(index);
    setCheckedItems(index);
    setSelectedPackage(data);
    setPackageName(packageName);
    setStorePurchaseCredit(data);
  };

  useEffect(() => {
    if (isOpen) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [isOpen]);

  if (!isOpen && !isModalVisible) return null;

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[300ms] ease-in-out ${
        isModalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className="fixed inset-0 bg-black opacity-10"
        onClick={onClose}
      ></div>
      <div
        className={`bg-white rounded-lg shadow-lg z-10 w-full max-w-[766px] relative transition-all ease-in-out duration-[300ms] ${
          isModalVisible ? "scale-100" : "scale-95"
        }`}
      >
        <IoClose
          className="text-[#667085] text-[20px] w-[24px] h-[24px] absolute right-[24px] top-[22px] cursor-pointer"
          onClick={onClose}
        />
        <div className="modal-scroll">
          <div className="p-[24px] flex items-center">
            <div
              className="flex justify-center items-center border-[#E4E7EC] border w-[48px] h-[48px] rounded-[10px] mr-[16px]"
              style={{
                boxShadow:
                  "0px 4px 8px 0px rgba(16, 24, 40, 0.05), 0px 2px 0px 0px rgba(16, 24, 40, 0.05), 0px 0px 1px 0px rgba(16, 24, 40, 0.18)",
              }}
            >
              <img src={coinsIcon} alt="coins" className="h-[22px] w-[22px]" />
            </div>
            <div>
              <h6 className="text-[#101828] font-[600] text-[18px] leading-[28px]">
                Add Enrichment Credits
              </h6>
              <p className="font-[400] text-[14px] text-[#475467]">
                Select how many credits you need.
              </p>
            </div>
          </div>
          <div className="px-[24px] pt-[20px] pb-[33px] border-y-[1px] border-[#E4E7EC]">
            {/* card */}
            <div className="flex w-full items-start flex-wrap">
              {purchaseCreditInfo.map((item, index) => {
                console.log({ item });
                const isChecked = checkedItems === index;
                return (
                  <div
                    key={index}
                    onClick={() => handleCheckboxChange(index, item)}
                    className={`w-[345px] cursor-pointer flex items-start border-[2px] transition duration-75 ease-in-out ${
                      isChecked ? "border-[#7F56D9]" : "border-[#E4E7EC]"
                    } p-[20px] rounded-[12px] m-[7px]`}
                  >
                    <div className="w-full">
                      <h4 className="text-[24px] font-[600] text-[#101828] leading-[32px] m-0">
                        {item.price}
                      </h4>
                      <div className="mb-[20px] mt-[8px]">
                        <p className="text-[16px] font-[600] text-[#101828] leading-[24px] mb-[2px]">
                          {item.title}
                        </p>
                        <p className="text-[14px] font-[400] text-[#475467] leading-[20px] mb-[2px]">
                          {item.purchaseCount}
                        </p>
                      </div>
                      <div className="flex">
                        <img
                          src={item.purchaseStatus}
                          alt="round-check"
                          className="w-[24px] h-[24px] mr-[12px]"
                        />
                        <p className="text-[16px] font-[400] text-[#475467] leading-[24px]">
                          {item.description}
                        </p>
                      </div>
                    </div>
                    <div
                      className="mr-[16px] creditCheckbox"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <CommonCheckbox
                        checked={isChecked}
                        onChange={() => handleCheckboxChange(index, item)}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex flex-col items-center justify-center py-[24px] px-[39px]">
            <div className="w-full flex flex-row">
              <Button
                title="Cancel"
                isWhiteBtn={true}
                onClick={onClose}
                className="!text-[16px] !h-[44px] !font-[600] !text-[#344054] y-axis-shadow !rounded-[8px]"
              />
              <Button
                title="Continue"
                secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9] ml-[12px]"
                className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow !rounded-[8px]"
                onClick={() => {
                  if (isChecked) {
                    onClose();
                    setIsStoredPurchaseModal(true);
                  } else {
                    toast.error(
                      "You must agree to the Terms of Use and Privacy Policy before proceeding."
                    );
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEnrichCreditOptions;
