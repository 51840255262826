import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

const useAuthStore = create(
  persist(
    (set) => ({
      userDetails: {},
      isSubscriptionCancelled: false,
      setUserDetails: (userDetails) => set({ userDetails: userDetails }),
      clearUserDetails: () => set({ userDetails: {} }),
      setIsSubscriptionCancelled: (userDetails) =>
        set({ isSubscriptionCancelled: userDetails }),
      clearSubscriptionCancelled: () => set({ isSubscriptionCancelled: false }),
      companyId: "",
      setCompanyId: (companyId) => set({ companyId }),
      clearCompanyId: () => set({ companyId: "" }),
      isAdmin: false,
      setIsAdmin: (isAdmin) => set({ isAdmin }),
      clearIsAdmin: () => set({ isAdmin: false }),
      authCheck: {},
      setAuthCheck: (val) => set({ authCheck: val }),
    }),
    {
      name: "auth-store",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export default useAuthStore;
