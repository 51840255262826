/* eslint-disable default-case */
import { datePattern } from "utils";
import { useEffect, useRef, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import moment from "moment";
import Department from "components/leadsComponent/department";
import Revenue from "components/leadsComponent/revenue";
import FundingAmount from "components/leadsComponent/fundingAmount";
import TimeSpent from "components/leadsComponent/timeSpent";
import DateFounded from "components/leadsComponent/dateFounded";
import Signals from "components/leadsComponent/signals";
import TypeInput from "components/leadsComponent/type-input";
import CrossIcon from "assets/images/cross-small.svg";
import { FaXmark } from "react-icons/fa6";
import ExcludeCross from "assets/images/exclude-cross.svg";
import SelectInput from "components/leadsComponent/select-input";
import {
  JobLevelOptions,
  jobRoleOptions,
  Locations,
  subJobRoleOptions,
  Companies,
} from "constant";

const AddLeadsOptions = (props) => {
  const {
    leadTitle,
    type,
    src,
    options = [],
    setFormData,
    formData,
    name,
    title,
    setTitle,
    placeholder,
  } = props;

  const inputRef = useRef();
  const [leadType, setLeadType] = useState("");
  const [showJobList, setJobShowList] = useState(false);
  const [show, setShow] = useState(false);

  const handleFocus = () => {
    if (inputRef && inputRef?.current && inputRef?.current?.scrollIntoView) {
      setTimeout(() => {
        inputRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 500);
    }
  };

  useEffect(() => {
    if (inputRef?.current) {
      handleFocus();
    }
  }, [formData?.company_industry?.values]);

  const onDeleteString = (chip) => {
    const splitData = chip?.split("Max: ")?.[1] || chip?.split("Min: ")?.[1];
    const onFindKey = () => {
      for (let key in formData) {
        if (datePattern.test(chip)) {
          if (moment(new Date(formData?.[key])).format("MM/DD/YYYY") === chip) {
            return key;
          }
        } else {
          if ((formData?.[key]?.label || formData?.[key]) === splitData) {
            return key;
          }
        }
      }
    };
    const key = onFindKey();
    const updatedData = {
      ...formData,
      [key]: "",
    };
    setFormData(updatedData);
  };
  const onDeleteChip = (chip) => {
    let keyToClear = "";
    console.log({ chip, name, formData });
    if (
      leadTitle === "Year Founded" &&
      (chip?.endsWith("/year_founded_start") ||
        chip?.endsWith("/year_founded_end"))
    ) {
      if (chip?.endsWith("/year_founded_start")) {
        keyToClear = "year_founded_start";
      } else if (chip?.endsWith("/year_founded_end")) {
        keyToClear = "year_founded_end";
      }
      if (keyToClear) {
        return setFormData((prevFormData) => ({
          ...prevFormData,
          [keyToClear]: "",
        }));
      }
    } else if (
      chip?.includes("Max:") ||
      chip?.includes("Min:") ||
      datePattern.test(chip)
    ) {
      onDeleteString(chip);
      return;
    } else if (name === "job_sub_role" || name === "job_role") {
      let filteredData = [...formData[name]?.values]?.filter(
        (i) => i?.label !== chip
      );
      return setFormData({
        ...formData,
        [name]: { values: filteredData },
      });
    } else if (name === "headcount" && formData?.lastDay?.length > 0) {
      if (Number(chip)) {
        setFormData({
          ...formData,
          lastDay: undefined,
        });
        return;
      }
    }
    let filterChip = [];
    if (formData?.[name]?.values?.length > 0) {
      filterChip = formData?.[name]?.values?.filter(
        (item) => item?.value !== chip
      );
      setFormData({
        ...formData,
        [name]: { values: [...filterChip] },
      });
    } else {
      filterChip = formData?.[name]?.filter((item) => item !== chip);
      setFormData({
        ...formData,
        [name]: [...filterChip],
      });
    }
  };

  const onDeleteAllChip = () => {
    const resetFormData = {
      ...formData,
      [name]: { values: [] },
    };

    if (formData?.[name]?.values?.length > 0) {
      setFormData(resetFormData);
    } else if (
      (formData["year_founded_start"]?.label?.length ||
        formData["year_founded_end"]?.label?.length) &&
      leadTitle === "Year Founded"
    ) {
      setFormData({
        ...formData,
        year_founded_start: "",
        year_founded_end: "",
      });
    } else {
      setFormData({
        ...formData,
        [name]: [],
      });
    }

    if (leadTitle === title) {
      setShow(false);
      setTitle("");
    }
  };
  const deleteUsingBackspace = (event) => {
    const { key } = event;
    const { name } = event.target;
    if (key === "Backspace") {
      setFormData({
        ...formData,
        [name]: "",
      });
    }
  };

  const handleNegativeNumbers = (event) => {
    const invalidChars = ["-", "+", "e", "E"];
    if (invalidChars?.includes(event.key)) {
      event.preventDefault();
    }
  };
  const preventInvalidInput = (event) => {
    const invalidChars = [
      "-",
      "+",
      "e",
      "E",
      "/",
      "\\",
      "~",
      "_",
      "[",
      "]",
      ";",
      " ",
    ];
    const isAlphabet = /^[a-zA-Z]$/;
    const isSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

    if (
      invalidChars.includes(event.key) ||
      isAlphabet.test(event.key) ||
      isSpecialChar.test(event.key)
    ) {
      event.preventDefault();
    }
  };

  const renderRelevantComponent = () => {
    switch (leadType) {
      case "First Name":
        return (
          <TypeInput
            setJobShowList={setJobShowList}
            showJobList={showJobList}
            name={name}
            options={options}
            leadType={leadType}
            placeholder={placeholder}
            leadTitle={leadTitle}
            title={title}
            formData={formData}
            setFormData={setFormData}
            subTitle="This will yield exact matches."
            active={leadTitle === title}
          />
        );
      case "Last Name":
        return (
          <TypeInput
            setJobShowList={setJobShowList}
            showJobList={showJobList}
            name={name}
            options={options}
            leadType={leadType}
            placeholder={placeholder}
            leadTitle={leadTitle}
            title={title}
            formData={formData}
            setFormData={setFormData}
            subTitle="This will yield exact matches."
            active={leadTitle === title}
          />
        );
      case "location":
        return (
          <TypeInput
            setJobShowList={setJobShowList}
            showJobList={showJobList}
            name={name}
            options={Locations}
            leadType={leadType}
            placeholder={placeholder}
            leadTitle={leadTitle}
            title={title}
            formData={formData}
            setFormData={setFormData}
            subTitle="Enter a city, state or region."
            isExcludeInInput
            active={leadTitle === title}
            preventEnter
          />
        );
      case "Job Title":
        return (
          <TypeInput
            setJobShowList={setJobShowList}
            showJobList={showJobList}
            name={name}
            options={options}
            leadType={leadType}
            placeholder={placeholder}
            leadTitle={leadTitle}
            title={title}
            formData={formData}
            setFormData={setFormData}
            subTitle="This will yield exact matches."
            active={leadTitle === title}
            preventEnter
            isExcludeInInput
          />
        );
      case "Job Level":
        return (
          <SelectInput
            placeholder="Type a job level or select from dropdown"
            options={JobLevelOptions}
            name={name}
            formData={formData}
            setFormData={setFormData}
            preventEnter
          />
        );
      case "Job Role":
        return (
          <SelectInput
            placeholder="Type a job role or select from dropdown"
            options={jobRoleOptions}
            name={name}
            formData={formData}
            setFormData={setFormData}
            preventEnter
          />
        );
      case "Job Subrole":
        return (
          <SelectInput
            placeholder="Type job subrole or select from dropdown"
            options={subJobRoleOptions}
            name={name}
            formData={formData}
            setFormData={setFormData}
            preventEnter
          />
        );
      case "Skills":
        return (
          <TypeInput
          setJobShowList={setJobShowList}
          showJobList={showJobList}
          name={name}
          options={[]}
          leadType={leadType}
          placeholder="Type and search for skills"
          leadTitle={leadTitle}
          title={title}
          formData={formData}
          setFormData={setFormData}
          subTitle="This will yield exact matches."
          active={leadTitle === title}
          preventEnter
        />
        );
      case "School":
        return (
          <TypeInput
            setJobShowList={setJobShowList}
            showJobList={showJobList}
            name={name}
            leadType={leadType}
            placeholder="Type a school..."
            leadTitle={leadTitle}
            title={title}
            formData={formData}
            setFormData={setFormData}
            subTitle="This will yield exact matches."
            options={[]}
            active={leadTitle === title}
            preventEnter
          />
        );
      case "Major":
        return (
          <TypeInput
            setJobShowList={setJobShowList}
            showJobList={showJobList}
            name={name}
            options={[]}
            leadType={leadType}
            placeholder="Type a Major..."
            leadTitle={leadTitle}
            title={title}
            formData={formData}
            setFormData={setFormData}
            subTitle="This will yield exact matches."
            active={leadTitle === title}
            preventEnter
          />
        );
      case "Revenue":
        return (
          <Revenue
            formData={formData}
            setFormData={setFormData}
            handleNegativeNumbers={handleNegativeNumbers}
            name={name}
            options={options}
          />
        );
      case "Company":
        return (
          <TypeInput
            setJobShowList={setJobShowList}
            showJobList={showJobList}
            name={name}
            options={Companies}
            leadType={leadType}
            placeholder={placeholder}
            leadTitle={leadTitle}
            title={title}
            formData={formData}
            setFormData={setFormData}
            subTitle="This will yield exact matches."
            isExcludeInInput
            active={leadTitle === title}
          />
        );
      case "HQ Location":
        return (
          <TypeInput
            setJobShowList={setJobShowList}
            showJobList={showJobList}
            name={name}
            options={[]}
            leadType={leadType}
            placeholder={placeholder}
            leadTitle={leadTitle}
            title={title}
            formData={formData}
            setFormData={setFormData}
            subTitle="Enter a city, state or region."
            isExcludeInInput
            active={leadTitle === title}
            preventEnter
          />
        );
      case "Headcount":
        return (
          <SelectInput
            placeholder="Select headcount from dropdown..."
            options={options}
            name={name}
            formData={formData}
            setFormData={setFormData}
            preventEnter
          />
        );
      case "Industry":
        return (
          <TypeInput
          setJobShowList={setJobShowList}
          showJobList={showJobList}
          name={name}
          options={[]}
          leadType={leadType}
          placeholder={placeholder}
          leadTitle={leadTitle}
          title={title}
          formData={formData}
          setFormData={setFormData}
          subTitle="This will yield exact matches."
          isExcludeInInput
          active={leadTitle === title}
          preventEnter
        />
        );
      case "managementLevel":
      case "department":
        return (
          <Department
            options={options}
            name={name}
            leadType={leadType}
            placeholder={placeholder}
            formData={formData}
            setFormData={setFormData}
            handleNegativeNumbers={handleNegativeNumbers}
          />
        );
      case "Signals":
        return (
          <Signals
            formData={formData}
            setFormData={setFormData}
            name={name}
            options={options}
          />
        );
      case "Funding Amount":
        return (
          <FundingAmount
            options={options}
            name={name}
            placeholder={"Select Stage of Funding"}
            formData={formData}
            setFormData={setFormData}
            handleNegativeNumbers={handleNegativeNumbers}
          />
        );
      case "Time Spent":
        return (
          <TimeSpent
            deleteUsingBackspace={deleteUsingBackspace}
            formData={formData}
            setFormData={setFormData}
            handleNegativeNumbers={handleNegativeNumbers}
            preventInvalidInput={preventInvalidInput}
          />
        );

      case "Year Founded":
        return (
          <DateFounded
            options={options}
            name={name}
            formData={formData}
            setFormData={setFormData}
          />
        );
    }
  };

  const handleClick = () => {
    if (leadTitle === title) {
      setShow(!show);
      setTitle("");
      setTitle("");
    } else {
      setLeadType(type);
      setTitle(leadTitle);
      setShow(true);
      setJobShowList(false);
    }
  };
  const renderChipsInsideTitle = () => {
    let renderData = formData[name];
    if (formData[name]?.values?.length) {
      renderData = formData[name]?.values?.map((i) => i.label);
    }

    if (
      (formData["year_founded_start"]?.label?.length ||
        formData["year_founded_end"]?.label?.length) &&
      leadTitle === "Year Founded"
    ) {
      const startYear = formData["year_founded_start"]?.label;
      const endYear = formData["year_founded_end"]?.label;
      renderData = [
        { key: "year_founded_start", value: startYear },
        { key: "year_founded_end", value: endYear },
      ]
        .filter(({ value }) => value?.length > 0)
        .map(({ key, value }) => `${value}/${key}`);
    }

    const transformItem = (item) => {
      if (item?.startsWith("Ex:")) {
        if (name === "location" || name === "company_location") {
          return item.slice(3)?.split("/")?.[0];
        }
        return item.slice(3);
      } else if (name === "location" || name === "company_location") {
        return item.split("/")?.[0];
      } else if (
        item?.endsWith("/year_founded_start") ||
        item?.endsWith("/year_founded_end")
      ) {
        return item.slice(0, 4);
      } else {
        return item;
      }
    };

    console.log({ formData });

    return renderData && Array.isArray(renderData)
      ? renderData.map((item, index) => (
          <div
            onClick={(event) => {
              event?.stopPropagation();
            }}
            key={index}
            className={`flex items-center max-w-[250px] font-inter font-[600] ${
              item?.startsWith("Ex:")
                ? "bg-[#FCE7F3] text-[#BE185D]"
                : "bg-[#F5EBFF] text-[#7E22CE]"
            }  capitalize text-xs rounded-[6px] pl-0 p-1 mr-[8px] mt-[8px]`}
          >
            <span className="overflow-hidden truncate pl-[10px] pr-[6px] whitespace-nowrap font-[500] flex-1">
              {transformItem(item)}
            </span>
            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                onDeleteChip(item);
              }}
              className={`text-[#6941c6] font-[600] pr-[2px] w-[20px]`}
            >
              <img
                src={item?.startsWith("Ex:") ? ExcludeCross : CrossIcon}
                alt="close"
                className={`${
                  item?.startsWith("Ex:")
                    ? "w-[8.5px] h-[8.5px]"
                    : "w-[20px] h-[20px]"
                }`}
              />
            </button>
          </div>
        ))
      : false;
  };

  const isHidden = () => {
    const hasFormDataValues =
      formData[name]?.length > 0 ||
      formData[name]?.values?.length ||
      ((formData["year_founded_start"]?.label?.length ||
        formData["year_founded_end"]?.label?.length) &&
        leadTitle === "Year Founded");

    return hasFormDataValues;
  };
  return (
    <div ref={inputRef} onFocus={handleFocus}>
      <Accordion
        className={`!bg-transparent accordionWrap mb-[12px] innerChips ${
          leadTitle !== title
            ? "!shadow-lg !shadow-[#1018280D]"
            : "!shadow-none"
        }`}
        expanded={leadTitle === title}
      >
        <AccordionSummary
          className={`flex !items-center w-full justify-between bg-[#F5EFFF] !py-2 !px-3 my-[12px] cursor-pointer relative z-50
            ${isHidden() && "active-accordion"} ${
            leadTitle === title && "active-accordion"
          }`}
          aria-controls="panel1-content"
          id="panel1-header"
          onClick={(e) => {
            e.stopPropagation();
            handleClick();
          }}
        >
          <div className="flex flex-col w-full">
            <div className="flex items-center justify-between w-full">
              <div className="flex items-center w-full">
                <img src={src} alt="img" className="mr-[8px] w-6 h-6" />
                <p
                  className={`text-[16px] font-inter 
                 text-[#667085] ${isHidden() && "!text-[#4F3D84]"}`}
                >
                  {leadTitle}
                </p>
              </div>
              {isHidden() ? (
                <div className="w-[18px] h-[18px] flex items-center justify-center rounded-sm border-2 border-[#8A43FF]">
                  <FaXmark
                    className="text-[14px] font-bold leading-4 text-[#8A43FF] cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      onDeleteAllChip();
                    }}
                  />
                </div>
              ) : null}
            </div>
            <div className="flex w-full flex-wrap">
              {renderChipsInsideTitle()}
            </div>
          </div>
        </AccordionSummary>
        <div
          className="fixed top-0 left-0 w-full h-full"
          onClick={() => {
            setShow(!show);
            setTitle("");
            setTitle("");
          }}
        />
        <AccordionDetails>{renderRelevantComponent()}</AccordionDetails>
      </Accordion>
    </div>
  );
};

export default AddLeadsOptions;
