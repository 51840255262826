import { randomCompanyLogo, resultTable } from "constant";
import verifiedImg from "assets/images/verified.svg";
import { getNameInititals, getRandomCompanyLogo } from "utils/index";
import { useState } from "react";

const SearchResultTable = ({ profiles }) => {
  const TableHeader = ["Name", "Company"];
  const [companyLogoMap, setCompanyLogoMap] = useState({});

  const getCompanyLogo = (companyName) => {
    if (companyLogoMap[companyName]) {
      return companyLogoMap[companyName];
    }
    const randomLogo =
      randomCompanyLogo[Math.floor(Math.random() * randomCompanyLogo.length)];
    setCompanyLogoMap((prevMap) => ({
      ...prevMap,
      [companyName]: randomLogo.src,
    }));

    return randomLogo.src;
  };

  return (
    <div className="font-inter box-border overflow-hidden rounded-tl-[12px] rounded-tr-[12px] rounded-bl-[12px] rounded-br-[12px] border border-[#E4E7EC]">
      <table className="min-w-full font-inter table-fixed rounded-xl">
        <thead className="sticky top-0 bg-white z-40">
          <tr className="w-full bg-[#F9FAFB] border-b border-[#E4E7EC] relative">
            {TableHeader.map((header, index) => (
              <th
                key={index}
                className="text-[12px] font-[500] py-[12px] text-[#475467] text-left px-[24px]"
              >
                {header}
              </th>
            ))}
          </tr>
          <span className="w-full border-b border-[#E4E7EC] h-[1px] absolute top-full z-10 left-0" />
        </thead>
        <tbody>
          {profiles?.length ? (
            profiles?.map((item, index) => {
              const isLastRow = index === resultTable.length - 1;
              return (
                <tr
                  className={`even:bg-[#F9FAFB] group border-b border-[#E4E7EC] hover:bg-gray-50 ${
                    isLastRow ? "border-b-0" : ""
                  }`}
                  key={index}
                >
                  <td
                    className={`${
                      isLastRow && "first:rounded-bl-[12px]"
                    }  text-[14px] px-[24px] py-[16px] text-[#101828] text-left w-[60%] max-[1300px]:w-[unset]`}
                  >
                    <div className="flex items-center">
                      <div className="w-[40px] min-w-[40px] h-[40px] mr-[12px] relative">
                        {/* <img
                          src={getNameInititals(item?.full_name)}
                          alt="lead"
                          className="w-full h-full"
                        /> */}
                        <div className="w-[40px] min-w-[40px] h-[40px] rounded-full flex items-center justify-center text-[14px] font-[700] text-[#4409B9] bg-[#EEEDFD]">
                          {getNameInititals(item?.full_name)}
                        </div>
                        {item?.verified ? (
                          <img
                            src={verifiedImg}
                            alt="lead"
                            className="w-[16px] h-[16px] absolute right-0 bottom-0"
                          />
                        ) : null}
                      </div>
                      <div>
                        <p className="font-[500] [text-[#101828] text-[14px] m-0 leading-[20px]">
                          {item?.full_name}
                        </p>
                        <p className="font-[400] text-[#475467] text-[14px] m-0 leading-[17px]">
                          {item?.job_title}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td
                    className={`${
                      isLastRow && "last:rounded-br-[12px]"
                    } text-[14px] px-[24px] py-[16px] text-[#101828] text-left w-[60%] max-[1300px]:w-[unset]`}
                  >
                    <div className="flex items-center">
                      <img
                        src={getCompanyLogo(item.job_company_name)}
                        alt="lead"
                        className="w-[40px] min-w-[40px] h-[40px] mr-[12px]"
                      />
                      <div>
                        <p className="font-[500] [text-[#101828] text-[14px] m-0 leading-[20px]">
                          {item?.job_company_name}
                        </p>
                        <p
                          onClick={() =>
                            window.open(
                              "https://www." + item?.job_company_website
                            )
                          }
                          className="font-[400] text-[#475467] text-[14px] m-0 leading-[17px]"
                        >
                          {item?.job_company_website}
                        </p>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td
                colSpan={TableHeader.length}
                className="text-[14px] px-[22px] py-[10px] text-center text-[#474563] h-[200px]"
              >
                No Data Found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default SearchResultTable;
