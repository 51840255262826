import CommonCheckbox from "components/commonCheckbox";
import LinkedIn from "assets/images/linkedin-view-profile.svg";
import SwitchToggle from "components/switchToggle";
import InsertDropdown from "components/newCampaign/insertDropdown";
import { HiOutlineMail } from "react-icons/hi";
import { useEffect, useState } from "react";

export const insertDropdownOpt = [
  { campaign_name: "First Name", value: "first_name" },
  { campaign_name: "Last Name", value: "last_name" },
  { campaign_name: "Company", value: "company" },
  // { campaign_name: "Location", value: "location" },
  { campaign_name: "Role", value: "role" },
];

const SendConnectionRequest = ({ value, handleStepValueChange, index }) => {
  const [formData, setFormData] = useState({
    inputValue: "",
    isSendRequestCheckBoxChecked: true,
  });

  useEffect(() => {
    if (value && value !== "string") {
      setFormData({
        inputValue: value ? JSON.parse(value)?.inputValue : "",
        isSendRequestCheckBoxChecked: value
          ? JSON.parse(value)?.isSendRequestCheckBoxChecked
          : true,
      });
    }
  }, [value]);

  const [
    selectedConnectionRequestMessageOption,
    setSelectedConnectionRequestMessageOption,
  ] = useState(null);

  const maxChars = 300;

  const handleInputChange = (e) => {
    const input = e.target.value;
    const slicedInput =
      input.length > maxChars ? input.slice(0, maxChars) : input;

    setFormData((prev) => ({
      ...prev,
      inputValue: slicedInput,
    }));

    handleStepValueChange(
      JSON.stringify({
        ...formData,
        inputValue: slicedInput,
      }),
      index
    );
  };

  const toggleCheckbox = () => {
    setFormData((prev) => ({
      inputValue: "",
      isSendRequestCheckBoxChecked: !prev.isSendRequestCheckBoxChecked,
    }));
    handleStepValueChange(
      JSON.stringify({
        ...formData,
        isSendRequestCheckBoxChecked: !formData.isSendRequestCheckBoxChecked,
      }),
      index
    );
  };

  useEffect(() => {
    if (selectedConnectionRequestMessageOption) {
      const newConnectionRequestMessage = `${formData.inputValue} {{${selectedConnectionRequestMessageOption?.value}}}`;
      setFormData((prev) => ({
        ...prev,
        inputValue: newConnectionRequestMessage,
      }));
      const newFormData = {
        ...formData,
        inputValue: newConnectionRequestMessage,
      };
      handleStepValueChange(JSON.stringify(newFormData), index);
    }
  }, [selectedConnectionRequestMessageOption]);

  return (
    <div className="p-[14px] bg-[#F9F5FF] rounded-lg border-[2px] border-[#7F56D9] shadow-sm w-[373px]">
      <div className="flex items-start ">
        <div className="flex items-start w-full">
          <img src={LinkedIn} alt="" className="w-[27px] h-[27px] ml-[-2px]" />
          <div className="ml-[10px]">
            <h2 className="font-inter text-[12px] font-[500] text-[#53389E]">
              Send Connection Request
            </h2>
            <div className="flex items-start">
              <div className="start-campaign-toggle mr-[12px] h-[14px] mt-[2px]">
                <SwitchToggle
                  checked={formData.isSendRequestCheckBoxChecked}
                  onChange={toggleCheckbox}
                />
              </div>
              <p className="font-inter text-[12px] font-[400] text-[#6941C6] m-0">
                with message
              </p>
            </div>
          </div>
        </div>

        <div className="mr-[16px]">
          <CommonCheckbox isRound={true} checked={true} />
        </div>
      </div>
      <div
        className={` ${
          !formData.isSendRequestCheckBoxChecked
            ? "h-0 pt-[0px] overflow-hidden opacity-0"
            : "h-[140px] pt-[12px] opacity-100"
        } transition-all duration-500 ease-in-out`}
      >
        <p className="font-inter font-[500] text-[10px] text-[#6941C6] mb-[5px]">
          Connection Message
        </p>
        <div className="relative">
          <HiOutlineMail className="text-[#7F56D9] w-[16px] h-[16px] text-[14px] absolute top-[7px] left-[7px] z-10" />
          <textarea
            placeholder={`e.g Hey {{firstname}}, would love to connect.`}
            className="relative pl-[30px] text-[10px] y-axis-shadow leading-[14px] w-full h-[75px] p-[7px] border-[#D0D5DD] rounded-[4px] bg-[#FFFFFF] text-[#667085] placeholder:text-[#667085] focus:!outline-none resize-none"
            value={formData.inputValue}
            onChange={handleInputChange}
          ></textarea>
        </div>
        <div className="flex items-center justify-between">
          <InsertDropdown
            options={insertDropdownOpt}
            position="top"
            initialName={{
              campaign_name: "Insert Variable",
              value: "Insert Variable",
            }}
            setSelectedOption={(newOption) => {
              setSelectedConnectionRequestMessageOption(newOption);
            }}
          />
          <p
            className={`font-inter text-[10px] ${
              formData.inputValue?.length === maxChars
                ? "text-red"
                : "text-[#667085]"
            }`}
          >
            {formData.inputValue?.length}/{maxChars} characters
          </p>
        </div>
      </div>
    </div>
  );
};

export default SendConnectionRequest;
