import banner from "assets/images/welcome-banner.png";
const WelcomeBanner = () => {
  return (
    <div
      className="flex flex-col justify-between py-[28.38px] px-[30.27px] font-inter rounded-[18.92px] h-[273px] relative overflow-hidden z-10"
      style={{
        background:
          "linear-gradient(235.1deg, rgba(255, 122, 234, 0.56) 16.23%,  rgba(255, 122, 234, 0.56) 31.23%, #2A0FD3 101.22%)",
      }}
    >
      <h2 className="font-[700] text-[28.38px] text-[#fff] leading-[34.35px] mb-[14px]">
        Goodbye Manual,
        <br />
        Hello Automation.
      </h2>
      <div className="">
        <p className="font-[400] text-[#FFFFFFCC] text-[13.24px] mb-[13.7px]">
          Need help getting started?
        </p>
        <a
        href="https://pipeline-knowledge-base.help.usepylon.com"
        target="_blank"
        className="cursor-pointer no-underline transition duration-75 ease-in-out hover:bg-[#577CFF] rounded-[9.46px] bg-[#261B4C] w-[136.3px] h-[40.7px] text-[#fff] flex items-center justify-center font-[700] text-[14.4px] leading-[16.5px]"
        >
          Help Center
        </a>
      </div>
      <img
        src={banner}
        alt="banner"
        className="absolute right-[-95px] z-[-1] top-[-24px] w-full max-w-[410px]"
      />
    </div>
  );
};
export default WelcomeBanner;
