import { IoClose } from "react-icons/io5";
import Button from "components/button";
import deleteIcon from "assets/images/delete-modal.svg";
import { useEffect, useState } from "react";

const DeleteCampaignModal = (props) => {
  const { isOpen, onClose, confirmDelete, loading } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [isOpen]);

  if (!isOpen && !isModalVisible) return null;

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[300ms] ease-in-out ${
        isModalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className="fixed inset-0 bg-black opacity-10"
        onClick={onClose}
      ></div>
      <div
        className={`bg-white p-[24px] rounded-lg modal-shadow z-10 w-full max-w-[600px] relative transition-all ease-in-out duration-[150ms] ${
          isModalVisible ? "scale-100" : "scale-95"
        }`}
      >
        <IoClose
          className="text-[#667085] text-[20px] w-[24px] h-[24px] absolute right-[24px] top-[26px] cursor-pointer"
          onClick={onClose}
        />
        <div className="modal-scroll">
          <div className="modal-scroll">
            <div className="flex flex-col items-start justify-start mb-[20px] font-inter">
              <div className="flex items-center justify-center mb-[16px]">
                <img
                  src={deleteIcon}
                  alt="info img"
                  className="h-[48px] w-[48px]"
                />
              </div>
              <h4 className="font-[600] text-[18px] text-[#101828] m-[4px] font-inter">
                Delete Campaign
              </h4>
              <p className="font-normal text-[14px] text-[#475467] font-inter mb-[8px]">
                Are you sure you want to delete this campaign?
              </p>
              <p className="font-[700] text-[14px] text-[#475467] font-inter">
                This action cannot be undone.
              </p>
            </div>
            <div className="flex items-center justify-center mt-[32px]">
              <Button
                title="Cancel"
                isWhiteBtn={true}
                onClick={onClose}
                className="!text-[16px] !h-[44px] !font-[600] !text-[#344054] y-axis-shadow !rounded-[8px]"
              />
              <Button
                title={
                  loading ? (
                    <div className="mx-auto w-[20px] h-[20px] border-[3px] border-t-[3px] border-[#D92D20] border-solid rounded-full animate-spin border-t-[#fff]" />
                  ) : (
                    "Delete"
                  )
                }
                secondaryColor="!border-[#D92D20] !bg-[#D92D20] hover:!bg-transparent hover:!text-[#D92D20] ml-[12px]"
                className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow !rounded-[8px]"
                onClick={confirmDelete}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteCampaignModal;
