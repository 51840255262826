import { useState, useEffect } from "react";

const ProgressBar = ({
  percentage,
  hideProgressStatus = false,
  activeGreenColor,
  isActionFailed, 
}) => {
  const [currentPercentage, setCurrentPercentage] = useState(0);

  useEffect(() => {
    if (isActionFailed) {
      return; 
    }
    const timer = setInterval(() => {
      setCurrentPercentage((prev) => {
        if (prev >= percentage) {
          clearInterval(timer);
          return percentage;
        }
        return prev + 1;
      });
    }, 10);

    return () => clearInterval(timer);
  }, [percentage, isActionFailed]);

  if (isActionFailed) {
    return (
      <div className="flex items-center">
        <div className="bg-gray-200 rounded-full h-[8px] overflow-hidden w-full">
          <div className="h-full bg-transparent"></div>
        </div>
        {!hideProgressStatus && (
          <p className="font-inter text-[#344054] font-[500] text-[12px] ml-[12px] w-[9%] text-right">
            N/A
          </p>
        )}
      </div>
    );
  }

  return (
    <div className="flex items-center">
      <div
        className={`bg-gray-200 rounded-full h-[8px] overflow-hidden ${
          hideProgressStatus ? "w-full" : "w-[91%]"
        }`}
      >
        <div
          className={`h-full transition-all rounded-full duration-500 ease-in-out ${
            Math.round(percentage) === 100 && activeGreenColor
              ? `bg-[#31F700]`
              : "bg-[#7F56D9]"
          }`}
          style={{ width: `${currentPercentage}%` }}
        ></div>
      </div>
      {!hideProgressStatus && (
        <p className="font-inter text-[#344054] font-[500] text-[12px] ml-[12px] w-[9%] text-right">
          {Math.round(percentage)}%
        </p>
      )}
    </div>
  );
};

export default ProgressBar;
