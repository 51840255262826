import Button from "components/button";
import { IoClose } from "react-icons/io5";
import downloadIcon from "assets/images/filed.svg";
import ReactSelect from "components/select";
import { useEffect, useState } from "react";
import { useProspectStore } from "stores";
import { useProspectColumnMapping } from "services/prospecting-api";
import { toast } from "react-toastify";
import heartBreakIcon from "assets/images/dil.png";

const SettingDataMapping = ({ setStep, onClose }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const { fileId, csvColumns } = useProspectStore();
  const prospectColumnMapping = useProspectColumnMapping();
  const options = csvColumns?.map((i) => ({ label: i, value: i }));
  const [isModalVisible, setIsModalVisible] = useState(false);

  // Effect to show the modal and control its visibility
  useEffect(() => {
    setIsModalVisible(true);
    return () => setIsModalVisible(false);
  }, []);

  const handleUploadBlockDomains = async () => {
    try {
      const response = await prospectColumnMapping.mutateAsync({
        file_id: fileId,
        mapping: {
          [selectedOption?.label]: "Company Domain",
        },
        data_type: "block company",
      });

      if (response.status === 200) {
        setStep(5);
      }
    } catch (error) {
      setStep(4);
      toast.success("No domains found in the mapped column.", {
        icon: () => <img src={heartBreakIcon} alt="heartBreakIcon" />,
      });
    }
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  return (
    <>
      <div
        className={`fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[300ms] ease-in-out ${
          isModalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
      >
        <div
          className="fixed inset-0 bg-black opacity-10"
          onClick={() => {
            onClose();
            setIsModalVisible(false);
          }}
        ></div>
        <div
          className={`bg-white p-[24px] rounded-lg modal-shadow z-10 w-full max-w-[600px] relative transition-all ease-in-out duration-[150ms] ${
            isModalVisible ? "scale-100" : "scale-95"
          }`}
        >
          <IoClose
            className="text-[#667085] text-[20px] w-[24px] h-[24px] absolute right-[24px] top-[26px] cursor-pointer"
            onClick={() => {
              onClose();
              setIsModalVisible(false);
            }}
          />
          <div className="flex flex-col items-start justify-start mb-6 font-inter">
            <div className="flex items-center justify-center border-[1px] border-[#E4E7EC] rounded-[10px] p-[11px] mb-[16px] y-axis-shadow">
              <img
                src={downloadIcon}
                alt="info img"
                className="h-[24px] w-[24px]"
              />
            </div>
            <h4 className="font-[600] text-[18px] text-[#101828] m-0 font-inter">
              Data Mapping
            </h4>
            <p className="font-normal text-[14px] text-[#475467] font-inter pt-[13px]">
              You must map the{" "}
              <span className="font-bold text-[#475467]"> Company Domain</span>
            </p>
          </div>
          <div className="flex items-center font-inter w-full pb-[15px]">
            <div className="w-[40%]">
              <label className="block text-[#667085] font-normal text-[16px] font-inter mt-[20px]">
                Company Domain
              </label>
            </div>
            <div className="w-[60%] flex flex-col modal-form-step">
              <label className="text-[#344054] font-[500] text-[14px] font-inter mb-[6px]">
                Select CSV Column
              </label>
              <ReactSelect
                placeholder="Select a Column to Map"
                menuMaxHeight="155px"
                options={options}
                onChange={handleSelectChange}
              />
            </div>
          </div>
          <div className="flex items-center justify-center mt-[32px]">
            <Button
              title="Cancel"
              isWhiteBtn={true}
              isRounded="6px"
              onClick={() => setStep(1)}
              className="!text-[16px] !h-[44px] !font-[600] !text-[#344054] y-axis-shadow"
            />
            {selectedOption && (
              <Button
                title={
                  <>
                    <div className="flex items-center justify-center">
                      {prospectColumnMapping?.isPending ? (
                        <div className="w-[20px] h-[20px] border-[3px] border-t-[3px] border-[#fff] border-solid rounded-full animate-spin border-t-[#8A43FF]" />
                      ) : (
                        "Confirm"
                      )}
                    </div>
                  </>
                }
                secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9] ml-[12px]"
                className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow"
                isRounded="6px"
                onClick={handleUploadBlockDomains}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingDataMapping;
