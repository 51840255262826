import { useState, useEffect } from "react";
import CommonCheckbox from "components/commonCheckbox";
import checkedIcon from "assets/images/green-check.svg";

const WaitingPeriod = ({ value, handleStepValueChange, index }) => {
  const [formData, setFormData] = useState({
    days: "1",
    hours: "",
  });

  useEffect(() => {
    const data = value === "string" ? '' : JSON.parse(value || "{}");
    if (value) {
      setFormData({
        ...formData,
        days: data?.days || '1',
        hours: data?.hours,
      })
    }
    
  }, [value])
  console.log(formData,'formData');
  const handleInputChange = (e, fieldName, max) => {
    const inputValue = e.target.value;
    const regex = /^[0-9]*$/;

    if (
      inputValue === "" ||
      (regex.test(inputValue) && (max === undefined || Number(inputValue) <= max))
    ) {
      setFormData((prev) => {
        const newFormData = { ...prev, [fieldName]: inputValue };
          handleStepValueChange(JSON.stringify(newFormData), index);
        return newFormData;
      });
    }
  };


  // useEffect(() => {
  //   if (value) {
  //     const parsedValue = typeof value !== "string" ? JSON.parse(value || "{}") : '';
  //     setFormData({
  //       days: parsedValue.days || "",
  //       hours: parsedValue.hours || "",
  //       isCheckboxChecked: parsedValue.isCheckboxChecked || true,
  //     });
  //   }
  // }, [value]);

  return (
    <div className="flex items-start p-[14px] bg-[#F9F5FF] rounded-lg border-[2px] border-[#7F56D9] shadow-sm w-[373px] font-inter">
      <div className="flex items-start w-full">
        <img src={checkedIcon} alt="" className="w-[27px] h-[27px] ml-[-2px]" />
        <div className="ml-[10px]">
          <h2 className="font-inter text-[12px] font-[500] text-[#53389E]">Waiting Period</h2>
          <p className="font-inter text-[12px] font-[400] text-[#6941C6] mb-[10px]">
            A break of time before your next action happens.
          </p>
          <div className="flex justify-around">
            <div className="flex items-center px-1">
              <p className="text-[#6941C6] font-[400] text-[12px]">wait</p>
              <input
                type="tel"
                value={formData.days}
                onChange={(e) => handleInputChange(e, 'days', 365)}
                placeholder="0"
                className="text-[20px] w-[65px] h-[26px] !p-1 text-center border-[#D0D5DD] rounded-[4px] y-axis-shadow mx-[10px] font-[500] bg-[#FFFFFF] focus:!outline-none text-[#8D48FF] !outline-none placeholder:text-[#D0D5DD] focus:!border-[#D0D5DD]"
              />
              <p className="text-[#6941C6] font-[400] text-[12px]">days</p>
            </div>
            <div className="flex items-center px-1">
              <p className="text-[#6941C6] font-[400] text-[12px]">wait</p>
              <input
                type="tel"
                value={formData.hours}
                onChange={(e) => handleInputChange(e, 'hours', 24)}
                placeholder="0"
                className="text-[20px] w-[65px] h-[26px] !p-1 text-center border-[#D0D5DD] rounded-[4px] y-axis-shadow mx-[10px] font-[500] bg-[#FFFFFF] focus:!outline-none text-[#8D48FF] !outline-none placeholder:text-[#D0D5DD] focus:!border-[#D0D5DD]"
              />
              <p className="text-[#6941C6] font-[400] text-[12px]">hours</p>
            </div>
          </div>
        </div>
      </div>
      <div className="mr-[16px]">
        <CommonCheckbox
          isRound={true}
          checked={true}
        />
      </div>
    </div>
  );
};

export default WaitingPeriod;
