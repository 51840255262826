import Cloud from "assets/images/cloud_illustration.gif";
import { useEffect, useState } from "react";

const CampaignIsStillUnderProcess = (props) => {
  const { isOpen, onClose, setIsCampaignIsStillUnderProcessModalOpen } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [isOpen]);

  if (!isOpen && !isModalVisible) return null;

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[300ms] ease-in-out ${
        isModalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className="fixed inset-0 bg-black opacity-10"
        onClick={onClose}
      ></div>
      <div
        className={`bg-white p-[26px] rounded-lg shadow-lg z-10 w-full max-w-[600px] relative transition-all ease-in-out duration-[150ms] ${
          isModalVisible ? "scale-100" : "scale-95"
        }`}
      >
        <div className="modal-scroll">
          <div className="flex justify-center mt-[48px]">
            <img src={Cloud} alt="" className="w-[180px]" />
          </div>
          <div className="text-center w-full mt-[18px]">
            <h1 className="text-[#101828] text-[16px] font-[600]">
              Your Campaign is still being processed!
            </h1>
          </div>
          <div className="text-center w-full mt-[18px] justify-center flex">
            <div className="w-[240px] text-center">
              <h1 className="text-[#475467] text-[12px] font-[400]">
                We’re working on queueing your leads and settings up your
                sequence.
              </h1>
            </div>
          </div>
          <div className="mt-[42px]">
            <button
              className="bg-[#7F56D9] text-[#FFFFFF] text-[16px] font-[600] w-[100%] h-[44px] rounded-[8px]"
              onClick={() => setIsCampaignIsStillUnderProcessModalOpen(false)}
            >
              Got it!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignIsStillUnderProcess;
