import { useContext, useEffect, useRef, useState } from "react";
import Button from "components/button";
import downloadIcon from "assets/images/upload-csv-file.svg";
import ReactSelect from "components/select";
import { companyDropDownOptions, dropdownOptions } from "constant";
import { AppContext } from "context/AppContext";
import { useProspectColumnMapping } from "services/prospecting-api";
import { useAuthStore, useProspectStore } from "stores";
import useToastStore from "stores/toastStore";
import { toast } from "react-toastify";

const defaultSelect = { value: "do_not_include", label: "Do Not Include" };
const defaultSelectCompany = { value: "", label: "Select option" };

const FormStep = ({ setStep, setEnrichType }) => {
  const prospectColumnMapping = useProspectColumnMapping();
  const { appState, setSelectedButtonType } = useContext(AppContext);
  const dataMappingParentRef = useRef(null);
  const { fileId, csvColumns } = useProspectStore();
  const { companyId } = useAuthStore();
  const { toastId } = useToastStore();
  const [selectedOptions, setSelectedOptions] = useState({});
  const selectRefs = {
    firstName: useRef(null),
    companyName: useRef(null),
    lastName: useRef(null),
    linkedinUrl: useRef(null),
    testCompanyId: useRef(null),
    email: useRef(null),
  };

  useEffect(() => {
    const defaultSelector =
      appState.selectedButtonType === "Company"
        ? defaultSelectCompany
        : defaultSelect;

    // setSelectedOptions({
    //   firstName: defaultSelector,
    //   companyName: defaultSelector,
    //   lastName: defaultSelector,
    //   linkedinUrl: defaultSelector,
    //   testCompanyId: defaultSelector,
    // });

    setEnrichType(appState.selectedButtonType);
  }, [appState.selectedButtonType, setEnrichType]);

  const handleSelectChange = (field) => (selectedOption) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [field]: selectedOption || defaultSelect,
    }));
  };

  const scrollToView = (ref) => {
    if (dataMappingParentRef.current && ref.current) {
      setTimeout(() => {
        dataMappingParentRef.current.scrollTo({
          top: ref.current.offsetTop - dataMappingParentRef.current.offsetTop,
          behavior: "smooth",
        });
      }, 200);
    }
  };

  const getFilteredOptions = (selectedOptions) => {
    const options = csvColumns?.map((item) => ({ value: item, label: item }));
    return appState?.importMethodClicked !== "uploadCampanyCsv"
      ? [...options, { value: "do_not_include", label: "Do Not Include" }] ||
          companyDropDownOptions
      : [...options, { value: "do_not_include", label: "Do Not Include" }] ||
          dropdownOptions;
  };

  const handleConfirm = async () => {
    toast.dismiss(toastId);
    const transformedOptions = Object.fromEntries(
      Object.entries(selectedOptions).map(([key, { value, label }]) => [
        label,
        key,
      ])
    );

    try {
      const response = await prospectColumnMapping.mutateAsync({
        company_id: companyId,
        file_id: fileId,
        mapping: transformedOptions,
        data_type:
          appState?.importMethodClicked === "uploadCampanyCsv"
            ? "company"
            : appState?.importMethodClicked === "uploadCsvEmail"
            ? "email"
            : "people",
      });

      if (response.status === 200) {
        if (appState?.importMethodClicked === "uploadCampanyCsv") {
          setStep("importing-your-leads");
        } else {
          setStep("enrich-leads-step");
        }
      }
    } catch (error) {
      setStep("data-mapping-failed");
      console.error("Error during mapping confirmation:", error);
    }
  };

  const disableConfirmButton =
    appState.importMethodClicked === "uploadPeopleCsv"
      ? ["Company Name", "First Name", "Last Name"].every((key) =>
          Object.keys(selectedOptions).includes(key)
        )
      : appState.importMethodClicked === "uploadCampanyCsv"
      ? ["Company Name"].every((key) =>
          Object.keys(selectedOptions).includes(key)
        )
      : Object.keys(selectedOptions).length > 0;

  return (
    <>
      <div className="flex items-start justify-start mb-[24px] font-inter">
        <div className="flex items-center justify-center border-[1px] border-[#E4E7EC] rounded-[10px] p-[11px] y-axis-shadow mr-[10px]">
          <img
            src={downloadIcon}
            alt="info img"
            className="h-[25px] w-[25px] min-w-[25px]"
          />
        </div>
        <div className="w-full">
          <h4 className="font-[600] text-[18px] text-[#181D27] m-0 font-inter">
            Map Your Data
          </h4>
          <p className="font-normal text-[14px] text-[#535862] font-inter pt-0.5">
            {appState.importMethodClicked === "uploadCampanyCsv" ? (
              "You must map the Company Name and Company Domain to continue."
            ) : appState.importMethodClicked === "uploadPeopleCsv" ? (
              <>
                You must map
                <b className="pl-[5px]">First Name, Last Name</b> and{" "}
                <b>Company Name</b> from your CSV.
              </>
            ) : null}
            {appState?.importMethodClicked === "uploadCsvUrl" && (
              <>
                You must map the column with your LinkedIn URLs from your CSV.
              </>
            )}
            {appState?.importMethodClicked === "uploadCsvEmail" && (
              <>Map email data to find LinkedIn URLs for leads.</>
            )}
          </p>
        </div>
      </div>
      {["Peoples", "Company"]?.includes(appState.selectedButtonType) && (
        <div
          className="w-full max-h-[380px] overflow-auto modal-scroll pr-[10px] pb-[12px] light-select-placeholder"
          ref={dataMappingParentRef}
        >
          <div className="w-[446px] min-h-[197px] mx-auto">
            {appState?.importMethodClicked === "uploadPeopleCsv" && (
              <>
                <div
                  className="flex flex-col modal-form-step mb-[20px]"
                  ref={selectRefs.firstName}
                >
                  <label className="text-[#344054] font-[500] text-[14px] font-inter mb-[6px]">
                    First Name<span className="text-[#7F56D9]">*</span>
                  </label>
                  <ReactSelect
                    value={selectedOptions?.["First Name"]}
                    onChange={handleSelectChange("First Name")}
                    options={getFilteredOptions(selectedOptions)}
                    placeholder="Select a Column to Map"
                    menuMaxHeight="155px"
                    onFocus={() =>
                      scrollToView(selectRefs.firstName, "firstName")
                    }
                  />
                </div>

                <div
                  className="flex flex-col modal-form-step mb-[20px]"
                  ref={selectRefs.lastName}
                >
                  <label className="text-[#344054] font-[500] text-[14px] font-inter mb-[6px]">
                    Last Name
                    <span className="text-[#7F56D9] inline-block mt-[-8px]">
                      *
                    </span>
                  </label>
                  <ReactSelect
                    value={selectedOptions?.["Last Name"]}
                    onChange={handleSelectChange("Last Name")}
                    options={getFilteredOptions(selectedOptions)}
                    placeholder="Select a Column to Map"
                    menuMaxHeight="155px"
                    onFocus={() =>
                      scrollToView(selectRefs.lastName, "lastName")
                    }
                  />
                </div>
              </>
            )}
            {appState?.importMethodClicked === "uploadCampanyCsv" ||
            appState?.importMethodClicked === "uploadPeopleCsv" ? (
              <>
                <div
                  className="w-full flex flex-col modal-form-step mb-[20px]"
                  ref={selectRefs.companyName}
                >
                  <label className="text-[#344054] font-[500] text-[14px] font-inter mb-[6px]">
                    Company Name
                    <span className="text-[#7F56D9] inline-block mt-[-8px]">
                      *
                    </span>
                  </label>
                  <ReactSelect
                    value={selectedOptions?.["Company Name"]}
                    onChange={handleSelectChange("Company Name")}
                    options={getFilteredOptions(selectedOptions)}
                    placeholder="Select a Column to Map"
                    menuMaxHeight="128px"
                    onFocus={() =>
                      scrollToView(selectRefs.companyName, "companyName")
                    }
                  />
                </div>
                <div
                  className="w-full flex flex-col modal-form-step"
                  ref={selectRefs.testCompanyId}
                >
                  <label className="text-[#344054] font-[500] text-[14px] font-inter mb-[6px]">
                    Company Website
                    <span className="text-[#C6C6C6] pl-[5px]">(optional)</span>
                  </label>
                  <ReactSelect
                    value={selectedOptions?.["Company Domain"]}
                    onChange={handleSelectChange("Company Domain")}
                    options={getFilteredOptions(selectedOptions)}
                    placeholder="Select a Column to Map"
                    menuMaxHeight="128px"
                    onFocus={() =>
                      scrollToView(selectRefs.testCompanyId, "testCompanyId")
                    }
                  />
                </div>
              </>
            ) : null}
            {appState?.importMethodClicked === "uploadCsvUrl" && (
              <div
                className="w-full flex flex-col modal-form-step"
                ref={selectRefs.linkedinUrl}
              >
                <label className="text-[#344054] font-[500] text-[14px] font-inter mb-[6px]">
                  LinkedIn URL
                  <span className="text-[#7F56D9] inline-block mt-[-8px]">
                    *
                  </span>
                </label>
                <ReactSelect
                  value={selectedOptions?.["LinkedIn URL"]}
                  onChange={handleSelectChange("LinkedIn URL")}
                  options={getFilteredOptions(selectedOptions)}
                  placeholder="Select a Column to Map"
                  menuMaxHeight="128px"
                  onFocus={() =>
                    scrollToView(selectRefs.linkedinUrl, "linkedinUrl")
                  }
                />
              </div>
            )}
            {appState?.importMethodClicked === "uploadCsvEmail" && (
              <div
                className="w-full flex flex-col modal-form-step"
                ref={selectRefs.email}
              >
                <label className="text-[#344054] font-[500] text-[14px] font-inter mb-[6px]">
                  Email
                  <span className="text-[#7F56D9] inline-block mt-[-8px]">
                    *
                  </span>
                </label>
                <ReactSelect
                  value={selectedOptions?.["Email"]}
                  onChange={handleSelectChange("Email")}
                  options={getFilteredOptions(selectedOptions)}
                  placeholder="Select a Column to Map"
                  menuMaxHeight="128px"
                />
              </div>
            )}
          </div>
        </div>
      )}

      <div className="flex items-center justify-center mt-[32px]">
        {disableConfirmButton ? (
          <Button
            title={
              <>
                <div className="flex items-center justify-center">
                  {prospectColumnMapping?.isPending ? (
                    <div className="flex items-center justify-center">
                      <div className="w-[18px] h-[18px] border-[3px] border-t-[3px] mr-[10px] border-[#8A43FF] border-solid rounded-full animate-spin border-t-[#fff]" />
                      Mapping data...
                    </div>
                  ) : (
                    "Continue"
                  )}
                </div>
              </>
            }
            secondaryColor={` ${
              prospectColumnMapping?.isPending
                ? "hover:!bg-transparent !text-[#344054] hover:!text-[#344054] !bg-[#FFF] !border-[#D0D5DD]"
                : "hover:!bg-transparent hover:!text-[#7F56D9] !bg-[#7F56D9] !border-[#7F56D9]"
            }`}
            className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow"
            isRounded="6px"
            onClick={handleConfirm}
            disabled={prospectColumnMapping?.isPending}
          />
        ) : (
          <Button
            title="Cancel"
            isWhiteBtn={true}
            isRounded="6px"
            onClick={() => {
              toast.dismiss(toastId);
              setStep("uploadPeopleCsv");
            }}
            className="!text-[16px] !h-[44px] !font-[600] !text-[#344054] y-axis-shadow"
          />
        )}
      </div>
    </>
  );
};

export default FormStep;
