import { convertToTotalMonths } from "utils/";

const TimeSpent = (props) => {
  const {
    deleteUsingBackspace,
    formData,
    setFormData,
    preventInvalidInput,
  } = props;

  const onInputChange = (event) => {
    let { name, value } = event?.target;
    value = value.replace(/^0+(?=\d)/, "");
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Extract min and max values from formData
  const minYear = parseInt(formData?.minYear, 10) || 0;
  const minMonth = parseInt(formData?.minMonth, 10) || 0;
  const maxYear = parseInt(formData?.maxYear, 10) || 0;
  const maxMonth = parseInt(formData?.maxMonth, 10) || 0;

  // Calculate total months for minTime and maxTime
  const totalMinMonths = convertToTotalMonths(minYear, minMonth);
  const totalMaxMonths = convertToTotalMonths(maxYear, maxMonth);

  // Error validation
  const isMaxTimeValid =
    totalMaxMonths > totalMinMonths ||
    (maxYear === minYear && maxMonth >= minMonth);

  return (
    <>
      <div className="flex items-center mb-[14px] px-2">
        <p className="text-[#344054] font-[500] text-normal text-[14px] mr-[15px] min-w-[70px]">
          Minimum
        </p>
        <div className="flex items-center">
          <input
            type="tel"
            placeholder="years"
            className="text-[#344054] placeholder:text-[#B2B4B8] text-[14px] relative h-full !outline-none border-[#D0D5DD] focus:!outline-none focus:!border-[#D0D5DD] bg-transparent border-[1px] w-[80px] y-axis-shadow rounded-[8px] px-[14px] py-[10px] min-h-[36px] mr-[11px] text-center"
            onChange={onInputChange}
            name="minYear"
            value={formData?.["minYear"]}
            min={0}
            maxLength={4}
            onKeyPress={preventInvalidInput}
            onKeyDown={deleteUsingBackspace}
          />
          <input
            type="tel"
            placeholder="months"
            className="text-[#344054] placeholder:text-[#B2B4B8] text-[14px] relative h-full !outline-none border-[#D0D5DD] focus:!outline-none focus:!border-[#D0D5DD] bg-transparent border-[1px] w-[80px] y-axis-shadow rounded-[8px] px-[14px] py-[10px] min-h-[36px] mr-[6px] text-center"
            onChange={(e) => {
              let value = e.target.value;
              let numberPattern = /^(1[0-2]?|[0-9])$/;
              let match = value.match(numberPattern);
              if (match) {
                onInputChange(e);
              }
            }}
            name="minMonth"
            value={formData?.["minMonth"]}
            min={0}
            onKeyDown={deleteUsingBackspace}
            onKeyPress={preventInvalidInput}
          />
        </div>
      </div>
      <div className="flex items-center px-2">
        <p className="text-[#344054] font-[500] text-normal text-[14px] mr-[15px] min-w-[70px]">
          Maximum
        </p>
        <div className="flex items-center">
          <input
            type="tel"
            placeholder="years"
            className="text-[#344054] placeholder:text-[#B2B4B8] text-[14px] relative h-full focus:!outline-none focus:!border-[#D0D5DD] !outline-none border-[#D0D5DD] bg-transparent border-[1px] w-[80px] text-center rounded-[8px] px-[14px] py-[10px] min-h-[34px] mr-[11px] y-axis-shadow"
            onChange={onInputChange}
            name="maxYear"
            min={0}
            maxLength={4}
            onKeyPress={preventInvalidInput}
            value={formData?.["maxYear"]}
          />
          <input
            type="tel"
            placeholder="months"
            className="text-[#344054] placeholder:text-[#B2B4B8] text-[14px] relative h-full focus:!outline-none focus:!border-[#D0D5DD] !outline-none border-[#D0D5DD] bg-transparent border-[1px] w-[80px] text-center rounded-[8px] px-[14px] py-[10px] min-h-[34px] y-axis-shadow"
            onChange={(e) => {
              let value = e.target.value;
              let numberPattern = /^(1[0-2]?|[0-9])$/;
              let match = value.match(numberPattern);
              if (match) {
                onInputChange(e);
              }
            }}
            min={0}
            name="maxMonth"
            value={formData?.["maxMonth"]}
            onKeyPress={preventInvalidInput}
            onKeyDown={deleteUsingBackspace}
          />
        </div>
      </div>
      {!isMaxTimeValid &&
        formData?.["minYear"] &&
        formData?.["minMonth"] &&
        formData?.["maxYear"] &&
        formData?.["maxMonth"] && (
          <p className="text-[11px] mt-[8px] text-[red]">
            Max time spent must be more than min time spent.
          </p>
        )}
    </>
  );
};

export default TimeSpent;
