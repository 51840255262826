import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

const useAccountStore = create(
  persist(
    (set) => ({
      linkedinAccountDetails: {},
      setLinkedinAccount: (linkedinAccountDetails) =>
        set({ linkedinAccountDetails }),
      clearLinkedinAccount: () => set({ linkedinAccountDetails: {} }),
      workspaceCreditLimit: 0,
      setWorkSpaceCreditLimit: (creditLimit) =>
        set({ workspaceCreditLimit: creditLimit }),
      clearWorkSpaceCreditLimit: () => set({ workspaceCreditLimit: 0 }),
    }),
    {
      name: "account-limits",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export default useAccountStore;
