import Rocket from "assets/images/rocket.png";

const StartCampaign = () => {
  return (
    <>
      <div className="flex items-center p-4 bg-white rounded-lg shadow-md py-[6px] px-[8px]">
        <div
          className="border border-[#EAECF0] h-[30px] w-[30px] flex justify-center items-center rounded-[4.74px]"
          style={{
            boxShadow: "0px 0.91px 1.82px 0px #1018280D",
          }}
        >
          <img src={Rocket} alt="colorWand" className="w-[17px]" />
        </div>
        <span className="text-[#101828] font-inter text-[13.67px] font-[600] ml-[8.53px]">
          Start Campaign
        </span>
      </div>
    </>
  );
};

export default StartCampaign;
