import Layout from "components/layout";
import { useLocation, useNavigate } from "react-router-dom";
import BreadcrumbHeader from "components/breadcrumbHeader";
import Button from "components/button";
import backIcon from "assets/images/dark-left.svg";
import SearchResultDefaultStep from "components/results/steps/searchResultDefaultStep";
import ResultSaveLeadStep from "components/results/steps/resultSaveLeadStep";
import { useState } from "react";
import AddTeamResult from "components/results/steps/addTeamResultStep";
import SuccessResultStep from "components/results/steps/resultSuccessStep";
import ReconnectBanner from "components/reconnectBanner";

const Results = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { leadCount, profiles, prospectData } = state;
  const [resultSteps, setResultSteps] = useState(1);

  const renderStepComponent = () => {
    switch (resultSteps) {
      case 1:
        return (
          <SearchResultDefaultStep
            setResultSteps={setResultSteps}
            leadCount={leadCount}
            prospectData={prospectData}
            profiles={profiles}
          />
        );
      case 2:
        return (
          <ResultSaveLeadStep
            setResultSteps={setResultSteps}
            prospectData={prospectData}
          />
        );
      case 3:
        return (
          <AddTeamResult
            setResultSteps={setResultSteps}
            isTeamAccessModal={true}
            prospectData={prospectData}
          />
        );
      case 4:
        return <SuccessResultStep />;
      default:
        return (
          <SearchResultDefaultStep
            setResultSteps={setResultSteps}
            profiles={profiles}
          />
        );
    }
  };

  return (
    <Layout>
      <div className="h-[100vh] flex flex-col justify-start items-start font-inter">
        <BreadcrumbHeader
          breadcrumbItems={[
            { title: "Prospecting", route: "/prospecting" },
            {
              title: "New Lead List",
              route: "/findLeads",
            },
            { title: "Results" },
          ]}
        />
        <ReconnectBanner />
        <div
          className="result-page-bg max-w-[100%] w-full h-full px-[26px] py-[20px]"
          data-aos="fade-left"
        >
          {resultSteps === 1 || resultSteps === 2 ? (
            <Button
              style={{
                boxShadow:
                  " 0px 1px 2px rgba(0, 0, 0, 0.05), inset 0px 0px 1px #D1D5DB,inset 0px 0px 0px #FFFFFF",
              }}
              isWhiteBtn={true}
              isFullWidth={false}
              className="mr-[9px] !px-[10px] !w-[82px]"
              title={
                <div className="flex items-center justify-center font-inter font-[500] text-[14px] !text-[#111827] !border-[#D1D5DB] mt-[-2px]">
                  <img
                    src={backIcon}
                    alt="import"
                    className="mr-[5px] w-[17px] h-[17px]"
                  />
                  Back
                </div>
              }
              onClick={() =>
                navigate("/findLeads", { state: { prospectData } })
              }
            />
          ) : null}
          <div className="flex items-center justify-center font-inter w-full h-[calc(100%_-120px)]">
            <div
              className={`bg-white p-[24px] rounded-[12px] ${
                resultSteps === 3 && "px-0"
              }`}
              style={{
                boxShadow:
                  " 0px 8px 8px -4px rgba(16, 24, 40, 0.04), 0px 20px 24px -4px rgba(16, 24, 40, 0.10)",
              }}
            >
              {renderStepComponent()}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Results;
