import Button from "components/button";
import OutlinedInput from "components/outlinedInput";
import { FaRegCircleQuestion } from "react-icons/fa6";
import { HiOutlineMail } from "react-icons/hi";
import TimezoneSelectComponent from "components/timeZoneSelect";
import { useEffect, useState } from "react";
import { MdOutlineAccessTime } from "react-icons/md";
import { toast } from "react-toastify";
import nameCheck from "assets/images/name-check.svg";
import { useAuthStore } from "stores";
import {
  useGetWorkspaceSettings,
  useUpdateWorkspaceSettings,
} from "services/settings-api";
import useSettingStore from "stores/settingStore";
import { getTimezoneObject } from "utils/index";
import SelectInput from "components/leadsComponent/select-input";
import { timeZones } from "constant";
import ReactSelect from "components/select";

const ProfileForm = ({ setIsUpdatePasswordModal }) => {
  const { isAdmin } = useAuthStore();
  const { userData, setUserData } = useSettingStore();
  const updateSettings = useUpdateWorkspaceSettings();
  const getSettings = useGetWorkspaceSettings();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [website, setWebsite] = useState("");
  const [timezone, setTimezone] = useState({
    value: "Pacific/Midway",
    label: "(GMT-11:00) Midway Island, Samoa",
  });
  const [companyError, setCompanyError] = useState("");
  const [websiteError, setWebsiteError] = useState("");

  const handleNameChange = (value) => {
    setName(value);
  };

  const handleEmailChange = (value) => {
    setEmail(value);
  };

  const handleCompanyChange = (value) => {
    setCompany(value);
    // Validate company name
    if (value.length < 3) {
      setCompanyError("Company name must be at least 3 characters long.");
    } else {
      setCompanyError("");
    }
  };

  const handleWebsiteChange = (value) => {
    setWebsite(value);
    // Validate website URL
    const urlPattern =
      /^(https?:\/\/)?([\w\d-]+\.)+[a-z]{2,}(\/[\w\d-]*)*\/?$/i;
    if (!urlPattern.test(value)) {
      setWebsiteError("Please enter a valid URL.");
    } else {
      setWebsiteError("");
    }
  };

  const handleTimezoneChange = (selectedTimezone) => {
    setTimezone(selectedTimezone);
  };

  useEffect(() => {
    if (company?.trim()?.length === 0) {
      setCompanyError("");
    }
  }, [company]);

  useEffect(() => {
    if (website?.trim()?.length === 0) {
      setWebsiteError("");
    }
  }, [website]);

  const handleUpdateSettings = async () => {
    if (companyError || websiteError) {
      toast.error("Please fix the validation errors before saving.");
      return;
    }
    try {
      const data = {
        company_name: company,
        website: website,
        timezone: timezone?.label,
      };
      await updateSettings?.mutateAsync(data);
      const storeData = { ...userData, company: data };

      setUserData(storeData);
      toast.success("Personal Info Saved", {
        icon: () => <img src={nameCheck} alt="name" />,
      });
      getUserProfile();
    } catch (error) {
      toast.success("Something went wrong");
    }
  };
  const handleUpdatePassword = async () => {
    setIsUpdatePasswordModal(true);
  };

  useEffect(() => {
    const selectedTimezone = getTimezoneObject(
      userData?.company?.timezone || ""
    );
    console.log({ selectedTimezone });
    setName(userData?.user?.name);
    setEmail(userData?.user?.email);
    setCompany(userData?.company?.company_name);
    setWebsite(userData?.company?.website);
    setTimezone(selectedTimezone);
  }, [userData]);

  console.log({ userData });

  const getUserProfile = async () => {
    try {
      const response = await getSettings.mutateAsync();
      const responseMain = {
        ...response,
        user: {
          ...response?.user,
          profile_pic: userData?.profile_pic,
        },
        company: {
          ...response?.company,
          // localTimezone: getTimezoneObject(response?.company?.timezone)?.value,
        },
      };
      setUserData(responseMain);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="max-w-[1064px]">
      <div className="border-t-[1px] border-[#E4E7EC] pt-[24px] pb-[20px] flex items-start">
        <h6 className="w-[280px] mr-[32px] font-[600] text-[#344054] text-[14px] mb-0">
          Name<span className="text-[#7F56D9]">*</span>
        </h6>
        <div className="flex items-start !w-[512px]">
          <OutlinedInput
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => handleNameChange(e.target.value)}
            className="h-[44px] placeholder:!text-[#949494] !text-[16px]"
            readOnly
          />
        </div>
      </div>
      <div className="border-t-[1px] border-[#E4E7EC] py-[20px] flex items-start">
        <h6 className="w-[280px] mr-[32px] font-[600] text-[#344054] text-[14px] mb-0">
          Email address<span className="text-[#7F56D9]">*</span>
        </h6>
        <div className="flex flex-col !w-[512px]">
          <div className="flex items-start relative w-full mb-[20px]">
            <HiOutlineMail className="text-[#667085] w-[20px] h-[20px] text-[20px] absolute top-[12px] left-[14px] z-10" />
            <OutlinedInput
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => handleEmailChange(e.target.value)}
              className="pl-[40px] h-[44px] placeholder:!text-[#949494] !text-[16px]"
              readOnly
            />
          </div>
          <Button
            title="Reset Password"
            gradientBg={true}
            className="!w-[171px] !text-[14px] leading-[20px] !h-[40px] !font-[600] y-axis-shadow !rounded-[7px] !pb-[3px] pt-[1.5px]"
            onClick={handleUpdatePassword}
          />
        </div>
      </div>
      <div className="border-t-[1px] border-[#E4E7EC] py-[20px]">
        <div className="flex items-start">
          <h6 className="w-[280px] mr-[32px] font-[600] text-[#344054] text-[14px] mb-0">
            Workspace Name<span className="text-[#7F56D9]">*</span>
          </h6>
          <div>
            <OutlinedInput
              type="text"
              placeholder="Jupiter AI"
              value={company}
              onChange={(e) => handleCompanyChange(e.target.value)}
              readOnly={!isAdmin}
              className="h-[44px] !w-[512px] placeholder:!text-[#949494] !text-[16px]"
            />
            {companyError && (
              <div className="text-red-500 text-sm mt-1">{companyError}</div>
            )}
          </div>
        </div>
        {/* <div className="flex items-start">
          <h6 className="w-[280px] mr-[32px] font-[600] text-[#344054] text-[14px] mb-0">
            Website<span className="text-[#7F56D9]">*</span>
          </h6>
          <div>
            <OutlinedInput
              type="text"
              placeholder="jupiter.ai"
              value={website}
              onChange={(e) => handleWebsiteChange(e.target.value)}
              readOnly={!isAdmin}
              className="h-[44px] !w-[512px] placeholder:!text-[#949494] !text-[16px]"
            />
            {websiteError && (
              <div className="text-red-500 text-sm mt-1">{websiteError}</div>
            )}
          </div>
        </div> */}
      </div>
      <div className="border-t-[1px] border-[#E4E7EC] pt-[20px] flex items-start">
        <h6 className="w-[280px] mr-[32px] font-[600] text-[#344054] text-[14px] mb-0">
          Timezone
          <FaRegCircleQuestion className="text-[16px] text-[#98A2B3] ml-[5px] mt-[-2px] inline" />
        </h6>
        <div className="!w-[512px] select-scroll-styling profile-timezone">
          <ReactSelect
            value={timezone}
            onChange={handleTimezoneChange}
            placeholder="Select your timezone"
            icon={MdOutlineAccessTime}
            maxHeight={"150px"}
            options={timeZones}
          />
        </div>
      </div>
      <div className="flex items-center justify-end mt-[24px]">
        {/* <Button
          title="Cancel"
          isWhiteBtn={true}
          onClick={() => {}}
          className="!w-[80px] !text-[14px] leading-[18px] !h-[40px] !font-[600] !text-[#344054] y-axis-shadow !rounded-[7px] !pb-0 pt-[1.5px]"
        /> */}
        <Button
          title="Save"
          gradientBg={true}
          className="!w-[85px] !text-[13.3px] leading-[18px] !h-[40px] !font-[600] y-axis-shadow !rounded-[7px] !pb-1 pt-[1.5px] ml-[12px]"
          onClick={handleUpdateSettings}
        />
      </div>
    </div>
  );
};

export default ProfileForm;
