import { IoClose } from "react-icons/io5";
import Button from "components/button";
import checkBg from "assets/images/check-bg.svg";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const PurchasedSuccessModal = (props) => {
  const { isOpen, onClose, setTab = ()=>null } = props;
  const location = useLocation()
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [isOpen]);

  const navigate = useNavigate()

  if (!isOpen && !isModalVisible) return null;

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[300ms] ease-in-out ${
        isModalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className="fixed inset-0 bg-black opacity-10"
        onClick={onClose}
      ></div>
      <div
        className={`p-[24px] bg-white rounded-lg shadow-lg z-10 w-full max-w-[449px] relative transition-all ease-in-out duration-[150ms] ${
          isModalVisible ? "scale-100" : "scale-95"
        }`}
      >
        <IoClose
          className="text-[#667085] text-[20px] w-[24px] h-[24px] absolute right-[24px] top-[22px] cursor-pointer"
          onClick={onClose}
        />
        <div className="modal-scroll">
          <div className="flex flex-col items-start justify-start font-inter">
            <div className="flex items-center justify-center mb-[16px] relative">
              <div className="relative flex items-center justify-center h-[48px] w-[48px] ml-[-8px]">
                <img
                  src={checkBg}
                  alt="checkBg"
                  className="h-full w-full rounded-full absolute top-0 z-[1]"
                />
                <div className="h-[20px] w-[20px] rounded-full relative z-[2]">
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 130.2 130.2"
                    className="w-full h-full"
                  >
                    <circle
                      className="custom-checkmark circle"
                      fill="none"
                      stroke="#039855"
                      strokeWidth="10"
                      strokeMiterlimit="10"
                      cx="65.1"
                      cy="65.1"
                      r="60"
                    />
                    <polyline
                      className="custom-checkmark check"
                      fill="none"
                      stroke="#039855"
                      strokeWidth="10"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      points="92.2,50.2 52.5,88.8 32.8,70.5"
                    />
                  </svg>
                </div>
              </div>
              <div className="animate-ping duration-100 bg-[#ECFDF3] absolute top-0 left-[-8px] w-[50px] h-[50px] rounded-full z-[-1]" />
            </div>
            <h4 className="font-[600] text-[18px] text-[#101828] mb-[9px] font-inter">
              Purchase Successful
            </h4>
            <p className="font-normal text-[14px] text-[#475467] mb-[12px] font-inter">
              Check your email inbox for the receipt.
            </p>
            <p className="font-normal text-[14px] text-[#475467] font-inter">
              You can find the receipt under the <b>Billing</b> tab in Settings.
            </p>
          </div>
          <div className="flex items-center justify-center mt-[18px]">
            <Button
              title="Go to Billing"
              isWhiteBtn={true}
              onClick={() => {
                onClose();
                if(location?.pathname !== "/settings"){
                  navigate(`/settings?tab=Billing`)
                }else{
                setTab?.("Billing");
                }
              }}
              className="!text-[16px] !h-[44px] !font-[600] !text-[#344054] y-axis-shadow !rounded-[8px]"
            />
            <Button
              title="Done"
              secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9] ml-[12px]"
              className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow !rounded-[8px]"
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchasedSuccessModal;
