import { useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";

const Input = ({
  type,
  name,
  placeholder,
  isSigninInput = false,
  className,
  value,
  onChange,
  onBlur,
  onFocus,
  // onPaste,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const inputClasses = `w-full h-[42px] font-[400] bg-[#00000014] border border-1 border-[#333741] rounded-[7px] outline-none py-[9px] px-[13px] relative 
    text-[16px] text-[#fff] placeholder:${
      isSigninInput ? "text-white" : "!text-[#85888E]"
    }
    focus-visible:border-[#fff] focus-visible:text-[#fff] focus-visible:placeholder:text-[#fff] transition-all mb-[18px]
    ${className}`;

  return (
    <div className="relative w-full">
      <input
        type={showPassword ? "text" : type || "text"}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        autoComplete="off"
        onFocus={onFocus}
        onBlur={onBlur}
        className={inputClasses}
        // onPaste={(e) => onPaste && e.preventDefault()}
        onDrop={(e) => e.preventDefault()}
        onDragOver={(e) => e.preventDefault()}
        {...rest}
      />
      {type === "password" &&
        (!showPassword ? (
          <FiEye
            className="absolute right-[15px] z-50 top-[14px] cursor-pointer"
            color="#CBCBE866"
            onClick={() => setShowPassword(!showPassword)}
          />
        ) : (
          <FiEyeOff
            className="absolute right-[15px] z-50 top-[14px] cursor-pointer"
            color="#CBCBE866"
            onClick={() => setShowPassword(!showPassword)}
          />
        ))}
    </div>
  );
};

export default Input;
