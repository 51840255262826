import './style.css'; 

const CustomRadioButton = ({ className, checked, onChange, id }) => {
  return (
    <label className={`custom-radio ${className}`} >
      <input
        type="radio"
        id={id}
        name="radio"
        checked={checked}
        onChange={onChange}
      />
      <span className="radio-checkmark"></span>
    </label>
  );
};

export default CustomRadioButton;
