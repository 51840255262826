import Logo from "assets/images/logo.svg";
import Dots from "components/dots";

const Waiting = () => {
  return (
    <>
      <div className="h-[100vh] flex flex-col justify-center items-center max-[767px]:h-auto  max-[767px]:p-[20px]  max-[767px]:pt-[50px]  max-[767px]:max-w-[100%]" data-aos="fade-right">
        <div className="max-w-[420px] mx-auto flex flex-col justify-center items-center">
          <img src={Logo} alt="Logo" className="h-[59px] w-[59px]" />
          <h1 className="text-[32px] text-center text-white font-[700] mt-[15px] mb-[10px]">
            Setting up your Workspace
          </h1>
          <p className="text-[16px] text-center text-[#CBCBE8BF]">
            Pipeline is connecting your LinkedIn Account. This process may take
            a few minutes.
          </p>
        </div>
      </div>
      <Dots screen={"waiting"} />
    </>
  );
};

export default Waiting;
