import { IoClose } from "react-icons/io5";
import Button from "components/button";
import connectIcon from "assets/images/connect.svg";
import Loader from "components/loader";
import { useEffect, useState } from "react";

const ConnectLoadingModal = ({
  isOpen,
  onClose,
  setLoader,
  setOpenConnectModalSteps,
  timerId,
  clearInterval,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [isOpen]);

  if (!isOpen && !isModalVisible) return null;

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[300ms] ease-in-out ${
        isModalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className="fixed inset-0 bg-black opacity-10"
        onClick={onClose}
      ></div>
      <div
        className={`bg-white p-[24px] rounded-lg modal-shadow z-10 w-full max-w-[400px] relative transition-all ease-in-out duration-[150ms] ${
          isModalVisible ? "scale-100" : "scale-95"
        }`}
      >
        <IoClose
          className="text-[#667085] text-[20px] w-[24px] h-[24px] absolute right-[24px] top-[26px] cursor-pointer"
          onClick={() => {
            clearTimeout(timerId);
            onClose();
            setOpenConnectModalSteps(0);
            setLoader(false);
            clearInterval();
          }}
        />
        <div className="modal-scroll">
          <div className="modal-scroll">
            <div className="flex flex-col items-center justify-center font-inter mt-[50px]">
              <div
                className="flex justify-center items-center border-[#E4E7EC] border w-[48px] h-[48px] rounded-[10px] mb-[16px]"
                style={{
                  boxShadow:
                    "0px 4px 8px 0px rgba(16, 24, 40, 0.05), 0px 2px 0px 0px rgba(16, 24, 40, 0.05), 0px 0px 1px 0px rgba(16, 24, 40, 0.18)",
                }}
              >
                <img
                  src={connectIcon}
                  alt="coins"
                  className="h-[24px] w-[24px]"
                />
              </div>
              <h4 className="font-[600] text-[18px] text-[#101828] m-0 font-inter mb-[15px]">
                Connecting
              </h4>
              {/* <Loader /> */}
              <div className="h-[25px] w-full flex items-center justify-center">
                <div className="w-[20px] h-[20px] border-[3px] border-t-[3px] border-[#7F56D9] border-solid rounded-full animate-spin border-t-[#fff]" />
              </div>
            </div>
            <div className="flex items-center justify-center mt-[39px]">
              <Button
                title="Cancel"
                isWhiteBtn={true}
                onClick={() => {
                  clearTimeout(timerId);
                  onClose();
                  setOpenConnectModalSteps(0);
                  setLoader(false);
                }}
                className="!text-[16px] !h-[44px] !font-[600] !text-[#344054] y-axis-shadow !rounded-[8px]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectLoadingModal;
