import { IoClose } from "react-icons/io5";
import Button from "components/button";
import userIcon from "assets/images/save-list.svg";
import { FaMinus } from "react-icons/fa6";
import { FiPlus } from "react-icons/fi";
import { useEffect, useState } from "react";
import { formatNumberWithCommas } from "utils/index";

const AddTeamSeat = (props) => {
  const {
    isOpen,
    onClose,
    setSentInvitationModal,
    setTeamSeatsNumber,
    teamSeatsNumber,
    handleClear,
  } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleIncrement = () => {
    setTeamSeatsNumber((prevCount) => prevCount + 1);
  };

  const handleDecrement = () => {
    setTeamSeatsNumber((prevCount) => (prevCount > 1 ? prevCount - 1 : 1));
  };

  useEffect(() => {
    if (isOpen) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [isOpen]);

  if (!isOpen && !isModalVisible) return null;
  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[300ms] ease-in-out ${
        isModalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className="fixed inset-0 bg-black opacity-10"
        onClick={handleClear}
      ></div>
      <div
        className={`bg-white rounded-lg shadow-lg z-10 w-full max-w-[504px] relative transition-all ease-in-out duration-[150ms] ${
          isModalVisible ? "scale-100" : "scale-95"
        }`}
      >
        <IoClose
          className="text-[#667085] text-[20px] w-[24px] h-[24px] absolute right-[24px] top-[22px] cursor-pointer"
          onClick={() => {
            handleClear();
          }}
        />
        <div className="modal-scroll">
          <div className="p-[24px] flex items-center">
            <div
              className="flex justify-center items-center border-[#E4E7EC] border w-[48px] h-[48px] rounded-[10px] mr-[16px]"
              style={{
                boxShadow:
                  "0px 4px 8px 0px rgba(16, 24, 40, 0.05), 0px 2px 0px 0px rgba(16, 24, 40, 0.05), 0px 0px 1px 0px rgba(16, 24, 40, 0.18)",
              }}
            >
              <img
                src={userIcon}
                alt="coins"
                className="h-[22px] w-[22px] select-none	"
              />
            </div>
            <div>
              <h6 className="text-[#101828] font-[600] text-[18px] leading-[28px] select-none	">
                Add Team Members
              </h6>
              <p className="font-[400] text-[14px] text-[#475467] select-none	">
                Select how many seats you need.
              </p>
            </div>
          </div>
          <div className="px-[24px] border-y-[1px] border-[#E4E7EC]">
            {/* counter */}
            <div className="border-b-[1px] py-[20px] border-[#E4E7EC] flex items-center justify-center">
              <div
                className={`flex justify-center items-center border-[#E4E7EC] border w-[44px] h-[44px] rounded-[10px] ${
                  teamSeatsNumber === 1
                    ? "cursor-not-allowed"
                    : "cursor-pointer"
                } `}
                style={{
                  boxShadow:
                    "0px 4px 8px 0px rgba(16, 24, 40, 0.05), 0px 2px 0px 0px rgba(16, 24, 40, 0.05), 0px 0px 1px 0px rgba(16, 24, 40, 0.18)",
                }}
                onClick={handleDecrement}
              >
                <FaMinus
                  className={`w-[20px] h-[20px] text-[20px] ${
                    teamSeatsNumber === 1 ? "text-[#aeafb2]" : "text-[#344054]"
                  }`}
                />
              </div>
              <p className="text-[#101828] font-[600] text-[72px] select-none w-[150px] text-center">
                {teamSeatsNumber}
              </p>
              <div
                className="flex justify-center items-center border-[#E4E7EC] border w-[44px] h-[44px] rounded-[10px] cursor-pointer"
                style={{
                  boxShadow:
                    "0px 4px 8px 0px rgba(16, 24, 40, 0.05), 0px 2px 0px 0px rgba(16, 24, 40, 0.05), 0px 0px 1px 0px rgba(16, 24, 40, 0.18)",
                }}
                onClick={handleIncrement}
              >
                <FiPlus className="w-[20px] h-[20px] text-[20px] text-[#344054]" />
              </div>
            </div>
            <div className="flex items-center justify-between my-[12px] select-none">
              <p className="font-[600] text-[#101828] text-[16px] m-0 select-none	">
                Price per seat
              </p>
              <p className="text-[16px] font-[400] text-[#475467] leading-[20px] select-none	">
                $150
              </p>
            </div>
            <div className="flex items-center justify-between mb-[32px] select-none	">
              <p className="font-[600] text-[#101828] text-[16px] m-0 select-none	">
                Total Due Today
              </p>
              <p className="text-[16px] font-[400] text-[#475467] leading-[20px] select-none	">
                ${formatNumberWithCommas(teamSeatsNumber * 150)}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-center p-[24px]">
            <Button
              title="Continue"
              secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9] select-none	"
              className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow !rounded-[8px]"
              onClick={() => {
                onClose();
                setSentInvitationModal(true);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTeamSeat;
