import { IoClose } from "react-icons/io5";
import Button from "components/button";
import hubspotIcon from "assets/images/Hubspot.svg";
import {
  ConnectProvider,
  AmpersandProvider,
} from "@amp-labs/react";
import { useAuthStore, useSettingStore } from "stores";
import { useEffect, useState } from "react";
import { HUBSPOTAPIKEY, HUBSPOTPROJECTID } from "constant/hubspot";

let isLoaded = false;
const ConnectHubSpot = (props) => {
  const { isOpen, onClose, onConnectSuccess, connectionId, setConnectionId } =
    props;
  const { userData } = useSettingStore();
  const { companyId } = useAuthStore();
  console.log(userData, "userData");
  const [isConnect, setIsConnect] = useState(false);
  const [isModalVisible, setisModalVisible] = useState(false);
  const [disable, setDisable] = useState(false);
  const userId = userData?.user_id;
  const hubspotProjectId = HUBSPOTPROJECTID;
  // const hubspotProjectId= process.env.REACT_APP_HUBSPOT_PROJECT_ID
  const hubspotAPIKey = HUBSPOTAPIKEY;

  useEffect(() => {
    if (connectionId && !isLoaded) {
      isLoaded = true
      onConnectSuccess(connectionId);
    }
  }, [connectionId, isLoaded]);
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 font-inter">
      {!connectionId && isConnect && (
        <div className="opacity-1 absolute z-[9999999] bg-white p-[24px] rounded-lg shadow-lg w-full max-w-[400px]">
          <AmpersandProvider
            options={{
              projectId: hubspotProjectId,
              apiKey: hubspotAPIKey,
            }}
          >
            <ConnectProvider
              provider="hubspot"
              consumerRef={userId.toString()}
              consumerName={userData?.user?.name}
              groupRef={companyId.toString()}
              groupName={`Company ${userData?.company?.company_name}`}
              onSuccess={(e) => {
                setConnectionId(e);
                setIsConnect(false);
              }}
            />
          </AmpersandProvider>
        </div>
      )}

      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={onClose}
      ></div>
      <div
        className={`bg-white p-[24px] rounded-lg modal-shadow z-10 w-full max-w-[400px] relative transition-all ease-in-out duration-[150ms] ${
          isModalVisible ? "scale-100" : "scale-95"
        }`}
      >
        <IoClose
          className="text-[#667085] text-[20px] w-[24px] h-[24px] absolute right-[24px] top-[26px] cursor-pointer"
          onClick={onClose}
        />
        <div className="modal-scroll">
          <div className="modal-scroll">
            <div className="flex flex-col items-center justify-center mb-[20px] font-inter">
              <div className="flex justify-center items-center border-[#E4E7EC] w-[48px] h-[48px] rounded-[10px] mb-[16px]">
                <img
                  src={hubspotIcon}
                  alt="coins"
                  className="h-[48px] w-[48px]"
                />
              </div>
              <p className="font-[600] text-[18px] text-[#101828] m-0 font-inter">
                Hubspot{" "}
              </p>
              <p className="font-[400] text-[14px] text-[#475467] m-0 font-inter mt-1">
                Connect your Hubspot to Pipeline to Integrate.
              </p>
            </div>
            {/* form */}

            <div className="flex items-center justify-center mt-[15px]">
              <Button
                onClick={() => {
                  setIsConnect(true);
                  setDisable(true);
                }}
                title="Connect"
                secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9] ml-[12px]"
                className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow !rounded-[8px]"
                disabled={disable}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectHubSpot;
