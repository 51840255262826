import CommonCheckbox from "components/commonCheckbox";
import LinkedIn from "assets/images/linkedin-view-profile.svg";

const ViewProfile = () => {
  return (
    <>
      <div className="flex items-start  p-[14px] bg-[#F9F5FF] rounded-lg border-[2px] border-[#7F56D9] shadow-sm w-[373px]">
        <div className="flex items-start w-full">
          <img src={LinkedIn} alt="" className="w-[27px] h-[27px] ml-[-2px]" />
          <div className="ml-[10px]">
            <h2 className="font-inter text-[12px] font-[500] text-[#53389E]">
              View Profile
            </h2>
            <p className="font-inter text-[12px] font-[400] text-[#6941C6]">
              View your leads profile once.
            </p>
          </div>
        </div>
        <div className="mr-[16px]">
          <CommonCheckbox isRound={true} checked={true} />
        </div>
      </div>
    </>
  );
};

export default ViewProfile;
