import { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";

const Dropdown = ({ position, options, initialName, onSelect }) => {
  const [menu, setMenu] = useState(false);
  const [selectedOption, setSelectedOption] = useState(initialName);

  const handleOptionClick = (option) => {
    setSelectedOption(option.label);
    setMenu(false);
    onSelect(option);
  };

  return (
    <div className="relative">
      <button
        className="border-[1px] border-[#D1D5DB] rounded-[6px] flex items-center mx-[6px] bg-white w-[155px]"
        onClick={() => setMenu(!menu)}
      >
        <p className="text-[#111827] text-[12px] font-[600] m-0 capitalize py-[6px] px-[10px] border-r-[1px] border-[#D1D5DB] w-full">
          {selectedOption}
        </p>
        <div className="p-[8px] flex items-center justify-center w-[33px]">
          <IoIosArrowDown className="text-[#9CA3AF] h-[16px] w-[16px] text-[12px]" />
        </div>
      </button>
      {menu && (
        <>
          <div
            className="fixed top-0 left-0 w-full h-full z-50"
            onClick={() => setMenu(false)}
          />
          <div
            className={`w-[150px] bg-[#fff] border-[1px] border-[#D1D5DB] rounded-[6px] absolute actionButton-shadow z-50 mt-[8px] ${position} right-[6px]`}
          >
            <ul>
              {options.map((option, index) => (
                <li
                  key={index}
                  className={`flex items-center capitalize px-[16px] py-[8px] cursor-pointer text-[13px] font-[400] text-[#374151] hover:bg-[#cccccc1c] ${
                    selectedOption === option.label
                      ? "font-[500] text-[#111827]"
                      : "text-[#6B7280]"
                  }`}
                  onClick={() => handleOptionClick(option)}
                >
                  {option.label}
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default Dropdown;
