import { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";

const FilterDropdown = ({ position, options, initialName, onSelect }) => {
  const [menu, setMenu] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Default");

  return (
    <div className="relative">
      <button
        className="border-[1px] border-[#D1D5DB] rounded-[6px] flex items-center bg-white mx-[6px]"
        onClick={() => setMenu(!menu)}
      >
        <p className="text-[#111827] text-[12px] font-[600] m-0 capitalize py-[6px] px-[10px] border-r-[1px] border-[#D1D5DB] flex-grow">
          {initialName}
        </p>
        <div
          className={`p-[8px] flex items-center justify-center w-[33px] rounded-[0] ${
            menu && "bg-[#9A98981F]"
          }`}
        >
          <IoIosArrowDown className="text-[#9CA3AF] h-[16px] w-[16px] text-[12px]" />
        </div>
      </button>
      {menu && (
        <>
          <div
            className="fixed top-0 left-0 w-full h-full z-50"
            onClick={() => setMenu(false)}
          />
          <div
            className={`w-[160px] py-[3px] bg-[#fff] border-[1px] border-[#D1D5DB] rounded-[6px] absolute actionButton-shadow z-[1000] mt-[8px] ${position} right-[6px]`}
          >
            <ul>
              {options.map((optionGroup, index) => (
                <div key={index}>
                  {optionGroup.name !== "Default" && (
                    <li
                      className={`text-[#111827] text-[12px] font-[600] px-[16px] py-[8px] ${
                        optionGroup.name === "view"
                          ? "uppercase text-[8px] text-[#6B7280] font-[700] tracking-wide pb-0"
                          : "capitalize"
                      }`}
                    >
                      {optionGroup.name}
                    </li>
                  )}
                  {optionGroup.data.map((option, index) => (
                    <li
                      key={index}
                      className={`flex items-center capitalize px-[16px] py-[8px] cursor-pointer text-[13px] font-[400] hover:bg-[#cccccc1c] ${
                        selectedOption === option.label
                          ? "!font-[500] text-[#111827]"
                          : "text-[#6B7280]"
                      }`}
                      onClick={() => {
                        setSelectedOption(option.label);
                        setMenu(false);
                        onSelect(option);
                      }}
                    >
                      {option.label}
                    </li>
                  ))}
                </div>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default FilterDropdown;
