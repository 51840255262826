import CommonCheckbox from "components/commonCheckbox";
import LinkedIn from "assets/images/linkedin-view-profile.svg";
import InsertDropdown from "components/newCampaign/insertDropdown";
import { HiOutlineMail } from "react-icons/hi";
import { useEffect, useState } from "react";

export const insertDropdownOpt = [
  { campaign_name: "First Name", value: "first_name" },
  { campaign_name: "Last Name", value: "last_name" },
  { campaign_name: "Company", value: "company" },
  // { campaign_name: "Location", value: "location" },
  { campaign_name: "Role", value: "role" },
];

const FollowUpMessage = ({ value, handleStepValueChange, index }) => {
  // Initialize formData state with inputValue
  const [formData, setFormData] = useState({
    inputValue: value || "",
  });

  const [selectedFollowUpMessageOption, setSelectedFollowUpMessageOption] =
    useState(null);

  const handleTextAreaChange = (e) => {
    const input = e.target.value;
    // Update formData with the new inputValue
    setFormData({ inputValue: input });
    handleStepValueChange(input, index);
  };

  useEffect(() => {
    if (selectedFollowUpMessageOption) {
      const newFollowUpMessage = `${formData.inputValue} {{${selectedFollowUpMessageOption.value}}} `;
      setFormData((prev) => ({
        ...prev,
        inputValue: newFollowUpMessage,
      }));
      handleStepValueChange(newFollowUpMessage, index);
    }
  }, [selectedFollowUpMessageOption]);

  return (
    <div className="p-[14px] bg-[#F9F5FF] rounded-lg border-[2px] border-[#7F56D9] shadow-sm w-[373px]">
      <div className="flex items-start">
        <div className="flex items-start w-full">
          <img src={LinkedIn} alt="" className="w-[27px] h-[27px] ml-[-2px]" />
          <div className="ml-[10px]">
            <h2 className="font-inter text-[12px] font-[500] text-[#53389E]">
              Follow-Up Message
            </h2>
            <p className="font-inter text-[12px] font-[400] text-[#6941C6] m-0">
              Send a message if your lead accepts your connection request.
            </p>
          </div>
        </div>
        <div className="mr-[16px]">
          <CommonCheckbox isRound={true} checked={true} />
        </div>
      </div>
      <div className="pt-[12px]">
        <p className="font-inter font-[500] text-[10px] text-[#6941C6] mb-[5px]">
          Message
        </p>
        <div className="relative">
          <HiOutlineMail className="text-[#7F56D9] w-[16px] h-[16px] text-[14px] absolute top-[7px] left-[7px] z-10" />
          <textarea
            value={formData.inputValue}
            onChange={handleTextAreaChange}
            placeholder={`e.g Hey {{firstname}}, thanks for accepting my request. Want to hop on a call?`}
            className="relative pl-[30px] text-[10px] y-axis-shadow leading-[14px] w-full h-[75px] p-[7px] border-[#D0D5DD] rounded-[4px] bg-[#FFFFFF] text-[#667085] placeholder:text-[#667085] focus:!outline-none resize-none"
          ></textarea>
        </div>
        <div className="flex items-center justify-between">
          <InsertDropdown
            options={insertDropdownOpt}
            position="top"
            initialName={{
              campaign_name: "Insert Variable",
              value: "Insert Variable",
            }}
            setSelectedOption={(newOption) => {
              setSelectedFollowUpMessageOption(newOption);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default FollowUpMessage;
