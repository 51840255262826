import React, { useState } from "react";
import ReactSelect from "components/select";
import { Switch } from "@mui/material";
import hubspotIcon from "assets/images/Hubspot.svg";
import AddMore from "assets/images/add-more.svg";
import Button from "components/button";
import info from "assets/images/info.svg";
import arrow from "assets/images/arrow.png";
import { toast } from "react-toastify";
import Tick from "assets/images/name-check.svg";
import SwitchToggle from "components/switchToggle";
import Delete from "assets/images/delete.svg";
import { FaPlus } from "react-icons/fa6";
import { CONTACT_OPTIONS } from "constant";

const IntegrationSettings = ({ setIsSwitchOn, setShowIntegrationSettings }) => {
  const navItems = ["Settings", "Contact", "Company"];
  const [selectedItem, setSelectedItem] = useState(navItems[0]);
  const users = ["John Doe", "Jane Doe", "Kim Doe"];
  const hubspotUsers = ["Robert Smith", "Annie Smith", "John Smith"];
  const [pipelineUsers, setPipelineUsers] = useState(users);
  const [hubspotOwners, setHubspotOwners] = useState(hubspotUsers);
  const [lifecycleStages, setLifecycleStages] = useState(users);
  const pipleLineFieldUsers = ["Company Name", "Company Website"];
  const [pipeLineField, setPipeLineField] = useState(pipleLineFieldUsers);
  const hubSpotFieldUsers = ["Company Name", "Website URL"];
  const [hubSpotField, setHubSpotField] = useState(hubSpotFieldUsers);
  const [selects, setSelects] = useState([{ id: 1 }]);
  const [event, setEvent] = useState("");
  const contactPipleLineField = [
    "First Name",
    "Last Name",
    "Job Title",
    "Company",
    "Website",
    "LinkedIn URL",
  ];
  const contactHubSpotField = [
    "First Name",
    "Last Name",
    "Job Title",
    "Company",
    "Website",
    "LinkedIn URL",
  ];
  const [contactPipeLineIntegration, setContactPipeLineIntegration] = useState(
    contactPipleLineField
  );
  const [
    contactHubSpotPipeLineIntegration,
    setContactHubSpotPipeLineIntegration,
  ] = useState(contactHubSpotField);

  const eventList = ["Send Connection Request"];
  const lifeCycleStageList = ["Marketing Qualified Lead"];
  const leadStatusList = ["Engaged"];
  const [contactEvent, setContactEvent] = useState(eventList);
  const [contactLifeCycleStage, setContactLifeCycleStage] =
    useState(lifeCycleStageList);
  const [contactLifeCycleStageValue, setContactLifeCycleStageValue] = useState(
    []
  );
  const [contactLeadStatusValue, setContactLeadStatusValue] = useState(
    []
  );
  const [contactLeadStatus, setContactLeadStatus] = useState(leadStatusList);
  const handleSaveButton = () => {
    setIsSwitchOn(true);
    setShowIntegrationSettings(false);
    toast.success("Hubspot Integration Updated", {
      icon: () => <img src={Tick} alt="name" />,
    });
  };
  const addSelect = () => {
    setSelects([...selects, { id: selects[selects.length - 1]?.id + 1 }]);
  };
  const removeSelect = (index) => {
    const updatedSelects = selects.filter((_, i) => _?.id !== index);
    setSelects(updatedSelects);
  };

  const AddMoreEvent = () => {
    const filterOptions = CONTACT_OPTIONS?.filter((option) =>
      event ? ![event]?.includes(option) : !contactEvent?.includes(option)
    );
    setContactEvent([...contactEvent, ...filterOptions]);
    setContactLifeCycleStage([...contactLifeCycleStage, ...lifeCycleStageList]);
    setContactLeadStatus([...contactLeadStatus, ...leadStatusList]);
  };

  const removeMoreEvent = (index) => {
    const updatedContactEvent = contactEvent.filter((_, i) => i !== index);
    const updatedContactLifeCycleStage = contactLifeCycleStage.filter(
      (_, i) => i !== index
    );
    const updatedContactLifeCycleStageValue = contactLifeCycleStageValue?.filter(
      (_, i) => i !== index
    );
    const updatedcontactLeadStatusValue = contactLeadStatusValue?.filter(
      (_, i) => i !== index
    );
    const updatedContactLeadStatus = contactLeadStatus.filter(
      (_, i) => i !== index
    );
    setContactEvent(updatedContactEvent);
    setContactLifeCycleStage(updatedContactLifeCycleStage);
    setContactLeadStatus(updatedContactLeadStatus);
    setContactLifeCycleStageValue(updatedContactLifeCycleStageValue)
    setContactLeadStatusValue(updatedcontactLeadStatusValue)
  };

  return (
    <div className="pl-[54px] pr-[45px] campaign-toggle">
      <div className="pb-[21px] border-[#E4E7EC] mb-[6px]">
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-row justify-start items-center">
            <img
              src={hubspotIcon}
              alt="Hubspot"
              className="h-[24px] w-[24px]"
            />
            <h4 className="font-[600] text-[#101828] text-[18px] ml-[5px]">
              Hubspot
            </h4>
          </div>
          <div>
            <Button
              title="Save Changes"
              onClick={handleSaveButton}
              secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9]"
              className="!text-[12px] !h-[33.71px] !w-[111px] !font-[600] y-axis-shadow !rounded-[8px]"
            />
          </div>
        </div>
        <p className="font-[400] text-[#475467] text-[14px] mt-[5px]">
          Configure and map your Hubspot to Pipeline.
        </p>
      </div>

      <div>
        <div className="w-[366px] h-[40px] border border-[#D0D5DD] rounded-[8px] flex overflow-hidden">
          {navItems.map((item, index) => (
            <div
              key={index}
              className={`flex-1 flex items-center ${
                selectedItem === item && "bg-[#F9FAFB]"
              }`}
              onClick={() => setSelectedItem(item)}
            >
              <div className="flex items-center flex-1 justify-center">
                {selectedItem === item && (
                  <div
                    className={`w-[8px] h-[8px] rounded-full bg-[#17B26A] mr-2 ${
                      selectedItem === item ? "opacity-100" : "opacity-0"
                    } transition-opacity duration-300`}
                  ></div>
                )}
                <p className="text-center text-[14px] font-[600] font-inter cursor-pointer text-[#182230] mt-[-2px]">
                  {item}
                </p>
              </div>
              {index < navItems.length - 1 && (
                <div className="border-r border-[#D0D5DD] h-full"></div>
              )}
            </div>
          ))}
        </div>
      </div>
      {selectedItem === "Settings" && (
        <div className="mt-[33px]">
          <div className="flex flex-row justify-start items-center ml-[12px]">
            <SwitchToggle />
            <h4 className="font-[600] text-[#101828] text-[18px] ml-[15px]">
              Blocklist
            </h4>
          </div>
          <p className="font-[400] text-[#475467] text-[14px] mt-[10px] ml-[12px]">
            Leads from the Lifecycle stage you select below will be added to
            Pipeline’s blocklist:
          </p>
          <div className="my-[20px] flex hubspot-select newCampaign-select items-end">
            <div className="flex flex-col">
              {selects.map((select, index, arr) => (
                <div key={select.id} className="flex items-center">
                  <div
                    className={`${
                      arr?.length - 1 > index && "mb-[20px]"
                    } w-[374px] h-[44px] ml-[12px]`}
                  >
                    <ReactSelect
                      options={[
                        {
                          label: "Lifecycle Stage",
                          value: "Lifecycle Stage",
                        },
                      ]}
                      placeholder="Lifecycle Stage"
                      onChange={(event) => console.log(event, "event")}
                    />
                  </div>
                  {arr?.length - 1 !== index ? (
                    <img
                      src={Delete}
                      alt=""
                      className="w-[20px] h-[20px] ml-[20px] mb-[20px] cursor-pointer"
                      onClick={() => removeSelect(select?.id)}
                    />
                  ) : (
                    <div
                      className="relative flex items-center mx-[20px] cursor-pointer"
                      onClick={addSelect}
                    >
                      <FaPlus className="mt-[-2px] text-[#808897] text-[15px]" />
                      <p className="ml-[3px] text-[#808897] text-[13.7px] font-[500] mb-0">
                        Add More
                      </p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="flex justify-start items-center ml-2">
            <img
              src={info}
              alt="info"
              className="mb-[30px] mr-[3px] w-[14px] h-[14px]"
            />
            <p className="w-[400px] h-[59px] font-[400] text-[#808897] text-[14px] mt-[7px] font-inter">
              This is to make sure you do not enroll leads into a campaign that
              are already your customers or in a current deal.
            </p>
          </div>
          <div className="flex flex-row justify-start items-center mt-[26px] ml-[12px]">
            <SwitchToggle />
            <h4 className="font-[600] text-[#101828] text-[18px] ml-[15px]">
              Account Owners
            </h4>
          </div>
          <p className="font-[400] text-[#475467] text-[14px] mt-[10px] ml-[12px]">
            Map Pipeline users to Account Owners in Hubspot
          </p>
          <div className="flex flex-row w-[900px] justify-between items-center">
            <div className="flex-1">
              <p className="font-[400] text-[#666D80] text-[14px] mt-[20px] ml-[12px]">
                Pipeline User
              </p>
              {pipelineUsers.map((user, index) => (
                <div className="relative">
                  <div
                    className="w-[374px] h-[44px] my-[20px] flex hubspot-select newCampaign-select ml-3"
                    key={index}
                  >
                    <ReactSelect
                      options={[
                        {
                          label: user,
                          value: user,
                        },
                      ]}
                      placeholder={user}
                      onChange={(event) => console.log(event, "event")}
                    />
                  </div>
                  <div className="absolute inset-y-0 right-0 transform top-0 flex items-center">
                    <img
                      src={arrow}
                      alt="arrow"
                      className="w-[12px] ml-[33px]"
                    />
                  </div>
                </div>
              ))}
            </div>

            <div className="flex-1 ml-[28px]">
              <p className="font-[400] text-[#666D80] text-[14px] mt-[20px] ml-[12px]">
                Hubspot Account Owner
              </p>
              {hubspotOwners.map((owner, index) => (
                <div
                  className="w-[374px] h-[44px] my-[20px] flex hubspot-select newCampaign-select ml-3"
                  key={index}
                >
                  <ReactSelect
                    options={[
                      {
                        label: owner,
                        value: owner,
                      },
                    ]}
                    placeholder={owner}
                    onChange={(event) => console.log(event, "event")}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {selectedItem === "Contact" && (
        <div className="mt-[33px] contactPage">
          <div className="flex flex-row justify-start items-center ml-[12px]">
            <SwitchToggle />
            <h4 className="font-[600] text-[#101828] text-[18px] ml-[5px]">
              Create New Contact
            </h4>
          </div>
          <p className="font-[400] text-[#475467] text-[14px] mt-[10px] ml-[12px]">
            Pipeline will create a new contact when the following events occur
            for a lead.{" "}
          </p>

          <div className="flex justify-start items-center ml-3 mt-1">
            <img
              src={info}
              alt="info"
              className="mt-[4px] mr-[3px] w-[14px] h-[16px]"
            />
            <p className="font-[400] text-[#808897] text-[14px] mt-[7px] font-inter">
              The Pipeline user that reaches out to a contact will be assigned
              as the account owner.
            </p>
          </div>
          <div className="flex flex-row w-full items-center">
            <div className="">
              <p className="font-[400] text-[#666D80] text-[14px] mt-[22px] ml-[12px]">
                Event{" "}
              </p>
              {contactEvent.map((user, index) => (
                <div className="relative w-[435px]">
                  <div
                    className="w-[374px] h-[44px] my-[20px] flex hubspot-select newCampaign-select ml-3"
                    key={index}
                  >
                    <ReactSelect
                      options={CONTACT_OPTIONS.map((u) => ({
                        label: u,
                        value: u,
                      }))}
                      placeholder={user}
                      onChange={(event) => {
                        const updatedContactEvent = [...contactEvent]; // Create a shallow copy of the contactEvent array
                        updatedContactEvent[index] = event?.value; // Update the string at the specified index
                        setContactEvent(updatedContactEvent); // Update the state with the new array
                      }}
                      value={{ label: user, value: user }}
                    />
                  </div>
                  <div className="absolute inset-y-0 right-[0px] transform top-0 flex items-center">
                    <img
                      src={arrow}
                      alt="arrow"
                      className="w-[12px] ml-[33px]"
                    />
                  </div>
                </div>
              ))}
            </div>

            <div className="ml-[31px]">
              <p className="font-[400] text-[#666D80] text-[14px] mt-[22px] ml-[12px]">
                Lifecycle Stage{" "}
              </p>
              {contactLifeCycleStage.map((user, index) => (
                <div className="relative w-[450px]">
                  <div
                    className="w-[374px] h-[44px] my-[20px] flex hubspot-select newCampaign-select ml-3"
                    key={index}
                  >
                    <ReactSelect
                      options={lifeCycleStageList?.map((u) => ({
                        label: u,
                        value: u,
                      }))}
                      placeholder={"Select a Stage"}
                      onChange={(event) => {
                        const updatedContactEvent = [
                          ...contactLeadStatusValue,
                        ]; // Create a shallow copy of the contactEvent array
                        updatedContactEvent[index] = event?.value; // Update the string at the specified index
                        setContactLeadStatusValue(updatedContactEvent); // Update the state with the new array
                      }}
                      value={
                        contactLeadStatusValue?.[index]?.length > 0
                          ? {
                              label: contactLeadStatusValue?.[index],
                              value: contactLeadStatusValue?.[index],
                            }
                          : ""
                      }
                    />
                  </div>
                  <div className="absolute inset-y-0 right-[15px] transform top-0 flex items-center">
                    <img
                      src={arrow}
                      alt="arrow"
                      className="w-[12px] ml-[33px]"
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="ml-[12px]">
              <p className="font-[400] text-[#666D80] text-[14px] mt-[22px] ml-[12px]">
                Lead Status{" "}
              </p>
              {contactLeadStatus.map((user, index) => (
                <div className="relative">
                  <div
                    className="w-[374px] h-[44px] my-[20px] flex hubspot-select newCampaign-select ml-3"
                    key={index}
                  >
                    <ReactSelect
                      options={leadStatusList?.map((u) => ({
                        label: u,
                        value: u,
                      }))}
                      placeholder={"Select a Status"}
                      onChange={(event) => {
                        const updatedContactEvent = [
                          ...contactLifeCycleStageValue,
                        ]; // Create a shallow copy of the contactEvent array
                        updatedContactEvent[index] = event?.value; // Update the string at the specified index
                        setContactLifeCycleStageValue(updatedContactEvent); // Update the state with the new array
                      }}
                      value={
                        contactLifeCycleStageValue?.[index]?.length > 0
                          ? {
                              label: contactLifeCycleStageValue?.[index],
                              value: contactLifeCycleStageValue?.[index],
                            }
                          : ""
                      }
                    />
                  </div>
                  {/* <div className="absolute inset-y-0 right-0 transform top-0 flex items-center">
                    <img
                      src={arrow}
                      alt="arrow"
                      className="w-[12px] ml-[33px]"
                    />
                  </div> */}
                  {contactLeadStatus?.length - 1 !== index && (
                    <img
                      src={Delete}
                      alt=""
                      className="w-[20px] h-[20px] ml-[20px] mb-[20px] cursor-pointer absolute right-[-30px] top-[10px]"
                      onClick={() => removeMoreEvent(index)}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
          {contactEvent?.length < 2 && (
            <div
              className="relative flex items-center justify-end mx-[20px] cursor-pointer w-[120px] ml-auto pr-[10px]"
              onClick={AddMoreEvent}
            >
              <FaPlus className="mt-[-2px] text-[#808897] text-[15px]" />
              <p className="ml-[3px] text-[#808897] text-[13.7px] font-[500] mb-0">
                Add More
              </p>
            </div>
          )}
          <div className="flex flex-row justify-start items-center mt-8 ml-[12px]">
            <SwitchToggle />
            <h4 className="font-[600] text-[#101828] text-[18px] ml-[5px]">
              Contact Field Mapping{" "}
            </h4>
          </div>
          <p className="font-[400] text-[#475467] text-[14px] mt-[10px] ml-[12px]">
            Map the fields below to configure how companies are synced{" "}
          </p>
          <div className="flex justify-start items-center ml-3 mt-1">
            <img
              src={info}
              alt="info"
              className="mt-[4px] mr-[3px] w-[14px] h-[14px]"
            />
            <p className="font-[400] text-[#808897] text-[14px] mt-[7px] font-inter">
              The Pipeline user that reaches out to a contact will be assigned
              as the account owner.
            </p>
          </div>
          <div className="flex flex-row w-[900px] justify-between items-center">
            <div className="flex-1">
              <p className="font-[400] text-[#666D80] text-[14px] mt-[22px] ml-[12px]">
                Pipeline Field{" "}
              </p>
              {contactPipeLineIntegration.map((user, index) => (
                <div className="relative">
                  <div
                    className="w-[374px] h-[44px] my-[20px] flex hubspot-select newCampaign-select ml-3"
                    key={index}
                  >
                    <ReactSelect
                      options={[
                        {
                          label: user,
                          value: user,
                        },
                      ]}
                      placeholder={user}
                      onChange={(event) => console.log(event, "event")}
                    />
                  </div>
                  <div className="absolute inset-y-0 right-0 transform top-0 flex items-center">
                    <img
                      src={arrow}
                      alt="arrow"
                      className="w-[12px] ml-[33px]"
                    />
                  </div>
                </div>
              ))}
            </div>

            <div className="flex-1 ml-[28px]">
              <p className="font-[400] text-[#666D80] text-[14px] mt-[22px] ml-[12px]">
                Hubspot Field{" "}
              </p>
              {contactHubSpotPipeLineIntegration.map((owner, index) => (
                <div
                  className="w-[374px] h-[44px] my-[20px] flex hubspot-select newCampaign-select ml-3"
                  key={index}
                >
                  <ReactSelect
                    options={[
                      {
                        label: owner,
                        value: owner,
                      },
                    ]}
                    placeholder={owner}
                    onChange={(event) => console.log(event, "event")}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {selectedItem === "Company" && (
        <div className="mt-[33px]">
          <div className="flex flex-row justify-start items-center ml-[12px]">
            <SwitchToggle />
            <h4 className="font-[600] text-[#101828] text-[18px] ml-[5px]">
              Create New Company
            </h4>
          </div>
          <p className="font-[400] text-[#475467] text-[14px] mt-[10px] ml-[12px]">
            Pipeline will create a new company when a Contact is created
          </p>

          <div className="flex justify-start items-center ml-3 mt-1">
            <img
              src={info}
              alt="info"
              className="mt-[4px] mr-[3px] w-[14px] h-[14px]"
            />
            <p className="font-[400] text-[#808897] text-[14px] mt-[7px] font-inter">
              If the company already exists, the new contact will be added under
              the existing Company in Hubspot.
            </p>
          </div>
          <div className="flex flex-row justify-start items-center mt-8 ml-[12px]">
            <SwitchToggle />
            <h4 className="font-[600] text-[#101828] text-[18px] ml-[5px]">
              Company Field Mapping{" "}
            </h4>
          </div>
          <p className="font-[400] text-[#475467] text-[14px] mt-[10px] ml-[12px]">
            Map the fields below to configure how companies are synced{" "}
          </p>
          <div className="flex flex-row w-[900px] justify-between items-center">
            <div className="flex-1">
              <p className="font-[400] text-[#666D80] text-[14px] mt-[23px] ml-[12px]">
                Pipeline Field{" "}
              </p>
              {pipeLineField.map((user, index) => (
                <div className="relative">
                  <div
                    className="w-[374px] h-[44px] my-[20px] flex hubspot-select newCampaign-select ml-3"
                    key={index}
                  >
                    <ReactSelect
                      options={[
                        {
                          label: user,
                          value: user,
                        },
                      ]}
                      placeholder={user}
                      onChange={(event) => console.log(event, "event")}
                    />
                  </div>
                  <div className="absolute inset-y-0 right-0 transform top-0 flex items-center">
                    <img
                      src={arrow}
                      alt="arrow"
                      className="w-[12px] ml-[33px]"
                    />
                  </div>
                </div>
              ))}
            </div>

            <div className="flex-1 ml-[28px]">
              <p className="font-[400] text-[#666D80] text-[14px] mt-[23px] ml-[12px]">
                Hubspot Field{" "}
              </p>
              {hubSpotField.map((owner, index) => (
                <div
                  className="w-[374px] h-[44px] my-[20px] flex hubspot-select newCampaign-select ml-3"
                  key={index}
                >
                  <ReactSelect
                    options={[
                      {
                        label: owner,
                        value: owner,
                      },
                    ]}
                    placeholder={owner}
                    onChange={(event) => console.log(event, "event")}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default IntegrationSettings;
