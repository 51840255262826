import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.css";
import calendarIcon from "assets/images/calendarIcon.png"

const DateRangePicker = ({ value, onChange, closeCalendar }) => {
  const [startDate, setStartDate] = useState(value[0]);
  const [endDate, setEndDate] = useState(value[1]);

  return (
    <div className="flex items-center custom-datepicker">
      <div className="flex items-center">
      <img src={calendarIcon} alt="calendar" className="mr-[10px] w-[22px] h-[22px] min-h-[22px] min-w-[22px]"/>
        <div className="start-date w-[150px]">
          <DatePicker
            selected={startDate}
            onChange={(date) => {
              setStartDate(date);
              onChange([date, value[1]]);
            }}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            className="w-full text-black px-4 py-2 rounded-lg border border-[#E4E7EC] focus:border-[#6941c6]"
            placeholderText="MM/DD/YYYY"
            dateFormat="MM/dd/yyyy"
            popperPlacement="bottom-start"
          />
        </div>
        <p className="w-[40px] text-center min-w-[40px]">to</p>
        <div className="end-date w-[150px]">
          <DatePicker
            selected={endDate}
            onChange={(date) => {
              setEndDate(date);
              onChange([value[0], date]);
            }}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            className="w-full text-black px-4 py-2 rounded-lg border border-[#E4E7EC] focus:border-[#6941c6]"
            placeholderText="MM/DD/YYYY"
            dateFormat="MM/dd/yyyy"
            popperPlacement="bottom-end"
          />
        </div>
      </div>
    </div>
  );
};

export default DateRangePicker;
