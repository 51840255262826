import { country_table } from "constant";
import arrowDown from "assets/images/arrow-down.svg";
import men from "assets/images/addMember3.svg";
import flag from "assets/images/us.svg";
import CompanyLogo from "assets/images/command.svg";
import { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useAccountStore } from "stores";
import { getNameInititals } from "utils/index";
import Avatar from "assets/images/manFace.jpg";
import verified from "assets/images/verified.svg";
const CompanyTable = ({
  onRowClick,
  selectedRow,
  data,
  totalItems,
  setSortOrder,
  setSortBy,
  sortOrder,
  sortBy,
  getRandomCompanyLogo,
  onRefetch,
  hasMore,
  setPaginationData,
}) => {
  const tableRef = useRef();
  const { linkedinAccountDetails } = useAccountStore();
  const tableHeader = [
    { title: "Company", key: "company" },
    { title: "HQ Location", key: "location" },
    { title: "Lead", key: "full_name" },
  ];
  const [companyLogoMap, setCompanyLogoMap] = useState({});

  const handleRowClick = (item) => {
    onRowClick(item);
    selectedRow(item);
  };

  const handleSort = (columnKey) => {
    const isAsc = sortBy === columnKey && sortOrder === "asc";
    setSortBy(columnKey);
    setSortOrder(isAsc ? "desc" : "asc");
    setPaginationData({ limit: 20, page: 1 });
  };

  useEffect(() => {
    if (tableRef?.current) {
      tableRef.current.scrollTop = 0;
    }
  }, [sortBy, sortOrder]);

  const getCompanyLogo = (companyName) => {
    if (companyLogoMap[companyName]) {
      return companyLogoMap[companyName];
    }
    const randomLogo = getRandomCompanyLogo?.()?.src || CompanyLogo;
    setCompanyLogoMap((prevMap) => ({
      ...prevMap,
      [companyName]: randomLogo,
    }));

    return randomLogo;
  };

  const fetchData = () => {
    setTimeout(() => {
      onRefetch();
    }, 500);
  };

  return (
    <div className="font-inter border border-[#E4E7EC] rounded-[11.3px]">
      <div
        className={`overflow-auto round-table-scroll ${
          linkedinAccountDetails?.reconnect_status
            ? "max-h-[calc(100vh_-235px)]"
            : "max-h-[calc(100vh_-170px)]"
        }`}
        id="scrollableDiv"
        ref={tableRef}
      >
        <InfiniteScroll
          dataLength={data.length || 0}
          next={fetchData}
          hasMore={data?.length < totalItems && hasMore}
          loader={
            totalItems !== data.length && (
              <div className="h-[50px] w-full flex items-center justify-center">
                <div className="w-[20px] h-[20px] border-[3px] border-t-[3px] border-[#7F56D9] border-solid rounded-full animate-spin border-t-[#fff]" />
              </div>
            )
          }
          scrollableTarget="scrollableDiv"
          style={{ height: "100%", overflow: "unset" }}
        >
          {" "}
          <table className="min-w-full h-unset common-table-shadow rounded-xl">
            <thead className="w-full bg-white sticky top-0 !z-[100] overflow-hidden rounded-[12px]">
              <tr className="w-full">
                {tableHeader.map((header, index) => {
                  const isLastIndex = index === tableHeader.length - 1;
                  return (
                    <th
                      key={index}
                      className={`text-[12px] font-[500] py-[12px] px-[22px] text-[#475467] capitalize text-left font-inter ${
                        isLastIndex ? "w-[200px] rounded-tr-[12px]" : ""
                      } ${index === 0 && "rounded-tl-[12px]"}`}
                      onClick={() => header.key && handleSort(header.key)}
                    >
                      {header?.title}
                      <img
                        src={arrowDown}
                        alt="arrowDown"
                        className={`ml-[4px] w-[10px] h-[10px] inline ${
                          sortBy === header.key && sortOrder === "desc"
                            ? "rotate-180"
                            : ""
                        }`}
                      />
                      {header?.title === "Company" && (
                        <div className="font-[500] text-[12px] rounded-[16px] border-[1px] ml-[6px] border-[#E9D7FE] py-[2px] px-[7px] bg-[#F9F5FF] inline text-[#6941C6]">
                          {data?.length || 0}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
              <span className="w-full border-b border-[#E4E7EC] h-[1px] absolute top-full z-10 left-0" />
            </thead>
            <tbody>
              {data?.map((item, rowIndex) => {
                const isTdLastIndex = rowIndex === data.length - 1;
                const logo = getCompanyLogo(item?.company);

                return (
                  <tr
                    className={`group border-b hover:bg-gray-50 cursor-pointer ${
                      isTdLastIndex ? "border-transparent" : "border-[#E4E7EC]"
                    }`}
                    key={rowIndex}
                    onClick={() => {
                      if (item?.sample_data) {
                        return;
                      }
                      handleRowClick(item);
                    }}
                  >
                    <td
                      className={`${
                        isTdLastIndex && "first:rounded-bl-[12px]"
                      } text-[13px] font-normal px-[22px] py-[10px] text-[#252C32] text-left w-[500px] max-[1300px]:w-[unset]`}
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center w-[70%]">
                          <div className="w-[40px] min-w-[40px] h-[40px] mr-[12px]">
                            <img
                              src={item?.company_logo || logo || CompanyLogo}
                              alt="lead"
                              className="w-full h-full"
                            />
                          </div>
                          <div>
                            <p className="font-[500] text-[#101828] text-[14px] m-0 leading-[20px]">
                              {item?.company}
                            </p>
                            <p
                              onClick={() =>
                                window.open(
                                  "https://www." + item?.company_domain
                                )
                              }
                              className="font-[400] cursor-pointer text-[#475467] text-[14px] m-0 leading-[17px]"
                            >
                              {item?.company_domain}
                            </p>
                          </div>
                        </div>
                        <div className="mx-auto flex items-center">
                          {item?.images?.map((image, index) => (
                            <div className="relative inline-block" key={index}>
                              <img
                                src={image.src}
                                alt="user images"
                                className={`mr-[5px] w-[20px] h-[20px] cursor-pointer ${
                                  image?.src[2] &&
                                  index === 1 &&
                                  "w-[22px] h-[22px]"
                                }`}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </td>
                    <td className="px-[22px] py-[10px] text-left  w-[300px] max-[1300px]:w-[unset]">
                      <div className="flex items-center">
                        {item?.locationIcon && (
                          <img
                            src={item?.locationIcon || flag}
                            alt="locationIcon"
                            className="w-[24px] h-[24px] mr-[12px]"
                          />
                        )}
                        <p className="text-[#475467] font-normal text-[14px]">
                          {item?.location}
                        </p>
                      </div>
                    </td>
                    <td
                      className={`${
                        isTdLastIndex && "first:rounded-bl-[12px]"
                      } text-[14px] px-[22px] py-[10px] text-[#101828] text-left  w-[400px] max-[1300px]:w-[unset]`}
                    >
                      <div className="flex items-center">
                        <div className="w-[40px] min-w-[40px] h-[40px] mr-[12px] relative">
                          {item?.profile_pic ? (
                            <img
                              src={item?.profile_pic || Avatar}
                              alt="lead"
                              className="w-full h-full rounded-[25px] object-cover object-top"
                            />
                          ) : (
                            <div className="w-[40px] min-w-[40px] h-[40px] rounded-full flex items-center justify-center text-[14px] font-[700] text-[#4409B9] bg-[#EEEDFD]">
                              {getNameInititals(item?.full_name)}
                            </div>
                          )}
                          <img
                            src={verified}
                            alt="verified"
                            className="absolute bottom-0 right-0 w-[14px] h-[14px]"
                          />
                        </div>
                        <div>
                          <p className="font-[500] [text-[#101828] text-[14px] m-0 leading-[20px]">
                            {item?.full_name}
                          </p>
                          <p className="font-[400] text-[#475467] text-[14px] m-0 leading-[17px]">
                            {item?.title}
                          </p>
                        </div>
                        <div>
                          <p className="font-[500] text-[#101828] text-[14px] m-0 leading-[17px]">
                            {item?.name}
                          </p>
                          <p className="font-[400] text-[#475467] text-[14px] m-0 leading-[17px]">
                            {item?.jobTitle}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default CompanyTable;
