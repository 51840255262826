import { Outlet, Navigate } from "react-router-dom";
import { getAccessToken, getPaymentVerify } from "utils/token-service";

const isAuthenticated = () => {
  return !!getAccessToken() && !!getPaymentVerify();;
};

const ProtectedLayout = () => {
  if (!isAuthenticated()) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

export default ProtectedLayout;
