import { useState } from "react";
import Button from "components/button";
import creditIcon from "assets/images/credit-card-refresh.svg";
import CommonCheckbox from "components/commonCheckbox";
import { uploadersEncrichmentData } from "constant";

const ChooseEnrichmentStep = ({ setStep }) => {
  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleCheckboxChange = (index) => {
    setSelectedIndex(index);
  };

  return (
    <>
      <div className="flex flex-col items-start justify-start mb-[20px] font-inter">
        <div className="flex items-center justify-center border-[1px] border-[#E4E7EC] rounded-[10px] p-[11px] mb-[16px] y-axis-shadow">
          <img src={creditIcon} alt="info img" className="h-[24px] w-[24px]" />
        </div>
        <h4 className="font-[600] text-[18px] text-[#101828] m-0 font-inter">
          Choose your Enrichment
        </h4>
        <p className="font-normal text-[14px] text-[#475467] font-inter">
          Select how you’d like to enrich your data.
        </p>
      </div>
      {uploadersEncrichmentData?.map((item, index) => (
        <div
          key={index}
          className={`flex justify-between border-[2px] cursor-pointer border-[#E4E7EC] rounded-[12px] p-[16px] mb-[12px] transition-all duration-100 ease-in ${selectedIndex === index && "bg-[#F9F5FF] !border-[#7F56D9]"
            }`}
          onClick={() => handleCheckboxChange(index)}
        >
          <div
            className={`w-[90%] flex ${item.subTitle ? "items-start" : "items-center"
              }`}
          >
            <img
              src={item.icons}
              alt="info img"
              className="min-w-[32px] w-[32px] h-[32px] mr-[16px]"
            />
            <div>
              <h6
                className={`font-inter font-[500] text-[14px] leading-[20px] text-[#344054] m-0 ${selectedIndex === index && "text-[#53389E]"
                  }`}
              >
                {item.title}
              </h6>
              {item.subTitle && (
                <p
                  className={`font-inter font-normal text-[14px] leading-[20px] text-[#475467] m-0 ${selectedIndex === index && "text-[#53389E]"
                    }`}
                >
                  {item.subTitle}
                </p>
              )}
            </div>
          </div>
          <div className={`mr-[16px] ${item.subTitle ? "mt-0" : "mt-[7px]"}`}>
            <CommonCheckbox
              isRound={true}
              checked={selectedIndex === index}
              onChange={() => handleCheckboxChange(index)}
            />
          </div>
        </div>
      ))}
      <div className="flex items-center justify-center mt-[32px]">
        <Button
          title="Back"
          isWhiteBtn={true}
          isRounded="6px"
          onClick={() => setStep("job-selection")}
          className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow !text-[#344054]"
        />
        <Button
          title="Confirm"
          secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9] ml-[12px]"
          className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow"
          isRounded="6px"
          onClick={() => {
            setStep("add-team");
          }}
        />
      </div>
    </>
  );
};

export default ChooseEnrichmentStep;
