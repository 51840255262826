import Button from "components/button";
import Input from "components/textInput";
import creditCardRefreshIcon from "assets/images/light-stars.svg";
import zapIcon from "assets/images/zap.svg";
import checkbox from "assets/images/checkbox-base.svg";
import towLayersIcon from "assets/images/layers-two.svg";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AppContext } from "context/AppContext";
import nameCheck from "assets/images/name-check.svg";
import Circle from "assets/images/gola.svg";
import enrichedIcon from "assets/images/enriched-toaster-icon.svg";
import {
  useCompanyEnrichLeads,
  useEnrichLeadsFromCopyPaste,
  useGetEnrichLeads,
  useManualCompanyEnrichLeads,
} from "services/prospecting-api";
import {
  useAccountStore,
  useAuthStore,
  useCampaignStore,
  useProspectStore,
} from "stores";
import EnrichLeadsLoadingStep from "../enrichLeadsLoading";
import { useCheckLeadStatus } from "services/campaigns-api";
let loader = false;

const EnrichLeadsStep = ({
  setStep,
  enrichType,
  totalDataCsv,
  setMaxProfile,
  setProspectId,
  leadsValue,
  setLeadsValue,
  prospectId,
  step,
}) => {
  const { appState, setAppState } = useContext(AppContext);
  const { companyId } = useAuthStore();
  const { setTrackerId } = useCampaignStore();
  const { fileId } = useProspectStore();
  const enrichLeadsAPI = useGetEnrichLeads();
  const { workspaceCreditLimit } = useAccountStore();
  const enrichLeadsUsingCopyPaste = useEnrichLeadsFromCopyPaste();
  const enrichLeadsCompanyAPI = useCompanyEnrichLeads();
  const enrichLeads = useManualCompanyEnrichLeads();
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isEnrichedAPIDone, setIsEnrichedApiDone] = useState(false);
  const onInputChange = (event) => {
    let { value } = event?.target;
    value = value.replace(/^0+(?=\d)/, "").replace(/\D/g, "");
    setLeadsValue(value);
  };

  let interval;

  useEffect(() => {
    if (
      step === "enrich-leads-step" &&
      appState?.importMethodClicked === "viaLinkedin"
    ) {
      if (Number(leadsValue) > Number(totalDataCsv)) {
        setErrorMessage(`You don't have enough leads.`);
      } else if (Number(leadsValue) > Number(workspaceCreditLimit)) {
        setErrorMessage("You don't have enough credits.");
      } else {
        setErrorMessage("");
      }
    } else {
      if (Number(leadsValue) > 100) {
        setErrorMessage(
          "You can enrich up to 100 leads from an imported list. Please enter a lower value!"
        );
      } else if (Number(leadsValue) > Number(workspaceCreditLimit)) {
        setErrorMessage("You don't have enough credits.");
      } else if (Number(leadsValue) > totalDataCsv) {
        setErrorMessage(`Only ${totalDataCsv} leads found!`);
      } else {
        setErrorMessage("");
      }
    }
  }, [leadsValue]);

  useEffect(() => {
    if (!loader) {
      loader = true;
      toast.success(
        <div className="flex items-start">
          <img
            src={Circle}
            alt="Unarchive"
            className="w-[38px] h-[38px] mr-[8px]"
          />
          <div className="mt-[4px]">
            <p className="text-[#101828] text-[14px] font-[600]">
              Why do I need to enrich my leads?
            </p>
            <p className="font-[400] text-[#344054] text-[14px] leading-[20px]">
              {appState.importMethodClicked === "uploadPeopleCsv" && (
                <>
                  This is to ensure you are enrolling leads into campaigns with
                  the most up-to-date information.
                </>
              )}
              {appState.importMethodClicked === "uploadCsvUrl" && (
                <>
                  Pipeline will enrich the leads to find the most up to date
                  info and LinkedIn URLs.
                </>
              )}
              {appState.importMethodClicked === "uploadCsvEmail" && (
                <>
                  Pipeline will find LinkedIn URLs from your email data in order
                  to enroll leads into a campaign.
                </>
              )}
              {!(
                appState.importMethodClicked === "uploadPeopleCsv" ||
                appState.importMethodClicked === "uploadCsvUrl" ||
                appState.importMethodClicked === "uploadCsvEmail"
              ) && (
                <>
                  This is to ensure you are enrolling leads into campaigns with
                  the most up-to-date information.
                </>
              )}
            </p>
          </div>
        </div>,
        {
          position: "top-right",
        }
      );
    }
  }, [loader]);

  useEffect(() => {
    return () => {
      loader = false;
    };
  }, []);

  const handleEnrichLeads = async () => {
    if (
      appState?.importMethodClicked === "manually-default" ||
      appState?.importMethodClicked === "manuallyCompanyUrl"
    ) {
      setStep("save-list-step");
      setAppState((prev) => ({
        ...prev,
        totalLinkedinURL: "",
      }));
      return;
    }
    const maxProfile =
      enrichType === "Peoples" ? totalDataCsv : Number(leadsValue) || 1;
    try {
      setLoading(true);
      let response;
      if (enrichType === "Peoples") {
        response = await enrichLeadsAPI?.mutateAsync({
          max_profiles: maxProfile,
          file_id: fileId,
          company_id: companyId,
          tracker_id: null,
        });
      } else {
        response = await enrichLeadsCompanyAPI?.mutateAsync({
          max_profiles: maxProfile,
          file_id: fileId,
          job_title: appState?.job_title,
          tracker_id: null,
        });
      }

      if (response?.status === 200) {
        setProspectId(response?.prospect_id);
        setTrackerId(response?.tracker_id);
        setLoading(false);
        setStep("save-list-step");
        toast.success("We enriched your leads!", {
          icon: () => <img src={enrichedIcon} alt="enriched" />,
        });
        setLeadsValue("");
      } else {
        setLoading(false);
        toast.success("Failed to Enrich Leads");
      }
    } catch (error) {
      setLeadsValue("");
      setMaxProfile(maxProfile);
      setStep("could-not-enrich-leads");
      toast.success(error?.data?.detail || error?.response?.data?.detail);
    }
  };

  const handleEnrichCompanyLeads = async () => {
    try {
      setLoading(true);
      const payload = {
        list_data: appState?.addedLinks || [],
        job_title: appState?.job_title,
        max_profiles:
          appState?.selectedButtonType === "Companies" &&
          appState?.importMethodClicked === "manually-default"
            ? Number(leadsValue) || totalDataCsv
            : enrichType === "Peoples"
            ? totalDataCsv
            : enrichType === "Company"
            ? Number(leadsValue)
            : appState?.totalLeads > 0
            ? appState?.totalLeads
            : totalDataCsv || 1,
        tracker_id: null,
      };
      const response = await enrichLeads?.mutateAsync(payload);
      if (response?.status === 200) {
        setLoading(false);
        setProspectId(response?.prospects_id);
        setTrackerId(response?.tracker_id);
        setStep("save-list-step");
        setLeadsValue("");
      } else {
        setLoading(false);
        toast.success("Failed to Enrich Leads");
      }
    } catch (error) {
      setLoading(false);
      setStep("could-not-enrich-leads");
      toast.success(error?.data?.detail || error?.response?.data?.detail);
    }
  };

  const trackerParams = {
    qeary: `prospect_id=${prospectId}`,
  };

  const { data: checkLeadStatus, refetch: refetchStatus } = useCheckLeadStatus(
    trackerParams,
    step === "enrich-leads-step" &&
      appState?.importMethodClicked === "viaLinkedin" &&
      isEnrichedAPIDone
  );

  const handleEnrichLeadsByCopyAndPaste = async () => {
    setLoading(true);
    try {
      const payload = {
        max_profiles: Number(leadsValue),
        prospect_id: prospectId || 0,
      };
      const response = await enrichLeadsUsingCopyPaste?.mutateAsync(payload);
      if (response?.status === 200) {
        setLoading(false);
        interval = setInterval(() => {
          refetchStatus()?.then((res) => {
            if (res?.data?.prospected_status) {
              setStep("save-list-step");
              toast.success("We enriched your leads!", {
                icon: () => <img src={enrichedIcon} alt="enriched" />,
              });
              clearInterval(interval);
            }
          });
        }, 5000);
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setIsEnrichedApiDone(true);
    }
  };

  console.log({ checkLeadStatus });

  useEffect(() => {
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (isLoading) return setStep("enrich-leads-loading-step");

  return (
    <>
      <div className="flex items-start justify-start mb-[24px] font-inter">
        <div className="flex items-center justify-center border-[1px] border-[#E4E7EC] rounded-[10px] p-[11px] y-axis-shadow mr-[10px]">
          <img
            src={creditCardRefreshIcon}
            alt="info img"
            className="h-[24px] w-[24px]"
          />
        </div>
        <div className="w-full">
          <h4 className="font-[600] text-[18px] text-[#101828] m-0 font-inter">
            Enrich Leads
          </h4>
          <p className="font-normal text-[14px] text-[#475467] font-inter">
            {enrichType === "Peoples" ||
            (appState?.importMethodClicked === "manually-default" &&
              appState?.selectedButtonType === "People")
              ? "If we cannot enrich a lead, you will not be charged a credit."
              : appState?.importMethodClicked === "viaLinkedin"
              ? "If we cannot enrich a lead, you will not be charged a credit."
              : "We found leads at the companies you imported."}
          </p>
        </div>
      </div>

      <div className="modal-form-step mb-[20px] px-[36px]">
        <div className="h-[38px] flex items-center w-fit border-[2px] rounded-[8px] border-[#9E77ED] font-inter px-2">
          <div className="bg-[#F4EBFF] h-[26.1px] w-[26.1px] flex items-center justify-center rounded-full border-[3px] border-[#F9F5FF]">
            <img src={zapIcon} alt="info img" className="h-3 w-3" />
          </div>
          <p className=" text-[14.7px] font-semibold text-[#6941C6] ml-2">
            {enrichType === "Peoples" &&
            appState?.importMethodClicked !== "viaLinkedin"
              ? `${totalDataCsv || 0} Leads in your CSV`
              : appState?.totalLinkedinURL
              ? `${appState?.totalLinkedinURL} links added`
              : appState?.importMethodClicked === "viaLinkedin"
              ? `${totalDataCsv || 0} ${
                  totalDataCsv === 0 ? "Lead" : "Leads"
                } in your LinkedIn Search`
              : `${totalDataCsv || 0} ${
                  totalDataCsv === 0 ? "Lead" : "Leads"
                } found`}
          </p>
        </div>
        {(enrichType === "Peoples" ||
          (appState?.importMethodClicked === "manually-default" &&
            appState?.selectedButtonType === "People")) && (
          <div className="flex w-full border-2 rounded-[8px] border-[#7F56D9] bg-[#f9f5ff] font-inter p-4 mt-4">
            <div className="bg-[#eee2ff] h-[38.4px] w-[38.4px] flex items-center justify-center rounded-full border-[4px] border-[#f3ebff]">
              <img src={towLayersIcon} alt="info img" className="h-4 w-4" />
            </div>
            <div className="w-full pr-1">
              <div className="flex w-full items-center justify-between font-inter ml-2">
                <p className=" text-[16.8px] font-medium text-[#53389E] ">
                  Enrich Leads
                </p>
                <img src={checkbox} alt="info img" className="h-4 w-4" />
              </div>
              <p className=" text-[16.8px] text-[#6941C6] ml-2">
                This will allow you to enroll them into a campaign
              </p>
            </div>
          </div>
        )}
        {appState?.selectedButtonType === "Companies" ||
          (appState?.selectedButtonType === "Companies" &&
            appState?.importMethodClicked === "manually-default") ||
          (enrichType === "Company" && (
            <p className="font-normal text-[14px] mt-[20px] mb-[-5px] text-[#475467] font-inter">
              Choose how many leads you want to enrich below.
            </p>
          ))}
        {appState?.selectedButtonType === "Companies" &&
          appState?.importMethodClicked === "manually-default" && (
            <p className="font-normal text-[14px] mt-[20px] mb-[-5px] text-[#475467] font-inter">
              Choose how many leads you want to enrich below.
            </p>
          )}

        {appState?.importMethodClicked === "viaLinkedin" && (
          <p className="font-normal text-[14px] mt-[20px] mb-[-5px] text-[#475467] font-inter">
            Choose how many leads you want to enrich from the search.
          </p>
        )}
        {(appState?.importMethodClicked === "uploadCampanyCsv" ||
          appState?.importMethodClicked === "viaLinkedin" ||
          (appState?.selectedButtonType === "Companies" &&
            appState?.importMethodClicked === "manually-default")) && (
          <div className="mt-5">
            <label className="block text-[#344054] font-medium text-sm font-inter mb-2">
              Enter Number
              <span className="text-[#7F56D9]">*</span>
            </label>
            <Input
              name="leads"
              value={leadsValue}
              placeholder="e.g 100"
              className={`${
                totalDataCsv === 0 ? "" : "bg-white"
              }  !text-black !mb-[0px] border-[1px] w-full max-w-[400px] border-[#D0D5DD] outline-none focus:!border-[#D0D5DD]`}
              onChange={onInputChange}
              type={"tel"}
              disabled={totalDataCsv === 0}
            />
            {errorMessage && (
              <p className="text-[#FF1707] text-[12px] font-[500] mt-[4px]">
                {errorMessage}
              </p>
            )}
          </div>
        )}
        {appState?.selectedButtonType === "Companies" &&
          appState?.importMethodClicked === "manually-default" && (
            <div className="mt-[14px]">
              <p className="text-[#475467] text-[14px] font-[400]">
                Please note: Each lead you enrich will use 1 credit.
              </p>
            </div>
          )}
        {(appState?.importMethodClicked === "manually-default" &&
          appState?.selectedButtonType !== "People") ||
          ((appState?.selectedButtonType === "Companies" ||
            appState?.selectedButtonType === "Peoples" ||
            enrichType === "Company") && (
            <div className="mt-[14px]">
              <p className="text-[#475467] text-[14px] font-[400]">
                {" "}
                {appState?.importMethodClicked === "uploadPeopleCsv" &&
                appState?.selectedButtonType === "Peoples"
                  ? "Please note: Each lead you enrich and find a LinkedIn URL for will cost 2 credits."
                  : appState?.importMethodClicked === "viaLinkedin"
                  ? "Please note: We will only enrich a max of 1,000 leads from the search result."
                  : "Please note: Each lead you enrich will use 1 credit."}
              </p>
            </div>
          ))}
      </div>
      <div className={`flex items-center justify-center mt-[23px]`}>
        {(appState?.importMethodClicked === "uploadCampanyCsv" ||
          (appState?.selectedButtonType === "Companies" &&
            appState?.importMethodClicked === "manually-default") ||
          (appState?.importMethodClicked === "manually-default" &&
            appState?.selectedButtonType === "People")) && (
          <Button
            title="Back"
            isWhiteBtn={true}
            isRounded="6px"
            onClick={() => {
              if (appState?.importMethodClicked === "manually-default") {
                setStep("manually-enter");
              } else {
                setStep("job-selection");
              }
            }}
            className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow mr-[12px] !text-[#344054]"
          />
        )}
        <Button
          title={
            enrichLeadsAPI?.isPending ||
            enrichLeads?.isPending ||
            enrichLeadsCompanyAPI?.isPending ||
            enrichLeadsUsingCopyPaste?.isPending ? (
              <div className="w-[20px] h-[20px] mx-auto border-[3px] border-t-[3px] border-[#fff] border-solid rounded-full animate-spin border-t-[#8A43FF]" />
            ) : (
              "Enrich"
            )
          }
          secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9]"
          className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow"
          isRounded="6px"
          disabled={
            enrichLeadsCompanyAPI?.isPending ||
            errorMessage?.length > 0 ||
            ((leadsValue === "" || Number(leadsValue) === 0) &&
              (appState?.importMethodClicked === "viaLinkedin" ||
                (appState?.importMethodClicked === "manually-default" &&
                  appState?.selectedButtonType === "Companies")))
          }
          onClick={() => {
            if (
              appState?.selectedButtonType === "Companies" &&
              (appState?.importMethodClicked === "manually-default" ||
                appState?.importMethodClicked === "manuallyCompanyUrl")
            ) {
              handleEnrichCompanyLeads();
            } else {
              if (appState?.importMethodClicked === "viaLinkedin") {
                handleEnrichLeadsByCopyAndPaste();
              } else {
                handleEnrichLeads();
              }
            }
          }}
        />
      </div>
    </>
  );
};

export default EnrichLeadsStep;
