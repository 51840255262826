import { getAccessToken } from "utils/token-service";
import io from "socket.io-client";

let socket;

const token = getAccessToken();

export const createSocketConnection = (handleNotification) => {
  socket = io(`${process.env.REACT_APP_API_KEY}`, {
    path: '/ws/socket.io',
    transports: ['websocket'],
    auth: {
      token: token,
    },
  });

  socket.on("connect", () => {
    console.log("Connected to server");    
    socket.emit("join", token);
  });

  socket.on("notification", (data) => {
    console.log("Notification from server:", data);
    handleNotification(data);
  });

  socket.on("disconnect", () => {
    console.log("Disconnected from server");
    socket.emit("un_join", token);
  });

  socket.on("connect_error", (err) => {
    console.error("Connection error:", err);
  });
};

export const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
  }
};
