import { useEffect, useRef, useState } from "react";
import LeadsReactSelect from "../leadReactSelect";
import { useGetSkills } from "services/prospecting-api";
import { debounce } from "utils/index";

const SelectInput = ({
  options,
  name,
  placeholder,
  formData,
  setFormData,
  preventEnter = false,
}) => {
  const [searchText, setSearchText] = useState("");
  const [resultOptions, setResultOptions] = useState([]);

  const selectedValues = formData?.[name]?.values || [];

  const handleSelectChange = (event) => {
    const updatedValues = selectedValues.some(
      (item) => item.value === event.value
    )
      ? selectedValues.filter((item) => item.value !== event.value) // Remove item
      : [...selectedValues, event]; // Add item

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: { values: updatedValues },
    }));

    if (name === "skills") {
      setResultOptions([]); // Clear results for skills
    }
  };

  const handleEnterKeyPressed = (event) => {
    if (event.key === "Enter" && !preventEnter) {
      const newValue = { value: searchText, label: searchText };
      const updatedValues = selectedValues.some(
        (item) => item.value === newValue.value
      )
        ? selectedValues.filter((item) => item.value !== newValue.value) // Remove item
        : [...selectedValues, newValue]; // Add item

      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: { values: updatedValues },
      }));

      setSearchText(""); // Clear search text
    }
  };

  const handleMenuClose = () => {
    if (name === "skills") {
      setResultOptions([]); // Clear options on menu close
    }
  };

  return (
    <div className="mb-[55px]">
      <LeadsReactSelect
        placeholder={placeholder}
        onChange={handleSelectChange}
        options={resultOptions.length > 0 ? resultOptions : options}
        value={selectedValues}
        hideMultiSelectValues
        menuMaxHeight="128px"
        inputValue={searchText}
        onInputChange={setSearchText}
        handleEnterKeyPressed={handleEnterKeyPressed}
        onMenuClose={handleMenuClose}
        isLeadsComponent
        hideNoResultMessage
        isLoading={false}
      />
    </div>
  );
};

export default SelectInput;
