const TooltipItem = ({
  icon,
  value,
  text,
  onMouseEnter,
  onMouseLeave,
  isVisible,
  tooltipImgStyling,
  hideWrapperMargin,
  fontSize,
  onClick,
}) => (
  <div
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    onClick={onClick}
    className={`relative flex items-center ${!hideWrapperMargin && "mr-5"}`}
  >
    <img
      src={icon}
      alt="Icon"
      className={`w-[20.7px] h-[20.7px] mr-1.5 ${tooltipImgStyling}`}
    />
    <p className="text-[13px] text-[#475467]">{value}</p>
    {isVisible && (
      <div
        role="tooltip"
        className={`z-[100] absolute left-[50%] h-[28px] transform -translate-x-1/2 bottom-full mb-2 px-2 py-1 text-[12.08px] font-[500] text-[#6941C6] bg-[#F9F5FF] rounded-[8px] shadow-sm tooltip border border-[#E9D7FE] whitespace-nowrap max-w-[200px] overflow-x-auto ${fontSize}`}
      >
        {text}
      </div>
    )}
  </div>
);

export default TooltipItem;
