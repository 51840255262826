import cloudIllustration from "assets/images/cloud_illustration.gif";
import { AppContext } from "context/AppContext";
import { useContext, useEffect } from "react";
let loader = false

const ImportingYourLeads = ({ setStep }) => {

  const {appState} = useContext(AppContext)

  useEffect(() => {
    const timer = setTimeout(() => {
      if(appState?.importMethodClicked === "uploadCampanyCsv"){
        setStep("job-selection")
      }else{
        setStep("enrich-leads-step");
      }
    }, 3000);

    return () => clearTimeout(timer);
  }, [setStep]);

  return (
    <>
      <div className="flex flex-col items-center justify-center mb-[20px] font-inter">
        <div className="flex items-center justify-center p-[11px] mt-[15px] ">
          <img
            src={cloudIllustration}
            alt="info img"
            className="h-[117px] w-[158px] ml-[-2px]"
          />
        </div>
        <h4 className="font-[600] text-[18px] text-[#101828] mb-2 font-inter">
          Importing your leads...
        </h4>
        <p className="font-normal text-center text-[13px] leading-[18px] text-[#475467] font-inter">
          Thanks for your patience.
        </p>
        <p className="font-normal text-center text-[13px] leading-[18px] text-[#475467] font-inter">
          Depending on how many {appState.selectedButtonType === "Company" ? "companies" : "people"} you imported,
        </p>
        <p className="font-normal text-center text-[13px] leading-[18px] text-[#475467] font-inter">
          this could take a few minutes.
        </p>
      </div>
    </>
  );
};

export default ImportingYourLeads;
