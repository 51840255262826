import { useContext, useEffect, useRef, useState } from "react";
import Button from "components/button";
import uploadIcon from "assets/images/uploader.svg";
import fileIcon from "assets/images/upload-file.svg";
import deleteIcon from "assets/images/delete.svg";
import ProgressBar from "components/progressBar";
import { toast } from "react-toastify";
import { AppContext } from "context/AppContext";
import { IoClose } from "react-icons/io5";
import { useAuthStore, useProspectStore } from "stores";
import { useUploadProspectCsv } from "services/prospecting-api";
import heartBreakIcon from "assets/images/dil.png";

let fileSize;

const SettingUploadCsvModal = ({ setStep, onClose }) => {
  const { setAppState } = useContext(AppContext);
  const { setFileId, setCsvColumns } = useProspectStore();
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [showCheckedImg, setShowIsCheckedImg] = useState(false);
  const fileInputRef = useRef(null);
  const uploadCsv = useUploadProspectCsv();
  const timerRef = useRef(null);
  const [isModalVisible, setIsModalVisible] = useState(true);

  useEffect(() => {
    return () => {
      clearInterval(timerRef?.current);
    };
  }, []);

  if (file?.size < 1024) {
    fileSize = `${file?.size} bytes`;
  } else {
    fileSize = `${Math.round(file?.size / 1024)} KB`;
  }

  const handleFileChange = async (e) => {
    if (e?.key) {
      e?.preventDefault();
      return;
    }
    const selectedFile = e?.target?.files ? e.target.files[0] : null;
    // Check if a file is selected
    if (selectedFile) {
      // Check if file type is CSV
      if (selectedFile.type === "text/csv") {
        // Check if file size is less than or equal to 20 MB (20 * 1024 * 1024 bytes)
        const maxFileSize = 2 * 1024 * 1024; // 20 MB in bytes
        if (selectedFile.size <= maxFileSize) {
          setFile(selectedFile);
          uploadFile(selectedFile);
        } else {
          toast.error("File size should not exceed 2 MB.");
        }
      } else {
        toast.error("Please select a valid CSV file.");
      }
    }
  };

  const uploadFile = (file) => {
    const totalSize = file.size;
    let uploadedSize = 0;
    const chunkSize = totalSize / 100;

    timerRef.current = setInterval(() => {
      if (uploadedSize >= totalSize) {
        clearInterval(timerRef?.current);
        setProgress(100);
        setTimeout(() => {
          setShowIsCheckedImg(true);
        }, 300);
        return;
      }
      uploadedSize += chunkSize;
      setProgress(Math.min(Math.round((uploadedSize / totalSize) * 100), 100));
    }, 100);
  };

  const deleteSelectedFile = () => {
    clearInterval(timerRef?.current);
    setFile(null);
    setProgress(0);
    setShowIsCheckedImg(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setAppState((prev) => ({
      ...prev,
      isDeleteCsv: true,
    }));
    setStep("upload-failed");
    toast.success("CSV Upload Failed", {
      icon: () => <img src={heartBreakIcon} alt="heartBreakIcon" />,
    });
  };

  const onConfirm = async () => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const result = await uploadCsv.mutateAsync(formData);
      if (result?.file_id) {
        setFileId(result?.file_id);
        setCsvColumns(result?.uploaded_csv_columns);
        setStep(3);
      } else {
        setStep(2);
        toast.success("CSV Upload Failed", {
          icon: () => <img src={heartBreakIcon} alt="heartBreakIcon" />,
        });
      }
    } catch (error) {
      setStep(2);
      toast.success("CSV Upload Failed", {
        icon: () => <img src={heartBreakIcon} alt="heartBreakIcon" />,
      });
    }
  };

  return (
    <>
      <div
        className={`fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[400ms] ease-in-out ${
          isModalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
      >
        <div
          className="fixed inset-0 bg-black opacity-10 transition-opacity duration-[1000ms] ease-in-out"
          onClick={() => {
            onClose();
            setIsModalVisible(false);
          }}
        ></div>
        <div
          className={`bg-white p-[24px] rounded-lg modal-shadow z-10 w-full max-w-[600px] relative transition-all ease-in-out duration-[400ms] ${
            isModalVisible ? "scale-100" : "scale-95"
          }`}
        >
          <IoClose
            className="text-[#667085] text-[20px] w-[24px] h-[24px] absolute right-[24px] top-[26px] cursor-pointer"
            onClick={() => {
              onClose();
              setIsModalVisible(false);
            }}
          />
          <div className="flex flex-col items-start justify-start mb-[20px] font-inter">
            <h4 className="font-[600] text-[18px] text-[#101828] m-0 font-inter">
              Upload CSV of Companies
            </h4>
            <p className="font-normal text-[14px] text-[#475467] font-inter">
              Upload a file to import your blocklist.
            </p>
          </div>
          <div className="flex items-center justify-center border-[2px] border-[#E4E7EC] rounded-[12px] p-[16px] mb-[12px] relative min-h-[125px]">
            <input
              ref={fileInputRef}
              type="file"
              accept=".csv"
              className="opacity-0 z-[2] cursor-pointer h-full w-full absolute top-0 left-0"
              onChange={(e) => handleFileChange(e)}
            />
            <div className="flex flex-col items-center justify-center absolute top-0 px-[24px] py-[16px] h-full w-full">
              <img
                src={uploadIcon}
                alt="icon"
                className="min-w-[40px] w-[40px] mb-[12px]"
              />
              <p className="font-inter font-normal text-[#475467] text-[14px]">
                <span className="font-[600] text-[#6941C6]">
                  Click to upload
                </span>{" "}
                or drag and drop
              </p>
              <p className="font-inter font-normal text-[#475467] text-[12px]">
                .csv files only.
              </p>
            </div>
          </div>
          {file && (
            <div className="flex items-start border-[2px] border-[#7F56D9] rounded-[12px] p-[16px] mb-[12px] relative">
              <img
                src={fileIcon}
                alt="info img"
                className="min-w-[32px] w-[32px] mr-[16px]"
              />
              <div className="w-full">
                <div className="flex items-start justify-between mb-[8px] w-[95%]">
                  <div className="w-full">
                    <div className="flex items-center">
                      <h6 className="font-inter font-[500] text-[14px] leading-[20px] text-[#344054] m-0">
                        {file.name}
                      </h6>
                      <img
                        src={deleteIcon}
                        alt="delete"
                        className="ml-[7px] min-w-[15px] max-w-[15px] cursor-pointer mt-[-2px]"
                        onClick={deleteSelectedFile}
                      />
                    </div>
                    <p className="font-inter font-normal text-[14px] leading-[20px] text-[#475467] m-0">
                      {fileSize}
                    </p>
                  </div>
                </div>
                <ProgressBar percentage={progress} />
              </div>
              {showCheckedImg && (
                <div className="h-[16px] w-[16px] rounded-full absolute z-[3] top-[16px] right-[16px]">
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 130.2 130.2"
                    className="w-full h-full"
                  >
                    <circle
                      className="custom-checkmark circle"
                      fill="none"
                      stroke="#7F56D9"
                      strokeWidth="12"
                      strokeMiterlimit="10"
                      cx="65.1"
                      cy="65.1"
                      r="60"
                    />
                    <polyline
                      className="custom-checkmark check"
                      fill="none"
                      stroke="#7F56D9"
                      strokeWidth="12"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      points="92.2,50.2 52.5,88.8 32.8,70.5"
                    />
                  </svg>
                </div>
              )}
            </div>
          )}
          <div className="flex items-center justify-center mt-[32px]">
            <Button
              title="Cancel"
              isWhiteBtn={true}
              isRounded="6px"
              onClick={() => onClose()}
              className="!text-[16px] !h-[44px] !font-[600] !text-[#344054] y-axis-shadow"
            />
            {file && (
              <Button
                title={
                  <>
                    <div className="flex items-center justify-center">
                      {uploadCsv?.isPending ? (
                        <div className="w-[20px] h-[20px] border-[3px] border-t-[3px] border-[#fff] border-solid rounded-full animate-spin border-t-[#8A43FF]" />
                      ) : (
                        "Confirm"
                      )}
                    </div>
                  </>
                }
                secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9] ml-[12px]"
                className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow"
                isRounded="6px"
                onClick={() => {
                  onConfirm();
                }}
                disabled={progress !== 100}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingUploadCsvModal;
