import { IoClose } from "react-icons/io5";
import Button from "components/button";
import checkBg from "assets/images/check-bg.svg";
import { useEffect, useState } from "react";
import connectIcon from "assets/images/captcha.svg";
import Select from "react-select";
import OTPInput from "otp-input-react";
import { toast } from "react-toastify";
import AnimatedButton from "components/animatedButton";
import LetterEmoji from "assets/images/loveletter.png";
import Phone from "assets/images/mobile.svg";
import { useLinkedinCallback } from "services/auth-api";

const Reconnect2FAModal = ({
  isOpen,
  onClose,
  handleResendCode,
  setIsLinkedinAccountConnected,
  handleGetLinkedInAccountDetails,
  accountId,
  startVerificationInterval,
  setLikedInData,
  setLoader,
  setOpenConnectModalSteps,
  OTP,
  setOTP,
  handleVerificationSubmit,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  console.log("OTP", OTP);

  useEffect(() => {
    if (isOpen) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [isOpen]);

  const linkedInCallback = useLinkedinCallback();

  const handleCallback = async (OTP) => {
    console.log("In Handle Callback", OTP);
    setLoader(true);
    try {
      const response = await linkedInCallback.mutateAsync({
        account_id: String(accountId),
        code: OTP,
      });

      if (response?.status === 201) {
        if (response?.message === "AccountCreated") {
          startVerificationInterval(String(accountId));
          setLoader(true);
          onClose();
        }
      }

      if (response?.status === 202) {
        if (response?.checkpoint?.type === "2FA") {
          setLikedInData(response);
          setLoader(true);
          onClose();
        }
      } else if (response?.status === 400) {
        setLoader(false);
        toast.error(response?.message);
      }
    } catch (error) {
      setLoader(false);
      toast.error(error?.data?.detail);
      setOpenConnectModalSteps(0);
    }
  };

  if (!isOpen && !isModalVisible) return null;

  return (
    <>
      <div
        className={`fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[300ms] ease-in-out ${
          isModalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
      >
        <div
          className="fixed inset-0 bg-black opacity-10"
          onClick={onClose}
        ></div>
        <div
          className={`p-[24px] bg-white rounded-lg shadow-lg z-10 w-full max-w-[556px] relative transition-all ease-in-out duration-[150ms] ${
            isModalVisible ? "scale-100" : "scale-95"
          }`}
        >
          <IoClose
            className="text-[#667085] text-[20px] w-[24px] h-[24px] absolute right-[24px] top-[27px] cursor-pointer"
            onClick={onClose}
          />
          <div className="modal-scroll">
            <>
              <div className="w-[48px] h-[48px] rounded-[13.9px] flex items-center justify-center border border-[#E4E7EC]">
                <img
                  src={Phone}
                  alt="Logo"
                  className="h-[24px] w-[24px] block"
                  style={{
                    filter: "brightness(1.2)",
                  }}
                />
              </div>
              <h1 className="text-[18px] text-[#303030] font-[600] mt-[20px] m-0">
                2FA
              </h1>
              <p className="text-[14px] font-[400] text-[#475467] leading-[24px]">
                You have 2FA enabled, please enter the code.
              </p>
              <form
                className="mt-[27px] w-full mb-[16.6px]"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="flex items-center justify-center mr-[-20px]">
                  <OTPInput
                    value={OTP}
                    onChange={setOTP}
                    autoFocus
                    OTPLength={6}
                    otpType="number"
                    inputClassName="otpInput custom-shadow !h-[56px] !px-[2px] !w-[40px] text-[28px] border border-[#E4E7EC] rounded-[6px] bg-transparent text-[#000] !outline-none focus:!border-[#8A43FF] focus:!border-[2px]"
                  />
                </div>
                <p className="text-[16px] mt-[16.68px] font-[400] text-center text-[#475467] leading-[24px]">
                  Didn’t receive the code?
                </p>
                <p
                  className="mt-[11.12px] text-[#6941C6] font-[600] text-[14px] text-center cursor-pointer"
                  onClick={() => handleResendCode("2fa_code")}
                >
                  Click to resend
                </p>
                <div className="flex items-center justify-center mt-[20.85px] w-full">
                  {OTP?.length >= 6 ? (
                    <AnimatedButton
                      title="Next"
                      onClick={() => {
                        onClose();
                        setLoader(true);
                        handleCallback(OTP);
                      }}
                      disabled={OTP.length !== 6}
                      className="!text-[16px] !font-[600] !h-[44px] !rounded-[7px] !border-[#7F56D9] !bg-[#7F56D9] w-full"
                    />
                  ) : (
                    <Button
                      isWhiteBtn={true}
                      title="Cancel"
                      secondaryColor=""
                      className="!text-[16px] text-[#344054] !h-[44px] w-full !font-[600] y-axis-shadow !rounded-[8px]"
                      onClick={() => {
                        handleGetLinkedInAccountDetails();
                        setIsLinkedinAccountConnected(true);
                        onClose();
                      }}
                    />
                  )}
                </div>
                {/* <div className="flex items-center justify-center mt-[20.85px]">
                  <Button
                    title="Cancel"
                    isWhiteBtn={true}
                    onClick={onClose}
                    className="!text-[16px] !h-[44px] !font-[600] !text-[#344054] y-axis-shadow !rounded-[8px]"
                  />
                  <Button
                    onClick={() => handleCallback(OTP)}
                    disabled={OTP.length <= 5}
                    title={
                      linkedInCallback?.isPending ? (
                        <div className="mx-auto w-[20px] h-[20px] border-[3px] border-t-[3px] border-[#fff] border-solid rounded-full animate-spin border-t-[#8A43FF]" />
                      ) : (
                        "Continue"
                      )
                    }
                    secondaryColor={`!border-[#7F56D9] !bg-[#7F56D9] ml-[12px] ${"hover:!bg-transparent hover:!text-[#7F56D9]"}`}
                    className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow !rounded-[8px]"
                  />
                </div> */}
              </form>
            </>
            {/* <div className="flex flex-col items-start justify-start mb-[20px] font-inter">
            <div
              className="flex justify-center items-center border-[#E4E7EC] border w-[66px] h-[66px] rounded-[10px] mb-[22px]"
              style={{
                boxShadow:
                  "0px 4px 8px 0px rgba(16, 24, 40, 0.05), 0px 2px 0px 0px rgba(16, 24, 40, 0.05), 0px 0px 1px 0px rgba(16, 24, 40, 0.18)",
              }}
            >
              <img
                src={connectIcon}
                alt="coins"
                className="h-[30px] w-[30px]"
              />
            </div>
            <h4 className="font-[600] text-[25px] text-[#101828] m-0 font-inter">
            Solve the Captcha
            </h4>
            <p className="font-normal text-[#475467] text-0[18.3px]">You’ll need to solve the captcha below.</p>
          </div>
          <div className="border-[#CBCBE84F] border-[1px] h-[412px] w-full"></div>
            <div className="flex items-center justify-center mt-[32px]">
              <Button
              isWhiteBtn={true}
                title="Cancel"
                secondaryColor=""
                className="!text-[22.2px] text-[#344054] !h-[61px] !font-[600] y-axis-shadow !rounded-[8px]"
                onClick={() => {
                  handleGetLinkedInAccountDetails();
                  setIsLinkedinAccountConnected(true);
                  onClose();
                }}
              />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Reconnect2FAModal;
