import visitLogo from "assets/images/visit-logo.png";
import logo from "assets/images/logo.svg";
import redirectArrow from "assets/images/dashboard-action-arrow.svg";
import { useNavigate } from "react-router-dom";
import useVisitorModalStore from "stores/visitorModalStore";

const UserVisitingModal = (props) => {
  const navigate = useNavigate();
  const { setIsConnectLinkedIn } = useVisitorModalStore();
  const isSkipSignUp = localStorage?.getItem("isSkipSignUp");
  const { isOpen, onClose } = props;

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 font-inter">
      <div
        className="fixed inset-0 bg-black opacity-10"
        onClick={onClose}
      ></div>
      <div className="bg-[#111014] border-[1px] border-[#7575758F] p-[34px] rounded-[12px] shadow-lg z-10 w-full max-w-[628px] relative">
        <div className="flex flex-col items-center">
          <div className="w-[260px] h-[250px] overflow-hidden relative">
            <img
              src={visitLogo}
              alt="visitLogo"
              className="h-full w-full relative rotate-and-color "
            />
            <img
              src={logo}
              alt="logo"
              className="w-[60px] h-[60px] absolute z-10 top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2"
            />
          </div>
          <div className="modal-scroll">
            <h4 className="font-[700] text-[28.38px] text-[#FFFFFF] leading-[34.35px] mb-[17px] font-inter text-center">
              Goodbye Manual,
              <br /> Hello Automation.
            </h4>
            <p className="font-[500] text-[13.2px] text-[#FFFFFFCC] font-inter text-center  mb-0">
              How to get started with Pipeline...
            </p>
            {/* links */}
            <div className="mt-[40px] mb-[36px] w-[276px]">
              <div
                className={`mb-[18px] flex items-center justify-between p-[8px] pl-[15px] h-[48px] border-[1px] border-[#E4E7EC38] rounded-[11.2px] ${
                  isSkipSignUp && "cursor-pointer"
                }`}
                style={{
                  background:
                    " linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(117, 23, 248, 0.18) 100%)",
                }}
                onClick={() => {
                  if (isSkipSignUp) {
                    navigate("/accounts");
                    setIsConnectLinkedIn(true);
                  }
                }}
              >
                <p className="font-[500] text-[15px] leading-[18.7px] text-[#D8D8D8] m-0 mt-[2px]">
                  Connect LinkedIn
                </p>
                <div
                  className={`flex items-center justify-center m-0 border-[1px] rounded-[8px] text-[#FFFFFF] text-[11.8px] leading-[14.8px] font-[600] border-[#7517F8] !bg-[#7517F8] !p-0 !h-[31px] ${
                    isSkipSignUp ? "!w-[71px]" : "!w-[56px]"
                  }`}
                >
                  {isSkipSignUp ? "Connect" : "Done"}
                </div>
              </div>
              <div
                className="cursor-pointer mb-[18px] flex items-center justify-between p-[11px] pl-[15px] h-[48px] border-[1px] border-[#E4E7EC38] rounded-[11.2px]"
                style={{
                  background:
                    " linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(117, 23, 248, 0.18) 100%)",
                }}
                onClick={() => navigate("/findLeads")}
              >
                <p className="font-[500] text-[15px] leading-[18.7px] text-[#D8D8D8] m-0 mt-[2px]">
                  Prospect New Leads
                </p>
                <img
                  src={redirectArrow}
                  className="w-[24px] h-[24px] mt-[1.5px]"
                  alt="redirectArrow"
                />
              </div>
              <div
                className="cursor-pointer flex items-center justify-between p-[11px] pl-[15px] h-[48px] border-[1px] border-[#E4E7EC38] rounded-[11.2px]"
                style={{
                  background:
                    " linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(117, 23, 248, 0.18) 100%)",
                }}
                onClick={() => {
                  setIsConnectLinkedIn(true);
                  navigate("/prospecting");
                }}
              >
                <p className="font-[500] text-[15px] leading-[18.7px] text-[#D8D8D8] m-0 mt-[2px]">
                  Import Leads
                </p>
                <img
                  src={redirectArrow}
                  className="w-[24px] h-[24px] mt-[1.5px]"
                  alt="redirectArrow"
                />
              </div>
            </div>
            <button
              className="text-[14px] block mx-auto font-[500] border-0 outline-none text-[#FFFFFFCC]"
              onClick={onClose}
            >
              Dismiss
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserVisitingModal;
