import { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import CardPic from "assets/images/Card-mockup.svg";
import { useAddPaymentMethod } from "services/payments-api";
import { toast } from "react-toastify";
import CC from "assets/images/cc.png";

const stripePromise = loadStripe(
  "pk_test_51Mrc5MICbyBbd0D4ZSK74Foa5tWLATm9oYkc2NZMnOho8eUuDWUpS5iqXOhtwsgu1q0WUGursLVpVhlCil4bbX9n002v8Q2snl"
);

const PaymentCard = ({
  isEditing,
  setIsPaymentCardOpen,
  onSave,
  cardDetails,
  isRefetch,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const updateCard = useAddPaymentMethod();
  const [formData, setFormData] = useState({
    name: "",
    expiration: "",
    cardNumber: "",
    cvv: "",
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (isEditing && cardDetails) {
      setFormData({
        name: cardDetails.name || "",
        expiration: cardDetails.expiration || "",
        cardNumber: cardDetails.cardNumber || "",
        cvv: cardDetails.cvv || "",
      });
    }
    setIsModalVisible(true);
  }, [isEditing, cardDetails]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) return;

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      setSuccess(false);
    } else {
      const response = await updateCard.mutateAsync({
        payment_method_id: paymentMethod?.id,
      });
      if (response.status === 400) {
        toast.success("Invalid Card Details", {
          icon: () => <img src={CC} alt="name" />,
        });
        setIsPaymentCardOpen(false);
        setIsModalVisible(false);
      } else {
        setError(null);
        setSuccess(true);
        onSave(paymentMethod);
        isRefetch();
        setIsPaymentCardOpen(false);
        setIsModalVisible(false);
      }
    }
  };

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 transition-opacity duration-300 ease-in-out ${
        isModalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className="fixed inset-0 bg-black opacity-10"
        onClick={() => {
          setIsPaymentCardOpen(false);
          setIsModalVisible(false);
        }}
      ></div>
      <div
        className={`max-w-[480px] p-[24px] font-inter bg-white drop-shadow-sm rounded-[8px] overflow-auto modal-scroll transition-all ease-in-out duration-[150ms] ${
          isModalVisible ? "scale-100" : "scale-95"
        }`}
      >
        <div className="flex justify-center w-[432px] h-[253.99px]">
          <img src={CardPic} className="" alt="Card" />
        </div>

        <div className="mt-[24px] mb-[20px] flex flex-col justify-center">
          <p className="text-[18px] font-semibold text-[#101828] font-inter">
            {isEditing ? "Update" : "Add"} payment method
          </p>
          <p className="text-[#475467] text-[14px] font-inter">
            {isEditing ? "Update" : "Add"} your card details.
          </p>
        </div>

        <form
          onSubmit={handleSubmit}
          className="flex flex-wrap w-full overflow-auto modal-scroll"
        >
          <div className="flex flex-col justify-between w-full mb-[16px]">
            <p className="mb-[6px] block text-left text-[#344054] text-[14px] font-medium font-inter">
              Card Details
            </p>
            <CardElement
              options={{
                style: {
                  base: {
                    fontSize: "16px",
                    color: "#424770",
                    "::placeholder": {
                      color: "#aab7c4",
                    },
                  },
                  invalid: {
                    color: "#9e2146",
                  },
                },
              }}
              className="border rounded-[8px] p-2 w-full border-[#D0D5DD]"
            />
          </div>

          {/* {error && <p className="text-red-500 text-xs mt-1">{error}</p>} */}
          {success && (
            <p className="text-green-500 text-xs mt-1">
              Payment method updated successfully!
            </p>
          )}

          <div className="flex justify-between mt-[32px] w-full">
            <button
              className="border border-[#D0D5DD] mr-[12px] rounded-lg w-[210px] text-[#344054] text-[16px] h-[44px] font-semibold py-2 px-4 focus:outline-none focus:shadow-outline font-inter"
              type="button"
              onClick={() => setIsPaymentCardOpen(false)}
            >
              Cancel
            </button>
            <button
              className="border bg-[#7F56D9] border-[#7F56D9] hover:text-[#7F56D9] hover:bg-transparent rounded-lg w-[210px] text-[#fff] text-[16px] h-[44px] font-semibold py-2 px-4 font-inter"
              disabled={!stripe}
            >
              {updateCard.isPending ? (
                <div className="mx-auto w-[20px] h-[20px] border-[3px] border-t-[3px] border-[#fff] border-solid rounded-full animate-spin border-t-[#8A43FF]" />
              ) : isEditing ? (
                "Update"
              ) : (
                "Add"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const PaymentCardModal = ({ ...props }) => (
  <Elements stripe={stripePromise}>
    <PaymentCard {...props} />
  </Elements>
);

export default PaymentCardModal;
