import Button from "components/button";
import thumbIcon from "assets/images/thumbs-down.svg";

const NotEnrichImportLeads = ({ setStep,leadsValue }) => {
  
  return (
    <div className="modal-scroll">
      <div className="flex flex-col items-start justify-start mb-[20px] font-inter">
        <img
          src={thumbIcon}
          alt="info img"
          className="h-[48px] w-[48px] mb-[16px]"
        />
        <h4 className="font-[600] text-[18px] text-[#101828] mb-[9px] font-inter">
          Could not enrich all imported leads.
        </h4>
        <p className="font-normal text-[14px] text-[#475467] mb-[7px] font-inter">
          <b>{leadsValue}</b> leads could not be enriched.
        </p>
        <p className="font-normal text-[14px] text-[#475467] font-inter">
          You will not be charged the {leadsValue} credits.
        </p>
      </div>
      <div className="flex items-center justify-center mt-[32px]">
        <Button
          title="Continue"
          isWhiteBtn={true}
          onClick={() => setStep("add-team")}
          className="!text-[16px] !h-[44px] !font-[600] !text-[#344054] y-axis-shadow !rounded-[8px]"
        />
      </div>
    </div>
  );
};

export default NotEnrichImportLeads;
