  // src/components/TimezoneSelect.js
  import "./style.css"
  import TimezoneSelect from 'react-timezone-select';
  import PropTypes from 'prop-types';

  const TimezoneSelectComponent = ({ value, onChange, placeholder, icon: Icon,maxHeight, ...props }) => {
    return (
      <div className="relative">
        {Icon && (
          <Icon className="absolute left-[10px] z-10 top-[12px] text-[#667085]" size={20} />
        )}
        <TimezoneSelect
          value={value}
          onChange={onChange}
          classNamePrefix="timeZone-select"
          placeholder={placeholder}
          className={`${Icon &&  "with-time-icon"} relative`}
          maxMenuHeight={maxHeight}
          {...props}
        />
      </div>
    );
  };

  TimezoneSelectComponent.propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    icon: PropTypes.elementType,
  };


  export default TimezoneSelectComponent;
