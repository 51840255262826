import deleteImg from "assets/images/thumbs-down.svg";
import Button from "components/button";
import { useContext, useEffect } from "react";
import { toast } from "react-toastify";
import Heart from "assets/images/dil.png";
import { AppContext } from "context/AppContext";

let loader = false;

const UploadFailed = ({ setStep }) => {
  const {appState,setAppState} = useContext(AppContext)
  // useEffect(() => {
  //   if(appState?.isDeleteCsv){
  //     setTimeout(() => {
  //       setStep("");
  //     }, 3000);
  //     setAppState((prev)=>({
  //       ...prev,
  //       isDeleteCsv:false
  //     }))
  //   }
  //   if (!loader) {
  //     loader = true
  //       toast.success("CSV Upload Failed", {
  //         icon: () => <img src={Heart} alt="name" />,
  //       });
  //     setTimeout(() => {
  //       setStep("data-mapping-failed");
  //     }, 3000);
  //   }
  //   setTimeout(() => {
  //     loader = false
  //   }, 5000);
  // }, [setStep]);

  return (
    <>
      <div className="tems-center justify-center font-inter">
        <div className="modal-scroll">
          <div className="modal-scroll">
            <div className="flex flex-col items-start justify-start font-inter">
              <div className="flex items-center justify-center mb-[16px]">
                <img
                  src={deleteImg}
                  alt="info img"
                  className="h-[48px] w-[48px]"
                />
              </div>
              <h4 className="font-[600] text-[18px] text-[#101828] m-[4px] mb-0 ml-0 font-inter">
                Upload Failed
              </h4>
              <p className="font-[700] text-[14px] text-[#475467] font-inter mt-[9px]">
                Please try again.
              </p>
            </div>
          </div>
        </div>
        <Button
          title="Back to CSV Upload"
          isWhiteBtn={true}
          isRounded="6px"
          onClick={() => {
            setStep("uploadPeopleCsv");
          }}
          className="!text-[16px] !h-[44px] !font-[600] !mb-[0px] !mt-[32px] y-axis-shadow mr-[12px] !text-[#344054]"
        />
      </div>
    </>
  );
};

export default UploadFailed;
