import { useEffect, useRef, useState } from "react";
import moment from "moment";
import { getNameInititals, getRandomProfileLogo } from "utils/index";
import WithdrawModal from "./WithdrawModal";
import ReconnectBanner from "components/reconnectBanner";
import Layout from "components/layout";
import Button from "components/button";
import EmptyState from "./EmptyState";
import ProspectingBreadcrumbHeader from "components/breadcrumbHeader";
import arrowDown from "assets/images/arrow-down.svg";
import LinkedinIcon from "assets/images/colored-linkedin.svg";
import verified from "assets/images/verified.svg";
import {
  useConnectionList,
  useRemoveConnection,
} from "services/connections-api";
import InfiniteScroll from "react-infinite-scroll-component";
import { useConnectionStore } from "stores";
import { connectionTabs, randomCompanyLogo } from "constant";
import ReviewSidePanel from "components/reviewSidePanel";
import DeleteLeadModal from "components/prospecting/prospectingSettingModals/deleteLeadModal";
import deleteImg from "assets/images/trash.svg";
import { toast } from "react-toastify";
import ConnectionTableSkeleton from "components/skeletonScreens/connectionTableSkeleton";
import Tabs from "components/tabs/new-tabs";
import { renderStatus } from "utils/render-status";
import SearchBar from "./SearchBar";

let loaded = false;
let fetch = true;

const tableHeader = [
  { title: "Lead", icon: "arrow", key: "name" },
  { title: "Company", icon: "arrow", key: "company" },
  { title: "Campaign Name", icon: "arrow", key: "campaign_name" },
  { title: "Status", icon: "arrow", key: "linkedin_status" },
  { title: "Date Connected    ", icon: "arrow", key: "created_at" },
];

const Connections = () => {
  const tableRef = useRef(null);
  const fetchRef = useRef(false);
  const deleteLead = useRemoveConnection();
  const { connectionList, setConnectionList } = useConnectionStore();

  const [isWithdrawModalOpened, setIsWithdrawModalOpened] = useState(false);
  const [seltectedRowItem, setSelectedRowItem] = useState(null);
  const [companyLogoMap, setCompanyLogoMap] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [checkedStates, setCheckedStates] = useState(
    filteredData?.map(() => false) // All checkboxes are unchecked initially
  );
  const [sortBy, setSortBy] = useState("created_ts");
  const [sortOrder, setSortOrder] = useState("desc");
  const [totalItems, setTotalItems] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [selectedLeadIds, setSelectedLeadIds] = useState([]);
  const [modalRandomProfileLogo, setModalRandomProfileLogo] = useState("");
  const [modalRandomCompanyLogo, setModalRandomCompanyLogo] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [paginationData, setPaginationData] = useState({
    limit: 20,
    page: 1,
  });
  const [searchList, setSearchList] = useState([]);
  const [tab, setTab] = useState("Accepted");

  let campaignParams = {
    limit: paginationData?.limit,
    sort_by: sortBy,
    sort_order: sortOrder,
    page: paginationData?.page,
    active_tab:
      connectionTabs?.find((item) => item?.label === tab)?.value || "accepted",
    search_query: searchList?.toString(),
  };

  const {
    data: connectionsData,
    refetch,
    isFetching,
    isRefetching,
  } = useConnectionList(campaignParams);

  useEffect(() => {
    if (paginationData?.page === 1) {
      setConnectionList(connectionsData?.leads_data);
      fetch = false;
    } else {
      setConnectionList([...connectionList, ...connectionsData?.leads_data]);
      setLoader(true);
    }
    setTotalItems(connectionsData?.pagination?.total_records);
  }, [connectionsData?.leads_data]);

  useEffect(() => {
    if (!loaded) {
      loaded = true;
    }
    if (!fetch) {
      refetch();
    }
  }, [sortBy, sortOrder, paginationData, tab]);

  useEffect(() => {
    setFilteredData(connectionList);
  }, [connectionList]);

  useEffect(() => {
    if (tableRef?.current) {
      tableRef.current.scrollTop = 0;
    }
  }, [sortBy, sortOrder, tab]);

  useEffect(() => {
    if (fetch && fetchRef.current) {
      refetch();
      setPaginationData({
        limit: 20,
        page: 1,
      });
    }
  }, [tab]);

  useEffect(() => {
    return () => {
      fetch = true;
    };
  }, []);

  const onRefetch = () => {
    if (totalItems === filteredData?.length) {
      setHasMore(false);
      return;
    }
    setLoader(false);
    setPaginationData({
      ...paginationData,
      page: paginationData?.page + 1,
      limit: 20,
    });
  };

  const handleRowClick = (row, logo) => {
    setSelectedRowItem(row, row?.company_logo || logo);
    setModalRandomCompanyLogo(logo);
    setModalRandomProfileLogo(getRandomProfileLogo());
  };

  const fetchData = () => {
    setTimeout(() => {
      onRefetch();
    }, 500);
  };

  const getCompanyLogo = (companyName) => {
    if (companyLogoMap[companyName]) {
      return companyLogoMap[companyName];
    }
    const randomLogo =
      randomCompanyLogo[Math.floor(Math.random() * randomCompanyLogo.length)];
    setCompanyLogoMap((prevMap) => ({
      ...prevMap,
      [companyName]: randomLogo.src,
    }));

    return randomLogo.src;
  };

  const handleDeleteLead = async () => {
    if (selectedLeadIds?.length === 0) {
      console.log("No leads selected for deletion");
      return;
    }

    for (const leadId of selectedLeadIds) {
      try {
        const response = await deleteLead.mutateAsync({
          lead_id: leadId,
        });
        toast.success("Connection Deleted", {
          icon: () => <img src={deleteImg} alt="name" />,
        });
        console.log(`Connection lead ${leadId}:`, response);
      } catch (error) {
        console.log(`Error deleting connection ${leadId}:`, error);
      }
    }
    refetch();
    setSelectedLeadIds([]);
    setCheckedStates((prevStates) => prevStates.map(() => false));
    setIsDeleteModalOpen(false);
  };

  const handleTableSort = (column) => {
    const isAsc = sortBy === column && sortOrder === "asc";
    setSortBy(column);
    setSortOrder(isAsc ? "desc" : "asc");
    setPaginationData({
      limit: 20,
      page: 1,
    });
    setLoader(false);
  };

  const handleSearchChipsChange = (newChips) => {
    setSearchList(newChips);
  };

  const handleSearch = () => {
    refetch();
  };

  useEffect(() => {
    if (searchList?.length < 1) {
      refetch();
    }
  }, [searchList]);

  return (
    <Layout>
      <div className="h-[100vh] flex flex-col justify-start items-start font-inter">
        <ProspectingBreadcrumbHeader
          breadcrumbItems={[
            { title: "Connections", route: "/connections" },
            { title: "All" },
          ]}
        />
        <ReconnectBanner />
        <div
          className="max-w-[100%] w-full px-[31px] py-[18px] h-full !overflow-hidden"
          data-aos="fade-left"
        >
          <div className="flex items-center justify-between w-full mb-3">
            <div className="w-full">
              <h1 className="text-[32px] text-left text-[#252C32] font-semibold leading-normal">
                Connections
              </h1>
              <p className="w-full text-[#475467] text-[14px] font-[400]">
                View new connections from all of your campaigns.
              </p>
            </div>
            <div className="flex items-center w-full justify-end">
              <Button
                isFullWidth={false}
                gradientBg={true}
                height="32px"
                className="!rounded-[8px] !pr-[8px]"
                title={
                  <div className="flex items-center !w-[108px] justify-center font-inter !text-[14px] !font-[600]">
                    Withdraw
                  </div>
                }
                onClick={() => {
                  setIsWithdrawModalOpened(true);
                }}
              />
            </div>
          </div>
          <div className="flex justify-between items-start">
            <Tabs
              tab={tab}
              setTab={(t) => {
                setSearchList([]);
                setTab(t);
                fetchRef.current = true;
                fetch = true;
              }}
              tabOptions={connectionTabs}
              //  disableTab={!prospectList?.length}
            />
            <SearchBar
              handleChange={handleSearchChipsChange}
              searchList={searchList}
              handleSearch={handleSearch}
            />
          </div>

          {(isFetching || isRefetching) && loader ? (
            <div className="mt-[21px]">
              <ConnectionTableSkeleton />
            </div>
          ) : filteredData?.length > 0 ? (
            <div className="border border-[#E4E7EC] rounded-xl mt-[21px]">
              <div
                className="round-table-scroll overflow-auto max-h-[calc(100vh_-250px)]"
                id="scrollableDiv"
              >
                <InfiniteScroll
                  dataLength={filteredData?.length || 0}
                  next={fetchData}
                  hasMore={filteredData?.length < totalItems && hasMore}
                  loader={
                    filteredData?.length !== totalItems && (
                      <div className="h-[50px] w-full flex items-center justify-center">
                        <div className="w-[20px] h-[20px] border-[3px] border-t-[3px] border-[#7F56D9] border-solid rounded-full animate-spin border-t-[#fff]" />
                      </div>
                    )
                  }
                  scrollableTarget="scrollableDiv"
                  style={{ height: "100%", overflow: "unset" }}
                  ref={tableRef}
                >
                  <table className={`min-w-full h-unset rounded-xl `}>
                    <thead className="sticky top-0 !z-[40] bg-[#fff] overflow-hidden rounded-xl">
                      <tr className="w-full relative">
                        {tableHeader.map((header, index) => (
                          <th
                            key={index}
                            className={`text-[12px] font-[500] py-[11.8px] px-[22px] text-[#475467] text-left font-inter cursor-pointer rounded-xl ${
                              filteredData?.length > 0 ? "" : "w-[25%]"
                            }`}
                          >
                            <div
                              className={`flex items-center relative ${
                                header.title === "Campaign Name"
                                  ? "ml-0"
                                  : "-ml-2.5"
                              }`}
                            >
                              <div
                                className={`flex items-center relative`}
                                onClick={() =>
                                  header?.key && handleTableSort(header?.key)
                                }
                              >
                                <span className="text-[11.37px]">
                                  {header.title}
                                </span>
                                {header.icon === "arrow" && (
                                  <img
                                    src={arrowDown}
                                    alt="arrowDown"
                                    className={`cursor-pointer ml-[4px] w-[10px] h-[10px] ${
                                      sortBy === header?.key &&
                                      sortOrder === "desc"
                                        ? "rotate-180"
                                        : ""
                                    }`}
                                  />
                                )}
                              </div>
                            </div>
                          </th>
                        ))}
                      </tr>
                      <span className="w-full border-b border-[#E4E7EC] h-[1px] absolute top-full z-10 left-0" />
                    </thead>
                    <tbody>
                      {filteredData?.length > 0 &&
                        filteredData?.map((item, rowIndex) => {
                          const logo = getCompanyLogo(item?.company_name);
                          const isLast = rowIndex === filteredData?.length - 1;
                          return (
                            <tr
                              className={`${
                                isLast ? "" : "border-b"
                              }  hover:bg-gray-50 cursor-pointer`}
                              key={rowIndex}
                              onClick={() =>
                                handleRowClick(item, item?.company_logo || logo)
                              }
                            >
                              <td className="text-[13px] py-[8px] pl-[12px] pr-[20px] text-left  w-[550px] max-[1300px]:w-[unset]">
                                <div className="flex items-center">
                                  <a
                                    href={item?.linkedin_url}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="z-100"
                                  >
                                    <img
                                      src={LinkedinIcon}
                                      className="h-[20.11px] w-[20.11px]"
                                      alt="Linkedin Icon"
                                    />
                                  </a>
                                  <div className="ml-3 relative">
                                    <img
                                      src={verified}
                                      alt="verified"
                                      className="absolute z-30 w-[10.7px] h-[10.7px] right-[3.5px] bottom-[3px] "
                                    />
                                    {item?.profile_pic ? (
                                      <img
                                        src={item?.profile_pic}
                                        alt="user images"
                                        className="h-[40px] w-[40px] min-w-[40px] rounded-full"
                                      />
                                    ) : (
                                      <div className="w-[40px] min-w-[40px] h-[40px] rounded-full flex items-center justify-center text-[14px] font-[700] text-[#4409B9] bg-[#EEEDFD]">
                                        {getNameInititals(item?.lead_name)}
                                      </div>
                                    )}
                                  </div>
                                  <div className="flex flex-col items-start ml-1.5 mt-0.5 w-[70%]">
                                    <p className="text-[#101828] break-words text-[14px] leading-5 font-inter font-[500]">
                                      {item?.lead_name || ""}
                                    </p>
                                    <p className="text-[#475467] break-words text-[14px] leading-5 font-inter -mt-0.5 font-[400]">
                                      {item?.job_title}
                                    </p>
                                  </div>
                                </div>
                              </td>

                              <td className="text-[13px] py-[8px] px-[12px] text-[#252C32] text-left  w-[300px] max-[1300px]:w-[unset]">
                                <div className="flex items-center">
                                  <div className="relative">
                                    <img
                                      src={item?.company_logo || logo}
                                      alt="user images"
                                      className={`h-[40px] w-[40px] min-w-[40px] ${
                                        item?.company_logo && "rounded-full"
                                      }`}
                                    />
                                  </div>
                                  <div className="flex flex-col items-start ml-1.5 mt-0.5">
                                    <p className="text-[#101828] break-words text-[14px] font-[500] leading-5 font-inter">
                                      {item?.company_name}
                                    </p>
                                    <p className="text-[#475467] break-words text-[14px] leading-5 font-inter font-[400]">
                                      {item?.company_url}
                                    </p>
                                  </div>
                                </div>
                              </td>

                              <td className="pr-[22px] py-[11.8px] px-[22px] text-left w-[350px] max-[1300px]:w-[unset]">
                                <p className="text-[#475467] break-words text-[13px] font-inter">
                                  {item?.campaign &&
                                    item?.campaign[0]?.campaign_name}
                                </p>
                              </td>

                              <td className="py-[5px] pr-[22px] pl-[10px] text-left w-[350px] max-[1300px]:w-[unset]">
                                {item?.status_name && (
                                  <p className="px-[6px] py-[2px] border border-[#D0D5DD] text-[11.37px] text-[#344054] font-[500]  rounded-[6px] font-inter inline-flex items-center">
                                    {renderStatus(item?.status_name)}
                                  </p>
                                )}
                              </td>

                              <td className="text-[13.26px] py-[8px] px-[12px] text-[#475467] text-left font-[400]  w-[280px] max-[1300px]:w-[unset]">
                                <p className="text-[#475467] break-words text-[13px] font-inter">
                                  {moment(item?.last_action_ts).format(
                                    "MMMM DD[,] YYYY"
                                  )}
                                </p>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </InfiniteScroll>
              </div>
            </div>
          ) : (
            <div className="flex justify-center items-center h-[calc(100vh_-300px)]">
              <EmptyState tab={tab} searchList={searchList} />
            </div>
          )}
        </div>
      </div>
      <div className="modal-scroll">
        <ReviewSidePanel
          rowData={seltectedRowItem}
          setRowData={setSelectedRowItem}
          isProspecting={true}
          profileLogo={modalRandomProfileLogo}
          companyLogo={modalRandomCompanyLogo}
          page="in-page"
        />
      </div>
      <DeleteLeadModal
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
        }}
        accountManager={true}
        confirmDelete={() => handleDeleteLead()}
        isLoading={deleteLead?.isPending}
      />
      <WithdrawModal
        isOpen={isWithdrawModalOpened}
        onClose={() => setIsWithdrawModalOpened(false)}
      />
    </Layout>
  );
};

export default Connections;
