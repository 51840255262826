const DashboardSkeleton = () => {
  return (
    <>
      {Array(6)
        ?.fill(1)
        ?.map((_, index) => {
          return (
            <div
              key={index}
              className="ulShadow h-[160px] w-[32%] border border-[#E4E7EC38] rounded-[11.2px] mb-[29.5px] animate-fadeIn"
              style={{
                background:
                  "linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, #000000 100%)",
              }}
            >
              <div className="relative flex flex-col h-full">
                <div className="h-full flex flex-col justify-between p-[22.48px]">
                  <h4 className="dashboard-animate-pulse rounded-md h-[18px] w-[100px]" />
                  <h3 className="dashboard-animate-pulse rounded-md h-[30px] w-[50px]"></h3>
                </div>
                <div className="h-[51px] border-t border-[#FFFFFF26] py-[10px] px-[22.48px] flex justify-end">
                  <div className="dashboard-animate-pulse rounded-md h-[25px] w-[120px]"></div>
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
};

export default DashboardSkeleton;
