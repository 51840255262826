import arrowDown from "assets/images/arrow-down.svg";
import helpIcon from "assets/images/Help-icon.svg";
import ProgressBar from "components/progressBar";
import { useEffect, useState } from "react";
import CommonCheckbox from "components/commonCheckbox";
import verified from "assets/images/verified.svg";
import { useAccountStore, useCampaignStore } from "stores";
import { useFetchCampaignLeads } from "services/campaigns-api";
import moment from "moment-timezone";
import EmptyPage from "components/emptyPageComponent";
import { randomCompanyLogo } from "constant";
import { getNameInititals } from "utils/index";
import InfiniteScroll from "react-infinite-scroll-component";
import LeadsTableSkeleton from "components/skeletonScreens/leadsTableSkeleton";
import LightStars from "assets/images/light-stars.svg";
import LinkedinIcon from "assets/images/colored-linkedin.svg";
import Stars from "assets/images/rocket.png";
import CommonReactTooltip from "components/commonReactTooltip";
import { renderStatus } from "utils/render-status";

const tableHeader = [
  { title: "Lead", icon: "arrow", key: "name" },
  { title: "Company", icon: "arrow", key: "company" },
  { title: "Progress", icon: "help", key: "name" },
  { title: "Status", icon: "arrow", key: "linkedin_status" },
  { title: "Last Action Taken", icon: "arrow", key: "last_action_ts" },
];

const FounderSfTable = (props) => {
  const {
    tableRef,
    setSelectedRowItem,
    setCheckedStates,
    checkedStates,
    setSelectedLeadIds,
    setRefetchLeads,
    isCheckingLeadsStatus,
    setAvailableLeads,
    setEnrichedLeads,
    isEnrichingLeadsOpen,
    setIsCheckingLeadsStatus,
    refetchStatus,
    checkLeadStatus,
    sortBy,
    setSortBy,
    sortOrder,
    setSortOrder,
    tab,
    trackerId,
    isDelete,
    isRB2B,
  } = props;

  const { campaignId, setLeadInfo } = useCampaignStore();
  const { linkedinAccountDetails } = useAccountStore();
  const [leadsData, setLeadsData] = useState([]);
  const [companyLogoMap, setCompanyLogoMap] = useState({});
  const [campaignLeadsData, setCampaignLeadsData] = useState([]);
  const [totalLeads, setTotalLeads] = useState();
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [paginationData, setPaginationData] = useState({
    limit: 20,
    page: 1,
  });

  let campaignParams = {
    campaign_id: campaignId,
    limit: paginationData?.limit,
    sort_by: sortBy,
    sort_order: sortOrder,
    page: paginationData?.page,
  };

  const { data: campaignData, refetch } = useFetchCampaignLeads(campaignParams);

  const toggleCheckbox = (index, item) => {
    setCheckedStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      return newStates;
    });

    setSelectedLeadIds((prevIds) => {
      if (!checkedStates[index]) {
        return [...prevIds, item.lead_id];
      } else {
        return prevIds.filter((id) => id !== item.lead_id);
      }
    });
  };

  useEffect(() => {
    refetch();
    if (tableRef?.current) {
      tableRef.current.scrollTop = 0;
    }
  }, [sortBy, sortOrder]);

  useEffect(() => {
    if (campaignData) {
      setLeadInfo(campaignData?.leads_available_info);
      setLeadsData(campaignData);
      if (paginationData.page === 1) {
        setCampaignLeadsData(campaignData?.leads_data);
        if (campaignData?.leads_data?.length >= 0) {
          setIsLoading(false);
        }
      }
      setTotalLeads(campaignData?.pagination?.total_records);
      setAvailableLeads(
        campaignData?.get_prospect_and_campaign_leads_count
          ?.total_leads_in_list || 0
      );
      setEnrichedLeads(
        campaignData?.get_prospect_and_campaign_leads_count
          ?.unique_leads_enrolled_in_this_campaign
      );
      setRefetchLeads(() => refetch);
    }
  }, [campaignData]);

  useEffect(() => {
    if (isDelete) {
      setPaginationData({ limit: 20, page: 1 });
    }
  }, [isDelete]);

  const handleTableSort = (column) => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortBy(column);
    setSortOrder(newSortOrder);
    setPaginationData({ limit: 20, page: 1 });
    setHasMore(true);
  };

  useEffect(() => {
    if (
      campaignId &&
      tab === "leads" &&
      (!checkLeadStatus?.campaign_load_lead_status ||
        !checkLeadStatus?.prospected_status)
    ) {
      if (trackerId) {
        setLeadsData([]);
        setCampaignLeadsData([]);
      }
      const intervalId = setInterval(() => {
        refetchStatus().then((res) =>
          setIsCheckingLeadsStatus(res?.data?.prospected_status)
        );
      }, 30000);

      return () => clearInterval(intervalId);
    }
  }, [campaignId, refetchStatus, isEnrichingLeadsOpen, tab, checkLeadStatus]);

  useEffect(() => {
    if (isCheckingLeadsStatus) {
      refetch();
    }
  }, [isCheckingLeadsStatus]);

  const getCompanyLogo = (companyName) => {
    if (companyLogoMap[companyName]) {
      return companyLogoMap[companyName];
    }
    const randomLogo =
      randomCompanyLogo[Math.floor(Math.random() * randomCompanyLogo.length)];
    setCompanyLogoMap((prevMap) => ({
      ...prevMap,
      [companyName]: randomLogo.src,
    }));

    return randomLogo.src;
  };

  const fetchData = () => {
    if (totalLeads === campaignLeadsData?.length) {
      setHasMore(false);
      return;
    }
    setPaginationData((prev) => ({
      ...prev,
      limit: 20,
      page: (prev.page || 0) + 1,
    }));
  };

  useEffect(() => {
    if (paginationData.page > 1) {
      refetch()?.then((res) => {
        const newLeadsData = res?.data?.leads_data || [];
        setCampaignLeadsData((prev) => [...prev, ...newLeadsData]);
        if (
          newLeadsData.length === 0 ||
          newLeadsData.length < paginationData.limit
        ) {
          setHasMore(false);
        }
      });
    }
  }, [paginationData]);

  useEffect(() => {
    if (tab) {
      setCheckedStates([]);
      setSelectedLeadIds([]);
    }
  }, [tab]);

  return (
    <>
      <div className="modal-scroll">
        <div
          className={`round-table-scroll ${
            linkedinAccountDetails?.reconnect_status
              ? "max-h-[calc(100vh_-336px)]"
              : "max-h-[calc(100vh_-300px)]"
          } ${
            campaignLeadsData?.length &&
            !isLoading &&
            checkLeadStatus?.campaign_load_lead_status
              ? "overflow-auto border rounded-xl border-[#E4E7EC]"
              : ""
          } overflow-auto`}
          id="scrollableDiv"
          ref={tableRef}
        >
          {isLoading ? (
            <div className="w-full ">
              <LeadsTableSkeleton />
            </div>
          ) : campaignLeadsData?.length > 0 &&
            (checkLeadStatus?.campaign_load_lead_status ||
              checkLeadStatus?.prospected_status) ? (
            <InfiniteScroll
              dataLength={campaignLeadsData.length}
              next={fetchData}
              hasMore={hasMore}
              loader={
                totalLeads !== campaignLeadsData?.length && (
                  <div className="h-[50px] w-full flex items-center justify-center">
                    <div className="w-[20px] h-[20px] border-[3px] border-t-[3px] border-[#7F56D9] border-solid rounded-full animate-spin border-t-[#fff]" />
                  </div>
                )
              }
              scrollableTarget="scrollableDiv"
              style={{ height: "100%", overflow: "unset" }}
            >
              <table
                className={`min-w-full h-unset rounded-xl ${
                  leadsData?.leads_data?.length > 0
                    ? "border-[#E4E7EC] rounded-xl"
                    : ""
                }`}
              >
                <thead className="sticky top-0 !z-[40] bg-[#fff] overflow-hidden rounded-[12px]">
                  <tr className="w-full relative">
                    {tableHeader.map((header, index) => (
                      <th
                        key={index}
                        className="text-[12px] font-[500] py-[11.8px] px-[22px] text-[#475467] text-left font-inter cursor-pointer"
                      >
                        <div
                          className={`flex items-center relative ${
                            header.title === "Campaign Name"
                              ? "ml-10"
                              : "-ml-2.5"
                          }`}
                        >
                          <div
                            className={`flex items-center relative`}
                            onClick={() =>
                              header?.key && handleTableSort(header?.key)
                            }
                          >
                            <span>{header.title}</span>
                            {header.icon === "arrow" && (
                              <img
                                src={arrowDown}
                                alt="arrowDown"
                                className={`cursor-pointer ml-[4px] w-[10px] h-[10px] ${
                                  sortBy === header?.key && sortOrder === "desc"
                                    ? "rotate-180"
                                    : ""
                                }`}
                              />
                            )}
                          </div>
                          {header.icon === "help" && (
                            <img
                              src={helpIcon}
                              alt="help"
                              className="ml-[4px]"
                              data-tooltip-id="progress-tooltip"
                            />
                          )}

                          {header.title === "Lead" && (
                            <div className="!cursor-default font-[500] flex justify-center items-center text-[10px] rounded-[16px] border-[1px] ml-[6px] border-[#E9D7FE] py-[1px] px-[7px] bg-[#F9F5FF] text-[#6941C6]">
                              <span>
                                <img
                                  src={LightStars}
                                  className="w-[10px]"
                                  style={{
                                    filter: "brightness(0.6)",
                                  }}
                                  alt=""
                                />
                              </span>
                              <span className="text-[10px] ml-[2px]">
                                {totalLeads || 0} Leads
                              </span>
                            </div>
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                  <span className="w-full border-b border-[#E4E7EC] h-[1px] absolute top-full z-10 left-0" />
                </thead>
                <tbody>
                  {campaignLeadsData?.length > 0
                    ? campaignLeadsData?.map((item, rowIndex) => {
                        const logo = getCompanyLogo(item?.company);
                        return (
                          <tr
                            className=" border-b hover:bg-gray-50 cursor-pointer"
                            key={rowIndex}
                            onClick={() =>
                              setSelectedRowItem(
                                item,
                                item?.company_logo || logo
                              )
                            }
                          >
                            <td className="text-[13px] py-[8px] pl-[12px] pr-[20px] text-left  w-[550px] max-[1300px]:w-[unset]">
                              <div className="flex items-center">
                                <div
                                  onClick={(e) => e?.stopPropagation()}
                                  className="campaign-toggle mr-[12px] -mt-4"
                                >
                                  <CommonCheckbox
                                    checked={checkedStates[rowIndex]}
                                    onChange={() =>
                                      toggleCheckbox(rowIndex, item)
                                    }
                                  />
                                </div>
                                <a
                                  href={item?.linkedin_url}
                                  target="_blank"
                                  onClick={(e) => e?.stopPropagation()}
                                  rel="noreferrer"
                                  className="z-100"
                                >
                                  <img
                                    src={LinkedinIcon}
                                    className="ml-4 h-[20.11px] w-[20.11px]"
                                    alt="Linkedin Icon"
                                  />
                                </a>
                                <div className="ml-3 relative">
                                  <img
                                    src={verified}
                                    alt="verified"
                                    className="absolute z-30 w-[10.7px] h-[10.7px] right-[3.5px] bottom-[3px] "
                                  />
                                  {item?.profile_pic ? (
                                    <img
                                      src={item?.profile_pic}
                                      alt="user images"
                                      className="h-[40px] w-[40px] min-w-[40px] rounded-full"
                                    />
                                  ) : (
                                    <div className="w-[40px] min-w-[40px] h-[40px] rounded-full flex items-center justify-center text-[14px] font-[700] text-[#4409B9] bg-[#EEEDFD]">
                                      {getNameInititals(item?.name)}
                                    </div>
                                  )}
                                </div>
                                <div className="flex flex-col items-start ml-1.5 mt-0.5 w-[70%]">
                                  <p className="text-[#101828] break-words text-[13px] leading-5 font-inter font-medium">
                                    {item?.name || ""}
                                  </p>
                                  <p className="text-[#475467] break-words text-[13px] leading-5 font-inter -mt-0.5">
                                    {item?.job_title}
                                  </p>
                                </div>
                              </div>
                            </td>

                            <td className="text-[13px] py-[8px] px-[12px] text-[#252C32] text-left  w-[300px] max-[1300px]:w-[unset]">
                              <div className="flex items-center">
                                <div className="relative">
                                  <img
                                    src={item?.company_logo || logo}
                                    alt="user images"
                                    className={`h-[40px] w-[40px] min-w-[40px] ${
                                      item?.company_logo && "rounded-full"
                                    }`}
                                  />
                                </div>
                                <div className="flex flex-col items-start ml-1.5 mt-0.5">
                                  <p className="text-[#101828] break-words text-[13px] leading-5 font-inter font-medium">
                                    {item?.company}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td className="text-[13px] py-[8px] px-[12px] text-[#252C32] text-left  w-[300px] max-[1300px]:w-[unset]">
                              <div className="w-[200px]">
                                <ProgressBar
                                  percentage={item?.progress}
                                  activeGreenColor={true}
                                  isActionFailed={
                                    item?.status === "ACTION FAILED"
                                      ? true
                                      : false
                                  } // Mark this progress bar as empty with N/A
                                />
                              </div>
                            </td>
                            <td className="py-[5px] pr-[22px] pl-[10px] text-left w-[350px] max-[1300px]:w-[unset]">
                              {item?.status && (
                                <p className="px-[6px] py-[2px] border border-[#D0D5DD] text-[12px] font-[500]  rounded-[6px] font-inter inline-flex items-center">
                                  {renderStatus(item?.status)}
                                </p>
                              )}
                            </td>
                            <td className="text-[13px] py-[8px] px-[12px] text-[#252C32] text-left  w-[280px] max-[1300px]:w-[unset]">
                              <p className="text-[#475467] break-words text-[13px] font-inter">
                                {moment(item?.last_action_ts).format(
                                  "MM/DD/YYYY [at] hh:mm A"
                                )}
                              </p>
                            </td>
                          </tr>
                        );
                      })
                    : ""}
                </tbody>
              </table>
            </InfiniteScroll>
          ) : !(
              checkLeadStatus?.campaign_load_lead_status ||
              checkLeadStatus?.prospected_status
            ) ? (
            <div className="flex items-center justify-center h-[82%] w-full mt-[180px] mb-[30px]">
              <div className="bg-white p-[24px] flex flex-col items-center rounded-lg z-10 w-full max-w-[270px] relative">
                <div className="enrich-animation-img mb-[20px]">
                  {/* enrich animation-image */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    height="54"
                    width="54"
                  >
                    <rect height="24" width="24"></rect>
                    <path
                      stroke-linecap="round"
                      stroke-width="1.5"
                      stroke="#000"
                      d="M19.25 9.25V5.25C19.25 4.42157 18.5784 3.75 17.75 3.75H6.25C5.42157 3.75 4.75 4.42157 4.75 5.25V18.75C4.75 19.5784 5.42157 20.25 6.25 20.25H12.25"
                      className="board"
                    ></path>
                    <path
                      d="M9.18748 11.5066C9.12305 11.3324 8.87677 11.3324 8.81234 11.5066L8.49165 12.3732C8.47139 12.428 8.42823 12.4711 8.37348 12.4914L7.50681 12.8121C7.33269 12.8765 7.33269 13.1228 7.50681 13.1872L8.37348 13.5079C8.42823 13.5282 8.47139 13.5714 8.49165 13.6261L8.81234 14.4928C8.87677 14.6669 9.12305 14.6669 9.18748 14.4928L9.50818 13.6261C9.52844 13.5714 9.5716 13.5282 9.62634 13.5079L10.493 13.1872C10.6671 13.1228 10.6671 12.8765 10.493 12.8121L9.62634 12.4914C9.5716 12.4711 9.52844 12.428 9.50818 12.3732L9.18748 11.5066Z"
                      className="star-2"
                    ></path>
                    <path
                      d="M11.7345 6.63394C11.654 6.41629 11.3461 6.41629 11.2656 6.63394L10.8647 7.71728C10.8394 7.78571 10.7855 7.83966 10.717 7.86498L9.6337 8.26585C9.41605 8.34639 9.41605 8.65424 9.6337 8.73478L10.717 9.13565C10.7855 9.16097 10.8394 9.21493 10.8647 9.28335L11.2656 10.3667C11.3461 10.5843 11.654 10.5843 11.7345 10.3667L12.1354 9.28335C12.1607 9.21493 12.2147 9.16097 12.2831 9.13565L13.3664 8.73478C13.5841 8.65424 13.5841 8.34639 13.3664 8.26585L12.2831 7.86498C12.2147 7.83966 12.1607 7.78571 12.1354 7.71728L11.7345 6.63394Z"
                      className="star-1"
                    ></path>
                    <path
                      className="stick"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      stroke="#000"
                      d="M17 14L21.2929 18.2929C21.6834 18.6834 21.6834 19.3166 21.2929 19.7071L20.7071 20.2929C20.3166 20.6834 19.6834 20.6834 19.2929 20.2929L15 16M17 14L15.7071 12.7071C15.3166 12.3166 14.6834 12.3166 14.2929 12.7071L13.7071 13.2929C13.3166 13.6834 13.3166 14.3166 13.7071 14.7071L15 16M17 14L15 16"
                    ></path>
                  </svg>
                </div>
                <h4 className="font-[600] text-center text-[16.5px] text-[#101828] mb-1.5">
                  Enriching Leads
                </h4>
                <div className="max-w-[248px]">
                  <p className="font-normal text-center text-[12.8px] leading-[18px] text-[#475467] ">
                    This may take a few minutes…
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex items-center justify-center h-[calc(100vh_-400px)] w-full">
              <EmptyPage
                title="No Leads found"
                description=""
                whiteButtonTitle="Find Leads"
                whiteButtonClass="!w-[128px]"
                primaryButtonTitle="New Campaign"
                primaryButtonClass="!w-[180px]"
                showIcon={false}
                showButtons={false}
                isRB2B={isRB2B}
              />
            </div>
          )}
        </div>
      </div>
      {/* progress tooltip */}
      <CommonReactTooltip
        id="progress-tooltip"
        placement="top"
        icon={Stars}
        title="Lead Progress"
        description="See the percentage of campaign steps that are completed for the lead."
        className="!w-[239px]"
      />
    </>
  );
};

export default FounderSfTable;
