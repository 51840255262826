import DashboardSkeleton from "components/skeletonScreens/dashboardSkeleton";
import Loader from "components/loader";
import { TimeFrameOptions } from "constant";
import { useState } from "react";

const DashBoardCards = ({
  handleTimeFrameChange,
  timeFrame,
  activityData,
  isLoading,
  isDropdownOpen,
  setIsDropdownOpen,
}) => {
  const [currentIndexed, setCurrentIndexed] = useState(null);

  return (
    <div className="flex justify-between flex-wrap relative w-full">
      {isDropdownOpen && (
        <div
          className="fixed top-0 left-0 z-30 bg-transparent w-full h-full"
          onClick={() => setIsDropdownOpen(false)}
        />
      )}
      {!isLoading ? (
        activityData?.map((item, index) => {
          return (
            <div
              key={index}
              className="ulShadow min-h-[156px] w-[32%] border border-[#E4E7EC38] rounded-[11.2px] mb-[29.5px]"
              style={{
                background:
                  "linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, #000000 100%)",
              }}
            >
              <div className="p-[22.48px] relative">
                <h4 className="font-[500] text-[#D8D8D8] font-inter text-[14px] mb-[7.49px] leading-[18.74px]">
                  {item?.name}
                </h4>
                <h3 className="font-[600] text-[#D8D8D8] font-inter text-[30px] leading-[35.6px]">
                  {item?.value}
                </h3>
              </div>
              <div className="px-[22.48px] py-[14.99px] border-t border-[#E4E7EC38] flex items-center justify-end">
                <button
                  className="text-[#D8D8D8] text-left text-[14px] font-[600] font-inter relative cursor-pointer outline-none border-0"
                  onClick={() => {
                    setCurrentIndexed(index);
                    setIsDropdownOpen(!isDropdownOpen);
                  }}
                >
                  <span className="capitalize">
                    {
                      TimeFrameOptions?.find(
                        (item) => item?.value === timeFrame
                      )?.label
                    }
                  </span>
                  {isDropdownOpen && currentIndexed === index && (
                    <ul className="absolute mt-2 top-full z-50 bg-[#000000] border-[1px] border-[#FFFFFF26] rounded-[9.37px] w-[150px] py-[6px]">
                      {TimeFrameOptions.map((option, index) => (
                        <li
                          key={index}
                          onClick={() => handleTimeFrameChange(option?.value)}
                          className={`cursor-pointer text-left text-[#D8D8D8] px-[22px] font-[500] text-[13.1px] py-1 ${
                            timeFrame === option?.value && "!text-[#6941C6]"
                          }`}
                        >
                          {option?.label}
                        </li>
                      ))}
                    </ul>
                  )}
                </button>
              </div>
            </div>
          );
        })
      ) : (
        <DashboardSkeleton />
      )}
    </div>
  );
};

export default DashBoardCards;
