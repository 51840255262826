import { useAccountStore } from "stores";

const ReconnectBanner = () => {
  const { linkedinAccountDetails } = useAccountStore();
  const isDisconnected = linkedinAccountDetails?.reconnect_status
  // if (isDisconnected) {

  // Todo: Jitin reversed the flag due to error. Not sure if this frontend or backend.
  // let isDisconnected_ = false
  if (isDisconnected) {
    return (
      <div className="font-regular mt-[10px] w-[98%] m-auto h-[50px] mb-[10px] block rounded-lg bg-red-500 p-4 text-[14px] leading-5 text-white opacity-100">
        Your LinkedIn has been disconnected from Pipeline. Please re-connect in your Accounts tab.
      </div>
    );
  }
  return null;
};

export default ReconnectBanner;
