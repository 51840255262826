import { components } from "react-select";
import downloadIcon from "assets/images/chevron-down.svg";

const CustomDropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={downloadIcon} alt="info img" className="h-[20px] w-[20px]" />
    </components.DropdownIndicator>
  );
};

export default CustomDropdownIndicator;
