import Button from "components/button";
import thumbIcon from "assets/images/thumbs-down.svg";
import { IoClose } from "react-icons/io5";
import { useEffect, useState } from "react";

const SettingDataNotMapping = ({ onClose, isOpen, setStep }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [isOpen]);

  if (!isOpen && !isModalVisible) return null;

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[300ms] ease-in-out ${
        isModalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className="fixed inset-0 bg-black opacity-10"
        onClick={onClose}
      ></div>
      <div
        className={`bg-white p-[24px] rounded-lg modal-shadow z-10 w-full max-w-[600px] relative transition-all ease-in-out duration-[150ms] ${
          isModalVisible ? "scale-100" : "scale-95"
        }`}
      >
        <IoClose
          className="text-[#667085] text-[20px] w-[24px] h-[24px] absolute right-[24px] top-[26px] cursor-pointer"
          onClick={onClose}
        />
        <div className="modal-scroll">
          <div className="flex flex-col items-start justify-start mb-[20px] font-inter">
            <img
              src={thumbIcon}
              alt="info img"
              className="h-[48px] w-[48px] mb-[16px]"
            />
            <h4 className="font-[600] text-[18px] text-[#101828] mb-[9px] font-inter">
              Your data could not be mapped.
            </h4>
            <p className="font-[700] text-[14px] text-[#475467] mb-[7px] font-inter">
              Please try again.
            </p>
          </div>
          <div className="flex items-center justify-center mt-[32px]">
            <Button
              title="Back to Data Mapping"
              isWhiteBtn={true}
              onClick={() => {
                onClose();
                setStep(3);
              }}
              className="!text-[16px] !h-[44px] !font-[600] !text-[#344054] y-axis-shadow !rounded-[8px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingDataNotMapping;
