import { useMutation, useQuery } from "@tanstack/react-query";
import axiosInt from "services";

const useAmpersandInstall = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt?.post(`/hubspot/ampersand_install`, data);
      return response;
    },
  });

export { useAmpersandInstall };
