import { createBrowserRouter } from "react-router-dom";
import AuthLayout from "./auth-layout";
import ProtectedLayout from "./protected-layout";
import SignIn from "pages/auth/signIn";
import SignUp from "pages/auth/signUp";
import ForgotPassword from "pages/auth/forgot-password";
import EmailVerification from "pages/auth/emailVerification";
import GetStarted from "pages/auth/getStarted";
import ConnectLinkedIn from "pages/auth/connectLinkedIn";
import Dashboard from "pages/dashboard";
import FindLeads from "pages/findLeads";
import Prospecting from "pages/prospecting";
import SearchResult from "pages/searchResult";
import Campaigns from "pages/campaign";
import NewCampaigns from "pages/newCampaign";
import Settings from "pages/settings";
import CampaignStepsAnimation from "pages/campaignStepsAnimation";
import UpgradeLinkedIn from "pages/auth/upgradeLinkedIn";
import Results from "pages/results";
import DefaultActivity from "pages/defaultActivity";
import Accounts from "pages/accounts";
import Waiting from "pages/auth/waiting";
import InviteSignup from "pages/auth/inviteSignup";
import NotFoundPage from "pages/notFound";
import Connections from "pages/connections/page";
import CreateWorkspace from "pages/auth/workspace";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      { path: "/", element: <SignIn /> },
      { path: "/signup", element: <SignUp /> },
      { path: "/forgot-password", element: <ForgotPassword /> },
      { path: "/email-verification", element: <EmailVerification /> },
      { path: "/started", element: <GetStarted /> },
      { path: "/connect-linkedIn", element: <ConnectLinkedIn /> },
      { path: "/auth", element: <InviteSignup /> },
      { path: "/create-workspace", element: <CreateWorkspace /> },
    ],
  },
  {
    path: "/",
    element: <ProtectedLayout />,
    children: [
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      { path: "/upgrade-linkedIn", element: <UpgradeLinkedIn /> },
      { path: "/waiting", element: <Waiting /> },
      { path: "/findLeads", element: <FindLeads /> },
      { path: "/prospecting", element: <Prospecting /> },
      { path: "/connections", element: <Connections /> },
      { path: "/prospecting/:id", element: <SearchResult /> },
      { path: "/campaigns", element: <Campaigns /> },
      { path: "/new-campaign", element: <NewCampaigns /> },
      { path: "/settings", element: <Settings /> },
      { path: "/campaign-steps", element: <CampaignStepsAnimation /> },
      { path: "/results", element: <Results /> },
      { path: "/default-activity", element: <DefaultActivity /> },
      { path: "/accounts", element: <Accounts /> },
      { path: "*", element: <NotFoundPage /> },
    ],
  },
]);
