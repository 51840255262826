import Button from "components/button";
import readImg from "assets/images/please-read.svg";
import { useEffect, useState } from "react";

const NotificationToggleModal = (props) => {
  const { isOpen, onClose } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [isOpen]);

  if (!isOpen && !isModalVisible) return null;

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[300ms] ease-in-out ${
        isModalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className="fixed inset-0 bg-black opacity-10"
        onClick={onClose}
      ></div>
      <div
        className={`bg-white p-[24px] rounded-lg modal-shadow z-10 w-full max-w-[400px] relative transition-all ease-in-out duration-[150ms] ${
          isModalVisible ? "scale-100" : "scale-95"
        }`}
      >
        <div className="modal-scroll">
          <div className="flex flex-col items-start justify-start mb-[20px] font-inter">
            <div className="flex items-center justify-center mb-[16px]">
              <img src={readImg} alt="info img" className="h-[48px] w-[48px]" />
            </div>
            <h4 className="font-[600] text-[18px] text-[#101828] mb-[16px] font-inter">
              Please Read...
            </h4>
            <p className="font-[400] text-[14px] text-[#475467] font-inter">
              By turning off these notifications, you might miss important
              information regarding your account that may prevent your campaigns
              from running.
            </p>
          </div>
          <div className="flex items-center justify-center mt-[32px]">
            <Button
              title="Got it"
              secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9]"
              className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow !rounded-[8px]"
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationToggleModal;
