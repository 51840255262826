import { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify"; // Import toast library
import Button from "components/button";
import sentIcon from "assets/images/sent.svg";
import mailIcon from "assets/images/mail.svg";
import OutlinedInput from "components/outlinedInput";
import errorIcon from "assets/images/error.svg";
import CommonCheckbox from "components/commonCheckbox";

const SentInvitationModal = (props) => {
  const {
    isOpen,
    onClose,
    setAddCollaborateTeamModal,
    manageSeatsInput,
    setManageSeatsInput,
    handleClear,
    addCollaborators,
    handleAddCollaborator,
  } = props;
  const [errors, setErrors] = useState({});
  const [isChecked, setIsChecked] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [isOpen]);

  if (!isOpen && !isModalVisible) return null;

  const handleInputChange = (index, value) => {
    setManageSeatsInput((prev) => {
      const updatedSeats = [...prev];
      updatedSeats[index] = { value };
      return updatedSeats;
    });

    if (value.trim() === "") {
      setErrors((prev) => {
        const newErrors = { ...prev };
        delete newErrors[index];
        return newErrors;
      });
    } else {
      validateEmail(index, value);
    }
  };

  const validateEmail = (index, email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const newErrors = { ...errors };

    if (!emailPattern.test(email)) {
      newErrors[index] = "Please enter a valid email.";
    } else {
      delete newErrors[index];
    }

    setErrors(newErrors);
  };

  const handlePurchase = () => {
    if (!isChecked) {
      return toast.error(
        "You must agree to the Terms of Use and Privacy Policy before proceeding."
      );
    }
    const allEmailsValid = manageSeatsInput.every((seat, index) => {
      return seat.value.trim() !== "" && !errors[index];
    });

    if (!allEmailsValid) {
      // Show toast notification for invalid emails
      toast.error("All emails must be valid.");
    } else {
      // Proceed with purchase
      handleAddCollaborator();
      // onClose();
      // setTeamPurchaseSuccessfulModal(true);
    }
  };

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[300ms] ease-in-out ${
        isModalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className="fixed inset-0 bg-black opacity-10"
        onClick={handleClear}
      ></div>
      <div
        className={`bg-white rounded-lg shadow-lg z-10 w-full max-w-[480px] relative modal-scrolltransition-all ease-in-out duration-[150ms] ${
          isModalVisible ? "scale-100" : "scale-95"
        }`}
      >
        <IoClose
          className="text-[#667085] text-[20px] w-[24px] h-[24px] absolute right-[24px] top-[22px] cursor-pointer"
          onClick={handleClear}
        />
        <div className="modal-scroll">
          <div className="p-[24px] flex items-center border-b-[1px] border-[#E4E7EC]">
            <div
              className="flex justify-center items-center border-[#E4E7EC] border w-[48px] h-[48px] rounded-[10px] mr-[16px]"
              style={{
                boxShadow:
                  "0px 4px 8px 0px rgba(16, 24, 40, 0.05), 0px 2px 0px 0px rgba(16, 24, 40, 0.05), 0px 0px 1px 0px rgba(16, 24, 40, 0.18)",
              }}
            >
              <img src={sentIcon} alt="coins" className="h-[22px] w-[22px]" />
            </div>
            <div>
              <h6 className="text-[#101828] font-[600] text-[18px] leading-[28px]">
                Send Invitation
              </h6>
              <p className="font-[400] text-[14px] text-[#475467]">
                Enter your teammates emails to invite them.
              </p>
            </div>
          </div>
          <div className="max-w-[400px] mx-auto mt-[19px] max-h-[318px] overflow-auto modal-scroll flex justify-center">
            <div className="w-[320px]">
              {manageSeatsInput.map((item, index) => {
                return (
                  <div key={index} className="w-full">
                    <p className="font-inter text-[14px] font-[500] text-[#344054] leading-[20px] mb-[6px]">
                      Invite {index + 1}{" "}
                      <span className="text-[#7F56D9]">*</span>
                    </p>
                    <div className="relative mb-[22px]">
                      <OutlinedInput
                        type="text"
                        value={manageSeatsInput[index]?.value || ""}
                        onChange={(e) =>
                          handleInputChange(index, e?.target?.value)
                        }
                        className={`pr-[12px] pl-[40px] py-[8px] !w-[320px] h-[44px] ${
                          errors[index] ? "border-[2px] !border-[#F04438]" : ""
                        }`}
                      />
                      <img
                        src={mailIcon}
                        alt="mail"
                        className="w-[20px] h-[20px] absolute left-[12px] top-[12px]"
                      />
                      {errors[index] && (
                        <p className="mt-[6px] font-[400] text-[14px] text-[#F04438]">
                          {errors[index]}
                        </p>
                      )}
                      {errors[index] && (
                        <img
                          src={errorIcon}
                          alt="error"
                          className="w-[18px] h-[18px] absolute right-[13px] top-[12px]"
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex flex-col items-center justify-center p-[24px] pt-0 mt-[30px]">
            <div className="flex w-full mb-[17px]">
              <div
                onClick={(e) => e.stopPropagation()}
                className="w-[10px] h-[10px] mr-[20px] creditCheckbox"
              >
                <CommonCheckbox
                  checked={isChecked}
                  onChange={() => setIsChecked(!isChecked)}
                />
              </div>
              <p className="text-[#101828] font-[500] text-[12px] -mt-1">
                By clicking Purchase, you agree to be bound by our{" "}
                <a
                  href="https://withpipeline.com/tos"
                  target="_blank"
                  rel="noreferrer"
                  className="underline cursor-pointer"
                >
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  href="https://withpipeline.com/privacy"
                  target="_blank"
                  rel="noreferrer"
                  className="underline cursor-pointer"
                >
                  Privacy Policy
                </a>
                .
              </p>
            </div>
            <div className="flex w-full flex-row">
              <Button
                title="Back"
                isWhiteBtn={true}
                onClick={() => {
                  onClose();
                  setAddCollaborateTeamModal(true);
                }}
                className="!text-[16px] !h-[44px] !font-[600] !text-[#344054] y-axis-shadow !rounded-[8px]"
              />
              <Button
                title={
                  addCollaborators?.isPending ? (
                    <div className="w-[20px] h-[20px] border-[3px] mx-auto border-t-[3px] border-[#fff] border-solid rounded-full animate-spin border-t-[#8A43FF]" />
                  ) : (
                    "Purchase"
                  )
                }
                secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9] ml-[12px]"
                className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow !rounded-[8px]"
                onClick={handlePurchase}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SentInvitationModal;
