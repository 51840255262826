import { useState } from "react";
import AIGenerateSteps from "./aIGenerateSteps";
import FollowUpMessage from "./followUp";
import LikeComment from "./likeComment";
import LikePost from "./likePost";
import MessageExistingConnection from "./messageExistingConnection";
import SendConnectionRequest from "./sendconnectionrequest";
import StartCampaign from "./startcampaign";
import ViewProfile from "./viewprofile";
import WaitingPeriod from "./waitingperiod";
import Delete from "assets/images/delete.svg";

// Your existing CSS class
const stepComponents = {
  "Start Campaign": StartCampaign,
  "View Profile": ViewProfile,
  "Waiting Period": WaitingPeriod,
  "Send Connection Request": SendConnectionRequest,
  "Follow-up Message": FollowUpMessage,
  "Message Existing Connection": MessageExistingConnection,
  "Like a Post": LikePost,
  "Like a Comment": LikeComment,
  "AI Generate Steps": AIGenerateSteps,
};

const CurrentCampaign = ({
  steps,
  handleDeleteStep,
  isNewCampaignDeleted,
  handleStepValueChange,
  deleteCampaignSequence,
}) => {
  console.log({ steps });
  const [deletingStepIndex, setDeletingStepIndex] = useState(null);

  const handleDeleteWithAnimation = (index) => {
    setDeletingStepIndex(index);
    handleDeleteStep(steps[index], index);
  };
  return (
    <div className="flex justify-center w-full flex-col items-center">
      {steps.map((step, index, arr) => {
        const StepComponent = stepComponents[step?.title];
        const isDeleting = deletingStepIndex === index;
        //          ${
        //   isDeleting && deleteCampaignSequence?.isPending ? "animate-fadeOut" : ""
        // }
        return StepComponent ? (
          <div className="flex items-center" key={index}>
            <div
              className={`flex flex-col items-center ${
                arr.length - 1 === index &&
                isNewCampaignDeleted &&
                "transition-opacity duration-200 ease-in-out opacity-0 animate-fadeIn"
              }
              `}
            >
              <StepComponent
                value={step?.value}
                handleStepValueChange={handleStepValueChange}
                index={index}
              />
              {index < steps.length - 1 && (
                <div className="h-[34px] w-[1.5px] bg-[#C6C5C6]" />
              )}
            </div>
            {index > 1 && step.title !== "AI Generate Steps" ? (
              <>
                {deleteCampaignSequence?.isPending && isDeleting ? (
                  <div className="w-[12px] ml-[22px] h-[12px] border-[2px] border-t-[3px] border-[#fff] border-solid rounded-full animate-spin border-t-[#8A43FF]" />
                ) : (
                  <img
                    src={Delete}
                    className={`${
                      index < steps.length - 1 ? "mt-[-34px]" : ""
                    } ml-[18px] cursor-pointer`}
                    alt="Delete"
                    onClick={() => handleDeleteWithAnimation(index)}
                  />
                )}
              </>
            ) : (
              <span className="w-[20px] ml-[18px]"></span>
            )}
          </div>
        ) : null;
      })}
    </div>
  );
};

export default CurrentCampaign;
