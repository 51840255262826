import { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";

const InsertDropdown = ({
  position,
  options,
  initialName,
  removeArrowLine = false,
  setSelectedOption,
}) => {
  const [menu, setMenu] = useState(false);
  return (
    <div className="relative">
      <button
        className={`border-[1px] border-[#D1D5DB] rounded-[6px] flex items-center bg-white ${
          removeArrowLine ? "min-w-[180px]" : ""
        }`}
        onClick={() => setMenu(!menu)}
      >
        <p
          className={`text-[#111827]  ${
            removeArrowLine ? "text-[13px]" : "text-[10px]"
          } font-[500] m-0 capitalize 
          ${removeArrowLine ? "py-[6px] " : "py-[4px] "} px-[7px] ${
            removeArrowLine ? "" : "border-r-[1px]"
          } border-[#D1D5DB] flex-grow`}
        >
          {initialName?.campaign_name}
        </p>
        <div
          className={`${
            removeArrowLine ? "py-[8px]" : "p-[4px]"
          } flex items-center justify-center w-[20px] rounded-[0] ${
            menu && "bg-[#9A98981F]"
          }`}
        >
          <IoIosArrowDown className="text-[#9CA3AF] h-[16px] w-[16px] text-[12px]" />
        </div>
      </button>
      {menu && (
        <>
          <div
            className="fixed top-0 left-0 w-full h-full z-50"
            onClick={() => setMenu(false)}
          />
          <div
            className={`${
              removeArrowLine ? "w-[165px]" : " w-[120px]"
            }  py-[3px] bg-[#fff] border-[1px] border-[#D1D5DB] rounded-[6px] 
            absolute actionButton-shadow z-50 mt-[8px] ${
              position === "top" ? "bottom-[30px]" : ""
            } ${
              position === "top"
                ? "left-[-5px]"
                : removeArrowLine
                ? "left-0"
                : "right-[6px]"
            } `}
          >
            <ul>
              {options.length ? (
                options?.map((option, index) => {
                  return (
                    <li
                      key={index}
                      className={`flex items-center capitalize px-[16px] py-[8px] cursor-pointer ${
                        removeArrowLine ? "text-[13px]" : "text-[10px]"
                      } 
                   font-[400] hover:bg-[#cccccc1c] ${
                     initialName?.campaign_name === option?.campaign_name
                       ? "!font-[500] text-[#111827]"
                       : "text-[#6B7280]"
                   }`}
                      onClick={() => {
                        setSelectedOption(option);
                        setMenu(false);
                      }}
                    >
                      {option?.campaign_name}
                    </li>
                  );
                })
              ) : (
                <li
                  onClick={() => {
                    setMenu(false);
                  }}
                  className="flex items-center capitalize px-[16px] py-[8px] cursor-pointer text-[13px] !font-[500] text-[#111827]"
                >
                  No Campaign found
                </li>
              )}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default InsertDropdown;
