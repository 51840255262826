import React, { useEffect, useState } from "react";
import Select from "react-select";
import { generateYearsArray } from "utils/";

// Define custom styles for the Select component
const customStyles = {
  placeholder: (provided) => ({
    ...provided,
    fontSize: "16px",
    color: "#6670854D",
    marginTop: "5px",
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: "16px",
    color: "#101828",
    display: "flex",
    alignItems: "center",
    gap: "8px",
  }),
  valueContainer: (provided) => ({
    ...provided,
    fontSize: "16px",
    color: "#101828",
  }),
  option: (provided) => ({
    ...provided,
    fontSize: "16px",
    color: "#667085",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    marginTop: "-3.4px",
    height: "44.5px",
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: "16px",
  }),
};

// Custom filter function to allow only numeric input
const numericFilter = (option, searchValue) => {
  if (!searchValue) return true;
  const searchNumber = parseInt(searchValue, 10);
  if (isNaN(searchNumber)) return false; // Filter out non-numeric search values
  return option.value.toString().includes(searchNumber.toString());
};

const DateFounded = (props) => {
  const { formData, setFormData } = props;

  const [minYear, setMinYear] = useState(
    formData?.year_founded_start?.value ? formData?.year_founded_start : null
  );
  const [maxYear, setMaxYear] = useState(formData?.year_founded_end?.value ?  formData?.year_founded_end : null);
  const [errorMessage, setErrorMessage] = useState("");

  // Update state when formData changes
  useEffect(() => {
    setMinYear(
      formData?.year_founded_start?.value ? formData?.year_founded_start : null
    );
    setMaxYear(
      formData?.year_founded_end?.value ? formData?.year_founded_end : null
    );
  }, [formData]);

  useEffect(() => {
    if (minYear && maxYear) {
      if (minYear.value > maxYear.value) {
        setErrorMessage("Max year should be greater than Min year.");
      } else if (minYear.value === maxYear.value && ( minYear?.value !== null || maxYear?.value !== null)) {
        setErrorMessage("Min and Max year should not be the same.");
      } else {
        setErrorMessage("");
      }
    } else {
      setErrorMessage("");
    }
  }, [minYear, maxYear]);

  const handleMinYearChange = (selectedOption) => {
    setMinYear(selectedOption);
    setFormData({
      ...formData,
      year_founded_start: selectedOption,
    });
  };

  const handleMaxYearChange = (selectedOption) => {
    setMaxYear(selectedOption);
    setFormData({
      ...formData,
      year_founded_end: selectedOption,
    });
  };

  // Generate full options list for both minYear and maxYear
  const yearOptions = generateYearsArray(1900, 2024).reverse();
  return (
    <div className="w-full flex flex-col">
      <div className="w-full flex flex-row justify-between">
        <div className="w-[40%] max-w-[172px] flex items-center">
          <div className="w-full">
            <Select
              onChange={handleMinYearChange}
              options={yearOptions}
              classNamePrefix="leads-react-select"
              className="!font-inter w-full"
              value={minYear}
              placeholder="Year"
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              maxMenuHeight="128px"
              styles={customStyles}
              isClearable
              isSearchable={true} // Allow searching
              filterOption={numericFilter} // Apply custom filter function
              noOptionsMessage={() => "No Results"}
            />
          </div>
        </div>
        <p className="mt-2 text-[#667085] ml-1">to</p>
        <div className="w-[40%] max-w-[172px] flex items-center">
          <div className="w-full">
            <Select
              onChange={handleMaxYearChange}
              options={yearOptions}
              classNamePrefix="leads-react-select"
              className="!font-inter w-full"
              value={maxYear}
              placeholder="Year"
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              maxMenuHeight="128px"
              styles={customStyles}
              isClearable
              isSearchable={true} // Allow searching
              filterOption={numericFilter} // Apply custom filter function
              noOptionsMessage={() => "No Results"}
            />
          </div>
        </div>
      </div>
      {errorMessage && (
        <p className="text-red-600 text-sm mt-2">{errorMessage}</p>
      )}
    </div>
  );
};

export default DateFounded;
