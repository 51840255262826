import { useContext, useEffect, useRef, useState } from "react";
import bell from "assets/images/bell.svg";
import clockActivity from "assets/images/notification-text.svg";
import whiteClockActivity from "assets/images/notification-text-white.svg";
import SearchIcon from "assets/images/Notification.svg";
import { useNavigate } from "react-router-dom";
import ActivityFeedModal from "./activityFeedModal";
import NotificationModal from "components/notificationModal";
import HeaderSearchModal from "components/headerSearchModal";
import pipelineCoin from "assets/images/pipelineStar.png";
import ReviewSidePanel from "components/reviewSidePanel";
import { useWorkSpaceCreditLimit } from "services/accounts-api";
import { useAccountStore, useAuthStore, useCampaignStore, useSettingStore } from "stores";
import { AppContext } from "context/AppContext";
import { formatNumberWithCommas, getRandomColor } from "utils/index";
import { FaAngleRight } from "react-icons/fa6";
import { AiFillHome } from "react-icons/ai";
import AddEnrichCreditOptions from "components/setting/purchaseModals/addEnrichCreditOptions";
import AddedPurchasedCredit from "components/setting/purchaseModals/addedPurchaseCredit";
import PurchasedSuccessModal from "components/setting/purchaseModals/purchaseSuccessfulModal";
import { purchaseCreditInfo } from "constant";

const ProspectingBreadcrumbHeader = ({
  breadcrumbItems,
  blackTheme = false,
  setTab,
}) => {
  const { setAppState } = useContext(AppContext);
  const { notificationCount, activityCount } = useSettingStore();
  const { workspaceCreditLimit, setWorkSpaceCreditLimit } = useAccountStore();
  const { clearIsDraftCampaignClicked, clearTrackerId, clearDraftListId ,clearOpenLeadsTabDirectly } =
    useCampaignStore();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNotificationModalVisible, setIsNotificationModalVisible] =
    useState(false);
  const [isSearchModal, setIsSearchModal] = useState(false);
  const modalRef = useRef(null);
  const clockIconRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [openModal, setOpenModal] = useState(null);
  const [seltectedRowItem, setSelectedRowItem] = useState(null);
  const modalRandomGradient = getRandomColor();
  const [isPurchaseCreditModal, setIsPurchaseCreditModal] = useState(false);
  const [storePurchaseCredit, setStorePurchaseCredit] = useState(
    purchaseCreditInfo[0]
  );
  const [isStoredPurchaseModal, setIsStoredPurchaseModal] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(purchaseCreditInfo[0]);
  const [packageName, setPackageName] = useState("package_1");
  const [purchaseSuccessfulModal, setPurchaseSuccessfulModal] = useState(false);
  const [creditsAdded, setCreditsAdded] = useState(0);
  const { isAdmin } = useAuthStore();
  const { data } = useWorkSpaceCreditLimit({ isEnabled: true });

  useEffect(() => {
    if (data?.credit_limit) {
      setWorkSpaceCreditLimit(data?.credit_limit);
    } else {
      setWorkSpaceCreditLimit(0);
    }
  }, [data]);

  const handleClockClick = () => {
    setIsVisible(!isVisible);
  };

  const handleBellClick = () => {
    setIsNotificationModalVisible(!isNotificationModalVisible);
  };

  const handleSearch = () => {
    setIsSearchModal(!isSearchModal);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === "k") {
        event.preventDefault();
        setIsSearchModal(true);
      }
      if (event.key === "Escape") {
        setIsSearchModal(false);
      }
    };

    const handleClickOutside = (event) => {
      if (
        isVisible &&
        modalRef.current &&
        !modalRef.current.contains(event.target) &&
        !clockIconRef.current.contains(event.target)
      ) {
        setTimeout(() => setIsVisible(false), 100);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isVisible]);

  return (
    <div
      className={`!border-b-[1px] border-[#00000019] py-1 px-4 w-full min-h-[45px] h-[45px] sticky top-0 z-50 ${
        !blackTheme && "bg-white"
      }`}
    >
      <div className="flex items-center justify-between h-full">
        {/* breadcrumb */}
        <div className="w-full flex items-center">
          {!blackTheme && (
            <div className="flex items-center">
              <div
                className={`${
                  blackTheme
                    ? "text-white"
                    : "text-[#252C32] font-normal text-[14px] cursor-pointer group"
                }`}
                onClick={() => navigate("/dashboard")}
              >
                <AiFillHome className="group-hover:text-[#4C20B8] inline mt-[-2.5px] mr-[8px]" />
                <span className="group-hover:text-[#4C20B8] font-normal text-[14px]">
                  Dashboard
                </span>
              </div>
              <FaAngleRight className="text-[#9E9E9E] mt-0.5 px-[10px] text-[27px]" />
            </div>
          )}
          {breadcrumbItems.map((item, index) => (
            <span key={index} className="flex items-center">
              {item.route ? (
                <span
                  className="text-[#252C32] font-normal text-[14px] font-inter cursor-pointer hover:text-[#4C20B8]"
                  onClick={() => {
                    clearTrackerId();
                    if (item?.route && item?.route === "/Campaigns") {
                      clearIsDraftCampaignClicked();
                      clearDraftListId();
                      clearOpenLeadsTabDirectly();
                      setAppState((prev) => ({
                        ...prev,
                        leadsStepCompleted: false,
                        stepsStepCompleted: false,
                        settingsStepCompleted: false,
                      }));
                      navigate(item.route);
                    } else {
                      item?.route && navigate(item.route);
                    }
                  }}
                >
                  {item.title}
                </span>
              ) : (
                <span className="text-[#9E9E9E] font-normal text-[14px] font-inter">
                  {item.title}
                </span>
              )}
              {index < breadcrumbItems.length - 1 && (
                <FaAngleRight className="text-[#9E9E9E] mt-0.5 px-[10px] text-[27px]" />
              )}
            </span>
          ))}
        </div>

        <div className="flex justify-end items-center w-full">
          <div
            className={`flex justify-center items-center mr-[14px] ${isAdmin && "cursor-pointer"}`}
            onClick={() => isAdmin && setIsPurchaseCreditModal(prev => !prev)}
          >
            <img
              src={pipelineCoin}
              alt="coin"
              className="mr-[7px] mt-[-1px] w-[27px] h-[27px]"
              style={{ filter: blackTheme && "brightness(3) invert(1)" }}
            />
            <p
              className={`font-[500] text-[14px] mt-[-1px] ${
                blackTheme ? "text-[#fff]" : "text-[#5A5A5A]"
              }`}
            >
              {formatNumberWithCommas(workspaceCreditLimit) || 0} Credits
            </p>
          </div>
          <div
            className={`cursor-pointer flex items-center justify-center w-[34.46px] h-[34.46px] rounded-[9.14px] mr-[5.6px] ${
              blackTheme
                ? " bg-[#121212] blacktheme-header-hover"
                : "bg-[#00000005] border-[#00000005] border-[1px] whiteTheme-header-hover"
            }  ${
              isSearchModal &&
              !blackTheme &&
              "border-[3px] border-[#6900FF14] !bg-[#7F56D9]"
            }`}
            style={{
              background:
                isSearchModal &&
                blackTheme &&
                "linear-gradient(180deg, rgba(105, 65, 198, 0.8) 0%, rgba(51, 10, 148, 0.8) 74%)",
            }}
            onClick={handleSearch}
          >
            <img
              src={SearchIcon}
              className="block w-[16.77px] h-[16.77px] iconBox"
              style={{
                filter: isSearchModal || blackTheme ? "brightness(1000%)" : "",
              }}
              alt="SearchIcon"
            />
          </div>
          <div className="relative">
            <div
              className={`cursor-pointer flex items-center justify-center w-[34.46px] h-[34.46px] rounded-[9.14px] mr-[5.6px] ${
                blackTheme
                  ? " bg-[#121212] blacktheme-header-hover"
                  : "bg-[#00000005] border-[#00000005] border-[1px] whiteTheme-header-hover"
              }  ${
                isNotificationModalVisible && !blackTheme
                  ? "border-[3px] border-[#6900FF14] !bg-[#7F56D9]"
                  : ""
              }`}
              style={{
                background:
                  isNotificationModalVisible &&
                  blackTheme &&
                  "linear-gradient(180deg, rgba(105, 65, 198, 0.8) 0%, rgba(51, 10, 148, 0.8) 74%)",
              }}
              onClick={handleBellClick}
            >
              <img
                src={bell}
                className="block w-[16.77px] h-[16.77px] iconBox"
                style={{
                  filter:
                    isNotificationModalVisible || blackTheme
                      ? "brightness(1000%)"
                      : "",
                }}
                alt="bell"
              />
            </div>
            {notificationCount > 0 && (
              <div className="flex justify-center items-center min-w-[16px] min-h-[16px] rounded-full bg-[#7518F8] text-[10px] leading-[12px] p-[3px] font-[600] text-white absolute right-[2px] top-[-3px]">
                {notificationCount || 0}
              </div>
            )}
          </div>
          <div className="relative">
            <div
              className={`cursor-pointer flex items-center justify-center w-[34.46px] h-[34.46px] rounded-[9.14px] ${
                blackTheme
                  ? " bg-[#121212] blacktheme-header-hover"
                  : "bg-[#00000005] border-[#00000005] border-[1px] whiteTheme-header-hover"
              }  ${
                isVisible &&
                !blackTheme &&
                "border-[3px] border-[#6900FF14] !bg-[#7F56D9]"
              }`}
              style={{
                background:
                  isVisible &&
                  blackTheme &&
                  "linear-gradient(180deg, rgba(105, 65, 198, 0.8) 0%, rgba(51, 10, 148, 0.8) 74%)",
              }}
              onClick={handleClockClick}
            >
              {!blackTheme ? (
                <img
                  ref={clockIconRef}
                  src={clockActivity}
                  className="block w-[16.77px] h-[16.77px] iconBox"
                  style={{
                    filter: isVisible || blackTheme ? "brightness(1000%)" : "",
                  }}
                  alt="clock activity"
                />
              ) : (
                <img
                  ref={clockIconRef}
                  src={whiteClockActivity}
                  style={{
                    filter: isVisible ? "brightness(1000%)" : "",
                  }}
                  className="block w-[16.77px] h-[16.77px] iconBox"
                  alt="clock activity"
                />
              )}
            </div>
            {activityCount > 0 && (
              <div className="flex justify-center items-center min-w-[16px] min-h-[16px] rounded-full bg-[#7518F8] text-[10px] leading-[12px] p-[3px] font-[600] text-white absolute right-[2px] top-[-3px]">
                {activityCount || 0}
              </div>
            )}
          </div>
        </div>
      </div>

      <div ref={modalRef} className="modal-scroll">
        <ActivityFeedModal
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          setIsModalOpen={setIsModalOpen}
          setOpenModal={setOpenModal}
        />
        <ReviewSidePanel
          isProspecting={true}
          rowData={openModal}
          setRowData={setOpenModal}
          randomGradient={modalRandomGradient}
        />
      </div>
      <div className="modal-scroll">
        <NotificationModal
          isNotificationModalVisible={isNotificationModalVisible}
          setIsNotificationModalVisible={setIsNotificationModalVisible}
        />
        <HeaderSearchModal
          isOpen={isSearchModal}
          onClose={() => {
            setIsSearchModal(false);
          }}
          setSelectedRowItem={setSelectedRowItem}
          setTab={setTab}
        />
        <ReviewSidePanel
          rowData={seltectedRowItem}
          setRowData={setSelectedRowItem}
          isProspecting={true}
          randomGradient={modalRandomGradient}
        />
        <AddEnrichCreditOptions
          isOpen={isPurchaseCreditModal}
          onClose={() => {
            setIsPurchaseCreditModal(false);
          }}
          setStorePurchaseCredit={setStorePurchaseCredit}
          setIsStoredPurchaseModal={setIsStoredPurchaseModal}
          setSelectedPackage={setSelectedPackage}
          setPackageName={setPackageName}
        />
        <AddedPurchasedCredit
          isOpen={isStoredPurchaseModal}
          onClose={() => setIsStoredPurchaseModal(false)}
          storePurchaseCredit={storePurchaseCredit}
          setIsPurchaseCreditModal={setIsPurchaseCreditModal}
          setPurchaseSuccessfulModal={setPurchaseSuccessfulModal}
          setStorePurchaseCredit={setStorePurchaseCredit}
          packageName={packageName}
          selectedPackage={selectedPackage}
          creditsAdded={creditsAdded}
          setCreditsAdded={setCreditsAdded}
        />
        <PurchasedSuccessModal
          isOpen={purchaseSuccessfulModal}
          onClose={() => setPurchaseSuccessfulModal(false)}
          setIsStoredPurchaseModal={setIsStoredPurchaseModal}
          setTab={setTab}
        />
      </div>
    </div>
  );
};

export default ProspectingBreadcrumbHeader;
