import { useNavigate } from "react-router-dom";

const Dots = (props) => {
  const { screen } = props;
  const navigete = useNavigate();
  const dots = [
    {
      label: "started",
    },
    {
      label: "connect-linkedIn",
    },
    {
      label: "waiting",
    },
  ];
  return (
    <div className="flex items-center justify-center mt-[-30px] z-50 relative max-[767px]:mt-[20px]">
      {dots?.map((item, index) => {
        return (
          <span
            className={`w-[8px] h-[8px] rounded-[4px] mx-[10px] cursor-pointer ${
              item?.label === screen ? "bg-[#C8C5C5]" : "bg-[#72727287]"
            }`}
            onClick={() => {
              switch (index) {
                case 0:
                  navigete("/started");
                  break;
                case 1:
                  navigete("/connect-linkedIn");
                  break;
                case 2:
                  navigete("/waiting");
                  break;
              }
            }}
          ></span>
        );
      })}
    </div>
  );
};

export default Dots;
