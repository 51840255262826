import CommonCheckbox from "components/commonCheckbox";
import watchIcon from "assets/images/watch.svg";

const Signals = (props) => {
  const { formData, setFormData, name, options } = props;

  const onInputChange = (event) => {
    let { name, value } = event?.target;
    value = value.replace(/^0+(?=\d)/, "");
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (label) => {
    const existingLabels = formData?.[name] || [];
    const labelIndex = existingLabels?.indexOf(label);

    const updatedLabels =
      labelIndex > -1
        ? existingLabels?.filter((existingLabel) => existingLabel !== label)
        : [...existingLabels, label];

    setFormData({
      ...formData,
      [name]: updatedLabels,
    });
  };

  const preventInvalidInput = (event) => {
    const invalidChars = [
      "-",
      "+",
      "e",
      "E",
      "/",
      "\\",
      "~",
      "_",
      "[",
      "]",
      ";",
      " ",
    ];
    const isAlphabet = /^[a-zA-Z]$/;
    const isSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

    if (
      invalidChars.includes(event.key) ||
      isAlphabet.test(event.key) ||
      isSpecialChar.test(event.key)
    ) {
      event.preventDefault();
    }
  };

  return (
    <>
      {options?.map((item, index) => {
        return (
          <div
            key={index}
            className="flex items-center mb-[7px] px-1 hover:bg-[#f5ebff] rounded-[6px]  cursor-pointer"
          >
            <div className="leads-checkbox w-[16px] h-[16px] mr-[10px] z-[999999] relative">
              <CommonCheckbox
                checked={
                  formData?.peopleSignals?.includes?.(item.label) ||
                  formData?.companySignals?.includes?.(item.label) ||
                  formData?.news.includes?.(item.label)
                }
                onChange={() => handleCheckboxChange(item.label)}
              />
            </div>
            <p
              className="text-[#344054] font-[500] text-[14px] mb-0 font-inter w-full"
              onClick={() => handleCheckboxChange(item.label)}
            >
              {item?.label}
            </p>
          </div>
        );
      })}
      {name === "news" && (
        <div className="flex items-center mt-[20px] px-1">
          <img
            src={watchIcon}
            alt="watch"
            className="w-[20px] h-[20px] mr-[9px]"
          />
          <p className="text-[#344054] font-[500] text-[14px]">Last</p>
          <input
            type="tel"
            placeholder="#"
            className="text-[14px] h-[31px] text-center border-[#D0D5DD] px-[14px] py-[3px] rounded-[8px] y-axis-shadow mx-[10px] inline-block bg-transparent focus:!outline-none w-[53px] !outline-none placeholder:text-[#B2B4B8] focus:!border-[#D0D5DD]"
            onChange={onInputChange}
            name="lastDayNews"
            value={formData?.["lastDayNews"] || ""}
            onKeyPress={preventInvalidInput}
            maxLength={3}
            onKeyDown={(e) => {
              if (e?.code === "Space") {
                e.preventDefault();
              }
            }}
          />
          <p className="text-[#344054] font-[500] text-[14px]">day(s)</p>
        </div>
      )}
    </>
  );
};

export default Signals;
