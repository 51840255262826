import { IoClose } from "react-icons/io5";
import Button from "components/button";
import deleteIcon from "assets/images/delete-modal.svg";
import deleteImg from "assets/images/bijli.svg";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

const EndSubscription = (props) => {
  const { isOpen, onClose, onSubmit, isPending } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [isOpen]);

  if (!isOpen && !isModalVisible) return null;

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[300ms] ease-in-out ${
        isModalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className="fixed inset-0 bg-black opacity-10"
        onClick={onClose}
      ></div>
      <div
        className={`bg-white p-[24px] rounded-lg modal-shadow z-10 w-full max-w-[600px] relative transition-all ease-in-out duration-[150ms] ${
          isModalVisible ? "scale-100" : "scale-95"
        }`}
      >
        <IoClose
          className="text-[#667085] text-[20px] w-[24px] h-[24px] absolute right-[24px] top-[26px] cursor-pointer"
          onClick={onClose}
        />
        <div className="modal-scroll">
          <div className="modal-scroll">
            <div className="flex flex-col items-start justify-start mb-[20px] font-inter">
              <div className="flex items-center justify-center mb-[16px]">
                <img
                  src={deleteImg}
                  alt="info img"
                  className="h-[48px] w-[48px]"
                />
              </div>
              <h4 className="font-[600] text-[18px] text-[#101828] m-[4px] ml-0 font-inter">
                Cancel Subscription
              </h4>
              <p className="font-normal text-[14px] text-[#475467] font-inter mb-[8px]">
                As the administrator, you are ending the subscription for the
                entire workspace. You and your team will loose access to the
                Pipeline accounts.
              </p>
              <p className="font-[700] text-[14px] text-[#475467] font-inter">
                You’ll be able to reactivate your workspace in the future if you
                change your mind.
              </p>
            </div>
            <div className="flex items-center justify-center mt-[32px]">
              <Button
                title="Cancel"
                isWhiteBtn={true}
                onClick={onClose}
                className="!text-[16px] !h-[44px] !font-[600] !text-[#344054] y-axis-shadow !rounded-[8px]"
              />
              <Button
                onClick={() => {
                  onSubmit();
                }}
                title={
                  isPending ? (
                    <div className="w-[20px] h-[20px] border-[3px] border-t-[3px] mx-auto border-[#D92D20] border-solid rounded-full animate-spin border-t-[#fff]" />
                  ) : (
                    "End Subscription"
                  )
                }
                secondaryColor="!border-[#D92D20] !bg-[#D92D20] hover:!bg-transparent hover:!text-[#D92D20] ml-[12px]"
                className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow !rounded-[8px]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EndSubscription;
