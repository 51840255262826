import Select from "react-select";
import OTPInput from "otp-input-react";
import { toast } from "react-toastify";
import AnimatedButton from "components/animatedButton";
import { useEffect, useState } from "react";
import phone from "assets/images/mobile.svg";
import Button from "components/button";
import { IoClose } from "react-icons/io5";
import { countrySelectStyleBalck } from "constant";
const inputClasses = `w-full h-[42px] font-[400] bg-[#fff] border border-1 border-[#333741] rounded-[7px] outline-none py-[9px] px-[13px] relative text-[16px] text-[#fff] placeholder:!text-[#85888E] focus-visible:border-[#fff] focus-visible:text-[#fff] focus-visible:placeholder:text-[#fff] transition-all`;

const AccountOtpModal = ({
  isPhoneCard,
  closeModal,
  countryCodeOptions,
  selectedCountryCode,
  setSelectedCountryCode,
  phoneNumber,
  handlePhoneNumberChange,
  isFocused,
  setIsFocused,
  handlePhoneVerify,
  OTP,
  setOTP,
  handleVerificationSubmit,
  handleResendCode,
  loading
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    setIsModalVisible(true);
    return () => setIsModalVisible(false); // Cleanup on unmount
  }, []);

  const handleClose = () => {
    setIsModalVisible(false);
    setTimeout(closeModal, 100);
  };

  if (!isModalVisible) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[300ms] ease-in-out">
      <div
        className="fixed inset-0 bg-black opacity-10"
        onClick={handleClose}
      ></div>
      <div className="bg-white p-[24px] rounded-lg modal-shadow z-10 w-full max-w-[545.14px] relative">
        <IoClose
          className="text-[#667085] text-[20px] w-[24px] h-[24px] absolute right-[24px] top-[26px] cursor-pointer z-10"
          onClick={handleClose}
        />
        {isPhoneCard ? (
          <>
            <>
              <div
                className="flex justify-center items-center border-[#E4E7EC] border w-[48px] h-[48px] rounded-[10px] mb-[22px]"
                style={{
                  boxShadow:
                    "0px 4px 8px 0px rgba(16, 24, 40, 0.05), 0px 2px 0px 0px rgba(16, 24, 40, 0.05), 0px 0px 1px 0px rgba(16, 24, 40, 0.18)",
                }}
              >
                <img src={phone} alt="phone" className="h-[24px] w-[24px]" />
              </div>
              <h1 className="text-[18px] text-[#101828] font-[600] mb-[5px]">
                Enter Phone Number
              </h1>
              <p className="text-[16px] font-[400] text-[#475467] leading-[24px] m-0">
                Enter your phone number to receive the code.{" "}
              </p>
              <form
                className="mt-[22px] w-full"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="mt-[27.26px] flex flex-col justify-center">
                  <p className="font-inter text-[14px] font-[500] text-[#344054] leading-[20px] mb-[6px]">
                    Phone Number <span className="text-[#7F56D9]">*</span>
                  </p>
                  <div
                  className={`flex-grow ${inputClasses} !w-[95%] !bg-[#fff] text-[#9e9ba0] relative flex ${
                    isFocused && "!border-[#9e9ba0]"
                  }`}
                >
                  <div className="w-[70px] absolute left-[5px] z-50 top-0.5 cursor-pointer  no-border-select">
                    <Select
                      defaultValue={countryCodeOptions[0]}
                      options={countryCodeOptions}
                      classNamePrefix="leads-react-select"
                      className="!font-inter w-full !border-none !border-black"
                      placeholder="US"
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.label}
                      maxMenuHeight="128px"
                      styles={countrySelectStyleBalck}
                      isClearable
                      isSearchable={false}
                      noOptionsMessage={() => "No Results"}
                      onChange={(selectedOption) => {
                        setSelectedCountryCode(selectedOption);
                      }}
                    />
                  </div>
                  <p className="text-[#000] pl-[60px] z-20">
                    +{selectedCountryCode?.value}
                  </p>
                  <input
                    type="tel"
                    placeholder="e.g. 6503334444"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    className={`flex-grow !bg-[#fff] w-[90%] !text-[#000] !border-none mt-0.5 -ml-2`}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                  />
                </div>
                  <p className="mt-[16px] font-[400] text-[14px] text-[#475476]">
                    Please include the country code in brackets.
                    <br /> e.g. for U.S. phone numbers (+1)6503334444{" "}
                  </p>
                </div>
                <div className="flex items-center justify-center mt-[35.43px]">
                  <Button
                    title="Cancel"
                    isWhiteBtn={true}
                    onClick={closeModal}
                    className="!text-[16px] !h-[44px] !font-[600] !text-[#344054] y-axis-shadow !rounded-[8px]"
                  />
                  <Button
                    onClick={handlePhoneVerify}
                    disabled={phoneNumber.length < 10 || loading}
                    title={
                      loading ? (
                        <div className="flex items-center justify-center">
                          <div className="w-[18px] h-[18px] border-[3px] border-t-[3px] mr-[12px] border-[#fff] border-solid rounded-full animate-spin border-t-[#8A43FF]" />
                        </div>
                      ) : (
                        "Continue"
                      )
                    }
                    secondaryColor={`!border-[#7F56D9] !bg-[#7F56D9] ml-[12px] ${"hover:!bg-transparent hover:!text-[#7F56D9]"}`}
                    className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow !rounded-[8px]"
                  />
                </div>
              </form>
            </>
          </>
        ) : (
          <>
            <div
              className="flex justify-center items-center border-[#E4E7EC] border w-[48px] h-[48px] rounded-[10px] mb-[22px]"
              style={{
                boxShadow:
                  "0px 4px 8px 0px rgba(16, 24, 40, 0.05), 0px 2px 0px 0px rgba(16, 24, 40, 0.05), 0px 0px 1px 0px rgba(16, 24, 40, 0.18)",
              }}
            >
              <img src={phone} alt="phone" className="h-[24px] w-[24px]" />
            </div>
            <h1 className="text-[18px] text-left text-[#303030] font-[600] mt-[20px] mb-[5px]">
              Enter Code
            </h1>
            <p className="text-[14px] font-[400] text-left text-[#737477] leading-[24px]">
              Enter the verification code sent to your email or phone by
              LinkedIn.
            </p>
            <form
              className="mt-[27px] w-full"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="mb-[32px] flex items-center justify-center mr-[-20px]">
                <OTPInput
                  value={OTP}
                  onChange={setOTP}
                  autoFocus
                  OTPLength={6}
                  otpType="number"
                  inputClassName="otpInput !h-[56px] !px-[2px] !w-[40px] text-[28px] border !border-[#46464A4F] rounded-[6px] bg-transparent text-[#000] !outline-none focus:!border-[#8A43FF] focus:!border-[2px]"
                />
              </div>
              <p className="text-center font-[400] text-[14px] text-[#475467] w-full mb-[11px]">
                Didn’t receive the email?
              </p>
              <p
                className="font-[600] text-center text-[14px] text-[#6941C6] cursor-pointer mb-[20px]"
                onClick={() => handleResendCode("phone_code")}
              >
                Click to resend
              </p>
              <Button
                isWhiteBtn={true}
                title="Cancel"
                onClick={handleVerificationSubmit}
                disabled={OTP.length !== 6}
                className="!text-[16px] !h-[44px] !font-[600] !text-[#344054] y-axis-shadow !rounded-[8px] !border-[#D0D5DD]"
              />
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default AccountOtpModal;
