import logomark from "assets/images/Logomark.svg";
import arrowDown from "assets/images/arrow-down.svg";
import helpIcon from "assets/images/Help-icon.svg";
import ActionButtons from "components/actionButtons";
import { useNavigate } from "react-router-dom";
import RoundProgressBar from "components/roundProgress";
import moment from "moment-timezone";
import {
  useAccountStore,
  useAuthStore,
  useProspectStore,
  useSettingStore,
} from "stores";
import { randomProfileLogo } from "constant";
import InfiniteScroll from "react-infinite-scroll-component";
import { formatNumberWithCommas, getNameInititals } from "utils/";
import heart from "assets/images/purple-heart.svg";
import goldenStar from "assets/images/goldenStar.svg";
import sparkles from "assets/images/sparkles.png";
import CommonReactTooltip from "components/commonReactTooltip";
import { Tooltip } from "react-tooltip";

const tableHeader = [
  { title: "List Name", icon: "arrow", key: "list_name" },
  { title: "Leads", icon: "help" },
  { title: "Team Access", icon: "help" },
  { title: "Status", icon: "help", key: "" },
  { title: "Created", icon: "arrow", key: "created_date" },
  { title: "", icon: "", key: "" },
];

const ProspectingTable = (props) => {
  const {
    isEditModalOpen,
    setIsEditModalOpen,
    isArchiveModalOpen,
    setIsArchiveModalOpen,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    tableData,
    tab,
    setCurrentId,
    menuIsOpen,
    confirmUnarchive,
    setIsShareModalOpen,
    isShareModalOpen,
    handleSortChange,
    sortBy,
    sortOrder,
    totalItems,
    hasMore,
    nextFetchData,
    tableRef,
    prospectList,
  } = props;
  const navigate = useNavigate();
  const { isAdmin, userDetails } = useAuthStore();
  const { setProspectInfo } = useProspectStore();
  const { linkedinAccountDetails } = useAccountStore();
  const { userData } = useSettingStore();

  const teamProfilePics = randomProfileLogo?.filter(
    (i) => i.src !== userData?.user?.profile_pic
  );

  const getRandomProfileLogoWithoutRandom = () =>
    teamProfilePics[Math.floor(Math.random() * teamProfilePics.length)];

  function handleNavigate(prospectData) {
    setProspectInfo(prospectData);
    navigate(`/prospecting/${prospectData?.prospect_id}`);
  }

  return (
    <>
      <div className="border border-[#E4E7EC] rounded-xl">
        <div
          className={`round-table-scroll ${
            linkedinAccountDetails?.reconnect_status
              ? "max-h-[calc(100vh_-280px)]"
              : "max-h-[calc(100vh_-210px)]"
          } overflow-auto`}
          id="scrollableDiv"
          ref={tableRef}
        >
          <InfiniteScroll
            dataLength={tableData?.length || 0}
            next={nextFetchData}
            hasMore={tableData?.length < totalItems && hasMore}
            loader={
              prospectList !== totalItems && (
                <div className="h-[50px] w-full flex items-center justify-center">
                  <div className="w-[20px] h-[20px] border-[3px] border-t-[3px] border-[#7F56D9] border-solid rounded-full animate-spin border-t-[#fff]" />
                </div>
              )
            }
            scrollableTarget="scrollableDiv"
            style={{ height: "100%", overflow: "unset" }}
          >
            <table className="min-w-full h-unset rounded-xl">
              <thead className="sticky top-0 !z-[1000] bg-[#fff] overflow-hidden rounded-[12px]">
                <tr className="w-full relative">
                  {tableHeader.map((header, index) => {
                    const isLastThead = index === tableHeader.length - 1;
                    const renderTooltipId =
                      header?.title === "Leads"
                        ? "lead-tooltip"
                        : header?.title === "Team Access"
                        ? "team-tooltip"
                        : header?.title === "Status"
                        ? "status-tooltip"
                        : null;
                    return (
                      <th
                        key={index}
                        className={`text-[12px] font-[500] py-[11.8px] px-[22px] text-[#475467] text-left font-inter cursor-pointer first:rounded-tl-[12px] last:rounded-tr-[12px] ${
                          isLastThead && "w-[100px]"
                        }`}
                        onClick={() =>
                          header.key && handleSortChange(header.key)
                        }
                      >
                        <div className="flex items-center relative">
                          <span className="pr-[4px]">{header.title}</span>
                          {header.icon === "arrow" && (
                            <img
                              src={arrowDown}
                              alt="arrow"
                              className={`cusror-pointer w-[10px] h-[10px] ${
                                sortBy === header.key && sortOrder === "desc"
                                  ? "rotate-180"
                                  : ""
                              }`}
                            />
                          )}
                          {header.icon === "help" && (
                            <img
                              src={helpIcon}
                              alt="help"
                              className="ml-[4px]"
                              data-tooltip-id={renderTooltipId}
                            />
                          )}
                        </div>
                      </th>
                    );
                  })}
                </tr>
                <span className="w-full border-b border-[#E4E7EC] h-[1px] absolute top-full z-10 left-0" />
              </thead>
              <tbody>
                {tableData?.length > 0 &&
                  tableData?.map((item, index) => {
                    const isLastRow = index === tableData.length - 1;

                    const sortAccessUsers = (accessUsers, priorityEmail) => {
                      if (!accessUsers) return [];

                      return accessUsers.sort((a, b) => {
                        if (a.email === priorityEmail) return -1;
                        if (b.email === priorityEmail) return 1;
                        return a.email.localeCompare(b.email);
                      });
                    };

                    const priorityEmail = userDetails.email;
                    const uniqueEntries = Array.from(
                      new Map(
                        sortAccessUsers(item?.access_users, priorityEmail).map(
                          (user) => [user.email, user]
                        )
                      ).values()
                    );

                    const formattedDate = moment(item?.created_at)?.format(
                      "MM/DD/YYYY [at] hh:mm A"
                    );
                    return (
                      <tr
                        className={`group ${
                          isLastRow ? "" : "border-b"
                        } hover:bg-gray-50 cursor-pointer`}
                        key={index}
                      >
                        <td
                          align="left"
                          onClick={() => handleNavigate(item)}
                          className={`${
                            isLastRow && "first:rounded-bl-[12px]"
                          } text-[13.2px] py-[18px] px-[22px] text-[#101828] text-left  w-[400px] max-[1300px]:w-[unset]`}
                        >
                          <div className="flex items-start">
                            <img
                              src={logomark}
                              alt="logomark"
                              className="w-[22.7px] h-[22.7px] mr-[11.3px]"
                            />
                            <div
                              className="text-[#101828] break-words flex font-[500] max-w-full line-clamp-2 webkit-box min-w-[200px]"
                              data-tooltip-id={`prospect-${index}`}
                            >
                              {item?.prospect_name}
                              {item?.message && (
                                <p className="px-[5px] text-[#344054] ml-[12px] py-[2px] border border-[#D0D5DD] font-[500] text-[10.9px] rounded-[7px] font-inter flex items-center max-w-[44px]">
                                  <span className="bg-[#9E77ED] outline outline-[#F4EBFF] outline-[3px] min-w-[6.2px] w-[6.2px] h-[6.2px] rounded-full mx-[3px] block" />
                                  {item?.message}
                                </p>
                              )}
                            </div>
                            {item?.prospect_name?.length > 140 && (
                              <Tooltip
                                id={`prospect-${index}`}
                                className={`actionButton-shadow !bg-[#7F56D9] !z-[9999] !rounded-[8px] !py-[8px] !px-[12px] !max-w-[380px]`}
                                place="bottom"
                              >
                                <p className="text-[#fff] text-[600] text-[12px] font-semibold break-words">
                                  {item?.prospect_name}
                                </p>
                              </Tooltip>
                            )}
                          </div>
                        </td>
                        <td
                          onClick={() => handleNavigate(item)}
                          className="text-[13.2px] py-[18px] px-[22px] text-[#475467] text-left w-[180px]"
                        >
                          <div
                            className="flex items-center justify-start w-fit"
                            data-tooltip-id={`leads-count-tooltip-${index}`}
                          >
                            {tab !== "Drafts" && (
                              <div className="mr-[6px] inline scale-x-[-1] pointer-events-none relative">
                                <RoundProgressBar
                                  percentage={item?.total_enriched_leads}
                                  page="prospecting"
                                  total={item?.total_available_leads}
                                />
                              </div>
                            )}
                            <span>
                              {item?.total_enriched_leads > 0 && (
                                <Tooltip
                                  id={`leads-count-tooltip-${index}`}
                                  className={`actionButton-shadow !bg-[#7F56D9] !z-[9999] !rounded-[8px] !py-[8px] !px-[12px]`}
                                  place={"bottom"}
                                >
                                  <div className="flex items-center">
                                    <img
                                      src={sparkles}
                                      alt="icon"
                                      className="w-[15px] h-[15px] min-w-[15px] mr-[4px]"
                                    />
                                    <p className="text-[#fff] text-[600] text-[12px] font-semibold">
                                      {item?.total_enriched_leads} enriched of{" "}
                                      {item?.total_available_leads} available
                                    </p>
                                  </div>
                                </Tooltip>
                              )}
                              {`${formatNumberWithCommas(
                                item?.total_enriched_leads
                              )}/${formatNumberWithCommas(
                                item?.total_available_leads
                              )}`}
                            </span>
                          </div>
                        </td>

                        <td
                          onClick={() => handleNavigate(item)}
                          className={`py-[5px] px-[22px] text-left relative w-[225px] max-[1300px]:w-[unset]`}
                        >
                          {uniqueEntries?.length > 0 &&
                            uniqueEntries?.map((user, index) => {
                              const profilePic = user?.profile_pic
                                ? user?.profile_pic
                                : userData?.user?.profile_pic && index === 0
                                ? userData?.user?.profile_pic
                                : getRandomProfileLogoWithoutRandom()?.src;
                              return (
                                <div
                                  className="absolute top-[25%]"
                                  style={{
                                    left: `${
                                      index === 0 ? 21 : (index + 1) * 21
                                    }px`,
                                    zIndex:
                                      index === 0
                                        ? 100
                                        : uniqueEntries.length - index,
                                  }}
                                >
                                  {(user?.is_owner && user?.profile_pic) ||
                                  user?.profile_pic ||
                                  (user?.is_owner && isAdmin) ? (
                                    <img
                                      key={index}
                                      src={profilePic}
                                      alt="user images"
                                      className={`max-w-[30px] max-h-[30px] min-w-[30px] cursor-pointer rounded-[100%] ${
                                        user?.member_active
                                          ? " opacity-100"
                                          : " opacity-40"
                                      }`}
                                    />
                                  ) : (
                                    <div
                                      className={`h-[30px] w-[30px] rounded-full ${
                                        user?.member_active
                                          ? " opacity-100"
                                          : " opacity-40"
                                      } flex items-center justify-center text-[13px] font-[700] text-[#4409B9] bg-[#EEEDFD] mr-[0px]`}
                                    >
                                      {getNameInititals(
                                        user?.is_owner
                                          ? userData?.user?.name
                                          : user?.name
                                      )}
                                    </div>
                                  )}

                                  {user?.is_owner && (
                                    <span className="w-[6px] h-[6px] bg-[#577CFF] rounded-[50%] absolute bottom-[2px] right-[2px]" />
                                  )}
                                </div>
                              );
                            })}
                        </td>
                        <td
                          onClick={() => handleNavigate(item)}
                          className="py-[5px] px-[22px] text-center w-[150px] max-[1300px]:w-[unset]"
                        >
                          {item?.campaign_active && (
                            <p className="max-w-[115px] border-[#D0D5DD] border-[1px] rounded-[6px] flex items-center capitalize font-[500] py-[2px] px-[5px] text-[11.3px] leading-[17px] text-[#344054]">
                              <span className="bg-[#17B26A] w-[6px] h-[6px] rounded-full mr-[5px] block" />
                              Campaign Active
                            </p>
                          )}
                        </td>
                        <td
                          onClick={() => handleNavigate(item)}
                          className={`text-[13.2px] py-[5px] px-[22px] text-[#475467] text-left  w-[250px] max-[1300px]:w-[unset]`}
                        >
                          {formattedDate}
                        </td>
                        <td
                          className={`py-[5px] px-[22px] h-full ${
                            isLastRow && "last:rounded-br-[12px]"
                          }`}
                          align="center"
                        >
                          <ActionButtons
                            // position={
                            //   index > 7 && lastIndexes.includes(index)
                            //     ? "!bottom-[20px]"
                            //     : "top-[20px]"
                            // }
                            isEditModalOpen={isEditModalOpen}
                            setIsEditModalOpen={setIsEditModalOpen}
                            isArchiveModalOpen={isArchiveModalOpen}
                            setIsArchiveModalOpen={setIsArchiveModalOpen}
                            isDeleteModalOpen={isDeleteModalOpen}
                            setIsDeleteModalOpen={setIsDeleteModalOpen}
                            item={item}
                            setCurrentId={setCurrentId}
                            menuIsOpen={menuIsOpen}
                            listType={tab}
                            confirmUnarchive={confirmUnarchive}
                            setIsShareModalOpen={setIsShareModalOpen}
                            isShareModalOpen={isShareModalOpen}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </InfiniteScroll>
        </div>
      </div>
      {/* lead tooltip */}
      <CommonReactTooltip
        id="lead-tooltip"
        placement="top"
        icon={sparkles}
        title="Enriched Leads"
        description="See how many leads you’ve enriched compared to the total available."
        className="!w-[239px]"
      />
      {/* team tooltip */}
      <CommonReactTooltip
        id="team-tooltip"
        place="top"
        icon={heart}
        title="Shared Lists"
        description="See which of your teammates in your workspace have access to the lead list."
        className="!w-[212px]"
      />
      {/* status tooltip */}
      <CommonReactTooltip
        id="status-tooltip"
        placement="top"
        icon={goldenStar}
        title="Status"
        description="If a lead list was used in a campaign, you'll see the tag here."
        className="!w-[238px]"
      />
    </>
  );
};

export default ProspectingTable;
