import React from "react";

const BillingTableSkeleton = () => {
  return (
    <div className="w-full border border-gray-200 rounded-md overflow-hidden">
      {/* Header Skeleton */}
      <div className="flex items-center py-3 bg-white box-border">
        {Array(7) // Adjust based on the number of headers
          .fill(0)
          .map((_, index) => (
            <div
              key={index}
              className={`px-4 ${
                index === 0 ? " pl-4 !pr-0 w-[40px]" : index === 1 ? "w-[35%]" : "w-[10%]"
              }`}
            >
              <div className={`h-[25px] animate-bg-pulse rounded-sm`}></div>
            </div>
          ))}
      </div>
      {/* Skeleton Rows */}
      {Array(7) // Adjust the number of skeleton rows as needed
        .fill(0)
        .map((_, index) => (
          <div
            key={index}
            className="flex items-center py-4 border-t border-gray-200 w-full box-border"
          >
            <div className="flex items-center  px-4 w-[40px] min-w-[40px]">
              <div className="h-[20px] rounded-sm w-[20px] min-w-[20px]  animate-bg-pulse"></div>
            </div>
            <div className="px-4 w-[35%]">
              <div className="h-[20px] rounded-sm animate-bg-pulse w-full"></div>
            </div>
            <div className="px-4 w-[10%]">
              <div className="h-[20px] rounded-sm animate-bg-pulse w-full"></div>
            </div>
            <div className="px-4 w-[10%]">
              <div className="h-[20px] rounded-sm animate-bg-pulse w-full"></div>
            </div>
            <div className="px-4 w-[10%]">
              <div className="h-[20px] rounded-sm animate-bg-pulse w-full"></div>
            </div>
            <div className="px-4 w-[10%]">
              <div className="h-[20px] rounded-sm animate-bg-pulse w-full"></div>
            </div>
            <div className="px-4 w-[10%]">
              <div className="h-[20px] rounded-sm animate-bg-pulse w-full"></div>
            </div>
            <div className="px-4 w-[10%] flex items-center justify-between">
              <div className="h-[20px] rounded-sm animate-bg-pulse w-[70%]"></div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default BillingTableSkeleton;
