export const AllLocations = [
  { label: "Afghanistan", value: "Afghanistan", location_type: "Country" },
  { label: "Albania", value: "Albania", location_type: "Country" },
  { label: "Algeria", value: "Algeria", location_type: "Country" },
  { label: "Andorra", value: "Andorra", location_type: "Country" },
  { label: "Angola", value: "Angola", location_type: "Country" },
  {
    label: "Antigua and Barbuda",
    value: "Antigua and Barbuda",
    location_type: "Country",
  },
  { label: "Argentina", value: "Argentina", location_type: "Country" },
  { label: "Armenia", value: "Armenia", location_type: "Country" },
  { label: "Australia", value: "Australia", location_type: "Country" },
  { label: "Austria", value: "Austria", location_type: "Country" },
  { label: "Azerbaijan", value: "Azerbaijan", location_type: "Country" },
  { label: "Bahamas", value: "Bahamas", location_type: "Country" },
  { label: "Bahrain", value: "Bahrain", location_type: "Country" },
  { label: "Bangladesh", value: "Bangladesh", location_type: "Country" },
  { label: "Barbados", value: "Barbados", location_type: "Country" },
  { label: "Belarus", value: "Belarus", location_type: "Country" },
  { label: "Belgium", value: "Belgium", location_type: "Country" },
  { label: "Belize", value: "Belize", location_type: "Country" },
  { label: "Benin", value: "Benin", location_type: "Country" },
  { label: "Bhutan", value: "Bhutan", location_type: "Country" },
  { label: "Bolivia", value: "Bolivia", location_type: "Country" },
  {
    label: "Bosnia and Herzegovina",
    value: "Bosnia and Herzegovina",
    location_type: "Country",
  },
  { label: "Botswana", value: "Botswana", location_type: "Country" },
  { label: "Brazil", value: "Brazil", location_type: "Country" },
  { label: "Brunei", value: "Brunei", location_type: "Country" },
  { label: "Bulgaria", value: "Bulgaria", location_type: "Country" },
  { label: "Burkina Faso", value: "Burkina Faso", location_type: "Country" },
  { label: "Burundi", value: "Burundi", location_type: "Country" },
  { label: "Cabo Verde", value: "Cabo Verde", location_type: "Country" },
  { label: "Cambodia", value: "Cambodia", location_type: "Country" },
  { label: "Cameroon", value: "Cameroon", location_type: "Country" },
  { label: "Canada", value: "Canada", location_type: "Country" },
  {
    label: "Central African Republic",
    value: "Central African Republic",
    location_type: "Country",
  },
  { label: "Chad", value: "Chad", location_type: "Country" },
  { label: "Chile", value: "Chile", location_type: "Country" },
  { label: "China", value: "China", location_type: "Country" },
  { label: "Colombia", value: "Colombia", location_type: "Country" },
  { label: "Comoros", value: "Comoros", location_type: "Country" },
  { label: "Congo", value: "Congo", location_type: "Country" },
  { label: "Costa Rica", value: "Costa Rica", location_type: "Country" },
  { label: "Croatia", value: "Croatia", location_type: "Country" },
  { label: "Cuba", value: "Cuba", location_type: "Country" },
  { label: "Cyprus", value: "Cyprus", location_type: "Country" },
  {
    label: "Czech Republic",
    value: "Czech Republic",
    location_type: "Country",
  },
  {
    label: "Democratic Republic of the Congo",
    value: "Democratic Republic of the Congo",
    location_type: "Country",
  },
  { label: "Denmark", value: "Denmark", location_type: "Country" },
  { label: "Djibouti", value: "Djibouti", location_type: "Country" },
  { label: "Dominica", value: "Dominica", location_type: "Country" },
  {
    label: "Dominican Republic",
    value: "Dominican Republic",
    location_type: "Country",
  },
  { label: "Ecuador", value: "Ecuador", location_type: "Country" },
  { label: "Egypt", value: "Egypt", location_type: "Country" },
  { label: "El Salvador", value: "El Salvador", location_type: "Country" },
  {
    label: "Equatorial Guinea",
    value: "Equatorial Guinea",
    location_type: "Country",
  },
  { label: "Eritrea", value: "Eritrea", location_type: "Country" },
  { label: "Estonia", value: "Estonia", location_type: "Country" },
  { label: "Eswatini", value: "Eswatini", location_type: "Country" },
  { label: "Ethiopia", value: "Ethiopia", location_type: "Country" },
  { label: "Fiji", value: "Fiji", location_type: "Country" },
  { label: "Finland", value: "Finland", location_type: "Country" },
  { label: "France", value: "France", location_type: "Country" },
  { label: "Gabon", value: "Gabon", location_type: "Country" },
  { label: "Gambia", value: "Gambia", location_type: "Country" },
  { label: "Georgia", value: "Georgia", location_type: "Country" },
  { label: "Germany", value: "Germany", location_type: "Country" },
  { label: "Ghana", value: "Ghana", location_type: "Country" },
  { label: "Greece", value: "Greece", location_type: "Country" },
  { label: "Grenada", value: "Grenada", location_type: "Country" },
  { label: "Guatemala", value: "Guatemala", location_type: "Country" },
  { label: "Guinea", value: "Guinea", location_type: "Country" },
  { label: "Guinea-Bissau", value: "Guinea-Bissau", location_type: "Country" },
  { label: "Guyana", value: "Guyana", location_type: "Country" },
  { label: "Haiti", value: "Haiti", location_type: "Country" },
  { label: "Honduras", value: "Honduras", location_type: "Country" },
  { label: "Hungary", value: "Hungary", location_type: "Country" },
  { label: "Iceland", value: "Iceland", location_type: "Country" },
  { label: "India", value: "India", location_type: "Country" },
  { label: "Indonesia", value: "Indonesia", location_type: "Country" },
  { label: "Iran", value: "Iran", location_type: "Country" },
  { label: "Iraq", value: "Iraq", location_type: "Country" },
  { label: "Ireland", value: "Ireland", location_type: "Country" },
  { label: "Israel", value: "Israel", location_type: "Country" },
  { label: "Italy", value: "Italy", location_type: "Country" },
  { label: "Ivory Coast", value: "Ivory Coast", location_type: "Country" },
  { label: "Jamaica", value: "Jamaica", location_type: "Country" },
  { label: "Japan", value: "Japan", location_type: "Country" },
  { label: "Jordan", value: "Jordan", location_type: "Country" },
  { label: "Kazakhstan", value: "Kazakhstan", location_type: "Country" },
  { label: "Kenya", value: "Kenya", location_type: "Country" },
  { label: "Kiribati", value: "Kiribati", location_type: "Country" },
  { label: "Kuwait", value: "Kuwait", location_type: "Country" },
  { label: "Kyrgyzstan", value: "Kyrgyzstan", location_type: "Country" },
  { label: "Laos", value: "Laos", location_type: "Country" },
  { label: "Latvia", value: "Latvia", location_type: "Country" },
  { label: "Lebanon", value: "Lebanon", location_type: "Country" },
  { label: "Lesotho", value: "Lesotho", location_type: "Country" },
  { label: "Liberia", value: "Liberia", location_type: "Country" },
  { label: "Libya", value: "Libya", location_type: "Country" },
  { label: "Liechtenstein", value: "Liechtenstein", location_type: "Country" },
  { label: "Lithuania", value: "Lithuania", location_type: "Country" },
  { label: "Luxembourg", value: "Luxembourg", location_type: "Country" },
  { label: "Madagascar", value: "Madagascar", location_type: "Country" },
  { label: "Malawi", value: "Malawi", location_type: "Country" },
  { label: "Malaysia", value: "Malaysia", location_type: "Country" },
  { label: "Maldives", value: "Maldives", location_type: "Country" },
  { label: "Mali", value: "Mali", location_type: "Country" },
  { label: "Malta", value: "Malta", location_type: "Country" },
  {
    label: "Marshall Islands",
    value: "Marshall Islands",
    location_type: "Country",
  },
  { label: "Mauritania", value: "Mauritania", location_type: "Country" },
  { label: "Mauritius", value: "Mauritius", location_type: "Country" },
  { label: "Mexico", value: "Mexico", location_type: "Country" },
  { label: "Micronesia", value: "Micronesia", location_type: "Country" },
  { label: "Moldova", value: "Moldova", location_type: "Country" },
  { label: "Monaco", value: "Monaco", location_type: "Country" },
  { label: "Mongolia", value: "Mongolia", location_type: "Country" },
  { label: "Montenegro", value: "Montenegro", location_type: "Country" },
  { label: "Morocco", value: "Morocco", location_type: "Country" },
  { label: "Mozambique", value: "Mozambique", location_type: "Country" },
  {
    label: "Myanmar (Burma)",
    value: "Myanmar (Burma)",
    location_type: "Country",
  },
  { label: "Namibia", value: "Namibia", location_type: "Country" },
  { label: "Nauru", value: "Nauru", location_type: "Country" },
  { label: "Nepal", value: "Nepal", location_type: "Country" },
  { label: "Netherlands", value: "Netherlands", location_type: "Country" },
  { label: "New Zealand", value: "New Zealand", location_type: "Country" },
  { label: "Nicaragua", value: "Nicaragua", location_type: "Country" },
  { label: "Niger", value: "Niger", location_type: "Country" },
  { label: "Nigeria", value: "Nigeria", location_type: "Country" },
  { label: "North Korea", value: "North Korea", location_type: "Country" },
  {
    label: "North Macedonia",
    value: "North Macedonia",
    location_type: "Country",
  },
  { label: "Norway", value: "Norway", location_type: "Country" },
  { label: "Oman", value: "Oman", location_type: "Country" },
  { label: "Pakistan", value: "Pakistan", location_type: "Country" },
  { label: "Palau", value: "Palau", location_type: "Country" },
  { label: "Palestine", value: "Palestine", location_type: "Country" },
  { label: "Panama", value: "Panama", location_type: "Country" },
  {
    label: "Papua New Guinea",
    value: "Papua New Guinea",
    location_type: "Country",
  },
  { label: "Paraguay", value: "Paraguay", location_type: "Country" },
  { label: "Peru", value: "Peru", location_type: "Country" },
  { label: "Philippines", value: "Philippines", location_type: "Country" },
  { label: "Poland", value: "Poland", location_type: "Country" },
  { label: "Portugal", value: "Portugal", location_type: "Country" },
  { label: "Qatar", value: "Qatar", location_type: "Country" },
  { label: "Romania", value: "Romania", location_type: "Country" },
  { label: "Russia", value: "Russia", location_type: "Country" },
  { label: "Rwanda", value: "Rwanda", location_type: "Country" },
  {
    label: "Saint Kitts and Nevis",
    value: "Saint Kitts and Nevis",
    location_type: "Country",
  },
  { label: "Saint Lucia", value: "Saint Lucia", location_type: "Country" },
  {
    label: "Saint Vincent and the Grenadines",
    value: "Saint Vincent and the Grenadines",
    location_type: "Country",
  },
  { label: "Samoa", value: "Samoa", location_type: "Country" },
  { label: "San Marino", value: "San Marino", location_type: "Country" },
  {
    label: "Sao Tome and Principe",
    value: "Sao Tome and Principe",
    location_type: "Country",
  },
  { label: "Saudi Arabia", value: "Saudi Arabia", location_type: "Country" },
  { label: "Senegal", value: "Senegal", location_type: "Country" },
  { label: "Serbia", value: "Serbia", location_type: "Country" },
  { label: "Seychelles", value: "Seychelles", location_type: "Country" },
  { label: "Sierra Leone", value: "Sierra Leone", location_type: "Country" },
  { label: "Singapore", value: "Singapore", location_type: "Country" },
  { label: "Slovakia", value: "Slovakia", location_type: "Country" },
  { label: "Slovenia", value: "Slovenia", location_type: "Country" },
  {
    label: "Solomon Islands",
    value: "Solomon Islands",
    location_type: "Country",
  },
  { label: "Somalia", value: "Somalia", location_type: "Country" },
  { label: "South Africa", value: "South Africa", location_type: "Country" },
  { label: "South Korea", value: "South Korea", location_type: "Country" },
  { label: "South Sudan", value: "South Sudan", location_type: "Country" },
  { label: "Spain", value: "Spain", location_type: "Country" },
  { label: "Sri Lanka", value: "Sri Lanka", location_type: "Country" },
  { label: "Sudan", value: "Sudan", location_type: "Country" },
  { label: "Suriname", value: "Suriname", location_type: "Country" },
  { label: "Sweden", value: "Sweden", location_type: "Country" },
  { label: "Switzerland", value: "Switzerland", location_type: "Country" },
  { label: "Syria", value: "Syria", location_type: "Country" },
  { label: "Tajikistan", value: "Tajikistan", location_type: "Country" },
  { label: "Tanzania", value: "Tanzania", location_type: "Country" },
  { label: "Thailand", value: "Thailand", location_type: "Country" },
  { label: "Timor-Leste", value: "Timor-Leste", location_type: "Country" },
  { label: "Togo", value: "Togo", location_type: "Country" },
  { label: "Tonga", value: "Tonga", location_type: "Country" },
  {
    label: "Trinidad and Tobago",
    value: "Trinidad and Tobago",
    location_type: "Country",
  },
  { label: "Tunisia", value: "Tunisia", location_type: "Country" },
  { label: "Turkey", value: "Turkey", location_type: "Country" },
  { label: "Turkmenistan", value: "Turkmenistan", location_type: "Country" },
  { label: "Tuvalu", value: "Tuvalu", location_type: "Country" },
  { label: "Uganda", value: "Uganda", location_type: "Country" },
  { label: "Ukraine", value: "Ukraine", location_type: "Country" },
  {
    label: "United Arab Emirates",
    value: "United Arab Emirates",
    location_type: "Country",
  },
  {
    label: "United Kingdom",
    value: "United Kingdom",
    location_type: "Country",
  },
  { label: "United States", value: "United States", location_type: "Country" },
  { label: "Uruguay", value: "Uruguay", location_type: "Country" },
  { label: "Uzbekistan", value: "Uzbekistan", location_type: "Country" },
  { label: "Vanuatu", value: "Vanuatu", location_type: "Country" },
  { label: "Vatican City", value: "Vatican City", location_type: "Country" },
  { label: "Venezuela", value: "Venezuela", location_type: "Country" },
  { label: "Vietnam", value: "Vietnam", location_type: "Country" },
  { label: "Yemen", value: "Yemen", location_type: "Country" },
  { label: "Zambia", value: "Zambia", location_type: "Country" },
  { label: "Zimbabwe", value: "Zimbabwe", location_type: "Country" },
];
