import { IoClose } from "react-icons/io5";
import Button from "components/button";
import listIcon from "assets/images/Featured-icon.svg";
import { toast } from "react-toastify";
import archieveIcon from "assets/images/boxes.svg";
import { useEffect, useState } from "react";

const ArchiveModal = (props) => {
  const { isOpen, onClose, confirmArchive, prospect } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const toaster = () => {
    toast.success("List Archived", {
      icon: () => <img src={archieveIcon} alt="archieveIcon" />,
    });
  };
  useEffect(() => {
    if (isOpen) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [isOpen]);

  if (!isOpen && !isModalVisible) return null;

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[400ms] ease-in-out ${
        isModalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className="fixed inset-0 bg-black opacity-10"
        onClick={onClose}
      ></div>
      <div
        className={`bg-white p-[24px] rounded-lg modal-shadow z-10 w-full max-w-[600px] relative transition-all ease-in-out duration-[200ms] ${
          isModalVisible ? "scale-100" : "scale-95"
        }`}
      >
        <IoClose
          className="text-[#667085] text-[20px] w-[24px] h-[24px] absolute right-[24px] top-[26px] cursor-pointer"
          onClick={onClose}
        />
        <div className="modal-scroll">
          <div className="modal-scroll">
            <div className="flex flex-col items-start justify-start mb-[20px] font-inter">
              <div className="flex items-center justify-center mb-[16px]">
                <img
                  src={listIcon}
                  alt="info img"
                  className="h-[48px] w-[48px]"
                />
              </div>
              <h4 className="font-[600] text-[18px] text-[#101828] m-0 font-inter">
                {prospect?.is_archived ? "Unarchive" : "Archive"} List
              </h4>
              <p className="font-normal text-[14px] text-[#475467] font-inter">
                Are you sure you want to{" "}
                {prospect?.is_archived ? "unarchive" : "archive"} this list?
              </p>
            </div>
            <div className="flex items-center justify-center mt-[32px]">
              <Button
                title="Cancel"
                isWhiteBtn={true}
                isRounded="6px"
                onClick={onClose}
                className="!text-[16px] !h-[44px] !font-[600] !text-[#344054] y-axis-shadow !rounded-[8px]"
              />
              <Button
                title={prospect?.is_archived ? "Unarchive" : "Archive"}
                secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9] ml-[12px]"
                className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow !rounded-[8px]"
                isRounded="6px"
                onClick={confirmArchive}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArchiveModal;
