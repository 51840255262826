import ReactSelect from "components/select";
import { useState } from "react";
import { TiMinus } from "react-icons/ti";

const Department = (props) => {
  const {
    options,
    name,
    leadType,
    placeholder,
    formData,
    setFormData,
    handleNegativeNumbers,
  } = props;
  const isValidDepartment =
    Number(formData?.["minDepartment"]) >=
      Number(formData?.["maxDepartment"]) &&
    formData?.["minDepartment"] &&
    formData?.["maxDepartment"];
  const [searchText, setSearchText] = useState("");
  const onSelectChange = (event, keyName) => {
    if (["dateFounded"]?.includes(name)) {
      setFormData({
        ...formData,
        [keyName]: event,
      });
    } else {
      setFormData({
        ...formData,
        [name]: {
          values: [...event],
        },
      });
    }
  };

  const onInputChange = (event) => {
    let { name, value } = event?.target;
    value = value.replace(/^0+(?=\d)/, "");
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <>
      <ReactSelect
        placeholder={placeholder}
        onChange={onSelectChange}
        options={options}
        value={formData?.[name]?.values || []}
        isMulti={true}
        menuMaxHeight="200px"
        hideMultiSelectValues
      />
      {leadType === "department" && (
        <>
          <p className="text-[#344054] font-[500] text-[14px] mb-[11px] mt-[25px] font-inter">
            # Employees by Department
          </p>
          <div className="flex items-center">
            <input
              type="number"
              placeholder="min"
              className="text-[14px] font-[400] pb-[4px] text-[#1E1E1ED4] placeholder:text-[#B2B4B8] border-[1px] border-[#D0D5DD] text-center py-[2px] px-[12px] w-[74px] outline-none focus:!border-[#D0D5DD] bg-[#fff] rounded-[8px] h-[34px] y-axis-shadow"
              onChange={onInputChange}
              name="minDepartment"
              value={formData?.["minDepartment"]}
              onKeyPress={handleNegativeNumbers}
              min={0}
            />
            <TiMinus className="text-[14px] text-[#8A43FF] mx-[6px]" />
            <input
              type="number"
              placeholder="max"
              className="text-[14px] font-[400] pb-[4px] text-[#1E1E1ED4] placeholder:text-[#B2B4B8] border-[1px] border-[#D0D5DD] text-center py-[2px] px-[12px] w-[74px] outline-none focus:!border-[#D0D5DD] bg-[#fff] rounded-[8px] h-[34px] y-axis-shadow"
              onChange={onInputChange}
              name="maxDepartment"
              value={formData?.["maxDepartment"]}
              onKeyPress={handleNegativeNumbers}
              min={0}
            />
          </div>
          {isValidDepartment && (
            <p className="text-[11px] mt-[8px] text-[red]">
              Max value must be greater than min value.
            </p>
          )}
        </>
      )}
    </>
  );
};

export default Department;
