import axios from "axios";
import {
  getAccessToken,
  setAccessToken,
  clearTokens,
} from "utils/token-service";

const axiosInt = axios.create({
  accept: "application/json",
  baseURL: process.env.REACT_APP_API_KEY,
});

axiosInt.interceptors.request.use(
  async (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export async function initializeBaseURL(dynamicBaseURL) {
  axiosInt.defaults.baseURL = dynamicBaseURL || process.env.REACT_APP_API_KEY;
}

// Response interceptor to handle token refresh logic
axiosInt.interceptors.response.use(
  (response) => response?.data, // If the response is successful, just return it
  async (error) => {
    const originalRequest = error.config;

    // Check for 401 error and whether a retry is already attempted
    if (
      error.response?.status === 401 &&
      !originalRequest?._retry &&
      !["/login/google-login", "/login/google-signup"]?.includes(
        error?.config?.url
      )
    ) {
      originalRequest._retry = true;

      try {
        const accessToken = getAccessToken();

        // Try to refresh the token
        const refreshResponse = await axios.post(
          `${process.env.REACT_APP_API_KEY}login/refresh_token`,
          {
            old_token: accessToken,
          }
        );

        const newAccessToken = refreshResponse.data.token;
        setAccessToken(newAccessToken);

        // Update the original request with new token and retry
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return axiosInt(originalRequest); // Retry original request with new token
      } catch (refreshError) {
        // Token refresh failed, clear tokens and redirect
        clearTokens();
        window.location.href = "/";
        return Promise.reject(refreshError); // Reject the refresh error
      }
    }

    // If it's not a 401 or retry fails, reject the original error
    return Promise.reject(error?.response);
  }
);
export default axiosInt;
