import { useEffect, useState } from "react";
import Input from "components/textInput";
import cloudIllustration from "assets/images/cloud_illustration.gif";
import Button from "components/button";
import { toast } from "react-toastify";
import { useAddLinkedinLeads } from "services/campaigns-api";
import { useAccountStore, useCampaignStore } from "stores";
import sadIcon from "assets/images/sad-emoji.svg";

const AddLeadsCampaign = ({
  isOpen,
  onClose,
  setIsEnrichingLeadsOpen,
  availableLeads,
  setIsCheckingLeadsStatus,
  refetchStatus,
}) => {
  const { campaignId, setTrackerId } = useCampaignStore();
  const addLinkedinLeadsAPI = useAddLinkedinLeads();
  const [leadsValue, setLeadsValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { workspaceCreditLimit } = useAccountStore();

  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [isOpen]);

  if (!isOpen && !isModalVisible) return null;

  const handleInputChange = (e) => {
    let value = e?.target?.value;
    const sanitizedValue = value
      .replace(/[^0-9]/g, "")
      .replace(/^0+(?=\d)/, "")
      .replace(/\D/g, "");

    if (sanitizedValue === "") {
      setErrorMessage("");
      setLeadsValue();
      return;
    }

    const formattedValue = sanitizedValue.replace(/^0+(?!$)/, "");
    const numericValue = parseInt(formattedValue, 10);

    if (!isNaN(numericValue) && numericValue >= 0) {
      if (numericValue > Number(availableLeads)) {
        // Show "You do not have enough credits." toast for values between 99 and 235
        setErrorMessage("You don't have enough leads.");
        toast.success("You don't have enough leads.", {
          icon: () => <img src={sadIcon} alt="name" />,
        });
      } 
      else if(numericValue > Number(workspaceCreditLimit)){
        setErrorMessage("You don't have enough credits.");
        toast.success("You don't have enough credits.", {
          icon: () => <img src={sadIcon} alt="name" />,
        });
      }
      else {
        setErrorMessage("");
      }
      setLeadsValue(value);
    }
  };

  const handleEnrichLeads = async () => {
    if (leadsValue?.length > 0) {
      try {
        const response = await addLinkedinLeadsAPI.mutateAsync({
          campaign_id: campaignId,
          leads_count: Number(leadsValue),
          tracker_id: null,
        });
        setIsEnrichingLeadsOpen(true);
        setLeadsValue("");
        onClose();
        refetchStatus?.();
        setIsCheckingLeadsStatus(false);
        setTrackerId(response?.tracker_id);
      } catch (error) {
        toast.success(error?.data?.detail);
      }
    } else {
      toast.error("Please enter a number");
    }
  };

  return (
    <>
      <div
        className={`fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[200ms] ease-in-out ${
          isModalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
      >
        <div className="fixed inset-0 bg-black opacity-10" onClick={onClose} />
        <div
          className={`bg-white p-[24px] flex flex-col items-center rounded-lg shadow-lg z-10 w-full max-w-[500px] relative transition-all ease-in-out duration-[300ms] ${
            isModalVisible ? "scale-100" : "scale-95"
          }`}
        >
          <div className="flex items-center justify-center p-[11px]">
            <img
              src={cloudIllustration}
              alt="info img"
              className="h-[117px] w-[158px] ml-[-2px]"
            />
          </div>
          <h4 className="font-[600] text-center text-[16.49px] text-[#101828] mb-[7.3px]">
            Add Leads to Campaign
          </h4>
          <p className="font-normal text-center text-[12.8px] leading-[18px] text-[#475467]">
            Enrich leads from your prospected list.
          </p>
          <div className="mt-[16px] flex flex-col justify-center max-w-[320px]">
            <label className="block text-[#344054] font-medium text-[14px] mb-[6px]">
              How many leads would you like to enroll?
              <span className="text-[#7F56D9] mt-[-2px] inline-block ml-[1px]">
                *
              </span>
            </label>
            <Input
              name="leads"
              value={leadsValue}
              placeholder="e.g 50"
              className={`bg-white  !text-black border-[1px] !text-[14px] !m-0 max-w-[320px] placeholder-[#D0D0D0] outline-none ${
                errorMessage?.length > 0
                  ? "!border-[#FF1707] focus:!border-[#FF1707]"
                  : "border-[#D0D5DD] focus:!border-[#D0D5DD]"
              }`}
              onChange={handleInputChange}
              type={"tel"}
            />
            <p className="text-[14px] leading-[18px] text-[#475467] mt-[6px]">
              Leads Available:{" "}
              {availableLeads?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </p>
          </div>
          <div className="w-full flex items-center justify-center mt-[32px]">
            <Button
              title="Cancel"
              isWhiteBtn={true}
              isRounded="6px"
              onClick={() => {
                onClose();
                setLeadsValue("");
                setErrorMessage("")
              }}
              className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow !text-[#344054]"
            />
            <Button
              title={
                addLinkedinLeadsAPI?.isPending ? (
                  <div className="w-[20px] h-[20px] border-[3px] border-t-[3px] mx-auto border-[#fff] border-solid rounded-full animate-spin border-t-[#8A43FF]" />
                ) : (
                  "Enrich"
                )
              }
              secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9] ml-[12px]"
              className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow"
              isRounded="6px"
              disabled={errorMessage?.length > 0}
              onClick={handleEnrichLeads}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddLeadsCampaign;
