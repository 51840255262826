import ProspectingBreadcrumbHeader from "components/breadcrumbHeader";
import Layout from "components/layout";
import starsIcon from "assets/images/black-stars.svg";
import { useState, useEffect, useContext } from "react";
import chekedStep from "assets/images/check-filled-step.svg";
import filledStep from "assets/images/dot.svg";
import emptyStep from "assets/images/empty-white-step.svg";
import Button from "components/button";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "context/AppContext";
import { useCampaignStore } from "stores";

const CampaignStepsAnimation = () => {
  const { setAppState } = useContext(AppContext);
  const location = useLocation()
  const {
    setCampaign,
    newCampaign,
    clearIsDraftCampaignClicked,
    clearDraftListId,
    draftListId
  } = useCampaignStore();

  const [currentStep, setCurrentStep] = useState(1);
  const navigate = useNavigate();

  const steps = [
    { title: "Lead List", description: "Select a list and enrich your leads" },
    {
      title: "Build Campaign Steps",
      description: "Create the campaign sequence",
    },
    {
      title: "Customize Settings",
      description: "Name your campaign and choose notification settings",
    },
    {
      title: "Review your Leads",
      description: "Remove any you don’t want to enroll.",
    },
  ];

  useEffect(() => {
    if (currentStep <= steps.length) {
      const timer = setTimeout(() => {
        setCurrentStep((prevStep) => prevStep + 1);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [currentStep, steps.length]);

  const animationCompleted = currentStep - 1 === steps.length;
  const handleNavigate = (route) => {
    setCampaign(newCampaign);
    clearIsDraftCampaignClicked();
    navigate(route,{state:{prospect_id:draftListId}});
    clearDraftListId()
    setAppState((prev) => ({
      ...prev,
      isSendRequestCheckBoxChecked: true,
      selectedFollowUpMessageOptoin: {
        label: "First Name",
        value: "first_name",
      },
      selectedConnectionRequestMessageOption: {
        label: "First Name",
        value: "first_name",
      },
      existingConnectionMessageOption: {
        label: "First Name",
        value: "firstname",
      },
      sendConnectionRequestMessage: ``,
      followUpMessage: ``,
      existingConnectionMessage: ``,
      alternativeEmail: "",
      selectedLeadList: null,
      numberOfLeadsToEnroll: "",
      activeLeadListSteps: [0],
      activeCampaignName: "",
      leadsStepCompleted: false,
      stepsStepCompleted: false,
      settingsStepCompleted: false,
    }));
  };
  return (
    <Layout>
      <div className="h-[100vh] flex flex-col justify-start items-start font-inter">
        <ProspectingBreadcrumbHeader
          breadcrumbItems={[
            { title: "Campaigns", route: "/Campaigns" },
            { title: "New Campaign" },
          ]}
        />
        <div className="max-w-[100%] w-full px-[31px] py-[18px] h-full">
          <div className="flex items-center justify-center h-full w-full">
            <div
              className="bg-[#fff] w-[532px] rounded-[12px] p-[24px] pb-0"
              style={{ boxShadow: "0 20px 24px -4px #1018281A" }}
            >
              <div className="flex flex-col items-center justify-center mb-[20px] font-inter">
                <div className="flex items-center justify-center border-[1px] border-[#E4E7EC] rounded-[10px] p-[11px] mb-[16px] y-axis-shadow">
                  <img
                    src={starsIcon}
                    alt="starsIcon"
                    className="h-[24px] w-[24px]"
                  />
                </div>
                <h4 className="font-[600] text-[18px] text-[#101828] m-0 font-inter">
                  {animationCompleted
                    ? "Your campaign was created!"
                    : "Checking..."}
                </h4>
              </div>
              {/* steps */}
              <div className="relative left-12">
                {steps.map((step, index) => (
                  <div key={index} className="flex items-start">
                    <div className="relative">
                      <div
                        className={`relative rounded-full ${
                          currentStep === index + 1 ? "h-[27px] w-[27px]" : ""
                        }`}
                      >
                        {/* show empty */}
                        <img
                          src={emptyStep}
                          alt="emptyStep"
                          className="w-[27px] h-[27px] opacity-100"
                        />
                        {/* Show filledStep img */}
                        <img
                          src={filledStep}
                          alt="filledStep"
                          className={`w-[30px] h-[30px] rounded-full absolute top-0 left-0 transition-opacity delay-[60ms] duration-[1500ms] ease-in-out ${
                            currentStep === index + 1
                              ? "opacity-100"
                              : "opacity-0"
                          }`}
                        />
                        {/* Show chekedStep img */}
                        <img
                          src={chekedStep}
                          alt="chekedStep"
                          className={`w-[27px] h-[27px] absolute top-0 left-0 transition-opacity delay-[60ms] duration-[3000ms] ease-in-out ${
                            currentStep > index + 1
                              ? "opacity-100"
                              : "opacity-0"
                          }`}
                        />
                      </div>
                      {/* Vertical line between steps */}
                      {index < steps.length - 1 && (
                        <div className="relative flex items-center top-[5px] left-[48%] w-[2px] h-[32px]">
                          <div className="absolute top-0 left-0 bg-[#E4E7EC] w-[2px] h-full top origin-top"></div>
                          <div
                            className={`absolute top-0 left-0 bg-[#9E77ED] w-[2px] h-full top origin-top delay-[60ms] transition-transform duration-[1500ms] ease-in-out`}
                            style={{
                              transform: `scaleY(${
                                currentStep > index + 1 ? 1 : 0
                              })`,
                            }}
                          ></div>
                        </div>
                      )}
                    </div>
                    <div className="ml-[13px] mb-[27px]">
                      <h3
                        className={`font-[600] text-[16px] leading-[23px] m-0 ${
                          currentStep === index + 1
                            ? "text-[#6941C6]"
                            : "text-[#344054]"
                        }`}
                      >
                        {step.title}
                      </h3>
                      <p
                        className={`text-[14px] text-[#475467] leading-[19px] ${
                          currentStep === index + 1
                            ? "text-[#7F56D9]"
                            : "text-[#344054]"
                        }`}
                      >
                        {step.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <Button
                title="View Campaign"
                onClick={() =>
                  animationCompleted && handleNavigate("/default-activity")
                }
                className={`!bg-[#7F56D9] ${
                  animationCompleted ? "!opacity-100" : "!opacity-0"
                } transition-opacity !border-[#7F56D9] mb-[20px] mt-[15px] rounded-[8px] !text-[#fff] font-[600] font-inter text-[16px]`}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CampaignStepsAnimation;
