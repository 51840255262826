import React, { useState, useEffect } from "react";
import user from "assets/images/hd1.png";
import detail from "assets/images/hd2.png";
import add from "assets/images/hd3.png";
import social from "assets/images/hd4.png";

const steps = [
  {
    id: 1,
    title: "Connect Account",
    description: "Sync with Pipeline",
    icon: user,
  },
  {
    id: 2,
    title: "Enter Code",
    description: "Verify your account",
    icon: detail,
  },
  {
    id: 3,
    title: "Premium Checker",
    description: "Upgrade or confirm",
    icon: add,
  },
  {
    id: 4,
    title: "Customize Workspace",
    description: "Teams",
    icon: social,
  },
];

const Stepper = ({ step }) => {
  const [currentStep, setCurrentStep] = useState(step);

  useEffect(() => {
    setCurrentStep(step);
  }, [step]);

  return (
    <div className="flex justify-center items-center py-4 font-inter">
      {steps.map((stepItem, index) => {
        const isLastStep = index === steps.length - 1;
        const isActive = index <= currentStep - 1;

        return (
          <div key={stepItem.id} className={`w-[240px] flex items-center ${isLastStep ? 'mr-0' : 'mr-[20px]'}`}>
            <div className="flex flex-col items-center">
              <div className="relative mb-[12px]">
                <img
                  src={stepItem.icon}
                  alt={stepItem.title}
                  className={`w-[40px] min-w-[40px] h-[40px] relative z-10 ${currentStep < index ? 'custom-blur' :'custom-brightness'}`}
                />
                {!isLastStep && (
                  <div
                    className={`absolute translate-y-1/2 top-[50%] left-[38px] w-[250px] h-[2px] transition-all duration-500 ${
                      isActive ? "bg-[#CECFD2]" : "bg-[#CECFD221]"
                    }`}
                  ></div>
                )}
              </div>
              <div
                className={`text-[#CECFD2] text-[14px] font-[600] leading-[20px] ${
                  currentStep >= index ? "opacity-100" : "opacity-50"
                } transition-opacity duration-500`}
              >
                {stepItem.title}
              </div>
              <div
                className={`text-[#94969C] text-[14px] font-[400] leading-[20px] m-0 ${
                  currentStep >= index ? "opacity-100" : "opacity-50"
                } transition-opacity duration-500`}
              >
                {stepItem.description}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Stepper;
