import CommonCheckbox from "components/commonCheckbox";
import heartIcon from "assets/images/like-heart.svg";
import starIcon from "assets/images/post-star.svg";
import SwitchToggle from "components/switchToggle";
import { useState, useEffect } from "react";
import { MdOutlineInfo } from "react-icons/md";

const LikeComment = ({ value, handleStepValueChange, index }) => {
  const [formData, setFormData] = useState({
    num: "1",
    checked: true,
  });

  useEffect(() => {
    if (value && value !== "string") {
      setFormData({
        num: value ? JSON.parse(value)?.num || "1" : "1",
        checked: value ? JSON.parse(value)?.checked || false : true,
      });
    }
  }, [value]);

  const handleInputChange = (e, fieldName, max) => {
    const inputValue = e.target.value;
    const regex = /^[0-9]*$/;

    if (
      inputValue === "" ||
      (regex.test(inputValue) &&
        (max === undefined || Number(inputValue) <= max))
    ) {
      setFormData((prev) => {
        const newFormData = { ...prev, [fieldName]: inputValue };
        if (prev.checked) {
          handleStepValueChange(JSON.stringify(newFormData), index);
        }

        return newFormData;
      });
    }
  };

  const toggleSwitch = () => {
    setFormData((prev) => {
      const newCheckedState = !prev.checked;
      const newFormData = { ...prev, checked: newCheckedState };
      if (newCheckedState) {
        handleStepValueChange(JSON.stringify(newFormData), index);
      } else {
        handleStepValueChange(
          JSON.stringify({ ...newFormData, num: "" }),
          index
        );
      }
      return newFormData;
    });
  };

  return (
    <div className="p-[14px] bg-[#F9F5FF] rounded-lg border-[2px] border-[#7F56D9] shadow-sm w-[373px] font-inter">
      <div className="flex items-start ">
        <div className="flex items-start w-full">
          <img src={heartIcon} alt="" className="w-[27px] h-[27px] ml-[-2px]" />
          <div className="ml-[10px]">
            <h2 className="font-inter text-[12px] font-[500] text-[#53389E]">
              Like a Comment
            </h2>
            <div className="flex items-start">
              <div className="start-campaign-toggle mr-[6px] h-[14px] mt-[2px]">
                <SwitchToggle
                  checked={formData.checked}
                  onChange={toggleSwitch}
                />
              </div>
              <p
                className={`font-inter text-[12px] font-[400] m-0 ${
                  !formData.checked ? "text-[#808897]" : "text-[#6941C6]"
                }`}
              >
                Sentiment Analysis
              </p>
            </div>
          </div>
        </div>
        <div className="mr-[16px]">
          <CommonCheckbox isRound={true} checked={true} />
        </div>
      </div>
      <div
        className={` ${
          !formData.checked
            ? "h-0 pt-[0px] overflow-hidden opacity-0"
            : "h-[140px] pt-[3px] opacity-100"
        } transition-all duration-500 ease-in-out`}
      >
        <div className="flex items-start my-[10px]">
          <img
            src={starIcon}
            alt="starIcon"
            className="w-[15px] h-[15px] mt-[2px] mr-[6px]"
          />
          <p className="font-inter font-[400] text-[11px] text-[#6941C6] leading-[15px]">
            Pipeline will use AI to assess whether a comment has positive
            sentiment, helping to avoid liking a comment that isn't desired.
          </p>
        </div>
        <div className="flex items-center px-1 mb-[16px]">
          <p className="text-[#6941C6] font-[400] text-[12px]">
            Like a comment no older than
          </p>
          <input
            type="tel"
            placeholder="0"
            value={formData.num}
            onChange={(e) => handleInputChange(e, "num", 12)}
            className="text-[20px] w-[65px] h-[26px] !p-1 text-center border-[#D0D5DD] rounded-[4px] y-axis-shadow mx-[10px] font-[500] bg-[#FFFFFF] focus:!outline-none text-[#8D48FF] !outline-none placeholder:text-[#D0D5DD] focus:!border-[#D0D5DD]"
          />
          <p className="text-[#6941C6] font-[400] text-[12px]">month.</p>
        </div>
        <div className="flex items-start">
          <MdOutlineInfo className="w-[13px] h-[13px] mt-[1px] mr-[5px] text-[#808897] text-[18px]" />
          <p className="font-inter font-[400] text-[11px] text-[#808897] m-0">
            If the lead does not have a comment to like, it will skip this step.
          </p>
        </div>
      </div>
    </div>
  );
};

export default LikeComment;
