import ProgressBar from "components/progressBar";
import cloudIllustration from "assets/images/cloud_illustration.gif";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import checkIcon from "assets/images/name-check.svg";

const AddLeadingAnimationModal = ({ isOpen, onClose }) => {
  const [progress, setProgress] = useState(0);
  const [isFadingOut, setIsFadingOut] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      // If modal is not open, reset fading state
      setIsFadingOut(false);
      return;
    }

    setProgress(0); 

    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          setTimeout(() => {
            // Start fade-out animation
            setIsFadingOut(true);
            setTimeout(() => {
              onClose(); // Close the modal after fade-out
              toast.success("New leads were added to your campaign", {
                icon: () => <img src={checkIcon} alt="name" />,
              });
            }, 1000); // Delay the toast notification to match fade-out duration
          }, 1000); 
          return 100; 
        }
        return prev + 15; 
      });
    }, 200); 

    return () => clearInterval(interval); 
  }, [isOpen, onClose]);

  if (!isOpen && !isFadingOut) return null; 

  return (
    <div
      className={`fixed inset-0 flex flex-col items-center justify-center z-50 font-inter transition-opacity duration-1000 ease-in-out ${
        isFadingOut ? 'opacity-0' : 'opacity-100'
      }`}
    >
      <div className="fixed inset-0 bg-black opacity-10" onClick={onClose} />
      <div className="bg-white p-[24px] flex flex-col items-center rounded-lg shadow-lg z-10 w-full max-w-[500px] relative">
        <div className="flex items-center justify-center p-[11px]">
          <img
            src={cloudIllustration}
            alt="info img"
            className="h-[117px] w-[158px] ml-[-2px]"
          />
        </div>
        <h4 className="font-[600] text-center text-[16.5px] text-[#101828] mb-[34.4px]">
        Adding Leads
        </h4>
        <div className="w-[180px]">
          <ProgressBar percentage={progress} hideProgressStatus={true} />
        </div>
      </div>
    </div>
  );
};

export default AddLeadingAnimationModal;
