import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import * as Sentry from "@sentry/react";

if (window?.location?.host === "dev-app.withpipeline.com") {
  Sentry.init({
    dsn: "https://879d5623cfd2ff538dc1870b6c891915@o4507983419146240.ingest.us.sentry.io/4508000601243648",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    tracePropagationTargets: [
      "https://dev-app.withpipeline.com",
      "https://dev-app-api.withpipeline.com"
    ],
    replaysSessionSampleRate: 0.1, // Sample rate at 10%
    replaysOnErrorSampleRate: 1.0, // Sample rate at 100% on errors
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

reportWebVitals();

