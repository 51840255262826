import Layout from "components/layout";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "components/button";
import importData from "assets/images/import.svg";
import deleteImg from "assets/images/trash.svg";
import Tabs from "components/tabs";
import { useContext, useEffect, useRef, useState } from "react";
import ProspectingTable from "components/prospecting/prospectingTable";
import BreadcrumbHeader from "components/breadcrumbHeader";
import { MdOutlineAdd } from "react-icons/md";
import EditListModal from "components/prospecting/prospectingSettingModals/editListModal";
import ArchiveModal from "components/prospecting/prospectingSettingModals/archiveModal";
import DeleteLeadModal from "components/prospecting/prospectingSettingModals/deleteLeadModal";
import { AppContext } from "context/AppContext";
import { prospectingTabs } from "constant";
import archieveIcon from "assets/images/boxes.svg";
import CommonModal from "components/prospecting/modal";
import ShareLinkModal from "components/prospecting/prospectingSettingModals/shareLinkModal";
import EmptyPage from "components/emptyPageComponent";
import BillingFailureTeamMember from "components/subscriptionModals/billingFailureTeamMember";
import {
  useArchiveProspect,
  useDeleteProspect,
  useGetProspectsList,
} from "services/prospecting-api";
import { toast } from "react-toastify";
import { useProspectStore } from "stores";
import useVisitorModalStore from "stores/visitorModalStore";
import useToastStore from "stores/toastStore";
import ProspectingTableSkeleton from "components/skeletonScreens/prospectingTableSkeleton";
import ReconnectBanner from "components/reconnectBanner";
import { Tooltip } from "react-tooltip";

const Prospecting = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { isConnectLinkedIn, setIsConnectLinkedIn } = useVisitorModalStore();
  const getProspectsListMutation = useGetProspectsList();
  const archiveProspectListMutation = useArchiveProspect();
  const deleteProspectList = useDeleteProspect();
  const { setProspectList, prospectList } = useProspectStore();
  const { toastId, clearToastId } = useToastStore();
  const [tab, setTab] = useState("All Lists");
  const tableRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const { setAppState } = useContext(AppContext);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [billingFailureTeamMember, setBillingFailureTeamMember] =
    useState(false);
  const [sortBy, setSortBy] = useState("created_date");
  const [sortOrder, setSortOrder] = useState("desc");
  const [paginationData, setPaginationData] = useState({
    limit: 20,
    page: 1,
  });
  const [edit, setEdit] = useState("");
  const [totalItems, setTotalItems] = useState(null);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const prospectId = currentId?.prospect_id;

  const fetchProspectList = async (sort_by, sort_order) => {
    try {
      const response = await getProspectsListMutation.mutateAsync({
        limit: paginationData?.limit,
        page: paginationData?.page,
        sort_by: sort_by,
        sort_order: sort_order,
        active_tab:
          prospectingTabs?.find((item) => item?.label === tab)?.value ||
          "all_list",
      });
      if (paginationData?.page === 1) {
        setProspectList([...response?.data]);
      } else {
        setProspectList([...prospectList, ...response?.data]);
      }
      setTotalItems(response?.total);
      setIsLoadingData(false);
    } catch (error) {
      console.error(error);
      setIsLoadingData(false);
    }
  };

  useEffect(() => {
    if (state === "search") {
      setTab("My Lists");
    }
  }, [state]);

  useEffect(() => {
    fetchProspectList(sortBy, sortOrder);
  }, [sortBy, sortOrder, paginationData]);

  useEffect(() => {
    setIsLoadingData(true);
    setPaginationData({
      limit: 20,
      page: 1,
    });
  }, [tab]);

  // sorting onchange
  const handleSortChange = (columnKey) => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortBy(columnKey);
    setSortOrder(newSortOrder);
    setPaginationData({
      limit: 20,
      page: 1,
    });
  };

  useEffect(() => {
    if (tableRef?.current) {
      tableRef.current.scrollTop = 0;
    }
  }, [sortBy, sortOrder]);

  const confirmDelete = async () => {
    try {
      const res = await deleteProspectList.mutateAsync({
        prospect_id: currentId,
      });
      setIsDeleteModalOpen(false);
      toast.success(`List Deleted`, {
        icon: () => <img src={deleteImg} alt="delete" />,
      });
      navigate("/prospecting");
      fetchProspectList(sortBy, sortOrder);
      setCurrentId(null);
    } catch (error) {
      toast.error(error?.data?.detail);
      setIsDeleteModalOpen(false);
      setCurrentId(null);
      console.error(error);
    }
  };

  const confirmArchive = async () => {
    const payload = {
      prospect_id: currentId,
      archived_status: true,
    };
    try {
      const response = await archiveProspectListMutation.mutateAsync(payload);
      fetchProspectList(sortBy, sortOrder);
      setIsArchiveModalOpen(false);
      toast.success("List Archived", {
        icon: () => <img src={archieveIcon} alt="archieveIcon" />,
      });
    } catch (error) {
      toast.success("error");
    }
  };

  const confirmUnarchive = async (id) => {
    const payload = {
      prospect_id: id,
      archived_status: false,
    };
    try {
      const response = await archiveProspectListMutation.mutateAsync(payload);
      fetchProspectList();
      setIsArchiveModalOpen(false);
    } catch (error) {
      toast.success("error");
    }
  };

  const fetchData = () => {
    if (totalItems === prospectList?.length) {
      setHasMore(false);
      return;
    }
    setTimeout(() => {
      setPaginationData((prev) => ({
        limit: 20,
        page: (prev?.page || 0) + 1,
      }));
    }, 500);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === "i") {
        event.preventDefault();
        setIsModalOpen(true);
      }
      if (event.key === "Escape") {
        setIsModalOpen(false);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  const isEmptyTab = tab === "Shared" || tab === "Imported" || tab === "Archive";

  return (
    <Layout>
      <div className="h-[100vh] flex flex-col justify-start items-start font-inter">
        <BreadcrumbHeader
          breadcrumbItems={[
            { title: "Prospecting", route: "/prospecting" },
            { title: "Lead Lists" },
          ]}
          setTab={setTab}
        />
        <ReconnectBanner />
        <div
          className="max-w-[100%] w-full px-[31px] py-[18px] h-[calc(100vh_-45px)] !overflow-hidden innerScrollBar"
          data-aos="fade-left"
        >
          <div className="flex items-center justify-between w-full">
            <h1 className="text-[32px] text-left text-[#252C32] font-semibold leading-normal mb-[8px]">
              Prospecting
            </h1>
            {prospectList?.length > 0 && (
              <div className="flex items-center w-full justify-end">
                <div className="mr-[9px]" data-tooltip-id="importData">
                  <Button
                    isWhiteBtn={true}
                    isFullWidth={false}
                    className="y-axis-shadow !rounded-[8px]"
                    title={
                      <div className="flex items-center justify-center font-inter font-[600] text-[14px] !text-[#111827] !border-[#D1D5DB] mt-[-2.5px]">
                        <img
                          src={importData}
                          alt="import"
                          className="mr-[6px] w-[16px] h-[16px] mt-1"
                        />
                        <p className="pt-[3px]">Import Data</p>
                      </div>
                    }
                    onClick={() => {
                      setIsModalOpen(true);
                    }}
                  />
                </div>
                <Tooltip
                  delayShow={2000}
                  id="importData"
                  className={`actionButton-shadow !bg-[#F9FAFB] !z-[9999] !rounded-[8px] !py-[6px] !pl-[9px] !pr-[12px]`}
                  place={"left"}
                >
                  <p className="font-[400] text-[#485467] text-[13px] font-inter">
                    ⌘+I
                  </p>
                </Tooltip>
                <Button
                  isFullWidth={false}
                  gradientBg={true}
                  height="32px"
                  className="!rounded-[8px] !pr-[8px]"
                  title={
                    <div className="flex items-center justify-center font-inter !text-[14px] !font-[600]">
                      Find Leads
                      <MdOutlineAdd className="text-[18px] ml-[7px] mt-[1px]" />
                    </div>
                  }
                  onClick={() => navigate("/findLeads")}
                />
              </div>
            )}
          </div>
          <div className="z-[9999] relative">
            <Tabs
              tab={tab}
              setTab={setTab}
              tabOptions={prospectingTabs}
              //  disableTab={!prospectList?.length}
            />
          </div>
          <div className="mt-5">
            {isLoadingData ? (
              <ProspectingTableSkeleton />
            ) : prospectList?.length > 0 ? (
              <ProspectingTable
                tableRef={tableRef}
                isEditModalOpen={isEditModalOpen}
                setIsEditModalOpen={setIsEditModalOpen}
                isArchiveModalOpen={isArchiveModalOpen}
                setIsArchiveModalOpen={setIsArchiveModalOpen}
                isDeleteModalOpen={isDeleteModalOpen}
                setIsDeleteModalOpen={setIsDeleteModalOpen}
                isShareModalOpen={isShareModalOpen}
                setIsShareModalOpen={setIsShareModalOpen}
                tableData={prospectList}
                tab={tab}
                setCurrentId={setCurrentId}
                menuIsOpen={menuIsOpen}
                confirmUnarchive={confirmUnarchive}
                sortBy={sortBy}
                sortOrder={sortOrder}
                handleSortChange={handleSortChange}
                totalItems={totalItems}
                isLoadingData={isLoadingData}
                nextFetchData={fetchData}
                hasMore={hasMore}
                prospectList={prospectList?.length || 0}
              />
            ) : (
              <div className="flex items-center justify-center h-[calc(100vh_-400px)] w-full">
                {isEmptyTab ? (
                  <EmptyPage
                    title="No Lead Lists found"
                    description=""
                    showButtons={false}
                  />
                ) : (
                  <EmptyPage
                    title="No Lead Lists found"
                    description="You’ll need to create a list to start your first campaign!"
                    whiteButtonTitle="Import Data"
                    whiteButtonClass="!w-[128px]"
                    primaryButtonTitle="Find Leads"
                    primaryButtonClass="!w-[146px]"
                    onClick={() => setIsModalOpen(true)}
                    onPrimaryClick={() => navigate("/findLeads")}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {/* modal */}
      <CommonModal
        isOpen={isModalOpen || isConnectLinkedIn}
        onClose={() => {
          setIsConnectLinkedIn(false);
          setIsModalOpen(false);
          clearToastId();
          toast.dismiss(toastId);
          setAppState((prev) => ({
            ...prev,
            saveListName: "",
            addProspectingData: true,
            isDeleteCsv: false,
          }));
        }}
      />

      <EditListModal
        isOpen={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false);
          setCurrentId(null);
        }}
        currentId={currentId}
        fetchProspectList={() => fetchProspectList(sortBy, sortOrder)}
        setEdit={setEdit}
        edit={edit}
        prospectId={prospectId}
      />
      <ArchiveModal
        isOpen={isArchiveModalOpen}
        onClose={() => {
          setIsArchiveModalOpen(false);
          setCurrentId(null);
        }}
        confirmArchive={confirmArchive}
      />
      <DeleteLeadModal
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
          setCurrentId(null);
        }}
        confirmDelete={confirmDelete}
        currentId={currentId}
        isLoading={deleteProspectList?.isPending}
      />
      <ShareLinkModal
        isOpen={isShareModalOpen}
        onClose={() => {
          setIsShareModalOpen(false);
          setCurrentId(null);
        }}
        isProspectingTableShareModal
        currentId={currentId}
      />
      <BillingFailureTeamMember
        isOpen={billingFailureTeamMember}
        onClose={() => {
          setBillingFailureTeamMember(false);
        }}
      />
    </Layout>
  );
};

export default Prospecting;
