import { useMutation, useQuery } from "@tanstack/react-query";
import axiosInt from "services";

const useCreatePaymentLink = () =>
  useMutation({
    mutationFn: async ({ price_id, customer_mail_id }) => {
      const response = await axiosInt.post(
        `/payment/payment_link_creation?price_id=${price_id}&customer_mail_id=${customer_mail_id}`,
        {}
      );
      return response;
    },
  });

const usePaymentMethods = ({ user_id }) =>
  useQuery({
    queryKey: ["paymentMethods", user_id],
    queryFn: async () => {
      const response = await axiosInt.get(
        `/payment/payment-methods/user/${user_id}`
      );
      return response;
    },
    enabled: !!user_id,
  });

const usePaymentBillInfo = ({ user_id }) =>
  useQuery({
    queryKey: ["billingInfo", user_id],
    queryFn: async () => {
      const response = await axiosInt.get(`/payment/billing-info/${user_id}`);
      return response;
    },
    enabled: !!user_id,
  });

const useAddPaymentMethod = () =>
  useMutation({
    mutationFn: async ({ payment_method_id }) => {
      const response = await axiosInt.post(`/payment/add_payment_methods`, {
        payment_method_id,
      });
      return response;
    },
  });

const usePaymentStatus = ({ user_id }) =>
  useQuery({
    queryKey: ["paymentStatus", user_id],
    queryFn: async () => {
      const response = await axiosInt.get(`/payment/auth_check`);
      return response;
    },
    // enabled: !!user_id,
  });

const useCompanySubscriptionStatus = ({ user_id }) =>
  useQuery({
    queryKey: ["companySubscriptionStatus", user_id],
    queryFn: async () => {
      const response = await axiosInt.get(
        `/payment/comapny_subscription_status`
      );
      return response;
    },
    enabled: !!user_id,
  });

const useCancelSubscription = () =>
  useMutation({
    mutationFn: async () => {
      const response = await axiosInt.put(`/payment/subscription/cancel`);
      return response;
    },
  });
const useReActivateSubscription = () =>
    useMutation({
      mutationFn: async () => {
        const response = await axiosInt.put(`/payment/subscription/reactivate`);
        return response;
      },
    });

const usePurchaseCredits = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.post(`/payment/purchase_credits`, data);
      return response;
    },
  });

const useUpdateSubscriptionStatus = () =>
  useMutation({
    mutationFn: async () => {
      const response = await axiosInt.put(`/payment/update_subscription_status`);
      return response;
    },
  });
export {
  useCreatePaymentLink,
  usePaymentMethods,
  usePaymentBillInfo,
  useAddPaymentMethod,
  usePaymentStatus,
  useCancelSubscription,
  usePurchaseCredits,
  useCompanySubscriptionStatus,
  useUpdateSubscriptionStatus,
  useReActivateSubscription
};
