import Button from "components/button";
import listIcon from "assets/images/save-list.svg";
import OutlinedInput from "components/outlinedInput";
import { useContext, useState } from "react";
import { AppContext } from "context/AppContext";
import { useEditProspectName } from "services/prospecting-api";
import { toast } from "react-toastify";

const SaveListModalStep = ({ setStep, onClose, prospectId }) => {
  const { appState, setAppState } = useContext(AppContext);
  const editNameMutation = useEditProspectName();
  const handleNameEdit = async () => {
    if (appState?.saveListName === "") {
      return toast.error("Please enter name");
    }
    try {
      const response = await editNameMutation.mutateAsync({
        prospect_id: prospectId,
        prospect_name: appState?.saveListName,
      });
      if (response.status === 200) {
        if (appState?.importMethodClicked === "manually-default") {
          setStep("succes-modal");
        } else {
          setStep("add-team-members");
        }
      }
    } catch (error) {
      if (error?.data && error?.data?.detail) {
        toast.error(error.data.detail);
      } else {
        toast.error("An unknown error occurred");
      }
    }
  };
  return (
    <div className="modal-scroll">
      <div className="flex flex-col items-start justify-start mb-[20px] font-inter">
        <div className="flex items-center justify-center border-[1px] border-[#E4E7EC] rounded-[10px] p-[11px] mb-[16px] y-axis-shadow">
          <img src={listIcon} alt="info img" className="h-[24px] w-[24px]" />
        </div>
        <h4 className="font-[600] text-[18px] text-[#101828] m-0 font-inter">
          Save Lead List
        </h4>
        <p className="font-normal text-[14px] text-[#475467] font-inter">
          {appState?.importMethodClicked === "uploadCampanyCsv"
            ? "Enter a name for your prospected lead list."
            : "Enter a name for your imported lead list."}
        </p>
      </div>
      <OutlinedInput
        type="text"
        placeholder="Enter a Name"
        value={appState?.saveListName || ""}
        onChange={(e) =>
          setAppState((prev) => ({ ...prev, saveListName: e?.target?.value }))
        }
      />
      <div className="flex items-center justify-center mt-[32px]">
        {/* <Button
          title="Back"
          isWhiteBtn={true}
          isRounded="6px"
          onClick={() => {
            setStep("enrich-leads-step")
          }}
          className="!text-[16px] !h-[44px] !font-[600] !text-[#344054] y-axis-shadow"
        /> */}
        <Button
          title={
            editNameMutation?.isPending ? (
              <div className="w-[20px] h-[20px] border-[3px] border-t-[3px] mx-auto border-[#fff] border-solid rounded-full animate-spin border-t-[#8A43FF]" />
            ) : (
              "Save"
            )
          }
          secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9]"
          className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow"
          isRounded="6px"
          onClick={() => {
            handleNameEdit();
          }}
        />
      </div>
    </div>
  );
};

export default SaveListModalStep;
