import Loader from "components/loader";
import Logo from "assets/images/linkedin-connect.png";
import { FiEye, FiEyeOff } from "react-icons/fi";
import Select from "react-select";
import AnimatedButton from "components/animatedButton";

const StepSignIn = ({
  isLoading,
  handleSubmit,
  onSubmit,
  register,
  errors,
  inputClasses,
  passwordVisible,
  setPasswordVisible,
  locations,
  setSelectedLocation,
  countrySelectStyle,
  fadeIn,
  onSearchLocation,
}) => (
  <div
    className="!w-[400px] !h-full mx-auto flex flex-col justify-center items-center !py-[36px] px-[30px] signin-shadow-bg rounded-[12px] mb-[20px] relative signin-card"
    data-aos={fadeIn && "fade-in"}
    data-aos-duration={fadeIn && "2000"}
  >
    <div className="w-full">
      <img src={Logo} alt="Logo" className="h-[44px] w-[126px] mx-auto block" />
      <h1 className="text-[28px] text-center text-[#F8F8F8] font-[600] mt-[20px] mb-[11px]">
        Sign in to LinkedIn
      </h1>
      <form className="mt-[20px] w-full" onSubmit={handleSubmit(onSubmit)}>
        {/* {isLoading ? (
          <div className="h-[235px] flex justify-center items-center">
            <Loader />
          </div>
        ) : ( */}
        <>
          {/* Email Input */}
          <p className="text-[14px] font-[500] text-[#CECFD2] mb-[6px]">
            Email
          </p>
          <input
            type="email"
            placeholder="Enter your email"
            className={`${inputClasses} !bg-[#0d0515] text-[#9e9ba0] focus-visible:!border-[#9e9ba0] ${
              errors.email ? "mb-1" : "mb-[10px]"
            }`}
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /^\S+@\S+$/i,
                message: "Invalid email address",
              },
            })}
            onDrop={(e) => e.preventDefault()}
            onDragOver={(e) => e.preventDefault()}
          />
          {errors.email && (
            <p className="text-red-500 text-xs mb-1.5">
              {errors.email.message}
            </p>
          )}

          {/* Password Input */}
          <p className="text-[14px] font-[500] text-[#CECFD2] mb-[6px]">
            Password
          </p>
          <div className="relative">
            <input
              type={passwordVisible ? "text" : "password"}
              placeholder="Enter your password"
              className={`${inputClasses} !bg-[#0d0515] text-[#9e9ba0] focus-visible:!border-[#9e9ba0] ${
                errors.password ? "mb-1" : "mb-[10px]"
              }`}
              {...register("password", { required: "Password is required" })}
              onDrop={(e) => e.preventDefault()}
              onDragOver={(e) => e.preventDefault()}
            />
            <div onClick={() => setPasswordVisible(!passwordVisible)}>
              {passwordVisible ? (
                <FiEye
                  className="absolute right-[15px] z-50 top-[14px] cursor-pointer"
                  color="#CBCBE866"
                />
              ) : (
                <FiEyeOff
                  className="absolute right-[15px] z-50 top-[14px] cursor-pointer"
                  color="#CBCBE866"
                />
              )}
            </div>
            {errors.password && (
              <p className="text-red-500 text-xs mb-1.5">
                {errors.password.message}
              </p>
            )}
          </div>

          {/* Location Select */}
          <p className="text-[14px] font-[500] text-[#CECFD2] mb-[6px]">
            Location
          </p>
          <div className="!bg-[#0d0515] linkedin-select w-full border border-[#333741] rounded-[7px] z-50 !mb-[17px] cursor-pointer no-border-select">
            <Select
              options={locations}
              classNamePrefix="leads-react-select"
              className="!font-inter w-full !border-none !border-black"
              placeholder="Search your country"
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              maxMenuHeight="128px"
              styles={countrySelectStyle}
              isClearable
              isSearchable={true}
              noOptionsMessage={() => "No Results"}
              onChange={(selectedOption) => setSelectedLocation(selectedOption)}
              isLoading={isLoading}
              onInputChange={(e) => {
                onSearchLocation(e);
              }}
            />
          </div>
        </>
        {/* )} */}
        {/* Agreement Text */}
        <div className="w-full justify-center flex mb-[17px]">
          <p className="text-[#ACB0B6] text-[10px] text-center w-[250px]">
            By clicking <span className="font-[600]">Connect</span>, you are
            authorizing us to access and take actions through your LinkedIn
            account.
          </p>
        </div>
        {/* Submit Button */}
        <AnimatedButton
          className="!text-[15px] !font-[600] !h-[42px] !rounded-[7px] !bg-[#635BFF]"
          fontSize="!text-[15px]"
          title="Connect"
        />
      </form>
    </div>
  </div>
);

export default StepSignIn;
