const Loader = ({ isAiGenerateLoader = false }) => {
  return (
    <div
      className={`flex space-x-2 justify-center items-center ${
        isAiGenerateLoader && "space-x-1"
      }`}
    >
      <span className="sr-only">Loading...</span>
      <div
        className={`h-[8px] w-[8px] bg-[#D9D9D9] rounded-full ${
          isAiGenerateLoader
            ? "ai-generate-loader !h-[3px] !w-[3px]"
            : "custom-bounce"
        }`}
        style={{ animationDelay: ".3s" }}
      ></div>
      <div
        className={`h-[8px] w-[8px] bg-[#D9D9D9] rounded-full ${
          isAiGenerateLoader
            ? "ai-generate-loader !h-[3px] !w-[3px]"
            : "custom-bounce"
        }`}
        style={{ animationDelay: ".8s" }}
      ></div>
      <div
        className={`h-[8px] w-[8px] bg-[#D9D9D9] rounded-full ${
          isAiGenerateLoader
            ? "ai-generate-loader !h-[3px] !w-[3px]"
            : "custom-bounce"
        }`}
        style={{ animationDelay: "1.2s" }}
      ></div>
    </div>
  );
};

export default Loader;
