import { create } from "zustand";

const useRandomImagesStore = create((set) => ({
  profileImage: "",
  companyImage: "",
  setProfileImage: (profileImage) => set({ profileImage }),
  clearProfileImage: () => set({ profileImage: "" }),
  setCompanyImage: (companyImage) => set({ companyImage }),
  clearCompanyImage: () => set({ companyImage: "" }),
}));


export default useRandomImagesStore;