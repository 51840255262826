import cloudIllustration from "assets/images/cloud_illustration.gif";

const SettingDomainAnimationModal = ({ isOpen, onClose}) => {

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed inset-0 flex flex-col items-center justify-center z-50 font-inter">
        <div className="fixed inset-0 bg-black opacity-10" onClick={onClose} />
        <div className="bg-white p-[24px] flex flex-col items-center rounded-lg shadow-lg z-10 w-full max-w-[600px] relative">
          <div className="flex items-center justify-center p-[11px]">
            <img
              src={cloudIllustration}
              alt="info img"
              className="h-[117px] w-[158px] ml-[-2px]"
            />
          </div>
          <h4 className="font-[600] text-center text-[16.5px] text-[#101828] mb-[7.3px]">
            Adding Domains to your Blocklist...
          </h4>
          <div className="max-w-[322px]">
            <p className="font-normal text-center text-[12.8px] leading-[18px] text-[#475467] m-0">
              Thanks for your patience.
            </p>
            <p className="font-normal text-center text-[12.8px] leading-[18px] text-[#475467]">
              Depending on how many domains you imported, this could take a few
              minutes.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingDomainAnimationModal;
