import { Tooltip } from "react-tooltip";

const CommonReactTooltip = ({ id, icon, title, description, placement ,className}) => {
  return (
    <Tooltip
      id={id}
      className={`actionButton-shadow !bg-white !z-[9999] !rounded-[8px] !p-[12px] ${className}`}
      place={placement}
    >
      <div className="flex items-center mb-[5px]">
        <img
          src={icon}
          alt="icon"
          className="w-[20px] h-[15px] min-w-[20px] mr-[4px]"
        />
        <p className="text-[#7F56D9] text-[600] text-[12px] font-semibold">
          {title}
        </p>
      </div>
      <p className="font-[500] text-[#475467] text-[12px]">
        {description}
      </p>
    </Tooltip>
  );
};
export default CommonReactTooltip;