import { useMutation, useQuery } from "@tanstack/react-query";
import axiosInt from "services";

const useCampaignCreateSequence = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.post("/linkedin/create_sequences", data);
      return response;
    },
  });

const useCampaignAddSettings = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.post("/linkedin/add_settings", data);
      return response;
    },
  });

const useLaunchCampaign = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.put("/linkedin/launch_campaign", data);
      return response;
    },
  });

const useCampaignList = (data) =>
  useQuery({
    queryFn: async () => {
      const { type, limit, sort_by, sort_order, page } = data;
      const response = await axiosInt.get(
        `/linkedin/list_campaign?type=${type}&limit=${limit}&page=${page}&sort_by=${sort_by}&sort_order=${sort_order}`
      );
      return response;
    },
  });

const useArchiveCampaign = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.put("/linkedin/archive_campaign", data);
      return response;
    },
  });

const useEditCampaignName = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.put("/linkedin/edit_campaign_name", data);
      return response;
    },
  });

const usePauseCampaignStatus = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.put(
        "/linkedin/campaign_pause_status",
        data
      );
      return response;
    },
  });

const useDeleteCampaign = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.delete("/linkedin/campaign_delete", {
        data,
      });
      return response;
    },
  });

const useCampaignLeadRemove = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.delete("/linkedin/lead_remove", { data });
      return response;
    },
  });

const useFetchCampaignLeads = (data, enabled = true) =>
  useQuery({
    queryFn: async () => {
      const { campaign_id, limit, sort_by, sort_order, page } = data;
      const response = await axiosInt.get(
        `/linkedin/fetch_campaign_leads?campaign_id=${campaign_id}&limit=${limit}&page=${page}&sort_by=${sort_by}&sort_order=${sort_order}`
      );
      return response;
    },
    enabled,
  });

const useGetCampaignSettings = (data) =>
  useQuery({
    enabled: !!data?.campaign_id,
    queryFn: async () => {
      const response = await axiosInt.get(
        `/linkedin/get_settings?campaign_id=${data?.campaign_id}`,
        data
      );
      return response;
    },
  });

const useUpdateCampaignSettings = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.put("/linkedin/update_settings", data);
      return response;
    },
  });

const useGetCampaignActivity = () =>
  useMutation({
    mutationFn: async ({
      campaignId,
      custom_start_date,
      custom_end_date,
      isAllTime,
    }) => {
      const response = await axiosInt.get(
        isAllTime
          ? `/linkedin/campaign_activity?campaign_id=${Number(campaignId)}`
          : `/linkedin/campaign_activity?campaign_id=${Number(
              campaignId
            )}&custom_start_date=${custom_start_date}&custom_end_date=${custom_end_date}`
      );
      return response;
    },
  });

const useGetCampaignSequences = () =>
  useMutation({
    mutationFn: async (id) => {
      const response = await axiosInt.get(
        `/linkedin/get_sequences?campaign_id=${Number(id)}`
      );
      return response;
    },
  });

const useCreateCampaignLeads = () =>
  useMutation({
    mutationFn: (data) => axiosInt.post("/prospect/create_prospect_list", data),
  });

const useEditCampaignSequences = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.put("/linkedin/edit_sequences", data);
      return response;
    },
  });

const useCheckLeadStatus = (data, enabled = true) =>
  useQuery({
    queryKey: ["checkLeadStatus", data.qeary], // Add a unique key
    queryFn: async () => {
      const { qeary } = data;
      const response = await axiosInt.get(
        `/linkedin/check_lead_status?${qeary}`
      );
      return response;
    },
    enabled,
  });

const useCampaignActiveStatus = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.put(
        "/linkedin/campaign_active_status",
        data
      );
      return response;
    },
  });

const useAddLinkedinLeads = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.post(
        "/linkedin/add_linkedin_leads",
        data
      );
      return response;
    },
  });

const useCampaignShare = () =>
  useMutation({
    mutationFn: async (id) => {
      const response = await axiosInt.get(
        `/linkedin/share/campaign?campaign_id=${Number(id)}&page=1&limit=10`
      );
      return response;
    },
  });

const useConnectionAnalytics = () =>
  useMutation({
    mutationFn: async ({ campaignId, graphType }) => {
      const response = await axiosInt.get(
        `/linkedin/connection_analytics?date_range=${graphType}&campaign_id=${Number(
          campaignId
        )}`
      );
      return response;
    },
  });

const useDeleteCampaignSequence = () =>
  useMutation({
    mutationFn: async ({ campaignId, sequenceId }) => {
      const response = await axiosInt?.delete(
        `/linkedin/delete_sequence?campaign_id=${campaignId}&sequence_id=${sequenceId}`
      );
      return response;
    },
  });

const useImportCampaignSequence = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.post(
        `/linkedin/import_campaign_sequence`,
        data
      );
      return response;
    },
  });

export {
  useCampaignCreateSequence,
  useCampaignAddSettings,
  useLaunchCampaign,
  useCampaignList,
  useArchiveCampaign,
  useEditCampaignName,
  usePauseCampaignStatus,
  useDeleteCampaign,
  useCampaignLeadRemove,
  useFetchCampaignLeads,
  useGetCampaignSettings,
  useUpdateCampaignSettings,
  useGetCampaignActivity,
  useGetCampaignSequences,
  useEditCampaignSequences,
  useCreateCampaignLeads,
  useCheckLeadStatus,
  useCampaignActiveStatus,
  useAddLinkedinLeads,
  useCampaignShare,
  useDeleteCampaignSequence,
  useConnectionAnalytics,
  useImportCampaignSequence,
};
