import colorWand from "assets/images/color-wand-outline.svg";
import StepButton from "../stepsButton";
import { chooseStepButtonList } from "constant";

const ChooseSteps = (props) => {
  const { onSelectStep, selectedSteps } = props;
  const handleButtonClick = (step) => {
    onSelectStep(step);
  };

  return (
    <div className="bg-white shadow-lg rounded-lg w-[375px] h-[474px] p-4 font-inter">
      <div className="flex items-center mb-[12px] w-full">
        <div
          className="border border-[#EAECF0] h-[43px] w-[43px] flex justify-center items-center rounded-[10px]"
          style={{
            boxShadow: "0px 0.91px 1.82px 0px #1018280D",
          }}
        >
          <img src={colorWand} alt="colorWand" />
        </div>
        <div className="flex items-start flex-col ml-[10.93px]">
          <span className="font-[600] text-[#101828] font-inter text-[16px]">
            Choose Steps
          </span>
          <p className="text-[#475467] text-[12px]">
            Select a step to add it to your campaign sequence.
          </p>
        </div>
      </div>
      <div>
        {/* {chooseStepButtonList.map((button, index) => (
          <StepButton
            selected={selectedSteps.some(
              (i) =>
                i?.title === button?.title
            )}
            key={index}
            index={index}
            button={button}
            onClick={handleButtonClick}
            className="mb-[10px]"
            title={
              <div className="flex items-center justify-center w-full h-full">
                {button?.icon && (
                  <div
                    className="w-[22px] h-[22px] mr-[10px] bg-white border-[1px] border-[#EAECF0] p-[3px] flex items-center justify-center rounded-[5px]"
                    style={{ boxShadow: "0 0.47px 0.95px 0 #10182833" }}
                  >
                    <img
                      src={button?.icon}
                      alt="import"
                      className="w-[15px] h-[15px]"
                    />
                  </div>
                )}
                {button?.title}
              </div>
            }
          />
        ))} */}
        {chooseStepButtonList.map((button, index) => (
          <StepButton
            selected={selectedSteps.some((i) => i?.title === button?.title)}
            key={index}
            index={index}
            button={button}
            onClick={
              button?.title === "Message Existing Connection"
                ? () => {}
                : handleButtonClick
            } 
            className={`mb-[10px] ${
              button?.title === "Message Existing Connection"
                ? "opacity-50 cursor-not-allowed hover:!bg-transparent hover:!text-[#344054]"
                : ""
            }`} 
            title={
              <div className="flex items-center justify-center w-full h-full">
                {button?.icon && (
                  <div
                    className="w-[22px] h-[22px] mr-[10px] bg-white border-[1px] border-[#EAECF0] p-[3px] flex items-center justify-center rounded-[5px]"
                    style={{ boxShadow: "0 0.47px 0.95px 0 #10182833" }}
                  >
                    <img
                      src={button?.icon}
                      alt="import"
                      className="w-[15px] h-[15px]"
                    />
                  </div>
                )}
                {button?.title}
              </div>
            }
          />
        ))}
      </div>
    </div>
  );
};

export default ChooseSteps;
