export const modifyUrl = (campaignId, path = "") => {
  if (!campaignId) return "";

  const baseUrl = window.location.origin + (path || window.location.pathname);
  const url = new URL(baseUrl);

  url.searchParams.set("campaign_id", campaignId);

  const modifiedUrl = url.toString();

  return modifiedUrl;
};

export const modifiedProspectUrl = (prospectId, path = "") => {
  if (!prospectId) return "";

  const baseUrl = window.location.origin + path;

  const modifiedUrl = `${baseUrl}/${prospectId}`;

  return modifiedUrl;
};
