import Layout from "components/layout";
import leftIcon from "assets/images/dark-left.svg";
import { useLocation, useNavigate } from "react-router-dom";
import BreadcrumbHeader from "components/breadcrumbHeader";
import { useContext, useEffect, useRef, useState } from "react";
import Button from "components/button";
import logomark from "assets/images/Logomark.svg";
import {
  INITIAL_NEW_CAMPAIGN_STEPS,
  TABLE_DATA,
  AiGenerateDefaultSteps,
  AiGenerateIsLikeAComment,
  AiGenerateIsLikeAPost,
  AiGenerateStepsIsSendConnectionRequest,
  AiGenerateStepsIsSendConnectionRequestIsFollowUp,
  AiGenerateStepsNotSendConnectionRequest,
  TimeFrameOptions,
} from "constant";
import ChooseSteps from "components/newCampaign/choosesteps";
import CurrentCampaign from "components/newCampaign/currentCampaignSteps";
import { toast } from "react-toastify";
import { AppContext } from "context/AppContext";
import OutlinedInput from "components/outlinedInput";
import Tick from "assets/images/name-check.svg";
import DateRangePicker from "../../components/daterangepicker";
import alertIcon from "assets/images/error.svg";
import { timeSlotsOptions } from "../../constant";
import FounderSfTable from "./FounderSfTable";
import ReviewSidePanel from "components/reviewSidePanel/index.jsx";
import deleteIcon from "assets/images/trash-01.svg";
import { MdOutlineAdd } from "react-icons/md";
import DeleteLeadModal from "components/prospecting/prospectingSettingModals/deleteLeadModal";
import AddLeadsCampaign from "components/campaigns/modals/addLeadsCampaign";
import EnrichingLeadsCampaign from "components/campaigns/modals/enrichingLeads";
import deleteImg from "assets/images/trash.svg";
import lockIcon from "assets/images/purple-lock.svg";
import Select from "react-select";
import {
  useDeleteCampaignSequence,
  useEditCampaignSequences,
  useGetCampaignSettings,
  useUpdateCampaignSettings,
  useCampaignLeadRemove,
  useCampaignShare,
  useGetCampaignActivity,
  useGetCampaignSequences,
  useCheckLeadStatus,
} from "services/campaigns-api";
import useCampaignStore from "stores/campaignStore";
import {
  getRandomColor,
  getRandomCompanyLogo,
  getRandomProfileLogo,
  getRandomProfileLogoWithRandom,
  reverseTransformSequences,
  transformSequences,
} from "utils/index";
import EmptyPage from "components/emptyPageComponent";
import Loader from "components/loader";
import AddLeadingAnimationModal from "components/campaigns/modals/addLeadingAnimationModal";
import { useAccountStore, useAuthStore } from "stores";
import useRandomImagesStore from "stores/randomImagesStore";
import moment from "moment";
import ReconnectBanner from "components/reconnectBanner";
import { useProspectFiltersInfo } from "services/prospecting-api";

const daysData = [
  { name: "Sun" },
  { name: "Mon" },
  { name: "Tue" },
  { name: "Wed" },
  { name: "Thu" },
  { name: "Fri" },
  { name: "Sat" },
];

const DefaultActivity = () => {
  const tableRef = useRef(null);
  const getCampaignSequences = useGetCampaignSequences();
  const updateCampaignSequences = useEditCampaignSequences();
  const deleteCampaignSequence = useDeleteCampaignSequence();
  const getCampaignActivity = useGetCampaignActivity();
  const getCampaignShareLink = useCampaignShare();
  const deleteLead = useCampaignLeadRemove();
  const useGetProspectFiltersInfo = useProspectFiltersInfo();

  const { companyId } = useAuthStore();
  const { profileImage } = useRandomImagesStore();
  const {
    campaignId,
    campaign,
    setCampaignId,
    trackerId,
    draftListId,
    clearDraftListId,
    openLeadsTabDirectly,
    clearOpenLeadsTabDirectly,
  } = useCampaignStore();
  const { setAppState } = useContext(AppContext);
  const navigate = useNavigate();
  const [tab, setTab] = useState(openLeadsTabDirectly ? "leads" : "activity");
  const location = useLocation();
  const parentRef = useRef(null);
  const updateCampaignSettingsAPI = useUpdateCampaignSettings();
  const [isNewCampaignDeleted, setIsNewCampaignDeleted] = useState(true);
  const [isNewCampaignAdded, setIsNewCampaignAdded] = useState(false);
  const [isAddLeadAnimationModal, setIsAddLeadAnimationModal] = useState(false);
  const [isAiIsGeneratingSteps, setIsAIisGeneratingSteps] = useState(false);
  const [modalRandomGradient, setModalRandomGradient] = useState("");
  const [modalRandomCompanyLogo, setModalRandomCompanyLogo] = useState("");
  const [modalRandomProfileLogo, setModalRandomProfileLogo] = useState("");
  const [startTime, setStartTime] = useState({
    value: "09:00",
    label: "09:00 AM",
  });
  const [endTime, setEndTime] = useState({
    value: "17:00",
    label: "05:00 PM",
  });
  const [error, setError] = useState("");
  const [activeDays, setActiveDays] = useState(
    Array(daysData.length).fill(false).fill(true, 1, 6)
  );
  const [checkedStates, setCheckedStates] = useState(
    new Array(TABLE_DATA?.length).fill(false)
  );

  const [campaignName, setCampaignName] = useState("");
  const [selectedSteps, setSelectedSteps] = useState(
    INITIAL_NEW_CAMPAIGN_STEPS
  );
  const { linkedinAccountDetails } = useAccountStore();

  const [dateRange, setDateRange] = useState([
    new Date(Date.now() - 24 * 60 * 60 * 1000), // 1 day before today
    new Date(), // today
  ]);
  const [isCalenderOpen, setIsCalenderOpen] = useState(false);
  const [seltectedRowItem, setSelectedRowItem] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAddLeadsOpen, setIsAddLeadsOpen] = useState(false);
  const [isEnrichingLeadsOpen, setIsEnrichingLeadsOpen] = useState(false);
  const [activityData, setActivityData] = useState([]);
  const [selectedLeadIds, setSelectedLeadIds] = useState([]);
  const [refetchLeads, setRefetchLeads] = useState(null);
  const [campaignSettings, setCampaignSettings] = useState(null);
  const [availableLeads, setAvailableLeads] = useState(0);
  const [enrichedLeads, setEnrichedLeads] = useState(0);
  const [isCheckingLeadsStatus, setIsCheckingLeadsStatus] = useState(false);
  const [sortBy, setSortBy] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");
  const [timeFrame, setTimeFrame] = useState("24 hours");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [currentIndexed, setCurrentIndexed] = useState(null);
  const [isDelete, setIsDelete] = useState(false);
  const [prospect, setProspect] = useState();

  const { refetch, isFetching } = useGetCampaignSettings({
    campaign_id: campaignId,
  });
  const campaignParamsStatus = {
    qeary: `campaign_id=${campaignId}${
      trackerId ? `&tracker_id=${trackerId}` : ""
    }`,
  };
  const { data: checkLeadStatus, refetch: refetchStatus } = useCheckLeadStatus(
    campaignParamsStatus,
    Boolean(campaignId) && tab === "leads"
  );

  const handleGetProspect = async () => {
    try {
      const response = await useGetProspectFiltersInfo.mutateAsync({
        company_id: companyId,
        prospect_id: draftListId || location?.state?.prospect_id,
      });
      setProspect(response);
    } catch (error) {}
  };

  useEffect(() => {
    if (tab === "settings") {
      handleGetProspect();
      refetch()?.then((res) => {
        const settingData = res?.data;
        setCampaignSettings(settingData);
        setCampaignName(settingData?.campaign_name);
        if (settingData?.start_hour) {
          setStartTime({
            label: timeSlotsOptions?.find(
              (time) => time?.value === settingData?.start_hour
            )?.label,
            value: settingData?.start_hour,
          });
          setEndTime({
            label: timeSlotsOptions?.find(
              (time) => time?.value === settingData?.end_hour
            )?.label,
            value: settingData?.end_hour,
          });
        }
        const data = activeDays.map((_, index) =>
          settingData?.days_of_the_week?.includes(index) ? true : false
        );
        setActiveDays(data);
      });
    }
  }, [tab]);

  useEffect(() => {
    if (tab !== "sequences") {
      setSelectedSteps(INITIAL_NEW_CAMPAIGN_STEPS);
    }
  }, [tab]);

  const handleStepValueChange = (newValue, index) => {
    if (index >= 1) {
      const updatedSteps = selectedSteps.map((item, ind) => {
        if (index === ind) {
          return {
            ...item,
            value: newValue,
          };
        }
        return item;
      });
      setSelectedSteps(updatedSteps);
    }
  };

  const handleGetSequences = async (isUpdated = false) => {
    try {
      const response = await getCampaignSequences.mutateAsync(campaignId);
      const sequences = reverseTransformSequences(response);
      if (isUpdated) {
        if (sequences?.sequences?.length === 0) {
          setSelectedSteps(INITIAL_NEW_CAMPAIGN_STEPS);
        } else {
          setSelectedSteps([
            {
              title: "Start Campaign",
              type: "Start Campaign",
              icon: "",
              sequence_id: "",
              value: "",
            },
            ...sequences?.sequences,
          ]);
        }
      } else {
        if (sequences?.sequences?.length === 0) {
          setSelectedSteps(INITIAL_NEW_CAMPAIGN_STEPS);
        } else {
          setSelectedSteps([
            {
              title: "Start Campaign",
              type: "Start Campaign",
              icon: "",
              sequence_id: "",
              value: "",
            },
            ...sequences?.sequences,
          ]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (tab === "sequence") {
      handleGetSequences();
    }
  }, [tab]);
  const handleUpdateSequences = async () => {
    const payload = {
      campaign_id: campaignId,
      sequences: [
        ...selectedSteps?.map((step, index) => {
          if (index > 0) {
            return {
              order_number: index,
              sequence_id: step?.sequence_id,
              value: step?.value,
              sentiment_value: true,
              id: step?.id,
            };
          }
          return null;
        }),
      ]?.filter(Boolean),
    };

    const checkIfItemExistsWithParsedValue = (title, condition) => {
      return selectedSteps?.some((item) => {
        if (item?.title === title) {
          const value = item?.value || "";
          if (title === "Follow-up Message") {
            return condition(value); // For Follow-up Message, treat value as a normal string
          } else {
            const parsedValue = JSON.parse(value);
            return condition(parsedValue); // For other titles, parse the value as JSON
          }
        }
        return false;
      });
    };

    const hasRequiredSteps = () => {
      const initialTitles = ["Start Campaign", "View Profile"];
      const requiredTitles = [
        "Send Connection Request",
        "Like a Post",
        "Like a Comment",
      ];
      if (selectedSteps?.length === 2) {
        return selectedSteps
          ?.map((item) => initialTitles.includes(item?.title))
          ?.every(Boolean);
      }
      return selectedSteps?.some((item) =>
        requiredTitles.includes(item?.title)
      );
    };

    // Check if "Send Connection Request" exists with appropriate conditions
    const connectionRequestExists = checkIfItemExistsWithParsedValue(
      "Send Connection Request",
      (parsedValue) => {
        return parsedValue?.isSendRequestCheckBoxChecked === true;
      }
    );

    const connectionRequestMessage = checkIfItemExistsWithParsedValue(
      "Send Connection Request",
      (parsedValue) => parsedValue?.inputValue
    );

    const followUpMessageExists = checkIfItemExistsWithParsedValue(
      "Follow-up Message",
      (value) => value
    );
    const response = hasRequiredSteps();
    if (!response) {
      // Display notification if none of the required steps are present
      toast.warning(
        "Please add one of the following Steps to launch a campaign: Send a Connection Request, Like a Post, or Like a Comment."
      );
      return;
    } else {
      if (
        selectedSteps?.some(
          (item) =>
            item?.title === "Send Connection Request" ||
            item?.title === "Follow-up Message"
        )
      ) {
        if (
          connectionRequestExists &&
          !connectionRequestMessage &&
          !selectedSteps?.some((item) => item?.title === "Follow-up Message")
        ) {
          toast.warning("Please provide a connection request message");
        } else if (
          connectionRequestExists &&
          !connectionRequestMessage &&
          !selectedSteps?.some((item) => item?.title === "Follow-up Message")
        ) {
          toast.warning("Please provide a connection request message.");
        } else if (
          !connectionRequestExists &&
          selectedSteps?.some(
            (item) => item?.title === "Send Connection Request"
          ) &&
          selectedSteps?.some((item) => item?.title === "Follow-up Message") &&
          !followUpMessageExists
        ) {
          toast.warning("Please provide a follow-up message.");
        } else if (
          !connectionRequestExists &&
          selectedSteps?.some(
            (item) => item?.title === "Send Connection Request"
          ) &&
          selectedSteps?.some((item) => item?.title === "Follow-up Message") &&
          followUpMessageExists
        ) {
          try {
            await updateCampaignSequences?.mutateAsync(
              transformSequences(payload)
            );
            handleGetSequences();
            toast.success("Sequence Updated", {
              icon: () => <img src={Tick} alt="name" />,
            });
          } catch (error) {}
        } else if (
          connectionRequestExists &&
          connectionRequestMessage &&
          selectedSteps?.some((item) => item?.title === "Follow-up Message") &&
          !followUpMessageExists
        ) {
          toast.warning("Please provide a follow-up message.");
        } else if (
          connectionRequestExists &&
          !connectionRequestMessage &&
          selectedSteps?.some((item) => item?.title === "Follow-up Message") &&
          followUpMessageExists
        ) {
          toast.warning("Please provide a connection request message.");
        } else if (
          connectionRequestExists &&
          selectedSteps?.some((item) => item?.title === "Follow-up Message") &&
          !connectionRequestMessage &&
          !followUpMessageExists
        ) {
          toast.warning(
            "Please provide a connection request message and follow-up message."
          );
        } else {
          try {
            await updateCampaignSequences?.mutateAsync(
              transformSequences(payload)
            );
            handleGetSequences(true);
            toast.success("Sequence Updated", {
              icon: () => <img src={Tick} alt="name" />,
            });
          } catch (error) {}
        }
      } else {
        try {
          await updateCampaignSequences?.mutateAsync(
            transformSequences(payload)
          );
          handleGetSequences(true);
          toast.success("Sequence Updated", {
            icon: () => <img src={Tick} alt="name" />,
          });
        } catch (error) {}
      }
    }
  };

  const handleDeleteSequence = async (sequenceId, updated) => {
    if (!sequenceId) {
      setSelectedSteps(updated);
      toast.success("Sequence deleted successfully", {
        icon: () => <img src={Tick} alt="name" />,
      });
      return;
    }
    try {
      const response = await deleteCampaignSequence?.mutateAsync({
        campaignId,
        sequenceId,
      });
      if (response) {
        setSelectedSteps(updated);
        toast.success("Sequence deleted successfully", {
          icon: () => <img src={Tick} alt="name" />,
        });
      }
    } catch (error) {
      toast.success(error?.data?.detail);
    }
  };

  const handleDateChange = (newDateRange) => {
    setDateRange(newDateRange);
  };
  // show review lead modal useEffect
  useEffect(() => {
    let timer;
    if (isEnrichingLeadsOpen) {
      timer = setTimeout(() => {
        if (isEnrichingLeadsOpen) {
          // setIsAddReviewLeadModal(true);
          setIsAddLeadAnimationModal(true);
          setIsEnrichingLeadsOpen(false);
        }
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [isEnrichingLeadsOpen]);

  useEffect(() => {
    if (parentRef && parentRef?.current && isNewCampaignAdded) {
      parentRef.current.scrollTo({
        top: parentRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
    setIsNewCampaignAdded(false);
  }, [isNewCampaignAdded]);

  const handleNext = async () => {
    if (campaignName) {
      let trueIndexes = activeDays
        .map((value, index) => (value === true ? index : -1)) // Map to index or -1 if false
        .filter((index) => index !== -1);
      const payload = {
        ...campaignSettings,
        campaign_name: campaignName,
        days_of_the_week: trueIndexes,
        start_hour: startTime?.value,
        end_hour: endTime?.value,
      };
      try {
        await updateCampaignSettingsAPI?.mutateAsync(payload);
        if (!error) {
          navigate("/campaigns");
          toast.success("Campaign Settings Updated", {
            icon: () => <img src={Tick} alt="name" />,
          });
        }
      } catch (error) {
        console.log({ error });
        toast.success("Something went wrong");
      }
    } else {
      toast.success("Please Enter a Campaign Name");
    }
  };

  const handleDeleteStep = (step, stepIndex) => {
    // Step 1: Create a copy of the array and remove the clicked step
    let updated = selectedSteps.filter((_, index) => index !== stepIndex);

    // Step 2: Determine the next target step based on the clicked step's title
    let nextStepIndex = -1;

    if (step.title === "View Profile") {
      // Find the index of the next "Waiting Period" after stepIndex
      nextStepIndex = updated.findIndex(
        (s, index) => s.title === "Waiting Period" && index >= stepIndex
      );
    } else if (step.title === "Waiting Period") {
      // Find the index of the next "View Profile" after stepIndex
      nextStepIndex = updated.findIndex(
        (s, index) => s.title === "View Profile" && index >= stepIndex
      );
    } else if (step?.title === "Send Connection Request") {
      // updated = updated?.filter((i)=>i?.title !== "Follow-up Message")
      if (updated.some((i) => i?.title === "Follow-up Message")) {
        toast.warning("You must delete your follow-up message first");
        return;
      }
      setAppState((prev) => ({
        ...prev,
        isSendRequestCheckBoxChecked: true,
        sendConnectionRequestMessage: "",
        selectedConnectionRequestMessageOption: {
          label: "First Name",
          value: "firstname",
        },
      }));
    } else if (step?.title === "Follow-up Message") {
      setAppState((prev) => ({
        ...prev,
        followUpMessage: "",
        selectedFollowUpMessageOptoin: {
          label: "First Name",
          value: "firstname",
        },
      }));
    } else if (step?.title === "Message Existing Connection") {
      setAppState((prev) => ({
        ...prev,
        existingConnectionMessage: "",
        existingConnectionMessageOption: {
          label: "First Name",
          value: "firstname",
        },
      }));
    }
    // Remove the next target step if it exists
    if (nextStepIndex !== -1) {
      const stepToRemoveIndex = updated.findIndex(
        (_, index) => index === nextStepIndex
      );
      if (stepToRemoveIndex !== -1) {
        // Remove both the current step and the next target step
        const finalUpdated = updated.filter(
          (_, index) => index !== stepIndex && index !== nextStepIndex
        );
        handleDeleteSequence(step?.id, finalUpdated);
        // Update the state with finalUpdated
      } else {
        // Update the state with updated
        handleDeleteSequence(step?.id, updated);
      }
    } else {
      // Update the state with updated
      handleDeleteSequence(step?.id, updated);
    }
    setIsNewCampaignDeleted(false);
  };

  const handleSelectStep = (step) => {
    const isActive = selectedSteps.some((i) => i?.title === step?.title);
    setSelectedSteps((prevSteps) => {
      if (step.title === "AI Generate Steps") {
        setIsAIisGeneratingSteps(true);
        handleAIGenerateSteps(prevSteps);
        return [...prevSteps, step];
      } else {
        const updatedSteps = handleOtherSteps(step, prevSteps);
        if (!isActive) {
          setIsNewCampaignDeleted(true);
          setIsNewCampaignAdded(true);
        }

        return updatedSteps;
      }
    });
  };

  const handleAIGenerateSteps = (newStepsList) => {
    const lastThreeObjects = selectedSteps.slice(-3);
    const requiredStepsWaitingPeriod = [
      "Send Connection Request",
      "Follow-up Message",
    ];
    const requiredStepsLikePost = [
      "Send Connection Request",
      "Follow-up Message",
      "Like a Post",
    ];
    const requiredStepsLikeComment = [
      "Send Connection Request",
      "Follow-up Message",
      "Like a Comment",
    ];
    const isSendConnectionRequest = selectedSteps.some(
      (item) => item.title === "Send Connection Request"
    );
    const isFollowUpMessage = selectedSteps.some(
      (item) => item.title === "Follow-up Message"
    );
    const isWaitingMessage = selectedSteps.some(
      (item) => item?.title === "Waiting Period"
    );
    const isLikePost = selectedSteps.some(
      (item) => item.title === "Like a Post"
    );
    const isLikeComment = selectedSteps.some(
      (item) => item.title === "Like a Comment"
    );

    const getStepsWithDelays = () => {
      if (!isSendConnectionRequest) {
        return AiGenerateStepsNotSendConnectionRequest;
      } else if (isSendConnectionRequest && !isFollowUpMessage) {
        return AiGenerateStepsIsSendConnectionRequest;
      } else if (
        isSendConnectionRequest &&
        isFollowUpMessage &&
        !isLikePost &&
        !isLikeComment &&
        !isWaitingMessage
      ) {
        return AiGenerateStepsIsSendConnectionRequestIsFollowUp;
      } else if (
        isSendConnectionRequest &&
        isFollowUpMessage &&
        isLikePost &&
        !isLikeComment
      ) {
        return AiGenerateIsLikeAPost;
      } else if (
        isSendConnectionRequest &&
        isFollowUpMessage &&
        isLikeComment &&
        !isWaitingMessage
      ) {
        return AiGenerateIsLikeAComment;
      } else {
        return AiGenerateDefaultSteps;
      }
    };
    const stepsWithDelays = getStepsWithDelays();

    const addStepAfterDelay = (step, delay) => {
      return new Promise((resolve) => {
        setTimeout(() => {
          setSelectedSteps((prevSteps) => {
            if (
              !prevSteps.some(
                (existingStep) => existingStep.title === step.title
              )
            ) {
              return [...prevSteps, step];
            }
            return isSendConnectionRequest &&
              isFollowUpMessage &&
              !isLikePost &&
              !isLikeComment &&
              !isWaitingMessage
              ? [...prevSteps, step]
              : prevSteps;
          });
          setIsNewCampaignAdded(true);
          resolve();
        }, delay);
      });
    };

    const removeAIGenerateSteps = () => {
      setSelectedSteps((prevSteps) =>
        prevSteps.filter((step) => step.title !== "AI Generate Steps")
      );
      setIsAIisGeneratingSteps(false);
    };

    const addStepsSequentially = async () => {
      for (const step of stepsWithDelays) {
        await addStepAfterDelay(step, step.delay);
      }
      setIsNewCampaignDeleted(true);
      setTimeout(() => {
        setIsNewCampaignDeleted(false);
        removeAIGenerateSteps();
      }, 1000);
    };
    addStepsSequentially();
  };

  const handleOtherSteps = (step, newStepsList) => {
    const lastStep = newStepsList[newStepsList.length - 1];
    const hasSendConnectionRequest = newStepsList.some(
      (s) => s.title === "Send Connection Request"
    );
    const hasMessageExistingConnection = newStepsList.some(
      (s) => s.title === "Message Existing Connection"
    );
    const hasLikePost = newStepsList.some((s) => s.title === "Like a Post");
    const hasLikeComment = newStepsList.some(
      (s) => s.title === "Like a Comment"
    );

    if (step.title === "Follow-up Message" && !hasSendConnectionRequest) {
      toast.warning("Please add Send Connection Request step first");
      return newStepsList;
    }

    if (step?.title === "Follow-up Message" && hasSendConnectionRequest) {
      if (lastStep?.title === "Follow-up Message") {
        return [
          ...newStepsList,
          {
            title: "Waiting Period",
            type: "Waiting Period",
            icon: "",
            sequence_id: 2,
            value: "",
          },
          step,
        ];
      }
    }

    if (step.title === "View Profile") {
      if (lastStep?.title === "Waiting Period") {
        return [...newStepsList, step];
      } else {
        return [
          ...newStepsList,
          {
            title: "Waiting Period",
            type: "Waiting Period",
            icon: "",
            sequence_id: 2,
            value: "",
          },
          step,
        ];
      }
    }

    if (
      (step.title === "Send Connection Request" && hasSendConnectionRequest) ||
      (step.title === "Message Existing Connection" &&
        hasMessageExistingConnection) ||
      (step.title === "Waiting Period" && lastStep?.title === "Waiting Period")
    ) {
      return newStepsList;
    }

    if (step.title === "Like a Post" && !hasLikePost) {
      return [...newStepsList, step];
    }

    if (step.title === "Like a Comment" && !hasLikeComment) {
      return [...newStepsList, step];
    }

    return [...newStepsList, step];
  };
  // time slots

  const handleStartTimeChange = (selectedOption) => {
    setStartTime(selectedOption);
    const startTimeValue = parseTime(selectedOption.label);

    let endTimeValue = startTimeValue + 3;
    if (endTimeValue >= 24) {
      endTimeValue -= 24;
    }

    const endOption = timeSlotsOptions.find(
      (time) => parseTime(time.label) === endTimeValue
    );

    if (endOption) {
      setEndTime(endOption);
    }

    validateTime(selectedOption.value, endOption.value);
  };

  const handleEndTimeChange = (selectedOption) => {
    setEndTime(selectedOption);
    validateTime(startTime.value, selectedOption.value);
  };

  const parseTime = (time) => {
    // Convert "01:00 PM" to 24-hour format
    const [hour, modifier] = time.split(" ");
    let [hours, minutes] = hour.split(":").map(Number);

    if (modifier === "PM" && hours !== 12) {
      hours += 12;
    } else if (modifier === "AM" && hours === 12) {
      hours = 0;
    }

    return hours + minutes / 60; // Convert to hours with decimal
  };

  const validateTime = (start, end) => {
    const startHour = parseTime(start);
    const endHour = parseTime(end);

    // If endHour is smaller, it's the next day, so add 24 hours
    const adjustedEndHour = endHour < startHour ? endHour + 24 : endHour;

    const diff = adjustedEndHour - startHour;

    if (diff < 3) {
      setError("This time window needs to be a minimum of 3 hours.");
    } else {
      setError("");
      // toast.success("Campaign Schedule Updated", {
      //   icon: () => <img src={Tick} alt="name" />,
      // });
    }
  };

  //  day select toggle
  const toggleDayActive = (index) => {
    setActiveDays((prev) => {
      const updatedDays = [...prev];
      updatedDays[index] = !updatedDays[index];
      return updatedDays;
    });
  };

  const handleGetActivity = async () => {
    try {
      const response = await getCampaignActivity.mutateAsync({
        campaignId: campaignId,
        custom_start_date: moment(dateRange[0]).format("YYYY-MM-DD"),
        custom_end_date: moment(dateRange[1]).format("YYYY-MM-DD"),
        isAllTime: timeFrame === "all_time",
      });

      const modifiedArray = [
        { name: "Invites Sent", value: response?.invites_sent?.total || 0 },
        {
          name: "New Connections",
          value: response?.invites_accepted?.total || 0,
        },
        { name: "Follow-ups", value: response?.followup_sent?.total || 0 },
        {
          name: "Profiles Viewed",
          value: response?.profile_viewed?.total || 0,
        },
        { name: "Liked Posts", value: response?.liked_posts?.total || 0 },
        { name: "Liked Comments", value: response?.liked_comments?.total || 0 },
      ];

      setActivityData(modifiedArray);
      setIsDropdownOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const startDate = moment(dateRange[0]).startOf("day"); // Set to the start of the day
    const endDate = moment(dateRange[1]).startOf("day"); // Set to the start of the day

    const timeDifference = endDate.diff(startDate, "days"); // Get the difference in days

    if (timeDifference === 1) {
      setTimeFrame("24 hours");
    } else {
      setTimeFrame("all_time");
    }

    if (dateRange) {
      handleGetActivity();
    }
  }, [dateRange, timeFrame]);

  const handleGetShareLink = async () => {
    try {
      const response = await getCampaignShareLink.mutateAsync(campaignId);
      const { analytics } = response;
      const modifiedArray = [
        { name: "Invites Sent", value: analytics.invites_sent.total },
        { name: "New Connections", value: analytics.invites_accepted.total },
        { name: "Follow-ups", value: analytics.followup_sent.total },
        { name: "Profiles Viewed", value: analytics.profile_viewed.total },
        { name: "Liked Posts", value: analytics.liked_posts.total },
        { name: "Liked Comments", value: analytics.liked_comments.total },
      ];
      setActivityData(modifiedArray);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (location?.search.includes("campaign_id")) {
      const urlParams = new URLSearchParams(location?.search);
      const campaignId = Number(urlParams.get("campaign_id"));
      setCampaignId(campaignId);

      handleGetShareLink();
    } else {
      // handleGetActivity();
    }
  }, []);

  const handleTimeFrameChange = (newTimeFrame) => {
    if (newTimeFrame === "24 hours") {
      setDateRange([
        new Date(Date.now() - 24 * 60 * 60 * 1000), // 1 day before today
        new Date(), // today
      ]);
    } else {
      setDateRange([
        new Date(Date.now() - 10 * 24 * 60 * 60 * 1000), // 10 days before today
        new Date(), // today
      ]);
    }
    setTimeFrame(newTimeFrame);
    setIsDropdownOpen(false);
  };

  const renderData = () => {
    switch (tab) {
      case "activity":
        return (
          <div className="flex justify-between flex-wrap relative w-full">
            {!getCampaignActivity?.isPending && activityData?.length > 0 ? (
              <>
                {activityData?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="ulShadow h-[166px] w-[32%] border border-[#E4E7EC] rounded-[12px] mb-[24px]"
                    >
                      <div className="p-[24px] h-[114px] relative">
                        <h4 className="font-[500] text-[#475467] font-inter text-[14px] mb-[6px]">
                          {item?.name}
                        </h4>
                        <h3 className="font-[600] text-[#101828] font-inter text-[30px]">
                          {item?.value}
                        </h3>
                      </div>
                      <div className="h-[52px] px-[24px] py-[16px] border-t border-[#E4E7EC] flex items-center justify-end">
                        <button
                          className="text-[#6941C6] text-left text-[14px] font-[600] font-inter relative cursor-pointer outline-none border-0"
                          onClick={() => {
                            setCurrentIndexed(index);
                            setIsDropdownOpen(!isDropdownOpen);
                          }}
                        >
                          <span className="capitalize">
                            {
                              TimeFrameOptions?.find(
                                (item) => item?.value === timeFrame
                              )?.label
                            }
                          </span>
                          {isDropdownOpen && currentIndexed === index && (
                            <ul className="absolute mt-2 top-full z-50 bg-[#f7f7f7] border-[1px] border-[#FFFFFF26] rounded-[9.37px] w-[150px] py-[6px]">
                              {TimeFrameOptions.map((option, index) => (
                                <li
                                  key={index}
                                  onClick={() =>
                                    handleTimeFrameChange(option?.value)
                                  }
                                  className={`cursor-pointer text-left text-[#000000] px-[22px] font-[500] text-[13.1px] py-1 ${
                                    timeFrame === option?.value &&
                                    "!text-[#6941C6]"
                                  }`}
                                >
                                  {option?.label}
                                </li>
                              ))}
                            </ul>
                          )}
                        </button>
                      </div>
                    </div>
                  );
                })}
                {/* <ConnectionRequests campaignId={campaignId} /> */}
              </>
            ) : getCampaignActivity.isPending ? (
              <div className="flex items-center justify-center h-[calc(100vh_-400px)] w-full">
                <Loader />
              </div>
            ) : (
              <div className="flex items-center justify-center h-[calc(100vh_-400px)] w-full">
                <EmptyPage
                  title="No Activity found"
                  description=""
                  whiteButtonTitle="Find Activity"
                  whiteButtonClass="!w-[128px]"
                  primaryButtonTitle="New Activity"
                  primaryButtonClass="!w-[180px]"
                  showIcon={false}
                  showButtons={false}
                />
              </div>
            )}
          </div>
        );
      case "leads":
        return (
          <FounderSfTable
            tableRef={tableRef}
            campaignTableData={TABLE_DATA}
            seltectedRowItem={seltectedRowItem}
            setSelectedRowItem={handleRowClick}
            checkedStates={checkedStates}
            setCheckedStates={setCheckedStates}
            setSelectedLeadIds={setSelectedLeadIds}
            setRefetchLeads={setRefetchLeads}
            GetRandomCompanyLogo={getRandomCompanyLogo}
            isCheckingLeadsStatus={isCheckingLeadsStatus}
            setIsCheckingLeadsStatus={setIsCheckingLeadsStatus}
            setAvailableLeads={setAvailableLeads}
            setEnrichedLeads={setEnrichedLeads}
            isEnrichingLeadsOpen={isEnrichingLeadsOpen}
            checkLeadStatus={checkLeadStatus}
            refetchStatus={refetchStatus}
            sortBy={sortBy}
            setSortBy={setSortBy}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            tab={tab}
            trackerId={trackerId}
            isDelete={isDelete}
            isRB2B={campaign?.rb2b_integration}
          />
        );
      case "sequence":
        return (
          <div
            className="h-[68vh] overflow-auto innerScrollBar w-full"
            ref={parentRef}
          >
            <div className="px-[31px] py-[18px]">
              <div className="w-[calc(100%_-300px)] flex items-start justify-center">
                <CurrentCampaign
                  steps={selectedSteps}
                  handleDeleteStep={handleDeleteStep}
                  isNewCampaignDeleted={isNewCampaignDeleted}
                  handleStepValueChange={handleStepValueChange}
                  deleteCampaignSequence={deleteCampaignSequence}
                />
              </div>

              <div
                className={`fixed ${
                  linkedinAccountDetails?.reconnect_status
                    ? "bottom-[40px]"
                    : "bottom-[80px]"
                }  right-[60px]`}
              >
                <ChooseSteps
                  onSelectStep={handleSelectStep}
                  selectedSteps={selectedSteps}
                />
              </div>
            </div>
          </div>
        );
      case "settings":
        return (
          <>
            {isFetching ? (
              <>
                <Loader />
              </>
            ) : (
              <div className="w-[85%] font-inter">
                <div className="pt-[12px] pb-[51px] w-[80%]">
                  <div className="flex items-start mb-[51px]">
                    <h6 className="font-[600] text-[#344054] text-[11.8px] w-[236px] mr-[36px] mb-0">
                      Campaign Name<span className="text-[#7F56D9]">*</span>
                    </h6>
                    <OutlinedInput
                      type="text"
                      placeholder="e.g. Angel Investors"
                      className="h-[37px] placeholder:!text-[#9C9C9C] !text-[13.5px] leading-[20.3px] !w-[433px]"
                      value={campaignName}
                      onChange={(e) => {
                        setCampaignName(e?.target?.value);
                      }}
                    />
                  </div>
                  <div className="flex items-start mb-[51px]">
                    <h6 className="font-[600] text-[#344054] text-[11.8px] w-[236px] mr-[36px] mb-0">
                      Lead List
                    </h6>
                    <OutlinedInput
                      type="text"
                      placeholder="e.g. Angel Investors"
                      className="h-[37px] placeholder:!text-[#9C9C9C] !text-[13.5px] leading-[20.3px] !w-[433px]"
                      value={prospect?.prospect_name}
                      disabledInput={true}
                    />
                  </div>
                  <div className="flex items-start">
                    <h6 className="font-[600] text-[#344054] text-[11.8px] w-[236px] mr-[36px] mb-0 flex items-center">
                      LinkedIn Account
                      <img
                        src={lockIcon}
                        alt="lockIcon"
                        className="w-[12px] h-[12px] ml-[7px] mt-[-4px]"
                      />
                    </h6>
                    <div className="w-[433px] flex items-center rounded-[6px] border-[1px] border-[#D0D5DD] px-[8px] py-[7px] y-axis-shadow">
                      <img
                        src={
                          linkedinAccountDetails?.profile_pic ||
                          getRandomProfileLogoWithRandom(profileImage)?.src
                        }
                        alt="linkedAcc"
                        className="w-[37px] h-[36px] mr-[6px] rounded-full"
                      />
                      <h5 className="font-[500] text-[#101828] text-[13px]">
                        {linkedinAccountDetails?.account_name || "Test Account"}
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="border-t-[1px] pt-[51px] pb-[31px]">
                  <div className="flex items-center mb-[50px]">
                    <h6 className="font-[600] text-[#344054] text-[11.8px] w-[236px] mr-[36px] mb-0 flex items-center">
                      Schedule
                    </h6>
                    <div className="flex items-center">
                      <ul className="flex items-center">
                        {daysData.map((day, index) => (
                          <li
                            key={index}
                            onClick={() => toggleDayActive(index)} // Toggle the active state on click
                            className={`font-inter cursor-pointer rounded-[6.5px] min-w-[41px] px-[12px] py-[8px] y-axis-shadow font-[600] text-[11.3px] mr-[15px] border-[1px] 
                   ${
                     activeDays[index]
                       ? "bg-[#7927FF] text-[#FFFFFF] border-[#7927FF] hover:bg-[#FFFFFF] hover:text-[#7927FF]"
                       : "bg-[#FFFFFF] text-[#344054] border-[#D0D5DD] hover:!bg-[#f5f5f5db]"
                   }`}
                          >
                            {day.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <div className="w-[236px] mr-[36px]">
                      <h6 className="font-[600] text-[#344054] text-[11.3px] mr-[36px] mb-0">
                        Time
                      </h6>
                      <div className="w-[222px]">
                        <p className="text-[#9A9A9A] text-[11.3px] font-[400] mb-[10px]">
                          If you have multiple campaigns running during the same
                          time windows, it will round robin between them.
                        </p>
                        <p className="text-[#9A9A9A] text-[11.3px] font-[400]">
                          Time is displayed in your Workspace’s set time zone.
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <div
                        className={`flex items-center ${
                          error ? "error-time-slot" : ""
                        }`}
                      >
                        <Select
                          value={startTime}
                          onChange={handleStartTimeChange}
                          options={timeSlotsOptions}
                          classNamePrefix="time-slots"
                          maxMenuHeight="130px"
                          autoFocus
                          isSearchable={false}
                        />
                        <p className="font-[400] text-[#6A6A6A] text-[12px] mx-[13px]">
                          to
                        </p>
                        <div
                          className={`relative flex items-center ${
                            error && "show-error-img"
                          }`}
                        >
                          <Select
                            value={endTime}
                            onChange={handleEndTimeChange}
                            options={timeSlotsOptions}
                            classNamePrefix="time-slots"
                            maxMenuHeight="130px"
                            autoFocus
                            isSearchable={false}
                          />
                          {error && (
                            <img
                              src={alertIcon}
                              alt="error"
                              className="absolute right-[7px] mt-[-1px]"
                            />
                          )}
                        </div>
                      </div>
                      {error && (
                        <div className="text-[10.6px] font-[400] text-[#D92D20] mt-[8px]">
                          {error}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-end mt-[20px]">
                  <Button
                    title="Save"
                    gradientBg={true}
                    className="!w-[91px] text-[14px]  !h-[37px] !font-[600] y-axis-shadow !rounded-[7px] !pb-0"
                    onClick={() => handleNext()}
                    disabled={updateCampaignSettingsAPI?.isPending || error}
                  />
                </div>
              </div>
            )}
          </>
        );
      default:
        break;
    }
  };

  const renderButtons = () => {
    switch (tab) {
      case "activity":
        return (
          <DateRangePicker
            value={dateRange}
            onChange={handleDateChange}
            closeCalendar={isCalenderOpen}
          />
        );
      case "leads":
        return (
          <div className="flex items-center">
            {checkedStates?.some((item) => item) && (
              <div onClick={() => setIsDeleteModalOpen(true)}>
                <img
                  src={deleteIcon}
                  alt="user images"
                  className="h-[24px] w-[24px] rounded-full mr-3 cursor-pointer"
                />
              </div>
            )}

            {!campaign?.rb2b_integration && (
              <Button
                isFullWidth={false}
                gradientBg={true}
                height="32px"
                className="!rounded-[8px] !pr-[8px]"
                title={
                  <div className="flex items-center justify-center font-inter !text-[14px] !font-[600]">
                    Add Leads
                    <MdOutlineAdd className="text-[18px] ml-[7px] mt-[1px]" />
                  </div>
                }
                onClick={() => setIsAddLeadsOpen(true)}
              />
            )}
          </div>
        );
      case "sequence":
        return (
          selectedSteps?.length > 2 && (
            <Button
              title={
                updateCampaignSequences?.isPending ? (
                  <div className="flex items-center justify-center w-full h-full">
                    <div className="w-[20px] h-[20px] border-[3px] !text-center border-t-[3px] border-[#fff] border-solid rounded-full animate-spin border-t-[#8A43FF]" />
                  </div>
                ) : (
                  "Save Changes"
                )
              }
              secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9] ml-[10px]"
              className="!w-[149px] leading-[18px] !h-[36px] !font-[600] y-axis-shadow !rounded-[7px] !pb-0 pt-[1.5px] !text-[15.75px]"
              onClick={handleUpdateSequences}
              disabled={
                isAiIsGeneratingSteps || updateCampaignSequences?.isPending
              }
            />
          )
        );
      case "settings":
        break;
      default:
        break;
    }
  };

  const handleDeleteLead = async () => {
    if (selectedLeadIds.length === 0) {
      console.log("No leads selected for deletion");
      return;
    }

    let hasErrors = false;
    for (const leadId of selectedLeadIds) {
      try {
        const response = await deleteLead.mutateAsync({
          campaign_id: campaignId,
          lead_id: leadId,
          remove_status: true,
        });
        console.log(`Deleted lead ${leadId}:`, response);
      } catch (error) {
        hasErrors = true;
        console.log(`Error deleting lead ${leadId}:`, error);
      }
    }

    if (hasErrors) {
      selectedLeadIds.length === 1
        ? toast.error("lead could not be deleted")
        : toast.error("Some leads could not be deleted");
    } else {
      selectedLeadIds.length === 1
        ? toast.success("Lead deleted", {
            icon: () => <img src={deleteImg} alt="success" />,
          })
        : toast.success("Leads deleted successfully.", {
            icon: () => <img src={deleteImg} alt="success" />,
          });
    }

    if (refetchLeads) {
      setIsDelete(true);
      refetchLeads();
      setTimeout(() => {
        setIsDelete(false);
      }, 2000);
    }

    setSelectedLeadIds([]);
    setCheckedStates((prevStates) => prevStates.map(() => false));
    setIsDeleteModalOpen(false);
    setIsCheckingLeadsStatus(true);
  };

  const handleRowClick = (row, logo) => {
    setSelectedRowItem(row);
    setModalRandomGradient(getRandomColor());
    setModalRandomCompanyLogo(logo);
    setModalRandomProfileLogo(getRandomProfileLogo());
  };
  return (
    <Layout>
      <div className="h-[100vh] flex flex-col justify-start items-start font-inter ">
        <BreadcrumbHeader
          breadcrumbItems={[
            { title: "Campaigns", route: "/campaigns" },
            {
              title: "Campaign List",
              route: "/campaigns",
            },
            {
              title: "Results",
              route: "",
            },
          ]}
        />
        <ReconnectBanner />
        <div
          className={`innerScrollBar max-w-[100%] w-full h-[calc(100vh_-45px)] ${
            tab !== "lead" && "overflow-auto"
          }"
          data-aos="fade-left`}
        >
          <div className="p-[20px]">
            <div className="flex items-center">
              <Button
                style={{
                  boxShadow:
                    " 0px 1px 2px rgba(0, 0, 0, 0.05), inset 0px 0px 1px #D1D5DB,inset 0px 0px 0px #FFFFFF",
                }}
                isWhiteBtn={true}
                isFullWidth={false}
                className="!px-[10px] !w-[81px]"
                title={
                  <div className="flex items-center justify-center font-inter font-[500] text-[14px] !text-[#111827] !border-[#D1D5DB] mt-[-2px]">
                    <img
                      src={leftIcon}
                      alt="import"
                      className="mr-[5px] w-[17px] h-[17px]"
                    />
                    Back
                  </div>
                }
                onClick={() => {
                  clearOpenLeadsTabDirectly();
                  navigate("/campaigns");
                  clearDraftListId();
                }}
              />
              <div className="flex ml-[20px]">
                <div className="flex items-center justify-center">
                  <img
                    src={logomark}
                    alt="documentIcon"
                    className="w-[25px] h-[25px] mr-[7px]"
                  />
                  <h1 className="text-[24.3px] font-inter text-left text-[#000000] font-[500] leading-normal mb-0 mt-[-2px] w-full">
                    {campaign?.campaign_name}
                  </h1>
                </div>
              </div>
            </div>

            <div className="px-[20px]">
              <div className="pt-6 mb-[34px] flex justify-between items-center">
                <ul className="border border-[#D0D5DD] h-[40px] overflow-hidden flex rounded-[8px] w-[487px] ulShadow">
                  <li
                    onClick={() => {
                      clearOpenLeadsTabDirectly();
                      setTab("activity");
                    }}
                    className={`w-[121px] ${
                      tab === "activity" && "bg-[#F9FAFB]"
                    } border-r border-[#D0D5DD] flex items-center justify-center cursor-pointer text-[14px] font-[600] text-[#182230] font-inter`}
                  >
                    {tab === "activity" && (
                      <span className="w-[10px] h-[10px] rounded-[10px] bg-[#17B26A] mr-[6px]" />
                    )}
                    Activity
                  </li>
                  <li
                    onClick={() => {
                      clearOpenLeadsTabDirectly();
                      setTab("leads");
                    }}
                    className={`${
                      tab === "leads" && "bg-[#F9FAFB]"
                    } w-[121px] border-r border-[#D0D5DD] flex items-center justify-center cursor-pointer text-[14px] font-[600] text-[#182230] font-inter`}
                  >
                    {tab === "leads" && (
                      <span className="w-[10px] h-[10px] rounded-[10px] bg-[#17B26A] mr-[6px]" />
                    )}
                    Leads
                  </li>
                  <li
                    onClick={() => {
                      clearOpenLeadsTabDirectly();
                      setTab("sequence");
                    }}
                    className={`${
                      tab === "sequence" && "bg-[#F9FAFB]"
                    } w-[121px] border-r border-[#D0D5DD] flex items-center justify-center cursor-pointer text-[14px] font-[600] text-[#182230] font-inter`}
                  >
                    {tab === "sequence" && (
                      <span className="w-[10px] h-[10px] rounded-[10px] bg-[#17B26A] mr-[6px]" />
                    )}
                    Sequence
                  </li>
                  <li
                    onClick={() => {
                      clearOpenLeadsTabDirectly();
                      setTab("settings");
                    }}
                    className={`${
                      tab === "settings" && "bg-[#F9FAFB]"
                    } w-[121px] flex items-center justify-center cursor-pointer text-[14px] font-[600] text-[#182230] font-inter`}
                  >
                    {tab === "settings" && (
                      <span className="w-[10px] h-[10px] rounded-[10px] bg-[#17B26A] mr-[6px]" />
                    )}
                    Settings
                  </li>
                </ul>
                <div
                  className={`${
                    tab === "activity" ? "w-[300px]" : "w-[200px]"
                  } flex justify-end`}
                >
                  {renderButtons()}
                </div>
              </div>
              {renderData()}
            </div>
          </div>
        </div>
      </div>
      <div className="modal-scroll">
        <ReviewSidePanel
          rowData={seltectedRowItem}
          setRowData={setSelectedRowItem}
          isProspecting={true}
          randomGradient={modalRandomGradient}
          profileLogo={modalRandomProfileLogo}
          companyLogo={modalRandomCompanyLogo}
          page="in-page"
        />
      </div>
      <DeleteLeadModal
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
        }}
        accountManager={true}
        confirmDelete={() => handleDeleteLead()}
        isLoading={deleteLead?.isPending}
        setIsCheckingLeadsStatus={setIsCheckingLeadsStatus}
      />
      <AddLeadsCampaign
        isOpen={isAddLeadsOpen}
        onClose={() => {
          setIsAddLeadsOpen(false);
        }}
        setIsEnrichingLeadsOpen={setIsEnrichingLeadsOpen}
        availableLeads={availableLeads}
        setRefetchLeads={setRefetchLeads}
        setIsCheckingLeadsStatus={setIsCheckingLeadsStatus}
        enrichedLeads={enrichedLeads}
      />
      <EnrichingLeadsCampaign
        isOpen={isEnrichingLeadsOpen}
        onClose={() => {
          setIsEnrichingLeadsOpen(false);
        }}
      />
      <AddLeadingAnimationModal
        isOpen={isAddLeadAnimationModal}
        onClose={() => setIsAddLeadAnimationModal(false)}
      />
    </Layout>
  );
};

export default DefaultActivity;
