import bgVideo from "assets/videos/Background-2.mp4";

const VideoWrapper = ({ children }) => {
  return (
    <div className="relative h-full flex flex-col justify-center !bg-[#251241]">
      <video
        autoPlay
        loop
        muted
        playsInline
        src={bgVideo}
        className="absolute top-0 left-0 w-full h-screen object-cover"
      />
      {children}
    </div>
  );
};

export default VideoWrapper;
