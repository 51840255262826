import dateIcon from "assets/images/dateIcon.svg";
import ReactDatePicker from "react-date-picker";
import { useState } from "react";

const DatePickers = (props) => {
  const { onChange, placeholder, value, name, minDate, maxDate } = props;
  const [isDate, setIsDate] = useState(false);

  const CalendarIcon = () => (
    <img
      src={dateIcon}
      alt="Calendar"
      className="h-[18px] w-[18px]"
      onClick={() => setIsDate(true)}
    />
  );

  const handleKeyDown = (event) => {
    const { value, selectionStart, selectionEnd } = event.target;
    const maxLength = 4;

    if (
      event.target.name.includes("year") &&
      event.key.length === 1 &&
      value.length >= maxLength
    ) {
      if (selectionStart === selectionEnd && value.length >= maxLength) {
        event.preventDefault();
      }
    }
  };

  return (
    <div
      className="max-w-full w-full custom-date-picker relative"
      onClick={() => setIsDate(true)}
    >
      {!isDate && (
        <label
          onClick={() => setIsDate(true)}
          className="text-[14px] text-[#667085] absolute left-[14px] pb-[8px] pt-[7px] top-[2px] bg-[#fff] z-50 cursor-pointer"
        >
          <span className="opacity-[0.6]">{placeholder}</span>
        </label>
      )}
      <ReactDatePicker
        onChange={onChange}
        value={value}
        className="w-full datePicker"
        dayPlaceholder="DD"
        monthPlaceholder="MM"
        yearPlaceholder="YYYY"
        clearIcon={false}
        calendarIcon={CalendarIcon}
        minDate={minDate}
        maxDate={maxDate}
        name={name}
        format="dd/MM/yyyy"
        onKeyDown={handleKeyDown}
      />
    </div>
  );
};

export default DatePickers;
