import React, { useContext, useEffect, useRef, useState } from "react";
import Button from "components/button";
import starsIcon from "assets/images/stars.svg";
import OutlinedInput from "components/outlinedInput";
import addIcon from "assets/images/purple-addition.svg";
import { FiSearch } from "react-icons/fi";
import mailIcon from "assets/images/mail.svg";
import deleteIcon from "assets/images/dark-trash.svg";
import { useManualPeopleEnrichLeads } from "services/prospecting-api";
import { AppContext } from "context/AppContext";
import { toast } from "react-toastify";
import { useCampaignStore } from "stores";

const ManuallyEnterStep = ({ setStep, setProspectId }) => {
  const { setTrackerId } = useCampaignStore();
  const getPrevSelectedBtnType = localStorage?.getItem("selectedButtonType");
  const [linkedinInputs, setLinkedinInputs] = useState([{ id: Date.now(), value: "" }]);
  const [emailInputs, setEmailInputs] = useState([{ id: Date.now(), value: "" }]);
  const [isInputAdded, setIsInputAdded] = useState(false); // New state to track input addition
  const parentRef = useRef(null);
  const addEnrichLeadsAPI = useManualPeopleEnrichLeads()
  const {appState,setAppState} = useContext(AppContext)

  useEffect(() => {
    if (isInputAdded && parentRef.current) {
      parentRef.current.scrollTo({
        top: parentRef.current.scrollHeight,
        behavior: "smooth",
      });
      setIsInputAdded(false); // Reset after scrolling
    }
  }, [linkedinInputs, emailInputs, isInputAdded]);

  const addLinkedinInput = () => {
    setLinkedinInputs([...linkedinInputs, { id: Date.now(), value: "" }]);
    setIsInputAdded(true);
  };

  const addEmailInput = () => {
    setEmailInputs([...emailInputs, { id: Date.now(), value: "" }]);
    setIsInputAdded(true);
  };

  const handleLinkedinInputChange = (id, event) => {
    const newInputs = linkedinInputs.map(input => 
      input.id === id ? { ...input, value: event.target.value } : input
    );
    setLinkedinInputs(newInputs);
  };

  const handleEmailInputChange = (id, event) => {
    const newInputs = emailInputs.map(input => 
      input.id === id ? { ...input, value: event.target.value } : input
    );
    setEmailInputs(newInputs);
  };

  const handleConfirmClick = async() => {
    if(appState?.selectedButtonType === "Companies"){
      setAppState((prev)=>({
        ...prev,
        addedLinks : linkedinInputs?.map((i)=>i?.value)
      }))
      setStep("job-selection");
      return
    }
    try {
      const links = linkedinInputs?.map((item)=>item?.value)
      const payload = {
        list_data: links,
        shared_user_ids: [],
        tracker_id: null,
      };
      const response =  await addEnrichLeadsAPI?.mutateAsync(payload);
      setProspectId(response?.prospects_id);
      setTrackerId(response?.tracker_id);
      setAppState((prev)=>({
        ...prev,
        totalLinkedinURL : links?.length
      }))
        setStep("enrich-leads-step");
    } catch (error) {
      toast.success(error?.data?.detail)
    }
    
  };
  
  const handleRemoveLinkedinInput = (id) => {
    const updated = linkedinInputs.filter(input => input.id !== id);
    setLinkedinInputs(updated);
  };

  const handleRemoveEmailInput = (id) => {
    const updated = emailInputs.filter(input => input.id !== id);
    setEmailInputs(updated);
  }

  return (
    <>
      <div className="flex flex-col items-start justify-start font-inter">
        <div className="flex items-center justify-center border-[1px] border-[#E4E7EC] rounded-[10px] p-[11px] mb-[16px] y-axis-shadow">
          <img src={starsIcon} alt="info img" className="h-[24px] w-[24px]" />
        </div>
        <h4 className="font-[600] text-[18px] text-[#101828] m-0 font-inter">
          Manually Enter
        </h4>
        <p className="font-normal text-[14px] text-[#475467] font-inter">
          {getPrevSelectedBtnType === "Companies"
            ? "Enter the Company Website’s you’d like to prospect leads from."
            : "Enter LinkedIn URLs to enrich the leads."}
        </p>
      </div>
      <div
        className="px-[24px] py-[22px] h-[300px] overflow-auto modal-scroll select-none"
        ref={parentRef}
      >
        {/* LinkedIn URLs */}
        <div
          className={`${
            getPrevSelectedBtnType === "Companies" ? "mb-0" : "mb-[32px]"
          }`}
        >
          <p className="font-[500] text-[14px] text-[#344054] font-inter mb-2">
            {getPrevSelectedBtnType === "Companies"
              ? "Website URL"
              : "LinkedIn URLs"}
          </p>
          {linkedinInputs.map((input, index, arr) => (
            <div key={input.id} className="relative flex items-center w-full mb-[12px]">
              <OutlinedInput
                // type="text"
                value={input.value}
                onChange={(event) => handleLinkedinInputChange(input.id, event)}
                placeholder={
                  getPrevSelectedBtnType === "Companies"
                    ? "Enter website URL"
                    : "Enter linkedin URL"
                }
                className="pl-[44px] mr-[30px] w-[80%]"
              />
              <FiSearch className="w-[20px] h-[20px] text-[20px] text-[#667085] absolute left-[16px] top-[12px]" />
              {arr.length > 1 && index > 0 ? (
                <img
                  src={deleteIcon}
                  alt="delete"
                  className="w-[20px] h-[20px] min-w-[20px] mt-[-2px] cursor-pointer"
                  onClick={() => handleRemoveLinkedinInput(input.id)}
                  draggable={false}
                />
              ) : (
                <div className="w-[20px] h-[20px]" />
              )}
            </div>
          ))}
          <button
            className="border-0 !outline-none font-inter font-[600] text-[#6941C6] text-[14px] flex items-center"
            onClick={addLinkedinInput}
          >
            <img src={addIcon} alt="add" className="max-w-[14px] mr-[10px]" />
            Add another
          </button>
        </div>
        {/* Email addresses */}
        {/* {getPrevSelectedBtnType === "People" && (
          <div>
            <p className="font-[500] text-[14px] text-[#344054] font-inter mb-2">
              Email address
            </p>
            {emailInputs.map((input, index, arr) => (
              <div key={input.id} className="relative flex items-center mb-[12px] ">
                <OutlinedInput
                  type="email"
                  value={input.value}
                  onChange={(event) => handleEmailInputChange(input.id, event)}
                  placeholder="Enter email address"
                  className="pl-[44px] mr-[30px]"
                />
                <img
                  src={mailIcon}
                  alt="mail"
                  className="w-[20px] h-[20px] absolute left-[16px] top-[12px]"
                />
                {arr.length > 1 && index > 0 ? (
                  <img
                    src={deleteIcon}
                    alt="delete"
                    className="w-[20px] h-[20px] min-w-[20px] mt-[-2px] cursor-pointer"
                    onClick={() => handleRemoveEmailInput(input.id)}
                  />
                ) : (
                  <div className="w-[20px] h-[20px]" />
                )}
              </div>
            ))}
            <button
              className="border-0 !outline-none font-inter font-[600] text-[#6941C6] text-[14px] flex items-center"
              onClick={addEmailInput}
            >
              <img src={addIcon} alt="add" className="max-w-[14px] mr-[10px]" />
              Add another
            </button>
          </div>
        )} */}
      </div>
      <div className="flex items-center justify-center mt-[32px]">
        <Button
          title="Back"
          isWhiteBtn={true}
          isRounded="6px"
          onClick={() => {
            if (appState?.importMethodClicked === "manuallyLink"){
              setStep("import-options");
            }else{
              setStep("manually-default");}}
            }
          className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow !text-[#344054]"
        />
        <Button
          title={(addEnrichLeadsAPI?.isPending) ? (
            <div className="w-[20px] h-[20px] mx-auto border-[3px] border-t-[3px] border-[#fff] border-solid rounded-full animate-spin border-t-[#8A43FF]" />
          ) : (
            "Confirm"
          )}
          secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9] ml-[12px]"
          className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow"
          isRounded="6px"
          disabled={!linkedinInputs?.every((item) => 
            /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=%]*)?\/?$/.test(item?.value)
          )}
          onClick={handleConfirmClick}
        />
      </div>
    </>
  );
};

export default ManuallyEnterStep;
