import { useMutation, useQuery } from "@tanstack/react-query";
import axiosInt from "services";

const useGetWorkspaceSettings = () =>
  useMutation({
    mutationFn: async () => {
      const response = await axiosInt.get(`/workspace/settings/get_profile`);
      return response;
    },
  });

const useUpdateWorkspaceSettings = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.put(
        `/workspace/settings/update_profile`,
        data
      );
      return response;
    },
  });

const useDeleteCollaborator = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.delete(
        `/workspace/delete_collaborator`,
        data
      );
      return response;
    },
  });

const useGetNotificationSettings = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt?.get(
        `/notification/get_notification_settings`
      );
      return response;
    },
  });

const useUpdateNotificationSettings = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt?.post(
        `/notification/update_notification_settings`,
        data
      );
      return response;
    },
  });
const useMarkAllAsReadNotifications = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt?.post(
        `/notification/mark_all_as_read`,
        data
      );
      return response;
    },
  });

const useAddCollaborators = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt?.post(
        `/workspace/settings/add_collaborators`,
        data
      );
      return response;
    },
  });

const useSwapAdminRole = () =>
  useMutation({
    mutationFn: async (data) => {
      const resonse = await axiosInt?.post(`/workspace/swap_admin_role`, data);
      return resonse;
    },
  });

const useEditInviteEmail = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.post(
        `/workspace/edit_invite_email`,
        data
      );
      return response;
    },
  });

const useManualBlockDomain = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.post(
        `/workspace/settings/manual_block_domain`,
        data
      );
      return response;
    },
  });

const useGetBlockDomain = () =>
  useMutation({
    mutationFn: async () => {
      const response = await axiosInt.get(
        `/workspace/settings/get_block_domain`
      );
      return response;
    },
  });

const useDeleteBlockDomain = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.delete(
        `/workspace/settings/delete_block_domain`,
        { data }
      );
      return response.data;
    },
  });

const useResendInvite = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.post(`/workspace/resend_invite`, data);
      return response;
    },
  });

const useDomainCsvUpload = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.post(
        `/workspace/settings/csv_block_domain`,
        data
      );
      return response;
    },
  });

const useGetNotification = (isNotificationModalVisible) =>
  useMutation({
    mutationFn: async () => {
      const response = await axiosInt?.get(`/notification/get_notification`);
      return response;
    },
    enabled: !!isNotificationModalVisible,
  });

const useReadNotification = (data) =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.post(`/notification/is_read`, data);
      return response;
    },
  });

const useDismissNotification = (data) =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.post(`/notification/dismiss`, data);
      return response;
    },
  });

const useGetNotificationCount = () =>
  useMutation({
    mutationFn: async () => {
      const response = await axiosInt?.post(`/notification/unread_count`);
      return response;
    },
  });

export {
  useGetWorkspaceSettings,
  useUpdateWorkspaceSettings,
  useDeleteCollaborator,
  useGetNotificationSettings,
  useUpdateNotificationSettings,
  useAddCollaborators,
  useSwapAdminRole,
  useEditInviteEmail,
  useManualBlockDomain,
  useGetBlockDomain,
  useDeleteBlockDomain,
  useResendInvite,
  useDomainCsvUpload,
  useGetNotification,
  useReadNotification,
  useDismissNotification,
  useMarkAllAsReadNotifications,
  useGetNotificationCount,
};
