import { ToastContainer, cssTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "animate.css/animate.min.css";

const bounce = cssTransition({
  enter: "animate__animated animate__fadeIn",
  exit: "animate__animated animate__fadeOut",
});

const Toaster = () => {
  return (
    <ToastContainer
      position="top-right"
      className="toaster-styling"
      transition={bounce}
      draggable
      pauseOnFocusLoss={false}
      autoClose={5000}
      icon={false}
      bodyClassName="toastBody"
      hideProgressBar={true}
    />
  );
};

export default Toaster;
