const Button = (props) => {
  const {
    title,
    onClick,
    isWhiteBtn,
    isRounded,
    isFullWidth = true,
    height,
    className,
    style,
    secondaryColor,
    hideHover,
    disabled,
    gradientBg,
  } = props;
  return (
    <button
      style={style}
      className={`bg-primary ${disabled ? "opacity-50" : "opacity-100"} ${
        height ? "h-[32px]" : "h-[42px]"
      }  rounded-[8px] px-[12px] outline-none text-white text-[14px] font-[600]  transition-all
      ${
        gradientBg
          ? "bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 hover:bg-gradient-to-br"
          : "hover:bg-transparent border-[1px] border-primary hover:text-primary"
      }
        ${
          isWhiteBtn &&
          `bg-white !border-[#DDE2E4] !border-[1px] !text-[#252C32] py-[4px] px-[12px] font-normal hover:text-[#252C32] ${
            !hideHover && "hover:!bg-[#f5f5f5db]"
          }  !h-[32px]`
        }
        ${secondaryColor}
        ${isRounded && "rounded-[6px]"}
        ${isFullWidth && "w-full"}
        ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {title}
    </button>
  );
};

export default Button;
