import Button from "components/button";
import listIcon from "assets/images/save-list.svg";
import OutlinedInput from "components/outlinedInput";
import { useState } from "react";
import { toast } from "react-toastify";
import { useProspectStore } from "stores";

const ResultSaveLeadStep = ({ setResultSteps }) => {
  const { setSaveLeadName } = useProspectStore();

  const [prospectName, setProspectName] = useState("");

  const handleCreateProspect = async () => {
    if (!prospectName.trim()) {
      toast.success("Prospect name is required.");
      return;
    }
    setSaveLeadName(prospectName);
    setProspectName("");
    setResultSteps(3);
  };

  return (
    <div className="modal-scroll w-[480px]">
      <div className="flex flex-col items-start justify-start mb-[20px] font-inter">
        <div className="flex items-center justify-center border-[1px] border-[#E4E7EC] rounded-[10px] p-[11px] mb-[16px] y-axis-shadow">
          <img src={listIcon} alt="info img" className="h-[24px] w-[24px]" />
        </div>
        <h4 className="font-[600] text-[18px] text-[#101828] m-0 font-inter">
          Save Lead List
        </h4>
        <p className="font-normal text-[14px] text-[#475467] font-inter">
          Enter a name for your prospected lead list.
        </p>
      </div>
      <OutlinedInput
        value={prospectName}
        onChange={(e) => setProspectName(e.target.value)}
        type="text"
        placeholder="Enter a Name"
        className="!h-[44px] w-full"
      />

      <div className="flex items-center justify-center mt-[32px]">
        <Button
          title="Back"
          isWhiteBtn={true}
          isRounded="6px"
          onClick={() => setResultSteps(1)}
          className="!text-[16px] !h-[44px] !font-[600] !text-[#344054] y-axis-shadow"
        />
        <Button
          title="Save"
          secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9] ml-[12px]"
          className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow"
          isRounded="6px"
          onClick={handleCreateProspect}
        />
      </div>
    </div>
  );
};

export default ResultSaveLeadStep;
