import { IoClose } from "react-icons/io5";
import Button from "components/button";
import checkBg from "assets/images/check-bg.svg";
import { useEffect, useState } from "react";
import connectIcon from "assets/images/captcha.svg";
import qrCode from "assets/images/qr.svg";

const ReconnectQRModal = ({
  isOpen,
  onClose,
  setIsLinkedinAccountConnected,
  handleGetLinkedInAccountDetails,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [isOpen]);
  if (!isOpen && !isModalVisible) return null;

  return (
    <>
      <div
        className={`fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[300ms] ease-in-out ${
          isModalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
      >
        <div
          className="fixed inset-0 bg-black opacity-10"
          onClick={onClose}
        ></div>
        <div
          className={`p-[24px] bg-white rounded-lg shadow-lg z-10 w-full max-w-[556px] relative transition-all ease-in-out duration-[150ms] ${
            isModalVisible ? "scale-100" : "scale-95"
          }`}
        >
          <IoClose
            className="text-[#667085] text-[20px] w-[24px] h-[24px] absolute right-[24px] top-[27px] cursor-pointer"
            onClick={onClose}
          />
          <div className="modal-scroll">
            <div className="flex flex-col items-start justify-start mb-[20px] font-inter">
              <div
                className="flex justify-center items-center border-[#E4E7EC] border w-[48px] h-[48px] rounded-[10px] mb-[22px]"
                style={{
                  boxShadow:
                    "0px 4px 8px 0px rgba(16, 24, 40, 0.05), 0px 2px 0px 0px rgba(16, 24, 40, 0.05), 0px 0px 1px 0px rgba(16, 24, 40, 0.18)",
                }}
              >
                <img src={qrCode} alt="qrCode" className="h-[24px] w-[24px]" />
              </div>
              <h4 className="font-[600] text-[16px] text-[#101828] mb-[5px] font-inter">
                Scan the QR Code{" "}
              </h4>
              <p className="font-normal text-[#475467] text-[14px]">
                With your mobile device, please scan the QR code.
              </p>
            </div>
            <div className="border-[#CBCBE84F] border-[1px] h-[412px] w-full"></div>
            <div className="flex items-center justify-center mt-[20px]">
              <Button
                isFullWidth={false}
                className="!bg-[#7F56D9] !border-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9] hover:!border-[#7F56D9] text-[#fff] !h-[44px] w-full !font-[600] text-[16px] rounded-[8px]"
                title={"Confirm"}
                onClick={() => {
                  handleGetLinkedInAccountDetails();
                  setIsLinkedinAccountConnected(true);
                  onClose();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReconnectQRModal;
