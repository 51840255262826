import { useContext, useState } from "react";
import Button from "components/button";
import buildingIcon from "assets/images/modal-building.svg";
import { AppContext } from "context/AppContext";
import {
  useCompanySearchLeads,
  useManualCompanySearchLeads,
} from "services/prospecting-api";
import { useAuthStore, useProspectStore } from "stores";
import ExcludeCross from "assets/images/exclude-cross.svg";
import CrossIcon from "assets/images/cross-small.svg";
import { toast } from "react-toastify";
import TypeInput from "components/leadsComponent/type-input";

const JobSelectionStep = ({ setStep, setTotalDataCsv }) => {
  const { setAppState } = useContext(AppContext);
  const searchCompanyLeadsAPI = useCompanySearchLeads();
  const searchLeadsAPI = useManualCompanySearchLeads();
  const { fileId } = useProspectStore();
  const { companyId } = useAuthStore();
  const { appState } = useContext(AppContext);
  const [isLoading, setLoading] = useState(false);

  const [formData, setFormData] = useState({ job_title: [] });
  const [showJobList, setJobShowList] = useState(false);
  console.log(appState, "appState");
  const handleRemoveChip = (value) => {
    const updatedHistory = formData?.job_title?.filter(
      (option) => option !== value
    );
    setFormData({
      job_title: updatedHistory,
    });
  };

  const handleConfirmSearchLeads = async () => {
    if (formData?.job_title?.length) {
      const payload = {
        file_id: fileId,
        company_id: companyId,
        job_title: formData?.job_title?.map((selection) => {
          return {
            v: transformItem(selection),
            s: "i",
          };
        }),
      };
      setAppState((prev) => ({
        ...prev,
        job_title: payload?.job_title,
      }));
      try {
        setLoading(true);
        const repsonse = await searchCompanyLeadsAPI?.mutateAsync(payload);
        setTotalDataCsv(repsonse?.total);
        setLoading(false);
        console.log({ repsonse });
        // setStep("looking-for-leads");
        setStep("enrich-leads-step");
      } catch (error) {
        setLoading(false);
        toast.success(error?.data?.detail || "Api Timeout");
      }
    } else {
      toast.success("Please Enter Job titles to continue");
    }
  };
  const handleSearchLeads = async () => {
    try {
      setLoading(true);
      const payload = {
        list_data: appState?.addedLinks || [],
        job_title: formData?.job_title?.map((selection) => {
          return {
            v: transformItem(selection),
            s: "i",
          };
        }),
      };
      const response = await searchLeadsAPI?.mutateAsync(payload);
      setTotalDataCsv(response?.total);
      setAppState((prev) => ({
        ...prev,
        totalLeads: response?.total,
        job_title: formData?.job_title?.map((selection) => {
          return {
            v: transformItem(selection),
            s: "i",
          };
        }),
      }));
      setLoading(false);
      setStep("enrich-leads-step");
    } catch (error) {
      setLoading(false);
      toast.success(error?.data?.detail);
    }
  };

  const transformItem = (item) => {
    if (item?.startsWith("Ex:")) {
      return item.slice(3);
    } else if (
      item?.endsWith("/year_founded_start") ||
      item?.endsWith("/year_founded_end")
    ) {
      return item.slice(0, 4);
    } else {
      return item;
    }
  };

  if (isLoading) return setStep("looking-for-leads");

  return (
    <>
      <div className="flex flex-col items-start justify-start mb-[20px] font-inter">
        <div className="flex items-center justify-center border-[1px] border-[#E4E7EC] rounded-[10px] p-[11px] mb-[16px] y-axis-shadow">
          <img
            src={buildingIcon}
            alt="info img"
            className="h-[24px] w-[24px]"
          />
        </div>
        <h4 className="font-[600] text-[18px] text-[#101828] m-0 font-inter">
          Job Titles
        </h4>
        <p className="font-normal text-[14px] text-[#475467] font-inter">
          What roles do you want to prospect for?
        </p>
      </div>

      <div className="modal-form-step mb-[20px]">
        <TypeInput
          setJobShowList={setJobShowList}
          showJobList={showJobList}
          name={"job_title"}
          options={[]}
          leadType={"Job Title"}
          placeholder={"Enter or Select Job Title(s)"}
          leadTitle={"Job Title"}
          title={"Job Title"}
          formData={formData}
          setFormData={setFormData}
          subTitle=""
          active={true}
          isImportDataModalInput
        />
        <div className="flex flex-wrap mt-[-80px] mb-[20px] relative z-50">
          {formData?.job_title?.map((item, index) => (
            <div
              onClick={(event) => {
                event?.stopPropagation();
              }}
              key={index}
              className={`flex items-center max-w-[250px] font-inter font-[600] ${
                item?.startsWith("Ex:")
                  ? "bg-[#FCE7F3] text-[#BE185D]"
                  : "bg-[#F5EBFF] text-[#7E22CE]"
              }  capitalize text-xs rounded-[6px] pl-0 p-1 mr-[8px] mt-[8px]`}
            >
              <span className="overflow-hidden truncate pl-[10px] pr-[6px] whitespace-nowrap font-[500] flex-1">
                {transformItem(item)}
              </span>
              <button
                type="button"
                onClick={(e) => {
                  // e.stopPropagation();
                  // onDeleteChip(item);
                  handleRemoveChip(item);
                }}
                className={`text-[#6941c6] font-[600] pr-[2px] w-[20px] z-[999999999]`}
              >
                <img
                  src={item?.startsWith("Ex:") ? ExcludeCross : CrossIcon}
                  alt="close"
                  className={`${
                    item?.startsWith("Ex:")
                      ? "w-[8.5px] h-[8.5px]"
                      : "w-[20px] h-[20px]"
                  }`}
                />
              </button>
            </div>
          ))}
        </div>
      </div>

      <div className="flex items-center justify-center mt-[32px] relative">
        {!formData?.job_title?.length ? (
          <Button
            title="Cancel"
            isWhiteBtn={true}
            isRounded="6px"
            onClick={() => {
              if (
                appState?.importMethodClicked === "manually-default" ||
                appState?.importMethodClicked === "manuallyCompanyUrl"
              ) {
                setStep("manually-enter");
              } else {
                setStep("csv-form-step");
              }
            }}
            className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow !text-[#344054]"
          />
        ) : (
          <Button
            title={
              searchCompanyLeadsAPI?.isPending || searchLeadsAPI?.isPending ? (
                <div className="w-[20px] h-[20px] mx-auto border-[3px] border-t-[3px] border-[#fff] border-solid rounded-full animate-spin border-t-[#8A43FF]" />
              ) : (
                "Confirm"
              )
            }
            secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9] ml-[12px]"
            className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow"
            isRounded="6px"
            onClick={() => {
              if (
                appState?.selectedButtonType === "Companies" &&
                (appState?.importMethodClicked === "manually-default" ||
                  appState?.importMethodClicked === "manuallyCompanyUrl")
              ) {
                handleSearchLeads();
              } else {
                handleConfirmSearchLeads();
              }
            }}
            disabled={formData?.job_title?.length === 0}
          />
        )}
      </div>
    </>
  );
};

export default JobSelectionStep;
