import React from "react";
import { MuiChipsInput } from "mui-chips-input";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete"; // Or use a custom icon
import SearchIcon from "assets/images/search-lg.svg";

const SearchBar = ({ handleChange, searchList, handleSearch }) => {
  return (
    <div
      className={`flex items-center border border-[#D5D7DA] rounded-[8px] w-[479px] min-h-10 ${
        searchList?.length === 0 ? "pl-2" : ""
      }`}
    >
      {searchList?.length === 0 && (
        <img
          src={SearchIcon}
          alt="search"
          className="w-[16px] h-[16px]"
          style={{
            filter:
              "brightness(0) saturate(100%) invert(79%) sepia(9%) saturate(188%) hue-rotate(184deg) brightness(85%) contrast(84%)",
          }}
        />
      )}
      <div className="flex items-center justify-end w-full overflow-auto modal-scroll ">
        <MuiChipsInput
          value={searchList}
          onChange={handleChange}
          placeholder={
            searchList?.length === 0
              ? "Search by lead data (e.g. San Francisco)"
              : ""
          }
          className={`text-[#ADADAD] text-[12px] border-[0px] style-chips-input !pr-[10px] searchInput ${
            searchList?.length === 0 ? "hasSearchIcon" : ""
          }`}
          hideClearAll
        />
      </div>
      <button
        onClick={handleSearch}
        className="text-[#414651] text-[14px] font-[600] px-[12px] w-[100px] border-l border-[#D5D7DA] h-[40px]"
      >
        Search
      </button>
    </div>
  );
};

export default SearchBar;
