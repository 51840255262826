import { useMutation } from "@tanstack/react-query";
import { setAccessToken } from "utils/token-service";
import axiosInt from "services";

const useLogin = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt.post("/login/auth_login", data);
      const { token } = response;
      if (token) {
        setAccessToken(token);
        axiosInt.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      }
      return response;
    },
  });

const useSignup = () =>
  useMutation({
    mutationFn: (data) => axiosInt.post("/login/signup", data),
  });

const useAuthCheck = () =>
  useMutation({
    mutationFn: (data) => {
      return axiosInt.post(
        `/login/auth_check${
          data?.impersonate_user
            ? `?impersonate_user=${data?.impersonate_user}`
            : ""
        }`
      );
    },
  });

const useEmailVerification = () =>
  useMutation({
    mutationFn: (data) => axiosInt.post("/login/confirm_signup", data),
  });

const useResendCode = () =>
  useMutation({
    mutationFn: ({ email }) => {
      const encodedEmail = encodeURIComponent(email);
      return axiosInt.get(`/login/resend_code?email=${encodedEmail}`);
    },
  });

const useForgotPassword = () =>
  useMutation({
    mutationFn: ({ email }) =>
      axiosInt.get(`/login/forgot_password?email=${email}`),
  });

const useForgotPasswordConfirm = () =>
  useMutation({
    mutationFn: (data) => axiosInt.post("/login/forgot_password_confirm", data),
  });

const useCreateCompany = () =>
  useMutation({
    mutationFn: (data) => axiosInt.post("/workspace/create_company", data),
  });

const useCreateLinkedinAccount = () =>
  useMutation({
    mutationFn: (data) => axiosInt.post("/linkedin_v2/create_account", data),
  });

const useReconnectLinkedinAccount = () =>
  useMutation({
    mutationFn: (data) => axiosInt.post("/linkedin_v2/reconnect_account", data),
  });

const useResendLinkedinVerificationCode = () =>
  useMutation({
    mutationFn: (data) =>
      axiosInt?.post("/linkedin_v2/checkpoint/resend", data),
  });

const useLinkedinAuthentication = () =>
  useMutation({
    mutationFn: (data) => axiosInt.post("/linkedin_v2/2fa_authenticate", data),
  });

const useLinkedinChallengerCode = () =>
  useMutation({
    mutationFn: (data) => axiosInt.post("/linkedin_v2/challenge_code", data),
  });

const useLinkedinPremiumCheck = () =>
  useMutation({
    mutationFn: () => axiosInt.get("linkedin_v2/premium_checker"),
  });

const useVerifyLinkedin = () =>
  useMutation({
    mutationFn: async ({ account_id }) => {
      const response = await axiosInt.get(
        `/linkedin_v2/verify_account_status?account_id=${account_id}`
      );
      return response;
    },
  });

const useUpdatePassword = () =>
  useMutation({
    mutationFn: async ({ password }) => {
      const response = await axiosInt.post(`/login/update_password`, {
        password,
      });
      return response;
    },
  });

const useLinkedInChallengerPhone = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt?.post(
        `/linkedin_v2/phone_challenge`,
        data
      );
      return response;
    },
  });

const useLinkedInPhoneCode = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt?.post(
        `/linkedin_v2/phone_challenge_code`,
        data
      );
      return response;
    },
  });

const useProxyLocation = () =>
  useMutation({
    mutationFn: async ({ query }) => {
      const response = await axiosInt?.get(
        `/fields/proxy-location?query=${query}`
      );
      return response;
    },
  });

const useGoogleSignin = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt?.post(`/login/google-login`, data);
      const { token } = response;
      if (token) {
        setAccessToken(token);
        axiosInt.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      }
      return response;
    },
  });

const useGoogleSignUp = () =>
  useMutation({
    mutationFn: async (data) => {
      const response = await axiosInt?.post(`/login/google-signup`, data);
      const { token } = response;
      if (token) {
        setAccessToken(token);
        axiosInt.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      }
      return response;
    },
  });

const useCreateLinkedinV2Account = () =>
  useMutation({
    mutationFn: (data) => axiosInt.post("/linkedin_v2/create_account", data),
  });

const useLinkedinCallback = () =>
  useMutation({
    mutationFn: (data) =>
      axiosInt.post("linkedin_v2/checkpoint/callback", data),
  });

export {
  useLogin,
  useSignup,
  useAuthCheck,
  useEmailVerification,
  useResendCode,
  useForgotPassword,
  useForgotPasswordConfirm,
  useReconnectLinkedinAccount,
  useResendLinkedinVerificationCode,
  useCreateCompany,
  useCreateLinkedinAccount,
  useLinkedinAuthentication,
  useLinkedinChallengerCode,
  useLinkedinPremiumCheck,
  useVerifyLinkedin,
  useUpdatePassword,
  useLinkedInChallengerPhone,
  useLinkedInPhoneCode,
  useProxyLocation,
  useGoogleSignin,
  useGoogleSignUp,
  useCreateLinkedinV2Account,
  useLinkedinCallback,
};
