import { createContext, useEffect, useMemo, useState } from "react";

const AppContext = createContext();

const INITIAL_STATE = {
  importMethodClicked: "",
  saveListName: "",
  isAdmin: false,
  isSendRequestCheckBoxChecked: true,
  selectedFollowUpMessageOptoin: null,
  selectedConnectionRequestMessageOption: null,
  existingConnectionMessageOption: null,
  sendConnectionRequestMessage: ``,
  followUpMessage: ``,
  existingConnectionMessage: ``,
  alternativeEmail: "",
  selectedLeadList: null,
  numberOfLeadsToEnroll: "",
  activeLeadListSteps: [0],
  activeCampaignName: "",
  leadsStepCompleted: false,
  stepsStepCompleted: false,
  settingsStepCompleted: false,
  selectedButtonType: "Peoples",
  addProspectingData: false,
  isDeleteCsv: false,
};

const AppContextProvider = (props) => {
  const { children } = props;
  const [appState, setAppState] = useState(INITIAL_STATE);
  const getIsAdmin = localStorage.getItem("isAdmin");

  useEffect(() => {
    setAppState((prev) => ({
      ...prev,
      isAdmin: getIsAdmin === "true" ? true : false,
    }));
  }, [getIsAdmin]);

  const setSelectedButtonType = (type) => {
    setAppState((prev) => ({
      ...prev,
      selectedButtonType: type,
    }));
  };

  const values = useMemo(
    () => ({
      appState,
      setAppState,
      setSelectedButtonType,
    }),
    [appState]
  );
  
  useEffect(() => {
    const storedData = localStorage.getItem("stepCompletionData");

    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setAppState((prev) => ({
        ...prev,
        leadsStepCompleted: parsedData?.leadsStepCompleted || false,
        stepsStepCompleted: parsedData?.stepsStepCompleted || false,
        settingsStepCompleted: parsedData?.settingsStepCompleted || false,
      }));
    }
  }, []);

  useEffect(() => {
    const dataToSave = {
      leadsStepCompleted: appState?.leadsStepCompleted || false,
      stepsStepCompleted: appState?.stepsStepCompleted || false,
      settingsStepCompleted: appState?.settingsStepCompleted || false,
    };

    localStorage.setItem("stepCompletionData", JSON.stringify(dataToSave));
  }, [
    appState?.leadsStepCompleted,
    appState?.stepsStepCompleted,
    appState?.settingsStepCompleted,
  ]);

  return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
};

export { AppContext, AppContextProvider };
