import { randomCompanyLogo } from "constant";
import { useState } from "react";
import { getNameInititals } from "utils/";
import InfiniteScroll from "react-infinite-scroll-component";
import verified from "assets/images/verified.svg";
import linkedInImg from "assets/images/colored-linkedin.svg";

const TableHeader = ["Lead", "Company", "Action"];

const ReviewTable = ({
  rowData,
  rowClick,
  isAddLeadModal = false,
  setIsDeleteModalOpen,
  setSelectedLeadId,
  fetchData,
  hasMore,
  totalLeads,
}) => {
  const [companyLogoMap, setCompanyLogoMap] = useState({});

  const onRowClick = (item) => {
    rowClick(item);
  };

  const getCompanyLogo = (companyName) => {
    if (companyLogoMap[companyName]) {
      return companyLogoMap[companyName];
    }
    const randomLogo =
      randomCompanyLogo[Math.floor(Math.random() * randomCompanyLogo.length)];
    setCompanyLogoMap((prevMap) => ({
      ...prevMap,
      [companyName]: randomLogo.src,
    }));

    return randomLogo.src;
  };

  return (
    <div
      className="round-table-scroll max-h-[65vh] overflow-auto w-full border border-[#E4E7EC] rounded-xl modal-scroll"
      id="scrollableDiv"
    >
      <InfiniteScroll
        dataLength={rowData?.length}
        next={fetchData}
        hasMore={hasMore}
        loader={
          rowData?.length > 20 &&
          totalLeads !== rowData?.length && (
            <div className="h-[50px] w-full flex items-center justify-center">
              <div className="w-[20px] h-[20px] border-[3px] border-t-[3px] border-[#7F56D9] border-solid rounded-full animate-spin border-t-[#fff]" />
            </div>
          )
        }
        scrollableTarget="scrollableDiv"
        style={{ height: "100%", overflow: "unset" }}
      >
        <table className="min-w-full h-unset common-table-shadow rounded-xl">
          <thead className="sticky top-0 bg-white z-40 overflow-hidden rounded-[12px]">
            <tr className="w-full relative">
              {TableHeader.map((header, index) => (
                <th
                  key={index}
                  className={`text-[13px] font-[500] py-[10px] px-[21px] text-[#475467] capitalize text-left ${
                    isAddLeadModal && "!text-[#475467] !text-[10.73px]"
                  }`}
                >
                  {header}
                </th>
              ))}
            </tr>
            <span className="w-full border-b border-[#E4E7EC] h-[1px] absolute top-full z-10 left-0" />
          </thead>
          <tbody>
            {rowData?.length > 0 ? (
              rowData?.map((item, index) => {
                const isTdLastIndex = index === rowData.length - 1;
                return (
                  <tr
                    className={`${
                      isTdLastIndex ? "" : "border-b"
                    } group hover:bg-gray-50 cursor-pointer`}
                    key={index}
                    onClick={() => onRowClick(item)}
                  >
                    <td className="text-[13px] text-[#101828] px-[24px] py-[16px] text-left  w-[350px] max-[800px]:w-[unset]">
                      <div className="flex items-center w-full">
                          <a
                            href={item?.linkedin_url}
                            target="_blank"
                            onClick={(e) => e?.stopPropagation()}
                            rel="noreferrer"
                            className="z-100"
                          >
                            <img
                              src={linkedInImg}
                              className="mr-[12px] h-[20.11px] w-[20.11px] min-w-[20px]"
                              alt="Linkedin Icon"
                            />
                          </a>
                        <div className="w-[40px] min-w-[40px] h-[40px] mr-[12px] relative">
                          {item?.profile_pic ? (
                            <img
                              src={item?.profile_pic}
                              alt="lead"
                              className="w-full h-full rounded-[25px] object-cover object-top"
                            />
                          ) : (
                            <div className="w-[40px] h-[40px] min-w-[40px] rounded-full flex items-center justify-center text-[14px] font-[700] text-[#4409B9] bg-[#EEEDFD] mr-[10px]">
                              {getNameInititals(item?.name)}
                            </div>
                          )}
                          <img
                            src={verified}
                            alt="verified"
                            className="absolute bottom-0 right-0 w-[14px] h-[14px]"
                          />
                        </div>
                        <div className="w-full">
                          <p
                            className={`font-[500] text-[#101828] text-[14px] leading-[17px] ${
                              isAddLeadModal && "!text-[12.51px]"
                            }`}
                          >
                            {item?.name}
                          </p>
                          <p
                            className={`font-[400] text-[#475467] text-[12px] leading-[17px] ${
                              isAddLeadModal && "!text-[12.51px]"
                            }`}
                          >
                            {item?.job_title}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="text-[13px] pl-[24px] py-[16px] text-left  w-[350px] max-[800px]:w-[unset]">
                        <div className="flex items-center w-full">
                          <div className="w-[40px] h-[40px] mr-[12px]">
                            <img
                              src={
                                item?.company_logo.length
                                  ? item?.company_logo
                                  : getCompanyLogo(item?.company)
                              }
                              alt="lead"
                              className="w-full h-full"
                            />
                          </div>
                          <div className="flex-1">
                            <p className="font-[500] text-[#101828] text-[14px] m-0 leading-[20px] ">
                              {item?.company}
                            </p>
                            <p className="font-[400] text-[#475467] text-[14px] m-0 leading-[17px] ">
                              {item?.company_domain}
                            </p>
                          </div>
                      </div>
                    </td>
                    <td
                      className={`text-[13px] cursor-pointer px-[24px] py-[16px] font-[600] text-left  text-[#767577] w-[100px] max-[500px]:w-[unset]  ${
                        isAddLeadModal && "!text-[12.51px]"
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedLeadId(item?.lead_id);
                        setIsDeleteModalOpen(true);
                      }}
                    >
                      Remove
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  className="text-[14px] px-[22px] py-[10px] text-center text-[#474563] h-[400px]"
                  colSpan={TableHeader.length}
                >
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </InfiniteScroll>
    </div>
  );
};

export default ReviewTable;
