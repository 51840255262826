import QrCodeIcon from "assets/images/qr-code.png";

const StepQrCode = () => {
  return (
    <div
      className="font-inter !h-full  flex flex-col justify-center items-center  m-auto max-[767px]:h-auto  max-[767px]:p-[20px]  max-[767px]:pt-[50px] "
      data-aos="fade-in"
      data-aos-duration="2000"
    >
      <div className="!w-[480px] flex flex-col items-center relative signin-card">
        <div className="w-16 h-16 flex items-center justify-center rounded-xl border border-[#333741] bg-[#0C111D]">
          <img
            src={QrCodeIcon}
            alt="Logo"
            className="h-8 w-8 block"
            style={{
              filter: "brightness(1.2)",
            }}
          />
        </div>
        <h1 className="text-[30px] text-center text-[#F5F5F6] font-[600] mt-[20px] mb-[1px]">
          Scan QR Code
        </h1>
        <p className="text-[16px] font-[400] text-center text-[#94969C] leading-[24px]">
          With your mobile device, please scan the QR code.
        </p>

        <div className="flex w-full items-center justify-center">
          <div className="h-[300px] w-[90%] border-[2px] rounded-lg border-[#CBCBE84F] mt-10"></div>
        </div>
      </div>
    </div>
  );
};

export default StepQrCode;
