import { useEffect } from "react";
import { ArkoseCaptchaIntegration } from "utils/arkose";
import CaptchaIcon from "assets/images/scanner-qr.png";
import { useLinkedinCallback } from "services/auth-api";
import { toast } from "react-toastify";

const StepCaptcha = ({
  data,
  setStep,
  setLoader,
  accountId,
  setIsPhoneCard,
  setLikedInData,
  startVerificationInterval,
}) => {
  const linkedInCallback = useLinkedinCallback();

  const publicKey = data?.public_key;

  const handleCallback = async (token) => {
    try {
      const response = await linkedInCallback.mutateAsync({
        account_id: String(accountId),
        code: token,
      });

      if (response?.status === 201) {
        if (response?.message === "AccountCreated") {
          startVerificationInterval(String(accountId));
        }
      }

      if (response?.status === 202) {
        if (response?.checkpoint?.type === "OTP") {
          setLikedInData(response);
          setLoader(false);
          setStep(1);
          setIsPhoneCard(false);
        } else if (response?.checkpoint?.type === "2FA") {
          setLoader(false);
          setStep(7);
          return;
        } else if (response?.checkpoint?.type === "PHONE_REGISTER") {
          setLoader(false);
          setIsPhoneCard(true);
          setStep(1);
          return;
        } else if (response?.checkpoint?.type === "IN_APP_VALIDATION") {
          setLoader(false);
          setStep(6);
          return;
        }
      }  else if (response?.status === 400) {
        setLoader(false);
        setStep(0);
        toast.error(response?.message);
      }
    } catch (error) {
      setLoader(false);
      setStep(0);
      toast.error("Login failed");
    }
  };

  useEffect(() => {
    if (!publicKey) return;

    const captcha = new ArkoseCaptchaIntegration(publicKey, data?.data);

    // Setup the callbacks
    captcha.onLoaded = () => {
      console.log("Captcha has been successfully loaded into the DOM!");
    };

    captcha.onSuccess = (token) => {
      console.log("Captcha has been resolved!", { token });
      captcha.unload();
      setLoader(true);
      handleCallback(token);
      // setStep(2);
    };

    captcha.onError = () => {
      console.log("Captcha resolution failed!");
    };

    try {
      captcha.load("captcha-frame");
    } catch (error) {
      console.error(error.message);
    }

    // Cleanup function to unload captcha on component unmount
    return () => {
      captcha.unload();
    };
  }, [publicKey, data]);

  return (
    <div
      className="font-inter !h-full flex flex-col justify-center items-center m-auto max-[767px]:h-auto max-[767px]:p-[20px] max-[767px]:pt-[50px]"
      data-aos="fade-in"
      data-aos-duration="2000"
    >
      <div className="!w-[425px] flex flex-col items-center relative signin-card">
        <div className="w-[60px] h-[60px] flex items-center justify-center rounded-xl border border-[#333741] bg-[#0C111D]">
          <img
            src={CaptchaIcon}
            alt="Logo"
            className="h-[30px] w-[30px] block"
            style={{
              filter: "brightness(1.2)",
            }}
          />
        </div>
        <h1 className="text-[30px] text-center text-[#F5F5F6] font-[600] mt-[20px] mb-[5px]">
          Solve the Captcha
        </h1>
        <p className="text-[16px] font-[400] text-center text-[#94969C] leading-[24px]">
          You’ll need to solve the captcha below.
        </p>

        <div className="flex w-full items-center justify-center">
          <div className="h-[290px] w-[89%] border-[2px] rounded-lg border-[#CBCBE84F] overflow-hidden mt-10">
            <div id="captcha-frame"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepCaptcha;
