import { IoClose } from "react-icons/io5";
import Button from "components/button";
import usersPlus from "assets/images/users-plus.svg";
import lockKeyholeSquare from "assets/images/lock-keyhole-square.svg";
import checkIcon from "assets/images/check-icon-new.svg";
import heartIcon from "assets/images/purple-heart.svg";
import { useForm } from "react-hook-form";
import { useUpdatePassword } from "services/auth-api";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FiEye, FiEyeOff } from "react-icons/fi";

const inputClasses = `w-full h-[42px] font-[400] border border-1 border-[#333741] rounded-[7px] outline-none py-[9px] px-[13px] relative text-[16px] text-[#000] placeholder:!text-[#000] transition-all`;

const UpdatePassword = ({ isOpen, onClose }) => {
  const updatePassword = useUpdatePassword();
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [isMinLength, setIsMinLength] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasLowercase, setHasLowercase] = useState(false);
  const [hasUppercase, setHasUppercase] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      newPassword: "",
      confirmPassword: "",
    },
  });

  const { newPassword } = watch();

  useEffect(() => {
    setIsMinLength(newPassword.length >= 8);
    setHasSpecialChar(/[^A-Za-z0-9]/.test(newPassword));
    setHasNumber(/[0-9]/.test(newPassword));
    setHasLowercase(/[a-z]/.test(newPassword));
    setHasUppercase(/[A-Z]/.test(newPassword));
  }, [newPassword]);

  const handleUpdatePassword = async (data) => {
    if (
      !hasSpecialChar ||
      !isMinLength ||
      !hasLowercase ||
      !hasUppercase ||
      !hasNumber
    ) {
      return;
    }

    if (data.confirmPassword !== data.newPassword) {
      if (data.confirmPassword.length === 0) {
        return toast.error("Enter confirm password");
      } else {
        return toast.error("Enter correct confirm password");
      }
    }

    try {
      const response = await updatePassword.mutateAsync({
        password: data.newPassword,
      });

      if (response.status === 200) {
        reset();
        toast.success("Pipeline Password Updated", {
          icon: () => <img src={heartIcon} alt="name" />,
        });
        onClose();
      }
    } catch (error) {
      console.log(error);
      onClose();
    }
  };
  useEffect(() => {
    if (isOpen) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [isOpen]);

  if (!isOpen && !isModalVisible) return null;

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[300ms] ease-in-out ${
        isModalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className="fixed inset-0 bg-black opacity-10"
        onClick={onClose}
      ></div>
      <div
        className={`bg-white p-[24px] rounded-lg modal-shadow z-10 w-full max-w-[400px] relative transition-all ease-in-out duration-[150ms] ${
          isModalVisible ? "scale-100" : "scale-95"
        }`}
      >
        <IoClose
          className="text-[#667085] text-[20px] w-[24px] h-[24px] absolute right-[24px] top-[26px] cursor-pointer"
          onClick={() => {
            reset();
            onClose();
          }}
        />
        <form
          onSubmit={handleSubmit(handleUpdatePassword)}
          className="modal-scroll"
        >
          <div className="flex flex-col items-start justify-start mb-[20px] font-inter">
            <div className="flex w-[48px] h-[48px] border border-[#E4E7EC] rounded-lg items-center justify-center mb-[16px]">
              <img
                src={usersPlus}
                alt="info img"
                className="h-[24px] w-[24px]"
              />
            </div>
            <h4 className="font-[600] text-[18px] text-[#101828] m-[4px] ml-0 font-inter">
              Update Pipeline Password
            </h4>
            <p className="font-[500] text-[14px] text-[#475467] font-inter mt-4 mb-1.5">
              New Password
            </p>
            <div className="flex flex-col items-start w-full relative">
              <img
                src={lockKeyholeSquare}
                alt="info img"
                className="h-[20px] w-[20px] absolute top-[12px] left-[14px] z-10"
              />
              <input
                type={passwordVisible ? "text" : "password"}
                {...register("newPassword", {
                  required: "Password is required",
                  validate: {
                    minLength: (value) => value.length >= 8 || "",
                    specialChar: (value) => /[^A-Za-z0-9]/.test(value) || "",
                  },
                })}
                className={`${inputClasses} !border-[#D0D5DD] px-[40px] h-[44px] placeholder:!text-[#949494] !text-[16px]`}
                onDrop={(e) => e.preventDefault()}
                onDragOver={(e) => e.preventDefault()}
                // onPaste={(e) => e.preventDefault()}
              />
              <div onClick={() => setPasswordVisible(!passwordVisible)}>
                {passwordVisible ? (
                  <FiEye
                    className="absolute right-[15px] z-50 top-[14px] cursor-pointer"
                    color="#667085"
                  />
                ) : (
                  <FiEyeOff
                    className="absolute right-[15px] z-50 top-[14px] cursor-pointer"
                    color="#667085"
                  />
                )}
              </div>
              <div>
                {errors.newPassword && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.newPassword.message}
                  </p>
                )}
              </div>
            </div>
            <div className="flex items-center mt-1.5">
              <div>
                {isMinLength ? (
                  <img
                    src={checkIcon}
                    alt="check"
                    className="w-[14px] h-[14px] mr-[6px]"
                  />
                ) : (
                  <div className="h-[14px] w-[14px] rounded-full border border-[#7F56D9] mr-[6px]" />
                )}
              </div>
              <p
                className={`font-[400] text-[10px] leading-[14px] ${
                  isMinLength || newPassword.length === 0
                    ? "text-[#736F6F]"
                    : "text-red-500"
                }`}
              >
                Must be at least 8 characters
              </p>
            </div>
            <div className="flex items-center mt-2">
              <div>
                {hasSpecialChar ? (
                  <img
                    src={checkIcon}
                    alt="check"
                    className="w-[14px] h-[14px] mr-[6px]"
                  />
                ) : (
                  <div className="h-[14px] w-[14px] rounded-full border border-[#7F56D9] mr-[6px]" />
                )}
              </div>
              <p
                className={`font-[400] text-[10px] leading-[14px] ${
                  hasSpecialChar || newPassword.length === 0
                    ? "text-[#736F6F]"
                    : "text-red-500"
                }`}
              >
                Must contain one special character
              </p>
            </div>
            <div className="flex items-center mt-1.5">
              <div>
                {hasLowercase ? (
                  <img
                    src={checkIcon}
                    alt="check"
                    className="w-[14px] h-[14px] mr-[6px]"
                  />
                ) : (
                  <div className="h-[14px] w-[14px] rounded-full border border-[#7F56D9] mr-[6px]" />
                )}
              </div>
              <p
                className={`font-[400] text-[10px] leading-[14px] ${
                  hasLowercase || newPassword.length === 0
                    ? "text-[#736F6F]"
                    : "text-red-500"
                }`}
              >
                Must contain one lowercase character
              </p>
            </div>
            <div className="flex items-center mt-1.5">
              <div>
                {hasUppercase ? (
                  <img
                    src={checkIcon}
                    alt="check"
                    className="w-[14px] h-[14px] mr-[6px]"
                  />
                ) : (
                  <div className="h-[14px] w-[14px] rounded-full border border-[#7F56D9] mr-[6px]" />
                )}
              </div>
              <p
                className={`font-[400] text-[10px] leading-[14px] ${
                  hasUppercase || newPassword.length === 0
                    ? "text-[#736F6F]"
                    : "text-red-500"
                }`}
              >
                Must contain one uppercase character
              </p>
            </div>
            <div className="flex items-center mt-1.5">
              <div>
                {hasNumber ? (
                  <img
                    src={checkIcon}
                    alt="check"
                    className="w-[14px] h-[14px] mr-[6px]"
                  />
                ) : (
                  <div className="h-[14px] w-[14px] rounded-full border border-[#7F56D9] mr-[6px]" />
                )}
              </div>
              <p
                className={`font-[400] text-[10px] leading-[14px] ${
                  hasNumber || newPassword.length === 0
                    ? "text-[#736F6F]"
                    : "text-red-500"
                }`}
              >
                Must contain one number
              </p>
            </div>
            <p className="font-[500] text-[14px] text-[#475467] font-inter mt-4 mb-1.5">
              Confirm New Password
            </p>
            <div className="flex flex-col items-start w-full relative">
              <img
                src={lockKeyholeSquare}
                alt="info img"
                className="h-[20px] w-[20px] absolute top-[12px] left-[14px] z-10"
              />
              <input
                type={confirmPasswordVisible ? "text" : "password"}
                {...register("confirmPassword", {
                  validate: (value) =>
                    value === newPassword || "Passwords do not match",
                })}
                onChange={() => clearErrors("confirmPassword")}
                className={`${inputClasses} !border-[#D0D5DD] px-[40px] h-[44px] placeholder:!text-[#949494] !text-[16px]`}
                onDrop={(e) => e.preventDefault()}
                onDragOver={(e) => e.preventDefault()}
                // onPaste={(e) => e.preventDefault()}
              />
              <div
                onClick={() =>
                  setConfirmPasswordVisible(!confirmPasswordVisible)
                }
              >
                {confirmPasswordVisible ? (
                  <FiEye
                    className="absolute right-[15px] z-50 top-[14px] cursor-pointer"
                    color="#667085"
                  />
                ) : (
                  <FiEyeOff
                    className="absolute right-[15px] z-50 top-[14px] cursor-pointer"
                    color="#667085"
                  />
                )}
              </div>
              <div>
                {errors.confirmPassword && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.confirmPassword.message}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center mt-[15px]">
            <Button
              title="Cancel"
              isWhiteBtn={true}
              onClick={onClose}
              className="!text-[16px] !h-[44px] !font-[600] !text-[#344054] y-axis-shadow !rounded-[8px]"
            />
            <Button
              type="submit"
              title={
                <div className="flex items-center justify-center">
                  {updatePassword.isPending ? (
                    <div className="w-[20px] h-[20px] border-[3px] border-t-[3px] border-[#fff] border-solid rounded-full animate-spin border-t-[#8A43FF]" />
                  ) : (
                    "Update"
                  )}
                </div>
              }
              secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9] ml-[12px]"
              className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow !rounded-[8px]"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdatePassword;
