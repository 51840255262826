import BreadcrumbHeader from "components/breadcrumbHeader";
import Layout from "components/layout";
import { MdOutlineAdd } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Spark from "assets/images/spark.svg";
import Bin from "assets/images/bin.svg";
import { useEffect, useRef, useState } from "react";
import FacePrimary from "assets/images/face-primary.svg";
import SettingsPrimary from "assets/images/settings-primary.svg";
import DeleteLinkedInAccountModal from "components/accountsComponents/Modals/deleteModal";
import GreyLeads from "assets/images/grey-leads.svg";
import ConnectLinkedInIcon from "assets/images/connect-linkedin.svg";
import ConnectLinkedInModal from "components/accountsComponents/Modals/connectLinkedInModal";
import ConnectLoadingModal from "components/accountsComponents/Modals/connectLoadingModal";
import AccountOtpModal from "components/accountsComponents/Modals/accountOtpModal";
import AccountUpgradeModal from "components/accountsComponents/Modals/AccountUpgradeModal";
import UpgradeAccountSuccessfully from "components/accountsComponents/Modals/upgradeAccountSuccessfully";
import { toast } from "react-toastify";
import Tick from "assets/images/name-check.svg";
import LetterEmoji from "assets/images/loveletter.png";
import { useAccountStore, useAuthStore, useSettingStore } from "stores";
import {
  useGetLinkedinAccount,
  useRemoveLinkedinAccount,
  useUpdateAccountLimits,
} from "services/accounts-api";
import useVisitorModalStore from "stores/visitorModalStore";
import {
  useCreateLinkedinV2Account,
  useLinkedinAuthentication,
  useLinkedinCallback,
  useLinkedinChallengerCode,
  useLinkedInChallengerPhone,
  useLinkedInPhoneCode,
  useReconnectLinkedinAccount,
  useResendLinkedinVerificationCode,
  useVerifyLinkedin,
} from "services/auth-api";
import { getNameInititals } from "utils/";
import { countryCodeOptions } from "constant";
import OutlinedInput from "components/outlinedInput";
import ReconnectBanner from "components/reconnectBanner";
import Button from "components/button";
import ReconnectCaptchaModal from "components/accountsComponents/Modals/ReconnectCaptchaModal";
import Reconnect2FAModal from "components/accountsComponents/Modals/reconnect2FAModal";
import ReconnectOtpModal from "components/accountsComponents/Modals/reconnectOtpModal";
import ReConnectInAppValidation from "components/accountsComponents/Modals/reconnectInAppValidationModal";
import ReconnectQRModal from "components/accountsComponents/Modals/reconnectQrCodeModal";

const Accounts = () => {
  const createLinkedinAccountMutation = useCreateLinkedinV2Account();
  const reconnectLinkedinAccountMutation = useReconnectLinkedinAccount();
  const verifyLinkedinPhone = useLinkedInChallengerPhone();
  const linkedinChallengerCodeMutation = useLinkedinChallengerCode();
  const linkedinAuthenticationMutation = useLinkedinAuthentication();
  const getLinkedInAccountDetails = useGetLinkedinAccount();
  const linkedinPhoneCode = useLinkedInPhoneCode();
  const verifyLinkedin = useVerifyLinkedin();
  const updateAccountLimitAPI = useUpdateAccountLimits();
  const removeLinkedInAccount = useRemoveLinkedinAccount();
  const resendLinkedinCode = useResendLinkedinVerificationCode();
  const linkedInCallback = useLinkedinCallback();

  const navigate = useNavigate();
  const intervalRef = useRef(null);
  const { isConnectLinkedIn } = useVisitorModalStore();
  const { userData } = useSettingStore();
  const { isAdmin } = useAuthStore();
  const { linkedinAccountDetails, setLinkedinAccount } = useAccountStore();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [openConnectModalSteps, setOpenConnectModalSteps] = useState(1);
  const [timerId, setTimerId] = useState(null);
  const [isConnectedFlow, setIsConnectedFlow] = useState(false);
  const [isReconnectedFlow, setIsReconnectedFlow] = useState(false);
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [likedInData, setLikedInData] = useState();
  const [isPhoneCard, setIsPhoneCard] = useState(false);
  const [linkedInStatus, setLinkedInStatus] = useState();
  const [OTP, setOTP] = useState("");
  const [inputData, setInputData] = useState();
  const [isProfileViewsManuallyUpdated, setIsProfileViewsManuallyUpdated] =
    useState(false);
  const [isLinkedinAccountConnected, setIsLinkedinAccountConnected] = useState(
    Object.values(linkedinAccountDetails || {})?.length > 0 ? true : false
  );
  const [selectedLocation, setSelectedLocation] = useState();
  const [isFocused, setIsFocused] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState(
    countryCodeOptions[0]
  );
  const [phoneNumber, setPhoneNumber] = useState("");
  const [recaptchaData, setRecaptchaData] = useState(null);
  const [accountId, setAccountId] = useState();

  const handleGetLinkedInAccountDetails = async () => {
    try {
      const response = await getLinkedInAccountDetails.mutateAsync();
      setLinkedinAccount(response);
    } catch (error) {
      setLinkedinAccount({});
      console.log(error);
    }
  };

  const onSetDataInputData = () => {
    setInputData([
      {
        key: "profile_view",
        label: "Profile Views",
        value: linkedinAccountDetails?.limits?.profile_view?.sending_limit,
        remaining_limit:
          linkedinAccountDetails?.limits?.profile_view?.remaining_limit,
      },
      {
        key: "send_connection",
        label: "Connection Requests",
        value: linkedinAccountDetails?.limits?.send_connection?.sending_limit,
        remaining_limit:
          linkedinAccountDetails?.limits?.send_connection?.remaining_limit,
      },
      {
        key: "followup_message",
        label: "Follow-ups",
        value: linkedinAccountDetails?.limits?.followup_message?.sending_limit,
        remaining_limit:
          linkedinAccountDetails?.limits?.followup_message?.remaining_limit,
      },
      {
        key: "like_posts",
        label: "Liking Posts",
        value: linkedinAccountDetails?.limits?.like_posts?.sending_limit,
        remaining_limit:
          linkedinAccountDetails?.limits?.like_posts?.remaining_limit,
      },
      {
        key: "like_comments",
        label: "Liking Comments",
        value: linkedinAccountDetails?.limits?.like_comments?.sending_limit,
        remaining_limit:
          linkedinAccountDetails?.limits?.like_comments?.remaining_limit,
      },
    ]);
  };

  useEffect(() => {
    onSetDataInputData();
  }, [linkedinAccountDetails]);

  useEffect(() => {
    handleGetLinkedInAccountDetails();
  }, []);

  const handleResendCode = async (resendType) => {
    try {
      const response = await resendLinkedinCode?.mutateAsync({
        session_id: likedInData?.session_id,
        account_id: likedInData?.account_id,
        resend_type: resendType,
      });
      toast.success("Code Re-sent", {
        icon: () => (
          <img src={LetterEmoji} alt="Resent" className="!w-[46px] !h-[16px]" />
        ),
      });
    } catch (error) {
      toast.success(error?.data?.detail);
    }
  };

  const handleInputChange = (index, newValue) => {
    if (/^\d*$/.test(newValue) && Number(newValue) <= 40) {
      const updatedData = [...inputData];
      updatedData[index].value = newValue;

      if (updatedData?.[index]?.label === "Profile Views") {
        setIsProfileViewsManuallyUpdated(true);
      }
      // if (updatedData?.[index]?.label === "Connection Requests") {
      //   const profileViewsIndex = updatedData?.findIndex(
      //     (item) => item.label === "Profile Views"
      //   );
      //   if (profileViewsIndex !== -1) {
      //     updatedData[profileViewsIndex].value = newValue;
      //   }
      // }

      setInputData(updatedData);
    }
  };

  const handleCallback = async (OTP) => {
    try {
      const response = await linkedInCallback.mutateAsync({
        account_id: String(accountId),
        code: OTP,
      });

      if (response?.status === 202) {
        if (response?.checkpoint?.type === "OTP") {
          setLikedInData(response);
          setLoader(false);
          setOpenConnectModalSteps(1);
          setIsPhoneCard(false);
        } else if (response?.checkpoint?.type === "2FA") {
          setSelectedLocation();
          setLoader(false);
          setOpenConnectModalSteps(8);
          return;
        }
      }

      if (response?.status === 201) {
        if (response?.message === "AccountCreated") {
          startVerificationInterval(String(accountId));
        }
      } else if (response?.status === 400) {
        setLoader(false);
        toast.error(response?.message);
      }
    } catch (error) {
      setLoader(false);
      setOpenConnectModalSteps(0);
      console.log(error, "err");
    }
  };

  const handleSave = async () => {
    // Populate the data object with values from inputData
    const data = inputData?.reduce(
      (acc, item) => {
        switch (item.key) {
          case "profile_view":
            acc.profile_view_limit = Number(item.value);
            break;
          case "send_connection":
            acc.connect_limit = Number(item.value);
            break;
          case "followup_message":
            acc.followup_limit = Number(item.value);
            break;
          case "like_posts":
            acc.like_post_limit = Number(item.value);
            break;
          case "like_comments":
            acc.like_comment_limit = Number(item.value);
            break;
          default:
            break;
        }
        return acc;
      },
      {
        profile_view_limit: 0,
        connect_limit: 0,
        followup_limit: 0,
        like_post_limit: 0,
        like_comment_limit: 0,
      }
    );
    const limits = {
      profile_view: data?.profile_view_limit,
      send_connection: data?.connect_limit,
      followup_message: data?.followup_limit,
      like_posts: data?.like_post_limit,
      like_comments: data?.like_comment_limit,
    };
    try {
      const response = await updateAccountLimitAPI?.mutateAsync({
        account_id: linkedinAccountDetails?.account_id,
        ...data,
      });
      setLinkedinAccount({
        ...linkedinAccountDetails,
        limits,
      });
      toast.success("Account Limit Updated", {
        icon: () => <img src={Tick} alt="name" />,
      });
      handleGetLinkedInAccountDetails();
    } catch (error) {
      // Handle errors appropriately
      toast.error("Failed to update account limit");
    }
  };

  const handleRemoveLinkedinAccount = async () => {
    try {
      const response = await removeLinkedInAccount?.mutateAsync();
      setIsDeleteModalOpen(false);
      setLinkedinAccount({});
      setIsLinkedinAccountConnected(false);
    } catch (error) {
      setIsDeleteModalOpen(false);
      toast.success("Something went wrong");
    }
  };

  const closeModal = () => {
    setSelectedLocation();
    setOTP("");
    setEmail("");
    setPassword("");
    setPhoneNumber("");
    setOpenConnectModalSteps(0);
    setIsPhoneCard(false);
  };

  const handleVerifyError = (error) => {
    if (error?.data && error?.data?.detail) {
      toast.error(error?.data?.detail);
    } else {
      toast.error("An error occurred during LinkedIn verification");
    }
  };

  const onConnect = async () => {
    if (!!!selectedLocation) {
      return toast.error("Please select country");
    }
    try {
      let response;
      if (isReconnectedFlow) {
        response = await reconnectLinkedinAccountMutation.mutateAsync({
          linkedin_username: email,
          linkedin_password: password,
          proxy_location: selectedLocation?.value,
        });
      } else {
        response = await createLinkedinAccountMutation.mutateAsync({
          linkedin_username: email,
          linkedin_password: password,
          proxy_location: selectedLocation?.value,
        });
      }

      if (response?.status === 400) {
        toast.success(response?.message || "An Unknown error occured");
        closeModal();
        setLoader(false);
        return;
      }
      if (response?.status === 202) {
        setAccountId(response?.account_id);
        if (response?.checkpoint?.type === "CAPTCHA") {
          setAccountId(response?.account_id);
          closeModal();
          setLoader(false);
          setRecaptchaData({
            data: response?.checkpoint?.data,
            public_key: response?.checkpoint?.public_key,
          });
          setOpenConnectModalSteps(7);
          return;
        } else if (response?.checkpoint?.type === "IN_APP_VALIDATION") {
          setAccountId(response?.account_id);
          setSelectedLocation();
          setLoader(false);
          setOpenConnectModalSteps(9);
          return;
        } else if (response?.checkpoint?.type === "2FA") {
          setSelectedLocation();
          setLoader(false);
          setOpenConnectModalSteps(8);
          return;
        } else if (response?.checkpoint?.type === "OTP") {
          setSelectedLocation();
          setLoader(false);
          setOpenConnectModalSteps(6);
          return;
        } else if (response?.checkpoint?.type === "PHONE_REGISTER") {
          setSelectedLocation();
          setLoader(false);
          setIsPhoneCard(true);
          setOpenConnectModalSteps(2);
          return;
        }
      }

      if (response.status === 200) {
        closeModal();
        setLoader(true);
        setTimeout(async () => {
          try {
            const verify = await verifyLinkedin.mutateAsync({
              account_id: response?.account_id,
            });
            if (verify?.login_process === "completed") {
              toast.error("Login Success");
              setLoader(false);
              handleGetLinkedInAccountDetails();
              return;
            } else if (verify?.login_process === "inprogress") {
              intervalRef.current = setInterval(async () => {
                try {
                  const verify = await verifyLinkedin.mutateAsync({
                    account_id: response?.account_id,
                  });
                  setLinkedInStatus(verify);
                  if (verify?.login_process === "completed") {
                    clearInterval(intervalRef.current);
                    setLikedInData(verify);
                    if (verify?.login_failed) {
                      setLoader(false);
                      setSelectedLocation();
                      toast.error("Login failed");
                      return;
                    } else if (verify?.invalid_credentials) {
                      setLoader(false);
                      setSelectedLocation();
                      toast.error(
                        "Wrong username/password combo. Please try again!"
                      );
                      return;
                    }
                    if (verify?.account_connected) {
                      setOpenConnectModalSteps(3);
                    } else if (verify?.phone_challenge_requested) {
                      setIsPhoneCard(true);
                      setOpenConnectModalSteps(3);
                    } else {
                      setOpenConnectModalSteps(2);
                    }
                    setLoader(false);
                    setSelectedLocation();
                  }  else if (verify?.login_failed) {
                    toast.error("Login failed");
                    clearInterval(intervalRef.current);
                    setLoader(false);
                    setSelectedLocation();
                    return;
                  }
                } catch (verifyError) {
                  clearInterval(intervalRef.current);
                  setLoader(false);
                  setSelectedLocation();
                  handleVerifyError(verifyError);
                }
              }, 30000);
            } else {
              toast.error("Login failed");
              setLoader(false);
              setSelectedLocation();
              return;
            }
          } catch (initialVerifyError) {
            handleGetLinkedInAccountDetails();
            setLoader(false);
            handleVerifyError(initialVerifyError);
          }
        }, 10000);
      }
    } catch (error) {
      // setSelectedLocation();
      setLoader(false);
      if (error?.data && error?.data?.detail) {
        toast.error(error?.data?.detail);
      } else {
        toast.error("An unknown error occurred");
      }
    }
  };

  const handleVerification = async (accountId) => {
    setLoader(true);
    try {
      const verify = await verifyLinkedin.mutateAsync({
        account_id: accountId,
      });
      if (verify?.account_connected) {
        clearInterval(intervalRef.current);
        setOTP("");
        verify?.is_premium
          ? setOpenConnectModalSteps(4)
          : setOpenConnectModalSteps(3);
        setLoader(false);
      } else if (verify?.login_failed) {
        clearInterval(intervalRef.current);
        return setLoader(false);
      }
    } catch (error) {
      clearInterval(intervalRef.current);
      setLoader(false);
      handleVerifyError(error);
    }
  };

  const startVerificationInterval = (accountId) => {
    intervalRef.current = setInterval(async () => {
      await handleVerification(accountId);
    }, 1000);
  };

  const handleVerificationSubmit = async () => {
    closeModal();
    setLoader(true);
    try {
      let response;

      if (likedInData?.challenge_requested) {
        response = await linkedinChallengerCodeMutation.mutateAsync({
          session_id: likedInData?.session_id?.toString(),
          account_id: likedInData?.account_id?.toString(),
          code: OTP,
        });
      } else if (likedInData?.two_factor_auth) {
        response = await linkedinAuthenticationMutation.mutateAsync({
          session_id: likedInData?.session_id?.toString(),
          account_id: likedInData?.account_id?.toString(),
          code: OTP,
        });
      } else if (likedInData?.phone_code_required) {
        response = await linkedinPhoneCode.mutateAsync({
          linkedin_username: "",
          account_name: "",
          session_id: likedInData?.session_id,
          account_id: likedInData?.account_id,
          code: OTP,
        });
      } else if (likedInData?.checkpoint?.type === "OTP") {
        handleCallback(OTP);
      } else if (likedInData?.checkpoint?.type === "2FA") {
        handleCallback(OTP);
      }

      if (response?.status === 200) {
        startVerificationInterval(response?.account_id);
      }
    } catch (error) {
      setLoader(false);
      handleVerifyError(error);
    }
  };

  const handlePhoneNumberChange = (e) => {
    // Allow the '+' symbol at the beginning, followed by digits
    const input = e.target.value.replace(/[^\d+]/g, "");

    // Ensure '+' is only at the start and limit total length to 15 characters
    const formattedInput = input.startsWith("+")
      ? "+" + input.slice(1).replace(/\+/g, "")
      : input;

    if (formattedInput.length <= 15) {
      setPhoneNumber(formattedInput);
    }
  };
  const handlePhoneVerify = async () => {
    handleCallback(`(+${selectedCountryCode.value})${phoneNumber}`);
  };
  const renderModal = () => {
    // openConnectModalSteps = 8
    switch (openConnectModalSteps) {
      case 1:
        return (
          <ConnectLinkedInModal
            isOpen={openConnectModalSteps || isConnectLinkedIn}
            onClose={closeModal}
            setOpenConnectModalSteps={setOpenConnectModalSteps}
            setLoader={setLoader}
            isReconnectedFlow={isReconnectedFlow}
            setTimerId={setTimerId}
            setEmail={setEmail}
            email={email}
            password={password}
            setPassword={setPassword}
            onConnect={onConnect}
            setSelectedLocation={setSelectedLocation}
            isPending={
              createLinkedinAccountMutation?.isPending ||
              reconnectLinkedinAccountMutation?.isPending
            }
          />
        );
      case 2:
        return (
          <AccountOtpModal
            isPhoneCard={isPhoneCard}
            closeModal={closeModal}
            countryCodeOptions={countryCodeOptions}
            selectedCountryCode={selectedCountryCode}
            setSelectedCountryCode={setSelectedCountryCode}
            phoneNumber={phoneNumber}
            handlePhoneNumberChange={handlePhoneNumberChange}
            isFocused={isFocused}
            setIsFocused={setIsFocused}
            handlePhoneVerify={handlePhoneVerify}
            OTP={OTP}
            setOTP={setOTP}
            handleVerificationSubmit={handleVerificationSubmit}
            handleResendCode={handleResendCode}
            loading={linkedInCallback?.isPending}
          />
        );
      case 3:
        return (
          <AccountUpgradeModal
            isOpen={openConnectModalSteps}
            onClose={closeModal}
            setOpenConnectModalSteps={setOpenConnectModalSteps}
            setLoader={setLoader}
            setTimerId={setTimerId}
            intervalRef={intervalRef}
          />
        );
      case 4:
        return (
          <UpgradeAccountSuccessfully
            isOpen={openConnectModalSteps}
            onClose={closeModal}
            setOpenConnectModalSteps={setOpenConnectModalSteps}
            setIsLinkedinAccountConnected={setIsLinkedinAccountConnected}
            setTimerId={setTimerId}
            handleGetLinkedInAccountDetails={handleGetLinkedInAccountDetails}
          />
        );
      case 6:
        return (
          <ReconnectOtpModal
            isOpen={openConnectModalSteps}
            onClose={closeModal}
            setOpenConnectModalSteps={setOpenConnectModalSteps}
            setIsLinkedinAccountConnected={setIsLinkedinAccountConnected}
            setTimerId={setTimerId}
            handleGetLinkedInAccountDetails={handleGetLinkedInAccountDetails}
            handleResendCode={handleResendCode}
            accountId={accountId}
            startVerificationInterval={startVerificationInterval}
            setLikedInData={setLikedInData}
            setLoader={setLoader}
            OTP={OTP}
            setOTP={setOTP}
            handleVerificationSubmit={handleVerificationSubmit}
          />
        );
      case 7:
        return (
          <ReconnectCaptchaModal
            isOpen={openConnectModalSteps}
            onClose={closeModal}
            setOpenConnectModalSteps={setOpenConnectModalSteps}
            setIsLinkedinAccountConnected={setIsLinkedinAccountConnected}
            setTimerId={setTimerId}
            handleGetLinkedInAccountDetails={handleGetLinkedInAccountDetails}
            data={recaptchaData}
            accountId={accountId}
            startVerificationInterval={startVerificationInterval}
            setLoader={setLoader}
            setIsPhoneCard={setIsPhoneCard}
            setLikedInData={setLikedInData}
          />
        );
      case 8:
        return (
          <Reconnect2FAModal
            isOpen={openConnectModalSteps}
            onClose={closeModal}
            setOpenConnectModalSteps={setOpenConnectModalSteps}
            setIsLinkedinAccountConnected={setIsLinkedinAccountConnected}
            setTimerId={setTimerId}
            handleGetLinkedInAccountDetails={handleGetLinkedInAccountDetails}
            handleResendCode={handleResendCode}
            accountId={accountId}
            startVerificationInterval={startVerificationInterval}
            setLikedInData={setLikedInData}
            setLoader={setLoader}
            OTP={OTP}
            setOTP={setOTP}
            handleVerificationSubmit={handleVerificationSubmit}
          />
        );
      case 9:
        return (
          <ReConnectInAppValidation
            isOpen={openConnectModalSteps}
            onClose={closeModal}
            accountId={accountId}
            startVerificationInterval={startVerificationInterval}
            setLoader={setLoader}
          />
        );
      case 10:
        return (
          <ReconnectQRModal
            isOpen={openConnectModalSteps}
            onClose={closeModal}
            setOpenConnectModalSteps={setOpenConnectModalSteps}
            setIsLinkedinAccountConnected={setIsLinkedinAccountConnected}
            setTimerId={setTimerId}
            handleGetLinkedInAccountDetails={handleGetLinkedInAccountDetails}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Layout>
      <div className="h-[100vh] flex flex-col justify-start items-start font-inter">
        <BreadcrumbHeader
          breadcrumbItems={[{ title: "Accounts", route: "/accounts" }]}
          showCredits
        />
        <ReconnectBanner />
        <div
          className="max-w-[100%] w-full px-[31px] py-[18px] h-[calc(100vh_-45px)] overflow-auto innerScrollBar"
          data-aos="fade-left"
        >
          <div className="flex items-center justify-between w-full mb-1">
            <h1 className="text-[32px] text-left text-[#252C32] font-semibold leading-normal mb-[8px]">
              Accounts
            </h1>
          </div>
          <div className="flex mt-[35px]">
            <div
              className="mr-[16px] flex justify-center items-center border-[#E4E7EC] border w-[48px] h-[48px] rounded-[10px]"
              style={{
                boxShadow:
                  "0px 4px 8px 0px rgba(16, 24, 40, 0.05), 0px 2px 0px 0px rgba(16, 24, 40, 0.05), 0px 0px 1px 0px rgba(16, 24, 40, 0.18)",
              }}
            >
              <img src={FacePrimary} alt="" />
            </div>
            <div>
              <p className="text-[#101828] text-[18px] font-[600]">
                LinkedIn Account
              </p>
              <p className="text-[#475467] text-[14px] font-[400]">
                Your account that is connected to Pipeline.
              </p>
            </div>
          </div>

          {linkedinAccountDetails?.account_id ? (
            <div className="mt-[30px] border border-[#E4E7EC] h-[78px] w-[100%] rounded-[12px] flex justify-between items-center px-[23px] py-[20px]">
              <div className="flex items-center">
                {userData?.user?.profile_pic ||
                linkedinAccountDetails?.profile_pic ||
                isAdmin ? (
                  <img
                    src={
                      linkedinAccountDetails?.profile_pic ||
                      userData?.user?.profile_pic
                    }
                    alt=""
                    className="mr-[12px] w-[40px] min-w-[40px] h-[40px] rounded-full"
                  />
                ) : (
                  <div className="h-[34px] w-[34px] min-w-[34px] rounded-full flex items-center justify-center text-[14px] font-[700] text-[#4409B9] bg-[#EEEDFD] mr-[12px]">
                    {getNameInititals(userData?.user?.name)}
                  </div>
                )}
                <p className="text-[#101828] font-[500] text-[14px]">
                  {linkedinAccountDetails?.account_name || "Test Account"}
                </p>
              </div>
              <div className="flex">
                <Button
                  style={{
                    boxShadow:
                      "0px 1px 2px 0px #1018280D, 0px -2px 0px 0px #1018280D inset, 0px 0px 0px 1px #1018282E inset;",
                  }}
                  isFullWidth={false}
                  gradientBg={true}
                  height="40px"
                  className="!rounded-[8px] !mr-[17px] !w-[136px] !h-[40px]"
                  title={
                    <div className="flex items-center justify-center font-inter !text-[14px] !font-[600]">
                      <img src={Spark} alt="Spark" className="mr-[11px]" />
                      Re-connect
                    </div>
                  }
                  onClick={() => {
                    setIsReconnectedFlow(true);
                    setOpenConnectModalSteps(1);
                  }}
                />
                <Button
                  isFullWidth={false}
                  isWhiteBtn={true}
                  onClick={() => setIsDeleteModalOpen(true)}
                  className="!max-w-[176px] !h-[40px] bg-[#FFFFFF] py-[10px] px-[16px] text-[#344054] font-[600] text-[14px] rounded-[8px] flex items-center border border-[#D0D5DD]"
                  title={
                    <div className="flex items-center justify-center font-inter !text-[14px] !font-[600]">
                      <img src={Bin} alt="Bin" className="mr-[11px]" />
                      <p>Remove Account</p>
                    </div>
                  }
                ></Button>
              </div>
            </div>
          ) : (
            <div className="mt-[33px] ml-[62px] mb-[39px]">
              <Button
                style={{
                  boxShadow:
                    "0px 1px 2px 0px #1018280D, 0px -2px 0px 0px #1018280D inset, 0px 0px 0px 1px #1018282E inset;",
                }}
                isFullWidth={false}
                gradientBg={true}
                height="40px"
                className="!rounded-[8px] !mr-[17px] !w-[185px] !h-[40px] pb-[1px]"
                title={
                  <div className="flex items-center justify-center font-inter !text-[14px] !font-[600]">
                    <img
                      src={ConnectLinkedInIcon}
                      alt="Spark"
                      className="mr-[11px]"
                    />{" "}
                    Connect Account
                  </div>
                }
                onClick={() => {
                  setIsConnectedFlow(true);
                  setOpenConnectModalSteps(1);
                  setIsReconnectedFlow(false);
                }}
              />
            </div>
          )}
          <div className="flex mt-[40px]">
            <div
              className="mr-[16px] flex justify-center items-center border-[#E4E7EC] border w-[48px] h-[48px] rounded-[10px]"
              style={{
                boxShadow:
                  "0px 4px 8px 0px rgba(16, 24, 40, 0.05), 0px 2px 0px 0px rgba(16, 24, 40, 0.05), 0px 0px 1px 0px rgba(16, 24, 40, 0.18)",
              }}
            >
              <img
                src={
                  linkedinAccountDetails?.account_id
                    ? SettingsPrimary
                    : GreyLeads
                }
                alt=""
              />
            </div>
            <div>
              <p
                className={`${
                  linkedinAccountDetails?.account_id
                    ? "text-[#101828]"
                    : "text-[#B8B8B8]"
                } text-[18px] font-[600]`}
              >
                Account Limits
              </p>
              <p
                className={`${
                  linkedinAccountDetails?.account_id
                    ? "text-[#475467]"
                    : "text-[#B8B8B8]"
                } text-[14px] font-[400]`}
              >
                Control your daily limits and preferences for your LinkedIn
                account.
              </p>
            </div>
          </div>
          <div className="mt-[30px] ml-[80px]">
            {inputData?.map((item, index) => (
              <div key={index} className="flex items-center mb-[16px]">
                <label
                  className={`${
                    isLinkedinAccountConnected
                      ? "text-[#344054]"
                      : "text-[#B8B8B8]"
                  } w-[160px] font-[500] mr-[32px] text-[14px] mb-6`}
                >
                  {item?.label}
                </label>
                <div>
                  <OutlinedInput
                    type="text"
                    disabled={
                      item.key === "profile_view" ||
                      !linkedinAccountDetails?.account_id
                    } // Disable Profile Views input
                    value={item?.value}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                    className={`!w-[448px] ${
                      isLinkedinAccountConnected
                        ? "text-[#292929]"
                        : "text-[#B8B8B8]"
                    }`}
                  />
                  <p
                    className={`text-[14px] mt-[5px] mb-[-2px] ${
                      isLinkedinAccountConnected
                        ? "text-[#292929]"
                        : "text-[#B8B8B8]"
                    }`}
                  >
                    Remaining limit: {item?.remaining_limit}
                  </p>
                </div>
              </div>
            ))}
          </div>
          {isLinkedinAccountConnected && (
            <div className="flex justify-end w-[720px] mt-[30px]">
              <Button
                onClick={handleSave}
                isFullWidth={false}
                gradientBg={true}
                className="bg-[#7F56D9] text-[#fff] px-[30px] py-[10px] font-[600] text-[13.3px] rounded-[8px]"
                title={"Save"}
              ></Button>
            </div>
          )}
        </div>
      </div>
      <DeleteLinkedInAccountModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        handleRemoveLinkedinAccount={handleRemoveLinkedinAccount}
        removeLinkedInAccount={removeLinkedInAccount}
      />
      {isConnectedFlow || isReconnectedFlow || isConnectLinkedIn ? (
        <>{renderModal()}</>
      ) : null}
      {loader && (
        <ConnectLoadingModal
          isOpen={true}
          onClose={() => setIsLoadingModal(false)}
          setLoader={setLoader}
          timerId={timerId}
          setOpenConnectModalSteps={setOpenConnectModalSteps}
          clearInterval={() => clearInterval(intervalRef.current)}
        />
      )}
    </Layout>
  );
};

export default Accounts;
