// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timeZone-select__control {
  min-height: 44px !important;
  border-color: #d0d5dd !important;
  border-radius: 8px !important;
  box-shadow: 0 1px 2px 0 #1018280D;
}
.timeZone-select__indicator-separator {
  display: none;
}
.timeZone-select__option {
  color: #475467 !important;
  font-size: 14px !important;
  padding: 4px 12px !important;
}
.timeZone-select__option--is-focused,
.timeZone-select__option--is-selected {
  background-color: #f9fafb !important;
}
.timeZone-select__single-value {
    font-weight: 500 !important;
    color: #101828 !important;
    margin-left: 26px !important;
    font-size: 16px !important;
}
.timeZone-select__placeholder {
  margin-left: 26px !important;
  color: #475467 !important;
}
.timeZone-select__control--is-focused,
.timeZone-select__control--menu-is-open {
  outline: none !important;
  box-shadow: unset !important;
}
.timeZone-select__indicator {
  color: #667085 !important;
}
.timeZone-select__input-container input {
  margin-left: 26px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/timeZoneSelect/style.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,gCAAgC;EAChC,6BAA6B;EAC7B,iCAAiC;AACnC;AACA;EACE,aAAa;AACf;AACA;EACE,yBAAyB;EACzB,0BAA0B;EAC1B,4BAA4B;AAC9B;AACA;;EAEE,oCAAoC;AACtC;AACA;IACI,2BAA2B;IAC3B,yBAAyB;IACzB,4BAA4B;IAC5B,0BAA0B;AAC9B;AACA;EACE,4BAA4B;EAC5B,yBAAyB;AAC3B;AACA;;EAEE,wBAAwB;EACxB,4BAA4B;AAC9B;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,4BAA4B;AAC9B","sourcesContent":[".timeZone-select__control {\n  min-height: 44px !important;\n  border-color: #d0d5dd !important;\n  border-radius: 8px !important;\n  box-shadow: 0 1px 2px 0 #1018280D;\n}\n.timeZone-select__indicator-separator {\n  display: none;\n}\n.timeZone-select__option {\n  color: #475467 !important;\n  font-size: 14px !important;\n  padding: 4px 12px !important;\n}\n.timeZone-select__option--is-focused,\n.timeZone-select__option--is-selected {\n  background-color: #f9fafb !important;\n}\n.timeZone-select__single-value {\n    font-weight: 500 !important;\n    color: #101828 !important;\n    margin-left: 26px !important;\n    font-size: 16px !important;\n}\n.timeZone-select__placeholder {\n  margin-left: 26px !important;\n  color: #475467 !important;\n}\n.timeZone-select__control--is-focused,\n.timeZone-select__control--menu-is-open {\n  outline: none !important;\n  box-shadow: unset !important;\n}\n.timeZone-select__indicator {\n  color: #667085 !important;\n}\n.timeZone-select__input-container input {\n  margin-left: 26px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
