import CommonCheckbox from "components/commonCheckbox";
import { TiMinus } from "react-icons/ti";

const Revenue = (props) => {
  const { formData, setFormData, handleNegativeNumbers, name, options } = props;
  const isValidRevenue =
    Number(formData?.["minRevenue"]) >= Number(formData?.["maxRevenue"]) &&
    formData?.["minRevenue"] &&
    formData?.["maxRevenue"];

  const onInputChange = (event) => {
    let { name, value } = event?.target;
    value = value.replace(/^0+(?=\d)/, "");
    setFormData({
      ...formData,
      [name]: value, 
    });
  };

  const handleCheckboxChange = (label) => {
    const existingLabels = formData?.[name] || [];
    const labelIndex = existingLabels?.indexOf(label);

    const updatedLabels =
      labelIndex > -1
        ? existingLabels?.filter((existingLabel) => existingLabel !== label)
        : [...existingLabels, label];

    setFormData({
      ...formData,
      [name]: updatedLabels,
    });
  };

  return (
    <>
      <p className="text-[#344054] font-[500] text-[14px] mb-[11px] font-inter">
        Enter Revenue Ranges
      </p>
      <div className="flex items-center mb-[10px]">
        <input
          type="number"
          placeholder="min"
          className="text-[14px] font-[400] pb-[4px] text-[#1E1E1ED4] placeholder:text-[#B2B4B8] border-[1px] border-[#D0D5DD] text-center py-[2px] px-[12px] w-[94px] outline-none focus:!border-[#D0D5DD] bg-transparent rounded-[8px] h-[34px] y-axis-shadow"
          onChange={onInputChange}
          name="minRevenue"
          value={formData?.["minRevenue"]}
          onKeyPress={handleNegativeNumbers}
          min={0}
        />
        <TiMinus className="text-[14px] text-[#8A43FF] mx-[6px]" />
        <input
          type="number"
          placeholder="max"
          className={`text-[14px] font-[400]  pb-[4px] text-[#1E1E1ED4] placeholder:text-[#B2B4B8] border-[1px] border-[#D0D5DD] text-center py-[2px] px-[12px] w-[94px] outline-none focus:!border-[#D0D5DD] bg-transparent rounded-[8px] h-[34px] y-axis-shadow ${
            isValidRevenue && "!border-[red]"
          }`}
          onChange={onInputChange}
          name="maxRevenue"
          value={formData?.["maxRevenue"]}
          onKeyPress={handleNegativeNumbers}
          min={0}
        />
      </div>
      {isValidRevenue && (
        <p className="text-[11px] mt-[8px] mb-[10px] text-[red]">
          Max value must be greater than min value.
        </p>
      )}
      {options?.map((item, index) => {
        return (
          <div key={index} className="flex items-center mb-[7px] px-1  hover:bg-[#f5ebff] rounded-[6px]  cursor-pointer">
            <div className="leads-checkbox w-[16px] h-[16px] mr-[10px] z-[999999] relative">
              <CommonCheckbox
                checked={formData?.revenue?.includes?.(item.label)}
                onChange={() => handleCheckboxChange(item.label)}
              />
            </div>
            <p className="text-[#344054] font-[500] text-[14px] mb-0 font-inter w-full" onClick={() => handleCheckboxChange(item.label)}>
              {item?.label}
            </p>
          </div>
        );
      })}
    </>
  );
};

export default Revenue;
