import Button from "components/button";
import { useEffect, useState } from "react";
import SwitchToggle from "components/switchToggle";
import { useWithdrawRequests } from "services/connections-api";
import { toast } from "react-toastify";
import { useGetLinkedinAccount } from "services/accounts-api";

const WithdrawModal = ({ isOpen, onClose }) => {
  const withdraw = useWithdrawRequests();
  const getLinkedInAccountDetails = useGetLinkedinAccount();

  const [linkedinAccount, setLinkedinAccount] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [withdrawValue, setWithdrawValue] = useState("");
  const [toggleState, setToggleState] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsModalVisible(true);
      handleGetLinkedInAccountDetails();
    } else {
      setIsModalVisible(false);
      setWithdrawValue("");
      setToggleState(false);
    }
  }, [isOpen]);

  const handleGetLinkedInAccountDetails = async () => {
    try {
      const response = await getLinkedInAccountDetails.mutateAsync();
      setLinkedinAccount(response);
    } catch (error) {
      setLinkedinAccount({});
    }
  };

  useEffect(() => {
    if (linkedinAccount) {
      setWithdrawValue(linkedinAccount.withdraw_request_days / 7 || "");
      setToggleState(linkedinAccount.withdraw_request || false);
    }
  }, [linkedinAccount]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    // Allow only numbers (and empty string, to clear the field)
    if (/^[0-9]*$/.test(value)) {
      setWithdrawValue(value);
      setToggleState(value !== "" && !/^0+$/.test(value)); // False if empty or only zeros
    }
  };

  const handleToggleChange = () => {
    setToggleState((prevState) => !prevState);
  };

  const onSubmit = async () => {
    try {
      const response = await withdraw.mutateAsync({
        withdraw_toggle: toggleState,
        weeks_old: Number(withdrawValue),
      });
      if (response?.status === 200) {
        toast.success(response?.message);
        setWithdrawValue("");
        onClose();
        setToggleState(false);
      }
    } catch (error) {
      toast.error(error?.data?.detail);
      onClose();
      setWithdrawValue("");
      setToggleState(false);
    }
  };

  if (!isOpen && !isModalVisible) return null;

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[300ms] ease-in-out ${
        isModalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className="fixed inset-0 bg-black opacity-10"
        onClick={onClose}
      ></div>
      <div
        className={`bg-white rounded-lg shadow-lg z-10 w-full max-w-[504px] relative transition-all ease-in-out duration-[150ms] ${
          isModalVisible ? "scale-100" : "scale-95"
        }`}
      >
        <div className="modal-scroll">
          <div className="p-[24px] flex items-center">
            <div className="">
              <div className="newcampaign-toggle withdrawl-toggle flex items-center">
                <SwitchToggle
                  checked={toggleState}
                  onChange={handleToggleChange} // Handle change of toggle
                />
                <h6 className="text-[#181D27] font-[600] text-[18px] leading-[28px] select-none ml-[14px]">
                  Withdraw Requests
                </h6>
              </div>
              <p className="font-[400] leading-[20px] text-[14px] mt-[7.58px] text-[#475467] select-none w-[90%]">
                Remove pending invites that have{" "}
                <span className="font-[700]">not</span> been accepted by leads
                you have reached out to in a campaign.
              </p>
            </div>
          </div>

          <div className="px-[24px] mt-[4px] flex flex-col justify-center items-center">
            <p className="text-[#344054] text-[14px] font-[500] ml-[-15px]">
              Withdraw Requests older than{" "}
              <sup className="text-[#7F56D9] text-[14px] font-[500] top-[0px]">
                *
              </sup>
            </p>
            <div className="flex items-center mt-[8px] mb-[8px]">
              <input
                type="text"
                placeholder="e.g 5"
                value={withdrawValue}
                onChange={handleInputChange} // Ensure the value stays numeric
                className="w-[143px] h-[44px] border-[#D0D5DD] rounded-[8px]"
              />
              <span className="text-[#667085] text-[16px] font-[400] ml-[12px]">
                weeks old
              </span>
            </div>
          </div>

          <div className="flex items-center justify-center p-[24px]">
            <Button
              title={
                withdraw?.isPending ? (
                  <div className="w-[20px] h-[20px] border-[3px] border-t-[3px] mx-auto border-[#fff] border-solid rounded-full animate-spin border-t-[#8A43FF]" />
                ) : (
                  "Save"
                )
              }
              secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9] select-none	"
              className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow !rounded-[8px]"
              onClick={onSubmit}
              disabled={withdrawValue === "" || /^0+$/.test(withdrawValue)}
            />
          </div>

          {getLinkedInAccountDetails?.isPending && (
            <div className="absolute top-0 flex items-center justify-center w-full h-full rounded-lg bg-white/90">
              <div className="w-[20px] h-[20px] border-[3px] border-t-[3px] mx-auto border-[#fff] border-solid rounded-full animate-spin border-t-[#8A43FF]" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WithdrawModal;
