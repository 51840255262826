const StepButton = ({
  title,
  className = "",
  index,
  button,
  onClick,
  selected,
}) => {
  return (
    <button
      onClick={() => (onClick(button))}
      style={{
        boxShadow: "0px 0.91px 1.82px 0px #1018280D",
      }}
      className={`w-full py-2 px-4 font-inter font-[600] bg-white border ${
        selected ? "border-[#8C45FF]" : "border-[#D0D5DD] "
      } rounded-[8px]
           text-[#344054] text-[14px] focus:outline-none
            
             hover:border-[#D0D5DD] hover:text-white hover:bg-[#7F56D9] transition duration-100 ease-in-out ${className}`}
    >
      {title}
    </button>
  );
};

export default StepButton;
