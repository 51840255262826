import Select from "react-select";
import tickIcon from 'assets/images/check.svg'; // Adjust the path to your assets

const LeadsReactSelect = (props) => {
  const {
    onChange,
    options,
    placeholder,
    value,
    isMulti,
    placement,
    isSearch,
    menuMaxHeight,
    onFocus,
    onInputChange,
    handleEnterKeyPressed,
    inputValue,
    selectRef,
    onMenuClose,
    hideNoResultMessage = false,
    isLoading
  } = props;

  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      fontSize: "16px",
      color: "#6670854D",
      // marginTop: "5px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "16px",
      color: "#667085",
      display: "flex",
      alignItems: "center",
      gap: "8px", // Add spacing between text and tick icon
    }),
    valueContainer: (provided) => ({
      ...provided,
      fontSize: "16px",
      color: "#667085",
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "16px",
      color: "#667085",
      display: "flex",
      alignItems: "center",
      gap: "8px", // Add spacing between text and tick icon
    }),
    menu: (provided) => ({
      ...provided,
      fontSize: "16px",
    }),
  };

  const customSingleValue = ({ data }) => (
    <div style={{ display: 'flex', alignItems: 'center',marginTop:"-30px" ,color:"#6670854D" }}>
      {placeholder}
      {data === value && <img src={tickIcon} alt="Tick"/>}
    </div>
  );

  const customOption = (props) => {
    const { innerRef, innerProps, data, isSelected } = props;
    return (
      <div
        ref={innerRef}
        {...innerProps}
        className="px-[14px] h-[44.5px] -mt-[3.4px] cursor-pointer hover:bg-gray-50"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        {isSelected && <img src={tickIcon} alt="Tick" className="mr-[8px]" />}
        <span className="text-[#475467] text-[16px]">{data.label}</span>
      </div>
    );
  };

  return (
    <Select
      ref={selectRef}
      onChange={(e) => onChange(e)}
      options={options}
      classNamePrefix={"leads-react-select"}
      className="!font-inter w-full"
      value={value}
      isMulti={isMulti}
      placeholder={placeholder}
      getOptionLabel={(option) => option?.label}
      getOptionValue={(option) => option?.value}
      menuPlacement={placement}
      isSearchable={isSearch}
      maxMenuHeight={menuMaxHeight}
      onFocus={onFocus}
      onInputChange={(value) => onInputChange(value)}
      onKeyDown={(event) => handleEnterKeyPressed(event)}
      inputValue={inputValue}
      styles={customStyles}
      closeMenuOnSelect={false}
      components={{ SingleValue: customSingleValue, Option: customOption }}
      noOptionsMessage={() => hideNoResultMessage ? null : "No Results Found"}
      onMenuClose={onMenuClose}
      isLoading={isLoading}
    />
  );
};

export default LeadsReactSelect;
