import BillingFailure from "components/subscriptionModals/billingFailure";
import Sidebar from "components/sidebar";
import BillingFailureTeamMember from "components/subscriptionModals/billingFailureTeamMember";
import { useAuthStore, useSettingStore } from "stores";
import { useEffect, useState } from "react";
import {
  useCompanySubscriptionStatus,
  usePaymentStatus,
} from "services/payments-api";
import SubscriptionWasCancelled from "components/subscriptionModals/canceled";
import { useLocation } from "react-router-dom";
import { PYLON_APP_ID } from "constant";

const Layout = (props) => {
  const { children } = props;
  const { isSubscriptionCancelled, setIsSubscriptionCancelled } =
    useAuthStore();
  const { setSubscriptionEndDate } = useSettingStore();
  const location = useLocation();
  const { pathname, search } = location;
  const { isAdmin, userDetails } = useAuthStore();
  const [shouldShowSubscriptionModal, setShouldShowSubscriptionModal] =
    useState(false);

  const [isFailed, setIsFailed] = useState(false);
  const [isSubscriptionCanceled, setIsSubscriptionCanceled] = useState(false);

  const { data: paymentMethodStatus } = usePaymentStatus({
    user_id: userDetails?.userid,
  });

  const { data: companySubscriptionStatus } = useCompanySubscriptionStatus({
    user_id: userDetails?.userid,
  });

  useEffect(() => {
    // Update payment failure status
    const failed = paymentMethodStatus?.status === "failed";
    setIsFailed(failed);

    // Update subscription cancellation status
    const canceled = companySubscriptionStatus?.status === "canceled";
    setIsSubscriptionCanceled(canceled);

    if (canceled) {
      setSubscriptionEndDate(companySubscriptionStatus?.subscription_end_date);
      setIsSubscriptionCancelled(true);
    } else {
      setIsSubscriptionCancelled(false);
    }
  }, [paymentMethodStatus, companySubscriptionStatus]);

  useEffect(() => {
    if (isSubscriptionCancelled) {
      const isNotBillingTab = search !== "?tab=Billing";
      const isNotSettingsPath = pathname !== "/settings";
      const isSettingsWithoutBillingTab =
        pathname === "/settings" && search !== "?tab=Billing";
      setShouldShowSubscriptionModal(
        isNotBillingTab && (isNotSettingsPath || isSettingsWithoutBillingTab)
      );
    }
  }, [location, companySubscriptionStatus, isSubscriptionCancelled]);

  useEffect(() => {
    if (userDetails?.userid) {
      console.log(userDetails,'userDetails', window);

      window.pylon = {
        chat_settings: {
          app_id: PYLON_APP_ID,
          email: userDetails?.email,
          name: userDetails?.name,
          // avatar_url: user.profileImgUrl,
        },
      };
    }
  }, [userDetails]);

  return (
    <div className="flex bg-white">
      <Sidebar isFailed={isFailed} />
      <div
        className={`flex-1 ${
          pathname === "/dashboard" ? "!bg-[#251241]" : "bg-[#FFF]"
        } overflow-x-hidden innerScrollBar`}
      >
        {children}
      </div>
      <BillingFailure isOpen={isAdmin && isFailed} />
      <BillingFailureTeamMember isOpen={!isAdmin && isFailed} />
      {shouldShowSubscriptionModal && (
        <>
          <SubscriptionWasCancelled
            isOpen={isAdmin && isSubscriptionCanceled}
          />
          <BillingFailureTeamMember
            isOpen={!isAdmin && isSubscriptionCanceled}
          />
        </>
      )}
    </div>
  );
};

export default Layout;
