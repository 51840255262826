import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "components/loader";
import { useEffect } from "react";

const InviteSignup = () => {
  const navigate = useNavigate();
  const [searchparems] = useSearchParams();
  const email = searchparems.get("email");
  const code = searchparems.get("code");
  useEffect(() => {
    localStorage.clear();
    navigate(`/signup?email=${encodeURIComponent(email)}&code=${encodeURIComponent(code)}`);
    localStorage.setItem("invited", true);
  }, [email]);
  return (
    <>
      <Loader />
    </>
  );
};

export default InviteSignup;
