import cloudIllustration from "assets/images/cloud_illustration.gif";

const LookingForLeads = ({ setStep }) => {

  return (
    <>
      <div className="flex flex-col items-center justify-center mb-[20px] font-inter">
        <div className="flex items-center justify-center p-[11px] mt-[15px] ">
          <img
            src={cloudIllustration}
            alt="info img"
            className="h-[117px] w-[158px] ml-[-2px]"
          />
        </div>
        <h4 className="font-[600] text-[18px] text-[#101828] mb-2 font-inter">
        Looking for leads...
        </h4>
        <p className="font-normal text-center text-[13px] leading-[18px] text-[#475467] font-inter">
          Thanks for your patience.
        </p>
        <p className="font-normal text-center text-[13px] leading-[18px] text-[#475467] font-inter">
          Depending on how many leads you imported,
        </p>
        <p className="font-normal text-center text-[13px] leading-[18px] text-[#475467] font-inter">
          this could take a few minutes.
        </p>
      </div>
    </>
  );
};

export default LookingForLeads;
