import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

const useSettingStore = create(
  persist(
    (set) => ({
      userData: {},
      blocklistDomainList: [],
      setBlocklistDomainList: (list) => set({ blocklistDomainList: list }),
      clearBlocklistDomainList: () => set({ blocklistDomainList: [] }),
      setUserData: (userData) => set({ userData: userData }),
      clearUserData: () => set({ userData: {} }),
      subscriptionEndDate: "",
      setSubscriptionEndDate: (subscriptionEndDate) =>
        set({ subscriptionEndDate: subscriptionEndDate }),
      clearSubscriptionEndDate: () => set({ subscriptionEndDate: "" }),
      fileId: "",
      setFileId: (fileId) => set({ fileId: fileId }),
      clearFileId: () => set({ fileId: "" }),
      notificationCount: "",
      setNotificationCount: (count) => set({ notificationCount: count }),
      clearNotificationCount: () => set({ notificationCount: "" }),
      activityCount: "",
      setActivityCount: (count) => set({ activityCount: count }),
      clearActivityCount: () => set({ activityCount: "" }),
    }),
    {
      name: "setting-store",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export default useSettingStore;
