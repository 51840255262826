import Button from "components/button";
import { useEffect, useState } from "react";
import leftIcon from "assets/images/addMember3.svg";
import centerIcon from "assets/images/addMember1.svg";
import rightIcon from "assets/images/addMember2.svg";
import copyIcon from "assets/images/copy-icon.svg";
import CommonCheckbox from "components/commonCheckbox";
import OutlinedInput from "components/outlinedInput";
import thumb from "assets/images/thumb.svg";
import { toast } from "react-toastify";
import {
  useGetCollaborators,
  useShareProspectList,
} from "services/prospecting-api";
import { useAuthStore, useProspectStore } from "stores";
import teamImg from "assets/images/activity-avatar3.svg";
import Loader from "components/loader";
import useSettingStore from "stores/settingStore";
import {
  getNameInititals,
  getRandomProfileLogo,
  getRandomProfileLogoWithRandom,
} from "utils/index";
import { modifiedProspectUrl } from "utils/modify-url";
import WorkspaceLogo from "assets/images/workspace.svg";

const ShareLinkModal = (props) => {
  const shareProspectList = useShareProspectList();
  const getCollaborators = useGetCollaborators();
  const {
    isOpen,
    onClose,
    isProspectingTableShareModal = false,
    currentId,
  } = props;
  const { isAdmin ,userDetails} = useAuthStore();
  const [selectedCheckboxIds, setSelectedCheckboxIds] = useState([]);
  const [copiedTooltipVisible, setCopiedTooltipVisible] = useState(false);
  const { userData } = useSettingStore();
  const [teamMembersData, setTeamMembersData] = useState([]);
  const [adminIds, setAdminIds] = useState([]);
  const { prospect } = useProspectStore();
  const [urlValue, setUrlValue] = useState("");
  const [loginUserId, setLoginUserId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleGetCollaborators = async () => {
    try {
      const resolve = await getCollaborators.mutateAsync();
      const combinedTeam = [
        ...resolve?.admin,
        {
          ...userData?.company,
          company: true,
          name: resolve?.admin?.[0]?.workspace || userDetails?.company || "",
        },
        ...resolve?.team_members?.filter((item) => item?.user_id),
      ];
      const currentUser = resolve?.team_members?.find(
        (item) => item?.user_id === userData?.user_id
      );
      const fetchedAdminIds =
        (isAdmin
          ? resolve?.admin?.map((admin) => admin.user_id)
          : [currentUser?.user_id]) || [];
      setAdminIds(fetchedAdminIds); // Store admin IDs
      if (!isAdmin) {
        setLoginUserId(...fetchedAdminIds);
      }
      const updatedSelectedCheckboxIds = [
        ...selectedCheckboxIds,
        ...fetchedAdminIds,
      ];

      const sortedData = [
        {
          ...resolve?.team_members?.find(
            (item) => item?.user_id === userData?.user_id
          ),
          isLoginUser: true,
        },
        {
          ...userData?.company,
          company: true,
          user_id: "company_id",
          name: resolve?.admin?.[0]?.workspace || userDetails?.company || "",
        },
        ...resolve?.admin,
        ...resolve?.team_members
          ?.filter((item) => item?.user_id !== userData?.user_id)
          .filter((item) => item?.user_id),
      ];

      const filterData = isAdmin ? combinedTeam : sortedData;
      setSelectedCheckboxIds(updatedSelectedCheckboxIds);
      setTeamMembersData(filterData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleShareProspectList = async () => {
    try {
      const payload = {
        prospect_id: currentId,
        shared_user_ids: selectedCheckboxIds
          ?.filter((id) => !adminIds.includes(id))
          ?.filter(Boolean)
          ?.filter((item) => item !== "company_id"),
      };
      const response = await shareProspectList?.mutateAsync(payload);
      onClose();
      toast.success("List Shared", {
        icon: () => <img src={thumb} alt="name" />,
      });
    } catch (error) {
      toast.error(error?.data?.detail);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (currentId) {
      handleGetCollaborators();
    }
  }, [currentId]);

  const handleCheckboxChange = (userId, isCompany) => {
    if (isCompany) {
      const isCompanySelected = selectedCheckboxIds.includes(userId);
      if (isCompanySelected) {
        // If the company checkbox is already selected, unselect all team members and remove company ID
        if (isAdmin) {
          setSelectedCheckboxIds([...adminIds]);
        } else if (loginUserId) {
          setSelectedCheckboxIds([loginUserId]);
        } else {
          setSelectedCheckboxIds([]);
        }
      } else {
        // If the company checkbox is selected, select all team members (excluding admins)
        const newSelection = teamMembersData
          .filter((item) => !item.company && !adminIds.includes(item.user_id))
          .map((item) => item.user_id);
        if (isAdmin) {
          setSelectedCheckboxIds([...newSelection, userId, ...adminIds]); // Add company ID and admin ID
        } else if (loginUserId) {
          setSelectedCheckboxIds([...newSelection, userId, loginUserId]); // Add company ID and admin ID
        } else {
          setSelectedCheckboxIds([...newSelection, userId]); // Add company ID
        }
      }
    } else {
      // For individual user checkboxes
      let newSelection;

      if (selectedCheckboxIds.includes(userId)) {
        // If user is already selected, unselect them
        newSelection = selectedCheckboxIds.filter((id) => id !== userId);
      } else {
        // Otherwise, add them to the selection
        newSelection = [...selectedCheckboxIds, userId];
      }

      // Check if all team members (except company and admins) are selected
      const allMembersSelected = teamMembersData
        .filter((item) => !item.company && !adminIds.includes(item.user_id))
        .every((member) => newSelection.includes(member.user_id));

      if (allMembersSelected) {
        // If all individual team members are selected, include the company ID
        newSelection.push("company_id"); // Replace with your actual company ID
      } else {
        // If any individual member is unselected, remove the company ID
        newSelection = newSelection.filter((id) => id !== "company_id"); // Replace with your actual company ID
      }

      setSelectedCheckboxIds(newSelection);
    }
  };

  const isUsers =
    teamMembersData?.filter((item) => item?.role === "team_members")?.length >
    0;
  const allTeamMembersSelected = teamMembersData
    ?.filter((i) => (!isUsers ? i?.company : !i?.company))
    .every((member) => selectedCheckboxIds?.includes(member.user_id));

  useEffect(() => {
    if (currentId) {
      const modifiedUrl = modifiedProspectUrl(currentId, "/prospecting");
      setUrlValue(modifiedUrl);
    }
  }, [currentId]);

  useEffect(() => {
    let timer;
    if (copiedTooltipVisible) {
      timer = setTimeout(() => {
        setCopiedTooltipVisible(false);
      }, 800);
    }
    return () => clearTimeout(timer);
  }, [copiedTooltipVisible]);

  const handleCopy = () => {
    if (!urlValue) return;

    navigator.clipboard
      .writeText(urlValue)
      .then(() => {
        setCopiedTooltipVisible(true);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const listOwner = prospect?.user_id === userData?.user_id;

  const [profileImages, setProfileImages] = useState([]);

  useEffect(() => {
    const images = teamMembersData.map((item, index) => {
      if (item?.role === "admin") {
        return index === 0
          ? userData?.user?.profile_pic ||
              getRandomProfileLogoWithRandom(index)?.src
          : getRandomProfileLogo()?.src;
      } else if (item?.company) {
        return WorkspaceLogo;
      } else {
        return null; // or some default image
      }
    });
    setProfileImages(images);
  }, [teamMembersData, userData]);

  if (!isOpen && !isModalVisible) return null;

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[200ms] ease-in-out ${
        isModalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className="fixed inset-0 bg-black opacity-10"
        onClick={onClose}
      ></div>
      <div
        className={`bg-white px-0 py-[24px] rounded-lg shadow-lg z-10 w-full max-w-[600px] relative transition-all ease-in-out duration-[300ms] ${
          isModalVisible ? "scale-100" : "scale-95"
        }`}
      >
        <div className="modal-scroll">
          <div
            className={`px-[24px] ${isProspectingTableShareModal && "mb-2"}`}
          >
            <div className="flex items-center justify-center mb-[20px] font-inter relative mt-[6px]">
              <img
                src={leftIcon}
                alt="left img"
                className="w-[48px] h-[48px] rounded-full mr-2"
              />
              <img
                src={centerIcon}
                alt="center img"
                className="w-[56px] h-[56px] rounded-full absolute top-[-8px]"
              />
              <img
                src={rightIcon}
                alt="right img"
                className="w-[48px] h-[48px] rounded-full ml-2"
              />
            </div>
            <div className="mt-[24px]">
              <h4 className="font-[600] text-[18px] text-[#101828] text-center m-0 font-inter">
                Share Link
              </h4>
              <p className="font-normal text-[14px] text-[#475467] text-center font-inter">
                This link will only work when your team member is signed into
                Pipeline and has access to the lead list.
              </p>
            </div>
            <div className="mt-[16px]">
              <p className="font-[500] font-inter text-[14px] text-[#344054] mb-[6px]">
                Share link
              </p>
              <div className="flex items-center">
                <OutlinedInput
                  placeholder="Enter share link"
                  className="!text-[#101828] text-ellipsis overflow-hidden whitespace-nowrap max-w-full"
                  value={urlValue}
                />
                <div className="relative">
                  <img
                    src={copyIcon}
                    alt="copy icon"
                    className="w-[20px] h-[20px] min-w-[20px] mx-[14px] block cursor-pointer relative hover:opacity-60 transition-opacity duration-200 ease-in-out"
                    onClick={handleCopy}
                  />
                  {copiedTooltipVisible && (
                    <div
                      id="tooltip-hover"
                      role="tooltip"
                      className="absolute z-10 top-[-42px] px-3 py-[4px] text-[12px] font-[400] text-white bg-gray-900 rounded-[6px] shadow-sm border border-[#3C3C3C] transition-opacity duration-300 opacity-100"
                    >
                      Copied!
                    </div>
                  )}
                </div>
              </div>
            </div>
            {(isAdmin || listOwner) && (
              <>
                <div className="mb-[20px] mt-[24px]">
                  <h4 className="font-[600] text-[18px] text-[#101828] text-center m-0 font-inter">
                    Add your team members
                  </h4>
                  <p className="font-normal text-[14px] text-[#475467] text-center font-inter">
                    Invite colleagues to collaborate on this lead list.
                  </p>
                </div>
                <div className="max-h-[320px] modal-scroll overflow-auto">
                  {teamMembersData?.length > 0 ? (
                    teamMembersData?.map((item, index) => {
                      return (
                        <div
                          key={item?.user_id}
                          className="flex items-center w-full mb-[12px]"
                        >
                          <div
                            className={`w-[16px] h-[16px] team-checkbox mr-[11px] ${
                              (item?.role === "admin" && isAdmin) ||
                              item?.isLoginUser
                                ? "team-fade-checkbox"
                                : ""
                            }`}
                          >
                            <CommonCheckbox
                              checked={
                                item?.isLoginUser
                                  ? true
                                  : item?.company
                                  ? allTeamMembersSelected
                                  : (item?.role === "admin" && isAdmin) ||
                                    selectedCheckboxIds.includes(item?.user_id)
                              }
                              onChange={() =>
                                handleCheckboxChange(
                                  item?.user_id,
                                  item?.company
                                )
                            }
                              disabled={
                                (item?.role === "admin" && isAdmin) ||
                                item?.isLoginUser
                              }
                            />
                          </div>
                          <div className="flex items-center justify-between w-full pr-2">
                            <div className="flex items-center w-[80%]">
                              {item?.role === "admin" || item?.company ? (
                                <img
                                  src={
                                    item?.icons
                                      ? item?.icon
                                      : item?.role === "admin"
                                      ? index === 0
                                        ? userData?.user?.profile_pic ||
                                          userDetails?.profile_pic ||
                                          getRandomProfileLogoWithRandom(index)
                                            ?.src
                                        : profileImages[index]
                                      : item?.company
                                      ? WorkspaceLogo
                                      : teamImg
                                  }
                                  alt="team member icon"
                                  className={`w-[40px] min-w-[40px] h-[40px] mr-[12px] object-center  ${
                                    !item?.company && "rounded-full"
                                  } `}
                                />
                              ) : (
                                <div className="w-[40px] min-w-[40px] h-[40px] rounded-full flex items-center justify-center text-[14px] font-[700] text-[#4409B9] bg-[#EEEDFD] mr-[12px]">
                                  {getNameInititals(item?.name || item?.email)}
                                </div>
                              )}

                              <div>
                                <h6
                                  className={`font-inter leading-[20px] text-[#475467] m-0 ${
                                    (item?.role === "admin" && isAdmin) ||
                                    item?.isLoginUser
                                      ? "text-[#AAA8AF]"
                                      : ""
                                  } ${
                                    item?.company_name
                                      ? "font-[400] text-[13px]"
                                      : "font-[600] text-[14px]"
                                  } ${item?.company && "-mt-[5px]"}`}
                                >
                                  {item?.company_name ||
                                    item?.name ||
                                    item?.email}
                                </h6>

                                <p
                                  className={`font-inter font-normal text-[14px] leading-[20px] text-[#475467] m-0 ${
                                    (item?.role === "admin" && isAdmin) ||
                                    item?.isLoginUser
                                      ? "text-[#AAA8AF]"
                                      : ""
                                  }`}
                                >
                                  {item?.company && item?.name
                                    ? "Your Workspace"
                                    : item?.email}
                                </p>
                              </div>
                            </div>
                            {!item?.company && (
                              <p
                                className={`w-[20%] text-right font-inter font-[500] text-[12px] leading-[18px] ${
                                  selectedCheckboxIds.includes(item?.user_id)
                                    ? "text-[#475467]"
                                    : "text-[#475467]"
                                }`}
                              >
                                {item?.isLoginUser
                                  ? "You"
                                  : selectedCheckboxIds.includes(
                                      item?.user_id
                                    ) && item?.role !== "admin"
                                  ? "Editor"
                                  : item?.role === "admin"
                                  ? index === 0
                                    ? isAdmin
                                      ? "You (Admin)"
                                      : "Admin"
                                    : "Admin"
                                  : ""}
                              </p>
                            )}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="w-full h-[200px] flex items-center justify-center">
                      <Loader />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          {!isProspectingTableShareModal && (isAdmin || listOwner) && (
            <div className="flex items-center justify-center pt-[32px] px-[24px] border-t-[1.5px] mt-[32px] border-[#E4E7EC]">
              <Button
                title={
                  shareProspectList?.isPending ? (
                    <div className="w-[20px] h-[20px] border-[3px] mx-auto border-t-[3px] border-[#fff] border-solid rounded-full animate-spin border-t-[#8A43FF]" />
                  ) : (
                    "Share"
                  )
                }
                secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9]"
                className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow !rounded-[8px]"
                isRounded="6px"
                onClick={handleShareProspectList}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShareLinkModal;
