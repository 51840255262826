import { Outlet, Navigate } from "react-router-dom";
import VideoWrapper from "./video-wrapper";
import { getAccessToken, getPaymentVerify } from "utils/token-service";

const isAuthenticated = () => {
  return !!getAccessToken() && !!getPaymentVerify();
};

const AuthLayout = () => {
  if (isAuthenticated()) {
    return <Navigate to="/dashboard" replace />;
  }

  return (
    <VideoWrapper>
      <Outlet />
    </VideoWrapper>
  );
};

export default AuthLayout;
