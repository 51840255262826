import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

const usePersistedProspectStore = create(
  persist(
    (set) => ({
      prospect: {},
      searchProspect: null,
      saveLeadName: "",
      setSaveLeadName: (name) => set({ saveLeadName: name }),
      setProspectInfo: (info) => set({ prospect: info }),
      clearProspectInfo: () => set({ prospect: {} }),
      setSearchPropspect: (data) => set({ searchProspect: data }),
      clearSearchPropspect: () => set({ searchProspect: null }),
      fileId: "",
      setFileId: (fileId) => set({ fileId: fileId }),
      clearFileId: () => set({ fileId: "" }),
      csvColumns: [],
      setCsvColumns: (csvColumns) => set({ csvColumns: csvColumns }),
      clearCsvColumns: () => set({ csvColumns: [] }),
    }),
    {
      name: "prospect-store",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

const useProspectListStore = create((set) => ({
  prospectList: [],
  setProspectList: (list) => set({ prospectList: list }),
  clearProspectList: () => set({ prospectList: [] }),
}));

const useProspectStore = () => {
  const persistedState = usePersistedProspectStore();
  const nonPersistedState = useProspectListStore();

  return {
    ...persistedState,
    ...nonPersistedState,
  };
};

export default useProspectStore;
