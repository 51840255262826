import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

const usePersistedCampaignIdStore = create(
  persist(
    (set) => ({
      campaignId: "",
      campaign: {},
      isDraftCampaignClicked: "",
      draftListId: null,
      isGetSettingsInLeadsTab: false,
      openLeadsTabDirectly: false,
      setOpenLeadsTabDirectly: (openLeadsTabDirectly) => set({openLeadsTabDirectly}),
      clearOpenLeadsTabDirectly: () =>set({openLeadsTabDirectly:false}),
      setIsGetSettingsInLeadsTab: (isGetSettingsInLeadsTab) =>
        set({ isGetSettingsInLeadsTab: isGetSettingsInLeadsTab }),
      clearIsGetSettingsInLeadsTab: () =>
        set({ isGetSettingsInLeadsTab: false }),
      setDraftListId: (draftListId) => set({ draftListId: draftListId }),
      clearDraftListId: () => set({ draftListId: null }),
      setIsDraftCampaignClicked: (option) =>
        set({ isDraftCampaignClicked: option }),
      clearIsDraftCampaignClicked: () => set({ isDraftCampaignClicked: "" }),
      setCampaignId: (campaignId) => set({ campaignId: campaignId }),
      setCampaign: (campaign) => set({ campaign: campaign }),
      clearCampaign: () => set({ campaign: {} }),
      clearCampaignId: () => set({ campaignId: "" }),
    }),
    {
      name: "campaign-id-store",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

const useCampaignListStore = create((set) => ({
  campaignList: [],
  setCampaignList: (list) => set({ campaignList: list }),
  clearCampaignList: () => set({ campaignList: [] }),
  campaignListId: "",
  setCampaignListId: (campaignListId) =>
    set({ campaignListId: campaignListId }),
  clearCampaignListId: () => set({ campaignListId: "" }),
  newCampaign: {},
  setNewCampaign: (newCampaign) => set({ newCampaign: newCampaign }),
  clearNewCampaign: () => set({ campaign: {} }),
  leads_info: {},
  setLeadInfo: (leads_info) => set({ leads_info: leads_info }),
  trackerId: null,
  setTrackerId: (tracker_id) => set({ trackerId: tracker_id }),
  clearTrackerId: () => set({ trackerId: null }),
}));

const useCampaignStore = () => {
  const persistedState = usePersistedCampaignIdStore();
  const nonPersistedState = useCampaignListStore();

  return {
    ...persistedState,
    ...nonPersistedState,
  };
};

export default useCampaignStore;
