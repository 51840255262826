import { IoClose } from "react-icons/io5";
import Button from "components/button";
import listIcon from "assets/images/export-lead.svg";
import { useEffect, useState } from "react";

const ExportLeadsModal = (props) => {
  const {
    isOpen,
    onClose,
    isPeopleSelected,
    setIsPeopleSelected,
    handleExportEnrichedLeads,
  } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [isOpen]);

  if (!isOpen && !isModalVisible) return null;

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[300ms] ease-in-out ${
        isModalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className="fixed inset-0 bg-black opacity-10"
        onClick={onClose}
      ></div>
      <div
        className={`bg-white p-[24px] rounded-lg modal-shadow z-10 w-full max-w-[400px] relative transition-all ease-in-out duration-[150ms] ${
          isModalVisible ? "scale-100" : "scale-95"
        }`}
      >
        <IoClose
          className="text-[#667085] text-[20px] w-[24px] h-[24px] absolute right-[24px] top-[26px] cursor-pointer"
          onClick={onClose}
        />
        <div className="modal-scroll">
          <div className="modal-scroll">
            <div className="flex flex-col items-start justify-start mb-[15px] font-inter">
              <div className="flex items-center justify-center border-[1px] border-[#E4E7EC] rounded-[10px] p-[11px] mb-[16px] y-axis-shadow">
                <img
                  src={listIcon}
                  alt="info img"
                  className="h-[24px] w-[24px]"
                />
              </div>
              <h4 className="font-[600] text-[18px] text-[#101828] m-0 font-inter">
                Export Leads
              </h4>
              <p className="font-normal text-[14px] text-[#475467] font-inter">
                Select which data you’d like to export.
              </p>
            </div>
            <div className="flex items-center justify-start">
              <Button
                title="People"
                isWhiteBtn={true}
                isFullWidth={false}
                onClick={() => setIsPeopleSelected(true)}
                className={`!text-[16px] !h-[44px] !font-[600] !text-[#344054] hover:!text-[#344054] !border-[#D0D5DD] !px-[18px] focus:!bg-[#7F56D9] focus:!text-white y-axis-shadow !rounded-[8px] ${
                  isPeopleSelected
                    ? "!bg-[#7F56D9] !text-white hover:!bg-[#7F56D9] hover:!text-white"
                    : ""
                }`}
                hideHover={isPeopleSelected}
              />
              <Button
                title="Companies"
                isWhiteBtn={true}
                isFullWidth={false}
                onClick={() => setIsPeopleSelected(false)}
                className={`!text-[16px] !h-[44px] !font-[600] !text-[#344054] hover:!text-[#344054] !border-[#D0D5DD] !px-[18px] ml-[12px] focus:!bg-[#7F56D9] focus:!text-white !rounded-[8px] y-axis-shadow ${
                  !isPeopleSelected
                    ? "!bg-[#7F56D9] !text-white hover:!bg-[#7F56D9] hover:!text-white"
                    : ""
                }`}
                hideHover={!isPeopleSelected}
              />
            </div>
            <div className="flex items-center justify-center mt-[26px]">
              <Button
                title="Cancel"
                isWhiteBtn={true}
                isRounded="6px"
                onClick={onClose}
                className="!text-[16px] !h-[44px] !font-[600] !text-[#344054] y-axis-shadow !rounded-[8px]"
              />
              <Button
                title="Export"
                secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9] ml-[12px]"
                className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow !rounded-[8px]"
                isRounded="6px"
                onClick={handleExportEnrichedLeads}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExportLeadsModal;
