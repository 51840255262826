import { useState, useEffect } from "react";

const RoundProgressBar = ({ percentage, page, total }) => {
  const [currentPercentage, setCurrentPercentage] = useState(0);

  useEffect(() => {
    if ((percentage === 0 && total === 0) || (percentage > total)) {
      setCurrentPercentage(0);
      return; 
    }

    const targetPercentage = (percentage / total) * 100;
    const timer = setInterval(() => {
      setCurrentPercentage((prev) => {
        if (prev >= targetPercentage) {
          clearInterval(timer);
          return targetPercentage;
        }
        return prev + 1;
      });
    }, 10);

    return () => clearInterval(timer);
  }, [percentage, total]);

  const radius = 25;
  const strokeWidth = 12;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset =
    circumference - (currentPercentage / 100) * circumference;

  return (
    <div className="flex items-center w-[14px] h-[14px] pointer-events-none">
      <svg
        width="70"
        height="70"
        viewBox="0 0 70 70"
        className="rotate-[-90deg] pointer-events-none"
      >
        <circle
          cx="35"
          cy="35"
          r={radius}
          stroke="#D5D5D5"
          strokeWidth={strokeWidth}
          fill="none"
        />
        <circle
          cx="35"
          cy="35"
          r={radius}
          stroke={page === "prospecting" ? "#4B20B8" : "#6366F1"}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          fill="none"
        />
      </svg>
    </div>
  );
};

export default RoundProgressBar;
