import Logo from "assets/images/logo.svg";
import GoogleIcon from "assets/images/googleIcon.svg";
import Avatar from "assets/images/avatar.svg";
import { FaAngleDown } from "react-icons/fa6";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "components/loader";
import AnimatedButton from "components/animatedButton";
import { useForm } from "react-hook-form";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { toast } from "react-toastify";
import { AppContext } from "context/AppContext";
import {
  useAuthCheck,
  useGoogleSignin,
  useLogin,
  useResendCode,
} from "services/auth-api";
import { useAccountStore, useAuthStore, useSettingStore } from "stores";
import { useCreatePaymentLink } from "services/payments-api";
import { setAccessToken, setPaymentVerify } from "utils/token-service";
import { useGetLinkedinAccount } from "services/accounts-api";
import { useGoogleLogin } from "@react-oauth/google";

const inputClasses = `w-full h-[42px] font-[400] bg-[#00000014] border border-1 border-[#333741] rounded-[7px] outline-none py-[9px] px-[13px] relative text-[16px] text-[#fff] placeholder:!text-[#85888E] focus-visible:border-[#fff] focus-visible:text-[#fff] focus-visible:placeholder:text-[#fff] transition-all`;

const SignIn = () => {
  const { setLinkedinAccount } = useAccountStore();
  const { setCompanyId, setIsAdmin, setUserDetails, setAuthCheck } =
    useAuthStore();
  const { setUserData } = useSettingStore();

  const navigate = useNavigate();
  const loginMutation = useLogin();
  const resendCodeMutation = useResendCode();
  const getLinkedInAccountDetails = useGetLinkedinAccount();
  const authCkeckMutation = useAuthCheck();
  const createPaymentLink = useCreatePaymentLink();

  const { setAppState } = useContext(AppContext);
  const [loader, setLoader] = useState(false);
  const [signedIn, setSignedIn] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [fadeIn, setFadeIn] = useState(true);
  const googleSignin = useGoogleSignin();

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const accessToken = urlParams.get("accessToken");
    if (accessToken) {
      localStorage?.clear();
      setLoader(true);
      setAccessToken(accessToken);
      onNavigate({ token: accessToken, impersonateUser: true });
    } else {
      console.log("No access token found in the URL");
    }
  }, []);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
    },
  });

  const handleSignIn = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      setLoader(true);
      try {
        const payload = {
          token: tokenResponse.access_token,
          invite_code: undefined,
        };

        const response = await googleSignin.mutateAsync(payload);
        if (response?.token) {
          onNavigate(response, response);
        }
      } catch (error) {
        toast.error(
          error?.message ||
            error?.data?.detail ||
            error?.data?.response?.detail ||
            "An unknown error occurred",
          {
            position: "bottom-right",
          }
        );
        console.log("Error in Login Process", error);
      } finally {
        setLoader(false);
        setFadeIn(false);
      }
    },
    onError: () => {
      toast.error("Google login failed");
    },
    scope: "openid profile email",
    flow: "implicit", // Ensure you're using the implicit flow (or default)
    response_type: "token id_token", // Explicitly ask for the id_token in the response
  });

  const handleGetLinkedInAccountDetails = async () => {
    try {
      const response = await getLinkedInAccountDetails.mutateAsync();
      setLinkedinAccount(response);
    } catch (error) {
      setLinkedinAccount({});
      console.log(error);
    }
  };

  const onNavigate = async (response, data) => {
    if (response.token) {
      if (response?.role_name === "admin") {
        setAppState((prev) => ({ ...prev, isAdmin: true }));
        setIsAdmin(true);
      } else {
        setAppState((prev) => ({ ...prev, isAdmin: false }));
        setIsAdmin(false);
      }
      try {
        const navigationCheck = await authCkeckMutation.mutateAsync({
          impersonate_user: response?.impersonateUser ? "true" : "false",
        });
        setUserDetails(response);
        setCompanyId(response?.company_id);
        setAuthCheck(navigationCheck);
        const userId = { user_id: response?.userid };
        if (!navigationCheck?.payment_setup) {
          setUserData(userId);
          const link = await createPaymentLink.mutateAsync({
            price_id: "price_1PvLvZICbyBbd0D4UCEVVHfb",
            customer_mail_id: data.email,
          });
          if (link.url) {
            reset();
            window.open(link.url, "_self");
          }
        } else {
          if (
            !navigationCheck?.linkedin_setup ||
            !navigationCheck?.company_setup
          ) {
            setUserData(userId);
            navigate("/started");
            localStorage.removeItem("isNewUser");
          } else {
            setUserData(userId);
            handleGetLinkedInAccountDetails();
            setPaymentVerify(true);
            navigate("/dashboard");
          }
        }
      } catch (error) {
        toast.error(error?.data?.detail);
      }
    }
  };

  const onSubmit = async (data) => {
    setLoader(true);
    try {
      const response = await loginMutation.mutateAsync({
        ...data,
      });
      if (response?.token) {
        onNavigate(response, data);
      }
    } catch (error) {
      if (error?.data && error?.data?.detail) {
        if (error.data.detail === "User is not confirmed.") {
          await resendCodeMutation.mutateAsync({
            email: data?.username,
          });
          navigate("/email-verification", { state: { email: data?.username } });
        }
        if (error.data.detail !== "User is not confirmed.") {
          toast.error(error.data.detail);
        }
      } else {
        toast.error("An unknown error occurred");
      }
    } finally {
      setLoader(false);
      setFadeIn(false);
    }
  };

  if (loader) {
    return (
      <div className="h-[100%] flex flex-col justify-center items-center max-w-[340px] m-auto max-[767px]:h-auto  max-[767px]:p-[20px]  max-[767px]:pt-[50px]  max-[767px]:max-w-[100%]">
        <img src={Logo} alt="Logo" className="h-[60px] w-[60px] z-50" />
        <div className="mt-[30px] ml-[-10px]">
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <div className="">
      <div
        className="font-inter !h-[100%] flex flex-col justify-center items-center max-w-[340px] m-auto max-[767px]:h-auto  max-[767px]:p-[20px]  max-[767px]:pt-[50px]  max-[767px]:max-w-[100%]"
        data-aos={fadeIn && "fade-in"}
        data-aos-duration={fadeIn && "2000"}
      >
        <div className="flex items-center z-[99999999] signin-glowing-section p-[4px] mb-[30px] rounded-[65px] pr-[10px] h-[34px]">
          <span className="font-[600] uppercase text-white text-[10px] leading-[12px] px-2">
            NEW:
          </span>
          <p className="m-0 glowing-text">Supercharge Your Sales</p>
        </div>
        <div className="flex flex-col signin-card !py-[40px]">
          <img src={Logo} alt="Logo" className="h-[60px] w-[60px]" />
          <h1 className="text-[32px] text-center text-[#F5F5F6] font-[600] mt-[20px] mb-[5px]">
            Sign In
          </h1>
          <p className="text-[15px] text-center text-[#CBCBE8BF] font-[400]">
            Your outreach, on autopilot.
          </p>

          <form className="mt-[30px] w-full" onSubmit={handleSubmit(onSubmit)}>
            <p className="text-[14px] font-[500] text-[#CECFD2] mb-[5px]">
              Email
            </p>
            <input
              type="email"
              placeholder="Enter your email"
              className={`${inputClasses} !bg-[#0d0515] text-[#9e9ba0] focus-visible:!border-[#9e9ba0] ${
                errors.username ? "mb-1" : "mb-[10px]"
              } `}
              {...register("username", {
                required: "Email is required",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Invalid email address",
                },
              })}
              onDrop={(e) => e.preventDefault()}
              onDragOver={(e) => e.preventDefault()}
            />
            {errors.username && (
              <p className="text-red-500 text-xs mb-1.5">
                {errors.username.message}
              </p>
            )}
            <p className="text-[14px] font-[500] text-[#CECFD2] mb-[6px]">
              Password
            </p>
            <div className="relative">
              <input
                type={passwordVisible ? "text" : "password"}
                placeholder="Create a password"
                className={`${inputClasses} !bg-[#0d0515] text-[#9e9ba0] focus-visible:!border-[#9e9ba0] ${
                  errors.password ? "mb-1" : "mb-[10px]"
                } `}
                {...register("password", { required: "Password is required" })}
                // onPaste={(e) => e.preventDefault()}
                onDrop={(e) => e.preventDefault()}
                onDragOver={(e) => e.preventDefault()}
              />
              <div onClick={() => setPasswordVisible(!passwordVisible)}>
                {passwordVisible ? (
                  <FiEye
                    className="absolute right-[15px] z-50 top-[14px] cursor-pointer"
                    color="#CBCBE866"
                  />
                ) : (
                  <FiEyeOff
                    className="absolute right-[15px] z-50 top-[14px] cursor-pointer"
                    color="#CBCBE866"
                  />
                )}
              </div>
              {errors.password && (
                <p className="text-red-500 text-xs mb-1.5">
                  {errors.password.message}
                </p>
              )}
            </div>
            <div
              className="text-[#CECFD2] text-[13px] font-[600] w-fit cursor-pointer ml-auto block text-right hover:underline transition-all hover:text-[#fff] mt-[-3px] mb-[15px]"
              onClick={() => navigate("/forgot-password")}
            >
              Forgot Password
            </div>
            <AnimatedButton
              title="Sign in"
              className="!text-[15px] !font-[600] !h-[42px] !rounded-[7px] !mb-[15px] !bg-[#635BFF]"
              fontSize="!text-[15px]"
            />
          </form>
          {!signedIn && (
            <button
              className="bg-white w-full h-[42px] rounded-[7px] outline-none text-[#232323] text-[13px] font-[600] border-[2px] border-white transition-all flex items-center justify-center hover:bg-transparent hover:text-white"
              onClick={handleSignIn}
            >
              Sign in with Google
              <img
                src={GoogleIcon}
                alt="GoogleIcon"
                className="ml-[5px] w-[18px] h-[18px]"
              />
            </button>
          )}
          {signedIn && (
            <button className="bg-white buttonAnimation h-[42px] rounded-[7px] outline-none text-[#232323] text-[12px] font-[600] border-[2px] border-white transition-all flex items-center justify-between p-[11px]">
              <div className="flex items-center">
                <img
                  src={Avatar}
                  alt="GoogleIcon"
                  className="w-[30px] h-[30px] ml-[-10px]"
                />
                <div className="ml-[10px]">
                  <p className="font-[500] text-[#3C4043] text-[12px] text-left">
                    Sign in as Thomas
                  </p>
                  <span className="font-[400] text-[#5F6368] text-[12px] mt-[-3px] flex items-center">
                    thomaskeevan@gmail.com
                    <FaAngleDown
                      color="#5F6368"
                      className="ml-[5px] mt-[2px]"
                    />
                  </span>
                </div>
              </div>
              <img
                src={GoogleIcon}
                alt="GoogleIcon"
                className="w-[35px] h-[35px] mr-[-10px]"
              />
            </button>
          )}
        </div>
        <div className="relative w-full">
          <p className="w-full text-center font-[400] text-[14px] text-[#eeeeee] absolute top-full mt-5 left-1/2 -translate-x-1/2">
            Don’t have an account?{" "}
            <span
              className="font-[600] text-[#fff] cursor-pointer"
              onClick={() => navigate("/signup")}
            >
              Sign up
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
