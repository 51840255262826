import deleteImg from "assets/images/thumbs-down.svg";
import Button from "components/button";
import { useEffect } from "react";

const FailedViaLinkedin = ({ setStep, maxProfile, onClose }) => {
  return (
    <>
      <div className="tems-center justify-center font-inter">
        <div className="modal-scroll">
          <div className="modal-scroll">
            <div className="flex flex-col items-start justify-start font-inter">
              <div className="flex items-center justify-center mb-[16px]">
                <img
                  src={deleteImg}
                  alt="info img"
                  className="h-[48px] w-[48px]"
                />
              </div>
              <h4 className="font-[600] text-[18px] text-[#101828] m-[4px] mb-0 ml-0 font-inter">
                Invalid URL
              </h4>
              <p className="text-[14px] text-[#475467] font-inter mt-[9px] font-[700]">
                Please try again.
              </p>
            </div>
          </div>
        </div>
        <Button
          title="Back"
          isWhiteBtn={true}
          isRounded="6px"
          onClick={() => {
            setStep("viaLinkedin");
          }}
          className="!text-[16px] !h-[44px] !font-[600] !mb-[0px] !mt-[32px] y-axis-shadow mr-[12px] !text-[#344054]"
        />
      </div>
    </>
  );
};

export default FailedViaLinkedin;
