import Loader from "components/loader";
import magicStick from "assets/images/magic-stick.svg";

const AIGenerateSteps = () => {
  return (
    <div className="w-[42px] h-[42px] bg-white border-[1px] border-[#EAECF0] p-[6px] flex items-center justify-center rounded-[6px] flex-col"
    style={{boxShadow: "0 8.05px 9.65px -1.61px #1018281A"}}>
      <img
        src={magicStick}
        alt="magicStick"
        className="w-[18px] h-[18px] mb-[10px]"
      />
      <div className="ml-[-5px]">
        <Loader isAiGenerateLoader={true} />
      </div>
    </div>
  );
};

export default AIGenerateSteps;
