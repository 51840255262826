import Logo from "assets/images/logo.svg";
import messageIcon from "assets/images/message-notification-square.svg";
import Input from "components/textInput";
import backTo from "assets/images/back-to.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "components/loader";
import AnimatedButton from "components/animatedButton";
import { toast } from "react-toastify";
import OTPInput from "otp-input-react";
import LetterEmoji from "assets/images/loveletter.png";
import checkIcon from "assets/images/login-check.svg";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useForm } from "react-hook-form";
import {
  useEmailVerification,
  useForgotPassword,
  useForgotPasswordConfirm,
  useResendCode,
} from "services/auth-api";

const inputClasses = `w-full h-[42px] font-[400] bg-[#00000014] border border-1 border-[#333741] rounded-[7px] outline-none py-[9px] px-[13px] relative text-[16px] text-[#fff] placeholder:!text-[#85888E] focus-visible:border-[#fff] focus-visible:text-[#fff] focus-visible:placeholder:text-[#fff] transition-all`;

const ForgotPassword = () => {
  const navigate = useNavigate();
  const forgotPasswordMutation = useForgotPassword();
  const forgotPasswordConfirmMutation = useForgotPasswordConfirm();
  const emailVerificationMutation = useEmailVerification();
  const resendCodeMutation = useResendCode();
  const [loader, setLoader] = useState(false);
  const [signedIn, setSignedIn] = useState(true);
  const [isOtp, setIsOtp] = useState(false);
  const [isNewPassword, setIsNewPassword] = useState(false);
  const [OTP, setOTP] = useState("");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [isMinLength, setIsMinLength] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasLowercase, setHasLowercase] = useState(false);
  const [hasUppercase, setHasUppercase] = useState(false);
  const [fadeIn, setFadeIn] = useState(true);
  const [timer, setTimer] = useState(30);
  const [canResend, setCanResend] = useState(false);

  useEffect(() => {
    if (isOtp) {
      let interval;
      if (timer > 0) {
        interval = setInterval(() => {
          setTimer((prev) => prev - 1);
        }, 1000);
      } else {
        setCanResend(true);
      }

      return () => clearInterval(interval);
    }
  }, [timer, isOtp]);

  useEffect(() => {
    if (confirmPassword && password !== confirmPassword) {
      setError("Passwords do not match");
    } else {
      setError("");
    }
  }, [confirmPassword, password]);

  const handlePasswordChange = (e) => {
    setPassword(e);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e);
  };

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
  });

  const { email } = watch();

  const handleEmailSubmit = async (data) => {
    try {
      const response = await forgotPasswordMutation.mutateAsync({
        email: data.email,
      });

      if (response.status === 200) {
        setSignedIn(false);
        setIsOtp(true);
        toast.success("Verification code sent", {
          icon: () => (
            <img src={LetterEmoji} alt="name" className="!w-[46px] !h-[16px]" />
          ),
        });
      }
    } catch (error) {
      if (
        (error.data && error.data.detail && error.data.detail[0]) ||
        (error.data && error.data.detail)
      ) {
        toast.error(error.data.detail[0].msg || error.data.detail);
      } else {
        toast.error("An unknown error occurred");
      }
    }
    // setTimeout(() => {
    //   setSignedIn(false);
    //   setIsOtp(true);
    //   toast.success("Verification code sent", {
    //     icon: () => (
    //       <img src={LetterEmoji} alt="name" className="!w-[46px] !h-[16px]" />
    //     ),
    //   });
    //   setLoading(false);
    // }, 2000);
  };

  const handleVerificationSubmit = async () => {
    // try {
    //   const response = await emailVerificationMutation.mutateAsync({
    //     email: email,
    //     code: OTP,
    //   });

    //   if (response.user_status) {
    //     setIsOtp(false);
    //     setIsNewPassword(true);
    //   }
    // } catch (error) {
    //   setIsOtp(false);
    //   setIsNewPassword(true);
    //   // if (error.data && error.data.detail) {
    //   //   toast.error(error.data.detail);
    //   // } else {
    //   //   toast.error("An unknown error occurred");
    //   // }
    // }
    setIsOtp(false);
    setIsNewPassword(true);
  };

  const handleEmailResend = async () => {
    try {
      const response = await resendCodeMutation.mutateAsync({
        email: email,
      });
      setCanResend(false);
      setTimer(30);
      console.log(response, "response");
      toast.success("Code Re-sent", {
        icon: () => (
          <img src={LetterEmoji} alt="name" className="!w-[46px] !h-[16px]" />
        ),
      });
    } catch (error) {
      console.log(error, "error");
      toast.error("Code Re-send Failed", {
        icon: () => (
          <img src={LetterEmoji} alt="name" className="!w-[46px] !h-[16px]" />
        ),
      });
    }
  };

  const handleCreatePassword = async () => {
    if (
      !hasSpecialChar ||
      !isMinLength ||
      !hasLowercase ||
      !hasUppercase ||
      !hasNumber
    ) {
      return;
    }

    if (confirmPassword !== password) {
      if (confirmPassword.length === 0) {
        return toast.error("Enter confirm password");
      } else {
        return toast.error("Enter correct confirm password");
      }
    }
    setLoader(true);
    try {
      const response = await forgotPasswordConfirmMutation.mutateAsync({
        email: email,
        code: OTP,
        password: password,
      });

      if (response.status === 200) {
        setTimeout(() => {
          toast.success("Password changed successfully");
          navigate("/");
          setLoader(false);
        }, 2000);
      }
    } catch (error) {
      setSignedIn(false);
      setIsNewPassword(true);
      if (error.data && error.data.detail) {
        toast.error(error.data.detail);
      } else {
        toast.error("An unknown error occurred");
      }
    } finally {
      setTimeout(() => {
        setLoader(false);
        setFadeIn(false);
      }, 2000);
    }
  };

  useEffect(() => {
    setIsMinLength(password.length >= 8);
    setHasSpecialChar(/[^A-Za-z0-9]/.test(password));
    setHasNumber(/[0-9]/.test(password));
    setHasLowercase(/[a-z]/.test(password));
    setHasUppercase(/[A-Z]/.test(password));
  }, [password]);

  if (loader) {
    return (
      <div className="h-[100%] flex flex-col justify-center items-center max-w-[340px] m-auto max-[767px]:h-auto  max-[767px]:p-[20px]  max-[767px]:pt-[50px]  max-[767px]:max-w-[100%]">
        <img src={Logo} alt="Logo" className="h-[60px] w-[60px] z-50" />
        <div className="mt-[30px] ml-[-10px]">
          <Loader />
        </div>
        <p className="text-[15px] z-30 mt-10 text-center text-[#CBCBE8]">
          Updating password and logging you in...
        </p>
      </div>
    );
  }

  return (
    <div
      className="font-inter w-full !h-[100%] flex flex-col justify-center items-center max-w-[360px] m-auto max-[767px]:h-auto  max-[767px]:p-[20px]  max-[767px]:pt-[50px]  max-[767px]:max-w-[100%] signin-card"
      data-aos={fadeIn && "fade-in"}
      data-aos-duration={fadeIn && "2000"}
    >
      {signedIn && (
        <>
          <img src={Logo} alt="Logo" className="h-[56px] w-[56px]" />
          <h1 className="text-[28px] text-center text-[#F5F5F6] font-[600] mt-[22px] mb-[11px]">
            Forgot Password
          </h1>
          <p className="text-[15px] text-center text-[#CBCBE8BF]">
            Enter your email to reset your password.
          </p>
          <form
            className="w-full flex flex-col items-center"
            onSubmit={handleSubmit(handleEmailSubmit)}
          >
            <div className="mt-[30px] w-full">
              <p className="text-[14px] font-[500] text-[#CECFD2] mb-[5px]">
                Email
              </p>
              <div>
                <input
                  type="email"
                  placeholder="Enter your email"
                  className={`${inputClasses} !bg-[#0d0515] text-[#9e9ba0] focus-visible:!border-[#9e9ba0] ${
                    errors.email ? "mb-1" : "mb-[18px]"
                  } `}
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^\S+@\S+$/i,
                      message: "Invalid email address",
                    },
                  })}
                  onDrop={(e) => e.preventDefault()}
                  onDragOver={(e) => e.preventDefault()}
                />
              </div>
              {errors.email && (
                <p className="text-red-500 text-xs mb-3.5">
                  {errors.email.message}
                </p>
              )}
              <AnimatedButton
                title={
                  <div className="flex items-center justify-center">
                    {forgotPasswordMutation.isPending ? (
                      <div className="w-[20px] h-[20px] border-[3px] border-t-[3px] border-[#fff] border-solid rounded-full animate-spin border-t-[#8A43FF]" />
                    ) : (
                      "Submit"
                    )}
                  </div>
                }
                className="!text-[15px] !font-[600] !h-[42px] !rounded-[7px] !mb-[15px] !bg-[#635BFF]"
                fontSize="!text-[15px]"
              />
            </div>
            <div className="text-center flex items-center font-[400] text-[#94969C] mt-[20px]">
              <img src={backTo} alt="Logo" className="h-5 w-[64px] z-50" />
              <p
                className="font-[600] text-[#CECFD2] text-[13.2px] cursor-pointer ml-1"
                onClick={() => navigate("/")}
              >
                Log in
              </p>
            </div>
          </form>
        </>
      )}
      {isOtp && (
        <>
          <div className="h-[56px] w-[56px] flex items-center justify-center rounded-[8px] border border-[#333741]">
            <img src={messageIcon} alt="Logo" className="h-[60px] w-[60px]" />
          </div>
          <h1 className="text-[30px] text-center text-[#F5F5F6] font-[600] mt-[24px] mb-[12px]">
            Verification Code
          </h1>
          <p className="text-[16px] text-md font-[400] text-center text-[#94969C] leading-[24px]">
            Enter the code sent to your email to
          </p>
          <p className="text-[16px] font-[400] text-center text-[#94969C] leading-[24px]">
            reset your password.
          </p>
          <form
            className="mt-[22px] w-full"
            onSubmit={(e) => e.preventDefault()}
          >
            <div className="mb-[32px] flex items-center justify-center mr-[-20px]">
              <OTPInput
                value={OTP}
                onChange={setOTP}
                autoFocus
                OTPLength={6}
                otpType="number"
                disabled={false}
                inputClassName="otpInput !h-[56px] !px-[2px] text-[28px] !w-[40px] font-[500] border border-[#CBCBE84F] rounded-[6px] bg-transparent text-[#fff] !outline-none focus:!border-[#8A43FF] focus:!border-[2px]"
              />
            </div>
            <AnimatedButton
              title={
                <div className="flex items-center justify-center">
                  {loading ? (
                    <div className="w-[20px] h-[20px] border-[3px] border-t-[3px] border-[#fff] border-solid rounded-full animate-spin border-t-[#8A43FF]" />
                  ) : (
                    "Verify"
                  )}
                </div>
              }
              disabled={OTP.length !== 6}
              onClick={handleVerificationSubmit}
              className="!text-[15px] !font-[600] !h-[42px] !rounded-[7px] !mb-[32px] !bg-[#635BFF]"
              fontSize="!text-[15px]"
            />
          </form>
          {canResend ? (
            <p className="text-center font-[400] text-[14px] text-[#94969C]">
              Didn’t receive the email?
              <span
                className="font-[600] text-[13px] text-[#CECFD2] cursor-pointer ml-[4px] "
                onClick={handleEmailResend}
              >
                Click to resend
              </span>
            </p>
          ) : (
            <p className="text-center font-[400] text-[14px] text-[#94969C]">
              <span
                className="font-[600] text-[13px] text-[#CECFD2] cursor-pointer ml-[4px]"
                onClick={handleEmailResend}
              >
                {`Please Wait ${timer}s to resend verification code`}
              </span>
            </p>
          )}
        </>
      )}
      {isNewPassword && (
        <>
          <img src={Logo} alt="Logo" className="h-[56px] w-[56px]" />
          <h1 className="text-[28px] text-center text-[#F5F5F6] font-[600] mt-[22px] mb-[11px]">
            New Password
          </h1>
          <p className="text-[15px] text-center text-[#CBCBE8BF]">
            Enter a new password for Pipeline.
          </p>

          <div className="mt-[30px] w-full">
            <p className="text-[14px] font-[500] text-[#CECFD2] mb-[6px]">
              Password
            </p>
            <div className="relative">
              <Input
                // ref={passwordRef}
                type={passwordVisible ? "text" : "password"}
                placeholder={"Create a password"}
                className="password-input !bg-[#0d0515] text-[#9e9ba0] focus:outline-none focus-visible:!border-[#9e9ba0]"
                value={password}
                onChange={(e) => handlePasswordChange(e?.target?.value)}
                // onPaste
              />
              <div onClick={() => setPasswordVisible(!passwordVisible)}>
                {passwordVisible ? (
                  <FiEye
                    className="absolute right-[15px] z-50 top-[14px] cursor-pointer"
                    color="#CBCBE866"
                  />
                ) : (
                  <FiEyeOff
                    className="absolute right-[15px] z-50 top-[14px] cursor-pointer"
                    color="#CBCBE866"
                  />
                )}
              </div>
            </div>

            <p className="text-[14px] font-[500] text-[#CECFD2] mb-[6px]">
              Confirm Password
            </p>
            <div className="relative">
              <Input
                // ref={confirmPassword}
                type={confirmPasswordVisible ? "text" : "password"}
                placeholder={"Enter confirm password"}
                className="password-input !bg-[#0d0515] text-[#9e9ba0] focus:outline-none focus-visible:!border-[#9e9ba0]"
                value={confirmPassword}
                onChange={(e) => handleConfirmPasswordChange(e?.target?.value)}
                // onPaste
              />
              <div
                onClick={() =>
                  setConfirmPasswordVisible(!confirmPasswordVisible)
                }
              >
                {confirmPasswordVisible ? (
                  <FiEye
                    className="absolute right-[15px] z-50 top-[14px] cursor-pointer"
                    color="#CBCBE866"
                  />
                ) : (
                  <FiEyeOff
                    className="absolute right-[15px] z-50 top-[14px] cursor-pointer"
                    color="#CBCBE866"
                  />
                )}
              </div>
            </div>
            {error && (
              <p className="text-[12px] text-[#FF6B6B] -mt-[12px] mb-1.5">
                {error}
              </p>
            )}

            <div className="mb-[17px]">
              <div className="flex items-center mb-[8px]">
                <div>
                  {isMinLength ? (
                    <img
                      src={checkIcon}
                      alt="check"
                      className="w-[14px] h-[14px] mr-[6px]"
                    />
                  ) : (
                    <div className="h-[14px] w-[14px] rounded-full border border-[#3A1F66] mr-[6px]" />
                  )}
                </div>
                <p
                  className={`font-[400] text-[10px] leading-[14px] ${
                    isMinLength || password.length === 0
                      ? "text-[#FFFFFF]"
                      : "text-red-500"
                  }`}
                >
                  Must be at least 8 characters
                </p>
              </div>
              <div className="flex items-center mb-[8px]">
                <div>
                  {hasSpecialChar ? (
                    <img
                      src={checkIcon}
                      alt="check"
                      className="w-[14px] h-[14px] mr-[6px]"
                    />
                  ) : (
                    <div className="h-[14px] w-[14px] rounded-full border border-[#3A1F66] mr-[6px]" />
                  )}
                </div>
                <p
                  className={`font-[400] text-[10px] leading-[14px] ${
                    hasSpecialChar || password.length === 0
                      ? "text-[#FFFFFF]"
                      : "text-red-500"
                  }`}
                >
                  Must contain one special character
                </p>
              </div>
              <div className="flex items-center mb-[8px]">
                <div>
                  {hasLowercase ? (
                    <img
                      src={checkIcon}
                      alt="check"
                      className="w-[14px] h-[14px] mr-[6px]"
                    />
                  ) : (
                    <div className="h-[14px] w-[14px] rounded-full border border-[#3A1F66] mr-[6px]" />
                  )}
                </div>
                <p
                  className={`font-[400] text-[10px] leading-[14px] ${
                    hasLowercase || password.length === 0
                      ? "text-[#FFFFFF]"
                      : "text-red-500"
                  }`}
                >
                  Must contain one lowercase character
                </p>
              </div>
              <div className="flex items-center mb-[8px]">
                <div>
                  {hasUppercase ? (
                    <img
                      src={checkIcon}
                      alt="check"
                      className="w-[14px] h-[14px] mr-[6px]"
                    />
                  ) : (
                    <div className="h-[14px] w-[14px] rounded-full border border-[#3A1F66] mr-[6px]" />
                  )}
                </div>
                <p
                  className={`font-[400] text-[10px] leading-[14px] ${
                    hasUppercase || password.length === 0
                      ? "text-[#FFFFFF]"
                      : "text-red-500"
                  }`}
                >
                  Must contain one uppercase character
                </p>
              </div>
              <div className="flex items-center">
                <div>
                  {hasNumber ? (
                    <img
                      src={checkIcon}
                      alt="check"
                      className="w-[14px] h-[14px] mr-[6px]"
                    />
                  ) : (
                    <div className="h-[14px] w-[14px] rounded-full border border-[#3A1F66] mr-[6px]" />
                  )}
                </div>
                <p
                  className={`font-[400] text-[10px] leading-[14px] ${
                    hasNumber || password.length === 0
                      ? "text-[#FFFFFF]"
                      : "text-red-500"
                  }`}
                >
                  Must contain one number
                </p>
              </div>
            </div>

            <AnimatedButton
              title="Submit"
              onClick={handleCreatePassword}
              className="!text-[15px] !font-[600] !h-[42px] !rounded-[7px] !mb-[15px] !bg-[#635BFF]"
              fontSize="!text-[15px]"
              disabled={!!error}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ForgotPassword;
