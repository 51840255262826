import Button from "components/button";
import InAppIcon from "assets/images/in-app.png";

const StepInApp = ({ accountId, startVerificationInterval }) => {
  return (
    <div
      className="font-inter !h-full  flex flex-col justify-center items-center  m-auto max-[767px]:h-auto"
      data-aos="fade-in"
      data-aos-duration="2000"
    >
      <div className="!w-[480px] flex flex-col items-center relative signin-card ">
        <div className="w-[60px] h-[60px] flex items-center justify-center rounded-xl border border-[#333741] bg-[#0C111D] mb-[30px]">
          <img
            src={InAppIcon}
            alt="Logo"
            className="h-[24px] w-[20px] block"
            style={{
              filter: "brightness(1.2)",
            }}
          />
        </div>
        <h1 className="text-[30px] text-[#FFFFFF] font-[600] mb-[5px]">
          Open your LinkedIn App
        </h1>
        <p className="text-[16px] font-[400] text-[#A6A6A6] leading-[24px]">
          On your mobile device, open the app and verify.{" "}
        </p>
        <Button
          onClick={() => startVerificationInterval(accountId)}
          isFullWidth={false}
          className="!bg-[#635BFF] !border-[#635BFF] hover:!bg-transparent hover:!text-[#635BFF] hover:!border-[#635BFF] text-[#fff] !h-[44px] w-full !font-[600] text-[16px] rounded-[8px] !mt-[32px]"
          title={"Confirm"}
        />
      </div>
    </div>
  );
};

export default StepInApp;
