import Layout from "components/layout";
import BreadcrumbHeader from "components/breadcrumbHeader";
import {
  AiGenerateDefaultSteps,
  AiGenerateIsLikeAComment,
  AiGenerateIsLikeAPost,
  AiGenerateStepsIsSendConnectionRequest,
  AiGenerateStepsIsSendConnectionRequestIsFollowUp,
  AiGenerateStepsNotSendConnectionRequest,
  INITIAL_NEW_CAMPAIGN_STEPS,
  newCampaignTabs,
} from "constant";
import Tabs from "components/tabs";
import ChooseSteps from "components/newCampaign/choosesteps";
import { useContext, useEffect, useRef, useState } from "react";
import CurrentCampaign from "components/newCampaign/currentCampaignSteps";
import CampaignLeadsStep from "components/newCampaign/campaignLeadsStep";
import AnimatedButton from "components/animatedButton";
import { FaArrowRightLong } from "react-icons/fa6";
import InsertDropdown from "components/newCampaign/insertDropdown";
import Button from "components/button";
import resetIcon from "assets/images/reset-icon.svg";
import CampaignSettings from "components/newCampaign/campaignSettings";
import { toast } from "react-toastify";
import { AppContext } from "context/AppContext";
import listIcon from "assets/images/save-single-user.svg";
import ReviewTable from "components/newCampaign/reviewTable";
import { useNavigate } from "react-router-dom";
import ReviewSidePanel from "components/reviewSidePanel";
import deleteImg from "assets/images/trash.svg";
import Tick from "assets/images/name-check.svg";
import {
  useCampaignCreateSequence,
  useCampaignLeadRemove,
  useCampaignList,
  useCheckLeadStatus,
  useDeleteCampaignSequence,
  useEditCampaignSequences,
  useFetchCampaignLeads,
  useGetCampaignSequences,
  useGetCampaignSettings,
  useImportCampaignSequence,
  useLaunchCampaign,
} from "services/campaigns-api";
import { useAccountStore, useAuthStore, useCampaignStore } from "stores";
import {
  getRandomColor,
  getRandomCompanyLogo,
  getRandomProfileLogo,
  reverseTransformSequences,
  transformSequences,
} from "utils/index";
import { useGetLinkedinAccount } from "services/accounts-api";
import DeleteLeadModal from "components/prospecting/prospectingSettingModals/deleteLeadModal";
import ReconnectBanner from "components/reconnectBanner";

let toastId = null;

const NewCampaigns = () => {
  const { appState, setAppState } = useContext(AppContext);
  const {
    setCampaignId,
    campaignId,
    isDraftCampaignClicked,
    clearIsDraftCampaignClicked,
    trackerId,
    clearCampaignId,
    clearCampaignListId,
    clearTrackerId,
    setLeadInfo,
    setIsGetSettingsInLeadsTab,
    clearIsGetSettingsInLeadsTab,
    isGetSettingsInLeadsTab,
  } = useCampaignStore();
  const parentRef = useRef(null);
  const navigate = useNavigate();
  const { companyId } = useAuthStore();
  const getCampaignSequences = useGetCampaignSequences();
  const createSequencesAPI = useCampaignCreateSequence();
  const importCampaignSequence = useImportCampaignSequence();
  const updateCampaignSequences = useEditCampaignSequences();
  const deleteCampaignSequence = useDeleteCampaignSequence();

  const [tab, setTab] = useState("Leads");
  const [completedTabs, setCompletedTabs] = useState(["Leads"]);
  const [selectedOption, setSelectedOption] = useState();
  const [modalRandomGradient, setModalRandomGradient] = useState("");
  const [modalRandomCompanyLogo, setModalRandomCompanyLogo] = useState("");
  const [modalRandomProfileLogo, setModalRandomProfileLogo] = useState("");
  const getLinkedInAccountDetails = useGetLinkedinAccount();
  const { setLinkedinAccount, linkedinAccountDetails } = useAccountStore();
  const [
    handleFetchCurrentCampaignSequences,
    setHandleFetchCurrentCampaignSequences,
  ] = useState(false);
  const [selectedSteps, setSelectedSteps] = useState(
    INITIAL_NEW_CAMPAIGN_STEPS
  );
  const [isCheckingLeadsStatus, setIsCheckingLeadsStatus] = useState(false);
  const [isNewCampaignAdded, setIsNewCampaignAdded] = useState(false);
  const [isNewCampaignDeleted, setIsNewCampaignDeleted] = useState(true);
  const [rowData, setRowData] = useState([]);
  const [singleRowData, setSingleRowData] = useState(null);
  const [isAiIsGeneratingSteps, setIsAIisGeneratingSteps] = useState(false);
  const [settingsData, setSettings] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedLeadId, setSelectedLeadId] = useState();
  const [campaignLeadsData, setCampaignLeadsData] = useState([]);
  const [totalLeads, setTotalLeads] = useState();
  const [hasMore, setHasMore] = useState(true);
  const [paginationData, setPaginationData] = useState({
    limit: 20,
    page: 1,
  });

  const { refetch: refetchSettings } = useGetCampaignSettings({
    campaign_id: campaignId,
  });

  useEffect(() => {
    if (
      isDraftCampaignClicked === "launch_pending" ||
      isDraftCampaignClicked === "steps_pending" ||
      tab === "Review" ||
      isGetSettingsInLeadsTab
    ) {
      refetchSettings()?.then((res) => {
        const settingDataResponse = res?.data;
        setSettings(settingDataResponse);
        clearIsGetSettingsInLeadsTab();
      });
    }
  }, [isDraftCampaignClicked, campaignId, tab, refetchSettings]);

  useEffect(() => {
    // Define the function to clear the states
    const handleBeforeUnload = () => {
      clearIsDraftCampaignClicked();
      clearTrackerId();
      // clearCampaignId();
      setIsGetSettingsInLeadsTab(true);
      clearCampaignListId();
    };

    // Add the event listener for the 'beforeunload' event
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up the event listener on component unmount to avoid memory leaks
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [
    clearIsDraftCampaignClicked,
    clearTrackerId,
    clearCampaignId,
    clearCampaignListId,
  ]);

  let campaignParams = {
    campaign_id: campaignId,
    limit: paginationData?.limit,
    sort_by: "name",
    sort_order: "asc",
    page: paginationData?.page,
  };

  // Fetch campaign data using useQuery
  const {
    data: campaignData,
    isFetching,
    refetch,
  } = useFetchCampaignLeads(campaignParams, Boolean(campaignId));

  useEffect(() => {
    if (campaignData) {
      setLeadInfo(campaignData?.leads_available_info);
      if (paginationData.page === 1) {
        setCampaignLeadsData(campaignData?.leads_data);
      }
      setTotalLeads(campaignData?.pagination?.total_records);
    }
  }, [campaignData]);

  const fetchData = () => {
    if (totalLeads === campaignLeadsData?.length) {
      setHasMore(false);
      return;
    }
    setPaginationData((prev) => ({
      ...prev,
      limit: 20,
      page: (prev.page || 0) + 1,
    }));
  };

  useEffect(() => {
    if (paginationData.page > 1) {
      refetch()?.then((res) => {
        const newLeadsData = res?.data?.leads_data || [];
        setCampaignLeadsData((prev) => [...prev, ...newLeadsData]);
        if (
          newLeadsData.length === 0 ||
          newLeadsData.length < paginationData.limit
        ) {
          setHasMore(false);
        }
      });
    }
  }, [paginationData]);

  const campaignParamsStatus = {
    qeary: `campaign_id=${campaignId}${
      trackerId ? `&tracker_id=${trackerId}` : ""
    }`,
  };
  const { data: checkLeadStatus, refetch: refetchStatus } = useCheckLeadStatus(
    campaignParamsStatus,
    Boolean(campaignId) && tab === "Review" && !trackerId
  );

  useEffect(() => {
    if (campaignId && tab === "Review" && rowData?.length === 0) {
      const intervalId = setInterval(() => {
        refetchStatus().then((res) =>
          setIsCheckingLeadsStatus(res?.data?.prospected_status)
        );
      }, 5000); // 5 seconds in milliseconds

      // Clear the interval when component unmounts or when campaignId or tab changes
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [campaignId, tab, rowData]);

  useEffect(() => {
    if (
      (checkLeadStatus?.campaign_load_lead_status ||
        checkLeadStatus?.prospected_status) &&
      tab === "Review"
    ) {
      refetch();
    }
  }, [checkLeadStatus, tab]);

  useEffect(() => {
    if (campaignData?.leads_data?.length) {
      setRowData(campaignData?.leads_data);
    } else {
      setRowData([]);
    }
  }, [campaignData, checkLeadStatus]);

  const useLaunchCampaignApi = useLaunchCampaign();

  const handleGetLinkedInAccountDetails = async () => {
    try {
      const response = await getLinkedInAccountDetails.mutateAsync();
      setLinkedinAccount(response);
    } catch (error) {
      setLinkedinAccount({});
      console.log(error);
    }
  };

  useEffect(() => {
    if (tab === "Settings") {
      handleGetLinkedInAccountDetails();
    }
  }, [tab]);

  const handleGetSequences = async () => {
    try {
      const response = await getCampaignSequences.mutateAsync(campaignId);
      const sequences = reverseTransformSequences(response);
      if (sequences?.sequences?.length === 0) {
        setSelectedSteps(INITIAL_NEW_CAMPAIGN_STEPS);
      } else {
        setSelectedSteps([
          {
            title: "Start Campaign",
            type: "Start Campaign",
            icon: "",
            sequence_id: "",
            value: "",
          },
          ...sequences?.sequences,
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (campaignId && isDraftCampaignClicked?.length !== 0) {
      handleGetSequences();
    }
  }, [campaignId, isDraftCampaignClicked]);

  const addSequences = async (payload) => {
    try {
      const response = await createSequencesAPI?.mutateAsync(payload);

      if (response?.campaign_id) {
        setCampaignId(response.campaign_id);
      }
      setTab("Settings");
      setAppState((prev) => ({
        ...prev,
        stepsStepCompleted: true,
      }));
      setHandleFetchCurrentCampaignSequences(true);
      clearIsDraftCampaignClicked();
      setSelectedOption(INITIAL_NEW_CAMPAIGN_STEPS);
      if (!handleFetchCurrentCampaignSequences) {
      }
      handleGetSequences();
    } catch (error) {
      toast.success(error?.data?.detail);
      console.error("Error adding sequences:", error);
    }
  };

  const updateSequences = async (payload) => {
    try {
      const response = await updateCampaignSequences?.mutateAsync(payload);
      if (response?.campaign_id) {
        setCampaignId(response.campaign_id);
      }
      setTab("Settings");
      setAppState((prev) => ({
        ...prev,
        leadsStepCompleted: true,
        stepsStepCompleted: true,
      }));
      setHandleFetchCurrentCampaignSequences(true);
      clearIsDraftCampaignClicked();
      handleGetSequences();
    } catch (error) {
      toast.success(error?.data?.detail);
      console.error("Error updating sequences:", error);
    }
  };

  useEffect(() => {
    if (isDraftCampaignClicked?.length !== 0 && campaignId) {
      if (isDraftCampaignClicked === "steps_pending") {
        setCompletedTabs(["Leads", "Steps"]);
        setTab("Steps");
      } else if (isDraftCampaignClicked === "settings_pending") {
        setCompletedTabs(["Leads", "Steps", "Settings"]);
        setTab("Settings");
      } else if (isDraftCampaignClicked === "launch_pending") {
        setCompletedTabs(["Leads", "Steps", "Settings", "Review"]);
        setTab("Review");
      } else {
        setCompletedTabs(["Leads", "Steps"]);
        setTab("Steps");
      }
    } else {
      if (
        appState?.leadsStepCompleted &&
        !appState?.stepsStepCompleted &&
        !appState?.settingsStepCompleted
      ) {
        setCompletedTabs(["Leads", "Steps"]);
      }
      if (
        appState?.leadsStepCompleted &&
        appState?.stepsStepCompleted &&
        !appState?.settingsStepCompleted
      ) {
        setCompletedTabs(["Leads", "Steps", "Settings"]);
      } else if (
        appState?.leadsStepCompleted &&
        appState?.stepsStepCompleted &&
        appState?.settingsStepCompleted
      ) {
        setCompletedTabs(["Leads", "Steps", "Settings", "Review"]);
      }
    }
  }, [
    appState?.leadsStepCompleted,
    appState?.stepsStepCompleted,
    appState?.settingsStepCompleted,
    isDraftCampaignClicked,
  ]);

  const handleSelectStep = (step) => {
    const isActive = selectedSteps.some((i) => i?.title === step?.title);
    setSelectedSteps((prevSteps) => {
      if (step.title === "AI Generate Steps") {
        setIsAIisGeneratingSteps(true);
        handleAIGenerateSteps(prevSteps);
        return [...prevSteps, step];
      } else {
        const updatedSteps = handleOtherSteps(step, prevSteps);
        if (!isActive) {
          setIsNewCampaignDeleted(true);
          setIsNewCampaignAdded(true);
        }

        return updatedSteps;
      }
    });
  };

  const handleReset = () => {
    setSelectedSteps(INITIAL_NEW_CAMPAIGN_STEPS);

    setAppState((prev) => ({
      ...prev,
      isSendRequestCheckBoxChecked: true,
      sendConnectionRequestMessage: "",
      followUpMessage: "",
      existingConnectionMessage: "",
      selectedFollowUpMessageOptoin: null,
      selectedConnectionRequestMessageOption: null,
      existingConnectionMessageOption: null,
    }));
  };

  const handleStepValueChange = (newValue, index) => {
    if (index >= 1) {
      const updatedSteps = selectedSteps.map((item, ind) => {
        if (index === ind) {
          return {
            ...item,
            value: newValue,
          };
        }
        return item;
      });
      setSelectedSteps(updatedSteps);
    }
  };

  const handleAIGenerateSteps = (newStepsList) => {
    const isSendConnectionRequest = selectedSteps.some(
      (item) => item.title === "Send Connection Request"
    );
    const isFollowUpMessage = selectedSteps.some(
      (item) => item.title === "Follow-up Message"
    );
    const isWaitingMessage = selectedSteps.some(
      (item) => item?.title === "Waiting Period"
    );
    const isLikePost = selectedSteps.some(
      (item) => item.title === "Like a Post"
    );
    const isLikeComment = selectedSteps.some(
      (item) => item.title === "Like a Comment"
    );

    const getStepsWithDelays = () => {
      if (!isSendConnectionRequest) {
        return AiGenerateStepsNotSendConnectionRequest;
      } else if (isSendConnectionRequest && !isFollowUpMessage) {
        return AiGenerateStepsIsSendConnectionRequest;
      } else if (
        isSendConnectionRequest &&
        isFollowUpMessage &&
        !isLikePost &&
        !isLikeComment &&
        !isWaitingMessage
      ) {
        return AiGenerateStepsIsSendConnectionRequestIsFollowUp;
      } else if (
        isSendConnectionRequest &&
        isFollowUpMessage &&
        isLikePost &&
        !isLikeComment
      ) {
        return AiGenerateIsLikeAPost;
      } else if (
        isSendConnectionRequest &&
        isFollowUpMessage &&
        isLikeComment &&
        !isWaitingMessage
      ) {
        return AiGenerateIsLikeAComment;
      } else {
        return AiGenerateDefaultSteps;
      }
    };

    const stepsWithDelays = getStepsWithDelays();

    const addStepAfterDelay = (step, delay) => {
      return new Promise((resolve) => {
        setTimeout(() => {
          setSelectedSteps((prevSteps) => {
            if (
              !prevSteps.some(
                (existingStep) => existingStep.title === step.title
              )
            ) {
              return [...prevSteps, step];
            }
            return isSendConnectionRequest &&
              isFollowUpMessage &&
              !isLikePost &&
              !isLikeComment &&
              !isWaitingMessage
              ? [...prevSteps, step]
              : prevSteps;
          });
          setIsNewCampaignAdded(true);
          resolve();
        }, delay);
      });
    };

    const removeAIGenerateSteps = () => {
      setSelectedSteps((prevSteps) =>
        prevSteps.filter((step) => step.title !== "AI Generate Steps")
      );
      setIsAIisGeneratingSteps(false);
    };

    const addStepsSequentially = async () => {
      for (const step of stepsWithDelays) {
        await addStepAfterDelay(step, step.delay);
      }
      setIsNewCampaignDeleted(true);
      setTimeout(() => {
        setIsNewCampaignDeleted(false);
        removeAIGenerateSteps();
      }, 1000);
    };
    addStepsSequentially();
  };

  const handleOtherSteps = (step, newStepsList) => {
    const lastStep = newStepsList[newStepsList?.length - 1];
    const hasSendConnectionRequest = newStepsList.some(
      (s) => s?.title === "Send Connection Request"
    );
    const hasMessageExistingConnection = newStepsList.some(
      (s) => s?.title === "Message Existing Connection"
    );
    const hasLikePost = newStepsList.some((s) => s?.title === "Like a Post");
    const hasLikeComment = newStepsList.some(
      (s) => s?.title === "Like a Comment"
    );

    if (step?.title === "Follow-up Message" && !hasSendConnectionRequest) {
      toast.warning("Please add Send Connection Request step first");
      return newStepsList;
    }

    if (step?.title === "Follow-up Message" && hasSendConnectionRequest) {
      if (lastStep?.title === "Follow-up Message") {
        return [
          ...newStepsList,
          {
            title: "Waiting Period",
            type: "Waiting Period",
            icon: "",
            sequence_id: 2,
            value: "",
          },
          step,
        ];
      }
    }

    if (step?.title === "View Profile") {
      if (lastStep?.title === "Waiting Period") {
        return [...newStepsList, step];
      } else {
        return [
          ...newStepsList,
          {
            title: "Waiting Period",
            type: "Waiting Period",
            icon: "",
            sequence_id: 2,
            value: "",
          },
          step,
        ];
      }
    }

    if (
      (step?.title === "Send Connection Request" && hasSendConnectionRequest) ||
      (step?.title === "Message Existing Connection" &&
        hasMessageExistingConnection) ||
      (step?.title === "Waiting Period" && lastStep?.title === "Waiting Period")
    ) {
      return newStepsList;
    }

    if (step?.title === "Like a Post" && !hasLikePost) {
      return [...newStepsList, step];
    }

    if (step?.title === "Like a Comment" && !hasLikeComment) {
      return [...newStepsList, step];
    }

    return [...newStepsList, step];
  };

  useEffect(() => {
    if (parentRef && parentRef?.current && isNewCampaignAdded) {
      parentRef.current.scrollTo({
        top: parentRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
    setIsNewCampaignAdded(false);
  }, [isNewCampaignAdded]);

  const checkIfItemExistsWithParsedValue = (title, condition) => {
    return selectedSteps?.some((item) => {
      if (item?.title === title) {
        const value = item?.value || "";
        if (title === "Follow-up Message") {
          return condition(value); // For Follow-up Message, treat value as a normal string
        } else {
          const parsedValue = JSON.parse(value);
          return condition(parsedValue); // For other titles, parse the value as JSON
        }
      }
      return false;
    });
  };

  const handleDeleteSequence = async (sequenceId, updated) => {
    if (!sequenceId) {
      setSelectedSteps(updated);
      toast.success("Sequence deleted successfully", {
        icon: () => <img src={Tick} alt="name" />,
      });
      return;
    }
    try {
      const response = await deleteCampaignSequence?.mutateAsync({
        campaignId,
        sequenceId,
      });
      if (response) {
        setSelectedSteps(updated);
        toast.success("Sequence deleted successfully", {
          icon: () => <img src={Tick} alt="name" />,
        });
      }
    } catch (error) {
      toast.success(error?.data?.detail);
    }
  };

  const handleDeleteStep = (step, stepIndex) => {
    // Step 1: Create a copy of the array and remove the clicked step
    let updated = selectedSteps.filter((_, index) => index !== stepIndex);

    // Step 2: Determine the next target step based on the clicked step's title
    let nextStepIndex = -1;

    if (step.title === "View Profile") {
      // Find the index of the next "Waiting Period" after stepIndex
      nextStepIndex = updated.findIndex(
        (s, index) => s.title === "Waiting Period" && index >= stepIndex
      );
    } else if (step.title === "Waiting Period") {
      // Find the index of the next "View Profile" after stepIndex
      nextStepIndex = updated.findIndex(
        (s, index) => s.title === "View Profile" && index >= stepIndex
      );
    } else if (step?.title === "Send Connection Request") {
      // updated = updated?.filter((i)=>i?.title !== "Follow-up Message")
      if (updated.some((i) => i?.title === "Follow-up Message")) {
        toast.warning("You must delete your follow-up message first");
        return;
      }
      setAppState((prev) => ({
        ...prev,
        isSendRequestCheckBoxChecked: true,
        sendConnectionRequestMessage: "",
        selectedConnectionRequestMessageOption: null,
      }));
    } else if (step?.title === "Follow-up Message") {
      setAppState((prev) => ({
        ...prev,
        followUpMessage: "",
        selectedFollowUpMessageOptoin: null,
      }));
    } else if (step?.title === "Message Existing Connection") {
      setAppState((prev) => ({
        ...prev,
        existingConnectionMessage: "",
        existingConnectionMessageOption: null,
      }));
    }
    // Remove the next target step if it exists
    if (nextStepIndex !== -1) {
      const stepToRemoveIndex = updated.findIndex(
        (_, index) => index === nextStepIndex
      );
      if (stepToRemoveIndex !== -1) {
        // Remove both the current step and the next target step
        const finalUpdated = updated.filter(
          (_, index) => index !== stepIndex && index !== nextStepIndex
        );
        // Update the state with finalUpdated
        handleDeleteSequence(step?.id, finalUpdated);
      } else {
        // Update the state with updated
        handleDeleteSequence(step?.id, updated);
      }
    } else {
      // Update the state with updated
      handleDeleteSequence(step?.id, updated);
    }
    setIsNewCampaignDeleted(false);
  };

  const hasRequiredSteps = () => {
    const initialTitles = ["Start Campaign", "View Profile"];
    const requiredTitles = [
      "Send Connection Request",
      "Like a Post",
      "Like a Comment",
    ];
    if (selectedSteps?.length === 2) {
      return selectedSteps
        ?.map((item) => initialTitles.includes(item?.title))
        ?.every(Boolean);
    }
    return selectedSteps?.some((item) => requiredTitles.includes(item?.title));
  };

  const handleClickLaunch = () => {
    setRowData([]);
    const payload = {
      campaign_id: campaignId,
      sequences: [
        ...selectedSteps?.map((step, index) => {
          if (index > 0) {
            return {
              id: step?.id,
              order_number: index,
              sequence_id: step?.sequence_id,
              value: step?.value,
              sentiment_value: true,
            };
          }
          return null;
        }),
      ]?.filter(Boolean),
    };

    // Check if "Send Connection Request" exists with appropriate conditions
    const connectionRequestExists = checkIfItemExistsWithParsedValue(
      "Send Connection Request",
      (parsedValue) => {
        return parsedValue?.isSendRequestCheckBoxChecked === true;
      }
    );

    const connectionRequestMessage = checkIfItemExistsWithParsedValue(
      "Send Connection Request",
      (parsedValue) => parsedValue?.inputValue
    );

    const followUpMessageExists = checkIfItemExistsWithParsedValue(
      "Follow-up Message",
      (value) => value
    );
    const response = hasRequiredSteps();
    if (!response) {
      // Display notification if none of the required steps are present
      toast.warning(
        "Please add one of the following Steps to launch a campaign: Send a Connection Request, Like a Post, or Like a Comment."
      );
      return;
    } else {
      if (
        selectedSteps?.some(
          (item) =>
            item?.title === "Send Connection Request" ||
            item?.title === "Follow-up Message"
        )
      ) {
        if (
          connectionRequestExists &&
          !connectionRequestMessage &&
          !selectedSteps?.some((item) => item?.title === "Follow-up Message")
        ) {
          toast.warning("Please provide a connection request message");
        } else if (
          connectionRequestExists &&
          !connectionRequestMessage &&
          !selectedSteps?.some((item) => item?.title === "Follow-up Message")
        ) {
          toast.warning("Please provide a connection request message.");
        } else if (
          !connectionRequestExists &&
          selectedSteps?.some(
            (item) => item?.title === "Send Connection Request"
          ) &&
          selectedSteps?.some((item) => item?.title === "Follow-up Message") &&
          !followUpMessageExists
        ) {
          toast.warning("Please provide a follow-up message.");
        } else if (
          !connectionRequestExists &&
          selectedSteps?.some(
            (item) => item?.title === "Send Connection Request"
          ) &&
          selectedSteps?.some((item) => item?.title === "Follow-up Message") &&
          followUpMessageExists
        ) {
          if (isDraftCampaignClicked && campaignId) {
            updateSequences(transformSequences(payload));
          } else {
            if (handleFetchCurrentCampaignSequences) {
              updateSequences(transformSequences(payload));
            } else {
              addSequences(transformSequences(payload));
            }
          }
        } else if (
          connectionRequestExists &&
          connectionRequestMessage &&
          selectedSteps?.some((item) => item?.title === "Follow-up Message") &&
          !followUpMessageExists
        ) {
          toast.warning("Please provide a follow-up message.");
        } else if (
          connectionRequestExists &&
          !connectionRequestMessage &&
          selectedSteps?.some((item) => item?.title === "Follow-up Message") &&
          followUpMessageExists
        ) {
          toast.warning("Please provide a connection request message.");
        } else if (
          connectionRequestExists &&
          selectedSteps?.some((item) => item?.title === "Follow-up Message") &&
          !connectionRequestMessage &&
          !followUpMessageExists
        ) {
          toast.warning(
            "Please provide a connection request message and follow-up message."
          );
        } else {
          if (isDraftCampaignClicked && campaignId) {
            updateSequences(transformSequences(payload));
          } else {
            if (handleFetchCurrentCampaignSequences) {
              updateSequences(transformSequences(payload));
            } else {
              addSequences(transformSequences(payload));
            }
          }
        }
      } else {
        if (isDraftCampaignClicked && campaignId) {
          updateSequences(transformSequences(payload));
        } else {
          if (handleFetchCurrentCampaignSequences) {
            updateSequences(transformSequences(payload));
          } else {
            addSequences(transformSequences(payload));
          }
        }
      }
    }
  };

  useEffect(() => {
    if (selectedOption && selectedOption?.campaignSteps) {
      setSelectedSteps(selectedOption?.campaignSteps);
    }
  }, [selectedOption]);

  const deleteLead = useCampaignLeadRemove();

  const handleUndo = async (leadId) => {
    try {
      const response = await deleteLead.mutateAsync({
        campaign_id: campaignId,
        lead_id: leadId,
        remove_status: false,
      });
      refetch();
      toast.success("Undo successful", {
        icon: () => <img src={Tick} alt="name" />,
      });
    } catch (error) {
      console.log(`Error deleting lead ${leadId}:`, error);
    }
  };

  const handleRemoveReviewTableItems = async (leadId) => {
    try {
      const response = await deleteLead.mutateAsync({
        campaign_id: campaignId,
        lead_id: leadId,
        remove_status: true,
      });
      refetch();
      toastId = toast.success(
        <div className="flex justify-between w-full">
          Lead Removed
          <span
            onClick={() => {
              toast.dismiss(toastId);
              handleUndo(leadId);
            }}
            className="mr-[20px] cursor-pointer text-[#7F56D9] bg-none border-none p-0"
          >
            Undo
          </span>
        </div>,
        {
          icon: () => <img src={deleteImg} alt="name" />,
        }
      );
    } catch (error) {
      console.log(`Error deleting lead ${leadId}:`, error);
    }
  };

  const handleLaunch = async () => {
    const payload = {
      campaign_id: campaignId,
      company_id: companyId,
    };
    try {
      const response = await useLaunchCampaignApi.mutateAsync(payload);
      navigate("/campaign-steps");
      clearTrackerId();
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleDeleteLead = async () => {
    try {
      const response = await deleteLead.mutateAsync({
        campaign_id: campaignId,
        lead_id: selectedLeadId,
        remove_status: true,
      });
      if (response?.status === 200) {
        setCampaignLeadsData((prevData) =>
          prevData.filter((lead) => lead.lead_id !== selectedLeadId)
        );

        setHasMore((prevData) => prevData && totalLeads - 1 > prevData.length);
        toast.success("Lead Deleted", {
          icon: () => <img src={deleteImg} alt="name" />,
        });
      }
    } catch (error) {
      console.log(`Error deleting lead ${selectedLeadId}:`, error);
    }

    setSelectedLeadId();
    setIsDeleteModalOpen(false);
    setIsCheckingLeadsStatus(true);
  };

  const { data: campaignListData, refetch: reFetchCampaignList } =
    useCampaignList({
      limit: 20,
      sort_by: "name",
      sort_order: "asc",
      page: 1,
      type: "All",
    });

  useEffect(() => {
    reFetchCampaignList();
  }, [tab]);

  const handleImportCampaignSequence = async () => {
    if (selectedOption?.campaign_id) {
      try {
        const response = await importCampaignSequence.mutateAsync({
          campaign_id: selectedOption?.campaign_id,
        });

        const sequenceWithoutId = response?.sequences.map(
          ({ id, ...rest }) => rest
        );

        const data = {
          campaign_settings: response?.campaign_settings,
          accounts: response?.accounts,
          sequences: sequenceWithoutId,
        };

        const sequences = reverseTransformSequences(data);
        if (sequences?.sequences?.length === 0) {
          setSelectedSteps(INITIAL_NEW_CAMPAIGN_STEPS);
        } else {
          setSelectedSteps([
            {
              title: "Start Campaign",
              type: "Start Campaign",
              icon: "",
              sequence_id: "",
              value: "",
            },
            ...sequences?.sequences,
          ]);
        }
      } catch (error) {
        console.log(error, "err");
      }
    }
  };

  useEffect(() => {
    handleImportCampaignSequence();
  }, [selectedOption]);

  return (
    <Layout>
      <div className="h-[100vh] flex flex-col justify-start items-start font-inter">
        <BreadcrumbHeader
          breadcrumbItems={[
            { title: "Campaigns", route: "/Campaigns" },
            { title: "New Campaign" },
          ]}
        />
        <ReconnectBanner />
        <div
          className={`bg-[#FCFCFC] p-[10px] border-b-[1px] border-[#0000001A] w-full flex ${
            tab === "Steps" ? "justify-between" : "justify-end"
          } `}
        >
          {tab === "Steps" && (
            <InsertDropdown
              options={campaignListData && campaignListData?.campaign_data}
              initialName={
                selectedOption || {
                  campaign_name: "Import Campaign Steps",
                  value: "Import Campaign Steps",
                }
              }
              position="bottom"
              removeArrowLine
              setSelectedOption={setSelectedOption}
            />
          )}
          <Tabs
            tabOptions={newCampaignTabs}
            isNewCapmaignScreen={true}
            tab={tab}
            setTab={(t) => {
              setSelectedOption({
                campaign_name: "Import Campaign Steps",
                value: "Import Campaign Steps",
              });
              setTab(t);
            }}
            isNewCampaignTabs={true}
            completedTabs={completedTabs}
          />
        </div>
        <div
          className={`max-w-[100%] w-full h-[calc(100%_-100px)] ${
            tab === "Settings"
              ? "overflow-hidden overflow-y-auto"
              : "overflow-hidden"
          }`}
        >
          {tab === "Leads" && (
            <div className="flex items-center justify-center w-full h-full px-[31px] py-[18px]">
              <CampaignLeadsStep setTab={setTab} tab={tab} />
            </div>
          )}
          {tab === "Steps" && (
            <div
              className="h-full newcampaign-steps-bg overflow-auto innerScrollBar "
              ref={parentRef}
            >
              <div className="px-[31px] py-[18px]">
                <div className="w-[calc(100%_-300px)] flex items-start justify-center">
                  <CurrentCampaign
                    steps={selectedSteps}
                    handleDeleteStep={handleDeleteStep}
                    isNewCampaignDeleted={isNewCampaignDeleted}
                    handleStepValueChange={handleStepValueChange}
                    deleteCampaignSequence={deleteCampaignSequence}
                  />
                </div>

                <div
                  className={`fixed ${
                    linkedinAccountDetails?.reconnect_status
                      ? "top-[189px]"
                      : "top-[119px]"
                  } right-[24px]`}
                >
                  <ChooseSteps
                    onSelectStep={handleSelectStep}
                    selectedSteps={selectedSteps}
                  />
                </div>
                <div className="fixed bottom-[30px] left-[250px] z-[999]">
                  <Button
                    isWhiteBtn={true}
                    isFullWidth={false}
                    className="y-axis-shadow !rounded-[8px] !h-[32px]"
                    title={
                      <div className="flex items-center justify-center font-inter font-[500] text-[14px] !text-[#666666] !border-[#D1D5DB] mt-[-2px]">
                        <img
                          src={resetIcon}
                          alt="resetIcon"
                          className="mr-[8px] w-[14px] h-[14px]"
                        />
                        <p className="mt-[2px]">Reset</p>
                      </div>
                    }
                    onClick={() => handleReset()}
                  />
                </div>
                <div className="fixed bottom-[15px] right-[100px] z-40">
                  <AnimatedButton
                    onClick={handleClickLaunch}
                    disabled={
                      isAiIsGeneratingSteps || deleteCampaignSequence?.isPending
                    }
                    gradientBg={true}
                    title={
                      <div className="flex items-center">
                        Launch
                        <FaArrowRightLong className="text-white text-[20px] w-5 h-5 ml-[10px]" />
                      </div>
                    }
                    className="!text-[20px] !w-[129px] !h-[36px] !bg-[#7927FF] !border-[#7927FF] !rounded-[8px]"
                    fontSize="!font-inter !text-[18px] !font-[600]"
                  />
                </div>
              </div>
            </div>
          )}
          {/* settings */}
          {tab === "Settings" && (
            <div className="flex items-start mx-auto flex-col justify-start w-[1200px] h-full px-[31px] py-[27px]">
              <CampaignSettings
                setTab={setTab}
                settingsData={settingsData}
                refetchSettings={refetchSettings}
                setCompletedTabs={setCompletedTabs}
              />
            </div>
          )}
          {/* review */}
          {tab === "Review" && (
            <div className="flex items-start mx-auto flex-col justify-start w-[1200px] h-full px-[31px] py-[27px] table-scroll">
              <div className="flex items-center mb-[15px]">
                <div className="flex items-center justify-center border-[1px] border-[#E4E7EC] rounded-[10px] p-[11px] y-axis-shadow mr-[12px]">
                  <img
                    src={listIcon}
                    alt="info img"
                    className="h-[24px] w-[24px]"
                  />
                </div>
                <div>
                  <div className="flex items-center">
                    <h4 className="font-[600] text-[#101828] text-[15px]">
                      Review Leads
                    </h4>
                    {totalLeads?.length > 0 && (
                      <p className="ml-[5px] mt-[-1px] font-[600] pt-[3px] border border-[#D9D6FE] rounded-[6px] p-[2px] text-[#7A5AF8] text-[9px] bg-[#F4F3FF]">
                        {/* <img
                        src={addIcon}
                        alt="addIcon"
                        className="inline max-w-[7px] mr-[3px] pb-[1px]"
                      /> */}
                        <span className="mt-[10px] px-[3px]">{totalLeads}</span>
                      </p>
                    )}
                  </div>
                  <p className="font-[400] text-[#475467] text-[11.48px] mt-[3px]">
                    Review the leads and remove any that should not be enrolled.
                  </p>
                </div>
              </div>
              {!checkLeadStatus?.campaign_load_lead_status &&
              trackerId &&
              rowData?.length === 0 ? (
                <div className="flex items-center justify-center h-[85%] w-full">
                  <div className="bg-white p-[24px] flex flex-col items-center rounded-lg z-10 w-full max-w-[270px] relative">
                    <div className="enrich-animation-img mb-[20px]">
                      {/* enrich animation-image */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        height="54"
                        width="54"
                      >
                        <rect height="24" width="24"></rect>
                        <path
                          stroke-linecap="round"
                          stroke-width="1.5"
                          stroke="#000"
                          d="M19.25 9.25V5.25C19.25 4.42157 18.5784 3.75 17.75 3.75H6.25C5.42157 3.75 4.75 4.42157 4.75 5.25V18.75C4.75 19.5784 5.42157 20.25 6.25 20.25H12.25"
                          className="board"
                        ></path>
                        <path
                          d="M9.18748 11.5066C9.12305 11.3324 8.87677 11.3324 8.81234 11.5066L8.49165 12.3732C8.47139 12.428 8.42823 12.4711 8.37348 12.4914L7.50681 12.8121C7.33269 12.8765 7.33269 13.1228 7.50681 13.1872L8.37348 13.5079C8.42823 13.5282 8.47139 13.5714 8.49165 13.6261L8.81234 14.4928C8.87677 14.6669 9.12305 14.6669 9.18748 14.4928L9.50818 13.6261C9.52844 13.5714 9.5716 13.5282 9.62634 13.5079L10.493 13.1872C10.6671 13.1228 10.6671 12.8765 10.493 12.8121L9.62634 12.4914C9.5716 12.4711 9.52844 12.428 9.50818 12.3732L9.18748 11.5066Z"
                          className="star-2"
                        ></path>
                        <path
                          d="M11.7345 6.63394C11.654 6.41629 11.3461 6.41629 11.2656 6.63394L10.8647 7.71728C10.8394 7.78571 10.7855 7.83966 10.717 7.86498L9.6337 8.26585C9.41605 8.34639 9.41605 8.65424 9.6337 8.73478L10.717 9.13565C10.7855 9.16097 10.8394 9.21493 10.8647 9.28335L11.2656 10.3667C11.3461 10.5843 11.654 10.5843 11.7345 10.3667L12.1354 9.28335C12.1607 9.21493 12.2147 9.16097 12.2831 9.13565L13.3664 8.73478C13.5841 8.65424 13.5841 8.34639 13.3664 8.26585L12.2831 7.86498C12.2147 7.83966 12.1607 7.78571 12.1354 7.71728L11.7345 6.63394Z"
                          className="star-1"
                        ></path>
                        <path
                          className="stick"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                          stroke="#000"
                          d="M17 14L21.2929 18.2929C21.6834 18.6834 21.6834 19.3166 21.2929 19.7071L20.7071 20.2929C20.3166 20.6834 19.6834 20.6834 19.2929 20.2929L15 16M17 14L15.7071 12.7071C15.3166 12.3166 14.6834 12.3166 14.2929 12.7071L13.7071 13.2929C13.3166 13.6834 13.3166 14.3166 13.7071 14.7071L15 16M17 14L15 16"
                        ></path>
                      </svg>
                    </div>
                    <h4 className="font-[600] text-center text-[16.5px] text-[#101828] mb-1.5">
                      Enriching Leads
                    </h4>
                    <div className="max-w-[248px]">
                      <p className="font-normal text-center text-[12.8px] leading-[18px] text-[#475467] ">
                        This may take a few minutes…
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <ReviewTable
                  handleRemoveReviewTableItems={handleRemoveReviewTableItems}
                  rowData={campaignLeadsData}
                  rowClick={(item) => {
                    setSingleRowData(item);
                    setModalRandomGradient(getRandomColor());
                    setModalRandomCompanyLogo(getRandomCompanyLogo());
                    setModalRandomProfileLogo(getRandomProfileLogo());
                  }}
                  setIsDeleteModalOpen={setIsDeleteModalOpen}
                  setSelectedLeadId={setSelectedLeadId}
                  fetchData={fetchData}
                  hasMore={hasMore}
                  totalLeads={totalLeads}
                />
              )}
              <div className="fixed bottom-[15px] right-[100px] z-[999]">
                <AnimatedButton
                  // onClick={() => navigate("/campaign-steps")}
                  onClick={handleLaunch}
                  gradientBg={true}
                  title={
                    <div className="flex items-center">
                      {useLaunchCampaignApi?.isPending ? (
                        <div className="w-[20px] h-[20px] border-[3px] border-t-[3px] border-[#fff] border-solid rounded-full animate-spin border-t-[#8A43FF]" />
                      ) : (
                        <>
                          Launch
                          <FaArrowRightLong className="text-white text-[20px] w-5 h-5 ml-[10px]" />
                        </>
                      )}
                    </div>
                  }
                  className="!text-[20px] !w-[129px] !h-[36px] !bg-[#7927FF] !border-[#7927FF] !rounded-[8px]"
                  fontSize="!font-inter !text-[18px] !font-[600]"
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="modal-scroll">
        <ReviewSidePanel
          rowData={singleRowData}
          setRowData={setSingleRowData}
          randomGradient={modalRandomGradient}
          profileLogo={modalRandomProfileLogo}
          companyLogo={modalRandomCompanyLogo}
          page="in-page"
        />
        <DeleteLeadModal
          isOpen={isDeleteModalOpen}
          onClose={() => {
            setIsDeleteModalOpen(false);
          }}
          accountManager={true}
          confirmDelete={() => handleDeleteLead()}
          loading={deleteLead?.isPending}
          setIsCheckingLeadsStatus={setIsCheckingLeadsStatus}
        />
      </div>
    </Layout>
  );
};

export default NewCampaigns;
