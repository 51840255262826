import { FaArrowRightLong } from "react-icons/fa6";

const AnimatedButton = (props) => {
  const {
    title,
    onClick,
    isWhiteBtn,
    isRounded,
    isFullWidth = true,
    height,
    className,
    primaryTextHover = false,
    fontSize,
    disabled,
    disabledAnimation,
    gradientBg
  } = props;
  return (
    <>
      <button
        className={`relative inline-flex items-center justify-center overflow-hidden rounded-[4px] px-[12px] outline-none font-[600] transition duration-[600ms] ease-out group 
       ${height ? "h-[32px]" : "h-[42px]"}
       ${gradientBg ? "bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-b": "border-2 border-primary bg-primary"}
       ${
         isWhiteBtn &&
         "bg-white !border-[#DDE2E4] !border-[1px] !text-[#252C32] py-[4px] font-normal"
       }
       ${isRounded && "rounded-[6px]"}
        ${isFullWidth && "w-full"}
        ${className} ${disabled ? "opacity-60" : "opacity-100"}
        ${disabledAnimation && "cursor-not-allowed"}`}
        onClick={onClick}
        disabled={disabled}
      >
        {!disabledAnimation && <span
          className={` ${className} absolute inset-0 flex items-center justify-center w-full h-full text-white delay-100 duration-[600ms] -translate-x-full bg-primary group-hover:translate-x-0 ease
           ${gradientBg ? "bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-b": "bg-primary"}`}
        >
          <FaArrowRightLong className="text-white text-[25px] w-6 h-6" />
        </span>}
        <span
          className={`font-[600] !p-0 !m-0 text-[14px] text-white absolute flex items-center justify-center w-full h-full transition-all delay-100 duration-[600ms] transform ${!disabledAnimation && "group-hover:translate-x-full"} ease
          ${isWhiteBtn && "bg-white !text-[#252C32] py-[4px] font-normal"}
          ${
            primaryTextHover && "hover:!text-primary group-hover:!text-primary "
          } ${fontSize}`}
        >
          <span className="block mt-[-1px]">{title}</span>
        </span>
      </button>
    </>
  );
};

export default AnimatedButton;
