import Layout from "components/layout";
import BreadcrumbHeader from "components/breadcrumbHeader";
import DashBoardCards from "components/dashboard/dashBoardCards";
import WelcomeBanner from "components/dashboard/welcomeBanner";
import { dashboardGetStartedLink } from "constant";
import handCuffs from "assets/images/hand-cuffs.svg";
import redirectArrow from "assets/images/redirect-arrow.svg";
import UserVisitingModal from "components/dashboard/userVisitingModal";
import { useEffect, useState } from "react";
import { useGetWorkspaceSettings } from "services/settings-api";
import useSettingStore from "stores/settingStore";
import { useAuthStore } from "stores";
import { getRandomProfileLogo, getTimezoneObject } from "utils/index";
import useVisitorModalStore from "stores/visitorModalStore";
import { useGetDashboardActivity } from "services/dashboard-api";
import ReconnectBanner from "components/reconnectBanner";

const Dashboard = () => {
  const { setIsConnectLinkedIn } = useVisitorModalStore();
  const { setUserData, userData } = useSettingStore();
  const { userDetails, isAdmin } = useAuthStore();
  const getSettings = useGetWorkspaceSettings();
  const getDashboardActivity = useGetDashboardActivity();

  const isSignFlowExist = localStorage?.getItem("isSignUp");
  const [isUserVisitingModal, setIsUserVisitingModal] = useState(true);
  const [timeFrame, setTimeFrame] = useState("24 hours");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activityData, setActivityData] = useState([]);

  const getUserProfile = async () => {
    try {
      const response = await getSettings.mutateAsync();
      const responseMain = {
        ...userData,
        ...response,
        user: {
          ...response?.user,
          profile_pic:
            isAdmin || response?.user?.profile_pic
              ? response?.user?.profile_pic || getRandomProfileLogo?.()?.src
              : "",
        },
        company: {
          ...response?.company,
          // localTimezone: getTimezoneObject(response?.company?.timezone)?.value,
        },
      };
      setUserData(responseMain);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  const getActivity = async () => {
    try {
      const response = await getDashboardActivity.mutateAsync({ timeFrame });
      const modifiedArray = [
        { name: "Invites Sent", value: response?.invites_sent || 0 },
        {
          name: "New Connections",
          value: response?.invites_accepted || 0,
        },
        { name: "Follow-ups", value: response?.followup_sent || 0 },
        { name: "Profiles Viewed", value: response?.profile_viewed || 0 },
        { name: "Liked Posts", value: response?.liked_posts || 0 },
        { name: "Liked Comments", value: response?.liked_comments || 0 },
      ];
      setActivityData(modifiedArray);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getActivity();
  }, [timeFrame]);

  const handleTimeFrameChange = (newTimeFrame) => {
    setTimeFrame(newTimeFrame);
    setIsDropdownOpen(false);
  };

  return (
    <Layout>
      <div className="h-[100vh] flex flex-col justify-start items-start font-inter relative z-20">
        <BreadcrumbHeader
          breadcrumbItems={[{ title: "", route: "" }]}
          blackTheme={true}
        />
        <ReconnectBanner />
        <div
          className="max-w-[100%] w-full px-[22px] py-[12px] overflow-auto h-[calc(100vh_-45px)] flex flex-col innerScrollBar"
          data-aos="fade-left"
        >
          <div className="flex">
            <h1 className="text-[32px] text-left text-[#D8D8D8] font-semibold leading-normal mb-[8px] capitalize">
              Hi, {userDetails?.name}!
            </h1>
            <div className="w-[120px] h-[28px] bg-[#57534E3D] rounded-full ml-4 mt-2.5 px-[5px]">
              <button
                className={`w-[50%] h-5 text-[10.5px] font-medium text-center ${
                  timeFrame === "24 hours"
                    ? "bg-white/10 border border-[#E2E2E212] rounded-full text-white"
                    : "text-white/40"
                }`}
                onClick={() => handleTimeFrameChange("24 hours")}
              >
                24hrs
              </button>
              <button
                className={`w-[50%] h-5 text-[10.5px] font-medium text-center ${
                  timeFrame !== "24 hours"
                    ? "bg-white/10 border border-[#E2E2E212] rounded-full text-white"
                    : "text-white/40"
                }`}
                onClick={() => handleTimeFrameChange("all_time")}
              >
                All Time
              </button>
            </div>
          </div>
          <div className="px-[56px] mt-[35px]">
            <DashBoardCards
              getActivity={getActivity}
              handleTimeFrameChange={handleTimeFrameChange}
              timeFrame={timeFrame}
              activityData={activityData}
              isLoading={getDashboardActivity?.isPending}
              isDropdownOpen={isDropdownOpen}
              setIsDropdownOpen={setIsDropdownOpen}
            />
            <div className="mt-[24px] flex items-start">
              <div className="w-[596px] mr-[47px]">
                <WelcomeBanner />
              </div>
              <div className="">
                <p className="font-[500] text-[#D8D8D8] text-[12px] mb-0">
                  Get Started
                </p>
                {dashboardGetStartedLink?.map((list, index) => {
                  return (
                    <div
                      key={index}
                      className="dashboard-gradient-buton cursor-pointer group flex items-center justify-between mt-[14px] w-[276px] rounded-[11.2px] py-[8px] px-[14px] border-[1px] border-[#E4E7EC38] transition-all duration-75 ease-in-out"
                      style={{ boxShadow: "0 0.94px 1.87px 0 #1018280D" }}
                    >
                      <a className="flex items-center no-underline" href={list?.link} target="_blank">
                        <div className="group-hover:bg-[#7517F8] flex items-center justify-center w-[30.8px] h-[31px] rounded-[7.75px] bg-[#121212] mr-[12px]">
                          <img
                            src={list?.icon}
                            className="w-[18px] h-[18px] ml-[1px]"
                            alt="list icons"
                          />
                        </div>
                        <p className="font-[500] text-[15px] leading-[18.7px] text-[#D8D8D8] m-0">
                          {list?.title}
                        </p>
                      </a>
                      <img
                        src={list?.actionIcon}
                        className="block w-[18px]"
                        alt="list actionIcon"
                      />
                    </div>
                  );
                })}
                {/* Read our Latest Article chip */}
                <div className="flex items-center mt-[17px]">
                  <div
                    className="dashboard-gradient-buton cursor-pointer group flex items-center justify-between rounded-[12px] py-[14px] px-[19px]  transition-all duration-75 ease-in-out h-[48px] border-[1px] border-[#E4E7EC38]"
                    style={{ boxShadow: "0 0.94px 1.87px 0 #1018280D" }}
                  >
                    <div className="flex items-center">
                      <p className="font-[500] text-[15px] leading-[18.7px] text-[#D8D8D8] m-0">
                        Read our Latest Article:
                      </p>
                      <div className="flex items-center ml-[11px]">
                        <img
                          src={handCuffs}
                          className="block w-[23px] h-[23px] mr-[7px]"
                          alt="list actionIcon"
                        />
                        <a
                          href="/dashboard"
                          className="text-[#9C72FF] font-[700] text-[13.1px] underline group-hover:text-[#D8D8D8]"
                        >
                          How to Avoid LinkedIn Jail
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-center w-[31px] h-[31px] rounded-[7.75px] bg-[#121212] ml-[11px]">
                    <img
                      src={redirectArrow}
                      className="w-[18.6px] h-[18.6px] mt-[-1px]"
                      alt="redirectArrow"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isSignFlowExist && (
        <UserVisitingModal
          isOpen={isUserVisitingModal}
          onClose={() => {
            setIsUserVisitingModal(false);
            setIsConnectLinkedIn(false);
            localStorage.removeItem("isSignUp");
            localStorage.removeItem("isSkipSignUp");
          }}
        />
      )}
    </Layout>
  );
};
export default Dashboard;
