import { useState, useEffect, useRef, useContext } from "react";
import ReactSelect from "components/select";
import starIcon from "assets/images/light-stars.svg";
import { CAMPAIGN_LEAD_TOGGLELIST } from "constant";
import OutlinedInput from "components/outlinedInput";
import SwitchToggle from "components/switchToggle";
import Button from "components/button";
import { toast } from "react-toastify";
import { AppContext } from "context/AppContext";
import MagicWand from "assets/images/magic-wand-02.svg";
import errorIcon from "assets/images/error.svg";
import sadIcon from "assets/images/sad-emoji.svg";
import { useCreateCampaignLeads } from "services/campaigns-api";
import { useGetProspectsList } from "services/prospecting-api";
import userIcon from "assets/images/user.svg";
import { useAccountStore, useAuthStore } from "stores";
import useCampaignStore from "stores/campaignStore";
import { useWorkSpaceCreditLimit } from "services/accounts-api";
import { formatNumberWithCommas } from "utils/index";

const CampaignLeadsStep = (props) => {
  const { companyId } = useAuthStore();
  const getProspectsList = useGetProspectsList();
  const createLeadsMutation = useCreateCampaignLeads();
  const { workspaceCreditLimit, setWorkSpaceCreditLimit } = useAccountStore();
  const { setCampaignId, setTrackerId, draftListId, setDraftListId } =
    useCampaignStore();
  const { setTab, tab } = props;
  const { setCampaignListId } = useCampaignStore();
  const { setAppState } = useContext(AppContext);
  const [refetchCredits, setRefectchCredits] = useState(false);
  const { data, refetch } = useWorkSpaceCreditLimit({
    isEnabled: refetchCredits,
  });
  const [prospectsList, setProspectsList] = useState([]);
  const [prospectsTableData, setProspectsTableData] = useState();
  const [selectedLeadList, setSelectedLeadList] = useState(null);
  const [numberOfLeadsToEnroll, setNumberOfLeadsToEnroll] = useState("");
  const [containerHeight, setContainerHeight] = useState("auto");
  const [expand, setExpand] = useState(false);
  const [activeLeadListSteps, setActiveLeadListSteps] = useState([0]);
  const [errorMessage, setErrorMessage] = useState("");
  const [showLeadsCount, setShowLeadCount] = useState(false);
  const [filterLead, setFilterLead] = useState("exclude_all_duplicates");

  const containerRef = useRef(null);

  const fetchProspectsList = async () => {
    try {
      const response = await getProspectsList.mutateAsync({
        limit: 100,
        page: 1,
        sort_by: "name",
        sort_order: "desc",
        company_id: companyId,
      });
      setProspectsTableData(response?.data);
      if (response?.data) {
        const data = response?.data
          ?.filter(
            (item) =>
              item?.prospected_type !== "Imported" ||
              item?.total_enriched_leads > 0
          )
          ?.map((item) => ({
            imageUrl: userIcon,
            value: item?.prospect_id,
            label: item?.prospect_name,
            leadsAvailable: item?.total_available_leads,
          }));
        setProspectsList(data);
      }
    } catch (error) {
      console.error("Failed to fetch prospects list", error);
    }
  };

  useEffect(() => {
    if (tab === "Leads") {
      fetchProspectsList();
    }
  }, [tab]);

  useEffect(() => {
    if (draftListId && prospectsTableData?.length !== 0) {
      const selectedProspect = prospectsTableData?.find(
        (item) => item?.prospect_id === draftListId
      );
      if (selectedProspect && selectedProspect?.is_shared === true) {
        handleToggleExpand();
      } else {
        setExpand(false);
      }

      setSelectedLeadList({
        imageUrl: userIcon,
        value: selectedProspect?.prospect_id,
        label: selectedProspect?.prospect_name,
        leadsAvailable: selectedProspect?.total_available_leads,
      });
    }
  }, [draftListId, prospectsTableData]);

  useEffect(() => {
    if (data?.credit_limit) {
      setWorkSpaceCreditLimit(data?.credit_limit);
    }
  }, [data]);

  useEffect(() => {
    if (containerRef.current) {
      const scrollHeight = containerRef.current.scrollHeight;
      setContainerHeight(expand ? `${scrollHeight}px` : "70px");
    }
  }, [expand, CAMPAIGN_LEAD_TOGGLELIST]);

  const handleSelectChange = (option) => {
    setSelectedLeadList(option);

    const selectedProspect = prospectsTableData?.find(
      (item) => item?.prospect_id === option?.value
    );

    if (selectedProspect?.is_shared === true) {
      handleToggleExpand();
    } else {
      setExpand(false);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    const sanitizedValue = value.replace(/[^0-9]/g, "");
    setNumberOfLeadsToEnroll(sanitizedValue?.replace(/^0+(?!$)/, ""));

    if (sanitizedValue === "") {
      setAppState((prev) => ({
        ...prev,
        numberOfLeadsToEnroll: "",
      }));
      setErrorMessage("");
      return;
    }

    const formattedValue = sanitizedValue.replace(/^0+(?!$)/, "");
    const numericValue = parseInt(formattedValue, 10);

    if (!isNaN(numericValue) && numericValue >= 0) {
      if (numericValue > Number(workspaceCreditLimit)) {
        // Show "You do not have enough credits." toast for values between 99 and 235
        setErrorMessage("You don't have enough credits.");
        toast.success("You don't have enough credits.", {
          icon: () => <img src={sadIcon} alt="name" />,
        });
        setShowLeadCount(true);
      } else if (numericValue > selectedLeadList?.leadsAvailable) {
        // Show "You do not have enough credits." toast for values between 99 and 235
        setErrorMessage("Please enter a smaller amount.");
        toast.success("This lead list does not have enough leads", {
          icon: () => <img src={sadIcon} alt="name" />,
        });
        setShowLeadCount(true);
      } else {
        setErrorMessage("");
      }
      setAppState((prev) => ({
        ...prev,
        numberOfLeadsToEnroll: formattedValue,
      }));
    }
  };

  const handleToggleExpand = () => {
    setExpand(true);
  };

  const onToggleChange = (id) => {
    setActiveLeadListSteps((prev) => {
      const isActive = prev?.includes(id);
      return isActive ? prev?.filter((item) => item !== id) : [id];
    });

    getFilterLeads(id);
  };

  const getFilterLeads = (value) => {
    switch (value) {
      case 0:
        setFilterLead("exclude_all_duplicates");
        break;
      case 1:
        setFilterLead("include_duplicates_from_my_team");
        break;
      case 2:
        setFilterLead("include_all_duplicates");
        break;
      default:
        setFilterLead("");
    }
  };

  const handleNext = async () => {
    if (draftListId) {
      setTab("Steps");
      return;
    }
    if (selectedLeadList && numberOfLeadsToEnroll) {
      setCampaignListId(selectedLeadList?.value);
      try {
        const payload = {
          prospect_id: selectedLeadList?.value,
          max_profiles: Number(numberOfLeadsToEnroll),
          filter_leads: filterLead,
          tracker_id: null,
        };
        const response = await createLeadsMutation?.mutateAsync(payload);
        if (response?.campaign_id) {
          setAppState((prev) => ({
            ...prev,
            leadsStepCompleted: true,
          }));
          setTab("Steps");
          setRefectchCredits(true);
          setCampaignId(response?.campaign_id);
          setDraftListId(selectedLeadList?.value);
          setTrackerId(response?.tracker_id);
          refetch();
        }
      } catch (error) {
        toast.error(
          error?.data?.detail || "Failed to enroll leads. Please try again."
        );
        console.log(error, "error");
      }
    } else if (!selectedLeadList) {
      toast.error("No Lead Lists Found");
    } else {
      toast.error("Please enter a number of leads");
    }
  };

  const onKeyDown = (e) => {
    if (e?.key === "Tab") {
      e?.preventDefault();
    }
  };

  return (
    <div className="w-[400px] bg-[#fff] rounded-[12px] common-y-shadow font-inter">
      <div className="p-[24px]">
        <div className="flex items-center justify-center border-[1px] border-[#E4E7EC] rounded-[10px] p-[11px] mb-[16px] y-axis-shadow w-[48px] h-[48px] mx-auto">
          <img src={starIcon} alt="info img" className="h-[24px] w-[24px]" />
        </div>
        <h4 className="font-[600] text-[18px] text-[#101828] m-0 font-inter text-center">
          Select a Lead List
        </h4>
        <div
          className={`my-[20px] hubspot-select newCampaign-select ${
            draftListId ? "opacity-50" : "opacity-100"
          }`}
        >
          <ReactSelect
            options={prospectsList}
            placeholder="Choose List"
            isNewCampaignSelect={true}
            onChange={handleSelectChange}
            value={selectedLeadList}
            placeholderImage
            readOnly={draftListId}
            isLoading={getProspectsList?.isPending}
          />
        </div>
        <p className="font-[600] text-[18px] text-[#101828] mb-[10px] font-inter text-center">
          How many leads do you want to enroll?
        </p>
        <div className="relative flex items-center mb-[7px]">
          <OutlinedInput
            disabled={draftListId}
            placeholder={
              draftListId ? "You can't update the lead count." : "e.g 50"
            }
            className={`!text-[#667085] pr-[20px] y-axis-shadow outline-input-campaign ${
              errorMessage && "!border-[#FF1707]"
            }`}
            value={numberOfLeadsToEnroll}
            onChange={handleInputChange}
            onKeyDown={onKeyDown}
          />
          {errorMessage && (
            <img
              src={errorIcon}
              alt="error"
              className="w-[16px] h-[16px] absolute right-[13px] top-[13px]"
            />
          )}
        </div>
        {errorMessage && (
          <p className="text-[#FF1707] text-[10.6px] font-[600]">
            {errorMessage}
          </p>
        )}
        {selectedLeadList?.leadType === "Imported" ? (
          <>
            <p className="text-[#475467] font-[400] text-[14px] leading-[20px] mt-[20px]">
              Lead Count: <span>100</span>
            </p>
            <div className="flex">
              <img src={MagicWand} alt="" className="mt-[-12px]" />
              <p className="text-[#7F56D9] font-[400] text-[14px] leading-[20px] ml-[5px] mt-[8px] mb-[20px]">
                All leads are enriched
              </p>
            </div>
          </>
        ) : (
          <>
            {selectedLeadList && (
              <p
                className={`text-[#475467] font-[400] text-[14px] leading-[20px] mt-[7px]`}
              >
                Leads Available:{" "}
                <span>
                  {formatNumberWithCommas(selectedLeadList?.leadsAvailable)}
                </span>
              </p>
            )}
          </>
        )}
        <div
          ref={containerRef}
          className="toggle-list-container mt-[22px]"
          style={{
            maxHeight: containerHeight,
            overflow: "hidden",
            transition: "max-height 0.3s ease-out",
          }}
        >
          <div className="flex items-start w-full mb-[10px] newcampaign-toggle">
            <div className={`mr-[8px] ${draftListId ? "opacity-50" : ""}`}>
              <SwitchToggle
                checked={activeLeadListSteps?.includes(0)}
                onChange={() => onToggleChange(0)}
                onKeyDown={onKeyDown}
                disabled={draftListId}
              />
            </div>
            <div>
              <h5 className="font-[500] text-[#344054] text-[14px] leading-[20px] font-inter">
                Ignore leads enrolled in other campaigns
              </h5>
              <p className="font-[400] text-[#9C9EA0] text-[14px] leading-[20px] font-inter">
                If a lead has been enrolled in a campaign by you or a teammate,
                exclude it.
              </p>
            </div>
          </div>
          {CAMPAIGN_LEAD_TOGGLELIST?.map((item, index) => {
            return (
              <div
                key={index}
                className="flex items-start w-full mb-[10px] newcampaign-toggle"
              >
                <div className="mr-[8px]">
                  <SwitchToggle
                    checked={activeLeadListSteps?.includes(index + 1)}
                    onChange={() => onToggleChange(index + 1)}
                    onKeyDown={onKeyDown}
                    disabled={draftListId}
                  />
                </div>
                <div>
                  <h5 className="font-[500] text-[#344054] text-[14px] leading-[20px] font-inter">
                    {item?.title}
                  </h5>
                  <p className="font-[400] text-[#9C9EA0] text-[14px] leading-[20px] font-inter">
                    {item?.description}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="p-[16px] pt-0">
        <Button
          title={
            <>
              <div className="flex items-center justify-center">
                {createLeadsMutation?.isPending ? (
                  <div className="w-[20px] h-[20px] border-[3px] border-t-[3px] border-[#fff] border-solid rounded-full animate-spin border-t-[#8A43FF]" />
                ) : (
                  "Next"
                )}
              </div>
            </>
          }
          disabled={errorMessage}
          secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9]"
          className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow !rounded-[8px]"
          onClick={() => handleNext()}
        />
      </div>
    </div>
  );
};

export default CampaignLeadsStep;
