import { useEffect, useRef, useState } from "react";
import copyIcon from "assets/images/copy-icon.svg";
import connectIcon from "assets/images/connect-rb2g.svg";
import OutlinedInput from "components/outlinedInput";
import { IoClose } from "react-icons/io5";
import Button from "components/button";
import { useRb2bEnrichLeads } from "services/rb2b-api";
import Webhooks from "assets/images/webhook-pipeline.svg";
import PlusIcon from "assets/images/plus-icon.svg";
import DeleteIcon from "assets/images/trash-02.svg";

const WebhookLists = ({
  isOpen,
  onClose,
  setStep,
  webHookURL,
  isLoading,
  setProspectId,
  setIsDeleteWebhookOpen,
  webhooks,
  enrichWebhook,
}) => {
  const [copiedTooltipVisible, setCopiedTooltipVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentCopyURLIndex, setCurrentCopyURLIndex] = useState();

  const webhookListRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [isOpen]);

  useEffect(() => {
    let timer;
    if (copiedTooltipVisible) {
      timer = setTimeout(() => {
        setCopiedTooltipVisible(false);
      }, 800);
    }
    return () => clearTimeout(timer);
  }, [copiedTooltipVisible]);

  const handleCopy = (index, url) => {
    setCurrentCopyURLIndex(index);
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setCopiedTooltipVisible(true);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handleNext = async () => {
    try {
      const response = await enrichWebhook.mutateAsync();
      if (response?.status === 200) {
        setProspectId(response?.prospects_id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (webhookListRef && webhookListRef?.current) {
      webhookListRef.current.scrollTop = webhookListRef.current.scrollHeight;
    }
  }, [webhookListRef]);

  if (!isOpen && !isModalVisible) return null;

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[400ms] ease-in-out ${
        isModalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className="fixed inset-0 bg-black opacity-10"
        onClick={onClose}
      ></div>
      <div
        className={`bg-white pt-[24px] rounded-lg shadow-lg z-10 w-full max-w-[426px] relative transition-all ease-in-out duration-[150ms] ${
          isModalVisible ? "scale-100" : "scale-95"
        }`}
      >
        <IoClose
          className="text-[#667085] text-[20px] w-[24px] h-[24px] absolute right-[15px] top-[15px] cursor-pointer"
          onClick={() => {
            onClose();
          }}
        />
        <img src={Webhooks} alt="connect rb2b" className="mx-auto" />
        <div className="modal-scroll px-[28px] pb-[18px]">
          <div className="mt-[16px] px-[10px]">
            <h4 className="font-[600] text-[18px] text-[#101828] text-center mb-[8px] font-inter">
              Your Webhooks{" "}
            </h4>
            <p className="font-normal text-[14px] text-[#475467] text-center font-inter mb-[15px]">
              Integrate multiple custom Webhooks to easily add new leads to
              Pipeline by providing a LinkedIn URL.
            </p>
          </div>
          <div
            className="max-h-[300px] overflow-auto modal-scroll pr-2"
            ref={webhookListRef}
          >
            {webhooks?.map((w, index) => {
              return (
                <div className="">
                  <label className="text-[14px] font-[500] text-[#344054]">
                    Webhook {index + 1}{" "}
                  </label>
                  <div className="relative flex items-center justify-center mt-[6px]">
                    <OutlinedInput
                      placeholder="Enter link"
                      className="!text-[#101828] !text-[16px] text-ellipsis overflow-hidden whitespace-nowrap max-w-full !px-[0px] !pr-[42px] !pl-[14px]"
                      value={w}
                      isLoading={isLoading}
                    />
                    <div className="absolute right-[10px]">
                      <img
                        src={copyIcon}
                        alt="copy icon"
                        className="w-[20px] h-[20px] min-w-[20px] ml-[14px] mr-[29px] block cursor-pointer relative hover:opacity-60 transition-opacity duration-200 ease-in-out"
                        onClick={() => handleCopy(index, w)}
                      />
                      {copiedTooltipVisible &&
                        currentCopyURLIndex === index && (
                          <div
                            id="tooltip-hover"
                            role="tooltip"
                            className="absolute z-10 top-[-42px] px-3 py-[4px] text-[12px] font-[400] text-white bg-gray-900 rounded-[6px] shadow-sm border border-[#3C3C3C] transition-opacity duration-300 opacity-100"
                          >
                            Copied!
                          </div>
                        )}
                    </div>
                    <img
                      src={DeleteIcon}
                      alt="DeleteIcon"
                      className="ml-[10px] cursor-pointer"
                      onClick={() => setIsDeleteWebhookOpen(true)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          {/* <div className="mt-[15px]">
            <label className="text-[14px] font-[500] text-[#344054]">
              Webhook 1{" "}
            </label>
            <div className="relative flex items-center justify-center mt-[6px]">
              <OutlinedInput
                placeholder="Enter link"
                className="!text-[#101828] !text-[16px] text-ellipsis overflow-hidden whitespace-nowrap max-w-full !px-[0px] !pr-[26px] !pl-[14px]"
                value={"app.withpipeline.com/webhook/13348492..."}
                isLoading={isLoading}
              />
              <div className="absolute right-[10px]">
                <img
                  src={copyIcon}
                  alt="copy icon"
                  className="w-[20px] h-[20px] min-w-[20px] ml-[14px] mr-[29px] block cursor-pointer relative hover:opacity-60 transition-opacity duration-200 ease-in-out"
                  onClick={handleCopy}
                />
                {copiedTooltipVisible && (
                  <div
                    id="tooltip-hover"
                    role="tooltip"
                    className="absolute z-10 top-[-42px] px-3 py-[4px] text-[12px] font-[400] text-white bg-gray-900 rounded-[6px] shadow-sm border border-[#3C3C3C] transition-opacity duration-300 opacity-100"
                  >
                    Copied!
                  </div>
                )}
              </div>
              <img
                src={DeleteIcon}
                alt="DeleteIcon"
                className="ml-[10px] cursor-pointer"
                onClick={() => setIsDeleteWebhookOpen(true)}
              />
            </div>
          </div> */}
          <div className="mt-[23px]">
            <div className="flex">
              <img
                src={PlusIcon}
                alt="PlusIcon"
                className="mr-[13px] cursor-pointer"
              />
              <p
                className="text-[#6941C6] font-[600] text-[14px] cursor-pointer"
                onClick={() => setStep(1)}
              >
                Create New Webhook
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebhookLists;
