import React from "react";
import { useNavigate } from "react-router-dom";
import background from "assets/images/not-found-bg.svg";

export default function NotFoundPage() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col justify-center items-center w-full h-full bg-black font-inter">
      <img
        src={background}
        alt="background"
        className="absolute top-0 left-0 w-full h-screen object-cover"
      />
      <div className="z-10 flex flex-col justify-center items-center">
        <p className="text-white text-[250px] leading-none font-bold">404</p>
        <button
          onClick={() => navigate("/dashboard")}
          className="w-[100px] h-9 rounded-lg text-sm font-medium bg-white text-black mt-5"
        >
          Back
        </button>
      </div>
    </div>
  );
}
