import OutlinedInput from "components/outlinedInput";
import Button from "components/button";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AppContext } from "context/AppContext";
import settingIcon from "assets/images/settingIcon.png";
import lockIcon from "assets/images/purple-lock.svg";
import Select from "react-select";
import { timeSlotsOptions } from "constant";
import alertIcon from "assets/images/error.svg";
import { useCampaignAddSettings } from "services/campaigns-api";
import { useAccountStore, useAuthStore, useCampaignStore } from "stores";
import Tick from "assets/images/name-check.svg";
import useRandomImagesStore from "stores/randomImagesStore";
import { getRandomProfileLogoWithRandom } from "utils/index";
import { useProspectFiltersInfo } from "services/prospecting-api";

const daysData = [
  { name: "Sun" },
  { name: "Mon" },
  { name: "Tue" },
  { name: "Wed" },
  { name: "Thu" },
  { name: "Fri" },
  { name: "Sat" },
];

const CampaignSettings = (props) => {
  const { setAppState } = useContext(AppContext);
  const { linkedinAccountDetails } = useAccountStore();
  const { setNewCampaign, isDraftCampaignClicked, draftListId } =
    useCampaignStore();
  const { profileImage } = useRandomImagesStore();
  const [campaignName, setCampaignName] = useState("");
  const campaignAddSettings = useCampaignAddSettings();
  const { campaignId } = useCampaignStore();
  const { companyId } = useAuthStore();
  const { setTab, settingsData, refetchSettings, setCompletedTabs } = props;
  const [startTime, setStartTime] = useState({
    value: "09:00",
    label: "09:00 AM",
  });
  const [endTime, setEndTime] = useState({ value: "17:00", label: "05:00 PM" });
  const [error, setError] = useState("");
  const [activeDays, setActiveDays] = useState(
    Array(daysData.length).fill(false).fill(true, 1, 6)
  );
  const [prospect, setProspect] = useState();

  const useGetProspectFiltersInfo = useProspectFiltersInfo();

  const handleGetProspect = async () => {
    try {
      const response = await useGetProspectFiltersInfo.mutateAsync({
        company_id: companyId,
        prospect_id: draftListId,
      });
      setProspect(response);
    } catch (error) {}
  };

  useEffect(() => {
    handleGetProspect();
    if (Object?.keys(settingsData || {})?.length !== 0) {
      setCampaignName(
        isDraftCampaignClicked === "steps_pending"
          ? ""
          : settingsData?.campaign_name || ""
      );
      const days = Array(7).fill(false);

      settingsData?.days_of_the_week?.forEach((i) => {
        days[i] = true;
      });
      if (settingsData?.days_of_the_week) {
        setActiveDays(days);
      } else {
        setActiveDays(Array(daysData.length).fill(false).fill(true, 1, 6));
      }
      setEndTime({
        label:
          timeSlotsOptions?.find(
            (time) => time?.value === settingsData?.end_hour
          )?.label || "05:00 PM",
        value: settingsData?.end_hour || "17:00",
      });
      setStartTime({
        label:
          timeSlotsOptions?.find(
            (time) => time?.value === settingsData?.start_hour
          )?.label || "09:00 AM",
        value: settingsData?.start_hour || "09:00",
      });
    }
  }, [settingsData]);

  const handleNext = async () => {
    const trimmedCampaignName = campaignName.trim();
    if (trimmedCampaignName?.length) {
      const activeDay = activeDays
        .map((value, index) => (value === true ? index : -1)) // Map to index or -1 if false
        .filter((index) => index !== -1);
      const payload = {
        campaign_id: campaignId,
        company_id: companyId,
        campaign_name: trimmedCampaignName,
        notification_email: true,
        notification_slack: true,
        days_of_the_week: activeDay,
        start_hour: startTime?.value,
        end_hour: endTime?.value,
      };
      try {
        const response = await campaignAddSettings.mutateAsync(payload);
        console.log("Campaign Schedule Updated", response);
        if (!error) {
          setAppState((prev) => ({
            ...prev,
            settingsStepCompleted: true,
          }));
          setNewCampaign({
            campaignId: campaignId,
            campaign_name: trimmedCampaignName,
          });
          toast.success("Campaign Settings Updated", {
            icon: () => <img src={Tick} alt="name" />,
          });
          await refetchSettings();
          setCompletedTabs((prevTabs) => [...prevTabs, "Review"]);
          setTab("Review");
        }
      } catch (error) {
        console.error("Error adding campaign settings:", error);
        toast.success("Failed to update campaign settings");
      }
    } else {
      toast.success("Please Enter a Campaign Name");
    }
  };

  const handleStartTimeChange = (selectedOption) => {
    setStartTime(selectedOption);
    const startTimeValue = parseTime(selectedOption.label);

    let endTimeValue = startTimeValue + 3;
    if (endTimeValue >= 24) {
      endTimeValue -= 24;
    }

    const endOption = timeSlotsOptions.find(
      (time) => parseTime(time.label) === endTimeValue
    );

    if (endOption) {
      setEndTime(endOption);
    }

    validateTime(selectedOption.value, endOption.value);
  };

  const handleEndTimeChange = (selectedOption) => {
    setEndTime(selectedOption);
    validateTime(startTime.value, selectedOption.value);
  };

  const parseTime = (time) => {
    // Convert "01:00 PM" to 24-hour format
    const [hour, modifier] = time.split(" ");
    let [hours, minutes] = hour.split(":").map(Number);

    if (modifier === "PM" && hours !== 12) {
      hours += 12;
    } else if (modifier === "AM" && hours === 12) {
      hours = 0;
    }

    return hours + minutes / 60; // Convert to hours with decimal
  };
  const validateTime = (start, end) => {
    const startHour = parseTime(start);
    const endHour = parseTime(end);

    // If endHour is smaller, it's the next day, so add 24 hours
    const adjustedEndHour = endHour < startHour ? endHour + 24 : endHour;

    const diff = adjustedEndHour - startHour;

    if (diff < 3) {
      setError("This time window needs to be a minimum of 3 hours.");
    } else {
      setError("");
    }
  };

  //  day select toggle
  const toggleDayActive = (index) => {
    setActiveDays((prev) => {
      const updatedDays = [...prev];
      updatedDays[index] = !updatedDays[index];
      return updatedDays;
    });
  };

  return (
    <div className="w-[75%] font-inter">
      <div className="mb-[16px] flex items-center">
        <img
          src={settingIcon}
          alt="settingIcon"
          className="w-[48px] h-[48px] mr-[13px]"
        />
        <div>
          <h4 className="font-[600] text-[#101828] text-[15.3px]">
            Campaign Settings
          </h4>
          <p className="font-[400] text-[#475467] text-[11.8px] mt-[3px]">
            Personalize these settings to finish setting up your campaign.
          </p>
        </div>
      </div>
      <div className="pt-[44px] pb-[51px] w-full border-t-[1px] border-[#E4E7EC]">
        <div className="flex items-start mb-[51px]">
          <h6 className="font-[600] text-[#344054] text-[11.8px] w-[236px] mr-[36px] mb-0">
            Campaign Name<span className="text-[#7F56D9]">*</span>
          </h6>
          <OutlinedInput
            type="text"
            value={campaignName}
            placeholder="e.g. Angel Investors"
            className="h-[37px] placeholder:!text-[#9C9C9C] !text-[13.5px] leading-[20.3px] !w-[433px]"
            onChange={(e) => {
              setCampaignName(e?.target?.value);
              setAppState((prev) => ({
                ...prev,
                activeCampaignName: e?.target?.value,
              }));
            }}
          />
        </div>
        <div className="flex items-start mb-[51px]">
          <h6 className="font-[600] text-[#344054] text-[11.8px] w-[236px] mr-[36px] mb-0">
            Lead List
          </h6>
          <OutlinedInput
            type="text"
            placeholder="e.g. Angel Investors"
            className="h-[37px] placeholder:!text-[#9C9C9C] !text-[13.5px] leading-[20.3px] !w-[433px]"
            value={prospect?.prospect_name}
            disabledInput={true}
          />
        </div>
        <div className="flex items-start">
          <h6 className="font-[600] text-[#344054] text-[11.8px] w-[236px] mr-[36px] mb-0 flex items-center">
            LinkedIn Account
            <img
              src={lockIcon}
              alt="lockIcon"
              className="w-[12px] h-[12px] ml-[7px] mt-[-4px]"
            />
          </h6>
          <div className="w-[433px] flex items-center rounded-[6px] border-[1px] border-[#D0D5DD] px-[8px] py-[7px] y-axis-shadow">
            <img
              src={
                linkedinAccountDetails?.profile_pic ||
                getRandomProfileLogoWithRandom(profileImage)?.src
              }
              alt="linkedAcc"
              className="w-[37px] h-[36px] mr-[6px] rounded-full"
            />
            <h5 className="font-[500] text-[#101828] text-[13px]">
              {linkedinAccountDetails?.account_name || "Test Account"}
            </h5>
          </div>
        </div>
      </div>
      <div className="border-t-[1px] border-[#E4E7EC] pt-[31px] pb-[31px]">
        <div className="flex items-center mb-[50px]">
          <h6 className="font-[600] text-[#344054] text-[11.8px] w-[236px] mr-[36px] mb-0 flex items-center">
            Schedule
          </h6>
          <div className="flex items-center">
            <ul className="flex items-center">
              {daysData.map((day, index) => (
                <li
                  key={index}
                  onClick={() => toggleDayActive(index)} // Toggle the active state on click
                  className={`font-inter cursor-pointer rounded-[6.5px] min-w-[41px] px-[12px] py-[8px] y-axis-shadow font-[600] text-[11.3px] mr-[15px] border-[1px] 
                   ${
                     activeDays[index]
                       ? "bg-[#7927FF] text-[#FFFFFF] border-[#7927FF] hover:bg-[#FFFFFF] hover:text-[#7927FF]"
                       : "bg-[#FFFFFF] text-[#344054] border-[#D0D5DD] hover:!bg-[#f5f5f5db]"
                   }`}
                >
                  {day.name}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="flex items-start">
          <div className="w-[236px] mr-[36px]">
            <h6 className="font-[600] text-[#344054] text-[11.3px] mr-[36px] mb-0">
              Time
            </h6>
            <p className="text-[#9A9A9A] text-[11.3px] font-[400] mb-[10px]">
              If you have multiple campaigns running during the same time
              windows, it will round robin between them.
            </p>
            <p className="text-[#9A9A9A] text-[11.3px] font-[400]">
              Time is displayed in your Workspace’s set time zone.
            </p>
          </div>
          <div className="flex flex-col">
            <div
              className={`flex items-center ${error ? "error-time-slot" : ""}`}
            >
              <Select
                value={startTime}
                onChange={handleStartTimeChange}
                options={timeSlotsOptions}
                classNamePrefix="time-slots"
                maxMenuHeight="130px"
                autoFocus
                isSearchable={false}
              />
              <p className="font-[400] text-[#6A6A6A] text-[12px] mx-[13px]">
                to
              </p>
              <div
                className={`relative flex items-center ${
                  error && "show-error-img"
                }`}
              >
                <Select
                  value={endTime}
                  onChange={handleEndTimeChange}
                  options={timeSlotsOptions}
                  classNamePrefix="time-slots"
                  maxMenuHeight="130px"
                  autoFocus
                  isSearchable={false}
                />
                {error && (
                  <img
                    src={alertIcon}
                    alt="error"
                    className="absolute right-[7px] mt-[-1px]"
                  />
                )}
              </div>
            </div>
            {error && (
              <div className="text-[10.6px] font-[400] text-[#D92D20] mt-[8px]">
                {error}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end mt-[0px]">
        <Button
          title={
            <>
              <div className="flex items-center justify-center">
                {campaignAddSettings?.isPending ? (
                  <div className="w-[20px] h-[20px] border-[3px] border-t-[3px] border-[#fff] border-solid rounded-full animate-spin border-t-[#8A43FF]" />
                ) : (
                  "Save"
                )}
              </div>
            </>
          }
          gradientBg={true}
          className="!w-[78px] !text-[12.7px] leading-[18px] !h-[37px] !font-[600] y-axis-shadow !rounded-[7px] !pb-0"
          onClick={() => handleNext()}
        />
      </div>
    </div>
  );
};

export default CampaignSettings;
