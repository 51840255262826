import { useContext, useEffect, useState } from "react";
import Button from "components/button";
import starIcon from "assets/images/stars.svg";
import { AppContext } from "context/AppContext";

const ManuallyDefaultStep = ({ setStep, setEnrichType, enrichType }) => {
  const { appState, setAppState } = useContext(AppContext);

  const initialButtonType =
    appState?.importMethodClicked === "manuallyCompanyUrl"
      ? "Companies"
      : appState?.importMethodClicked === "manuallyLink"
      ? "People"
      : "People";

  const [selectedButtonType, setSelectedButtonType] =
    useState(initialButtonType);

  const handleSelection = (type) => {
    setSelectedButtonType(type);
  };
  const handleNext = () => {
    if (selectedButtonType === "People") {
      setStep("manually-enter");
    } else if (selectedButtonType === "Companies") {
      setStep("manually-enter");
    }
  };
  useEffect(() => {
    setAppState((prev) => ({
      ...prev,
      selectedButtonType: selectedButtonType,
    }));
    localStorage.setItem("selectedButtonType", selectedButtonType);
  }, [selectedButtonType]);

  return (
    <div className="modal-scroll">
      <div className="flex flex-col items-start justify-start mb-[20px] font-inter">
        <div className="flex items-center justify-center border-[1px] border-[#E4E7EC] rounded-[10px] p-[11px] mb-[16px] y-axis-shadow">
          <img src={starIcon} alt="info img" className="h-[24px] w-[24px]" />
        </div>
        <h4 className="font-[600] text-[18px] text-[#101828] m-0 font-inter">
          What kind of links are you uploading?
        </h4>
      </div>
      <div className="flex mb-[30px]">
        <Button
          title="People"
          isWhiteBtn={true}
          isFullWidth={false}
          isRounded="8px"
          onClick={() => {
            setEnrichType("Peoples");
            handleSelection("People");
          }}
          className={`!text-[16px] !h-[44px] !font-[600] !text-[#344054] !border-[#D0D5DD] !px-[18px] focus:!bg-[#7F56D9] focus:!text-white y-axis-shadow ${
            selectedButtonType === "People" ? "!bg-[#7F56D9] !text-white" : ""
          }`}
          hideHover={selectedButtonType === "People"}
        />
        <Button
          title="Companies"
          isWhiteBtn={true}
          isFullWidth={false}
          isRounded="8px"
          onClick={() => {
            setEnrichType("Companies");
            handleSelection("Companies");
          }}
          className={`!text-[16px] !h-[44px] !font-[600] !text-[#344054] !border-[#D0D5DD] !px-[18px] ml-[12px] focus:!bg-[#7F56D9] focus:!text-white y-axis-shadow ${
            selectedButtonType === "Companies"
              ? "!bg-[#7F56D9] !text-white"
              : ""
          }`}
          hideHover={selectedButtonType === "Companies"}
        />
      </div>
      <div className="flex items-center justify-center mt-[32px]">
        <Button
          title="Back"
          isWhiteBtn={true}
          isRounded="6px"
          onClick={() => setStep("import-options")}
          className="!text-[16px] !h-[44px] !font-[600] !text-[#344054] y-axis-shadow"
        />
        <Button
          title="Confirm"
          secondaryColor="!border-[#7F56D9] !bg-[#7F56D9] hover:!bg-transparent hover:!text-[#7F56D9] ml-[12px]"
          className="!text-[16px] !h-[44px] !font-[600] y-axis-shadow"
          isRounded="6px"
          onClick={handleNext}
        />
      </div>
    </div>
  );
};

export default ManuallyDefaultStep;
