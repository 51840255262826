import { useEffect, useState } from "react";
import copyIcon from "assets/images/copy-icon.svg";
import shareIcon from "assets/images/share-modal-icon.svg";
import OutlinedInput from "components/outlinedInput";
import { useCampaignStore } from "stores";
import { modifyUrl } from "utils/modify-url";

const ShareLinkCampaignModal = (props) => {
  const { campaignId } = useCampaignStore();
  const { isOpen, onClose } = props;
  const [copiedTooltipVisible, setCopiedTooltipVisible] = useState(false);
  const [urlValue, setUrlValue] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (campaignId) {
      const modifiedUrl = modifyUrl(campaignId, "/default-activity");
      setUrlValue(modifiedUrl);
    }
  }, [campaignId]);

  useEffect(() => {
    let timer;
    if (copiedTooltipVisible) {
      timer = setTimeout(() => {
        setCopiedTooltipVisible(false);
      }, 800);
    }
    return () => clearTimeout(timer);
  }, [copiedTooltipVisible]);

  const handleCopy = () => {
    if (!urlValue) return;

    navigator.clipboard
      .writeText(urlValue)
      .then(() => {
        setCopiedTooltipVisible(true);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  if (!isOpen && !isModalVisible) return null;

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 font-inter transition-opacity duration-[400ms] ease-in-out ${
        isModalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className="fixed inset-0 bg-black opacity-10"
        onClick={onClose}
      ></div>
      <div
        className={`bg-white p-[26px] rounded-lg shadow-lg z-10 w-full max-w-[454px] relative transition-all ease-in-out duration-[150ms] ${
          isModalVisible ? "scale-100" : "scale-95"
        }`}
      >
        <img
          src={shareIcon}
          alt="left img"
          className="w-[48px] h-[48px] rounded-full absolute top-[-25px] left-1/2 -translate-x-1/2"
        />
        <div className="modal-scroll">
          <div className="mt-[20px]">
            <h4 className="font-[600] text-[20px] text-[#101828] text-center mb-[8px] font-inter">
              Share Campaign
            </h4>
            <p className="font-normal text-[16px] text-[#475467] text-center font-inter">
              This link will only work if your teammate is signed into their
              Pipeline account.
            </p>
          </div>
          <div className="flex items-center justify-center ml-auto mt-[15px] w-[376px]">
            <OutlinedInput
              placeholder="Enter share link"
              className="!text-[#101828] !text-[16px] text-ellipsis overflow-hidden whitespace-nowrap max-w-full"
              value={urlValue}
            />
            <div className="relative">
              <img
                src={copyIcon}
                alt="copy icon"
                className="w-[20px] h-[20px] min-w-[20px] mx-[14px] block cursor-pointer relative hover:opacity-60 transition-opacity duration-200 ease-in-out"
                onClick={handleCopy}
              />
              {copiedTooltipVisible && (
                <div
                  id="tooltip-hover"
                  role="tooltip"
                  className="absolute z-10 top-[-42px] px-3 py-[4px] text-[12px] font-[400] text-white bg-gray-900 rounded-[6px] shadow-sm border border-[#3C3C3C] transition-opacity duration-300 opacity-100"
                >
                  Copied!
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareLinkCampaignModal;
