/* eslint-disable default-case */
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import LoginStepper from "components/loginStepper";
import LoginLoader from "components/loginLoader";
import UpgradeLinkedIn from "../upgradeLinkedIn";
import CreateWorkSpace from "../createWorkSpace";
import { GrLinkNext } from "react-icons/gr";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import {
  useAuthCheck,
  useCreateLinkedinV2Account,
  useLinkedinAuthentication,
  useLinkedinCallback,
  useLinkedinChallengerCode,
  useLinkedInChallengerPhone,
  useLinkedInPhoneCode,
  useProxyLocation,
  useResendLinkedinVerificationCode,
  useVerifyLinkedin,
} from "services/auth-api";
import { useGetLinkedinAccount } from "services/accounts-api";
import { useAccountStore } from "stores";
import { setPaymentVerify } from "utils/token-service";
import { countryCodeOptions, countrySelectStyle } from "constant";
import StepSignIn from "./components/step-signin";
import StepOTP from "./components/step-otp";
import { debounceCountry } from "utils/";
import { AllLocations } from "constant/locations";
import StepCaptcha from "./components/step-captcha";
import StepQrCode from "./components/step-qr-code";
import StepInApp from "./components/step-in-app";
import Logo from "assets/images/logo.svg";
import Step2FA from "./components/step-2fa";
import Loader from "components/loader";
import LetterEmoji from "assets/images/loveletter.png";

const inputClasses = `w-full h-[42px] font-[400] bg-[#00000014] border border-1 border-[#333741] rounded-[7px] outline-none py-[9px] px-[13px] relative text-[16px] text-[#fff] placeholder:!text-[#85888E] focus-visible:border-[#fff] focus-visible:text-[#fff] focus-visible:placeholder:text-[#fff] transition-all`;

const ConnectLinkedIn = () => {
  const intervalRef = useRef(null);
  const navigate = useNavigate();
  // const { countryCode, error } = useCountryCode();
  const { setLinkedinAccount } = useAccountStore();
  const createLinkedinAccountMutation = useCreateLinkedinV2Account();
  const linkedinAuthenticationMutation = useLinkedinAuthentication();
  const linkedinChallengerCodeMutation = useLinkedinChallengerCode();
  const verifyLinkedinPhone = useLinkedInChallengerPhone();
  const linkedinPhoneCode = useLinkedInPhoneCode();
  const getLinkedInAccountDetails = useGetLinkedinAccount();
  const verifyLinkedin = useVerifyLinkedin();
  const authCkeckMutation = useAuthCheck();
  const proxyLocationMutation = useProxyLocation();
  const linkedInCallback = useLinkedinCallback();
  const resendLinkedinCode = useResendLinkedinVerificationCode();

  const [step, setStep] = useState(0);
  const [OTP, setOTP] = useState("");
  const [loader, setLoader] = useState(false);
  const [isLaunchButtonClicked, setIsLaunchButtonClicked] = useState(false);
  const [fadeIn, setFadeIn] = useState(true);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [likedInData, setLikedInData] = useState();
  const [invitedUser, setInvitedUser] = useState(false);
  const [authStatus, setAuthStatus] = useState();
  const [linkedInStatus, setLinkedInStatus] = useState();
  const [isPhoneCard, setIsPhoneCard] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState(
    countryCodeOptions[0]
  );
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [locations, setLocations] = useState(AllLocations);
  const [selectedLocation, setSelectedLocation] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [captchaValue, setCaptchaValue] = useState();
  const [accountId, setAccountId] = useState();

  const handleAuthCheck = async () => {
    try {
      const navigationCheck = await authCkeckMutation.mutateAsync({});
      setAuthStatus(navigationCheck);
      if (!navigationCheck.payment_setup) {
        navigate("/");
      }

      if (navigationCheck.linkedin_setup) {
        setStep(3);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCountry = async (searchQuery) => {
    try {
      setIsLoading(true);
      const location = await proxyLocationMutation.mutateAsync({
        query: searchQuery,
      });
      location?.results?.length && setLocations(location?.results);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSearchLocation = debounceCountry((event) => {
    const searchValue = event;
    if (searchValue) {
      getCountry(searchValue);
    } else {
      setLocations(AllLocations);
    }
  }, 300);

  useEffect(() => {
    handleAuthCheck();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = async (data) => {
    if (!!!selectedLocation) {
      return toast.error("Please select country");
    }
    setLoader(true);
    try {
      const response = await createLinkedinAccountMutation.mutateAsync({
        linkedin_username: data.email,
        linkedin_password: data.password,
        proxy_location: selectedLocation?.value,
      });

      if (response?.status === 202) {
        setAccountId(response?.account_id);
        setLikedInData(response);
        if (response?.checkpoint?.type === "CAPTCHA") {
          setSelectedLocation();
          setLoader(false);
          setCaptchaValue(response?.checkpoint);
          setStep(4);
          return;
        } else if (response?.checkpoint?.type === "IN_APP_VALIDATION") {
          setSelectedLocation();
          setLoader(false);
          setStep(6);
          return;
        } else if (response?.checkpoint?.type === "2FA") {
          setSelectedLocation();
          setLoader(false);
          setStep(7);
          return;
        } else if (response?.checkpoint?.type === "OTP") {
          setSelectedLocation();
          setLoader(false);
          setIsPhoneCard(false);
          setStep(1);
          return;
        } else if (response?.checkpoint?.type === "PHONE_REGISTER") {
          setSelectedLocation();
          setLoader(false);
          setIsPhoneCard(true);
          setStep(1);
          return;
        }
      }

      if (response?.status === 200) {
        setTimeout(async () => {
          try {
            const verify = await verifyLinkedin.mutateAsync({
              account_id: response?.account_id,
            });
            if (verify?.login_process === "inprogress") {
              intervalRef.current = setInterval(async () => {
                try {
                  const verify = await verifyLinkedin.mutateAsync({
                    account_id: response?.account_id,
                  });
                  setLinkedInStatus(verify);
                  if (verify?.login_process === "completed") {
                    clearInterval(intervalRef.current);
                    setLikedInData(verify);
                    if (verify?.login_failed) {
                      toast.error("Login failed");
                      setLoader(false);
                      setSelectedLocation();
                      return;
                    } else if (verify?.invalid_credentials) {
                      setLoader(false);
                      setSelectedLocation();
                      toast.error(
                        "Wrong username/password combo. Please try again!"
                      );
                      return;
                    } else if (verify?.challenge_requested) {
                      setLoader(false);
                      setSelectedLocation();
                      handleGetLinkedInAccountDetails();
                    }
                    if (verify?.account_connected) {
                      setLoader(false);
                      verify?.is_premium ? setStep(3) : setStep(2);
                    } else if (verify?.phone_challenge_requested) {
                      setStep(1);
                      setIsPhoneCard(true);
                    } else {
                      setStep(1);
                    }
                    setLoader(false);
                    setSelectedLocation();
                  } else if (verify?.login_failed) {
                    clearInterval(intervalRef.current);
                    toast.error("Login failed");
                    setLoader(false);
                    setSelectedLocation();
                  }
                } catch (verifyError) {
                  clearInterval(intervalRef.current);
                  setLoader(false);
                  setSelectedLocation();
                  handleVerifyError(verifyError);
                }
              }, 30000);
            } else if (verify?.login_process === "completed") {
              if (verify?.account_connected) {
                setLoader(false);
                verify?.is_premium ? setStep(3) : setStep(2);
              }
            } else {
              toast.error("Login failed");
              setLoader(false);
              setSelectedLocation();
            }
          } catch (initialVerifyError) {
            setSelectedLocation();
            setLoader(false);
            handleVerifyError(initialVerifyError);
          }
        }, 10000);
      }

      if (response?.status === 400) {
        setSelectedLocation();
        setLoader(false);
        toast.error(response?.message);
      }
    } catch (error) {
      setSelectedLocation();
      setLoader(false);
      if (error?.data && error?.data?.detail) {
        toast.error(error?.data?.detail);
      } else {
        toast.error("An unknown error occurred");
      }
    }
  };

  const handleVerifyError = (error) => {
    if (error?.data && error?.data?.detail) {
      toast.error(error?.data?.detail);
    } else {
      toast.error("An error occurred during LinkedIn verification");
    }
  };

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const handleVerification = async (accountId) => {
    try {
      const verify = await verifyLinkedin.mutateAsync({
        account_id: accountId,
      });
      if (verify?.account_connected) {
        clearInterval(intervalRef.current);
        setOTP("");
        verify?.is_premium ? setStep(3) : setStep(2);
      } else if (verify?.login_failed) {
        clearInterval(intervalRef.current);
        setOTP("");
        setStep(0);
      }
    } catch (error) {
      clearInterval(intervalRef.current);
      handleVerifyError(error);
    } finally {
      setLoader(false);
      clearInterval(intervalRef.current);
    }
  };

  const startVerificationInterval = (accountId) => {
    setLoader(true);
    intervalRef.current = setInterval(async () => {
      await handleVerification(accountId);
    }, 30000);
  };

  const handleCallback = async (OTP) => {
    setLoader(true);
    try {
      const response = await linkedInCallback.mutateAsync({
        account_id: String(accountId),
        code: OTP,
      });

      if (response?.status === 202) {
        if (response?.checkpoint?.type === "OTP") {
          setLikedInData(response);
          setLoader(false);
          setStep(1);
          setIsPhoneCard(false);
        } else if (response?.checkpoint?.type === "2FA") {
          setSelectedLocation();
          setLoader(false);
          setStep(7);
          return;
        }
      }

      if (response?.status === 201) {
        if (response?.message === "AccountCreated") {
          startVerificationInterval(String(accountId));
        }
      } else if (response?.status === 400) {
        setLoader(false);
        toast.error(response?.message);
      }
    } catch (error) {
      setLoader(false);
      toast.error("Login failed");
      setStep(0);
    }
  };

  const handleVerificationSubmit = async () => {
    setLoader(true);
    try {
      let response;

      if (likedInData?.challenge_requested) {
        response = await linkedinChallengerCodeMutation.mutateAsync({
          session_id: likedInData?.session_id?.toString(),
          account_id: likedInData?.account_id?.toString(),
          code: OTP,
        });
      } else if (likedInData?.two_factor_auth) {
        response = await linkedinAuthenticationMutation.mutateAsync({
          session_id: likedInData?.session_id?.toString(),
          account_id: likedInData?.account_id?.toString(),
          code: OTP,
        });
      } else if (likedInData?.phone_code_required) {
        response = await linkedinPhoneCode.mutateAsync({
          linkedin_username: "",
          account_name: "",
          session_id: likedInData?.session_id,
          account_id: likedInData?.account_id,
          code: OTP,
        });
      } else if (likedInData?.checkpoint?.type === "OTP") {
        handleCallback(OTP);
      } else if (likedInData?.checkpoint?.type === "2FA") {
        handleCallback(OTP);
      }

      if (response?.status === 200) {
        startVerificationInterval(response?.account_id);
      }
    } catch (error) {
      setLoader(false);
      handleVerifyError(error);
    }
  };

  const handleGetLinkedInAccountDetails = async () => {
    try {
      const response = await getLinkedInAccountDetails.mutateAsync();
      setLinkedinAccount(response);
      if (response?.captcha) {
        setStep(5);
      }
    } catch (error) {
      setLinkedinAccount({});
      console.log(error);
    }
  };

  const handlePhoneVerify = async () => {
    handleCallback(`(+${selectedCountryCode.value})${phoneNumber}`);
  };

  const handlePhoneNumberChange = (e) => {
    const input = e.target.value.replace(/\D/g, "");
    if (input.length <= 10) {
      setPhoneNumber(input);
    }
  };

  const handleResendCode = async () => {
    try {
      const response = await resendLinkedinCode?.mutateAsync({
        account_id: accountId,
      });

      if (response?.success) {
        toast.success("Code Re-sent", {
          icon: () => (
            <img src={LetterEmoji} alt="name" className="!w-[46px] !h-[16px]" />
          ),
        });
      }
    } catch (error) {
      toast.success(error?.data?.detail);
    }
  };

  const renderSteps = () => {
    switch (step) {
      case 0:
        return (
          <StepSignIn
            isLoading={isLoading}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            register={register}
            errors={errors}
            inputClasses={inputClasses}
            passwordVisible={passwordVisible}
            setPasswordVisible={setPasswordVisible}
            locations={locations}
            setSelectedLocation={setSelectedLocation}
            countrySelectStyle={countrySelectStyle}
            fadeIn={fadeIn}
            onSearchLocation={onSearchLocation}
          />
        );
      case 1:
        return (
          <StepOTP
            isPhoneCard={isPhoneCard}
            inputClasses={inputClasses}
            isFocused={isFocused}
            setIsFocused={setIsFocused}
            setSelectedCountryCode={setSelectedCountryCode}
            selectedCountryCode={selectedCountryCode}
            phoneNumber={phoneNumber}
            handlePhoneNumberChange={handlePhoneNumberChange}
            handlePhoneVerify={handlePhoneVerify}
            OTP={OTP}
            setOTP={setOTP}
            handleVerificationSubmit={handleVerificationSubmit}
            handleResendCode={handleResendCode}
          />
        );
      case 2:
        return (
          <UpgradeLinkedIn
            setStep={setStep}
            setLoader={setLoader}
            setInvitedUser={setInvitedUser}
            verifyLinkedin={verifyLinkedin}
            intervalRef={intervalRef}
            handleVerifyError={handleVerifyError}
          />
        );
      case 3:
        return (
          <CreateWorkSpace
            setLoader={setLoader}
            setIsLaunchButtonClicked={setIsLaunchButtonClicked}
            handleGetLinkedInAccountDetails={handleGetLinkedInAccountDetails}
          />
        );
      case 4:
        return (
          <StepCaptcha
            data={captchaValue}
            setStep={setStep}
            setLoader={setLoader}
            accountId={accountId}
            setIsPhoneCard={setIsPhoneCard}
            setLikedInData={setLikedInData}
            startVerificationInterval={startVerificationInterval}
          />
        );
      case 5:
        return <StepQrCode />;
      case 6:
        return (
          <StepInApp
            accountId={accountId}
            startVerificationInterval={startVerificationInterval}
          />
        );
      case 7:
        return (
          <Step2FA
            OTP={OTP}
            setOTP={setOTP}
            accountId={accountId}
            startVerificationInterval={startVerificationInterval}
            setLoader={setLoader}
            setLikedInData={setLikedInData}
            setStep={setStep}
            handleResendCode={handleResendCode}
          />
        );
    }
  };

  return (
    <>
      {step === 0 && !loader && (
        <p
          className="text-center font-[600] text-[14px] text-[#98A2B3] cursor-pointer absolute right-5 top-5"
          onClick={() => {
            setLoader(true);
            localStorage.setItem("isSkipSignUp", true);
            if (authStatus?.company_setup) {
              handleGetLinkedInAccountDetails();
              setIsLaunchButtonClicked(false);
              setPaymentVerify(true);
              navigate("/dashboard");
            }
            setStep(3);
            setTimeout(() => {
              setLoader(false);
            }, 3000);
          }}
        >
          Skip this step{" "}
          <GrLinkNext className="text-[16px] w-[18px] h-[18px] inline ml-[8px] mt-[-1px]" />
        </p>
      )}

      {loader ? (
        <>
          <div className="!h-full font-inter flex flex-col justify-center items-center max-[767px]:h-auto  max-[767px]:p-[20px] max-[767px]:pt-[50px] max-[767px]:max-w-[100%]">
            <img
              src={Logo}
              alt="Logo"
              className="h-[60px] w-[60px] mb-[30px] relative"
            />
            <Loader />
            {/* <div className="w-[20px] h-[20px] border-[3px] border-t-[3px] border-[#fff] border-solid rounded-full animate-spin border-t-[#7F56D9]" /> */}
          </div>
        </>
      ) : (
        renderSteps()
      )}

      {!invitedUser && (
        <div className="fixed bottom-[20px] left-[50%] -translate-x-1/2">
          <LoginStepper step={step} setStep={setStep} />
        </div>
      )}
    </>
  );
};

export default ConnectLinkedIn;
