import deleteImg from "assets/images/speaker.svg";
import { useState } from "react";
import { toast } from "react-toastify";
import {
  useCreatePaymentLink,
  useUpdateSubscriptionStatus,
} from "services/payments-api";
import { useAccountStore, useAuthStore } from "stores";

const BillingFailure = (props) => {
  const { isOpen, onClose } = props;
  const { userDetails } = useAuthStore();
  const [loader, setLoader] = useState(false);
  const createPaymentLink = useCreatePaymentLink();
  const updateSubscriptionStatus = useUpdateSubscriptionStatus();
  const { clearLinkedinAccount, clearWorkSpaceCreditLimit } = useAccountStore();

  const onClick = async () => {
    setLoader(true);
    try {
      const link = await createPaymentLink.mutateAsync({
        price_id: "price_1PvLvZICbyBbd0D4UCEVVHfb",
        customer_mail_id: userDetails?.email,
      });

      if (link?.url) {
        window.open(link?.url, "_self");
        setLoader(false);
        localStorage.clear();
        clearLinkedinAccount();
        clearWorkSpaceCreditLimit();
      }
    } catch (error) {
      await updateSubscriptionStatus.mutateAsync();
      window.location.reload();
      setLoader(false);
      if (
        (error?.data && error?.data?.detail && error?.data?.detail?.[0]) ||
        (error?.data && error?.data?.detail)
      ) {
        toast.error(error?.data?.detail?.[0]?.msg || error?.data?.detail);
      } else {
        toast.error("An unknown error occurred");
      }
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center font-inter z-[99]">
      <div
        className="fixed inset-0 bg-black opacity-10"
        onClick={onClose}
      ></div>
      <div className="bg-white p-[24px] rounded-lg modal-shadow z-10 w-full max-w-[400px] relative">
        <div className="modal-scroll">
          <div className="modal-scroll">
            <div className="flex flex-col items-start justify-start mb-[20px] font-inter">
              <div className="flex items-center justify-center mb-[16px]">
                <img
                  src={deleteImg}
                  alt="info img"
                  className="h-[48px] w-[48px]"
                />
              </div>
              <h4 className="font-[600] text-[18px] text-[#101828] m-[4px] ml-0 font-inter">
                Billing Failure
              </h4>
              <p className="font-normal text-[14px] text-[#475467] font-inter mt-[11px]">
                Stripe was unable to process your payment for your monthly
                subscription.
              </p>
              <p className="text-[14px] text-[#475467] font-[500] font-inter mt-[23px] italic">
                Your campaigns have been paused and you will not be able to
                access your account until you have updated your payment.
              </p>
            </div>
            <div className="flex items-center justify-center mt-[32px]">
              <button
                style={{
                  boxShadow:
                    "0px 1px 2px 0px #1018280D, 0px -2px 0px 0px #1018280D inset, 0px 0px 0px 1px #1018282E inset",
                }}
                onClick={onClick}
                className="bg-[#7F56D9] text-[white] w-full p-[10px] rounded-[8px] font-[600] justify-center flex items-center"
              >
                {loader ? (
                  <div className="w-[20px] h-[20px] border-[3px] border-t-[3px] border-[#fff] border-solid rounded-full animate-spin border-t-[#8A43FF]" />
                ) : (
                  "Update Payment Method"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingFailure;
