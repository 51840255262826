import BgPattern from "assets/images/bgPattern.png";
import Illustration from "assets/images/Illustration.png";
import { useNavigate } from "react-router-dom";

const DontAccess = () => {
  const navigete = useNavigate();
  return (
    <div
      style={{
        backgroundImage: `url(${BgPattern})`,
        backgroundSize: "cover",
        backgroundPosition: "top center",
      }}
      className="w-[440px] h-[440px] m-auto mt-[0px] pt-[9rem]"
    >
      <img
        src={Illustration}
        alt="Illustration"
        className="w-[158px] h-[118px] m-auto"
      />
      <h4 className="mt-[18px] font-[600] text-[16.49px] text-[#101828] text-center font-inter">
        You don’t have access!
      </h4>
      <p className="m-auto mt-[7px] text-center text-[#475467] text-[12.82px] font-[400] w-[291px]">
        Please contact your Workspace Administrator for questions regarding
        adding team members, purchasing more credits and billing info.
      </p>
      <button
        onClick={() => navigete("/dashboard")}
        className="m-auto block mt-[30px] w-[151px] h-[40px] border border-[#D0D5DD] rounded-[8px] dashboardButton font-[600] text-[14.66px] text-[#344054]"
      >
        Go to Dashboard
      </button>
    </div>
  );
};

export default DontAccess;
