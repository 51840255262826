import {
  chooseStepButtonList,
  randomCompanyLogo,
  randomProfileLogo,
  randowGradientsBg,
  timeZones,
} from "constant";
import moment from "moment";

export const findKey = (item, formData) => {
  for (let key in formData) {
    if (
      ["funding", "headcount", "managementLevel", "department"]?.includes(key)
    ) {
      const values = formData?.[key]?.values?.map((item) => item?.value);
      if (values?.includes(item)) {
        return key;
      }
    } else if (formData[key]?.includes(item)) {
      return key;
    }
  }
  return null;
};

export const generateYearsArray = (startYear, endYear = 2024) => {
  if (startYear >= endYear) {
    return [];
  }

  return Array.from({ length: endYear - startYear }, (v, i) => ({
    label: (startYear + i + 1).toString(),
    value: startYear + i + 1,
  }));
};

export const datePattern = /^\d{2}\/\d{2}\/\d{4}$/;

export const onMinMax = (name, formData) => {
  if (
    name?.includes("min") &&
    (formData[name]?.length > 0 || formData[name]?.label?.length > 0)
  ) {
    return [`Min: ${formData[name]?.label || formData[name]}`];
  } else if (name?.includes("max")) {
    const minName = name.replace("max", "min");
    const minValue = parseFloat(formData[minName]);
    const maxValue = parseFloat(formData[name]);
    if (!isNaN(minValue) && !isNaN(maxValue) && maxValue <= minValue) {
      if (
        (name === "maxYear" && maxValue >= minValue) ||
        (name === "maxMonth" && maxValue === minValue)
      ) {
        return [`Max: ${formData[name]?.label || formData[name]}`];
      }
      return [];
    } else if (
      formData[name]?.length > 0 ||
      formData[name]?.label?.length > 0
    ) {
      return [`Max: ${formData[name]?.label || formData[name]}`];
    }
  } else if (["startDate", "endDate"]?.includes(name)) {
    const date = formData[name]
      ? moment(new Date(formData[name])).format("MM/DD/YYYY")
      : "";
    return date ? [`${date}`] : [];
  }
  return [];
};

export const convertToTotalMonths = (year, month) => {
  return year * 12 + month;
};

export function formatNumberWithCommas(number) {
  // Convert the number to a string
  let numberStr = number?.toString();

  // Use regex to add commas
  return numberStr?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export const getDataPrefill = (prospectData) => {
  const filters = prospectData?.filters;

  const defaultArray = [];
  const defaultObject = [];

  const mapFilterValues = (data, key) =>
    data?.[key]
      ?.map(({ s, v }) => (s === "e" ? key === "job_company" ? null :`Ex:${v}` : v))
      ?.filter(Boolean) || defaultArray;

  const mapFilterValuesLocation = (data, key) =>
    data?.[key]
      ?.map(({ s, v, b }) => (s === "e" ? `Ex:${v}` : `${v}/${b}`))
      ?.filter(Boolean) || defaultArray;

  const mapToLabelValue = (data, key) =>
    data?.[key]?.map((value) => ({ value, label: value })) || defaultObject;

  return {
    first_name: filters?.first_name || defaultArray,
    last_name: filters?.last_name || defaultArray,
    location: mapFilterValuesLocation(filters, "location"),
    job_title: mapFilterValues(filters, "job_title"),
    job_title_level: { values: mapToLabelValue(filters, "job_title_level") },
    job_role: { values: mapToLabelValue(filters, "job_role") },
    job_sub_role: { values: mapToLabelValue(filters, "job_sub_role") },
    skill: filters?.skill || [],
    school: filters?.school || [],
    major: filters?.major || [],
    job_company: mapFilterValues(filters, "job_company" ,),
    company_location: mapFilterValuesLocation(filters, "company_location"),
    company_size: { values: mapToLabelValue(filters, "company_size") },
    company_industry: filters?.company_industry || [],
    year_founded_start: {
      label: filters?.year_founded_start,
      value: Number(filters?.year_founded_start) || null,
    },
    year_founded_end: {
      label: filters?.year_founded_end,
      value: Number(filters?.year_founded_end) || null,
    },
  };
};

export const downloadCSV = (csvData, filename = "data.csv") => {
  // Create a Blob object with the CSV data
  const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });

  // Create a URL for the Blob object
  const url = URL.createObjectURL(blob);

  // Create a hidden anchor element
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);

  // Append the anchor to the body
  document.body.appendChild(link);

  // Trigger the download by simulating a click
  link.click();

  // Clean up
  document.body.removeChild(link);
  URL.revokeObjectURL(url); // Free up memory
};

export function generateNewSequenceArrayFromApiResponse(apiResponse) {
  return apiResponse.sequences
    .map((sequence) => {
      const step = chooseStepButtonList.find(
        (item) => item.sequence_id === sequence.sequence_id
      );

      if (step) {
        return {
          ...step,
          order_number: sequence.order_number,
          sentiment_value: sequence.sentiment_value,
          id: sequence?.id,
          value: sequence?.value,
        };
      } else {
        return null;
      }
    })
    .filter((item) => item !== null);
}

export const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

export const getNameInititals = (name) => {
  if (!name) return "NA";

  const splittedName = name
    .split(" ")
    .map((word) => word.replace(/[^a-zA-Z]/g, "")) // Remove anything that's not a letter
    .filter(Boolean);

  if (splittedName.length > 1) {
    return (
      (splittedName[0]?.charAt(0) || "") + (splittedName[1]?.charAt(0) || "")
    ).toUpperCase();
  } else if (splittedName.length === 1) {
    return (splittedName[0]?.substring(0, 2) || "").toUpperCase();
  }

  return "NA";
};

export const getRandomProfileLogo = () =>
  randomProfileLogo[Math.floor(Math.random() * randomProfileLogo.length)];

export const getRandomProfileLogoWithRandom = (random) =>
  randomProfileLogo[Math.floor(random * randomProfileLogo.length)];

export const getRandomColor = () =>
  randowGradientsBg[Math.floor(Math.random() * randowGradientsBg.length)];

export const getRandomCompanyLogo = () =>
  randomCompanyLogo[Math.floor(Math.random() * randomCompanyLogo.length)];

export const getRandomCompanyLogoWithRandom = (random) =>
  randomCompanyLogo[Math.floor(random * randomCompanyLogo.length)];

export const transformSequences = (data) => {
  const formatTime = (parsedValue) => {
    const days = parsedValue?.days || "0";
    const hours = parsedValue?.hours || "0";
    const minutes = parsedValue?.minutes || "0";
    return `${days} days ${hours} hours ${minutes} minutes`;
  };

  const formatNumWithUnit = (parsedValue, unit) => {
    const num = parsedValue?.num?.trim() || "1";
    return `${num} ${unit}`;
  };

  const handleParsingError = (sequence) => {
    if (sequence?.sequence_id === 2) {
      return `1 days 0 hours 0 minutes`;
    }
    if (sequence?.sequence_id === 6 || sequence?.sequence_id === 7) {
      return `1 months`;
    }
    return String(sequence?.value);
  };

  data?.sequences?.forEach((sequence) => {
    let value = sequence?.value;
    try {
      const parsedValue = JSON.parse(value);

      switch (sequence?.sequence_id) {
        case 2:
          sequence.value = formatTime(parsedValue);
          break;
        case 6:
        case 7:
          sequence.value = formatNumWithUnit(
            parsedValue,
            sequence?.sequence_id === 7 || sequence?.sequence_id === 6
              ? "months"
              : "days"
          );
          break;
        case 3:
          sequence.value = parsedValue?.inputValue || "";
          break;
        default:
          sequence.value = parsedValue?.inputValue || String(value);
          break;
      }
    } catch (e) {
      sequence.value = handleParsingError(sequence);
    }
  });
  const filteredSequences = data?.sequences
    ?.filter((sequence) => (sequence?.sequence_id ? sequence : null))
    ?.filter(Boolean);
  return {
    ...data,
    sequences: filteredSequences,
  };
};

export const reverseTransformSequences = (data) => {
  const matchRegex = (value, regex, defaults) => {
    const match = value.match(regex);
    return match ? match.slice(1) : defaults;
  };

  const handleSequenceValue = (sequence) => {
    const value = sequence?.value;

    switch (sequence?.sequence_id) {
      case 2: {
        const [days, hours, minutes] = matchRegex(
          value,
          /(\d+)\s+days\s+(\d+)\s+hours\s+(\d+)\s+minutes/,
          ["1", "0", "0"]
        );
        return JSON.stringify({ days, hours, minutes });
      }

      case 6: {
        const [num] = matchRegex(value, /(\d+)\s+months/, ["1"]);
        return JSON.stringify({ num, checked: true });
      }

      case 7: {
        const [num] = matchRegex(value, /(\d+)\s+months/, ["1"]);
        return JSON.stringify({ num, checked: true });
      }

      case 3: {
        return JSON.stringify({
          inputValue: value,
          isSendRequestCheckBoxChecked: value?.length > 0,
        });
      }

      default:
        return value;
    }
  };
  data?.sequences?.forEach((sequence) => {
    sequence.value = handleSequenceValue(sequence);
  });

  const transformedSequences = data?.sequences
    ?.map((sequence) => {
      const step = chooseStepButtonList?.find(
        (item) => item.sequence_id === sequence.sequence_id
      );
      if (step) {
        return {
          ...step,
          sentiment_value: sequence.sentiment_value,
          id: sequence?.id,
          value: sequence?.value,
        };
      }
      return null;
    })
    ?.filter(Boolean);

  return {
    ...data,
    sequences: transformedSequences,
  };
};

export const getTimezoneObject = (timezoneString) => {
  return timeZones?.find((item) => item?.label === timezoneString);
};

export const debounceCountry = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func.apply(null, args);
    }, delay);
  };
};
export const isFormFilled = (formData) => {
  return (
    formData.first_name.length > 0 ||
    formData.last_name.length > 0 ||
    formData.major.length > 0 ||
    formData.school.length > 0 ||
    formData.location.length > 0 ||
    formData.job_title.length > 0 ||
    formData.job_company.length > 0 ||
    formData.company_location.length > 0 ||
    formData.year_founded_start ||
    formData.year_founded_end ||
    formData.job_title_level.values.length > 0 ||
    formData.job_role.values.length > 0 ||
    formData.job_sub_role.values.length > 0 ||
    formData.skill.length > 0 ||
    formData.company_size.values.length > 0 ||
    formData.company_industry.length > 0
  );
};

// uppercase to capitalize
export const capitalizeWords = (str) => {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
