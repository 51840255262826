import SwitchToggle from "components/switchToggle";

const ConnectedAppsComponent = ({
  title,
  description,
  imageSrc,
  isSwitchOn,
  onSwitchChange,
  onConnectClick,
  isRB2B = false,
  setClickedCard,
  clickedCard,
  cardId,
  onConfigure = () => null,
}) => {
  const handleClick = () => {
    setClickedCard?.(cardId);
  };

  return (
    <div
      className={`w-[398px] h-[189px] border rounded-[12px] ${
        clickedCard === cardId
          ? "border-2 border-[#6941C6]"
          : "border-[#E4E7EC]"
      }`}
      style={{
        boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
      }}
      onClick={handleClick}
    >
      <div className="border-[#E4E7EC] border-b p-[20px]">
        <div className="flex justify-between mb-[26px]">
          <div className="flex flex-row items-center">
            <img
              src={imageSrc}
              alt={title}
              className="w-[48px] h-[48px] mr-[14px]"
            />
            <p className="text-[16px] font-[600] text-[#101828]">{title}</p>
          </div>
          <div className="campaign-toggle">
            <SwitchToggle
              checked={isSwitchOn}
              onChange={onSwitchChange}
              disabled
            />
          </div>
        </div>
        <div>
          <p className="font-[400] text-[14px] text-[#475467]">{description}</p>
        </div>
      </div>
      <div className="flex justify-end items-center px-[24px] py-[16px]">
        <button
          onClick={() => {
            if (cardId === "webhooks" && isSwitchOn) {
              onConfigure();
              return;
            } else {
              onConnectClick();
            }
          }}
          className="font-[600] text-[14px] text-[#6941C6]"
          disabled
        >
          {!isSwitchOn
            ? "Connect"
            : cardId === "webhooks" && isSwitchOn
            ? "Configure"
            : "Disconnect"}
        </button>
      </div>
    </div>
  );
};

export default ConnectedAppsComponent;
